define("clients/models/ortho-photo-map", ["exports", "@ember-data/model", "clients/models/shareable"], function (_exports, _model, _shareable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*jshint esversion: 6 */
  var _default = _shareable.default.extend({
    source_type: (0, _model.attr)('string'),
    processing: (0, _model.attr)('boolean'),
    processing_status: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    remote_urls: (0, _model.attr)('hash'),
    min_latitude: (0, _model.attr)('string'),
    max_latitude: (0, _model.attr)('string'),
    min_longitude: (0, _model.attr)('string'),
    max_longitude: (0, _model.attr)('string'),
    accessKey: (0, _model.attr)('string'),
    tiles_link: (0, _model.attr)('string'),
    mission: (0, _model.belongsTo)('mission'),
    admin_share: (0, _model.belongsTo)('share'),
    shot: (0, _model.belongsTo)('shot'),
    final: Ember.computed('source_type', function () {
      return this.source_type == 'edit';
    }),
    downloadableTif: Ember.computed('remote_urls.tif', function () {
      if (this.remote_urls) {
        return `${this.get('remote_urls.tif')}`;
      }

      return null;
    }),
    downloadableJpg: Ember.computed('remote_urls.jpg.length', function () {
      if (this.get('remote_urls.jpg.length')) {
        return `${this.get('remote_urls.jpg')}`;
      }

      return null;
    }),
    isPromoted: Ember.computed('source_type', function () {
      return this.source_type == 'edit';
    })
  });

  _exports.default = _default;
});