define("clients/data/sample-mission-share-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    'cre-sample-1': 'https://api.dronebase.com/v1/clients/proxy/DBM7214786245',
    'rre-images-package': 'https://clients.dronebase.com/m/14b736a01ea103acb1a3a1f5f045e176',
    'rre-starter-package': 'https://clients.dronebase.com/m/e580cbf691d904d79f5de528da47bf63',
    'rre-pro-package': 'https://clients.dronebase.com/m/f3da923d61a20954e72a5e51d3286b05',
    'rre-pano-package': 'https://clients.dronebase.com/m/510cc6c761723a1077022e1c23561f27',
    'cre-pano-package': 'https://clients.dronebase.com/m/3fe73e8cce81a431cb8d918a63a13e02',
    'cre-shopping-center': 'https://clients.dronebase.com/m/5cacb72863d5e28a723a402aaf3dfa61',
    'cre-regional-center': 'https://clients.dronebase.com/m/7300b07e933131a600060750a103a6ca',
    'cre-images-package': 'https://clients.dronebase.com/m/c6aca1970a05ed08f24cd95a5180fd9f',
    'cre-images-shopping': 'https://clients.dronebase.com/m/49709986851727efa2ee82cafad9a64d',
    'cre-images-regional': 'https://clients.dronebase.com/m/31101f64ea4d47f13507efb31d9059cf',
    'cre-starter-package': 'https://clients.dronebase.com/m/98c97bf0dc62f35e08cf643111be9438',
    'cre-starter-shopping': 'https://clients.dronebase.com/m/d30f8c806dbf95a678e9d3d78ee339bf',
    'cre-starter-regional': 'https://clients.dronebase.com/m/4d7771b99405b362ed75b78b5a8d13c3',
    'cre-pro-package': 'https://clients.dronebase.com/m/948fe423b0ea2bde805fdefdc0676244',
    'cre-pro-shopping': 'https://clients.dronebase.com/m/b96bf9deeec951f7dc8bf388885320cd',
    'cre-pro-regional': 'https://clients.dronebase.com/m/7a5d052f1bcff484cb76e73902bef149'
  };
  _exports.default = _default;
});