define("clients/components/order-flow/package-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['package-block-container'],
    metrics: Ember.inject.service(),
    trackingId: Ember.computed('model.client.id', function () {
      if (this.get('model.client.id')) {
        return Number(this.get('model.client.id').substring(3));
      } else {
        return 'loggedout';
      }
    }),
    isSelected: Ember.computed('package.name', 'selectedPackage.name', function () {
      return this.selectedPackage == this.package;
    }),
    package_main_name: Ember.computed('package.name', function () {
      return this.get('package.name').split('(')[0];
    }),
    package_brackets: Ember.computed('package.name', function () {
      if (this.get('package.name').split('(').length > 1) {
        return `(${this.get('package.name').split('(')[1]}`;
      }

      return null;
    }),
    actions: {
      selectPackage: function () {
        this.selectPackageAction();
      },
      openSampleLink: function () {
        this.metrics.trackEvent({
          event: 'event',
          category: 'orderflow',
          action: 'click-sample-link-package',
          label: this.get('package.id'),
          value: this.trackingId
        });
        window.open(this.get('package.sample_link'), '_blank');
      }
    }
  });

  _exports.default = _default;
});