define("clients/adapters/share", ["exports", "clients/adapters/application", "ember-data-url-templates", "clients/config/environment"], function (_exports, _application, _emberDataUrlTemplates, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: `${_environment.default.api.host}/${_environment.default.api.namespace}/clients`,

    buildURL(modelName, id, snapshot) {
      return `${this.namespace}/shares${this.shareEndpoint(snapshot)}/${this.shareableId(id, snapshot)}`;
    },

    shareEndpoint: function (snapshot) {
      const shareTypes = ['video', 'image', 'panorama', 'ortho', 'mission'];

      if (!!snapshot.adapterOptions && !!snapshot.adapterOptions.shareType && shareTypes.indexOf(snapshot.adapterOptions.shareType) >= 0) {
        return `/${snapshot.adapterOptions.shareType}s`;
      } else {
        return '';
      }
    },
    shareableId: function (id, snapshot) {
      if (id) {
        return id;
      } else {
        return snapshot.id;
      }
    }
  });

  _exports.default = _default;
});