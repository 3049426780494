define("clients/models/catalog-item", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    supported_geometry: (0, _model.attr)('string'),
    shot_list: (0, _model.belongsTo)('shot_list', {
      async: false
    }),
    has_pano_shot_type: (0, _model.attr)('boolean'),
    panoShotType: Ember.computed('shot_list', function () {
      return this.get('shot_list.shot_types.[]').filter(item => {
        if (item.get('template') === 'pano_tiles') {
          return item;
        }
      }).firstObject;
    })
  });

  _exports.default = _default;
});