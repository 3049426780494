define("clients/data/sample_missions/rre_pro_package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": {
      "id": "DBM3351507871",
      "type": "missions",
      "attributes": {
        "instructions": null,
        "status": "complete",
        "client_id": "DBC3006554",
        "payment": {
          "id": null,
          "processor": null,
          "card": null
        },
        "external_assets_url": null,
        "owned_by_current_client": true,
        "contact_email": "clients@zeitview.com",
        "created_on": "2019-12-02T23:26:15Z",
        "is_pano_mission": false,
        "logo_url": null,
        "blurb": null,
        "paid": true,
        "paid_on": "2019-12-02T23:32:52Z",
        "price": 36900,
        "archive_url": null,
        "edit_url": null,
        "customer_instructions": "Weekend only. Fly between 12 \u2013 2 pm.",
        "scheduled_at_start": "2019-12-07T18:00:00Z",
        "scheduled_at_end": "2019-12-07T19:00:00Z",
        "parent_id": null,
        "reshoot_mission_id": null,
        "rejection_reason": null,
        "rejection_notes": null,
        "reference_id": "RRE Pro Sample Mission ",
        "cloud_reqs": "less_than_50",
        "scheduling_requirement": "not_needed",
        "arcgis_feature_layer": null,
        "geo_properties": {}
      },
      "relationships": {
        "location": {
          "data": {
            "id": "284849",
            "type": "locations"
          }
        },
        "package": {
          "data": {
            "id": "20040",
            "type": "packages"
          }
        },
        "credit_card": {
          "data": null
        },
        "onsite_contact": {
          "data": {
            "id": "49448",
            "type": "onsite_contacts"
          }
        },
        "scheduling_contact": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "images": {
          "data": [{
            "id": "DBI9327499775",
            "type": "images"
          }, {
            "id": "DBI2151789455",
            "type": "images"
          }, {
            "id": "DBI0227293475",
            "type": "images"
          }, {
            "id": "DBI3029037527",
            "type": "images"
          }, {
            "id": "DBI0594688837",
            "type": "images"
          }, {
            "id": "DBI9277127383",
            "type": "images"
          }, {
            "id": "DBI2276614068",
            "type": "images"
          }, {
            "id": "DBI5318111693",
            "type": "images"
          }, {
            "id": "DBI8718999667",
            "type": "images"
          }, {
            "id": "DBI4710216361",
            "type": "images"
          }, {
            "id": "DBI3906141433",
            "type": "images"
          }, {
            "id": "DBI9061023848",
            "type": "images"
          }, {
            "id": "DBI8345668816",
            "type": "images"
          }, {
            "id": "DBI8041148280",
            "type": "images"
          }, {
            "id": "DBI1379195719",
            "type": "images"
          }]
        },
        "videos": {
          "data": [{
            "id": "DBV6583721828",
            "type": "videos"
          }, {
            "id": "DBV7424430429",
            "type": "videos"
          }, {
            "id": "DBV1718620960",
            "type": "videos"
          }, {
            "id": "DBV3152076988",
            "type": "videos"
          }, {
            "id": "DBV1751223265",
            "type": "videos"
          }, {
            "id": "DBV7925721287",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        },
        "attachments": {
          "data": []
        },
        "ortho_photo_maps": {
          "data": []
        },
        "shares": {
          "data": [{
            "id": "598c65ee7c933aeeef97598352014674",
            "type": "shares"
          }]
        },
        "main_image": {
          "data": {
            "id": "DBI9327499775",
            "type": "images"
          }
        },
        "collaborators": {
          "data": []
        },
        "parent": {
          "data": null
        },
        "shots": {
          "data": [{
            "id": "1059981",
            "type": "shots"
          }, {
            "id": "1059980",
            "type": "shots"
          }, {
            "id": "1059979",
            "type": "shots"
          }, {
            "id": "1059978",
            "type": "shots"
          }, {
            "id": "1059977",
            "type": "shots"
          }, {
            "id": "1059976",
            "type": "shots"
          }, {
            "id": "1059975",
            "type": "shots"
          }, {
            "id": "1059974",
            "type": "shots"
          }, {
            "id": "1059973",
            "type": "shots"
          }]
        },
        "invoice_line_items": {
          "data": [{
            "id": "DBINV783211513519392",
            "type": "invoice_line_items"
          }]
        }
      }
    },
    "included": [{
      "id": "284849",
      "type": "locations",
      "attributes": {
        "name": "19 Misty Vale Rd, Sandy Hook, CT 06482, USA",
        "latitude": 41.4123638,
        "longitude": -73.2534763,
        "address": "19 Misty Vale Road",
        "address2": null,
        "city": "Newtown",
        "state": "Connecticut",
        "postal_code": "06482",
        "country": "United States",
        "formatted_address": "19 Misty Vale Rd, Sandy Hook, CT 06482, USA",
        "timezone_id": "America/New_York",
        "properties": {}
      }
    }, {
      "id": "20040",
      "type": "packages",
      "attributes": {
        "name": "RRE Pro Package Sample ",
        "description": null,
        "price": 36900,
        "cloud_reqs": "less_than_50",
        "position": null,
        "slug": "rre-pro-pack"
      },
      "relationships": {
        "vertical": {
          "data": {
            "id": "2",
            "type": "verticals"
          }
        },
        "organization": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "catalog_items": {
          "data": []
        }
      }
    }, {
      "id": "2",
      "type": "verticals",
      "attributes": {
        "name": "Residential Real Estate",
        "short_name": "RRE"
      }
    }, {
      "id": "49448",
      "type": "onsite_contacts",
      "attributes": {
        "name": null,
        "call_action": null,
        "phone": null,
        "note": null
      }
    }, {
      "id": "DBC3006554",
      "type": "clients",
      "attributes": {
        "first_name": "Demo",
        "last_name": "Zeitview",
        "phone": "",
        "email": "clients@zeitview.com",
        "token": "qC65CqCmbKfMZvIU2gRb_uD7Sqw",
        "company_name": null,
        "invoiceable": false
      },
      "relationships": {
        "hidden_sample_missions": {
          "data": [{
            "id": "*",
            "type": "hidden_sample_mission"
          }]
        }
      }
    }, {
      "id": "DBI9327499775",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10003595-43c347a2170f2fe2a8b1e0088b8713aa1a52b568/original-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
        "name": "DBI-15-6R5X3MLX-DBUV011-DBM0011938845-100-06.JPG",
        "source_type": "edit",
        "formatted_size": "4.67 MB",
        "size": 4894254,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355383333333,
        "gps_longitude": -73.395783333333,
        "gps_altitude": 142.2,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10003595-43c347a2170f2fe2a8b1e0088b8713aa1a52b568/large_1920-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10003595-43c347a2170f2fe2a8b1e0088b8713aa1a52b568/medium_1024-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10003595-43c347a2170f2fe2a8b1e0088b8713aa1a52b568/small_640-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10003595-43c347a2170f2fe2a8b1e0088b8713aa1a52b568/four_three_640-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10003595-43c347a2170f2fe2a8b1e0088b8713aa1a52b568/square_640-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10003595-43c347a2170f2fe2a8b1e0088b8713aa1a52b568/downloadable-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG"
        },
        "created_on": "2019-12-02T23:29:09Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059974",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2151789455",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10003591-3fb562c41eb378eee9b39c83f529306030131dd5/original-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
        "name": "DBI-14-voTtuUuW-DBU14-DBM0011938845_100_08.JPG",
        "source_type": "edit",
        "formatted_size": "4.52 MB",
        "size": 4737475,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355919444444,
        "gps_longitude": -73.396211111111,
        "gps_altitude": 157.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10003591-3fb562c41eb378eee9b39c83f529306030131dd5/large_1920-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10003591-3fb562c41eb378eee9b39c83f529306030131dd5/medium_1024-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10003591-3fb562c41eb378eee9b39c83f529306030131dd5/small_640-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10003591-3fb562c41eb378eee9b39c83f529306030131dd5/four_three_640-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10003591-3fb562c41eb378eee9b39c83f529306030131dd5/square_640-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10003591-3fb562c41eb378eee9b39c83f529306030131dd5/downloadable-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG"
        },
        "created_on": "2019-12-02T23:29:04Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059974",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0227293475",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10003589-6ff1f345f9add789c8a6b7919e6f80c40cf48a18/original-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
        "name": "DBI-13-woYl5ftp-DBU13-DBM0011938845_100_07.JPG",
        "source_type": "edit",
        "formatted_size": "4.42 MB",
        "size": 4638336,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355772222222,
        "gps_longitude": -73.396416666667,
        "gps_altitude": 145.3,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10003589-6ff1f345f9add789c8a6b7919e6f80c40cf48a18/large_1920-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10003589-6ff1f345f9add789c8a6b7919e6f80c40cf48a18/medium_1024-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10003589-6ff1f345f9add789c8a6b7919e6f80c40cf48a18/small_640-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10003589-6ff1f345f9add789c8a6b7919e6f80c40cf48a18/four_three_640-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10003589-6ff1f345f9add789c8a6b7919e6f80c40cf48a18/square_640-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10003589-6ff1f345f9add789c8a6b7919e6f80c40cf48a18/downloadable-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG"
        },
        "created_on": "2019-12-02T23:29:02Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059974",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3029037527",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10003588-f810073f487944ce9a786a83525015c3f649c45d/original-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
        "name": "DBI-12-6gkXpGpj-DBU11-DBM0011938845_100_05.JPG",
        "source_type": "edit",
        "formatted_size": "4.63 MB",
        "size": 4855984,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355555555556,
        "gps_longitude": -73.396355555556,
        "gps_altitude": 142,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10003588-f810073f487944ce9a786a83525015c3f649c45d/large_1920-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10003588-f810073f487944ce9a786a83525015c3f649c45d/medium_1024-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10003588-f810073f487944ce9a786a83525015c3f649c45d/small_640-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10003588-f810073f487944ce9a786a83525015c3f649c45d/four_three_640-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10003588-f810073f487944ce9a786a83525015c3f649c45d/square_640-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10003588-f810073f487944ce9a786a83525015c3f649c45d/downloadable-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG"
        },
        "created_on": "2019-12-02T23:29:00Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059974",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0594688837",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10003587-bb302bb50b3bf8f0546ea5c6e282e236b1282f6e/original-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
        "name": "DBI-11-G7u1d73J-DBU10-DBM0011938845_100_04.JPG",
        "source_type": "edit",
        "formatted_size": "4.56 MB",
        "size": 4781833,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.3555,
        "gps_longitude": -73.396011111111,
        "gps_altitude": 138.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10003587-bb302bb50b3bf8f0546ea5c6e282e236b1282f6e/large_1920-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10003587-bb302bb50b3bf8f0546ea5c6e282e236b1282f6e/medium_1024-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10003587-bb302bb50b3bf8f0546ea5c6e282e236b1282f6e/small_640-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10003587-bb302bb50b3bf8f0546ea5c6e282e236b1282f6e/four_three_640-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10003587-bb302bb50b3bf8f0546ea5c6e282e236b1282f6e/square_640-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10003587-bb302bb50b3bf8f0546ea5c6e282e236b1282f6e/downloadable-522213e7e7d5eb7e7652b936adf67054e121094a.JPG"
        },
        "created_on": "2019-12-02T23:28:57Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059974",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9277127383",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10003584-2339dab88c981e9217684d9586f92ef86a045094/original-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
        "name": "DBI-10-ZQ6wCMtf-DBU9-DBM0011938845_100_03.JPG",
        "source_type": "edit",
        "formatted_size": "4.51 MB",
        "size": 4726912,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355333333333,
        "gps_longitude": -73.396083333333,
        "gps_altitude": 140.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10003584-2339dab88c981e9217684d9586f92ef86a045094/large_1920-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10003584-2339dab88c981e9217684d9586f92ef86a045094/medium_1024-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10003584-2339dab88c981e9217684d9586f92ef86a045094/small_640-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10003584-2339dab88c981e9217684d9586f92ef86a045094/four_three_640-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10003584-2339dab88c981e9217684d9586f92ef86a045094/square_640-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10003584-2339dab88c981e9217684d9586f92ef86a045094/downloadable-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG"
        },
        "created_on": "2019-12-02T23:28:56Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059974",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2276614068",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10003573-10860828b509be50792e535dba24a3db4159edc3/original-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
        "name": "DBI-9-JDzxJmc4-DBU8-DBM0011938845_100_02.JPG",
        "source_type": "edit",
        "formatted_size": "4.37 MB",
        "size": 4582821,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355719444444,
        "gps_longitude": -73.395705555556,
        "gps_altitude": 123.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10003573-10860828b509be50792e535dba24a3db4159edc3/large_1920-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10003573-10860828b509be50792e535dba24a3db4159edc3/medium_1024-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10003573-10860828b509be50792e535dba24a3db4159edc3/small_640-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10003573-10860828b509be50792e535dba24a3db4159edc3/four_three_640-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10003573-10860828b509be50792e535dba24a3db4159edc3/square_640-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10003573-10860828b509be50792e535dba24a3db4159edc3/downloadable-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG"
        },
        "created_on": "2019-12-02T23:28:42Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059974",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI5318111693",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10003571-9419ff147ccdb9e9f516770e86ffc6733a8601bc/original-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
        "name": "DBI-8-FoKEy7gP-DBU7-DBM0011938845_100_01.JPG",
        "source_type": "edit",
        "formatted_size": "4.42 MB",
        "size": 4638672,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355897222222,
        "gps_longitude": -73.396075,
        "gps_altitude": 149.3,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10003571-9419ff147ccdb9e9f516770e86ffc6733a8601bc/large_1920-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10003571-9419ff147ccdb9e9f516770e86ffc6733a8601bc/medium_1024-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10003571-9419ff147ccdb9e9f516770e86ffc6733a8601bc/small_640-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10003571-9419ff147ccdb9e9f516770e86ffc6733a8601bc/four_three_640-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10003571-9419ff147ccdb9e9f516770e86ffc6733a8601bc/square_640-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10003571-9419ff147ccdb9e9f516770e86ffc6733a8601bc/downloadable-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG"
        },
        "created_on": "2019-12-02T23:28:36Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059974",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8718999667",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10003568-545a5b7a88d546e061b36a401be778fdb06e8912/original-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
        "name": "DBI-7-tGTQU7t0-DBU15-DBM0011938845_Birds_Eye.JPG",
        "source_type": "edit",
        "formatted_size": "4.58 MB",
        "size": 4801035,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355583333333,
        "gps_longitude": -73.396008333333,
        "gps_altitude": 216.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10003568-545a5b7a88d546e061b36a401be778fdb06e8912/large_1920-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10003568-545a5b7a88d546e061b36a401be778fdb06e8912/medium_1024-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10003568-545a5b7a88d546e061b36a401be778fdb06e8912/small_640-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10003568-545a5b7a88d546e061b36a401be778fdb06e8912/four_three_640-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10003568-545a5b7a88d546e061b36a401be778fdb06e8912/square_640-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10003568-545a5b7a88d546e061b36a401be778fdb06e8912/downloadable-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG"
        },
        "created_on": "2019-12-02T23:28:32Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059973",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4710216361",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10003563-7689715397ed26a3cf1890641b0b9356f0714c90/original-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
        "name": "DBI-6-aK0ftEW8-DBU6-DBM0011938845_10_06.JPG",
        "source_type": "edit",
        "formatted_size": "4.5 MB",
        "size": 4718345,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355719444444,
        "gps_longitude": -73.396205555556,
        "gps_altitude": 119.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10003563-7689715397ed26a3cf1890641b0b9356f0714c90/large_1920-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10003563-7689715397ed26a3cf1890641b0b9356f0714c90/medium_1024-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10003563-7689715397ed26a3cf1890641b0b9356f0714c90/small_640-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10003563-7689715397ed26a3cf1890641b0b9356f0714c90/four_three_640-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10003563-7689715397ed26a3cf1890641b0b9356f0714c90/square_640-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10003563-7689715397ed26a3cf1890641b0b9356f0714c90/downloadable-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG"
        },
        "created_on": "2019-12-02T23:28:25Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059973",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3906141433",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10003558-cf8bc0f379e8660e160c830414879e9464b6382c/original-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
        "name": "DBI-5-onves8uX-DBU5-DBM0011938845_10_05.JPG",
        "source_type": "edit",
        "formatted_size": "4.35 MB",
        "size": 4565311,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355588888889,
        "gps_longitude": -73.396216666667,
        "gps_altitude": 119.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10003558-cf8bc0f379e8660e160c830414879e9464b6382c/large_1920-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10003558-cf8bc0f379e8660e160c830414879e9464b6382c/medium_1024-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10003558-cf8bc0f379e8660e160c830414879e9464b6382c/small_640-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10003558-cf8bc0f379e8660e160c830414879e9464b6382c/four_three_640-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10003558-cf8bc0f379e8660e160c830414879e9464b6382c/square_640-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10003558-cf8bc0f379e8660e160c830414879e9464b6382c/downloadable-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG"
        },
        "created_on": "2019-12-02T23:28:17Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059973",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9061023848",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10003556-915529cd8549b544158ffbe85988533c61e0975e/original-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
        "name": "DBI-4-VfJ905Y1-DBU4-DBM0011938845_10_04.JPG",
        "source_type": "edit",
        "formatted_size": "4.34 MB",
        "size": 4550027,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355433333333,
        "gps_longitude": -73.396030555556,
        "gps_altitude": 116.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10003556-915529cd8549b544158ffbe85988533c61e0975e/large_1920-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10003556-915529cd8549b544158ffbe85988533c61e0975e/medium_1024-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10003556-915529cd8549b544158ffbe85988533c61e0975e/small_640-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10003556-915529cd8549b544158ffbe85988533c61e0975e/four_three_640-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10003556-915529cd8549b544158ffbe85988533c61e0975e/square_640-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10003556-915529cd8549b544158ffbe85988533c61e0975e/downloadable-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG"
        },
        "created_on": "2019-12-02T23:28:13Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059973",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8345668816",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10003552-d231df576506823249f1ccf000ad087126f2cb35/original-91116a8f05403c970ead6159446a774f35a328aa.JPG",
        "name": "DBI-3-cQTalaXj-DBU3-DBM0011938845_10_03.JPG",
        "source_type": "edit",
        "formatted_size": "4.3 MB",
        "size": 4506834,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355472222222,
        "gps_longitude": -73.395783333333,
        "gps_altitude": 116.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10003552-d231df576506823249f1ccf000ad087126f2cb35/large_1920-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10003552-d231df576506823249f1ccf000ad087126f2cb35/medium_1024-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10003552-d231df576506823249f1ccf000ad087126f2cb35/small_640-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10003552-d231df576506823249f1ccf000ad087126f2cb35/four_three_640-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10003552-d231df576506823249f1ccf000ad087126f2cb35/square_640-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10003552-d231df576506823249f1ccf000ad087126f2cb35/downloadable-91116a8f05403c970ead6159446a774f35a328aa.JPG"
        },
        "created_on": "2019-12-02T23:28:08Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059973",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8041148280",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10003548-149eee00d843dcaae98984acf07443a1fcfc374d/original-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
        "name": "DBI-2-DRQUxUvb-DBU2-DBM0011938845_10_02.JPG",
        "source_type": "edit",
        "formatted_size": "4.06 MB",
        "size": 4257980,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355738888889,
        "gps_longitude": -73.395716666667,
        "gps_altitude": 115.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10003548-149eee00d843dcaae98984acf07443a1fcfc374d/large_1920-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10003548-149eee00d843dcaae98984acf07443a1fcfc374d/medium_1024-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10003548-149eee00d843dcaae98984acf07443a1fcfc374d/small_640-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10003548-149eee00d843dcaae98984acf07443a1fcfc374d/four_three_640-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10003548-149eee00d843dcaae98984acf07443a1fcfc374d/square_640-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10003548-149eee00d843dcaae98984acf07443a1fcfc374d/downloadable-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG"
        },
        "created_on": "2019-12-02T23:28:03Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059973",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI1379195719",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10003542-8c7135b80e8bb832e5e6fdb91e13a8a358b57ff5/original-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
        "name": "DBI-1-GzhKy0fY-DBU1-DBM0011938845_10_01.JPG",
        "source_type": "edit",
        "formatted_size": "4.22 MB",
        "size": 4422001,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355761111111,
        "gps_longitude": -73.396094444445,
        "gps_altitude": 115.9,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10003542-8c7135b80e8bb832e5e6fdb91e13a8a358b57ff5/large_1920-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10003542-8c7135b80e8bb832e5e6fdb91e13a8a358b57ff5/medium_1024-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10003542-8c7135b80e8bb832e5e6fdb91e13a8a358b57ff5/small_640-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10003542-8c7135b80e8bb832e5e6fdb91e13a8a358b57ff5/four_three_640-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10003542-8c7135b80e8bb832e5e6fdb91e13a8a358b57ff5/square_640-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10003542-8c7135b80e8bb832e5e6fdb91e13a8a358b57ff5/downloadable-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG"
        },
        "created_on": "2019-12-02T23:27:59Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059973",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV6583721828",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-22-YxORNh4T-RREProSample.mp4",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "3920eb113e3417c281dafcd8ed999f17",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174877-be03fdb815e0e9b7f083b9b6710051837e1908fc/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/174877-be03fdb815e0e9b7f083b9b6710051837e1908fc/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174877-be03fdb815e0e9b7f083b9b6710051837e1908fc/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174877-be03fdb815e0e9b7f083b9b6710051837e1908fc/DL-DBV-22-YxORNh4T-RREProSample.mp4"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059980",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV7424430429",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-20-KmXrBSOF-DL-DBUV000-DBM0011938845-Custom.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174335-ad09bbde5dabf03e09b505ec164cea9bfd274eb7/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174335-ad09bbde5dabf03e09b505ec164cea9bfd274eb7/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174335-ad09bbde5dabf03e09b505ec164cea9bfd274eb7/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174335-ad09bbde5dabf03e09b505ec164cea9bfd274eb7/DL-DBV-20-KmXrBSOF-DL-DBUV000-DBM0011938845-Custom.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059979",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV1718620960",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-19-O1b1gt7K-DL-DBUV003-DBM0011938845-Rise--Reverse.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174334-f70159eb001dd6d3fbe40e83d816d8391c7c23a8/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174334-f70159eb001dd6d3fbe40e83d816d8391c7c23a8/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174334-f70159eb001dd6d3fbe40e83d816d8391c7c23a8/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174334-f70159eb001dd6d3fbe40e83d816d8391c7c23a8/DL-DBV-19-O1b1gt7K-DL-DBUV003-DBM0011938845-Rise--Reverse.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059978",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV3152076988",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-18-BenSHCed-DL-DBUV002-DBM0011938845-Orbit.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174333-d666ad210c4c2bc5c610d67ce41c0a61a5240965/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174333-d666ad210c4c2bc5c610d67ce41c0a61a5240965/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174333-d666ad210c4c2bc5c610d67ce41c0a61a5240965/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174333-d666ad210c4c2bc5c610d67ce41c0a61a5240965/DL-DBV-18-BenSHCed-DL-DBUV002-DBM0011938845-Orbit.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059977",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV1751223265",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-17-dGGLlrNH-DL-DBUV004-DBM0011938845-Trucking.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174332-200df316ab0c67f4eb5f3d2583b1889239eef619/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174332-200df316ab0c67f4eb5f3d2583b1889239eef619/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174332-200df316ab0c67f4eb5f3d2583b1889239eef619/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174332-200df316ab0c67f4eb5f3d2583b1889239eef619/DL-DBV-17-dGGLlrNH-DL-DBUV004-DBM0011938845-Trucking.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059976",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV7925721287",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-16-8iORuROS-DL-DBUV001-DBM0011938845-Dolly.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174331-0607dbed4e021a55f718fed9a7cf5f3c019f3ab2/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174331-0607dbed4e021a55f718fed9a7cf5f3c019f3ab2/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174331-0607dbed4e021a55f718fed9a7cf5f3c019f3ab2/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174331-0607dbed4e021a55f718fed9a7cf5f3c019f3ab2/DL-DBV-16-8iORuROS-DL-DBUV001-DBM0011938845-Dolly.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059975",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "598c65ee7c933aeeef97598352014674",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-05T06:40:08Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "1059981",
      "type": "shots",
      "attributes": {
        "instructions": "(1) image from from above looking straight down at the property with the full home/lot in frame. ",
        "mission_id": "277979",
        "shot_type_id": 18,
        "pilot_comment": "Not here.",
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Image - Birds Eye "
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "18",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "18",
      "type": "shot_types",
      "attributes": {
        "name": "Image - Birds Eye ",
        "description": "",
        "video": null,
        "slug": "image-birds-eye-photo-looking-straight-down-entire-property-in-frame",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "12",
            "type": "shot_type_sample_images"
          }, {
            "id": "13",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1059980",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "277979",
        "shot_type_id": 22,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Final Assets"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "22",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV6583721828",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "22",
      "type": "shot_types",
      "attributes": {
        "name": "Final Assets",
        "description": "Use this shot to upload final edited assets for the customer. Not for use by pilots.\n",
        "video": null,
        "slug": "final-assets",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1059979",
      "type": "shots",
      "attributes": {
        "instructions": "Cinematic Video clip (of pool, back yard, etc.)",
        "mission_id": "277979",
        "shot_type_id": 11,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Custom Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "11",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV7424430429",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "11",
      "type": "shot_types",
      "attributes": {
        "name": "Custom Shot",
        "description": "",
        "video": "",
        "slug": "custom-shot",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1059978",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: Rise + Reverse Away facing the front of the subject property.  \n",
        "mission_id": "277979",
        "shot_type_id": 10,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Rise + Reverse Away Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "10",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV1718620960",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "10",
      "type": "shot_types",
      "attributes": {
        "name": "Rise + Reverse Away Shot",
        "description": "",
        "video": "https://youtu.be/PwOvzZqQWkg",
        "slug": "rise-reverse-away-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1059977",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Contextual Orbit shot around property (counterclockwise)  \n*Must have entire subject property in frame and your camera must be angled up enough so that we also have the horizon in frame.* The point is to provide context of the property in relation to the surrounding area. This will require you to fly at a higher altitude and farther off the property to get the required shot. Typically shot around 100 ft. Orbit should be slow + steady. No jerky camera movements.  \n",
        "mission_id": "277979",
        "shot_type_id": 8,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Orbit Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "8",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV3152076988",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "8",
      "type": "shot_types",
      "attributes": {
        "name": "Orbit Shot",
        "description": "",
        "video": "https://youtu.be/MzdF92gWXyU",
        "slug": "orbit-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1059976",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Truck (*right to left*) across the front of the property. The front of the property should take up the frame from ground to top of roof. Adjust distance accordingly.  \n",
        "mission_id": "277979",
        "shot_type_id": 2,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Trucking Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "2",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV1751223265",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "2",
      "type": "shot_types",
      "attributes": {
        "name": "Trucking Shot",
        "description": "",
        "video": "https://youtu.be/m-r79WAcDNs",
        "slug": "trucking-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1059975",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Slowly fly towards the front of the property at a constant altitude (10 feet over roof level) with your camera pointing straight (can have slight angle downward if needed). Keep the horizon flat. Fly completely over property and the end of the property lot.  \n",
        "mission_id": "277979",
        "shot_type_id": 1,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Dolly Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "1",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV7925721287",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "shot_types",
      "attributes": {
        "name": "Dolly Shot",
        "description": "",
        "video": "https://youtu.be/H80sEIUHa7Y",
        "slug": "dolly-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1059974",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "277979",
        "shot_type_id": 13,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (100 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "13",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI2276614068",
            "type": "images"
          }, {
            "id": "DBI0594688837",
            "type": "images"
          }, {
            "id": "DBI3029037527",
            "type": "images"
          }, {
            "id": "DBI0227293475",
            "type": "images"
          }, {
            "id": "DBI9327499775",
            "type": "images"
          }, {
            "id": "DBI2151789455",
            "type": "images"
          }, {
            "id": "DBI9277127383",
            "type": "images"
          }, {
            "id": "DBI5318111693",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "13",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (100 ft above ground level)",
        "description": "",
        "video": "",
        "slug": "images-cardinal-directions-100-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "17",
            "type": "shot_type_sample_images"
          }, {
            "id": "18",
            "type": "shot_type_sample_images"
          }, {
            "id": "19",
            "type": "shot_type_sample_images"
          }, {
            "id": "20",
            "type": "shot_type_sample_images"
          }, {
            "id": "21",
            "type": "shot_type_sample_images"
          }, {
            "id": "22",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1059973",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and you may need to adjust altitude as you work around obstacles (trees, power lines, etc).*  \n",
        "mission_id": "277979",
        "shot_type_id": 12,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (10 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "12",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI1379195719",
            "type": "images"
          }, {
            "id": "DBI8041148280",
            "type": "images"
          }, {
            "id": "DBI8718999667",
            "type": "images"
          }, {
            "id": "DBI4710216361",
            "type": "images"
          }, {
            "id": "DBI3906141433",
            "type": "images"
          }, {
            "id": "DBI8345668816",
            "type": "images"
          }, {
            "id": "DBI9061023848",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "12",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (10 ft above ground level)",
        "description": "*If there are power lines do your best to avoid having them in frame. It is okay to fly higher or lower than roof level to avoid the power lines in the images.* ",
        "video": "",
        "slug": "images-cardinal-directions-10-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "35",
            "type": "shot_type_sample_images"
          }, {
            "id": "36",
            "type": "shot_type_sample_images"
          }, {
            "id": "37",
            "type": "shot_type_sample_images"
          }, {
            "id": "38",
            "type": "shot_type_sample_images"
          }, {
            "id": "39",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "DBINV783211513519392",
      "type": "invoice_line_items",
      "attributes": {
        "reason": "base_package_price",
        "description": null,
        "amount": 36900,
        "created_on": "2019-12-02T23:26:15Z",
        "display_name": "Base Package Price"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM3351507871",
            "type": "missions"
          }
        },
        "work_item": {
          "data": null
        }
      }
    }]
  };
  _exports.default = _default;
});