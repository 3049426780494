define("clients/services/utm-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),

    track(event_captured, handler) {
      let record;
      let utm_vars = {
        "campaign": this.cookies.read("utm_campaign"),
        "term": this.cookies.read("utm_term"),
        "medium": this.cookies.read("utm_medium"),
        "content": this.cookies.read("utm_content"),
        "source": this.cookies.read("utm_source")
      };
      record = this.store.createRecord('utm-event', Ember.assign({
        "event_captured": event_captured
      }, utm_vars));
      let promise = record.save();

      if (handler) {
        promise.then(handler).catch(handler);
      }

      return promise;
    }

  });

  _exports.default = _default;
});