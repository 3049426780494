define("clients/data/sample_mission_list", ["exports", "clients/data/sample_missions/introductory_sample_mission"], function (_exports, _introductory_sample_mission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cre-sample-1": _introductory_sample_mission.default
  };
  _exports.default = _default;
});