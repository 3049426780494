define("clients/components/order-flow/flow-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedFlow: Ember.computed.reads('model.orderFlow'),
    actions: {
      setOrderFlow(orderFlow) {
        return this.setOrderFlow(orderFlow);
      },

      increaseOrderStep() {
        return this.increaseOrderStepAction();
      }

    }
  });

  _exports.default = _default;
});