define("clients/data/sample_mission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "type": "FeatureCollection",
    "features": [{
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [[[-122.02780103041073, 37.33401715997264], [-122.02999169577026, 37.333859342911005], [-122.03203215742502, 37.33361621868083], [-122.03187122488413, 37.331744185594594], [-122.03078662104036, 37.33162901834106], [-122.0304315780487, 37.33064369128805], [-122.0278868610992, 37.33043041533282], [-122.02780103041073, 37.33401715997264]]]
      },
      "properties": {
        "type": "Property Area",
        "name": "Apple HQ - Infinite Loop",
        "notes": "We'd like to create a marketing video showcasing Apple HQ and the surrounding parking area from the air. \n\nThere are many features to our property that we'd like to showcase. ",
        "area": 118561,
        "bounds": {
          "Ia": {
            "G": 37.33043041533282,
            "j": 37.33401715997264
          },
          "Ca": {
            "j": -122.03203215742502,
            "G": -122.02780103041073
          }
        },
        "center": {
          "G": 37.33222378765273,
          "K": -122.02991659391785
        }
      },
      "id": 1
    }, {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [[[-122.03092312170406, 37.331594417425194], [-122.03194632673643, 37.33157735558697], [-122.03194632673643, 37.33137735681337], [-122.0319745476379, 37.331309109240046], [-122.03197186542889, 37.33043349580932], [-122.0313428874149, 37.33043077009093], [-122.0307139094009, 37.33063278930754], [-122.03092312170406, 37.331594417425194]]]
      },
      "properties": {
        "type": "No Fly Zone",
        "name": "Not our property",
        "notes": "This is not Apple HQ property",
        "area": 12661,
        "bounds": {
          "Ia": {
            "G": 37.33043077009093,
            "j": 37.331594417425194
          },
          "Ca": {
            "j": -122.0319745476379,
            "G": -122.0307139094009
          }
        },
        "center": {
          "G": 37.33101259375806,
          "K": -122.0313442285194
        }
      },
      "id": 2
    }, {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-122.0296912883606, 37.33186124713385]
      },
      "properties": {
        "type": "Point of Interest",
        "name": "Courtyard",
        "notes": "Please highlight the main courtyard in the middle of our property"
      },
      "id": 3
    }, {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-122.02970201719666, 37.33338826053761]
      },
      "properties": {
        "type": "Point of Interest",
        "name": "Loop",
        "notes": "Please do an orbit around our actual buildings following the street - Infinite Loop"
      },
      "id": 4
    }, {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-122.02808196295166, 37.329336061895006]
      },
      "properties": {
        "type": "Point of Interest",
        "name": "Lawson Middle School",
        "notes": "From our property, please capture Lawson Middle School in the distance. This will help us showcase the many features in the surrounding neighborhood (including school)."
      },
      "id": 5
    }, {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-122.0314937328186, 37.33093137460447]
      },
      "properties": {
        "type": "Point of Interest",
        "name": "BJs",
        "notes": "From the property, please showcase retail around our area showing that there are many features"
      },
      "id": 6
    }, {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-122.02779228437805, 37.33592183999838]
      },
      "properties": {
        "type": "Point of Interest",
        "name": "Neighborhood",
        "notes": "From our property please showcase the availability of homes + neighborhoods from our property"
      },
      "id": 7
    }]
  };
  _exports.default = _default;
});