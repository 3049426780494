define("clients/routes/integrations", ["exports", "clients/mixins/inner-application-route-setup-controller-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _innerApplicationRouteSetupControllerMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _innerApplicationRouteSetupControllerMixin.default, {
    sessionAccount: Ember.inject.service(),
    model: function () {
      return Ember.RSVP.hash({
        account: this.sessionAccount.account
      });
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      this.get('sessionAccount.account').then(client => {
        this.controllerFor('application').set('showInsightsBanner', client.get('feature_flags.show_insights_banner'));
        this.controllerFor('application').set('clientHasComposerReports', client.get('has_composer_reports'));
        this.controllerFor('application').set('canManageInsightsUsers', this.sessionAccount.canManageInsightsUsers);
      });
    }
  });

  _exports.default = _default;
});