define("clients/components/share/share-panoramas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activePanorama: Ember.computed.reads('panoramas.firstObject'),
    actions: {
      selectPanorama: function (panorama) {
        this.set('activePanorama', panorama);
      }
    }
  });

  _exports.default = _default;
});