define("clients/components/ortho-viewer", ["exports", "clients/mixins/google-map-init-mixin"], function (_exports, _googleMapInitMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_googleMapInitMixin.default, {
    initMap: function () {
      let options = {
        zoom: 17,
        tilt: 0,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        mapTypeControl: false,
        streetViewControl: false,
        scaleControl: false,
        panControl: false,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.LARGE,
          position: google.maps.ControlPosition.RIGHT_CENTER
        },
        draggable: true,
        disableDoubleClickZoom: false
      };

      if (this.view == 'asset-item-thumb') {
        options.fullscreenControl = false;
        options.zoomControl = false;
      }

      if (!this.map) {
        this.map = new google.maps.Map(document.getElementById('ortho' + this.get('ortho.shot.id')), options);
      }

      let latLng;

      if (this.get('ortho.mission.location.latitude') && this.get('ortho.mission.location.longitude')) {
        latLng = this.buildLatLng(this.get('ortho.mission.location.latitude'), this.get('ortho.mission.location.longitude'));
      } else {
        latLng = new google.maps.LatLng(37.2350, -115.8111);
      }

      this.map.setCenter(latLng);
      const TILES_LINKS = this.get('ortho.tiles_link') + "{z}/{x}/{y}.png";
      var CoordMapType;

      CoordMapType = function (tileSize) {
        this.tileSize = tileSize;
      };

      CoordMapType.prototype.getTile = function (coord, zoom, ownerDocument) {
        var doNothing, img, src;

        doNothing = function (err) {
          var img;
          img = err.target;
          img.src = "";
          img.style.display = 'none';
          return false;
        };

        src = TILES_LINKS.replace('{x}', coord.x).replace('{y}', Math.pow(2, zoom) - coord.y - 1).replace('{z}', zoom);
        img = ownerDocument.createElement('img');
        img.style.width = this.tileSize.width + 'px';
        img.style.height = this.tileSize.height + 'px';
        img.src = src;
        img.onerror = doNothing;
        img.onError = doNothing;
        return img;
      };

      this.map.overlayMapTypes.insertAt(0, new CoordMapType(new google.maps.Size(256, 256)));
    },
    buildLatLng: function (lat, lng) {
      lat = Number(lat);
      lng = Number(lng);
      return new google.maps.LatLng({
        lat: lat,
        lng: lng
      });
    }
  });

  _exports.default = _default;
});