define("clients/routes/register", ["exports", "clients/mixins/css-class-namespace"], function (_exports, _cssClassNamespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cssClassNamespace.default, {
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),

    model() {
      return new Ember.RSVP.hash({
        client: this.store.createRecord('client', {
          signup_source: 'self_registered'
        }),
        verticals: this.store.findAll('vertical')
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      this.controllerFor('application').set('showNavBar', true);
      return this.controllerFor('application').set('minimizedSidebar', false);
    },

    actions: {
      willTransition() {
        return this.controllerFor('application').set('showNavBar', false);
      },

      register() {
        let attemptedTransition = this.session.attemptedTransition;

        if (attemptedTransition) {
          attemptedTransition.retry();
          return this.session.set('attemptedTransition', null);
        } else {
          this.controllerFor('application').set('showRegisterModal', true);
          this.sessionAccount.account.then(account => {
            this.controllerFor('application').set('model.account', account);
            this.transitionTo('dashboard.locations.index');
          });
        }
      }

    }
  });

  _exports.default = _default;
});