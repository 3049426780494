define("clients/mixins/css-class-namespace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CssClassNamespaceMixin;
  CssClassNamespaceMixin = Ember.Mixin.create({
    setupController: function (controller, model) {
      this._super(controller, model);

      return this.addCssNamespace();
    },
    deactivate: function () {
      this._super();

      return this.removeCssNamespace();
    },
    addCssNamespace: function () {
      return $('body').addClass(this.routeNameToCssClass());
    },
    removeCssNamespace: function () {
      return $('body').removeClass(this.routeNameToCssClass());
    },
    routeNameToCssClass: function () {
      return this.routeName.replace(/\./g, '-').dasherize();
    }
  });
  var _default = CssClassNamespaceMixin;
  _exports.default = _default;
});