define("clients/routes/account/update-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service(),
    model: function () {
      return this.sessionAccount.account;
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      this.controllerFor('application').set('showNavBar', true);
      this.controllerFor('application').set('hideSidebar', true);
      this.controllerFor('application').set('minimizedSidebar', false);
    },
    actions: {
      save: function (model) {
        model.save().then(() => {
          this.controllerFor('application').set('hideSidebar', false);
          this.transitionTo('dashboard.locations');
        });
      }
    }
  });

  _exports.default = _default;
});