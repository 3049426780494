define("clients/data/sample_missions/cre_pro_shopping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": {
      "id": "DBM7795792701",
      "type": "missions",
      "attributes": {
        "instructions": "* Scope: Aerial Images and Videos\n* Time: Between 11am - 2pm\n* Schedule: Within 48-72 hours; Weather permitting",
        "status": "complete",
        "client_id": "DBC3006554",
        "payment": {
          "id": null,
          "processor": null,
          "card": "Visa - 3969"
        },
        "external_assets_url": null,
        "owned_by_current_client": true,
        "contact_email": "clients@zeitview.com",
        "created_on": "2019-12-04T20:52:58Z",
        "is_pano_mission": false,
        "logo_url": null,
        "blurb": null,
        "paid": true,
        "paid_on": "2019-12-04T21:12:41Z",
        "price": 56900,
        "archive_url": null,
        "edit_url": null,
        "customer_instructions": "Weekend only. Fly between 12 - 3 pm\n",
        "scheduled_at_start": "2019-12-05T19:00:00Z",
        "scheduled_at_end": "2019-12-05T20:00:00Z",
        "parent_id": null,
        "reshoot_mission_id": null,
        "rejection_reason": null,
        "rejection_notes": null,
        "reference_id": "CRE Pro Package - Shopping Centers Sample\t",
        "cloud_reqs": "less_than_50",
        "scheduling_requirement": "not_needed",
        "arcgis_feature_layer": null,
        "geo_properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-84.686266139426, 34.03691790271], [-84.683874423804, 34.036428910564], [-84.68361156732, 34.037123469444], [-84.683134134116, 34.037231237951], [-84.683134134116, 34.037820182511], [-84.686073020377, 34.037882417011], [-84.686266139426, 34.03691790271]]]
            },
            "properties": {
              "name": "Polygon #1",
              "notes": "",
              "number": 1,
              "area": "34241.1",
              "bounds": {
                "south": 34.036428910564,
                "west": -84.686266139426,
                "north": 34.037882417011,
                "east": -84.683134134116
              },
              "center": {
                "lat": 34.037155663787,
                "lng": -84.684700136771
              }
            },
            "id": 1
          }]
        }
      },
      "relationships": {
        "location": {
          "data": {
            "id": "284946",
            "type": "locations"
          }
        },
        "package": {
          "data": {
            "id": "19165",
            "type": "packages"
          }
        },
        "credit_card": {
          "data": {
            "id": "4898",
            "type": "credit_cards"
          }
        },
        "onsite_contact": {
          "data": {
            "id": "49606",
            "type": "onsite_contacts"
          }
        },
        "scheduling_contact": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "images": {
          "data": [{
            "id": "DBI8597164825",
            "type": "images"
          }, {
            "id": "DBI8760574137",
            "type": "images"
          }, {
            "id": "DBI6642380667",
            "type": "images"
          }, {
            "id": "DBI8376309490",
            "type": "images"
          }, {
            "id": "DBI3685638867",
            "type": "images"
          }, {
            "id": "DBI9261501463",
            "type": "images"
          }, {
            "id": "DBI4080429092",
            "type": "images"
          }, {
            "id": "DBI5623489935",
            "type": "images"
          }, {
            "id": "DBI3311858014",
            "type": "images"
          }, {
            "id": "DBI1245210655",
            "type": "images"
          }, {
            "id": "DBI5179860475",
            "type": "images"
          }, {
            "id": "DBI5829111494",
            "type": "images"
          }, {
            "id": "DBI0738293409",
            "type": "images"
          }, {
            "id": "DBI8035671465",
            "type": "images"
          }, {
            "id": "DBI8170083453",
            "type": "images"
          }, {
            "id": "DBI5293278666",
            "type": "images"
          }, {
            "id": "DBI0356717204",
            "type": "images"
          }, {
            "id": "DBI1025313174",
            "type": "images"
          }, {
            "id": "DBI9457110968",
            "type": "images"
          }, {
            "id": "DBI7618504574",
            "type": "images"
          }, {
            "id": "DBI7679823879",
            "type": "images"
          }, {
            "id": "DBI6864505553",
            "type": "images"
          }, {
            "id": "DBI3494678406",
            "type": "images"
          }, {
            "id": "DBI4782590917",
            "type": "images"
          }, {
            "id": "DBI8221785687",
            "type": "images"
          }, {
            "id": "DBI4989095648",
            "type": "images"
          }]
        },
        "videos": {
          "data": [{
            "id": "DBV2856468233",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        },
        "attachments": {
          "data": []
        },
        "ortho_photo_maps": {
          "data": []
        },
        "shares": {
          "data": [{
            "id": "904e1eb10d52d41722adf67e03c4407f",
            "type": "shares"
          }]
        },
        "main_image": {
          "data": {
            "id": "DBI8597164825",
            "type": "images"
          }
        },
        "collaborators": {
          "data": []
        },
        "parent": {
          "data": null
        },
        "shots": {
          "data": [{
            "id": "1061113",
            "type": "shots"
          }, {
            "id": "1061112",
            "type": "shots"
          }, {
            "id": "1061111",
            "type": "shots"
          }, {
            "id": "1061110",
            "type": "shots"
          }, {
            "id": "1061109",
            "type": "shots"
          }]
        },
        "invoice_line_items": {
          "data": [{
            "id": "DBINV837868120540436",
            "type": "invoice_line_items"
          }]
        }
      }
    },
    "included": [{
      "id": "284946",
      "type": "locations",
      "attributes": {
        "name": "3384 Cobb Pkwy NW, Acworth, GA 30101, USA",
        "latitude": 34.0368356,
        "longitude": -84.6849469,
        "address": "3384 Cobb Parkway Northwest",
        "address2": "",
        "city": "Acworth",
        "state": "Georgia",
        "postal_code": "30101",
        "country": "United States",
        "formatted_address": "3384 Cobb Pkwy NW, Acworth, GA 30101, USA",
        "timezone_id": "America/New_York",
        "properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-84.686266139426, 34.03691790271], [-84.683874423804, 34.036428910564], [-84.68361156732, 34.037123469444], [-84.683134134116, 34.037231237951], [-84.683134134116, 34.037820182511], [-84.686073020377, 34.037882417011], [-84.686266139426, 34.03691790271]]]
            },
            "properties": {
              "name": "Polygon #1",
              "notes": "",
              "number": 1,
              "area": "34241.1",
              "bounds": {
                "south": 34.036428910564,
                "west": -84.686266139426,
                "north": 34.037882417011,
                "east": -84.683134134116
              },
              "center": {
                "lat": 34.037155663787,
                "lng": -84.684700136771
              }
            },
            "id": 1
          }]
        }
      }
    }, {
      "id": "19165",
      "type": "packages",
      "attributes": {
        "name": "Pro Package - Shopping Centers (Up to 400,000 sq ft)",
        "description": "Highlight your Shopping Center and the surrounding area with a 60-second edited video that includes branding, captions, contact info, and music (also includes unedited images & video).",
        "price": 56900,
        "cloud_reqs": "less_than_50",
        "position": 8,
        "slug": "cre-pro-pack-shopping-center"
      },
      "relationships": {
        "vertical": {
          "data": {
            "id": "1",
            "type": "verticals"
          }
        },
        "organization": {
          "data": null
        },
        "client": {
          "data": null
        },
        "catalog_items": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "verticals",
      "attributes": {
        "name": "Commercial Real Estate",
        "short_name": "CRE"
      }
    }, {
      "id": "4898",
      "type": "credit_cards",
      "attributes": {
        "brand": "Visa",
        "last_4": "3969",
        "exp_month": 2,
        "exp_year": 2024,
        "stripe_id": "card_1FW88bJo202eeWudwLnrPXPF"
      }
    }, {
      "id": "49606",
      "type": "onsite_contacts",
      "attributes": {
        "name": "John Smith",
        "call_action": "Call When You Arrive",
        "phone": "123-456-7890",
        "note": null
      }
    }, {
      "id": "DBC3006554",
      "type": "clients",
      "attributes": {
        "first_name": "Demo",
        "last_name": "Zeitview",
        "phone": "",
        "email": "clients@zeitview.com",
        "token": "qC65CqCmbKfMZvIU2gRb_uD7Sqw",
        "company_name": null,
        "invoiceable": false
      },
      "relationships": {
        "hidden_sample_missions": {
          "data": [{
            "id": "*",
            "type": "hidden_sample_mission"
          }]
        }
      }
    }, {
      "id": "DBI8597164825",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034112-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/original-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
        "name": "DBI-31-uSG9cLNH-CRE-Images-Package---Shopping-Centers-Sample_DBI-52-KjcTo8Vv-Lakeside-Marketplace_DBU93-DJI_0076.jpeg",
        "source_type": "edit",
        "formatted_size": "2.54 MB",
        "size": 2658148,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034112-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/large_1920-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034112-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/medium_1024-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034112-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/small_640-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034112-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/four_three_640-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034112-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/square_640-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034112-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/downloadable-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg"
        },
        "created_on": "2019-12-06T00:52:12Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061113",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8760574137",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034111-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/original-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
        "name": "DBI-30-0QuUnSKR-CRE-Images-Package---Shopping-Centers-Sample_DBI-58-9MohZxUx-Lakeside-Marketplace_DBU99-DJI_0088.jpeg",
        "source_type": "edit",
        "formatted_size": "2.49 MB",
        "size": 2609129,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034111-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/large_1920-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034111-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/medium_1024-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034111-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/small_640-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034111-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/four_three_640-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034111-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/square_640-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034111-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/downloadable-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg"
        },
        "created_on": "2019-12-06T00:52:12Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061113",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6642380667",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034110-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/original-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
        "name": "DBI-29-74eJjHKp-CRE-Images-Package---Shopping-Centers-Sample_DBI-49-GBkeyipm-Lakeside-Marketplace_DBU90-DJI_0073.jpeg",
        "source_type": "edit",
        "formatted_size": "2.49 MB",
        "size": 2608538,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034110-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/large_1920-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034110-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/medium_1024-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034110-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/small_640-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034110-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/four_three_640-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034110-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/square_640-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034110-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/downloadable-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg"
        },
        "created_on": "2019-12-06T00:52:12Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061113",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8376309490",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034109-76eb74c083f750365ded847e5c017e4ac68cc868/original-12ed543fd505bfe44d19beeb9ea970d13fadcc95.jpeg",
        "name": "DBI-28-5bts7zq4-CRE-Images-Package---Shopping-Centers-Sample_DBI-38-GTIEV2gg-Lakeside-Marketplace_DBU79-DJI_0059.jpeg",
        "source_type": "edit",
        "formatted_size": "1.69 MB",
        "size": 1771909,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034109-76eb74c083f750365ded847e5c017e4ac68cc868/large_1920-12ed543fd505bfe44d19beeb9ea970d13fadcc95.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034109-76eb74c083f750365ded847e5c017e4ac68cc868/medium_1024-12ed543fd505bfe44d19beeb9ea970d13fadcc95.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034109-76eb74c083f750365ded847e5c017e4ac68cc868/small_640-12ed543fd505bfe44d19beeb9ea970d13fadcc95.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034109-76eb74c083f750365ded847e5c017e4ac68cc868/four_three_640-12ed543fd505bfe44d19beeb9ea970d13fadcc95.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034109-76eb74c083f750365ded847e5c017e4ac68cc868/square_640-12ed543fd505bfe44d19beeb9ea970d13fadcc95.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034109-76eb74c083f750365ded847e5c017e4ac68cc868/downloadable-12ed543fd505bfe44d19beeb9ea970d13fadcc95.jpeg"
        },
        "created_on": "2019-12-06T00:52:11Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061113",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3685638867",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034108-76eb74c083f750365ded847e5c017e4ac68cc868/original-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
        "name": "DBI-27-QevIdklB-CRE-Images-Package---Shopping-Centers-Sample_DBI-53-1e11H3VD-Lakeside-Marketplace_DBU94-DJI_0077.jpeg",
        "source_type": "edit",
        "formatted_size": "2.42 MB",
        "size": 2540186,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034108-76eb74c083f750365ded847e5c017e4ac68cc868/large_1920-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034108-76eb74c083f750365ded847e5c017e4ac68cc868/medium_1024-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034108-76eb74c083f750365ded847e5c017e4ac68cc868/small_640-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034108-76eb74c083f750365ded847e5c017e4ac68cc868/four_three_640-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034108-76eb74c083f750365ded847e5c017e4ac68cc868/square_640-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034108-76eb74c083f750365ded847e5c017e4ac68cc868/downloadable-62155bd10193342d2fbb88f6512029f76865071a.jpeg"
        },
        "created_on": "2019-12-06T00:52:11Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061113",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9261501463",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034107-a2324af53fd5d53f4816b54a805d9498173ca44c/original-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
        "name": "DBI-26-5vNHBdMU-CRE-Images-Package---Shopping-Centers-Sample_DBI-54-8efSASmw-Lakeside-Marketplace_DBU95-DJI_0079.jpeg",
        "source_type": "edit",
        "formatted_size": "2.46 MB",
        "size": 2581268,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034107-a2324af53fd5d53f4816b54a805d9498173ca44c/large_1920-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034107-a2324af53fd5d53f4816b54a805d9498173ca44c/medium_1024-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034107-a2324af53fd5d53f4816b54a805d9498173ca44c/small_640-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034107-a2324af53fd5d53f4816b54a805d9498173ca44c/four_three_640-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034107-a2324af53fd5d53f4816b54a805d9498173ca44c/square_640-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034107-a2324af53fd5d53f4816b54a805d9498173ca44c/downloadable-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg"
        },
        "created_on": "2019-12-06T00:51:45Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4080429092",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034106-a2324af53fd5d53f4816b54a805d9498173ca44c/original-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
        "name": "DBI-25-2UUcjuQr-CRE-Images-Package---Shopping-Centers-Sample_DBI-47-Mqr74Qkf-Lakeside-Marketplace_DBU88-DJI_0070.jpeg",
        "source_type": "edit",
        "formatted_size": "2.08 MB",
        "size": 2178978,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034106-a2324af53fd5d53f4816b54a805d9498173ca44c/large_1920-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034106-a2324af53fd5d53f4816b54a805d9498173ca44c/medium_1024-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034106-a2324af53fd5d53f4816b54a805d9498173ca44c/small_640-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034106-a2324af53fd5d53f4816b54a805d9498173ca44c/four_three_640-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034106-a2324af53fd5d53f4816b54a805d9498173ca44c/square_640-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034106-a2324af53fd5d53f4816b54a805d9498173ca44c/downloadable-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg"
        },
        "created_on": "2019-12-06T00:51:45Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI5623489935",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034103-423d12e006060c11e792c9d6f3badb33a351ca6a/original-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
        "name": "DBI-22-aqr7pW7a-CRE-Images-Package---Shopping-Centers-Sample_DBI-57-TgwqvXuM-Lakeside-Marketplace_DBU98-DJI_0087.jpeg",
        "source_type": "edit",
        "formatted_size": "2.39 MB",
        "size": 2507677,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034103-423d12e006060c11e792c9d6f3badb33a351ca6a/large_1920-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034103-423d12e006060c11e792c9d6f3badb33a351ca6a/medium_1024-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034103-423d12e006060c11e792c9d6f3badb33a351ca6a/small_640-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034103-423d12e006060c11e792c9d6f3badb33a351ca6a/four_three_640-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034103-423d12e006060c11e792c9d6f3badb33a351ca6a/square_640-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034103-423d12e006060c11e792c9d6f3badb33a351ca6a/downloadable-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg"
        },
        "created_on": "2019-12-06T00:51:44Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3311858014",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034101-423d12e006060c11e792c9d6f3badb33a351ca6a/original-d6b51620db80cf004109697928574792eca08fcd.jpeg",
        "name": "DBI-20-3WlxudO4-CRE-Images-Package---Shopping-Centers-Sample_DBI-48-jE4NmqfY-Lakeside-Marketplace_DBU89-DJI_0071.jpeg",
        "source_type": "edit",
        "formatted_size": "2.04 MB",
        "size": 2142333,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034101-423d12e006060c11e792c9d6f3badb33a351ca6a/large_1920-d6b51620db80cf004109697928574792eca08fcd.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034101-423d12e006060c11e792c9d6f3badb33a351ca6a/medium_1024-d6b51620db80cf004109697928574792eca08fcd.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034101-423d12e006060c11e792c9d6f3badb33a351ca6a/small_640-d6b51620db80cf004109697928574792eca08fcd.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034101-423d12e006060c11e792c9d6f3badb33a351ca6a/four_three_640-d6b51620db80cf004109697928574792eca08fcd.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034101-423d12e006060c11e792c9d6f3badb33a351ca6a/square_640-d6b51620db80cf004109697928574792eca08fcd.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034101-423d12e006060c11e792c9d6f3badb33a351ca6a/downloadable-d6b51620db80cf004109697928574792eca08fcd.jpeg"
        },
        "created_on": "2019-12-06T00:51:44Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI1245210655",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034099-7d29e35325db10e0280864ba9830b2d57e0c2ac4/original-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
        "name": "DBI-18-PKjrfr2q-CRE-Images-Package---Shopping-Centers-Sample_DBI-42-9rot8SIa-Lakeside-Marketplace_DBU83-DJI_0063.jpeg",
        "source_type": "edit",
        "formatted_size": "1.87 MB",
        "size": 1961119,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034099-7d29e35325db10e0280864ba9830b2d57e0c2ac4/large_1920-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034099-7d29e35325db10e0280864ba9830b2d57e0c2ac4/medium_1024-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034099-7d29e35325db10e0280864ba9830b2d57e0c2ac4/small_640-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034099-7d29e35325db10e0280864ba9830b2d57e0c2ac4/four_three_640-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034099-7d29e35325db10e0280864ba9830b2d57e0c2ac4/square_640-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034099-7d29e35325db10e0280864ba9830b2d57e0c2ac4/downloadable-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg"
        },
        "created_on": "2019-12-06T00:51:43Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI5179860475",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034095-7d29e35325db10e0280864ba9830b2d57e0c2ac4/original-9eb54f47001de7b12d8ccecf55838e302a083c0c.jpeg",
        "name": "DBI-14-PU5eB4oU-CRE-Images-Package---Shopping-Centers-Sample_DBI-45-TzJSLqDA-Lakeside-Marketplace_DBU86-DJI_0067.jpeg",
        "source_type": "edit",
        "formatted_size": "1.92 MB",
        "size": 2011066,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034095-7d29e35325db10e0280864ba9830b2d57e0c2ac4/large_1920-9eb54f47001de7b12d8ccecf55838e302a083c0c.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034095-7d29e35325db10e0280864ba9830b2d57e0c2ac4/medium_1024-9eb54f47001de7b12d8ccecf55838e302a083c0c.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034095-7d29e35325db10e0280864ba9830b2d57e0c2ac4/small_640-9eb54f47001de7b12d8ccecf55838e302a083c0c.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034095-7d29e35325db10e0280864ba9830b2d57e0c2ac4/four_three_640-9eb54f47001de7b12d8ccecf55838e302a083c0c.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034095-7d29e35325db10e0280864ba9830b2d57e0c2ac4/square_640-9eb54f47001de7b12d8ccecf55838e302a083c0c.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034095-7d29e35325db10e0280864ba9830b2d57e0c2ac4/downloadable-9eb54f47001de7b12d8ccecf55838e302a083c0c.jpeg"
        },
        "created_on": "2019-12-06T00:51:43Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI5829111494",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034094-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/original-0d9a3633314f04a9482ef068e811bfa0dcc80c5c.jpeg",
        "name": "DBI-13-pLwYaThP-CRE-Images-Package---Shopping-Centers-Sample_DBI-43-0iuykUPm-Lakeside-Marketplace_DBU84-DJI_0064.jpeg",
        "source_type": "edit",
        "formatted_size": "2.02 MB",
        "size": 2118849,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034094-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/large_1920-0d9a3633314f04a9482ef068e811bfa0dcc80c5c.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034094-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/medium_1024-0d9a3633314f04a9482ef068e811bfa0dcc80c5c.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034094-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/small_640-0d9a3633314f04a9482ef068e811bfa0dcc80c5c.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034094-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/four_three_640-0d9a3633314f04a9482ef068e811bfa0dcc80c5c.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034094-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/square_640-0d9a3633314f04a9482ef068e811bfa0dcc80c5c.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034094-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/downloadable-0d9a3633314f04a9482ef068e811bfa0dcc80c5c.jpeg"
        },
        "created_on": "2019-12-06T00:51:42Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0738293409",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034093-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/original-6b558fc504d057a630e1f639a01abd4d8df65b0c.jpeg",
        "name": "DBI-12-BTeNJg55-CRE-Images-Package---Shopping-Centers-Sample_DBI-41-gyYgLLgd-Lakeside-Marketplace_DBU82-DJI_0062.jpeg",
        "source_type": "edit",
        "formatted_size": "2.01 MB",
        "size": 2105762,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034093-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/large_1920-6b558fc504d057a630e1f639a01abd4d8df65b0c.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034093-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/medium_1024-6b558fc504d057a630e1f639a01abd4d8df65b0c.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034093-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/small_640-6b558fc504d057a630e1f639a01abd4d8df65b0c.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034093-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/four_three_640-6b558fc504d057a630e1f639a01abd4d8df65b0c.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034093-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/square_640-6b558fc504d057a630e1f639a01abd4d8df65b0c.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034093-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/downloadable-6b558fc504d057a630e1f639a01abd4d8df65b0c.jpeg"
        },
        "created_on": "2019-12-06T00:51:42Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8035671465",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034091-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/original-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
        "name": "DBI-11-yUNFQKEv-CRE-Images-Package---Shopping-Centers-Sample_DBI-32-nu7toZ8x-Lakeside-Marketplace_DBU73-SE_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.26 MB",
        "size": 2366480,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034091-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/large_1920-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034091-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/medium_1024-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034091-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/small_640-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034091-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/four_three_640-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034091-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/square_640-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034091-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/downloadable-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg"
        },
        "created_on": "2019-12-06T00:51:07Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8170083453",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034090-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/original-762384d67608457afc248fde66c893da7f918496.jpeg",
        "name": "DBI-10-WCDSA7ZA-CRE-Images-Package---Shopping-Centers-Sample_DBI-35-3rht1dJV-Lakeside-Marketplace_DBU76-FRONT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.04 MB",
        "size": 2138776,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034090-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/large_1920-762384d67608457afc248fde66c893da7f918496.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034090-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/medium_1024-762384d67608457afc248fde66c893da7f918496.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034090-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/small_640-762384d67608457afc248fde66c893da7f918496.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034090-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/four_three_640-762384d67608457afc248fde66c893da7f918496.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034090-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/square_640-762384d67608457afc248fde66c893da7f918496.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034090-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/downloadable-762384d67608457afc248fde66c893da7f918496.jpeg"
        },
        "created_on": "2019-12-06T00:51:07Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI5293278666",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034089-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/original-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
        "name": "DBI-9-Gj9NU7aO-CRE-Images-Package---Shopping-Centers-Sample_DBI-34-Yb0qjasr-Lakeside-Marketplace_DBU75-W_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.33 MB",
        "size": 2444404,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034089-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/large_1920-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034089-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/medium_1024-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034089-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/small_640-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034089-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/four_three_640-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034089-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/square_640-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034089-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/downloadable-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg"
        },
        "created_on": "2019-12-06T00:51:07Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0356717204",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034088-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/original-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
        "name": "DBI-8-CSbtEuJz-CRE-Images-Package---Shopping-Centers-Sample_DBI-31-zGjKtYXQ-Lakeside-Marketplace_DBU72-S_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.17 MB",
        "size": 2279977,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034088-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/large_1920-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034088-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/medium_1024-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034088-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/small_640-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034088-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/four_three_640-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034088-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/square_640-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034088-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/downloadable-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg"
        },
        "created_on": "2019-12-06T00:51:07Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI1025313174",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034087-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/original-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
        "name": "DBI-7-VLUhPlSX-CRE-Images-Package---Shopping-Centers-Sample_DBI-29-Q4idBa2p-Lakeside-Marketplace_DBU70-NE_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.43 MB",
        "size": 2545508,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034087-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/large_1920-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034087-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/medium_1024-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034087-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/small_640-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034087-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/four_three_640-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034087-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/square_640-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034087-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/downloadable-492d0b22f10d900ef123841826e66de9705918d8.jpeg"
        },
        "created_on": "2019-12-06T00:51:06Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9457110968",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034086-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/original-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
        "name": "DBI-6-Dvh5h2U7-CRE-Images-Package---Shopping-Centers-Sample_DBI-28-zNfK8rO0-Lakeside-Marketplace_DBU69-N_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.11 MB",
        "size": 2212920,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034086-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/large_1920-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034086-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/medium_1024-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034086-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/small_640-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034086-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/four_three_640-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034086-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/square_640-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034086-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/downloadable-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg"
        },
        "created_on": "2019-12-06T00:51:06Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7618504574",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034085-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/original-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
        "name": "DBI-5-s0lgtIaA-CRE-Images-Package---Shopping-Centers-Sample_DBI-27-JGEYmlMO-Lakeside-Marketplace_DBU68-E_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.33 MB",
        "size": 2439645,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034085-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/large_1920-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034085-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/medium_1024-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034085-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/small_640-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034085-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/four_three_640-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034085-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/square_640-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034085-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/downloadable-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg"
        },
        "created_on": "2019-12-06T00:51:06Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7679823879",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034080-36566a8ff97222ea4975224e6b56a8954fe96d46/original-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
        "name": "DBI-21-rXN32TWY-CRE-Images-Package---Shopping-Centers-Sample_DBI-30-6Cem6m7R-Lakeside-Marketplace_DBU71-NW_100FTR.jpeg",
        "source_type": "edit",
        "formatted_size": "2.2 MB",
        "size": 2305414,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034080-36566a8ff97222ea4975224e6b56a8954fe96d46/large_1920-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034080-36566a8ff97222ea4975224e6b56a8954fe96d46/medium_1024-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034080-36566a8ff97222ea4975224e6b56a8954fe96d46/small_640-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034080-36566a8ff97222ea4975224e6b56a8954fe96d46/four_three_640-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034080-36566a8ff97222ea4975224e6b56a8954fe96d46/square_640-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034080-36566a8ff97222ea4975224e6b56a8954fe96d46/downloadable-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg"
        },
        "created_on": "2019-12-06T00:50:26Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061109",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6864505553",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034079-1b952b83c02d14cc0155b73890b90ac4b92b8445/original-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
        "name": "DBI-20-2Z0jrRkq-CRE-Images-Package---Shopping-Centers-Sample_DBI-33-egnffXbz-Lakeside-Marketplace_DBU74-SW_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.08 MB",
        "size": 2183094,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034079-1b952b83c02d14cc0155b73890b90ac4b92b8445/large_1920-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034079-1b952b83c02d14cc0155b73890b90ac4b92b8445/medium_1024-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034079-1b952b83c02d14cc0155b73890b90ac4b92b8445/small_640-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034079-1b952b83c02d14cc0155b73890b90ac4b92b8445/four_three_640-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034079-1b952b83c02d14cc0155b73890b90ac4b92b8445/square_640-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034079-1b952b83c02d14cc0155b73890b90ac4b92b8445/downloadable-58b56ed2c054c513b803908e2c621724881381a3.jpeg"
        },
        "created_on": "2019-12-06T00:50:25Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061109",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3494678406",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034078-1b952b83c02d14cc0155b73890b90ac4b92b8445/original-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
        "name": "DBI-19-dYgr8DCm-CRE-Images-Package---Shopping-Centers-Sample_DBI-51-n6hpnhi5-Lakeside-Marketplace_DBU92-DJI_0075.jpeg",
        "source_type": "edit",
        "formatted_size": "2.21 MB",
        "size": 2322350,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034078-1b952b83c02d14cc0155b73890b90ac4b92b8445/large_1920-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034078-1b952b83c02d14cc0155b73890b90ac4b92b8445/medium_1024-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034078-1b952b83c02d14cc0155b73890b90ac4b92b8445/small_640-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034078-1b952b83c02d14cc0155b73890b90ac4b92b8445/four_three_640-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034078-1b952b83c02d14cc0155b73890b90ac4b92b8445/square_640-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034078-1b952b83c02d14cc0155b73890b90ac4b92b8445/downloadable-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg"
        },
        "created_on": "2019-12-06T00:50:25Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061109",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4782590917",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022108-eb6ef2c27f8fb4b45bde10561d857c3dc6504c7f/original-0b5bc5f30c8c20722a0637f7d935eae70b78a4b7.jpeg",
        "name": "DBI-7-J9Nh4NdZ-CRE-Shopping-Center---Pro-Package_DBI-13-downloadable-9056869b7405a854fb6e71d59ed653af89190af0-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "1.97 MB",
        "size": 2068363,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022108-eb6ef2c27f8fb4b45bde10561d857c3dc6504c7f/large_1920-0b5bc5f30c8c20722a0637f7d935eae70b78a4b7.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022108-eb6ef2c27f8fb4b45bde10561d857c3dc6504c7f/medium_1024-0b5bc5f30c8c20722a0637f7d935eae70b78a4b7.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022108-eb6ef2c27f8fb4b45bde10561d857c3dc6504c7f/small_640-0b5bc5f30c8c20722a0637f7d935eae70b78a4b7.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022108-eb6ef2c27f8fb4b45bde10561d857c3dc6504c7f/four_three_640-0b5bc5f30c8c20722a0637f7d935eae70b78a4b7.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022108-eb6ef2c27f8fb4b45bde10561d857c3dc6504c7f/square_640-0b5bc5f30c8c20722a0637f7d935eae70b78a4b7.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022108-eb6ef2c27f8fb4b45bde10561d857c3dc6504c7f/downloadable-0b5bc5f30c8c20722a0637f7d935eae70b78a4b7.jpeg"
        },
        "created_on": "2019-12-04T20:56:15Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8221785687",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022104-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/original-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
        "name": "DBI-4-hyJfkApL-CRE-Shopping-Center---Pro-Package_DBI-6-downloadable-8c8bd726f1e7c2e7ab07cd153987ce194dcce521-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "1.88 MB",
        "size": 1967253,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022104-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/large_1920-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022104-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/medium_1024-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022104-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/small_640-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022104-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/four_three_640-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022104-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/square_640-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022104-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/downloadable-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg"
        },
        "created_on": "2019-12-04T20:56:14Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4989095648",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022100-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/original-070d74e7e137fc3171cc5f92907287c14b9acb1b.jpeg",
        "name": "DBI-1-KMWB9elc-CRE-Shopping-Center---Pro-Package_DBI-2-downloadable-2c3d73a0c4507f991902810165ca6c438fd2323b-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "1.83 MB",
        "size": 1918761,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022100-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/large_1920-070d74e7e137fc3171cc5f92907287c14b9acb1b.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022100-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/medium_1024-070d74e7e137fc3171cc5f92907287c14b9acb1b.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022100-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/small_640-070d74e7e137fc3171cc5f92907287c14b9acb1b.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022100-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/four_three_640-070d74e7e137fc3171cc5f92907287c14b9acb1b.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022100-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/square_640-070d74e7e137fc3171cc5f92907287c14b9acb1b.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022100-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/downloadable-070d74e7e137fc3171cc5f92907287c14b9acb1b.jpeg"
        },
        "created_on": "2019-12-04T20:56:14Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV2856468233",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-25-eDyyjQ4u-DL-DBV-24-Y0nURtc2-DL-CRE-Shopping-Center---Pro-Package_DBV-24-CRE_Shopping_Center-final-assets_1.mp4",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "e06529a02e306d52921ce9497b3f2fde",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174510-aa7d3cbb5d41fcad33b4b504f3c10a39e3666d83/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/174510-aa7d3cbb5d41fcad33b4b504f3c10a39e3666d83/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174510-aa7d3cbb5d41fcad33b4b504f3c10a39e3666d83/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174510-aa7d3cbb5d41fcad33b4b504f3c10a39e3666d83/DL-DBV-25-eDyyjQ4u-DL-DBV-24-Y0nURtc2-DL-CRE-Shopping-Center---Pro-Package_DBV-24-CRE_Shopping_Center-final-assets_1.mp4"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061111",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "904e1eb10d52d41722adf67e03c4407f",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-05T07:02:24Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "1061113",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278183",
        "shot_type_id": 148,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Low Level Corner Shots"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "148",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI8597164825",
            "type": "images"
          }, {
            "id": "DBI8760574137",
            "type": "images"
          }, {
            "id": "DBI6642380667",
            "type": "images"
          }, {
            "id": "DBI8376309490",
            "type": "images"
          }, {
            "id": "DBI3685638867",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "148",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Low Level Corner Shots",
        "description": "Images from just below roof level that are angled down the lineup of buildings. We need one image from each end of the building. If there are multiple building or if it is an L-shape or U-shaped property we need an image from each corner. For example, an L-shaped building will have at least 4 images and a U-shaped building with have at least 6 images. \n\nImages with little to no roof in frame are a big point of emphasis our customers. If you need to adjust, back away from the property rather than increasing altitude *so that we don't see the top of the roof.*\n**[Click here for example images](http://bit.ly/2wNIc1D)**",
        "video": null,
        "slug": "images_-_low_level_corner_shots",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061112",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278183",
        "shot_type_id": 147,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Anchor Tenants"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "147",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI4080429092",
            "type": "images"
          }, {
            "id": "DBI9261501463",
            "type": "images"
          }, {
            "id": "DBI5623489935",
            "type": "images"
          }, {
            "id": "DBI3311858014",
            "type": "images"
          }, {
            "id": "DBI1245210655",
            "type": "images"
          }, {
            "id": "DBI5829111494",
            "type": "images"
          }, {
            "id": "DBI5179860475",
            "type": "images"
          }, {
            "id": "DBI0738293409",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "147",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Anchor Tenants",
        "description": "Straight on image of each anchor tenant from just below roof level. *An anchor tenant is grocery store, department store, or other large retailer.* Images with little to no roof in frame are a big point of emphasis our customers. If you need to adjust, back away from the property rather than increasing altitude *so that we don't see the top of the roof.*",
        "video": null,
        "slug": "images_-_anchor_tenants",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061111",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278183",
        "shot_type_id": 22,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Final Assets"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "22",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV2856468233",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "22",
      "type": "shot_types",
      "attributes": {
        "name": "Final Assets",
        "description": "Use this shot to upload final edited assets for the customer. Not for use by pilots.\n",
        "video": null,
        "slug": "final-assets",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061110",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "278183",
        "shot_type_id": 24,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (350 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "24",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI8035671465",
            "type": "images"
          }, {
            "id": "DBI8170083453",
            "type": "images"
          }, {
            "id": "DBI0356717204",
            "type": "images"
          }, {
            "id": "DBI5293278666",
            "type": "images"
          }, {
            "id": "DBI1025313174",
            "type": "images"
          }, {
            "id": "DBI9457110968",
            "type": "images"
          }, {
            "id": "DBI7618504574",
            "type": "images"
          }, {
            "id": "DBI4989095648",
            "type": "images"
          }, {
            "id": "DBI4782590917",
            "type": "images"
          }, {
            "id": "DBI8221785687",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "24",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (350 ft above ground level)",
        "description": "*If there are altitude restrictions in this airspace, please take these images at the highest allowable altitude instead of 350'. *",
        "video": null,
        "slug": "images-cardinal-directions-350-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "23",
            "type": "shot_type_sample_images"
          }, {
            "id": "24",
            "type": "shot_type_sample_images"
          }, {
            "id": "25",
            "type": "shot_type_sample_images"
          }, {
            "id": "26",
            "type": "shot_type_sample_images"
          }, {
            "id": "27",
            "type": "shot_type_sample_images"
          }, {
            "id": "28",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1061109",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "278183",
        "shot_type_id": 13,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (100 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "13",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI7679823879",
            "type": "images"
          }, {
            "id": "DBI6864505553",
            "type": "images"
          }, {
            "id": "DBI3494678406",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "13",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (100 ft above ground level)",
        "description": "",
        "video": "",
        "slug": "images-cardinal-directions-100-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "17",
            "type": "shot_type_sample_images"
          }, {
            "id": "18",
            "type": "shot_type_sample_images"
          }, {
            "id": "19",
            "type": "shot_type_sample_images"
          }, {
            "id": "20",
            "type": "shot_type_sample_images"
          }, {
            "id": "21",
            "type": "shot_type_sample_images"
          }, {
            "id": "22",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "DBINV837868120540436",
      "type": "invoice_line_items",
      "attributes": {
        "reason": "base_package_price",
        "description": null,
        "amount": 56900,
        "created_on": "2019-12-04T20:52:58Z",
        "display_name": "Base Package Price"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "missions"
          }
        },
        "work_item": {
          "data": null
        }
      }
    }]
  };
  _exports.default = _default;
});