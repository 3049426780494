define("clients/components/connect-to-esri", ["exports", "clients/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    esriLoader: Ember.inject.service('esri-loader'),
    redirectToEsriUrl: Ember.computed(function () {
      return `https://www.arcgis.com/sharing/rest/oauth2/` + `authorize?client_id=${_environment.default.esri_app_id}&response_type=code&` + `redirect_uri=${window.location.href}` + `&expiration=-1`;
    }),
    actions: {
      buttonClicked: function () {
        if (this.whenClicked) {
          this.whenClicked("Clicked Connect ArcGIS Button");
        }

        window.location.replace(this.redirectToEsriUrl);
      }
    }
  });

  _exports.default = _default;
});