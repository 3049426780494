define("clients/templates/components/property-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LkXoiTgj",
    "block": "{\"symbols\":[\"property\",\"index\"],\"statements\":[[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"content-section\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"sub-header\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[35,0,[\"length\"]],1],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          Property sizes\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          Property size\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"property-notes\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[35,0,[\"length\"]],1],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"p\"],[14,0,\"property-notes\"],[12],[2,\"#\"],[1,[32,1,[\"number\"]]],[2,\" - \"],[1,[32,1,[\"acres\"]]],[2,\" acres\"],[10,\"span\"],[12],[2,\"(\"],[1,[32,1,[\"sqrFeet\"]]],[2,\" sq ft)\"],[13],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"p\"],[14,0,\"property-notes\"],[12],[1,[32,1,[\"acres\"]]],[2,\" acres\"],[10,\"span\"],[12],[2,\"(\"],[1,[32,1,[\"sqrFeet\"]]],[2,\" sq ft)\"],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"propertyNotes\",\"is-greater\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "clients/templates/components/property-notes.hbs"
    }
  });

  _exports.default = _default;
});