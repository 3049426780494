define("clients/components/mission-view/mission-address-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionAddressViewComponent;
  MissionAddressViewComponent = Ember.Component.extend({
    hideExpandedMap: true,
    canEditMap: Ember.computed('mission.package.catalog_items.length', 'mission.status', function () {
      return ['created', 'scheduling_call', 'confirmed'].includes(this.get('mission.status')) && this.get('mission.package.catalog_items.length');
    }),
    addressFirstLine: Ember.computed('mission.location.address', 'mission.location.city', 'mission.location.state', 'mission.location.country', function () {
      return this.get('mission.location.address') || this.get('mission.location.city') || this.get('mission.location.state') || this.get('mission.location.country');
    }),
    addressSecondLine: Ember.computed('mission.location.address', 'mission.location.city', 'mission.location.state', 'mission.location.country', 'mission.location.postal_code', function () {
      var addressParts;
      addressParts = [this.get('mission.location.city'), this.get('mission.location.state'), this.get('mission.location.postal_code'), this.get('mission.location.country')];
      addressParts = addressParts.filter(function (part) {
        return part;
      });
      return addressParts.join(', ');
    }),
    actions: {
      toggleEditRefId: function (text) {
        this.set('showRefIdModal', !this.get('showRefIdModal'));
        return this.set('textForShowRefId', text);
      }
    }
  });
  var _default = MissionAddressViewComponent;
  _exports.default = _default;
});