define("clients/mixins/share-header-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ShareHeaderMixin;
  ShareHeaderMixin = Ember.Mixin.create({
    setHeaderTags: function (title, imageUrl) {
      var headTags;
      headTags = [{
        type: 'meta',
        tagId: 'meta-og-url',
        attrs: {
          property: 'og:url',
          content: window.location
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-type',
        attrs: {
          property: 'og:type',
          content: 'website'
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-title',
        attrs: {
          property: 'og:title',
          content: "" + title
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-image',
        attrs: {
          property: 'og:image',
          content: "" + imageUrl
        }
      }, {
        type: 'meta',
        tagId: 'twitter-card',
        attrs: {
          property: 'twitter:card',
          content: 'gallery'
        }
      }, {
        type: 'meta',
        tagId: 'twitter-site',
        attrs: {
          property: 'twitter:site',
          content: '@zeitview'
        }
      }, {
        type: 'meta',
        tagId: 'twitter-creator',
        attrs: {
          property: 'twitter:creator',
          content: '@zeitview'
        }
      }];
      return this.set('headTags', headTags);
    }
  });
  var _default = ShareHeaderMixin;
  _exports.default = _default;
});