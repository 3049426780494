define("clients/controllers/dashboard/locations/index", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    missionFilter: 'all',
    missionsLoaded: false,
    sort_attribute: 'scheduled',
    sort_direction: 'desc',
    current_date_column: 'flight_date',
    show_org_missions: false,
    currentGroup: null,
    groupObserver: Ember.observer('currentGroup', function () {
      return this.set('group_id', this.get('currentGroup.id'));
    }),
    metrics: Ember.inject.service(),
    showCreateGroupModal: false,
    showMoveToGroupModal: false,
    showRemoveFromGroupModal: false,
    showRenameGroupModal: false,
    showRemoveGroupModal: false,
    selectedGroup: null,
    showContextMenu: false,
    MissionsCsvDownloader: Ember.inject.service(),
    queryParams: ['q', 'sort_attribute', 'sort_direction', 'page', 'statuses', 'current_date_column', 'show_org_missions', 'group_id', 'totalPages'],
    upcomingMissions: ['created', 'scheduling_call', 'confirmed', 'pilots_notified', 'pilot_accepted'],
    inProgressMissions: ['pilot_accepted', 'flight_complete', 'assets_uploaded', 'processing_shots', 'assets_classified', 'in_production'],
    completedMissions: ['invoice_needed', 'invoiced', 'awaiting_payment', 'complete'],
    selectedMissions: [],

    init() {
      this._super();

      return this.set('selectedMissions', []);
    },

    missions: Ember.computed('model.missions.[]', function () {
      return this.get('model.missions').map(mission => mission);
    }),
    packageAddons: Ember.computed('model.client.{available_addons,id}', 'model.{org_package.vertical,package_addons}', function () {
      if (Ember.isPresent(this.get('model.client.id'))) {
        return this.get('model.client.available_addons');
      } else {
        return this.get('model.package_addons').filter(addon => {
          return addon.get('verticals').toArray().includes(this.get('model.org_package.vertical'));
        });
      }
    }),
    firstUpsellAddon: Ember.computed('model.client.upsell_addon_ids', 'packageAddons', function () {
      return this.get('packageAddons').filter(addon => {
        return this.get('model.client.upsell_addon_ids').includes(parseInt(addon.id));
      })[0];
    }),
    initOmniSearch: Ember.on('init', function () {
      document.addEventListener('keyup', event => {
        if (Array.from(event.target.classList).includes('omni-search-input')) {
          if (this.get('searchTerms').length) {
            (0, _jquery.default)('.omni-search-button').addClass('active');
          } else {
            (0, _jquery.default)('.omni-search-button').removeClass('active');
          }

          if (event.keyCode === 13) {
            return this.set('q', this.get('searchTerms'));
          }
        }
      });
    }),
    searchPlaceholder: Ember.computed('show_org_missions', function () {
      if (this.get('show_org_missions')) {
        return "Location, ID, Email";
      } else {
        return "Location, ID";
      }
    }),
    isAnySelected: Ember.computed('selectedMissions.length', function () {
      return this.get('selectedMissions').length > 0;
    }),
    showIntroductionGroupsModal: Ember.computed.reads('model.client.feature_flags.show_mission_groups_modal'),
    actions: {
      setFilter(val) {
        this.set('missionFilter', val);
        this.set('page', 1);

        switch (val) {
          case 'all':
            return this.set('statuses', '');

          case 'upcoming':
            return this.set('statuses', this.upcomingMissions);

          case 'inProgress':
            return this.set('statuses', this.inProgressMissions);

          case 'completed':
            return this.set('statuses', this.completedMissions);

          case 'canceled':
            return this.set('statuses', 'canceled');
        }
      },

      setSelectedMission(mission) {
        this.set('selectedMission', mission);
        this.set('showModal', true);
        return this.set('isShowingModal', true);
      },

      search() {
        this.set('page', 1);
        return this.set('q', this.get('searchTerms'));
      },

      toggleShowDownload() {
        this.set('showDownload', !this.get('showDownload'));
        return false;
      },

      downloadBlurred() {
        (0, _jquery.default)(':hover')[(0, _jquery.default)(':hover').length - 1].click();
        this.set('showDownload', false);
        return false;
      },

      downloadCsv() {
        const queryParams = this.get('queryParams');
        const params = {};
        queryParams.forEach(param => {
          if (param !== 'show_org_missions') {
            return params[param] = this.get(param);
          }
        });

        if (this.get('show_org_missions')) {
          params['show_org_missions'] = 'entire_organization';
        }

        return this.get('MissionsCsvDownloader').load(params).catch(error => {
          if (error.status === 422) {
            alert('There are no missions within the last year');
          }

          throw error;
        });
      },

      toggleShowOrgMissions() {
        this.toggleProperty('show_org_missions');
        return false;
      },

      newOrderWithUpsell(addonId) {
        this.transitionToRoute('order-flow', {
          queryParams: {
            upsellAddonId: addonId
          }
        });
        return this.get('metrics').trackEvent({
          event: 'event',
          category: 'Upsell',
          action: 'click',
          label: 'Accept upsell offer on locations list'
        });
      },

      toggleCreateGroupModal() {
        this.toggleProperty('showCreateGroupModal');
        return false;
      },

      toggleMoveToGroupModal() {
        this.toggleProperty('showMoveToGroupModal');
        return false;
      },

      switchModal() {
        this.toggleProperty('showCreateGroupModal');
        this.toggleProperty('showMoveToGroupModal');
        return false;
      },

      toggleRemoveFromGroupModal() {
        this.toggleProperty('showRemoveFromGroupModal');
        return false;
      },

      toggleRenameGroupModal(group) {
        this.set('selectedGroup', group);
        this.toggleProperty('showRenameGroupModal');
        return false;
      },

      toggleRemoveGroupModal(group) {
        this.set('selectedGroup', group);
        this.toggleProperty('showRemoveGroupModal');
        return false;
      },

      closeIntroductionGroupsModal() {
        this.set('model.client.feature_flags.show_mission_groups_modal', false);
        this.get('model.client').save();
        return false;
      }

    }
  });

  _exports.default = _default;
});