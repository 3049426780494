define("clients/components/mission-plan-map-feature", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['featureStyle'],
    supportedGeometry: Ember.computed('feature.object', function () {
      return this.feature.object.getGeometry().getType();
    }),
    catalogOptions: Ember.computed('feature.{catalog_item_ids,no_fly_zone,object}', 'packageCatalogItems', 'supportedGeometry', function () {
      if (this.get('feature.no_fly_zone')) {
        return [];
      } else {
        return this.packageCatalogItems.filter(item => {
          if (this.supportedGeometry === 'Polygon' && item.has_pano_shot_type) {
            return;
          }

          const supported_geometry = item.supported_geometry.split(',');
          return supported_geometry.includes(this.supportedGeometry);
        }).map(item => {
          const exists = this.get('feature.catalog_item_ids').find(catalog_id => catalog_id === item.id);
          return Ember.Object.create({
            item: item,
            selected: !!exists
          });
        });
      }
    }),
    catalogItemPanorama: Ember.computed('feature.catalog_item_ids.[]', function () {
      const catalog_item_ids = this.feature.catalog_item_ids;
      const catalog_items = this.packageCatalogItems;
      const catalog_item = catalog_items.find(item => catalog_item_ids.includes(item.id));
      return catalog_item && catalog_item.has_pano_shot_type ? catalog_item : null;
    }),
    catalogItemPanoAttributes: Ember.computed('catalogItemPanorama', 'feature.{shot_type_attributes,shot_type_ids.[]}', function () {
      return this.feature.shot_type_attributes[this.get('catalogItemPanorama.panoShotType.id')];
    }),
    changeIconsWhenCatalogItems: function (feature) {
      this.get('feature.iconName') === 'point' ? this.changeToPanoramaIcon(feature) : this.changeToPointIcon(feature);
      this.set('feature.iconName', this.get('feature.iconName') === 'point' ? 'panorama_default' : 'point');
    },
    hasLineCatalogItem: Ember.computed.and('packageCatalogItems.length', 'feature.hasLineCatalogItem'),
    nameChanged: Ember.observer('feature.name', function () {
      return this.get('feature.object').setProperty('name', this.feature.name);
    }),
    notesChanged: Ember.observer('feature.notes', function () {
      return this.get('feature.object').setProperty('notes', this.feature.notes);
    }),

    didInsertElement() {
      return (0, _jquery.default)("input[type='text']").focus();
    },

    actions: {
      delete(feature) {
        return this.deleteMission(feature);
      },

      save() {
        this.toggleProperty('editMode');
        return this.saveMission;
      },

      toggleEditMode() {
        this.parentView.disableFeature(this.feature);

        if (!this.editMode) {
          return this.parentView.enableFeature(this.feature);
        }
      },

      removeCatalogItem(id) {
        this.catalogOptions.find(option => {
          if (option.item.id === id) {
            option.set('selected', false);
            return true;
          }
        });
        const hasCatalogItemSelected = this.get('catalogOptions').find(option => option.selected && option.item.has_pano_shot_type);

        if (!hasCatalogItemSelected && this.get('feature.feature_type') === "point_of_interest") {
          this.changeToPointIcon(this.get('feature'));
          this.set('feature.iconName', 'point');
        }

        this.set('feature.catalog_item_ids', this.get('feature.catalog_item_ids').filter(catalog_id => catalog_id !== id));
        return this.get('feature.object').setProperty('catalog_item_ids', this.get('feature.catalog_item_ids'));
      },

      addCatalogItem(target) {
        if (!this.get('feature.catalog_item_ids').find(catalog_id => catalog_id === target.item.get('id'))) {
          this.get('feature.catalog_item_ids').pushObject(target.item.get('id'));
        }

        const hasCatalogItemSelected = this.get('catalogOptions').find(option => option.selected && option.item.has_pano_shot_type);

        if (hasCatalogItemSelected && this.get('feature.feature_type') === "point_of_interest") {
          this.changeToPanoramaIcon(this.get('feature'));
          this.set('feature.iconName', 'panorama_default');
          this.get('feature.shot_type_attributes')[hasCatalogItemSelected.item.panoShotType.id] = {
            altitude: hasCatalogItemSelected.item.panoShotType.altitude || 100
          };
        }

        return this.get('feature.object').setProperty('catalog_item_ids', this.get('feature.catalog_item_ids'));
      }

    }
  });

  _exports.default = _default;
});