define("clients/templates/components/charts/cancellations-by-reason", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OIjE1ID6",
    "block": "{\"symbols\":[\"cancel\"],\"statements\":[[10,\"div\"],[14,0,\"chart-container table-chart\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"chart-title\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,\"data-toggle\",\"tooltip\"],[14,\"data-placement\",\"top\"],[14,\"data-container\",\"body\"],[15,\"title\",[34,2]],[12],[2,\"\\n      Cancellations by Reason \"],[10,\"i\"],[14,0,\"fa fa-question-circle\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"chart-content\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3,[\"data\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"cancel-line\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"name\"]],\"Unclassified Reason\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"name\"],[12],[2,\"\\n            \"],[10,\"span\"],[14,\"data-toggle\",\"tooltip\"],[14,\"data-placement\",\"top\"],[14,\"data-container\",\"body\"],[14,\"title\",\"Older missions may have been canceled because of airspace restrictions or at the customer’s request\"],[12],[2,\"\\n              \"],[1,[32,1,[\"name\"]]],[2,\" \"],[10,\"i\"],[14,0,\"fa fa-question-circle\"],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"name\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[10,\"div\"],[14,0,\"count\"],[12],[1,[32,1,[\"missions_count\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"percentage\"],[12],[1,[32,1,[\"percentage\"]]],[2,\"%\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"is-equal\",\"if\",\"tooltipText\",\"content\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "clients/templates/components/charts/cancellations-by-reason.hbs"
    }
  });

  _exports.default = _default;
});