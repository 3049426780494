define("clients/metrics-adapters/logger", ["exports", "clients/config/environment", "ember-metrics/utils/object-transforms", "ember-metrics/metrics-adapters/base", "jquery"], function (_exports, _environment, _objectTransforms, _base, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Logger;
  Logger = _base.default.extend({
    sessionAccount: Ember.inject.service(),
    toStringExtension: function () {
      return 'logger';
    },
    init: function () {},
    trackEvent: function (options) {
      var _this, compactedOptions, event, params, props, url;

      if (options == null) {
        options = {};
      }

      if (_environment.default.logger.host) {
        compactedOptions = _objectTransforms.default.compact(options);
        event = compactedOptions.event;
        props = _objectTransforms.default.without(compactedOptions, 'event');
        _this = this;

        if (_objectTransforms.default.isPresent(props)) {
          props['app'] = _environment.default.modulePrefix;

          if (this.get('sessionAccount.account')) {
            props['userId'] = this.get('sessionAccount.account.id');
          }

          params = Ember.$.param(props);
          url = _environment.default.logger.host + '/events?' + params;
          return _jquery.default.ajax({
            method: 'POST',
            url: url
          }).then(function (response) {}, function (response) {});
        }
      }
    }
  });
  var _default = Logger;
  _exports.default = _default;
});