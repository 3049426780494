define("clients/data/sample_missions/cre_pro_package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": {
      "id": "DBM7214786245",
      "type": "missions",
      "attributes": {
        "instructions": "+ Scope: Aerial Images and Videos\n\n+ Time: Between 11am - 2pm\n\n+ Schedule: Within 48-72 hours; Weather permitting",
        "status": "complete",
        "client_id": "DBC3006554",
        "payment": {
          "id": null,
          "processor": null,
          "card": "Visa - 3969"
        },
        "external_assets_url": null,
        "owned_by_current_client": true,
        "contact_email": "clients@zeitview.com",
        "created_on": "2019-10-23T00:02:17Z",
        "is_pano_mission": false,
        "logo_url": null,
        "blurb": null,
        "paid": true,
        "paid_on": "2019-10-24T22:40:19Z",
        "price": 46900,
        "archive_url": null,
        "edit_url": null,
        "customer_instructions": "Weekend only. Fly between 5 - 6 pm",
        "scheduled_at_start": "2019-10-26T22:00:00Z",
        "scheduled_at_end": "2019-10-26T23:00:00Z",
        "parent_id": null,
        "reshoot_mission_id": null,
        "rejection_reason": null,
        "rejection_notes": null,
        "reference_id": "ZeitviewSampleMission",
        "cloud_reqs": "less_than_50",
        "scheduling_requirement": "scheduled",
        "arcgis_feature_layer": null,
        "geo_properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-93.796412409787, 41.592615714255], [-93.795351069836, 41.592607690333], [-93.795726579099, 41.59425078648], [-93.797227801327, 41.594050193223], [-93.797243894582, 41.592618832732], [-93.796412409787, 41.592615714255]]]
            },
            "properties": {
              "name": "Polygon #1",
              "notes": "",
              "number": 1,
              "area": "24319.0",
              "bounds": {
                "south": 41.592607690333,
                "west": -93.797243894582,
                "north": 41.59425078648,
                "east": -93.795351069836
              },
              "center": {
                "lat": 41.593429238407,
                "lng": -93.796297482209
              }
            },
            "id": 1
          }]
        }
      },
      "relationships": {
        "location": {
          "data": {
            "id": "281730",
            "type": "locations"
          }
        },
        "package": {
          "data": {
            "id": "4555",
            "type": "packages"
          }
        },
        "credit_card": {
          "data": {
            "id": "4898",
            "type": "credit_cards"
          }
        },
        "onsite_contact": {
          "data": {
            "id": "46878",
            "type": "onsite_contacts"
          }
        },
        "scheduling_contact": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "images": {
          "data": [{
            "id": "DBI8532371149",
            "type": "images"
          }, {
            "id": "DBI0458937384",
            "type": "images"
          }, {
            "id": "DBI0419714054",
            "type": "images"
          }, {
            "id": "DBI4933297293",
            "type": "images"
          }, {
            "id": "DBI3938178586",
            "type": "images"
          }, {
            "id": "DBI9752919324",
            "type": "images"
          }, {
            "id": "DBI1962407297",
            "type": "images"
          }, {
            "id": "DBI3442308017",
            "type": "images"
          }, {
            "id": "DBI7990417916",
            "type": "images"
          }, {
            "id": "DBI4274425053",
            "type": "images"
          }, {
            "id": "DBI8086126253",
            "type": "images"
          }, {
            "id": "DBI6268348479",
            "type": "images"
          }, {
            "id": "DBI1057634445",
            "type": "images"
          }, {
            "id": "DBI9959548548",
            "type": "images"
          }, {
            "id": "DBI2285291220",
            "type": "images"
          }, {
            "id": "DBI1396105140",
            "type": "images"
          }, {
            "id": "DBI0048210078",
            "type": "images"
          }, {
            "id": "DBI6979528367",
            "type": "images"
          }, {
            "id": "DBI6162164261",
            "type": "images"
          }]
        },
        "videos": {
          "data": [{
            "id": "DBV5978712319",
            "type": "videos"
          }, {
            "id": "DBV6202697679",
            "type": "videos"
          }, {
            "id": "DBV6442836042",
            "type": "videos"
          }, {
            "id": "DBV3509131718",
            "type": "videos"
          }, {
            "id": "DBV7288595718",
            "type": "videos"
          }, {
            "id": "DBV5140566529",
            "type": "videos"
          }, {
            "id": "DBV5210648048",
            "type": "videos"
          }, {
            "id": "DBV2334681977",
            "type": "videos"
          }, {
            "id": "DBV8916023667",
            "type": "videos"
          }, {
            "id": "DBV5967107038",
            "type": "videos"
          }, {
            "id": "DBV6226527447",
            "type": "videos"
          }, {
            "id": "DBV1612140950",
            "type": "videos"
          }, {
            "id": "DBV1037353960",
            "type": "videos"
          }, {
            "id": "DBV9191385824",
            "type": "videos"
          }, {
            "id": "DBV2625849645",
            "type": "videos"
          }, {
            "id": "DBV1830534065",
            "type": "videos"
          }, {
            "id": "DBV2007362573",
            "type": "videos"
          }, {
            "id": "DBV2431580406",
            "type": "videos"
          }, {
            "id": "DBV9336746201",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        },
        "attachments": {
          "data": []
        },
        "ortho_photo_maps": {
          "data": []
        },
        "shares": {
          "data": [{
            "id": "948fe423b0ea2bde805fdefdc0676244",
            "type": "shares"
          }]
        },
        "main_image": {
          "data": {
            "id": "DBI8532371149",
            "type": "images"
          }
        },
        "collaborators": {
          "data": [{
            "id": "DBC4577234760",
            "type": "collaborators"
          }]
        },
        "parent": {
          "data": null
        },
        "shots": {
          "data": [{
            "id": "1047829",
            "type": "shots"
          }, {
            "id": "1047828",
            "type": "shots"
          }, {
            "id": "1047827",
            "type": "shots"
          }, {
            "id": "1047826",
            "type": "shots"
          }, {
            "id": "1047825",
            "type": "shots"
          }, {
            "id": "1047824",
            "type": "shots"
          }, {
            "id": "1047823",
            "type": "shots"
          }, {
            "id": "1047822",
            "type": "shots"
          }, {
            "id": "1047821",
            "type": "shots"
          }, {
            "id": "1047820",
            "type": "shots"
          }, {
            "id": "1047819",
            "type": "shots"
          }]
        },
        "invoice_line_items": {
          "data": [{
            "id": "DBINV035899191541541",
            "type": "invoice_line_items"
          }]
        }
      }
    },
    "included": [{
      "id": "281730",
      "type": "locations",
      "attributes": {
        "name": "6400 Westown Pkwy, West Des Moines, IA 50266, USA",
        "latitude": 41.5933811,
        "longitude": -93.7962948,
        "address": "6400 Westown Parkway",
        "address2": "",
        "city": "West Des Moines",
        "state": "Iowa",
        "postal_code": "50266",
        "country": "United States",
        "formatted_address": "6400 Westown Pkwy, West Des Moines, IA 50266, USA",
        "timezone_id": "America/Chicago",
        "properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-93.797345818524, 41.592623738175], [-93.796102088361, 41.592607690333], [-93.795337658791, 41.592614820771], [-93.795528095631, 41.593488528785], [-93.79628159266, 41.59350959129], [-93.796326986508, 41.593675082158], [-93.796879114156, 41.593669064316], [-93.796908618455, 41.593242689551], [-93.797345818524, 41.592623738175]]]
            },
            "properties": {
              "name": "Polygon #1",
              "notes": "",
              "number": 1,
              "area": "14302.0",
              "bounds": {
                "south": 41.592607690333,
                "west": -93.797345818524,
                "north": 41.593675082158,
                "east": -93.795337658791
              },
              "center": {
                "lat": 41.593141386246,
                "lng": -93.796341738658
              }
            },
            "id": 1
          }]
        }
      }
    }, {
      "id": "4555",
      "type": "packages",
      "attributes": {
        "name": "Pro Package",
        "description": "Highlight your property and the surrounding area with a 60-second edited video that includes branding, captions, contact info, and music (also includes unedited images & video).",
        "price": 46900,
        "cloud_reqs": "less_than_50",
        "position": 4,
        "slug": "cre-pro-pack"
      },
      "relationships": {
        "vertical": {
          "data": {
            "id": "1",
            "type": "verticals"
          }
        },
        "organization": {
          "data": null
        },
        "client": {
          "data": null
        },
        "catalog_items": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "verticals",
      "attributes": {
        "name": "Commercial Real Estate",
        "short_name": "CRE"
      }
    }, {
      "id": "4898",
      "type": "credit_cards",
      "attributes": {
        "brand": "Visa",
        "last_4": "3969",
        "exp_month": 2,
        "exp_year": 2024,
        "stripe_id": "card_1FW88bJo202eeWudwLnrPXPF"
      }
    }, {
      "id": "46878",
      "type": "onsite_contacts",
      "attributes": {
        "name": "Joe Smith",
        "call_action": "Call When You Arrive",
        "phone": "123-456-7890",
        "note": null
      }
    }, {
      "id": "DBC3006554",
      "type": "clients",
      "attributes": {
        "first_name": "Demo",
        "last_name": "Zeitview",
        "phone": "",
        "email": "clients@zeitview.com",
        "token": "qC65CqCmbKfMZvIU2gRb_uD7Sqw",
        "company_name": null,
        "invoiceable": false
      },
      "relationships": {
        "hidden_sample_missions": {
          "data": [{
            "id": "*",
            "type": "hidden_sample_mission"
          }]
        }
      }
    }, {
      "id": "DBI8532371149",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581503-5872cefb2fa8dfbff7ea658c1885d20e27d7242b/original-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
        "name": "DBI-22-UTujz0vQ-downloadable-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
        "source_type": "edit",
        "formatted_size": "7.01 MB",
        "size": 7354035,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593836111111,
        "gps_longitude": -93.797358333333,
        "gps_altitude": 268.8,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581503-5872cefb2fa8dfbff7ea658c1885d20e27d7242b/large_1920-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581503-5872cefb2fa8dfbff7ea658c1885d20e27d7242b/medium_1024-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581503-5872cefb2fa8dfbff7ea658c1885d20e27d7242b/small_640-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581503-5872cefb2fa8dfbff7ea658c1885d20e27d7242b/four_three_640-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581503-5872cefb2fa8dfbff7ea658c1885d20e27d7242b/square_640-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581503-5872cefb2fa8dfbff7ea658c1885d20e27d7242b/downloadable-535ff7b4799b54f086ef13417e0bb269b3925519.JPG"
        },
        "created_on": "2019-10-23T00:37:17Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047826",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0458937384",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581408-e54b38f075a44c95d543ea71b0912036ec65a78b/original-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
        "name": "DBI-37-FWO8IpOK-downloadable-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
        "source_type": "edit",
        "formatted_size": "6.99 MB",
        "size": 7328091,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593958333333,
        "gps_longitude": -93.797041666667,
        "gps_altitude": 254.3,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581408-e54b38f075a44c95d543ea71b0912036ec65a78b/large_1920-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581408-e54b38f075a44c95d543ea71b0912036ec65a78b/medium_1024-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581408-e54b38f075a44c95d543ea71b0912036ec65a78b/small_640-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581408-e54b38f075a44c95d543ea71b0912036ec65a78b/four_three_640-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581408-e54b38f075a44c95d543ea71b0912036ec65a78b/square_640-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581408-e54b38f075a44c95d543ea71b0912036ec65a78b/downloadable-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG"
        },
        "created_on": "2019-10-23T00:19:21Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047828",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0419714054",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581407-e54b38f075a44c95d543ea71b0912036ec65a78b/original-85c01ca4fc8767e66107eb0e4c33965acfbc983f.JPG",
        "name": "DBI-36-5LxHwG3x-downloadable-85c01ca4fc8767e66107eb0e4c33965acfbc983f.JPG",
        "source_type": "edit",
        "formatted_size": "6.92 MB",
        "size": 7251344,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593116666667,
        "gps_longitude": -93.796538888889,
        "gps_altitude": 352.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581407-e54b38f075a44c95d543ea71b0912036ec65a78b/large_1920-85c01ca4fc8767e66107eb0e4c33965acfbc983f.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581407-e54b38f075a44c95d543ea71b0912036ec65a78b/medium_1024-85c01ca4fc8767e66107eb0e4c33965acfbc983f.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581407-e54b38f075a44c95d543ea71b0912036ec65a78b/small_640-85c01ca4fc8767e66107eb0e4c33965acfbc983f.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581407-e54b38f075a44c95d543ea71b0912036ec65a78b/four_three_640-85c01ca4fc8767e66107eb0e4c33965acfbc983f.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581407-e54b38f075a44c95d543ea71b0912036ec65a78b/square_640-85c01ca4fc8767e66107eb0e4c33965acfbc983f.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581407-e54b38f075a44c95d543ea71b0912036ec65a78b/downloadable-85c01ca4fc8767e66107eb0e4c33965acfbc983f.JPG"
        },
        "created_on": "2019-10-23T00:19:20Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047828",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4933297293",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581404-d8b888facd9f997ba85e39a887976b79d08540c4/original-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
        "name": "DBI-35-rULRVEkj-downloadable-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
        "source_type": "edit",
        "formatted_size": "6.86 MB",
        "size": 7190165,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.595311111111,
        "gps_longitude": -93.798283333333,
        "gps_altitude": 356.8,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581404-d8b888facd9f997ba85e39a887976b79d08540c4/large_1920-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581404-d8b888facd9f997ba85e39a887976b79d08540c4/medium_1024-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581404-d8b888facd9f997ba85e39a887976b79d08540c4/small_640-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581404-d8b888facd9f997ba85e39a887976b79d08540c4/four_three_640-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581404-d8b888facd9f997ba85e39a887976b79d08540c4/square_640-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581404-d8b888facd9f997ba85e39a887976b79d08540c4/downloadable-2f061394182882b945975bdb8fa14d5f936112ca.JPG"
        },
        "created_on": "2019-10-23T00:19:19Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3938178586",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581403-62f73bec992273915bc030dedab92db5bd665fd9/original-48d8f29a4b08d3dc615a93a4d071d94d316beb34.JPG",
        "name": "DBI-34-m6tAWs2l-downloadable-48d8f29a4b08d3dc615a93a4d071d94d316beb34.JPG",
        "source_type": "edit",
        "formatted_size": "6.88 MB",
        "size": 7214686,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.592963888889,
        "gps_longitude": -93.798202777778,
        "gps_altitude": 337.9,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581403-62f73bec992273915bc030dedab92db5bd665fd9/large_1920-48d8f29a4b08d3dc615a93a4d071d94d316beb34.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581403-62f73bec992273915bc030dedab92db5bd665fd9/medium_1024-48d8f29a4b08d3dc615a93a4d071d94d316beb34.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581403-62f73bec992273915bc030dedab92db5bd665fd9/small_640-48d8f29a4b08d3dc615a93a4d071d94d316beb34.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581403-62f73bec992273915bc030dedab92db5bd665fd9/four_three_640-48d8f29a4b08d3dc615a93a4d071d94d316beb34.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581403-62f73bec992273915bc030dedab92db5bd665fd9/square_640-48d8f29a4b08d3dc615a93a4d071d94d316beb34.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581403-62f73bec992273915bc030dedab92db5bd665fd9/downloadable-48d8f29a4b08d3dc615a93a4d071d94d316beb34.JPG"
        },
        "created_on": "2019-10-23T00:19:18Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9752919324",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581402-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/original-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
        "name": "DBI-33-mDcxYt7u-downloadable-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
        "source_type": "edit",
        "formatted_size": "6.73 MB",
        "size": 7060582,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593022222222,
        "gps_longitude": -93.798544444444,
        "gps_altitude": 338,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581402-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/large_1920-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581402-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/medium_1024-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581402-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/small_640-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581402-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/four_three_640-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581402-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/square_640-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581402-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/downloadable-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG"
        },
        "created_on": "2019-10-23T00:19:17Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI1962407297",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581401-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/original-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
        "name": "DBI-32-wJJ820e7-downloadable-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
        "source_type": "edit",
        "formatted_size": "7.22 MB",
        "size": 7575328,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.596194444444,
        "gps_longitude": -93.796425,
        "gps_altitude": 338.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581401-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/large_1920-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581401-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/medium_1024-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581401-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/small_640-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581401-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/four_three_640-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581401-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/square_640-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581401-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/downloadable-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG"
        },
        "created_on": "2019-10-23T00:19:17Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3442308017",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581400-6fbda4d9fe95f30a2caff16b49efe022ac37b61d/original-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
        "name": "DBI-31-khxpM9IQ-downloadable-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
        "source_type": "edit",
        "formatted_size": "6.94 MB",
        "size": 7272541,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.595638888889,
        "gps_longitude": -93.793852777778,
        "gps_altitude": 337.9,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581400-6fbda4d9fe95f30a2caff16b49efe022ac37b61d/large_1920-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581400-6fbda4d9fe95f30a2caff16b49efe022ac37b61d/medium_1024-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581400-6fbda4d9fe95f30a2caff16b49efe022ac37b61d/small_640-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581400-6fbda4d9fe95f30a2caff16b49efe022ac37b61d/four_three_640-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581400-6fbda4d9fe95f30a2caff16b49efe022ac37b61d/square_640-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581400-6fbda4d9fe95f30a2caff16b49efe022ac37b61d/downloadable-d84445703a5ee69913dea10b9cf11a92ff094870.JPG"
        },
        "created_on": "2019-10-23T00:19:16Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7990417916",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581399-f247a624036a7ea7e5cd5ee154416d208f0eac49/original-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
        "name": "DBI-30-F7TBOt01-downloadable-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
        "source_type": "edit",
        "formatted_size": "6.77 MB",
        "size": 7094349,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593408333333,
        "gps_longitude": -93.798991666667,
        "gps_altitude": 336,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581399-f247a624036a7ea7e5cd5ee154416d208f0eac49/large_1920-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581399-f247a624036a7ea7e5cd5ee154416d208f0eac49/medium_1024-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581399-f247a624036a7ea7e5cd5ee154416d208f0eac49/small_640-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581399-f247a624036a7ea7e5cd5ee154416d208f0eac49/four_three_640-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581399-f247a624036a7ea7e5cd5ee154416d208f0eac49/square_640-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581399-f247a624036a7ea7e5cd5ee154416d208f0eac49/downloadable-0682793e7996851c1da4c81b5963f908c5c2a539.JPG"
        },
        "created_on": "2019-10-23T00:19:15Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4274425053",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581398-349e25895296a9042f70fd1334beb012b1e0c679/original-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
        "name": "DBI-29-xQWWxsP9-downloadable-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
        "source_type": "edit",
        "formatted_size": "7.37 MB",
        "size": 7725460,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.592311111111,
        "gps_longitude": -93.798858333333,
        "gps_altitude": 335.3,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581398-349e25895296a9042f70fd1334beb012b1e0c679/large_1920-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581398-349e25895296a9042f70fd1334beb012b1e0c679/medium_1024-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581398-349e25895296a9042f70fd1334beb012b1e0c679/small_640-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581398-349e25895296a9042f70fd1334beb012b1e0c679/four_three_640-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581398-349e25895296a9042f70fd1334beb012b1e0c679/square_640-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581398-349e25895296a9042f70fd1334beb012b1e0c679/downloadable-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG"
        },
        "created_on": "2019-10-23T00:19:14Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8086126253",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581397-349e25895296a9042f70fd1334beb012b1e0c679/original-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
        "name": "DBI-28-P9D2dccV-downloadable-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
        "source_type": "edit",
        "formatted_size": "7.09 MB",
        "size": 7429308,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.592686111111,
        "gps_longitude": -93.797641666667,
        "gps_altitude": 290.5,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581397-349e25895296a9042f70fd1334beb012b1e0c679/large_1920-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581397-349e25895296a9042f70fd1334beb012b1e0c679/medium_1024-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581397-349e25895296a9042f70fd1334beb012b1e0c679/small_640-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581397-349e25895296a9042f70fd1334beb012b1e0c679/four_three_640-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581397-349e25895296a9042f70fd1334beb012b1e0c679/square_640-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581397-349e25895296a9042f70fd1334beb012b1e0c679/downloadable-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG"
        },
        "created_on": "2019-10-23T00:19:14Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6268348479",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581396-ad8af1144400c9ff055ba092f947d6567b85ee10/original-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
        "name": "DBI-27-bDA5O3Mk-downloadable-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
        "source_type": "edit",
        "formatted_size": "7.2 MB",
        "size": 7546071,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.595555555556,
        "gps_longitude": -93.793655555556,
        "gps_altitude": 337.9,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581396-ad8af1144400c9ff055ba092f947d6567b85ee10/large_1920-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581396-ad8af1144400c9ff055ba092f947d6567b85ee10/medium_1024-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581396-ad8af1144400c9ff055ba092f947d6567b85ee10/small_640-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581396-ad8af1144400c9ff055ba092f947d6567b85ee10/four_three_640-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581396-ad8af1144400c9ff055ba092f947d6567b85ee10/square_640-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581396-ad8af1144400c9ff055ba092f947d6567b85ee10/downloadable-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG"
        },
        "created_on": "2019-10-23T00:19:13Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI1057634445",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581395-7d3913aa146b0bb868f049ea4677e88174f710a7/original-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
        "name": "DBI-26-nNDC6xKx-downloadable-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
        "source_type": "edit",
        "formatted_size": "6.77 MB",
        "size": 7097909,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593319444444,
        "gps_longitude": -93.793633333333,
        "gps_altitude": 338,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581395-7d3913aa146b0bb868f049ea4677e88174f710a7/large_1920-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581395-7d3913aa146b0bb868f049ea4677e88174f710a7/medium_1024-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581395-7d3913aa146b0bb868f049ea4677e88174f710a7/small_640-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581395-7d3913aa146b0bb868f049ea4677e88174f710a7/four_three_640-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581395-7d3913aa146b0bb868f049ea4677e88174f710a7/square_640-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581395-7d3913aa146b0bb868f049ea4677e88174f710a7/downloadable-566c58054eb103640db99ce3c40d401d5e0d9646.JPG"
        },
        "created_on": "2019-10-23T00:19:12Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9959548548",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581394-b79f7d37e23de3891e8f449b661dbc1916cf199a/original-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
        "name": "DBI-25-ukxnxkry-downloadable-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
        "source_type": "edit",
        "formatted_size": "6.59 MB",
        "size": 6910822,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.592494444444,
        "gps_longitude": -93.796258333333,
        "gps_altitude": 274,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581394-b79f7d37e23de3891e8f449b661dbc1916cf199a/large_1920-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581394-b79f7d37e23de3891e8f449b661dbc1916cf199a/medium_1024-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581394-b79f7d37e23de3891e8f449b661dbc1916cf199a/small_640-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581394-b79f7d37e23de3891e8f449b661dbc1916cf199a/four_three_640-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581394-b79f7d37e23de3891e8f449b661dbc1916cf199a/square_640-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581394-b79f7d37e23de3891e8f449b661dbc1916cf199a/downloadable-0632da6d3944561fa734289f437dad64c1c8be12.JPG"
        },
        "created_on": "2019-10-23T00:19:11Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047826",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2285291220",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581393-afc0ea96e79d9a87932913b7c7b70a9f0b8d8685/original-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
        "name": "DBI-24-E2tw38OL-downloadable-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
        "source_type": "edit",
        "formatted_size": "7.24 MB",
        "size": 7590756,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.594547222222,
        "gps_longitude": -93.797491666667,
        "gps_altitude": 268.9,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581393-afc0ea96e79d9a87932913b7c7b70a9f0b8d8685/large_1920-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581393-afc0ea96e79d9a87932913b7c7b70a9f0b8d8685/medium_1024-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581393-afc0ea96e79d9a87932913b7c7b70a9f0b8d8685/small_640-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581393-afc0ea96e79d9a87932913b7c7b70a9f0b8d8685/four_three_640-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581393-afc0ea96e79d9a87932913b7c7b70a9f0b8d8685/square_640-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581393-afc0ea96e79d9a87932913b7c7b70a9f0b8d8685/downloadable-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG"
        },
        "created_on": "2019-10-23T00:19:10Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047826",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI1396105140",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581391-57cd36b2b1f3968ba56502048c3c010455536f1f/original-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
        "name": "DBI-22-aafW6v7i-downloadable-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
        "source_type": "edit",
        "formatted_size": "6.91 MB",
        "size": 7245785,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593527777778,
        "gps_longitude": -93.797575,
        "gps_altitude": 274.2,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581391-57cd36b2b1f3968ba56502048c3c010455536f1f/large_1920-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581391-57cd36b2b1f3968ba56502048c3c010455536f1f/medium_1024-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581391-57cd36b2b1f3968ba56502048c3c010455536f1f/small_640-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581391-57cd36b2b1f3968ba56502048c3c010455536f1f/four_three_640-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581391-57cd36b2b1f3968ba56502048c3c010455536f1f/square_640-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581391-57cd36b2b1f3968ba56502048c3c010455536f1f/downloadable-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG"
        },
        "created_on": "2019-10-23T00:19:09Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047826",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0048210078",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581390-0e8045cddbd1b86f69308eddcf9eba3cabc30a54/original-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
        "name": "DBI-21-47vp11zz-downloadable-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
        "source_type": "edit",
        "formatted_size": "7.3 MB",
        "size": 7652996,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593497222222,
        "gps_longitude": -93.797836111111,
        "gps_altitude": 290.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581390-0e8045cddbd1b86f69308eddcf9eba3cabc30a54/large_1920-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581390-0e8045cddbd1b86f69308eddcf9eba3cabc30a54/medium_1024-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581390-0e8045cddbd1b86f69308eddcf9eba3cabc30a54/small_640-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581390-0e8045cddbd1b86f69308eddcf9eba3cabc30a54/four_three_640-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581390-0e8045cddbd1b86f69308eddcf9eba3cabc30a54/square_640-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581390-0e8045cddbd1b86f69308eddcf9eba3cabc30a54/downloadable-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG"
        },
        "created_on": "2019-10-23T00:19:08Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047826",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6979528367",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581389-8d7b5f0c6b41ddebc8bc8189d3861581c825454d/original-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
        "name": "DBI-20-F1ZJA8fl-downloadable-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
        "source_type": "edit",
        "formatted_size": "7.09 MB",
        "size": 7430429,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593294444444,
        "gps_longitude": -93.794052777778,
        "gps_altitude": 295.7,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581389-8d7b5f0c6b41ddebc8bc8189d3861581c825454d/large_1920-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581389-8d7b5f0c6b41ddebc8bc8189d3861581c825454d/medium_1024-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581389-8d7b5f0c6b41ddebc8bc8189d3861581c825454d/small_640-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581389-8d7b5f0c6b41ddebc8bc8189d3861581c825454d/four_three_640-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581389-8d7b5f0c6b41ddebc8bc8189d3861581c825454d/square_640-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581389-8d7b5f0c6b41ddebc8bc8189d3861581c825454d/downloadable-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG"
        },
        "created_on": "2019-10-23T00:19:06Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047826",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6162164261",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/9581384-1d26b578718b26dfde0c4b7decfaa8543bbc2019/original-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
        "name": "DBI-18-LOvGFmfi-downloadable-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
        "source_type": "edit",
        "formatted_size": "7.39 MB",
        "size": 7747773,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.59275,
        "gps_longitude": -93.794780555556,
        "gps_altitude": 297.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581384-1d26b578718b26dfde0c4b7decfaa8543bbc2019/large_1920-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/9581384-1d26b578718b26dfde0c4b7decfaa8543bbc2019/medium_1024-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/9581384-1d26b578718b26dfde0c4b7decfaa8543bbc2019/small_640-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/9581384-1d26b578718b26dfde0c4b7decfaa8543bbc2019/four_three_640-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/9581384-1d26b578718b26dfde0c4b7decfaa8543bbc2019/square_640-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/9581384-1d26b578718b26dfde0c4b7decfaa8543bbc2019/downloadable-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG"
        },
        "created_on": "2019-10-23T00:18:28Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047826",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV5978712319",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-39-YawoYbBe-CRE_sample1.mp4",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "7290b1f514641eb6007dbf7befe989b5",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/171209-0d69ef9ab6f20ff175015163330a113ace790d36/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/171209-0d69ef9ab6f20ff175015163330a113ace790d36/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/171209-0d69ef9ab6f20ff175015163330a113ace790d36/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/171209-0d69ef9ab6f20ff175015163330a113ace790d36/DL-DBV-39-YawoYbBe-CRE_sample1.mp4"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047829",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV6202697679",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-27-fKp4ewLz-DL-DBUV003-Creative.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "a507993303c0ff136db8a2b7b4de3ba3",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170987-bf1f2f36529a0955d1945c4ae10940ea277995af/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170987-bf1f2f36529a0955d1945c4ae10940ea277995af/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170987-bf1f2f36529a0955d1945c4ae10940ea277995af/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170987-bf1f2f36529a0955d1945c4ae10940ea277995af/DL-DBV-27-fKp4ewLz-DL-DBUV003-Creative.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047825",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV6442836042",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-31-3j4TOney-DL-DBUV017-New-Orbit-2_1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "11e35a1b35abb24d9795cb894faec2a7",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170986-ff0052a37df56f149da884e5ecbde6b9b7db22df/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170986-ff0052a37df56f149da884e5ecbde6b9b7db22df/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170986-ff0052a37df56f149da884e5ecbde6b9b7db22df/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170986-ff0052a37df56f149da884e5ecbde6b9b7db22df/DL-DBV-31-3j4TOney-DL-DBUV017-New-Orbit-2_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047821",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV3509131718",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-26-7FTbteRb-DL-DBUV019-New-Rise-Up.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "23604fa072ada3518905a49545f9e448",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170985-b26d7e51c75267dc8968bd64bb4bc3bac2c64007/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170985-b26d7e51c75267dc8968bd64bb4bc3bac2c64007/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170985-b26d7e51c75267dc8968bd64bb4bc3bac2c64007/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170985-b26d7e51c75267dc8968bd64bb4bc3bac2c64007/DL-DBV-26-7FTbteRb-DL-DBUV019-New-Rise-Up.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047825",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV7288595718",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-25-MebD6NdI-DL-DBUV002-Creative-2.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "5b552848f9df20591040c318cf7e6405",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170984-e44cc6b28b30a465ae04abb52bd48e4159f64f5d/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170984-e44cc6b28b30a465ae04abb52bd48e4159f64f5d/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170984-e44cc6b28b30a465ae04abb52bd48e4159f64f5d/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170984-e44cc6b28b30a465ae04abb52bd48e4159f64f5d/DL-DBV-25-MebD6NdI-DL-DBUV002-Creative-2.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047825",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV5140566529",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-30-XRboM018-DL-DBUV016-New-Orbit-1_1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "d201647aa7366148c5067716ba073696",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170983-a3ffc6d25c527ba6ae1e12563bef988d832248d7/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170983-a3ffc6d25c527ba6ae1e12563bef988d832248d7/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170983-a3ffc6d25c527ba6ae1e12563bef988d832248d7/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170983-a3ffc6d25c527ba6ae1e12563bef988d832248d7/DL-DBV-30-XRboM018-DL-DBUV016-New-Orbit-1_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047821",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV5210648048",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-21-eu31tKR7-DL-DBUV001-Crane.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "58b2b4c77969e0f84919692b7757eb31",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170982-bfafb09b9a7cc3824ffc96ef674d9fd68510a046/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170982-bfafb09b9a7cc3824ffc96ef674d9fd68510a046/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170982-bfafb09b9a7cc3824ffc96ef674d9fd68510a046/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170982-bfafb09b9a7cc3824ffc96ef674d9fd68510a046/DL-DBV-21-eu31tKR7-DL-DBUV001-Crane.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047824",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV2334681977",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-29-t8O17bTc-DL-DBUV007-Orbit-1_1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "276a49ebf6c0785451327caf9899a642",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170981-01a543cfd37078f8cf85754f4d765b844d86106d/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170981-01a543cfd37078f8cf85754f4d765b844d86106d/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170981-01a543cfd37078f8cf85754f4d765b844d86106d/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170981-01a543cfd37078f8cf85754f4d765b844d86106d/DL-DBV-29-t8O17bTc-DL-DBUV007-Orbit-1_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047821",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV8916023667",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-21-M8wfOSLj-DL-DBUV000-Birdseye.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "1aa9485d105a566fc4fc202abb2bb042",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170980-9a50313913836c0d1407b42022742aa11f09f0c9/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170980-9a50313913836c0d1407b42022742aa11f09f0c9/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170980-9a50313913836c0d1407b42022742aa11f09f0c9/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170980-9a50313913836c0d1407b42022742aa11f09f0c9/DL-DBV-21-M8wfOSLj-DL-DBUV000-Birdseye.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047823",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV5967107038",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-21-wXLGrZlE-DL-DBUV012-Trucking-Right.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "5fefe226d43dc3995c9e6fcca3ccf27f",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170979-1ea87968112b67259b321bae78d7dd043b1e4ced/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170979-1ea87968112b67259b321bae78d7dd043b1e4ced/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170979-1ea87968112b67259b321bae78d7dd043b1e4ced/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170979-1ea87968112b67259b321bae78d7dd043b1e4ced/DL-DBV-21-wXLGrZlE-DL-DBUV012-Trucking-Right.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047822",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV6226527447",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-28-oAHkS2o3-DL-DBUV018-New-Orbit-3_1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "c1058738ba44e86c2734e3c117835033",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170978-179c348a62e02d13b21373dd921e2956467aeb0a/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170978-179c348a62e02d13b21373dd921e2956467aeb0a/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170978-179c348a62e02d13b21373dd921e2956467aeb0a/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170978-179c348a62e02d13b21373dd921e2956467aeb0a/DL-DBV-28-oAHkS2o3-DL-DBUV018-New-Orbit-3_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047821",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV1612140950",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-27-uJYXFLdI-DL-DBUV020-New-Trucking-Left_1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "6598698f918b901d9a63bb5791fd3d75",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170977-ad2cacfd04d8b9959bb70ddd9ce2a238a054fbd5/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170977-ad2cacfd04d8b9959bb70ddd9ce2a238a054fbd5/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170977-ad2cacfd04d8b9959bb70ddd9ce2a238a054fbd5/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170977-ad2cacfd04d8b9959bb70ddd9ce2a238a054fbd5/DL-DBV-27-uJYXFLdI-DL-DBUV020-New-Trucking-Left_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047820",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV1037353960",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-26-PWpO9oef-DL-DBUV021-New-Trucking-Right_1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "210342df13c9f8fe7c75d5623c7d5798",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170976-cdad93c8f8144ac8acc52685b267c233463f047c/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170976-cdad93c8f8144ac8acc52685b267c233463f047c/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170976-cdad93c8f8144ac8acc52685b267c233463f047c/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170976-cdad93c8f8144ac8acc52685b267c233463f047c/DL-DBV-26-PWpO9oef-DL-DBUV021-New-Trucking-Right_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047820",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV9191385824",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-25-kDmU3MYg-DL-DBUV011-Trucking-Left_1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "743a601a73ea9d7d20ac195739fb3122",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170975-8fb121663dbdf31b146d25834daf97eff8bf1a2b/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170975-8fb121663dbdf31b146d25834daf97eff8bf1a2b/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170975-8fb121663dbdf31b146d25834daf97eff8bf1a2b/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170975-8fb121663dbdf31b146d25834daf97eff8bf1a2b/DL-DBV-25-kDmU3MYg-DL-DBUV011-Trucking-Left_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047820",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV2625849645",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-24-67RwMKpR-DL-DBUV004-Dolly-2_1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "52549a5416ce3c2db15548cd1512891f",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170974-db7a8e7af70c445d702bb5d27de544eb8a0455bd/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170974-db7a8e7af70c445d702bb5d27de544eb8a0455bd/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170974-db7a8e7af70c445d702bb5d27de544eb8a0455bd/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170974-db7a8e7af70c445d702bb5d27de544eb8a0455bd/DL-DBV-24-67RwMKpR-DL-DBUV004-Dolly-2_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047819",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV1830534065",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-23-YsRtpRYV-DL-DBUV006-Dolly.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "ad0c13b3bd38132c5e5ed84db46e5004",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170973-63a6a728fa0dcf87e5e88d75e9857dce4828c59c/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170973-63a6a728fa0dcf87e5e88d75e9857dce4828c59c/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170973-63a6a728fa0dcf87e5e88d75e9857dce4828c59c/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170973-63a6a728fa0dcf87e5e88d75e9857dce4828c59c/DL-DBV-23-YsRtpRYV-DL-DBUV006-Dolly.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047819",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV2007362573",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-22-4EYCAWgM-DL-DBUV014-New-Dolly-2_1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "fed306d4dc47e6f50cc26e4d083c9060",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170972-83e0f8bd46c6ef59b129a131c695f6e1db624531/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170972-83e0f8bd46c6ef59b129a131c695f6e1db624531/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170972-83e0f8bd46c6ef59b129a131c695f6e1db624531/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170972-83e0f8bd46c6ef59b129a131c695f6e1db624531/DL-DBV-22-4EYCAWgM-DL-DBUV014-New-Dolly-2_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047819",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV2431580406",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-21-XrDEgWzS-DL-DBUV013-New-Dolly-1_1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "6bf9691b0d0ae635f7cd599950c5ea4f",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170971-b1c16676a7ce0280ef53bb1323a5e0e4cc9221c5/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170971-b1c16676a7ce0280ef53bb1323a5e0e4cc9221c5/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170971-b1c16676a7ce0280ef53bb1323a5e0e4cc9221c5/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170971-b1c16676a7ce0280ef53bb1323a5e0e4cc9221c5/DL-DBV-21-XrDEgWzS-DL-DBUV013-New-Dolly-1_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047819",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV9336746201",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-21-upyyhlpJ-DL-DBUV015-New-Dolly_1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "b059069d77be68a3968e4ccce88f094b",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/170969-94153a3f32cccfa8abe3a50a897e6e88442db174/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/170969-94153a3f32cccfa8abe3a50a897e6e88442db174/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/170969-94153a3f32cccfa8abe3a50a897e6e88442db174/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/170969-94153a3f32cccfa8abe3a50a897e6e88442db174/DL-DBV-21-upyyhlpJ-DL-DBUV015-New-Dolly_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1047819",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "948fe423b0ea2bde805fdefdc0676244",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-10-23T20:00:38Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "DBC4577234760",
      "type": "collaborators",
      "attributes": {
        "first_name": "Joan",
        "last_name": "Smith",
        "email": "clients@zeitview.com"
      }
    }, {
      "id": "1047829",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "274588",
        "shot_type_id": 22,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Final Assets"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "22",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV5978712319",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "22",
      "type": "shot_types",
      "attributes": {
        "name": "Final Assets",
        "description": "Use this shot to upload final edited assets for the customer. Not for use by pilots.\n",
        "video": null,
        "slug": "final-assets",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1047828",
      "type": "shots",
      "attributes": {
        "instructions": "(3) images of the front of building (straight on, angled left, angled right) from just below roof level with the *full building in frame.* Images with little to no roof in frame are a big point of emphasis our customers. If you need to adjust, back away from the property rather than increasing altitude *so that we don\u2019t see the top of the roof.*",
        "mission_id": "274588",
        "shot_type_id": 17,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Image - Front Close Up (full building in frame)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "17",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI0419714054",
            "type": "images"
          }, {
            "id": "DBI0458937384",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "17",
      "type": "shot_types",
      "attributes": {
        "name": "Image - Front Close Up (full building in frame)",
        "description": "* *If there are power lines do your best to avoid having them in frame. It is okay to fly higher or lower than roof level to avoid the power lines in the images.* ",
        "video": null,
        "slug": "front_shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "14",
            "type": "shot_type_sample_images"
          }, {
            "id": "15",
            "type": "shot_type_sample_images"
          }, {
            "id": "16",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1047827",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "274588",
        "shot_type_id": 24,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Images - Cardinal Directions (350 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "24",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI9752919324",
            "type": "images"
          }, {
            "id": "DBI4274425053",
            "type": "images"
          }, {
            "id": "DBI1962407297",
            "type": "images"
          }, {
            "id": "DBI4933297293",
            "type": "images"
          }, {
            "id": "DBI3938178586",
            "type": "images"
          }, {
            "id": "DBI3442308017",
            "type": "images"
          }, {
            "id": "DBI8086126253",
            "type": "images"
          }, {
            "id": "DBI6268348479",
            "type": "images"
          }, {
            "id": "DBI1057634445",
            "type": "images"
          }, {
            "id": "DBI7990417916",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "24",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (350 ft above ground level)",
        "description": "*If there are altitude restrictions in this airspace, please take these images at the highest allowable altitude instead of 350'. *",
        "video": null,
        "slug": "images-cardinal-directions-350-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "23",
            "type": "shot_type_sample_images"
          }, {
            "id": "24",
            "type": "shot_type_sample_images"
          }, {
            "id": "25",
            "type": "shot_type_sample_images"
          }, {
            "id": "26",
            "type": "shot_type_sample_images"
          }, {
            "id": "27",
            "type": "shot_type_sample_images"
          }, {
            "id": "28",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1047826",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "274588",
        "shot_type_id": 13,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Images - Cardinal Directions (100 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "13",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI8532371149",
            "type": "images"
          }, {
            "id": "DBI9959548548",
            "type": "images"
          }, {
            "id": "DBI2285291220",
            "type": "images"
          }, {
            "id": "DBI1396105140",
            "type": "images"
          }, {
            "id": "DBI0048210078",
            "type": "images"
          }, {
            "id": "DBI6979528367",
            "type": "images"
          }, {
            "id": "DBI6162164261",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "13",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (100 ft above ground level)",
        "description": "",
        "video": "",
        "slug": "images-cardinal-directions-100-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "17",
            "type": "shot_type_sample_images"
          }, {
            "id": "18",
            "type": "shot_type_sample_images"
          }, {
            "id": "19",
            "type": "shot_type_sample_images"
          }, {
            "id": "20",
            "type": "shot_type_sample_images"
          }, {
            "id": "21",
            "type": "shot_type_sample_images"
          }, {
            "id": "22",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1047825",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: focus on some point of interest or sign\n",
        "mission_id": "274588",
        "shot_type_id": 54,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Creative Video Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "54",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV6202697679",
            "type": "videos"
          }, {
            "id": "DBV3509131718",
            "type": "videos"
          }, {
            "id": "DBV7288595718",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "54",
      "type": "shot_types",
      "attributes": {
        "name": "Creative Video Shot",
        "description": null,
        "video": null,
        "slug": "getty-video-getty-video",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1047824",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: Rise + Reverse Away facing the front of the subject property.  \n",
        "mission_id": "274588",
        "shot_type_id": 10,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Rise + Reverse Away Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "10",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV5210648048",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "10",
      "type": "shot_types",
      "attributes": {
        "name": "Rise + Reverse Away Shot",
        "description": "",
        "video": "https://youtu.be/PwOvzZqQWkg",
        "slug": "rise-reverse-away-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1047823",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: Looking straight down at the center of the property, rise up vertically to maximum altitude. Go slow and steady. This will reveal property and property boundaries. If the property it too large to be revealed just show as much as you can from maximum altitude.  \n",
        "mission_id": "274588",
        "shot_type_id": 9,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Birds Eye Shots"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "9",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV8916023667",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "9",
      "type": "shot_types",
      "attributes": {
        "name": "Birds Eye Shots",
        "description": "",
        "video": "",
        "slug": "birds-eye-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1047822",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Truck (*left to right*) across the front of the property. The front of the property should take up the frame from ground to top of roof. Adjust distance accordingly.  \n",
        "mission_id": "274588",
        "shot_type_id": 2,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Trucking Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "2",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV5967107038",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "2",
      "type": "shot_types",
      "attributes": {
        "name": "Trucking Shot",
        "description": "",
        "video": "https://youtu.be/m-r79WAcDNs",
        "slug": "trucking-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1047821",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Large + high altitude Contextual Orbit shot around property (counterclockwise)  \n*Must have entire subject property in frame and your camera must be angled up enough so that we also have the horizon in frame.* The point is to provide context of the property in relation to the surrounding area. This will require you to fly at a higher altitude and farther off the property to get the required shot. Typically shot around 300 ft. Orbit should be slow + steady. No jerky camera movements.  \n",
        "mission_id": "274588",
        "shot_type_id": 8,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Orbit Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "8",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV6442836042",
            "type": "videos"
          }, {
            "id": "DBV5140566529",
            "type": "videos"
          }, {
            "id": "DBV2334681977",
            "type": "videos"
          }, {
            "id": "DBV6226527447",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "8",
      "type": "shot_types",
      "attributes": {
        "name": "Orbit Shot",
        "description": "",
        "video": "https://youtu.be/MzdF92gWXyU",
        "slug": "orbit-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1047820",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Truck (*right to left*) across the front of the property. The front of the property should take up the frame from ground to top of roof. Adjust the distance accordingly.  \n",
        "mission_id": "274588",
        "shot_type_id": 2,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Trucking Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "2",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV1612140950",
            "type": "videos"
          }, {
            "id": "DBV1037353960",
            "type": "videos"
          }, {
            "id": "DBV9191385824",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "1047819",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Slowly fly towards the front of the property at a constant altitude (10 feet over roof level) with your camera pointing straight (can have slight angle downward if needed). Keep the horizon flat. Fly completely over the property and the end of the property lot.  \n",
        "mission_id": "274588",
        "shot_type_id": 1,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Dolly Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "1",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV2625849645",
            "type": "videos"
          }, {
            "id": "DBV1830534065",
            "type": "videos"
          }, {
            "id": "DBV2007362573",
            "type": "videos"
          }, {
            "id": "DBV2431580406",
            "type": "videos"
          }, {
            "id": "DBV9336746201",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "shot_types",
      "attributes": {
        "name": "Dolly Shot",
        "description": "",
        "video": "https://youtu.be/H80sEIUHa7Y",
        "slug": "dolly-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "DBINV035899191541541",
      "type": "invoice_line_items",
      "attributes": {
        "reason": "base_package_price",
        "description": null,
        "amount": 46900,
        "created_on": "2019-10-23T00:02:17Z",
        "display_name": "Base Package Price"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "missions"
          }
        },
        "work_item": {
          "data": null
        }
      }
    }]
  };
  _exports.default = _default;
});