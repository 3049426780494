define("clients/routes/authentication-callback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),

    model() {
      const state = this.get('session').get('data.authenticated.state');

      if (state === '/login') {
        return this.transitionTo('/locations'); // get to the locations index list
      } else {
        return this.transitionTo(state); // get back to where you came from
      }
    }

  });

  _exports.default = _default;
});