define("clients/components/pano-viewer", ["exports", "clients/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PanoViewer;
  PanoViewer = Ember.Component.extend({
    didInsertElement: function () {
      return this.initPano();
    },
    panoramaObserver: Ember.observer('panorama', function () {
      var krpano, newPano;
      krpano = document.getElementById(this.get('krpanoId'));
      newPano = _environment.default.api.host + "/v1/clients/pano_xml/" + this.get('panorama.access_key');
      return krpano.call("loadpano(" + newPano + ");");
    }),
    initPano: function () {
      var krpanoId, krpanoReady;
      console.log(this.get('panorama.name'));
      krpanoId = "krpanoSWFObject" + this.get('panorama.access_key');
      this.set('krpanoId', krpanoId);
      $("#pano" + this.get('panorama.access_key')).on('click', function () {
        var krPano;
        krPano = document.getElementById(krpanoId);

        if (krPano.get('autorotate.isrotating')) {
          return krPano.call('autorotate.stop();');
        }
      });

      krpanoReady = function (krPano) {
        return krPano;
      };

      return embedpano({
        swf: '/panorama/krpano.swf',
        xml: _environment.default.api.host + "/v1/clients/pano_xml/" + this.get('panorama.access_key'),
        id: "krpanoSWFObject" + this.get('panorama.access_key'),
        target: "pano" + this.get('panorama.access_key'),
        mobilescale: 1.0,
        passQueryParameters: true,
        consolelog: true,
        onready: krpanoReady,
        mwheel: false
      });
    }
  });
  var _default = PanoViewer;
  _exports.default = _default;
});