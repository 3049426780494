define("clients/controllers/shares/mission", ["exports", "clients/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionController;
  MissionController = Ember.Controller.extend({
    queryParams: ['full_screen', 'active_pano'],
    full_screen: null,
    active_pano: null,
    init: function () {
      this._super();

      this.set('shareBarModel', this.store.createRecord('share-bar-model'));
      return this.get('shareBarModel').set('shareUrl', window.location);
    }
  });
  var _default = MissionController;
  _exports.default = _default;
});