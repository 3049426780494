define("clients/components/property-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    propertyNotes: Ember.computed('findArea', 'mapFeatures', 'parseNote', function () {
      let features = this.get('mapFeatures');

      if (features) {
        return features.filter(this.findArea).map(this.parseNote, this);
      }

      return null;
    }),
    parseNote: function (item, index) {
      return {
        number: index + 1,
        acres: (this.findArea(item) * 0.0002471).toFixed(2),
        sqrFeet: Math.round(this.findArea(item) * 10.76391).toLocaleString()
      };
    },
    findArea: function (item) {
      return item.properties.area;
    }
  });

  _exports.default = _default;
});