define("clients/templates/components/mission-group/remove-from-group-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t+HSah/f",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],null,[[\"title\",\"isSaving\"],[\"Remove from Group\",[35,3]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    The (\"],[1,[35,0,[\"length\"]]],[2,\") selected Missions will be removed from this group:\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"group-input\"],[12],[2,\"\\n    \"],[1,[35,1,[\"name\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"group-buttons\"],[12],[2,\"\\n      \"],[8,\"button\",[[4,[38,2],[[32,0],\"close\"],null]],[[\"@type\"],[\"secondary\"]],[[\"default\"],[{\"statements\":[[2,\"Cancel\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"button\",[[4,[38,2],[[32,0],\"save\"],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Confirm\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"selectedMissions\",\"group\",\"action\",\"isSaving\",\"mission-group/base-modal\"]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-group/remove-from-group-modal.hbs"
    }
  });

  _exports.default = _default;
});