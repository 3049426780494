define("clients/authenticators/devise", ["exports", "ember-simple-auth/authenticators/devise", "clients/config/environment"], function (_exports, _devise, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DeviseAuthenticator;
  DeviseAuthenticator = _devise.default.extend({
    serverTokenEndpoint: _environment.default.api.host + '/v1/authenticate',
    resourceName: 'client',
    makeRequest: function (data, options) {
      if (options == null) {
        options = {};
      }

      Ember.merge(options, {
        data: data,
        headers: {
          'X-API-TOKEN': _environment.default.api.app_token,
          'accept': 'application/json',
          'content-type': 'application/json'
        }
      });
      return this._super(data, options);
    }
  });
  var _default = DeviseAuthenticator;
  _exports.default = _default;
});