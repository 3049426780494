define("clients/templates/components/mission-share-bucket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5SLeJi0p",
    "block": "{\"symbols\":[\"attachment\"],\"statements\":[[10,\"div\"],[14,0,\"bucket-header\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"bucket-name\"],[12],[1,[35,3,[\"name\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"bucket-attachments\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3,[\"promotedAttachments\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"a\"],[24,0,\"attachment-item\"],[24,\"target\",\"_blank\"],[4,[38,0],[[32,0],\"openAttachment\",[32,1]],null],[12],[2,\"\\n\"],[6,[37,1],[[32,1,[\"isPreparing\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"loading-data\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"box\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"sk-circle\"],[12],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"spinner\"],[12],[2,\"\\n              \"],[10,\"div\"],[14,0,\"rect1\"],[12],[13],[2,\"\\n              \"],[10,\"div\"],[14,0,\"rect2\"],[12],[13],[2,\"\\n              \"],[10,\"div\"],[14,0,\"rect3\"],[12],[13],[2,\"\\n              \"],[10,\"div\"],[14,0,\"rect4\"],[12],[13],[2,\"\\n              \"],[10,\"div\"],[14,0,\"rect5\"],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[10,\"div\"],[14,0,\"item-icon\"],[12],[2,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/assets/images/report-cover.svg\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"item-bottom\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"name\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"date\"],[12],[2,\"Created \"],[1,[30,[36,2],[[32,1,[\"created_at\"]],\"MM/DD/YY\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"moment-format\",\"bucket\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-share-bucket.hbs"
    }
  });

  _exports.default = _default;
});