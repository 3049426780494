define("clients/helpers/error-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref) {
    let [errorList, separator] = _ref;
    separator = separator || ",";

    if (errorList) {
      return errorList.map(function (error) {
        return error.message;
      }).join(separator);
    }
  });

  _exports.default = _default;
});