define("clients/components/mission-view/mission-assets-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionAssetsView;
  MissionAssetsView = Ember.Component.extend({
    init: function () {
      this._super();

      if (this.get('totalAssetCount') > 200) {
        return this.set('assetsView', 'list');
      }
    },
    assetsView: 'thumb',
    showOrthoModal: false,
    orthoPhotoMap: {},
    canSeeAssets: Ember.computed('totalAssetCount', 'showAssets', function () {
      return this.get('totalAssetCount');
    }),
    totalAssetCount: Ember.computed('mission.images', 'mission.videos', 'mission.promotedAttachments', 'mission.ortho_photo_maps', 'mission.panoramas', function () {
      if (this.get('showAssets')) {
        return (this.get('mission.images.length') || 0) + (this.get('mission.videos').filterBy('assignedToShot', true).get('length') || 0) + (this.get('mission.panoramas').filterBy('source_type', 'edit').get('length') || 0) + (this.get('mission.promotedAttachments').get('length') || 0) + (this.get('mission.ortho_photo_maps').filterBy('source_type', 'edit').get('length') || 0);
      }
    }),
    showAssets: Ember.computed('mission.status', function () {
      return ['invoice_needed', 'invoiced', 'in_production', 'complete'].includes(this.get('mission.status'));
    }),
    canUploadAssets: Ember.computed('mission.package', 'mission.status', function () {
      return this.get('mission.status') === 'upload_required' && this.get('mission.package.order_flow') === 'client_upload';
    }),
    actions: {
      toggleAssetsView: function (view) {
        return this.set('assetsView', view);
      },
      toggleOrthoModal: function (ortho) {
        this.set('orthoPhotoMap', ortho);
        return this.toggleProperty('showOrthoModal');
      },
      goToUploadAction: function () {
        return this.get('goToUploadAction')(this.get('mission.id'));
      }
    }
  });
  var _default = MissionAssetsView;
  _exports.default = _default;
});