define("clients/services/geo_json_converter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    toGeoJson(mapFeatures) {
      if (!mapFeatures || mapFeatures.length === 0) return null;
      const collection = mapFeatures.map(mapFeature => {
        return {
          type: 'Feature',
          id: mapFeature.id,
          geometry: mapFeature.geometry,
          name: mapFeature.name,
          properties: mapFeature.properties
        };
      });
      return {
        type: 'FeatureCollection',
        features: collection
      };
    }

  });

  _exports.default = _default;
});