define("clients/utils/uploader/queue", ["exports", "clients/utils/uploader/file_proxy", "clients/utils/trim", "jquery"], function (_exports, _file_proxy, _trim, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const getHeader = function (headers, header) {
    const headerKeys = Ember.A(Object.keys(headers));
    const headerIdx = headerKeys.map(s => s.toLowerCase()).indexOf(header.toLowerCase());

    if (headerIdx !== -1) {
      headers[headerKeys[headerIdx]];
    }

    return null;
  };

  const UploaderQueue = Ember.ArrayProxy.extend({
    store: Ember.inject.service(),
    name: null,
    uploader: null,
    browsing: false,
    filteringFiles: false,
    // eslint-disable-next-line ember/require-computed-property-dependencies
    status: Ember.computed({
      get() {
        return this.get('uploader.state');
      }

    }),
    uploadsQueued: Ember.computed('length', 'uploading', function () {
      return this.get('length') && !this.get('uploading');
    }),
    uploading: Ember.computed('status', function () {
      return this.get('status') === plupload.UPLOADING;
    }),
    stopped: Ember.computed('status', function () {
      return this.get('status') === plupload.STOPPED;
    }),
    finished: Ember.computed('status', 'stopped', 'uploadsQueued', function () {
      return this.get('stopped') && !this.get('uploadsQueued');
    }),
    uploadCount: 0,
    numFilesUploading: 0,

    init() {
      this.set('content', Ember.A([]));
      return this._super();
    },

    disableBrowse(disable) {
      if (disable == null) {
        disable = true;
      }

      return this.get('uploader').disableBrowse(disable);
    },

    isFileSizeChunkableOnS3(fileSize) {
      return fileSize > 1024 * 1024 * 5;
    },

    configureUploader(config, airbrake) {
      if (config == null) {
        config = {};
      }

      const uploader = new plupload.Uploader(config);
      uploader.bind('FilesAdded', Ember.run.bind(this, 'filesAdded'));
      uploader.bind('FilesRemoved', Ember.run.bind(this, 'filesRemoved'));
      uploader.bind('BeforeUpload', Ember.run.bind(this, 'configureUpload'));
      uploader.bind('UploadProgress', Ember.run.bind(this, 'progressDidChange'));
      uploader.bind('FileUploaded', Ember.run.bind(this, 'fileUploaded'));
      uploader.bind('UploadComplete', Ember.run.bind(this, 'uploadComplete'));
      uploader.bind('Error', Ember.run.bind(this, 'onError'));
      uploader.bind('Browse', Ember.run.bind(this, 'onBrowse'));
      uploader.bind('FileFiltered', Ember.run.bind(this, 'onFileFiltered'));
      uploader.bind('StateChanged', Ember.run.bind(this, 'onStateChange'));
      uploader.bind('ChunkUploaded', Ember.run.bind(this, 'onChunkUploaded'));
      uploader.settings.browse_button = [config.browse_button];
      const settings = Ember.copy(uploader.settings);
      this.set('uploadCount', settings.uploadCount);

      if (!this.get('uploadCount')) {
        this.set('uploadCount', 0);
      }

      delete settings.uploadCount;
      this.set('settings', settings);
      this.set('uploader', uploader);
      this.set('airbrake', airbrake);
      uploader.init();
      return uploader;
    },

    onBrowse() {
      return this.set('browsing', true);
    },

    onFileFiltered() {
      return this.set('filteringFiles', true);
    },

    destroy() {
      this._super();

      this.get('uploader').unbindAll();
      this.set('content', Ember.A([]));
      return this.set('uploader', null);
    },

    clearNumUploading() {
      return this.numFilesUploading = 0;
    },

    refresh() {
      return this.get('uploader').refresh();
    },

    // eslint-disable-next-line ember/require-computed-property-dependencies
    size: Ember.computed(function () {
      return this.get('uploader.total.size') || 0;
    }),
    // eslint-disable-next-line ember/require-computed-property-dependencies
    loaded: Ember.computed(function () {
      return this.get('uploader.total.loaded') || 0;
    }),
    progress: Ember.computed('size', 'loaded', function () {
      const percent = this.get('loaded') / this.get('size') || 0;
      return Math.floor(percent * 100);
    }),

    dataURLtoBlob(dataurl) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new Blob([u8arr], {
        type: mime
      });
    },

    resizeThumbnail(file) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const maxW = 600;
      const maxH = 600;
      const img = document.createElement('img');

      img.onload = () => {
        const iw = img.width;
        const ih = img.height;
        const scale = Math.min(maxW / iw, maxH / ih);
        const iwScaled = iw * scale;
        const ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        context.drawImage(img, 0, 0, iwScaled, ihScaled);
        return file.set('thumbnail', URL.createObjectURL(this.dataURLtoBlob(canvas.toDataURL())));
      };

      img.src = URL.createObjectURL(file.content.getNative());
    },

    filesAdded(uploader, files) {
      this.set('browsing', false);
      this.set('filteringFiles', false);
      return (() => {
        const result = [];

        for (let file of files) {
          this.uploadCount += 1;
          this.numFilesUploading += 1;

          const filep = _file_proxy.default.create({
            uploadNumber: this.uploadCount,
            content: file,
            shot_id: this.get('shot_id')
          });

          if (this.get('target.showNativeThumbnail')) {
            this.resizeThumbnail(filep);
          }

          this.pushObject(filep);
          this.notifyPropertyChange('size');
          this.notifyPropertyChange('loaded');

          if (!uploader.settings.disableAutoUpload) {
            result.push(this.get('target').addAsset(filep));
          }
        }

        uploader.refresh();
        uploader.start();
        return result;
      })();
    },

    filesRemoved(uploader, files) {
      for (let file of Array.from(files)) {
        const filep = this.findBy('id', file.id);

        if (filep) {
          this.removeObject(filep);
        }
      }

      this.uploadCount -= 1;
      this.notifyPropertyChange('size');
      return this.notifyPropertyChange('loaded');
    },

    chunkedKey(file) {
      const filep = this.findBy('id', file.id);
      return `${file.base_key}${filep.get('finalFilename')}.tmp/${filep.get('finalFilename')}.${file.chunkIndex}`;
    },

    configureChunkChecker(uploader, file) {
      return uploader.settings.pre_chunk_upload_check = new Ember.RSVP.Promise(function (resolve, reject) {
        if (Array.from(uploader.settings.uploaded_chunks).includes(file.chunkIndex)) {
          return reject();
        } else {
          return resolve();
        }
      });
    },

    configureUpload(uploader, file) {
      const filep = this.findBy('id', file.id);
      uploader.settings = Ember.copy(this.get('settings'));
      uploader.settings.multipart_params = {
        "acl": "private",
        "success_action_status": "201"
      };
      file.isChunked = this.isFileSizeChunkableOnS3(file.size);
      this.preFetchS3SignedUrl(uploader, file, filep);
      return false;
    },

    preFetchS3SignedUrl(uploader, file, filep) {
      return filep.fetchS3SignedUrl(file.isChunked, filep.get('sanitizedName')).then(() => {
        Ember.assign(uploader.settings, filep.settings);
        file.status = plupload.UPLOADING;
        file.base_key = uploader.settings.base_key;

        if (file.isChunked) {
          file.chunkIndex = 0;
          uploader.settings.chunk_size = plupload.parseSize("10mb");
          delete uploader.settings.multipart_params.chunks;
          delete uploader.settings.multipart_params.chunk;
          uploader.settings.multipart_params.key = this.chunkedKey(file);
          this.configureChunkChecker(uploader, file);
        } else {
          uploader.settings.multipart_params.chunks = 0;
          uploader.settings.multipart_params.chunk = 0;
        }

        uploader.trigger("UploadFile", file);
        return this.progressDidChange(uploader, file);
      }, function () {} //ERROR
      );
    },

    onChunkUploaded(uploader, file) {
      file.chunkIndex++;
      this.configureChunkChecker(uploader, file);
      return uploader.settings.multipart_params.key = this.chunkedKey(file);
    },

    progressDidChange(uploader, file) {
      const filep = this.findBy('id', file.id);

      if (filep) {
        filep.notifyPropertyChanges();
      }

      this.notifyPropertyChange('size');
      this.notifyPropertyChange('loaded');
      return uploader.refresh();
    },

    fileUploaded(uploader, file, response) {
      const results = this.parseResponse(response);
      let filep = this.findBy('id', file.id);
      results.target_key = filep.get('sanitizedName');
      results.base_key = file.base_key;
      results.chunked = file.isChunked;

      if (file.isChunked) {
        results.full_url = `${uploader.settings.url}/${uploader.settings.base_key}${filep.get('finalFilename')}`;
      } else {
        results.full_url = results.headers.location;

        if (!results.headers.location) {
          this.get('airbrake').notify(new Error(`S3 not returning Location header. response: ${JSON.stringify(response)} - results: ${JSON.stringify(results)}`));
        }
      }

      filep = this.findBy('id', file.id);

      if (filep) {
        this.removeObject(filep);
      }

      if (results.status === 0 || Math.floor(results.status / 200) === 1) {
        filep._deferred.resolve(results);
      } else {
        filep._deferred.reject("Queue fileUploaded:138");
      }

      filep = null;
      return file = null;
    },

    uploadComplete() {
      Ember.run.later(this.get('uploader'), 'refresh', 750);
      this.notifyPropertyChange('loaded');
      return this.notifyPropertyChange('size');
    },

    onError(uploader, error) {
      if (error.file) {
        let filep = this.findBy('id', error.file.id);

        if (filep == null) {
          filep = _file_proxy.default.create({
            content: error.file
          });
        }

        filep.set('error', true);

        if (filep._deferred) {
          filep._deferred.reject(`Queue onError:157, error: ${JSON.stringify(error)}`);
        } else {
          filep.upload = filep.read = function () {
            Ember.run.debounce(this.get('uploader'), 'refresh', 750);
            return Ember.RSVP.reject(error, `File: '${error.file.id}' ${error.message}`);
          };

          filep.destroy();
          this.get('target').sendAction('onfileadd', filep);
        }

        this.notifyPropertyChange('length');
        return Ember.run.debounce(this.get('uploader'), 'refresh', 750);
      } else {
        this.set('error', error);
        return this.get('target').sendAction('onerror', error);
      }
    },

    onStateChange(uploader) {
      if (uploader.state === plupload.STOPPED) {
        uploader.total.reset();
        this.notifyPropertyChange('size');
        this.notifyPropertyChange('loaded');
      }

      return this.notifyPropertyChange('status');
    },

    parseResponse(response) {
      let body = (0, _trim.default)(response.response);
      const rawHeaders = Ember.A(response.responseHeaders.split(/\n|\r/)).without('');
      const headers = rawHeaders.reduce(function (E, header) {
        const parts = header.split(/^([A-Za-z_-]*:)/);

        if (parts.length > 0) {
          E[parts[1].slice(0, -1).toLowerCase()] = (0, _trim.default)(parts[2]);
        }

        return E;
      }, {});
      const contentType = (getHeader(headers, 'Content-Type') || '').split(';');

      if (contentType.indexOf('text/html') !== -1) {
        body = _jquery.default.parseHTML(body);
      } else if (contentType.indexOf('text/xml') !== -1) {
        body = _jquery.default.parseXML(body);
      } else if (contentType.indexOf('application/json') !== -1 || contentType.indexOf('text/javascript') !== -1 || contentType.indexOf('application/javascript') !== -1) {
        body = _jquery.default.parseJSON(body);
      }

      return {
        status: response.status,
        body,
        headers
      };
    }

  });
  var _default = UploaderQueue;
  _exports.default = _default;
});