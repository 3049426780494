define("clients/templates/components/mission-plan-shot-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dysWWdjK",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[11,\"select\"],[16,0,[34,3]],[4,[38,4],[[32,0],\"updateValue\"],[[\"on\"],[\"change\"]]],[12],[2,\"\\n  \"],[10,\"option\"],[14,2,\"\"],[12],[2,\"\\n    \"],[1,[34,5]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],[[\"key\"],[\"@identity\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"option\"],[15,2,[31,[[30,[36,1],[[32,1],[35,0]],null]]]],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,1],[35,2]],null]],[2,\"\\n     \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"optionValuePath\",\"read-path\",\"optionLabelPath\",\"selectClass\",\"action\",\"prompt\",\"content\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-plan-shot-select.hbs"
    }
  });

  _exports.default = _default;
});