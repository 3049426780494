define("clients/serializers/watcher", ["exports", "clients/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var WatcherSerializer;
  WatcherSerializer = _application.default.extend();
  var _default = WatcherSerializer;
  _exports.default = _default;
});