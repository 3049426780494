define("clients/templates/shares/mission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XadCAccS",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,6],null,[[\"shareType\",\"shareToken\",\"mission\",\"shareBarModel\",\"loginAction\",\"full_screen\",\"client\",\"openAttachmentAction\",\"active_pano\"],[\"mission\",[35,3,[\"shareToken\"]],[35,3,[\"share\",\"shareable\"]],[35,5],\"loginAction\",[35,4],[35,3,[\"client\"]],[30,[36,2],[[32,0],[35,1],\"openAttachment\"],null],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"active_pano\",\"send\",\"action\",\"model\",\"full_screen\",\"shareBarModel\",\"mission-share\"]}",
    "meta": {
      "moduleName": "clients/templates/shares/mission.hbs"
    }
  });

  _exports.default = _default;
});