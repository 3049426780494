define("clients/templates/components/mission-view/mission-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WTre2P+D",
    "block": "{\"symbols\":[],\"statements\":[[3,\" Mission view for Client Dash V2 \"],[2,\"\\n\"],[10,\"div\"],[14,0,\"mission-view\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col col-1\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"mission\",\"sampleMission\"],[[35,1],[35,0]]]]],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"mission\",\"sampleMission\"],[[35,1],[35,0]]]]],[2,\"\\n    \"],[1,[30,[36,8],null,[[\"mission\",\"client\",\"sampleMission\",\"sampleMissionId\",\"goToUploadAction\"],[[35,1],[35,7],[35,0],[35,6],[30,[36,5],[[32,0],\"goToUpload\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col col-2\"],[12],[2,\"\\n    \"],[1,[30,[36,9],null,[[\"mission\",\"sampleMission\"],[[35,1],[35,0]]]]],[2,\"\\n    \"],[1,[30,[36,10],null,[[\"client\",\"mission\",\"sampleMission\"],[[35,7],[35,1],[35,0]]]]],[2,\"\\n\"],[6,[37,11],[[35,7,[\"is_insights\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],null,[[\"mission\",\"sampleMission\"],[[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sampleMission\",\"mission\",\"mission-view/collaborator-view\",\"mission-view/mission-address-view\",\"mission-view/mission-details-view\",\"action\",\"sampleMissionId\",\"client\",\"mission-view/mission-assets-view\",\"mission-view/mission-timeline-view\",\"mission-view/client-details-view\",\"unless\"]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-view/mission-view.hbs"
    }
  });

  _exports.default = _default;
});