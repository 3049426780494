define("clients/components/input-formanced", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputFormancedComponent;
  InputFormancedComponent = Ember.Component.extend({
    errorMessages: {
      credit_card_number: 'Invalid credit card number',
      credit_card_expiry: 'Invalid credit card expiration',
      credit_card_cvc: 'Invalid cvc'
    },
    actions: {
      showErrors: function () {
        if (this.$('input').formance("validate_" + this.get('fieldType'))) {
          this.$('input').removeClass('error');
          return this.set('errors', null);
        } else {
          this.$('input').addClass('error');
          this.set("errors", this.get('errorMessage'));
          return this.set('showError', true);
        }
      }
    },
    didInsertElement: function () {
      this.$('input').formance("format_" + this.get('fieldType'));
      return this.set('errorMessage', this.errorMessages[this.get('fieldType')]);
    }
  });
  var _default = InputFormancedComponent;
  _exports.default = _default;
});