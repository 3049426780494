define("clients/controllers/dashboard/missions/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DashboardMissionsIndexController;
  DashboardMissionsIndexController = Ember.Controller.extend();
  var _default = DashboardMissionsIndexController;
  _exports.default = _default;
});