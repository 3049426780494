define("clients/components/DBMap/how-to", ["exports", "swiper"], function (_exports, _swiper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'how-to-container',
    classNameBindings: ['isOpen:opened'],
    isOpen: false,
    swiper: null,
    currentSlide: 0,
    isLastSlide: Ember.computed.equal('currentSlide', 3),
    didInsertElement: function () {
      _swiper.default.use([_swiper.Navigation, _swiper.Pagination]);

      this.set('swiper', new _swiper.default('.swiper-container', {
        pagination: {
          clickable: true,
          el: '.swiper-pagination',
          type: 'bullets'
        },
        simulateTouch: false,
        autoHeight: true,
        slidesPerView: 1,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next'
        }
      }));
      this.swiper.on('slideChange', swiper => {
        this.set('currentSlide', swiper.realIndex);
      });
    },
    actions: {
      open: function () {
        this.set('isOpen', true);
      },
      next: function () {
        this.swiper.slideNext();
      },
      close: function () {
        // immediately swipe to first slide after close css transition ends
        Ember.run.later(() => {
          this.swiper.slideTo(1, 1);
        }, 150);
        this.set('isOpen', false);
      }
    }
  });

  _exports.default = _default;
});