define("clients/components/order-flow/thankyou", ["exports", "clients/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    metrics: Ember.inject.service(),
    missionsLink: Ember.computed('account.{is_insights_buildings,organization.id}', function () {
      if (this.get('account.is_insights_buildings')) {
        return _environment.default.insights_endpoint + "/inspections?iorder=desc&ipage=1&isize=25&isortby=inspectionStart&vid=" + this.get('account.organization.id');
      } else {
        return '/locations';
      }
    }),
    requiresAuth0Login: Ember.computed('session.data.authenticated.{accessToken,idToken}', function () {
      // registered during orderflow
      return !Ember.isPresent(this.session.get('data.authenticated.accessToken')) && !Ember.isPresent(this.session.get('data.authenticated.idToken'));
    }),

    init() {
      this._super();

      this.metrics.trackPage({
        hitType: 'pageview',
        page: '/order_flow/order_complete'
      });

      if (this.sessionAccount.account) {
        return this.sessionAccount.account.then(account => {
          if (this.requiresAuth0Login) {
            this.session.set('isAuthenticated', false);
          }

          return this.set('account', account);
        });
      }
    },

    actions: {
      newOrder() {
        return this.newOrderAction();
      }

    }
  });

  _exports.default = _default;
});