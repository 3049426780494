define("clients/components/mission-view/collaborator-view", ["exports", "clients/validations/collaborator", "ember-changeset-validations", "ember-changeset"], function (_exports, _collaborator, _emberChangesetValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CollaboratorViewComponent;
  CollaboratorViewComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    initChangeSet: Ember.on('init', function () {
      this.set('newCollaborator', this.get('store').createRecord('collaborator'));
      return this.changeset = new _emberChangeset.default(this.get('newCollaborator'), (0, _emberChangesetValidations.default)(_collaborator.default), _collaborator.default, {
        skipValidate: true
      });
    }),
    actions: {
      submit: function (mission) {
        if (this.get('createMode')) {
          return this.send('save', mission);
        } else {
          return this.send('add', mission);
        }
      },
      "delete": function (collaborator, mission) {
        collaborator.destroyRecord();
        return this.resetForm();
      },
      cancel: function () {
        return this.resetForm();
      },
      add: function (mission) {
        var alreadyCollaborating, email;
        email = this.changeset.get('email');
        alreadyCollaborating = mission.get('collaborators').map(function (collaborator, index) {
          return collaborator.get('email');
        }).includes(email);

        if (alreadyCollaborating) {
          return this.set('duplicateInvite', true);
        } else {
          return this.changeset.validate('email').then(function (_this) {
            return function () {
              if (_this.changeset.get('isInvalid')) {
                return;
              }

              return _this.search(email).then(function (collaborator) {
                return _this.createCollaborator(mission, collaborator);
              });
            };
          }(this));
        }
      },
      save: function (mission) {
        var collaborator;
        collaborator = this.get('newCollaborator');
        collaborator.set('first_name', this.changeset.get('first_name'));
        collaborator.set('last_name', this.changeset.get('last_name'));
        collaborator.set('email', this.changeset.get('email'));
        return this.createCollaborator(mission, collaborator);
      }
    },
    createCollaborator: function (mission, collaborator) {
      collaborator.set('mission', mission);
      collaborator.set('justAdded', true);
      return this.changeset.validate().then(function (_this) {
        return function () {
          if (_this.changeset.get('isInvalid')) {
            return;
          }

          collaborator.save();
          return _this.resetForm();
        };
      }(this));
    },
    search: function (email) {
      return this.get('mission').store.query('collaborator', {
        email: email
      }).then(function (_this) {
        return function (records) {
          return new Ember.RSVP.Promise(function (resolve, reject) {
            var collaborator, user;

            if (records.get('length') > 0) {
              collaborator = _this.get('newCollaborator');
              user = records.objectAt(0);
              collaborator.set('first_name', user.get('first_name'));
              collaborator.set('last_name', user.get('last_name'));
              collaborator.set('email', user.get('email'));
              return resolve(collaborator);
            } else {
              _this.set('createMode', true);

              _this.set('duplicateInvite', false);

              return reject();
            }
          });
        };
      }(this));
    },
    resetForm: function () {
      this.set('createMode', false);
      this.changeset.set('first_name', '');
      this.changeset.set('last_name', '');
      this.changeset.set('email', '');
      this.set('newCollaborator', this.get('store').createRecord('collaborator'));
      return this.set('duplicateInvite', false);
    }
  });
  var _default = CollaboratorViewComponent;
  _exports.default = _default;
});