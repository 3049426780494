define("clients/controllers/insights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    roofReportExample: 'https://static1.squarespace.com/static/6229b8a65380461f8a76c246/t/63d2b64de8c9cd13e20af0cf/1674753615426/zeitview_roof_report_example.pdf',
    thermalReportExample: 'https://static1.squarespace.com/static/6229b8a65380461f8a76c246/t/63d3fc121122d450f6bccda7/1674837012880/zeitview_thermal_roof_report_example_final.pdf',
    measurementReportExample: 'https://static1.squarespace.com/static/6229b8a65380461f8a76c246/t/63c9d4e691a1292c8405285e/1674171627147/Zeitview_Sample_RoofMeasurementsReport_101520-low.pdf'
  });

  _exports.default = _default;
});