define("clients/templates/components/input-formanced", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NHRAhqdW",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,6],null,[[\"value\",\"focus-out\",\"type\",\"class\",\"placeholder\",\"pattern\"],[[35,5],[30,[36,4],[[32,0],\"showErrors\"],null],[35,3],\"form-control input-lg\",[35,2],[35,1]]]]],[2,\"\\n\"],[6,[37,8],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"error\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"error-spacing\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"errors\",\"pattern\",\"placeholder\",\"type\",\"action\",\"value\",\"input\",\"showError\",\"if\"]}",
    "meta": {
      "moduleName": "clients/templates/components/input-formanced.hbs"
    }
  });

  _exports.default = _default;
});