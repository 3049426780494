define("clients/templates/components/select-custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B/v1Ca9d",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[11,\"select\"],[16,0,[34,7]],[4,[38,8],[[32,0],\"changeValue\"],[[\"on\"],[\"change\"]]],[12],[2,\"\\n\"],[6,[37,9],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"option\"],[14,\"disabled\",\"\"],[15,\"selected\",[30,[36,5],[[35,2]],null]],[12],[2,\"\\n      \"],[1,[34,6]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,12],[[30,[36,11],[[30,[36,11],[[35,10]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"option\"],[15,2,[31,[[30,[36,1],[[32,1],[35,0]],null]]]],[15,\"selected\",[30,[36,3],[[32,1],[35,2]],null]],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,1],[35,4]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"optionValuePath\",\"read-path\",\"selection\",\"is-equal\",\"optionLabelPath\",\"is-not\",\"prompt\",\"selectClass\",\"action\",\"if\",\"content\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "clients/templates/components/select-custom.hbs"
    }
  });

  _exports.default = _default;
});