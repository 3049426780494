define("clients/models/utm-event", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    campaign: (0, _model.attr)('string'),
    term: (0, _model.attr)('string'),
    source: (0, _model.attr)('string'),
    medium: (0, _model.attr)('string'),
    content: (0, _model.attr)('string'),
    event_captured: (0, _model.attr)('string')
  });

  _exports.default = _default;
});