define("clients/mirage/config", ["exports", "clients/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */
    this.urlPrefix = _environment.default.api.host; // this.namespace = '';    // make this `/api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing

    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
       https://www.ember-cli-mirage.com/docs/route-handlers/shorthands
    */

    this.get('/v3/clients/mission_groups', schema => {
      return schema.missionGroups.all();
    });
    this.post('/v3/clients/mission_groups', schema => {
      return schema.missionGroups.first();
    });
    this.patch('/v3/clients/mission_groups/:id', (schema, request) => {
      return schema.missionGroups.find(request.params.id);
    });
    this.delete('/v3/clients/mission_groups/:id', () => {
      return {
        meta: {
          success: true
        }
      };
    });
    this.get('/v1/clients/account', schema => {
      return schema.clients.first();
    });
    this.patch('/v1/clients/account', schema => {
      return schema.clients.first();
    });
    this.get('/v1/clients/package_addons', schema => {
      return schema.packageAddons.all();
    });
    this.get('/v2/clients/missions', schema => {
      // Add pagination to response. But page-numbers doesn't work in the tests
      // const curPage = parseInt(request.params.page) || 1;
      // const perPage = 20;
      // const totalCount = schema.missions.all().length;
      // const totalPages = Math.ceil(totalCount / perPage);
      //
      // const missions = schema.missions.all().filter((_, i) => {
      //   return (i >= (curPage - 1) * perPage) && (i < (curPage * perPage));
      // });
      // let json = this.serializerOrRegistry.serialize(missions);
      //
      // json.meta = {
      //   page: curPage,
      //   per_page: perPage,
      //   total_pages: totalPages,
      //   total_count: totalCount
      // };
      //
      // json.links = {
      //   self: `${ENV.api.host}/v2/clients/missions?page=${curPage}`,
      //   first: `${ENV.api.host}/v2/clients/missions?page=1`,
      //   prev: curPage > 1 ? `${ENV.api.host}/v2/clients/missions?page=${curPage - 1}` : null,
      //   next: curPage < totalPages ? `${ENV.api.host}/v2/clients/missions?page=${curPage + 1}` : null,
      //   last: `${ENV.api.host}/v2/clients/missions?page=${totalPages}`,
      // };
      //
      // return json;
      return schema.missions.all();
    });
    this.get('/v3/clients/suggested_groups', function (_ref) {
      let {
        suggestedGroups
      } = _ref;
      return this.serialize(suggestedGroups.all()).suggestedGroups;
    });
    this.get('/v1/missions/packages', schema => {
      return schema.packages.all();
    });
    this.get('/v2/clients/missions/:id', (schema, request) => {
      return schema.missions.find(request.params.id);
    });
    this.get('/v1/clients/currencies', schema => {
      return schema.currencies.all();
    });
  }
});