define("clients/components/asset-share", ["exports", "clients/config/environment", "clients/data/sample-mission-share-link"], function (_exports, _environment, _sampleMissionShareLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AssetShareComponent;
  AssetShareComponent = Ember.Component.extend({
    metrics: Ember.inject.service(),
    buttonText: null,
    classNames: ["asset-share"],
    store: Ember.inject.service(),
    shareLink: Ember.computed('share', function () {
      if (this.get('sampleMissionId')) {
        return _sampleMissionShareLink.default[this.get('sampleMissionId')];
      } else {
        return this.get('share.link') || this.get('proxyShareLink');
      }
    }),
    init: function () {
      this._super();

      return $('.asset-share a').on('click', function (_this) {
        return function (e) {
          return _this.get('metrics').trackEvent({
            event: 'event',
            objectId: _this.get('shareable.id'),
            objectType: _this.get('shareable.constructor.modelName'),
            category: 'Sharing',
            action: 'share',
            label: "Shared Sharable - " + _this.get('shareable.constructor.modelName')
          });
        };
      }(this));
    },
    newShareLink: Ember.computed('share.link', function () {
      var url;
      url = new URL(this.get('share.link'));
      return window.location.origin + url.pathname;
    }),
    proxyShareLink: Ember.computed('shareable', function () {
      return _environment.default.api.host + "/v1/clients/proxy/" + this.get('shareable.id');
    }),
    actions: {
      openSharePage: function () {
        this.get('metrics').trackEvent({
          event: 'event',
          category: 'Mission Dashboard',
          action: 'Share Assets',
          label: this.get('shareable.id')
        });
        return window.open(this.get('shareLink'), '_blank');
      }
    }
  });
  var _default = AssetShareComponent;
  _exports.default = _default;
});