define("clients/data/sample_images/cre_starter_shopping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": [{
      "id": "DBI0961388337",
      "type": "image",
      "attributes": {
        "name": "DBI-38-FkYLNGeL-CRE-Images-Package---Shopping-Centers-Sample_DBI-53-1e11H3VD-Lakeside-Marketplace_DBU94-DJI_0077.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034066-c762a251a99348bd0bd133a0796152f69138932b/downloadable-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034066-c762a251a99348bd0bd133a0796152f69138932b/large_1920-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA2Ni1jNzYyYTI1MWE5OTM0OGJkMGJkMTMzYTA3OTYxNTJmNjkxMzg5MzJiL2xhcmdlXzE5MjAtNjIxNTViZDEwMTkzMzQyZDJmYmI4OGY2NTEyMDI5Zjc2ODY1MDcxYS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA2Ni1jNzYyYTI1MWE5OTM0OGJkMGJkMTMzYTA3OTYxNTJmNjkxMzg5MzJiL2xhcmdlXzE5MjAtNjIxNTViZDEwMTkzMzQyZDJmYmI4OGY2NTEyMDI5Zjc2ODY1MDcxYS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:40:48Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060824",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4605215017",
      "type": "image",
      "attributes": {
        "name": "DBI-37-AZvttwEQ-CRE-Images-Package---Shopping-Centers-Sample_DBI-58-9MohZxUx-Lakeside-Marketplace_DBU99-DJI_0088.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034065-c762a251a99348bd0bd133a0796152f69138932b/downloadable-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034065-c762a251a99348bd0bd133a0796152f69138932b/large_1920-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA2NS1jNzYyYTI1MWE5OTM0OGJkMGJkMTMzYTA3OTYxNTJmNjkxMzg5MzJiL2xhcmdlXzE5MjAtZGEzMmQyYzZmZmUwMGY2M2JjYmU2NjIxNmY1N2E0MDgzMmZkYTY0OS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA2NS1jNzYyYTI1MWE5OTM0OGJkMGJkMTMzYTA3OTYxNTJmNjkxMzg5MzJiL2xhcmdlXzE5MjAtZGEzMmQyYzZmZmUwMGY2M2JjYmU2NjIxNmY1N2E0MDgzMmZkYTY0OS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:40:48Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060824",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3314317848",
      "type": "image",
      "attributes": {
        "name": "DBI-36-sJQ99AF1-CRE-Images-Package---Shopping-Centers-Sample_DBI-49-GBkeyipm-Lakeside-Marketplace_DBU90-DJI_0073.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034064-c762a251a99348bd0bd133a0796152f69138932b/downloadable-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034064-c762a251a99348bd0bd133a0796152f69138932b/large_1920-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA2NC1jNzYyYTI1MWE5OTM0OGJkMGJkMTMzYTA3OTYxNTJmNjkxMzg5MzJiL2xhcmdlXzE5MjAtMzFmN2Y0N2NmNzk4OGI3YzgzYjJkODQ5NDkxYTBlMjljNWJkMTZlMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA2NC1jNzYyYTI1MWE5OTM0OGJkMGJkMTMzYTA3OTYxNTJmNjkxMzg5MzJiL2xhcmdlXzE5MjAtMzFmN2Y0N2NmNzk4OGI3YzgzYjJkODQ5NDkxYTBlMjljNWJkMTZlMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:40:48Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060824",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6461351850",
      "type": "image",
      "attributes": {
        "name": "DBI-34-9w29wmK1-CRE-Images-Package---Shopping-Centers-Sample_DBI-52-KjcTo8Vv-Lakeside-Marketplace_DBU93-DJI_0076.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034063-7ffe9fbe01faa163c7a12c15f21fa7b60d41f0d7/downloadable-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034063-7ffe9fbe01faa163c7a12c15f21fa7b60d41f0d7/large_1920-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA2My03ZmZlOWZiZTAxZmFhMTYzYzdhMTJjMTVmMjFmYTdiNjBkNDFmMGQ3L2xhcmdlXzE5MjAtMDlhMTc3MmVkYTU2NDAzYjUzZjdiOTZlZmY5MmRjNDY2Y2M3Yjc1ZS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA2My03ZmZlOWZiZTAxZmFhMTYzYzdhMTJjMTVmMjFmYTdiNjBkNDFmMGQ3L2xhcmdlXzE5MjAtMDlhMTc3MmVkYTU2NDAzYjUzZjdiOTZlZmY5MmRjNDY2Y2M3Yjc1ZS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:40:47Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060824",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8547453569",
      "type": "image",
      "attributes": {
        "name": "DBI-33-WlYhJAs3-CRE-Images-Package---Shopping-Centers-Sample_DBI-54-8efSASmw-Lakeside-Marketplace_DBU95-DJI_0079.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034062-51d4e6de606876d12b191be9b410173bb4e9af41/downloadable-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034062-51d4e6de606876d12b191be9b410173bb4e9af41/large_1920-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA2Mi01MWQ0ZTZkZTYwNjg3NmQxMmIxOTFiZTliNDEwMTczYmI0ZTlhZjQxL2xhcmdlXzE5MjAtZGEyYmVhMzE5YjMzMTliNWU2MmVmOWQ0MmU4ZmQ4MzExMmQ1NGE4Ny5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA2Mi01MWQ0ZTZkZTYwNjg3NmQxMmIxOTFiZTliNDEwMTczYmI0ZTlhZjQxL2xhcmdlXzE5MjAtZGEyYmVhMzE5YjMzMTliNWU2MmVmOWQ0MmU4ZmQ4MzExMmQ1NGE4Ny5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:40:22Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060823",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8927383984",
      "type": "image",
      "attributes": {
        "name": "DBI-32-LVQaqJwL-CRE-Images-Package---Shopping-Centers-Sample_DBI-47-Mqr74Qkf-Lakeside-Marketplace_DBU88-DJI_0070.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034061-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/downloadable-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034061-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/large_1920-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA2MS0zY2QyZWM3M2QzZTE5MDk1MDVlYjk4YzYwZWY3MjNlZTI1MjdlZTA5L2xhcmdlXzE5MjAtZTY0MzQxMTU5YTI1YmI0YTk4Njg0ZGQ1YWIyNjY0YjkxZWVmNGJlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA2MS0zY2QyZWM3M2QzZTE5MDk1MDVlYjk4YzYwZWY3MjNlZTI1MjdlZTA5L2xhcmdlXzE5MjAtZTY0MzQxMTU5YTI1YmI0YTk4Njg0ZGQ1YWIyNjY0YjkxZWVmNGJlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:40:21Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060823",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI9616606698",
      "type": "image",
      "attributes": {
        "name": "DBI-29-mZdGmnhV-CRE-Images-Package---Shopping-Centers-Sample_DBI-57-TgwqvXuM-Lakeside-Marketplace_DBU98-DJI_0087.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034058-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/downloadable-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034058-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/large_1920-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA1OC0zY2QyZWM3M2QzZTE5MDk1MDVlYjk4YzYwZWY3MjNlZTI1MjdlZTA5L2xhcmdlXzE5MjAtMTg4YzRkOWI5ZWY5Nzc3NTVkZTE4YmViZDE4NzA3YmI3NzJmMDhjMS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA1OC0zY2QyZWM3M2QzZTE5MDk1MDVlYjk4YzYwZWY3MjNlZTI1MjdlZTA5L2xhcmdlXzE5MjAtMTg4YzRkOWI5ZWY5Nzc3NTVkZTE4YmViZDE4NzA3YmI3NzJmMDhjMS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:40:21Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060823",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI0000867195",
      "type": "image",
      "attributes": {
        "name": "DBI-18-NEbZA7L2-CRE-Images-Package---Shopping-Centers-Sample_DBI-27-JGEYmlMO-Lakeside-Marketplace_DBU68-E_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034047-db7dee70410497eaf8f8246821098cc34b833cf8/downloadable-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034047-db7dee70410497eaf8f8246821098cc34b833cf8/large_1920-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0Ny1kYjdkZWU3MDQxMDQ5N2VhZjhmODI0NjgyMTA5OGNjMzRiODMzY2Y4L2xhcmdlXzE5MjAtZmQzNDg2NWI2ZDFhOGQ3ODRlYzY3MWU3MjJiZGQwMWQzZmE0MmY3ZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0Ny1kYjdkZWU3MDQxMDQ5N2VhZjhmODI0NjgyMTA5OGNjMzRiODMzY2Y4L2xhcmdlXzE5MjAtZmQzNDg2NWI2ZDFhOGQ3ODRlYzY3MWU3MjJiZGQwMWQzZmE0MmY3ZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:39:38Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060821",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8347933228",
      "type": "image",
      "attributes": {
        "name": "DBI-17-vENyagRr-CRE-Images-Package---Shopping-Centers-Sample_DBI-35-3rht1dJV-Lakeside-Marketplace_DBU76-FRONT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034046-db7dee70410497eaf8f8246821098cc34b833cf8/downloadable-762384d67608457afc248fde66c893da7f918496.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034046-db7dee70410497eaf8f8246821098cc34b833cf8/large_1920-762384d67608457afc248fde66c893da7f918496.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0Ni1kYjdkZWU3MDQxMDQ5N2VhZjhmODI0NjgyMTA5OGNjMzRiODMzY2Y4L2xhcmdlXzE5MjAtNzYyMzg0ZDY3NjA4NDU3YWZjMjQ4ZmRlNjZjODkzZGE3ZjkxODQ5Ni5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0Ni1kYjdkZWU3MDQxMDQ5N2VhZjhmODI0NjgyMTA5OGNjMzRiODMzY2Y4L2xhcmdlXzE5MjAtNzYyMzg0ZDY3NjA4NDU3YWZjMjQ4ZmRlNjZjODkzZGE3ZjkxODQ5Ni5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:39:38Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060821",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4351558565",
      "type": "image",
      "attributes": {
        "name": "DBI-16-bmwyEZPR-CRE-Images-Package---Shopping-Centers-Sample_DBI-34-Yb0qjasr-Lakeside-Marketplace_DBU75-W_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034045-db7dee70410497eaf8f8246821098cc34b833cf8/downloadable-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034045-db7dee70410497eaf8f8246821098cc34b833cf8/large_1920-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0NS1kYjdkZWU3MDQxMDQ5N2VhZjhmODI0NjgyMTA5OGNjMzRiODMzY2Y4L2xhcmdlXzE5MjAtOTczMWYwY2JkOWFmZjFhZDg4OTI0NGI5NTkyMjNlYzhiNDZkOGNiMi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0NS1kYjdkZWU3MDQxMDQ5N2VhZjhmODI0NjgyMTA5OGNjMzRiODMzY2Y4L2xhcmdlXzE5MjAtOTczMWYwY2JkOWFmZjFhZDg4OTI0NGI5NTkyMjNlYzhiNDZkOGNiMi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:39:38Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060821",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI2854602987",
      "type": "image",
      "attributes": {
        "name": "DBI-15-HwKAXnfl-CRE-Images-Package---Shopping-Centers-Sample_DBI-31-zGjKtYXQ-Lakeside-Marketplace_DBU72-S_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034044-db7dee70410497eaf8f8246821098cc34b833cf8/downloadable-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034044-db7dee70410497eaf8f8246821098cc34b833cf8/large_1920-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0NC1kYjdkZWU3MDQxMDQ5N2VhZjhmODI0NjgyMTA5OGNjMzRiODMzY2Y4L2xhcmdlXzE5MjAtN2I2NWM2YWFjODBiMWViZDRjMjQ1YzViYzkyZWFjN2FmYzQyYTJlMC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0NC1kYjdkZWU3MDQxMDQ5N2VhZjhmODI0NjgyMTA5OGNjMzRiODMzY2Y4L2xhcmdlXzE5MjAtN2I2NWM2YWFjODBiMWViZDRjMjQ1YzViYzkyZWFjN2FmYzQyYTJlMC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:39:38Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060821",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3471752043",
      "type": "image",
      "attributes": {
        "name": "DBI-14-BdRTdv2X-CRE-Images-Package---Shopping-Centers-Sample_DBI-29-Q4idBa2p-Lakeside-Marketplace_DBU70-NE_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034043-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/downloadable-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034043-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/large_1920-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0My02YzU2MWZjYjBjOGE0M2UzZTIxYTZiZGU0Y2YxYWIzM2VkZGNkMmFhL2xhcmdlXzE5MjAtNDkyZDBiMjJmMTBkOTAwZWYxMjM4NDE4MjZlNjZkZTk3MDU5MThkOC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0My02YzU2MWZjYjBjOGE0M2UzZTIxYTZiZGU0Y2YxYWIzM2VkZGNkMmFhL2xhcmdlXzE5MjAtNDkyZDBiMjJmMTBkOTAwZWYxMjM4NDE4MjZlNjZkZTk3MDU5MThkOC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:39:37Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060821",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6976490758",
      "type": "image",
      "attributes": {
        "name": "DBI-13-Zry0H7jF-CRE-Images-Package---Shopping-Centers-Sample_DBI-28-zNfK8rO0-Lakeside-Marketplace_DBU69-N_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034042-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/downloadable-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034042-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/large_1920-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0Mi02YzU2MWZjYjBjOGE0M2UzZTIxYTZiZGU0Y2YxYWIzM2VkZGNkMmFhL2xhcmdlXzE5MjAtODQxMDQwNDNmNmJiOWJkZmZlMTM0NGZlMjJjMDgxMDY3MmY2MDU4ZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0Mi02YzU2MWZjYjBjOGE0M2UzZTIxYTZiZGU0Y2YxYWIzM2VkZGNkMmFhL2xhcmdlXzE5MjAtODQxMDQwNDNmNmJiOWJkZmZlMTM0NGZlMjJjMDgxMDY3MmY2MDU4ZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:39:37Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060821",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4430421258",
      "type": "image",
      "attributes": {
        "name": "DBI-12-26Ioip2B-CRE-Images-Package---Shopping-Centers-Sample_DBI-32-nu7toZ8x-Lakeside-Marketplace_DBU73-SE_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034041-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/downloadable-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034041-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/large_1920-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0MS02YzU2MWZjYjBjOGE0M2UzZTIxYTZiZGU0Y2YxYWIzM2VkZGNkMmFhL2xhcmdlXzE5MjAtZGI0MWY3ZjhkMjg2MjBkMjI4MWJlYWRiY2UzN2E4NGM0MGY4YzhhNy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0MS02YzU2MWZjYjBjOGE0M2UzZTIxYTZiZGU0Y2YxYWIzM2VkZGNkMmFhL2xhcmdlXzE5MjAtZGI0MWY3ZjhkMjg2MjBkMjI4MWJlYWRiY2UzN2E4NGM0MGY4YzhhNy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:39:37Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060821",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI1035800300",
      "type": "image",
      "attributes": {
        "name": "DBI-30-KOWpKD9Z-CRE-Images-Package---Shopping-Centers-Sample_DBI-50-paYmqqka-Lakeside-Marketplace_DBU91-DJI_0074.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034040-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/downloadable-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034040-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/large_1920-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0MC0zZmNiNjdiYjkzYTA2YjZkYmFhODIwZDBjYzc1NWNmYWIxZjhmZTUxL2xhcmdlXzE5MjAtOWNjOGIzY2RmMmI1ODdjZGM3YjBjNGZjZWRmOGQyOGZiNDZlMzlkYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA0MC0zZmNiNjdiYjkzYTA2YjZkYmFhODIwZDBjYzc1NWNmYWIxZjhmZTUxL2xhcmdlXzE5MjAtOWNjOGIzY2RmMmI1ODdjZGM3YjBjNGZjZWRmOGQyOGZiNDZlMzlkYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:37:52Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060820",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6787202082",
      "type": "image",
      "attributes": {
        "name": "DBI-29-181as2rw-CRE-Images-Package---Shopping-Centers-Sample_DBI-36-wOLh2iGJ-Lakeside-Marketplace_DBU77-DJI_0055.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034039-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/downloadable-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034039-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/large_1920-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzOS0zZmNiNjdiYjkzYTA2YjZkYmFhODIwZDBjYzc1NWNmYWIxZjhmZTUxL2xhcmdlXzE5MjAtYTUyMThmNTg3MGI0NmUzZDliMjM1YzBlYjRmYmVhYTU0NWVjNWIyYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzOS0zZmNiNjdiYjkzYTA2YjZkYmFhODIwZDBjYzc1NWNmYWIxZjhmZTUxL2xhcmdlXzE5MjAtYTUyMThmNTg3MGI0NmUzZDliMjM1YzBlYjRmYmVhYTU0NWVjNWIyYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:37:52Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060820",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI7935423026",
      "type": "image",
      "attributes": {
        "name": "DBI-28-JTkImIEF-CRE-Images-Package---Shopping-Centers-Sample_DBI-51-n6hpnhi5-Lakeside-Marketplace_DBU92-DJI_0075.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034038-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/downloadable-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034038-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/large_1920-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzOC0zZmNiNjdiYjkzYTA2YjZkYmFhODIwZDBjYzc1NWNmYWIxZjhmZTUxL2xhcmdlXzE5MjAtZDM1YWYxMTZhZDhmZmFjNmEzOTI1NjRlMWUzY2Q1YWQ5OWQ3OTNkYS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzOC0zZmNiNjdiYjkzYTA2YjZkYmFhODIwZDBjYzc1NWNmYWIxZjhmZTUxL2xhcmdlXzE5MjAtZDM1YWYxMTZhZDhmZmFjNmEzOTI1NjRlMWUzY2Q1YWQ5OWQ3OTNkYS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:37:52Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060820",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3258187517",
      "type": "image",
      "attributes": {
        "name": "DBI-27-CDklnGBi-CRE-Images-Package---Shopping-Centers-Sample_DBI-33-egnffXbz-Lakeside-Marketplace_DBU74-SW_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034037-1d3180ce79d42efddb4018d6ba92408214f1f6af/downloadable-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034037-1d3180ce79d42efddb4018d6ba92408214f1f6af/large_1920-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzNy0xZDMxODBjZTc5ZDQyZWZkZGI0MDE4ZDZiYTkyNDA4MjE0ZjFmNmFmL2xhcmdlXzE5MjAtNThiNTZlZDJjMDU0YzUxM2I4MDM5MDhlMmM2MjE3MjQ4ODEzODFhMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzNy0xZDMxODBjZTc5ZDQyZWZkZGI0MDE4ZDZiYTkyNDA4MjE0ZjFmNmFmL2xhcmdlXzE5MjAtNThiNTZlZDJjMDU0YzUxM2I4MDM5MDhlMmM2MjE3MjQ4ODEzODFhMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:37:51Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060820",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6107705341",
      "type": "image",
      "attributes": {
        "name": "DBI-26-RHIdKsod-CRE-Images-Package---Shopping-Centers-Sample_DBI-30-6Cem6m7R-Lakeside-Marketplace_DBU71-NW_100FTR.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034036-1d3180ce79d42efddb4018d6ba92408214f1f6af/downloadable-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034036-1d3180ce79d42efddb4018d6ba92408214f1f6af/large_1920-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzNi0xZDMxODBjZTc5ZDQyZWZkZGI0MDE4ZDZiYTkyNDA4MjE0ZjFmNmFmL2xhcmdlXzE5MjAtOTk5ZDRmYTBhNTVlNjkzZTA3NWM2ZGJhOTc2OTJjN2IwZjVmZmNiMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzNi0xZDMxODBjZTc5ZDQyZWZkZGI0MDE4ZDZiYTkyNDA4MjE0ZjFmNmFmL2xhcmdlXzE5MjAtOTk5ZDRmYTBhNTVlNjkzZTA3NWM2ZGJhOTc2OTJjN2IwZjVmZmNiMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:37:51Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060820",
            "type": "shot"
          }
        }
      }
    }]
  };
  _exports.default = _default;
});