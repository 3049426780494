define("clients/components/input-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isSearchResultPresent: Ember.computed('searchResults.[]', function () {
      return Ember.isPresent(this.searchResults);
    }),
    debouncer: null,

    init() {
      this._super(...arguments);

      this.searchResults = this.searchResults || [];
    },

    actions: {
      showSuggestions() {
        this.set('isShowingSuggestions', true);
      },

      debouncedUpdateSearchValue() {
        Ember.run.cancel(this.debouncer);
        const debounceCall = Ember.run.debounce(this.manualInput(), 300);
        this.set('debouncer', debounceCall);
      },

      manualInput() {
        this.onInput();
      },

      selectItem(searchValue) {
        this.set('searchResults', []);
        Ember.run.later(() => {
          this.set('searchValue', searchValue);
          this.onchangeAction(this.searchValue);
        });
      },

      outsideClick() {
        this.set('isShowingSuggestions', false);
      }

    }
  });

  _exports.default = _default;
});