define("clients/routes/missions/receipt", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "clients/mixins/css-class-namespace"], function (_exports, _authenticatedRouteMixin, _cssClassNamespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _cssClassNamespace.default, {
    sessionAccount: Ember.inject.service(),
    setupController: function (controller, model) {
      this._super(controller, model);

      model.mission.loadInvoiceLineItems();
    },
    model: function (params) {
      return Ember.RSVP.hash({
        mission: this.store.find('mission', params.mission_id),
        client: this.sessionAccount.account
      });
    }
  });

  _exports.default = _default;
});