define("clients/models/invoice-line-item", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created_on: (0, _model.attr)('date'),
    reason: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    amount: (0, _model.attr)('number'),
    currency: (0, _model.belongsTo)('currency', {
      async: false
    }),
    display_name: (0, _model.attr)('string'),
    mission: (0, _model.belongsTo)('mission'),
    work_item: (0, _model.belongsTo)('work-item', {
      async: false
    })
  });

  _exports.default = _default;
});