define("clients/components/mission-view/add-ref-id-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AddRefIdModalComponent;
  AddRefIdModalComponent = Ember.Component.extend({
    init: function () {
      this._super();

      return this.set('referenceId', this.get('mission.reference_id'));
    },
    actions: {
      close: function () {
        return this.set('showModal', false);
      },
      save: function () {
        this.set('isSaving', true);
        this.set('mission.reference_id', this.get("referenceId"));
        return this.get('mission').save().then(function (_this) {
          return function () {
            _this.set('isSaving', false);

            return _this.set('showModal', false);
          };
        }(this))["catch"](function (_this) {
          return function () {
            return _this.set('isSaving', false);
          };
        }(this));
      }
    }
  });
  var _default = AddRefIdModalComponent;
  _exports.default = _default;
});