define("clients/templates/shares/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UKdDLtau",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"shareType\",\"mission\",\"shareBarModel\",\"shareToken\",\"currentShare\"],[\"image\",[35,3],[35,2],[35,1,[\"shareToken\"]],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currentShare\",\"model\",\"shareBarModel\",\"mission\",\"mission-share\"]}",
    "meta": {
      "moduleName": "clients/templates/shares/image.hbs"
    }
  });

  _exports.default = _default;
});