define("clients/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vYLU5fT5",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"loading-pane loading-data\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"box\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sk-circle\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"spinner\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect1\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect2\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect3\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect4\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect5\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "clients/templates/login.hbs"
    }
  });

  _exports.default = _default;
});