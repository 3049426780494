define("clients/routes/dashboard", ["exports", "clients/config/environment", "clients/mixins/mission-share", "ember-simple-auth/mixins/authenticated-route-mixin", "clients/mixins/css-class-namespace", "clients/mixins/inner-application-route-setup-controller-mixin"], function (_exports, _environment, _missionShare, _authenticatedRouteMixin, _cssClassNamespace, _innerApplicationRouteSetupControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _missionShare.default, _cssClassNamespace.default, _innerApplicationRouteSetupControllerMixin.default, {
    sessionAccount: Ember.inject.service(),

    redirect(model, transition) {
      if (transition.targetName === 'dashboard.index') {
        return this.transitionTo('dashboard.locations.index');
      }
    },

    metrics: Ember.inject.service(),

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('showRegisterModal', this.controllerFor('application').get('showRegisterModal'));
      this.controllerFor('application').set('showRegisterModal', false);
      return this.get('sessionAccount.account').then(client => {
        if (client.is_insights_buildings) {
          const insight_user_management_url = `${_environment.default.insights_endpoint}/inspections?iorder=desc&ipage=1&isize=25&isortby=inspectionStart&vid=${client.organization.id}`;
          window.location = insight_user_management_url;
        }

        this.controllerFor('application').set('showInsightsBanner', client.get('feature_flags.show_insights_banner'));
        this.controllerFor('application').set('canManageInsightsUsers', this.sessionAccount.canManageInsightsUsers);
        return this.controllerFor('application').set('clientHasComposerReports', client.get('has_composer_reports'));
      });
    },

    actions: {
      goToOrderFlow() {
        return this.transitionTo('order-flow');
      },

      edit_mission(mission) {
        this.get('metrics').trackEvent({
          event: 'event',
          objectId: mission.id,
          objectType: 'mission',
          category: 'Dashboard',
          action: 'click',
          label: 'Edit/View Plan'
        });
        return this.transitionTo('missions.edit', mission.id);
      },

      show_mission(mission) {
        this.get('metrics').trackEvent({
          event: 'event',
          objectId: mission.id,
          objectType: 'mission',
          category: 'Dashboard',
          action: 'click',
          label: 'Show Mission'
        });
        return this.transitionTo('missions.show', mission.id);
      },

      map_show_mission(mission) {
        this.get('metrics').trackEvent({
          event: 'event',
          objectId: mission.id,
          objectType: 'mission',
          category: 'Dashboard',
          action: 'click',
          label: 'Map Show Mission'
        });
        return this.transitionTo('missions.show', mission.id);
      },

      willTransition() {
        return this.controllerFor('application').set('showInsightsBanner', false);
      }

    }
  });

  _exports.default = _default;
});