define("clients/helpers/hide-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HideDateHelper;
  HideDateHelper = Ember.Helper.helper(function (arg) {
    var index, item, timeline_items;
    timeline_items = arg[0], item = arg[1], index = arg[2];

    if (index === 0) {
      return false;
    }

    return item.date.toDateString() === timeline_items[index - 1].date.toDateString();
  });
  var _default = HideDateHelper;
  _exports.default = _default;
});