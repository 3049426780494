define("clients/templates/components/slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GwoT1hWX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"values-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"min-value \",[30,[36,3],[[30,[36,2],[[35,1],[35,0]],null],\"active\"],null]]]],[12],[2,\"\\n    \"],[1,[34,1]],[2,\" \"],[1,[34,4]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"value \",[30,[36,3],[[30,[36,6],[[30,[36,2],[[35,1],[35,0]],null],[30,[36,2],[[35,5],[35,0]],null]],null],\"hide\"],null]]]],[12],[2,\"\\n    \"],[1,[34,0]],[2,\" \"],[1,[34,4]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"max-value \",[30,[36,3],[[30,[36,2],[[35,5],[35,0]],null],\"active\"],null]]]],[12],[2,\"\\n    \"],[1,[34,5]],[2,\" \"],[1,[34,4]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"input\"],[15,\"min\",[34,1]],[15,\"max\",[34,5]],[15,\"step\",[34,7]],[15,2,[34,0]],[15,1,[34,8]],[14,4,\"range\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"value\",\"min\",\"is-equal\",\"if\",\"unit\",\"max\",\"or\",\"step\",\"inputId\"]}",
    "meta": {
      "moduleName": "clients/templates/components/slider.hbs"
    }
  });

  _exports.default = _default;
});