define("clients/data/sample_timeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": [{
      "id": "1672086",
      "type": "activity_logs",
      "attributes": {
        "created_at": "2019-10-24T22:40:19Z",
        "action": "complete",
        "reason": null,
        "blurb": null,
        "additional_info": null
      }
    }, {
      "id": "1667882",
      "type": "activity_logs",
      "attributes": {
        "created_at": "2019-10-23T18:06:46Z",
        "action": "in_production",
        "reason": null,
        "blurb": null,
        "additional_info": null
      }
    }, {
      "id": "1667880",
      "type": "activity_logs",
      "attributes": {
        "created_at": "2019-10-23T18:06:43Z",
        "action": "assets_classified",
        "reason": null,
        "blurb": null,
        "additional_info": null
      }
    }, {
      "id": "1667878",
      "type": "activity_logs",
      "attributes": {
        "created_at": "2019-10-23T18:06:34Z",
        "action": "assets_uploaded",
        "reason": null,
        "blurb": null,
        "additional_info": null
      }
    }, {
      "id": "1667877",
      "type": "activity_logs",
      "attributes": {
        "created_at": "2019-10-23T18:06:27Z",
        "action": "flight_complete",
        "reason": null,
        "blurb": null,
        "additional_info": null
      }
    }, {
      "id": "1666930",
      "type": "activity_logs",
      "attributes": {
        "created_at": "2019-10-23T01:49:55Z",
        "action": "pilot_accepted",
        "reason": null,
        "blurb": null,
        "additional_info": null
      }
    }, {
      "id": "1666873",
      "type": "activity_logs",
      "attributes": {
        "created_at": "2019-10-23T00:29:22Z",
        "action": "assets_classified",
        "reason": null,
        "blurb": null,
        "additional_info": null
      }
    }, {
      "id": "1666847",
      "type": "activity_logs",
      "attributes": {
        "created_at": "2019-10-23T00:07:32Z",
        "action": "assets_uploaded",
        "reason": null,
        "blurb": null,
        "additional_info": null
      }
    }, {
      "id": "1666846",
      "type": "activity_logs",
      "attributes": {
        "created_at": "2019-10-23T00:06:50Z",
        "action": "pilot_accepted",
        "reason": null,
        "blurb": null,
        "additional_info": null
      }
    }, {
      "id": "1666843",
      "type": "activity_logs",
      "attributes": {
        "created_at": "2019-10-23T00:06:01Z",
        "action": "pilots_notified",
        "reason": null,
        "blurb": null,
        "additional_info": null
      }
    }, {
      "id": "1666840",
      "type": "activity_logs",
      "attributes": {
        "created_at": "2019-10-23T00:05:28Z",
        "action": "confirmed",
        "reason": null,
        "blurb": null,
        "additional_info": null
      }
    }, {
      "id": "1666838",
      "type": "activity_logs",
      "attributes": {
        "created_at": "2019-10-23T00:02:17Z",
        "action": "rescheduled",
        "reason": "Initial Schedule",
        "blurb": "Initial Pilot Schedule",
        "additional_info": {
          "to": "2019-10-26T22:00:00Z"
        }
      }
    }, {
      "id": "1666836",
      "type": "activity_logs",
      "attributes": {
        "created_at": "2019-10-23T00:02:17Z",
        "action": "created",
        "reason": null,
        "blurb": null,
        "additional_info": null
      }
    }]
  };
  _exports.default = _default;
});