define("clients/components/DBMap/altitude-instructions", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tooltip-component-container'],
    _labelClass: 'tooltip-component-label',
    _bodyClass: 'tooltip-component-body',
    _labelClasses: Ember.computed('labelClass', '_labelClass', function () {
      return [this.labelClass].filter(Boolean).flat().concat(this._labelClass).join(' ');
    }),
    _bodyClasses: Ember.computed('bodyClass', '_bodyClass', '_isActive', function () {
      let classes = [this.bodyClass].filter(Boolean).flat();
      classes = classes.concat(this._bodyClass);
      if (this._isActive) classes = classes.concat('active');
      return classes.join(' ');
    }),
    event: 'click',
    closeOnLeave: false,
    closeDelay: 3000,
    //ms
    _isActive: false,
    _toggleOnEvents: Ember.A(['click']),
    _isToggleable: Ember.computed('event', '_toggleOnEvents', function () {
      return this._toggleOnEvents.includes(this.event);
    }),
    _closeCallback: null,
    _eventNamespace: 'tooltip-component-namespace',
    _labelElement: null,
    _bodyElement: null,
    _parentScrollElement: null,
    _bodyTop: 0,
    _parentScrollOffset: 0,
    _parentScrollId: 'tooltip-scrollable-parent',
    _bodyPositionObserver: Ember.observer('_bodyTop', '_paddingLeftRight', '_parentScrollOffset', function () {
      this._bodyElement[0].style.setProperty('--left', `${this._paddingLeftRight}px`);

      this._bodyElement[0].style.setProperty('--top', `${this._bodyTop - this._parentScrollOffset}px`);
    }),
    _resizeObserver: null,
    _parentScrollWidth: 300,
    _paddingLeftRight: 15,
    _bodyWidthObserver: Ember.observer('_parentScrollWidth', function () {
      this._bodyElement[0].style.setProperty('--width', `${this._parentScrollWidth - this._paddingLeftRight * 2}px`);
    }),
    _assetLinks: Object.freeze({
      100: 'https://clients.staging.dronebase.com/p/268ba51a39167c47f8f1f99f8c91cd2b',
      200: 'https://clients.staging.dronebase.com/p/6907ed4703fecaa9748cf9d1c5b38142',
      300: 'https://clients.staging.dronebase.com/p/85a5e447c2eff4ef7007f778cead2360',
      400: 'https://clients.staging.dronebase.com/m/e475270062f6a6970adb0943d28227f2'
    }),

    didInsertElement() {
      this._super(...arguments);

      this.set('_labelElement', (0, _jquery.default)(`.${this._labelClass}`));
      this.set('_bodyElement', (0, _jquery.default)(`.${this._bodyClass}`));
      this.set('_parentScrollElement', (0, _jquery.default)(`#${this._parentScrollId}`));
      this.set('_parentScrollOffset', this._parentScrollElement[0].scrollTop);

      this._labelElement.on(`${this.event}.${this._eventNamespace}`, this.handleTooltipEvent.bind(this));

      this._parentScrollElement.on(`scroll.${this._eventNamespace}`, this.handleParentScroll.bind(this));

      this.set('_resizeObserver', new ResizeObserver(this.handleParentResize.bind(this)));

      this._resizeObserver.observe(this._parentScrollElement[0]);

      if (this.closeOnLeave) {
        this._bodyElement.on(`mouseenter.${this._eventNamespace}`, this.handleMouseEnter.bind(this));

        this._bodyElement.on(`mouseleave.${this._eventNamespace}`, this.handleMouseLeave.bind(this));
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      Ember.run.cancel(this._closeCallback);

      this._labelElement.off(`${this.event}.${this._eventNamespace}`);

      this._parentScrollElement.off(`scroll.${this._eventNamespace}`);

      this._resizeObserver.disconnect();

      if (this.closeOnLeave) {
        this._bodyElement.off(`mouseenter.${this._eventNamespace}`);

        this._bodyElement.off(`mouseleave.${this._eventNamespace}`);
      }
    },

    handleTooltipEvent() {
      this.set('_isActive', this._isToggleable ? !this._isActive : true);

      if (this._isActive) {
        this.set('_bodyTop', this._labelElement[0].offsetTop + this._labelElement[0].clientHeight);
      }
    },

    handleParentScroll(event) {
      this.set('_parentScrollOffset', event.target.scrollTop);
    },

    handleParentResize(entries) {
      this.set('_parentScrollWidth', entries[0].target.clientWidth);
    },

    handleMouseLeave() {
      this._closeCallback = Ember.run.later(() => {
        this.set('_isActive', false);
      }, this.closeDelay);
    },

    handleMouseEnter() {
      Ember.run.cancel(this._closeCallback);
    }

  });

  _exports.default = _default;
});