define("clients/components/share/share-shot", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['mission-share-shot'],
    isExpanded: false,
    gallery: null,
    $galleryContainer: null,
    isSpecificImage: false,
    collapseAssetsCount: 8,
    init: function () {
      this._super(arguments);

      this.set('shotShareBarModel', this.store.createRecord('share-bar-model', {
        'showShareOptions': true
      }));
    },
    playingVideos: Ember.computed('shot.{name,promotedAssets}', 'videos', function () {
      if (this.get('shot.name') === 'Final Assets') {
        let scope = this.get('shot.promotedAssets');
        return [scope.filterBy('assetType', 'video').get('firstObject')];
      } else {
        return [];
      }
    }),
    didInsertElement: function () {
      this._super(arguments);

      this.set('$galleryContainer', this.$());
      this.initializeLightGallery(this.$galleryContainer);
      (0, _jquery.default)('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
      });
    },
    didRender: function () {
      this._super(arguments);

      if (this.currentImageIndex() > -1) {
        this.set('isExpanded', true);
      }

      this.showSpecificSharedImage();
    },
    isShowMore: Ember.computed('showMoreCount', 'isExpanded', function () {
      return this.showMoreCount > 0 && !this.isExpanded;
    }),
    showMoreCount: Ember.computed('collapseAssetsCount', 'shot.promotedAssets.[]', function () {
      return this.get('shot.promotedAssets.length') - this.collapseAssetsCount;
    }),
    showAssets: Ember.computed('collapseAssetsCount', 'shot.promotedAssets.[]', function () {
      return this.get('shot.promotedAssets').slice(0, this.collapseAssetsCount);
    }),
    hiddenAssets: Ember.computed('collapseAssetsCount', 'shot.{name,promotedAssets.[]}', function () {
      let scope = this.get('shot.promotedAssets');

      if (this.get('shot.name') === 'Final Assets') {
        const firstVideo = scope.filterBy('assetType', 'video').get('firstObject');
        scope = scope.filter(shot => {
          return shot !== firstVideo;
        });
      }

      scope = scope.slice(this.collapseAssetsCount, scope.length);
      return scope;
    }),
    videos: Ember.computed('shot.videos.@each.isPromoted', function () {
      return this.get('shot.videos').filterBy('isPromoted', true);
    }),
    images: Ember.computed('shot.images.@each.isPromoted', function () {
      return this.get('shot.images').filterBy('isPromoted', true);
    }),
    shareBarId: Ember.computed('shot.id', function () {
      return "shotShareBar#" + this.get('shot.id');
    }),
    initializeLightGallery: function ($galleryContainer) {
      if (this.gallery && this.gallery.data('lightGallery')) {
        this.gallery.data('lightGallery').destroy(true);
      }

      let gallery = $galleryContainer.lightGallery({
        selector: '.gallery-item',
        hideBarsDelay: 999999,
        appendCounterTo: $galleryContainer.find('.topbar-counter'),
        mode: 'lg-slide',
        videojs: true,
        autoplayFirstVideo: false,
        videoMaxWidth: 1024,
        download: true
      });
      this.set('gallery', gallery);
      $galleryContainer.on('onAfterSlide.lg', () => {
        const imageUrl = (0, _jquery.default)('#lg-download').attr('href');
        const codeInput = (0, _jquery.default)('.code-input');
        codeInput.val(imageUrl);
        this.set('shareUrl', imageUrl);
        this.shotShareBarModel.set('shareUrl', imageUrl);
        (0, _jquery.default)('.topbar-download').attr('href', imageUrl);
      });
      $galleryContainer.on('onAfterOpen.lg', () => {
        this.shotShareBarModel.set('showShareBar', true);
        this.setDownloadUrlForShareModel();
      });
      $galleryContainer.on('onAfterSlide.lg', () => {
        this.setDownloadUrlForShareModel();
      });
      $galleryContainer.on('onCloseAfter.lg', () => {
        this.shotShareBarModel.set('showShareBar', false); // remove existing counter so it's not displayed multiple times

        (0, _jquery.default)('.topbar-counter').empty();
        this.shotShareBarModel.set('assetUrl', '');
      });
    },
    setDownloadUrlForShareModel: function () {
      const galleryObj = this.gallery.data('lightGallery');
      const assetUrl = (0, _jquery.default)(galleryObj.$items[galleryObj.index]).data('downloadUrl');
      this.shotShareBarModel.set('assetUrl', assetUrl);
    },
    showSpecificSharedImage: function () {
      if (this.currentImageIndex() > -1 && !this.isSpecificImage) {
        this.set('isSpecificImage', true);
        this.set('isSpecificImageShown', true);
        Ember.run.later(() => {
          this.initializeLightGallery(this.$galleryContainer);
          Ember.run.later(() => {
            this.gallery.data('lightGallery').$items[this.currentImageIndex()].click();
          }, 500);
        });
      }
    },
    currentImageIndex: function () {
      return this.get('shot.images').filterBy('isPromoted', true).indexOf(this.get('currentShare.shareable'));
    },
    actions: {
      showMore: function () {
        this.set('isExpanded', true);
      },
      playVideo: function (video) {
        // Keep playingVideos as a string because of how the view update works.
        this.set('playingVideos', this.playingVideos + video);
      },
      closeVideo: function (video) {
        const playingVideos = this.playingVideos.replace("" + video, "");
        this.set('playingVideos', playingVideos);
      },
      closeGallery: function () {
        this.gallery.data('lightGallery').destroy(false);
        this.shotShareBarModel.set('showShareBar', false);

        if (this.isSpecificImageShown) {
          this.set('isSpecificImageShown', false);
          Ember.run.later(() => {
            this.initializeLightGallery(this.$galleryContainer);
          });
        }
      },
      download: function (asset) {
        window.location = asset.get('downloadUrl');
        return false;
      }
    }
  });

  _exports.default = _default;
});