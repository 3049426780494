define("clients/templates/shares/ortho", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vxbgBCIx",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"shareType\",\"currentShare\",\"mission\",\"shareBarModel\",\"shareToken\"],[\"ortho\",[35,3],[35,2],[35,1],[35,0,[\"shareToken\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"shareBarModel\",\"mission\",\"currentShare\",\"mission-share\"]}",
    "meta": {
      "moduleName": "clients/templates/shares/ortho.hbs"
    }
  });

  _exports.default = _default;
});