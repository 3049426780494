define("clients/services/google-init", ["exports", "jquery", "clients/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GoogleInitService;
  GoogleInitService = Ember.Service.extend({
    loading: false,
    loaded: false,
    loadMap: function () {
      if (!this.get('loaded') && !this.get('loading')) {
        this.set('loading', true);
        return Ember.run.scheduleOnce('afterRender', this, this.getScript);
      }
    },
    getScript: function () {
      var _this;

      _this = this;
      return _jquery.default.getScript("https://maps.googleapis.com/maps/api/js?v=3&key=" + _environment.default.googleMapsKey + "&signed_in=false&libraries=places,drawing,geometry", function () {
        _this.set('loaded', true);

        return _this.set('loading', false);
      });
    }
  });
  var _default = GoogleInitService;
  _exports.default = _default;
});