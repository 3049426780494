define("clients/services/upload-queue-manager", ["exports", "clients/utils/uploader/queue"], function (_exports, _queue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init() {
      this._super();

      return this.set('queues', Ember.Object.create());
    },

    findOrCreate(name, component, config) {
      let queue = this.queues.get(name);

      if (queue) {
        if (config !== null) {
          queue.set('target', component);
          queue.configureUploader(config);
        }
      } else {
        queue = _queue.default.create({
          name,
          target: component
        });
        this.queues.set(name, queue);
        queue.configureUploader(config, this.airbrake);
      }

      return queue;
    }

  });

  _exports.default = _default;
});