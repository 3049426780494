define("clients/components/register-form", ["exports", "clients/validations/newclient", "ember-changeset-validations", "ember-changeset", "jquery"], function (_exports, _newclient, _emberChangesetValidations, _emberChangeset, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_newclient.default, {
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    utmService: Ember.inject.service(),
    attributeBindings: ['novalidate'],
    novalidate: true,
    tagName: 'form',
    role: 'form',
    buttonName: 'SUBMIT',
    buttonDisabled: false,

    init() {
      this._super();

      this.set('client', this.store.createRecord('client'));

      if (this.fromOrderFlow) {
        this.metrics.trackPage({
          hitType: 'pageview',
          page: '/order_flow/create_account'
        });
      }

      return this.changeset = new _emberChangeset.default(this.client, (0, _emberChangesetValidations.default)(_newclient.default), _newclient.default);
    },

    didInsertElement() {
      return (0, _jquery.default)('input').on('keyup', e => {
        if (e.keyCode === 13) {
          (0, _jquery.default)('.btnSubmit').addClass('clicked');
          return this.saveAccount();
        }
      });
    },

    actions: {
      save(event) {
        if (event) {
          event.preventDefault();
        }

        this.set('termsError', !this.terms_and_conditions);
        return this.changeset.validate().then(() => {
          if (this.changeset.get('isInvalid') || this.termsError) {
            if (this.changeset.get('errors') && this.changeset.get('errors')[0] && this.changeset.get('errors')[0].validation) {
              this.set('error_message', this.changeset.get('errors')[0].validation[0]);
            }

            this.set('saveState', 'error');
            Ember.run.later(() => {
              return this.set('saveState', 'initial');
            }, 500);
            return;
          } else {
            this.set('saveState', 'inProgress');
            this.set('buttonDisabled', true);
            this.set('buttonName', 'SUBMITTING...');

            if (Ember.isEmpty(this.changeset.get('signup_source'))) {
              this.changeset.set('signup_source', 'website_orderflow');
            }

            if (this.cookies.exists('org_on_signup')) {
              this.changeset.set('organization_slug', this.cookies.read('org_on_signup'));
              this.cookies.clear('org_on_signup');
            }

            this.changeset.set('organization', this.organization);

            if (this.verticalToAssign) {
              this.changeset.set('vertical', this.verticalToAssign);
            }

            return this.changeset.save().then(response => {
              this.metrics.trackEvent({
                event: 'create_account',
                client_name: this.changeset.get('first_name') + ' ' + this.changeset.get('last_name'),
                client_email: this.changeset.get('email'),
                client_company: this.changeset.get('company_name')
              }); // Any ideas on making sure this expires?

              this.session.set('data.authenticated.token', response.token);
              this.session.set('data.authenticated.email', response.email);
              this.session.set('data.isAuthenticated', true);
              this.utmService.track('signup', () => {
                this.registerAction(this.client);
                return Ember.run.later(() => {
                  if (!['destroying', 'destroyed'].includes(this._state)) {
                    this.set('saveState', 'success');
                    return Ember.run.later(() => {
                      return this.set('saveState', 'initial');
                    }, 500);
                  }
                }, 500);
              });
            }, () => {
              this.set('buttonDisabled', false);
              this.set('buttonName', 'SUBMIT');
              this.get('client.errors').forEach(function (_ref) {
                let {
                  attribute,
                  message
                } = _ref;
                return this.changeset.pushErrors(attribute, message);
              });
              return Ember.run.later(() => {
                this.set('saveState', 'error');
                return Ember.run.later(() => {
                  return this.set('saveState', 'initial');
                }, 500);
              }, 500);
            });
          }
        });
      }

    }
  });

  _exports.default = _default;
});