define("clients/helpers/multiplication", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function () {
    let [value1, value2] = Array.from(arguments.length <= 0 ? undefined : arguments[0]);

    if (!value1) {
      value1 = 0;
    }

    return value1 * value2;
  });

  _exports.default = _default;
});