define("clients/adapters/suggested-group", ["exports", "clients/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'v3/clients'
  });

  _exports.default = _default;
});