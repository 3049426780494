define("clients/helpers/format-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDollar = _exports.default = void 0;

  // This function receives the params `params, hash`
  const formatDollar = function (_ref) {
    let [amount] = _ref;

    if (amount != null && typeof amount != 'undefined') {
      if (amount < 0) {
        return `-$${Math.abs(amount) / 100}`;
      } else {
        return `$${amount / 100}`;
      }
    }
  };

  _exports.formatDollar = formatDollar;
  const FormatDollarHelper = Ember.Helper.helper(formatDollar);
  var _default = FormatDollarHelper;
  _exports.default = _default;
});