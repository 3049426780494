define("clients/templates/missions/receipt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qymu5Gnh",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"mission\",\"lineItems\",\"client\"],[[35,0,[\"mission\"]],[35,0,[\"mission\",\"sortedLineItems\"]],[35,0,[\"client\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"mission-receipt\"]}",
    "meta": {
      "moduleName": "clients/templates/missions/receipt.hbs"
    }
  });

  _exports.default = _default;
});