define("clients/routes/shares/mission", ["exports", "clients/mixins/css-class-namespace", "clients/mixins/share-header-mixin", "clients/data/sample_mission_list", "clients/config/environment"], function (_exports, _cssClassNamespace, _shareHeaderMixin, _sample_mission_list, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SharesMissionRoute;
  SharesMissionRoute = Ember.Route.extend(_cssClassNamespace.default, _shareHeaderMixin.default, {
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    headers: {},
    setupController: function (controller, model) {
      var mission;

      this._super(controller, model);

      this.controllerFor('application').set('collabMode', true);

      if (model.share.get('shareable')) {
        mission = controller.set('mission', model.share.get('shareable'));
      } else {
        mission = controller.set('mission', model.share);
      }

      this.set('title', mission.get('location.name') + ' - Zeitview');
      controller.set('shareBarModel.hasPano', mission.get('panoramas') && mission.get('panoramas').length);
      this.setHeaderTags(mission.get('location.name'), mission.get('meta_image_url'));
      this.set('headers', this.get('sessionAccount.headers'));
      return mission.loadImages(model.shareToken);
    },
    model: function (params) {
      var mission, urlParams;
      urlParams = new URL(window.location).searchParams;

      if (urlParams.get('sample_mission')) {
        Object.values(_sample_mission_list.default).forEach(function (_this) {
          return function (mission) {
            if (mission.data.id === params.token) {
              return _this.get('store').pushPayload(mission);
            }
          };
        }(this));
        mission = this.get('store').peekRecord('mission', params.token);
        return {
          share: mission,
          shareToken: params.token,
          sampleMission: true
        };
      }

      return Ember.RSVP.hash({
        share: this.get('store').findRecord('share', params.token, {
          adapterOptions: {
            shareType: 'mission'
          }
        }),
        shareToken: params.token
      });
    },
    actions: {
      willTransition: function (transition) {
        this.controllerFor('application').set('showNavBar', false);
        return this.controllerFor('application').set('collabMode', false);
      },
      loginAction: function () {
        return this.transitionTo('login');
      },
      openAttachment: function (attachment) {
        var headers;
        attachment.set('isPreparing', true);
        headers = this.get('headers');
        return Ember.$.ajax({
          url: _environment.default.api.host + "/v1/clients/assets/s3_view_request",
          type: 'POST',
          dataType: 'json',
          data: {
            attachment_id: attachment.get('id')
          },
          headers: headers
        }).then(function (response) {
          var win;
          attachment.set('isPreparing', false);
          win = window.open(response.url, '_blank');
          return win.focus();
        });
      }
    }
  });
  var _default = SharesMissionRoute;
  _exports.default = _default;
});