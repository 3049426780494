define("clients/helpers/is-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(_ref) {
      let [value] = _ref;
      return value === null || value === undefined || value.length <= 0;
    }

  });

  _exports.default = _default;
});