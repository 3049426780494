define("clients/models/video", ["exports", "@ember-data/model", "clients/models/shareable"], function (_exports, _model, _shareable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _shareable.default.extend({
    url: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    version_urls: (0, _model.attr)(),
    processing: (0, _model.attr)('boolean'),
    source_type: (0, _model.attr)('string'),
    formatted_size: (0, _model.attr)('string'),
    mission: (0, _model.belongsTo)('mission'),
    shot: (0, _model.belongsTo)('shot'),
    assignedToShot: Ember.computed('shot.id', function () {
      return !!this.get('shot.id');
    }),
    thumbnail: Ember.computed('processing', 'url', 'version_urls.poster_image', function () {
      if (this.get('processing')) {
        return 'assets/images/processing_video.jpg';
      } else {
        return this.get('version_urls.poster_image');
      }
    }),
    downloadUrl: Ember.computed.reads('version_urls.download'),
    isPromoted: Ember.computed.equal('source_type', 'edit'),
    mp4_url: Ember.computed.or('version_urls.mp4_high', 'version_urls.mp4_med'),
    saveAsUpdate: function () {
      this.send('willCommit');
      this.send('didCommit');
      return this.save();
    }
  });

  _exports.default = _default;
});