define("clients/templates/components/order-flow/package-selection-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XzTFXQfn",
    "block": "{\"symbols\":[\"slidePackages\",\"package\"],\"statements\":[[10,\"div\"],[14,0,\"swiper-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"swiper-wrapper\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"swiper-slide\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"carousel-item\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"              \"],[1,[30,[36,3],null,[[\"package\",\"selectedPackage\",\"selectPackageAction\",\"client\"],[[32,2],[35,2],[30,[36,1],[[32,0],\"selectPackage\",[32,2]],null],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"swiper-button-prev\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"swiper-button-next\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"swiper-pagination\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"client\",\"action\",\"selectedItem\",\"order-flow/package-block\",\"-track-array\",\"each\",\"packageSlides\"]}",
    "meta": {
      "moduleName": "clients/templates/components/order-flow/package-selection-list.hbs"
    }
  });

  _exports.default = _default;
});