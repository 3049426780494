define("clients/components/mission-share", ["exports", "clients/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionShareComponent;
  MissionShareComponent = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    isHidePanoEmbedPopup: true,
    showPanoWiever: true,
    classNames: ['mission-share-container'],
    activePanorama: Ember.computed('panoramas', function () {
      var panorama;

      if (this.get('panoramas.length') > 0) {
        panorama = this.get('active_pano') ? this.get('panoramas').find(function (_this) {
          return function (p) {
            return p.id === _this.get('active_pano');
          };
        }(this)) : void 0;
        return panorama || this.get('panoramas').firstObject;
      }
    }),
    didRender: function () {
      return window.prerenderReady = true;
    },
    showShareBar: Ember.computed('shareBarModel.showShareBar', function () {
      return this.get('shareBarModel.showShareBar');
    }),
    currentYear: Ember.computed(function () {
      return moment().year();
    }),
    didInsertElement: function () {
      var playingVideo;
      this.updateUrl();
      $('#videoCarousel').on('slid.bs.carousel', function (_this) {
        return function () {
          return _this.updateUrl();
        };
      }(this));
      $('#logo-editor #logo-upload').on('change', function (_this) {
        return function () {
          return _this.updateBranding('#logo-editor');
        };
      }(this));
      $('#blurb-editor #mission-blurb').on('change', function (_this) {
        return function () {
          return _this.updateBranding('#blurb-editor');
        };
      }(this));
      playingVideo = this.$('.playing-shared-video')[0];

      if (playingVideo) {
        return playingVideo.scrollIntoView();
      }
    },
    updateBranding: function (selector) {
      return this.get('sessionAccount.account').then(function (_this) {
        return function (client) {
          var $form, $logo, authHeader, email, formData, request, token, url;
          $form = $(selector);
          formData = new FormData($form[0]);
          $logo = $form.find('img.logo');
          url = _environment.default.api.host + '/v1/clients/shares/' + _this.get('shareToken');
          email = client.get('email');
          token = client.get('token');
          authHeader = 'Token token="' + token + '", email="' + email + '"';
          request = {
            url: url,
            type: 'PATCH',
            data: formData,
            contentType: false,
            cache: false,
            processData: false,
            dataType: 'json',
            beforeSend: function (xhr) {
              return xhr.setRequestHeader('Authorization', authHeader);
            }
          };
          return $.ajax(request).done(function (response, message, xhr) {
            return alert('Your changes have been saved. Please refresh to see changes.');
          }).fail(function () {
            return alert('There was an error saving your changes.');
          });
        };
      }(this));
    },
    updateUrl: function () {
      var index, url;
      url = $('#videoCarousel .item.active').data('downloadurl');
      $('#video-downloads a').attr('href', url);
      index = $('#videoCarousel .item.active').data('index');
      return $('#current-video-count').text(index + 1);
    },
    loggedIn: Ember.computed('session.isAuthenticated', function () {
      return this.get('session.isAuthenticated');
    }),
    canEdit: Ember.computed('loggedIn', 'mission.owned_by_current_client', 'sampleMission', function () {
      if (this.get('sampleMission')) {
        return false;
      }

      return this.get('loggedIn') && this.get('mission.owned_by_current_client');
    }),
    shots: Ember.computed('mission.shots.[]', function () {
      if (this.get('mission.shots')) {
        return this.get('mission.shots').filter(function (shot) {
          return shot.get('name') !== 'Final Assets';
        });
      }
    }),
    finalAssetsShot: Ember.computed('mission.shots.[]', function () {
      if (this.get('mission.shots')) {
        return this.get('mission.shots').find(function (shot) {
          return shot.get('name') === 'Final Assets';
        });
      }
    }),
    isShowFinalShot: Ember.computed('finalAssetsShot', function () {
      return this.get('finalAssetsShot.assetCount') > 1;
    }),
    panoramas: Ember.computed('mission.panoramas.[]', function () {
      if (this.get('mission.panoramas')) {
        return this.get('mission.panoramas').sortBy('shot.name');
      } else if (this.get('shareType') === 'pano') {
        return [this.get('panorama')];
      }
    }),
    orthos: Ember.computed('mission.ortho_photo_maps.[]', function () {
      if (this.get('mission.ortho_photo_maps')) {
        return this.get('mission.ortho_photo_maps').filterBy('source_type', 'edit');
      } else {
        return null;
      }
    }),
    actions: {
      hideShareBar: function () {
        return this.get('shareBarModel').set('showShareBar', false);
      },
      showShareOptions: function () {
        this.get('shareBarModel').set('showShareOptions', true);
        this.get('shareBarModel').set('showVideoShareOptions', false);
        this.get('shareBarModel').set('showShareBar', true);
        return this.get('shareBarModel').set('shareUrl', window.location);
      },
      showVideoShareOptions: function () {
        var url;
        url = $('#videoCarousel .item.active').data('shareurl');
        this.get('shareBarModel').set('shareUrl', url);
        this.get('shareBarModel').set('showShareOptions', true);
        this.get('shareBarModel').set('showVideoShareOptions', true);
        return this.get('shareBarModel').set('showShareBar', true);
      },
      loginAction: function () {
        return this.sendAction('loginAction');
      },
      openAttachment: function (attachment) {
        return this.openAttachmentAction(attachment);
      }
    }
  });
  var _default = MissionShareComponent;
  _exports.default = _default;
});