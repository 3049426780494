define("clients/components/mission-view/asset-item-thumb", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AssetItemThumbComponent;
  AssetItemThumbComponent = Ember.Component.extend({
    classNames: ['item-thumb'],
    classNameBindings: ['getClassNames'],
    hoverOverActions: true,
    getClassNames: Ember.computed('asset', 'visible', function () {
      var classes;
      classes = "";

      if (this.get('asset.assetType') === 'panorama') {
        classes += 'panorama-thumb ';
      }

      if (this.get('asset.assetType') === 'ortho_photo_map') {
        classes += 'ortho-photo-map-thumb ';
      }

      if (!this.get('visible')) {
        classes += 'hidden';
      }

      return classes;
    }),
    videoDuration: Ember.computed({
      get: function () {
        var videoContainerId;
        videoContainerId = "#video-" + this.get('firstId') + "-" + this.get('secondId');

        if (!this.get('asset.processing')) {
          Ember.run.later(function (_this) {
            return function () {
              var duration, durationInSeconds;
              duration = $(videoContainerId).children()[0] ? $(videoContainerId).children()[0].duration : 0;
              durationInSeconds = parseInt(duration);

              if (durationInSeconds) {
                return _this.send('setFormattedDuration', durationInSeconds);
              }
            };
          }(this), 5000);
        }

        return 0;
      },
      set: function (key, value) {
        return this._videoDuration = value;
      }
    }),
    actions: {
      download: function (asset) {
        return window.location = asset.get('downloadUrl');
      },
      downloadOrtho: function (asset, type) {
        return window.location = asset.get('downloadable' + type);
      },
      toggleStar: function (asset) {
        return asset.set('starred', !asset.get('starred'));
      },
      toggleGalley: function () {
        var url;

        if (this.get('asset.assetType') === 'panorama') {
          url = this.get('asset.version_urls.large_1920');
          return window.open(url, 'Image');
        } else {
          return this.element.querySelectorAll('a')[0].click();
        }
      },
      setFormattedDuration: function (seconds) {
        var minutes;
        minutes = Math.floor(seconds / 60);
        seconds = seconds - minutes * 60;

        if (seconds < 10) {
          seconds = "0" + seconds;
        }

        return this.set('videoDuration', minutes + ':' + seconds);
      },
      toggleOrthoModal: function (ortho) {
        return this.sendAction('orthoModalToggle', ortho);
      }
    }
  });
  var _default = AssetItemThumbComponent;
  _exports.default = _default;
});