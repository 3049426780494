define("clients/authenticators/admin_pretender", ["exports", "ember-simple-auth/authenticators/devise", "clients/config/environment"], function (_exports, _devise, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AdminPretenderAuthenticator;
  AdminPretenderAuthenticator = _devise.default.extend({
    serverTokenEndpoint: _environment.default.api.host + '/v1/impersonate',
    resourceName: 'client',
    authenticate: function (email, token, clientEmail) {
      var data, identificationAttributeName, ref, resourceName, tokenAttributeName, useResponse;
      useResponse = this.get('rejectWithResponse');
      ref = this.getProperties('resourceName', 'identificationAttributeName', 'tokenAttributeName'), resourceName = ref.resourceName, identificationAttributeName = ref.identificationAttributeName, tokenAttributeName = ref.tokenAttributeName;
      data = {};
      data[resourceName] = {
        token: token
      };
      data[resourceName][identificationAttributeName] = email;
      data['client_email'] = clientEmail;
      return new Promise(function (_this) {
        return function (resolve, reject) {
          return _this.makeRequest(data).then(function (response) {
            if (response.ok) {
              return response.json().then(function (json) {
                var _json;

                if (_this._validate(json)) {
                  resourceName = _this.get('resourceName');
                  _json = json[resourceName] ? json[resourceName] : json;
                  return Ember.run(null, resolve, _json);
                } else {
                  return Ember.run(null, reject, "Check that server response includes " + tokenAttributeName + " and " + identificationAttributeName);
                }
              });
            } else {
              if (useResponse) {
                return Ember.run(null, reject, response);
              } else {
                return response.json().then(function (json) {
                  return Ember.run(null, reject, json);
                });
              }
            }
          })["catch"](function (error) {
            return Ember.run(null, reject, error);
          });
        };
      }(this));
    },
    makeRequest: function (data, options) {
      if (options == null) {
        options = {};
      }

      Ember.assign(options, {
        data: data,
        headers: {
          'X-API-TOKEN': _environment.default.api.app_token,
          'accept': 'application/json',
          'content-type': 'application/json'
        }
      });
      return this._super(data, options);
    }
  });
  var _default = AdminPretenderAuthenticator;
  _exports.default = _default;
});