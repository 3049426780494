define("clients/controllers/shares/ortho", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super();

      this.set('shareBarModel', this.store.createRecord('share-bar-model'));
      this.shareBarModel.set('shareUrl', window.location);
    },

    currentShare: Ember.computed.reads('model.share.shareable')
  });

  _exports.default = _default;
});