define("clients/data/sample_missions/cre_images_regional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": {
      "id": "DBM2326557882",
      "type": "missions",
      "attributes": {
        "instructions": "* Scope: Aerial Images\n* Time: Between 11am - 2pm\n* Schedule: Within 48-72 hours; Weather permitting",
        "status": "complete",
        "client_id": "DBC3006554",
        "payment": {
          "id": null,
          "processor": null,
          "card": "Visa - 3969"
        },
        "external_assets_url": null,
        "owned_by_current_client": true,
        "contact_email": "clients@zeitview.com",
        "created_on": "2019-12-04T19:17:09Z",
        "is_pano_mission": false,
        "logo_url": null,
        "blurb": null,
        "paid": true,
        "paid_on": "2019-12-04T19:19:37Z",
        "price": 21900,
        "archive_url": null,
        "edit_url": null,
        "customer_instructions": "Weekend only. Fly between 12 - 3 pm\n",
        "scheduled_at_start": "2019-12-05T18:00:00Z",
        "scheduled_at_end": "2019-12-05T19:00:00Z",
        "parent_id": null,
        "reshoot_mission_id": null,
        "rejection_reason": null,
        "rejection_notes": null,
        "reference_id": "CRE Images Package - Regional Centers Sample\t",
        "cloud_reqs": "less_than_50",
        "scheduling_requirement": "not_needed",
        "arcgis_feature_layer": null,
        "geo_properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-71.210277388499, 42.47998826476], [-71.205541422199, 42.481452089516], [-71.20624416096, 42.482226154818], [-71.210969398425, 42.481047199716], [-71.210277388499, 42.47998826476]]]
            },
            "properties": {
              "name": "Polygon #1",
              "notes": "",
              "number": 1,
              "area": "48045.2",
              "bounds": {
                "south": 42.47998826476,
                "west": -71.210969398425,
                "north": 42.482226154818,
                "east": -71.205541422199
              },
              "center": {
                "lat": 42.481107209789,
                "lng": -71.208255410312
              }
            },
            "id": 1
          }]
        }
      },
      "relationships": {
        "location": {
          "data": {
            "id": "284967",
            "type": "locations"
          }
        },
        "package": {
          "data": {
            "id": "19162",
            "type": "packages"
          }
        },
        "credit_card": {
          "data": {
            "id": "4898",
            "type": "credit_cards"
          }
        },
        "onsite_contact": {
          "data": {
            "id": "49590",
            "type": "onsite_contacts"
          }
        },
        "scheduling_contact": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "images": {
          "data": [{
            "id": "DBI0258551092",
            "type": "images"
          }, {
            "id": "DBI3990349857",
            "type": "images"
          }, {
            "id": "DBI0320895451",
            "type": "images"
          }, {
            "id": "DBI6632239860",
            "type": "images"
          }, {
            "id": "DBI7310852668",
            "type": "images"
          }, {
            "id": "DBI8334414843",
            "type": "images"
          }, {
            "id": "DBI9683101024",
            "type": "images"
          }, {
            "id": "DBI6155600222",
            "type": "images"
          }, {
            "id": "DBI1264167062",
            "type": "images"
          }, {
            "id": "DBI5968025748",
            "type": "images"
          }, {
            "id": "DBI3549421714",
            "type": "images"
          }, {
            "id": "DBI4820957677",
            "type": "images"
          }, {
            "id": "DBI4782435528",
            "type": "images"
          }, {
            "id": "DBI8651571151",
            "type": "images"
          }, {
            "id": "DBI7022167498",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        },
        "attachments": {
          "data": []
        },
        "ortho_photo_maps": {
          "data": []
        },
        "shares": {
          "data": [{
            "id": "bc1eeb927442a8cee5ce090e142aacae",
            "type": "shares"
          }]
        },
        "main_image": {
          "data": {
            "id": "DBI0258551092",
            "type": "images"
          }
        },
        "collaborators": {
          "data": []
        },
        "parent": {
          "data": null
        },
        "shots": {
          "data": [{
            "id": "1061032",
            "type": "shots"
          }]
        },
        "invoice_line_items": {
          "data": [{
            "id": "DBINV260881220012425",
            "type": "invoice_line_items"
          }]
        }
      }
    },
    "included": [{
      "id": "284967",
      "type": "locations",
      "attributes": {
        "name": "700 District Ave, Burlington, MA 01803, USA",
        "latitude": 42.4810004,
        "longitude": -71.2089957,
        "address": "700 District Avenue",
        "address2": null,
        "city": "Burlington",
        "state": "Massachusetts",
        "postal_code": "01803",
        "country": "United States",
        "formatted_address": "700 District Ave, Burlington, MA 01803, USA",
        "timezone_id": "America/New_York",
        "properties": {}
      }
    }, {
      "id": "19162",
      "type": "packages",
      "attributes": {
        "name": "Images Package - Regional Centers (400,000+ sq ft)",
        "description": "A set of high-res unedited images captured at an increased altitude showcasing your Regional Center and the surrounding area at multiple angles.",
        "price": 21900,
        "cloud_reqs": "less_than_50",
        "position": 10,
        "slug": "cre-images-pack-lg-shopping-center"
      },
      "relationships": {
        "vertical": {
          "data": {
            "id": "1",
            "type": "verticals"
          }
        },
        "organization": {
          "data": null
        },
        "client": {
          "data": null
        },
        "catalog_items": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "verticals",
      "attributes": {
        "name": "Commercial Real Estate",
        "short_name": "CRE"
      }
    }, {
      "id": "4898",
      "type": "credit_cards",
      "attributes": {
        "brand": "Visa",
        "last_4": "3969",
        "exp_month": 2,
        "exp_year": 2024,
        "stripe_id": "card_1FW88bJo202eeWudwLnrPXPF"
      }
    }, {
      "id": "49590",
      "type": "onsite_contacts",
      "attributes": {
        "name": "John Smith",
        "call_action": "Call When You Arrive",
        "phone": "123-456-7890",
        "note": null
      }
    }, {
      "id": "DBC3006554",
      "type": "clients",
      "attributes": {
        "first_name": "Demo",
        "last_name": "Zeitview",
        "phone": "",
        "email": "clients@zeitview.com",
        "token": "qC65CqCmbKfMZvIU2gRb_uD7Sqw",
        "company_name": null,
        "invoiceable": false
      },
      "relationships": {
        "hidden_sample_missions": {
          "data": [{
            "id": "*",
            "type": "hidden_sample_mission"
          }]
        }
      }
    }, {
      "id": "DBI0258551092",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021089-fdfed117ce9157aeaf6f11a2181496d9f10ee836/original-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
        "name": "DBI-15-yG7xN1eX-CRE-Regional-Center---Images-Package_DBI-12-DBU-29-3Aqq1tOp-DJI_0083-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "source_type": "edit",
        "formatted_size": "4.45 MB",
        "size": 4667854,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021089-fdfed117ce9157aeaf6f11a2181496d9f10ee836/large_1920-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021089-fdfed117ce9157aeaf6f11a2181496d9f10ee836/medium_1024-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021089-fdfed117ce9157aeaf6f11a2181496d9f10ee836/small_640-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021089-fdfed117ce9157aeaf6f11a2181496d9f10ee836/four_three_640-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021089-fdfed117ce9157aeaf6f11a2181496d9f10ee836/square_640-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021089-fdfed117ce9157aeaf6f11a2181496d9f10ee836/downloadable-9381ddd567cc4c93c31a116da8e9494746210344.jpeg"
        },
        "created_on": "2019-12-04T19:19:15Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3990349857",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021088-fdfed117ce9157aeaf6f11a2181496d9f10ee836/original-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
        "name": "DBI-14-Bfa1LHcj-CRE-Regional-Center---Images-Package_DBI-9-DBU-26-9JPjFFF3-DJI_0088-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "source_type": "edit",
        "formatted_size": "4.09 MB",
        "size": 4288810,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021088-fdfed117ce9157aeaf6f11a2181496d9f10ee836/large_1920-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021088-fdfed117ce9157aeaf6f11a2181496d9f10ee836/medium_1024-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021088-fdfed117ce9157aeaf6f11a2181496d9f10ee836/small_640-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021088-fdfed117ce9157aeaf6f11a2181496d9f10ee836/four_three_640-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021088-fdfed117ce9157aeaf6f11a2181496d9f10ee836/square_640-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021088-fdfed117ce9157aeaf6f11a2181496d9f10ee836/downloadable-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg"
        },
        "created_on": "2019-12-04T19:19:15Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0320895451",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021087-a6c1bbae5facfddffb04ab55b328cf4251b388ee/original-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
        "name": "DBI-13-oPEjAqpk-CRE-Regional-Center---Images-Package_DBI-7-DBU-23-U1pJS1iQ-DJI_0094-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "source_type": "edit",
        "formatted_size": "4.17 MB",
        "size": 4377579,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021087-a6c1bbae5facfddffb04ab55b328cf4251b388ee/large_1920-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021087-a6c1bbae5facfddffb04ab55b328cf4251b388ee/medium_1024-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021087-a6c1bbae5facfddffb04ab55b328cf4251b388ee/small_640-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021087-a6c1bbae5facfddffb04ab55b328cf4251b388ee/four_three_640-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021087-a6c1bbae5facfddffb04ab55b328cf4251b388ee/square_640-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021087-a6c1bbae5facfddffb04ab55b328cf4251b388ee/downloadable-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg"
        },
        "created_on": "2019-12-04T19:19:14Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6632239860",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021086-a6c1bbae5facfddffb04ab55b328cf4251b388ee/original-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
        "name": "DBI-12-pYyLWHDa-CRE-Regional-Center---Images-Package_DBI-6-DBU-22-vkFgtxlh-DJI_0096-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "source_type": "edit",
        "formatted_size": "4.16 MB",
        "size": 4364315,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021086-a6c1bbae5facfddffb04ab55b328cf4251b388ee/large_1920-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021086-a6c1bbae5facfddffb04ab55b328cf4251b388ee/medium_1024-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021086-a6c1bbae5facfddffb04ab55b328cf4251b388ee/small_640-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021086-a6c1bbae5facfddffb04ab55b328cf4251b388ee/four_three_640-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021086-a6c1bbae5facfddffb04ab55b328cf4251b388ee/square_640-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021086-a6c1bbae5facfddffb04ab55b328cf4251b388ee/downloadable-b36a59269151b78278571fc56570818271c1b0a7.jpeg"
        },
        "created_on": "2019-12-04T19:19:14Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7310852668",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021085-956461940ababed50c5fb1ad1fef8b3dc7196093/original-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
        "name": "DBI-11-sONNfeUY-CRE-Regional-Center---Images-Package_DBI-3-DBU-9-zay9WAga-DJI_0120-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "source_type": "edit",
        "formatted_size": "4.31 MB",
        "size": 4519337,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021085-956461940ababed50c5fb1ad1fef8b3dc7196093/large_1920-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021085-956461940ababed50c5fb1ad1fef8b3dc7196093/medium_1024-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021085-956461940ababed50c5fb1ad1fef8b3dc7196093/small_640-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021085-956461940ababed50c5fb1ad1fef8b3dc7196093/four_three_640-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021085-956461940ababed50c5fb1ad1fef8b3dc7196093/square_640-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021085-956461940ababed50c5fb1ad1fef8b3dc7196093/downloadable-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg"
        },
        "created_on": "2019-12-04T19:19:13Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8334414843",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021084-956461940ababed50c5fb1ad1fef8b3dc7196093/original-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
        "name": "DBI-10-97854Lb7-CRE-Regional-Center---Images-Package_DBI-2-DBU-7-zxNP2A12-DJI_0124-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "source_type": "edit",
        "formatted_size": "4.3 MB",
        "size": 4513335,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021084-956461940ababed50c5fb1ad1fef8b3dc7196093/large_1920-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021084-956461940ababed50c5fb1ad1fef8b3dc7196093/medium_1024-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021084-956461940ababed50c5fb1ad1fef8b3dc7196093/small_640-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021084-956461940ababed50c5fb1ad1fef8b3dc7196093/four_three_640-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021084-956461940ababed50c5fb1ad1fef8b3dc7196093/square_640-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021084-956461940ababed50c5fb1ad1fef8b3dc7196093/downloadable-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg"
        },
        "created_on": "2019-12-04T19:19:13Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9683101024",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021083-956461940ababed50c5fb1ad1fef8b3dc7196093/original-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
        "name": "DBI-9-mjTFtBND-CRE-Regional-Center---Images-Package_DBI-15-DBU-32-AaIO2KQt-DJI_0077-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "source_type": "edit",
        "formatted_size": "4.35 MB",
        "size": 4557060,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021083-956461940ababed50c5fb1ad1fef8b3dc7196093/large_1920-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021083-956461940ababed50c5fb1ad1fef8b3dc7196093/medium_1024-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021083-956461940ababed50c5fb1ad1fef8b3dc7196093/small_640-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021083-956461940ababed50c5fb1ad1fef8b3dc7196093/four_three_640-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021083-956461940ababed50c5fb1ad1fef8b3dc7196093/square_640-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021083-956461940ababed50c5fb1ad1fef8b3dc7196093/downloadable-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg"
        },
        "created_on": "2019-12-04T19:19:13Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6155600222",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021082-685dedf0433522b617f1c2fa0f3fe0bffb206bba/original-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
        "name": "DBI-8-2tyAECIe-CRE-Regional-Center---Images-Package_DBI-14-DBU-31-nDr9vnn7-DJI_0079-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "source_type": "edit",
        "formatted_size": "4.37 MB",
        "size": 4587481,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021082-685dedf0433522b617f1c2fa0f3fe0bffb206bba/large_1920-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021082-685dedf0433522b617f1c2fa0f3fe0bffb206bba/medium_1024-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021082-685dedf0433522b617f1c2fa0f3fe0bffb206bba/small_640-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021082-685dedf0433522b617f1c2fa0f3fe0bffb206bba/four_three_640-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021082-685dedf0433522b617f1c2fa0f3fe0bffb206bba/square_640-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021082-685dedf0433522b617f1c2fa0f3fe0bffb206bba/downloadable-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg"
        },
        "created_on": "2019-12-04T19:19:12Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI1264167062",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021081-685dedf0433522b617f1c2fa0f3fe0bffb206bba/original-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
        "name": "DBI-7-uKTW7qvN-CRE-Regional-Center---Images-Package_DBI-11-DBU-28-iFOZ3lMK-DJI_0084-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "source_type": "edit",
        "formatted_size": "4.25 MB",
        "size": 4456519,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021081-685dedf0433522b617f1c2fa0f3fe0bffb206bba/large_1920-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021081-685dedf0433522b617f1c2fa0f3fe0bffb206bba/medium_1024-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021081-685dedf0433522b617f1c2fa0f3fe0bffb206bba/small_640-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021081-685dedf0433522b617f1c2fa0f3fe0bffb206bba/four_three_640-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021081-685dedf0433522b617f1c2fa0f3fe0bffb206bba/square_640-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021081-685dedf0433522b617f1c2fa0f3fe0bffb206bba/downloadable-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg"
        },
        "created_on": "2019-12-04T19:19:12Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI5968025748",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021080-685dedf0433522b617f1c2fa0f3fe0bffb206bba/original-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
        "name": "DBI-6-tOmjfmFS-CRE-Regional-Center---Images-Package_DBI-10-DBU-27-tedIIENP-DJI_0086-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "source_type": "edit",
        "formatted_size": "4.23 MB",
        "size": 4435590,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021080-685dedf0433522b617f1c2fa0f3fe0bffb206bba/large_1920-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021080-685dedf0433522b617f1c2fa0f3fe0bffb206bba/medium_1024-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021080-685dedf0433522b617f1c2fa0f3fe0bffb206bba/small_640-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021080-685dedf0433522b617f1c2fa0f3fe0bffb206bba/four_three_640-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021080-685dedf0433522b617f1c2fa0f3fe0bffb206bba/square_640-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021080-685dedf0433522b617f1c2fa0f3fe0bffb206bba/downloadable-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg"
        },
        "created_on": "2019-12-04T19:19:12Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3549421714",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021079-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/original-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
        "name": "DBI-5-3HxXhZdb-CRE-Regional-Center---Images-Package_DBI-8-DBU-25-SCuaiqDO-DJI_0090-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "source_type": "edit",
        "formatted_size": "4.1 MB",
        "size": 4294087,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021079-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/large_1920-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021079-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/medium_1024-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021079-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/small_640-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021079-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/four_three_640-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021079-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/square_640-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021079-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/downloadable-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg"
        },
        "created_on": "2019-12-04T19:19:11Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4820957677",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021078-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/original-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
        "name": "DBI-4-0ssGaiMq-CRE-Regional-Center---Images-Package_DBI-5-DBU-19-Qq63FFL2-DJI_0101-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "source_type": "edit",
        "formatted_size": "5.23 MB",
        "size": 5488296,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021078-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/large_1920-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021078-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/medium_1024-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021078-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/small_640-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021078-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/four_three_640-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021078-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/square_640-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021078-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/downloadable-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg"
        },
        "created_on": "2019-12-04T19:19:11Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4782435528",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021077-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/original-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
        "name": "DBI-3-ijrvURwK-CRE-Regional-Center---Images-Package_DBI-1-DBU-2-pXdHUx0i-DJI_0134-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "source_type": "edit",
        "formatted_size": "4.49 MB",
        "size": 4703872,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021077-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/large_1920-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021077-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/medium_1024-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021077-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/small_640-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021077-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/four_three_640-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021077-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/square_640-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021077-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/downloadable-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg"
        },
        "created_on": "2019-12-04T19:19:09Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8651571151",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021076-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/original-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
        "name": "DBI-2-tSzHIopR-CRE-Regional-Center---Images-Package_DBI-13-DBU-30-qk1GYZQK-DJI_0081-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "source_type": "edit",
        "formatted_size": "4.37 MB",
        "size": 4580837,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021076-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/large_1920-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021076-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/medium_1024-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021076-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/small_640-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021076-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/four_three_640-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021076-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/square_640-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021076-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/downloadable-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg"
        },
        "created_on": "2019-12-04T19:19:09Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7022167498",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021075-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/original-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
        "name": "DBI-1-PyLlS7HV-CRE-Regional-Center---Images-Package_DBI-4-DBU-12-A8Pl3OqY-DJI_0115-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "source_type": "edit",
        "formatted_size": "4.29 MB",
        "size": 4501638,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021075-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/large_1920-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021075-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/medium_1024-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021075-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/small_640-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021075-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/four_three_640-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021075-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/square_640-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021075-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/downloadable-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg"
        },
        "created_on": "2019-12-04T19:19:09Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "bc1eeb927442a8cee5ce090e142aacae",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-05T06:56:38Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "1061032",
      "type": "shots",
      "attributes": {
        "instructions": "(16) images taken from equal increments around the property. *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "278165",
        "shot_type_id": 24,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (350 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "24",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI7022167498",
            "type": "images"
          }, {
            "id": "DBI5968025748",
            "type": "images"
          }, {
            "id": "DBI1264167062",
            "type": "images"
          }, {
            "id": "DBI9683101024",
            "type": "images"
          }, {
            "id": "DBI8334414843",
            "type": "images"
          }, {
            "id": "DBI3990349857",
            "type": "images"
          }, {
            "id": "DBI0320895451",
            "type": "images"
          }, {
            "id": "DBI0258551092",
            "type": "images"
          }, {
            "id": "DBI4820957677",
            "type": "images"
          }, {
            "id": "DBI7310852668",
            "type": "images"
          }, {
            "id": "DBI6632239860",
            "type": "images"
          }, {
            "id": "DBI6155600222",
            "type": "images"
          }, {
            "id": "DBI3549421714",
            "type": "images"
          }, {
            "id": "DBI4782435528",
            "type": "images"
          }, {
            "id": "DBI8651571151",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "24",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (350 ft above ground level)",
        "description": "*If there are altitude restrictions in this airspace, please take these images at the highest allowable altitude instead of 350'. *",
        "video": null,
        "slug": "images-cardinal-directions-350-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "23",
            "type": "shot_type_sample_images"
          }, {
            "id": "24",
            "type": "shot_type_sample_images"
          }, {
            "id": "25",
            "type": "shot_type_sample_images"
          }, {
            "id": "26",
            "type": "shot_type_sample_images"
          }, {
            "id": "27",
            "type": "shot_type_sample_images"
          }, {
            "id": "28",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "DBINV260881220012425",
      "type": "invoice_line_items",
      "attributes": {
        "reason": "base_package_price",
        "description": null,
        "amount": 21900,
        "created_on": "2019-12-04T19:17:09Z",
        "display_name": "Base Package Price"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "missions"
          }
        },
        "work_item": {
          "data": null
        }
      }
    }]
  };
  _exports.default = _default;
});