define("clients/controllers/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    cookies: Ember.inject.service(),
    store: Ember.inject.service(),
    metrics: Ember.inject.service(),
    session: Ember.inject.service(),
    utmService: Ember.inject.service(),
    verticalError: null,
    registrationComplete: false,
    buttonName: 'Sign up',
    verticals: Ember.computed.reads('model.verticals'),
    client: Ember.computed.reads('model.client'),
    findVerticalByShortName: function (shortName) {
      return this.verticals.find(elem => {
        return elem.get('short_name') == shortName;
      });
    },
    currentVertical: Ember.computed.reads('model.client.vertical'),
    creVertical: Ember.computed('model.verticals.[]', function () {
      return this.findVerticalByShortName('CRE');
    }),
    aecVertical: Ember.computed('model.verticals.[]', function () {
      return this.findVerticalByShortName('Construction');
    }),
    pmVertical: Ember.computed('model.verticals.[]', function () {
      return this.findVerticalByShortName('PM');
    }),
    rreVertical: Ember.computed('model.verticals.[]', function () {
      return this.findVerticalByShortName('RRE');
    }),
    insVertical: Ember.computed('model.verticals.[]', function () {
      return this.findVerticalByShortName('Insurance');
    }),
    othVertical: Ember.computed('model.verticals.[]', function () {
      return this.findVerticalByShortName('Other');
    }),
    actions: {
      changeVertical: function (selection) {
        this.set('model.client.vertical', selection);
      },
      save: function (event) {
        let resetForm = () => {
          this.set('buttonDisabled', false);
          this.set('buttonName', 'Sign up');
        };

        if (event) {
          event.preventDefault();
        }

        let client = this.get('model.client');
        this.set('saveState', 'inProgress');
        this.set('buttonDisabled', true);
        this.set('buttonName', 'Submitting...');

        if (this.cookies.exists('org_on_signup')) {
          client.set('organization_slug', this.cookies.read('org_on_signup'));
          this.cookies.clear('org_on_signup');
        }

        if (!client.get('vertical.id')) {
          resetForm();
          this.set('vertical_error', "Please pick your industry");
          return;
        } else {
          this.set('vertical_error', null);
        }

        client.save().then(() => {
          this.metrics.trackEvent({
            event: 'event',
            category: 'client-account-created',
            label: 'register page',
            action: 'click'
          });
          this.set('registrationComplete', true);
        }, () => {
          resetForm();
        });
      }
    }
  });

  _exports.default = _default;
});