define("clients/templates/components/share/only-ortho", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NX67SqSZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"mission-share-shot\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"bucket-header\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"bucket-name\"],[12],[2,\"Orthomosaic\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"ortho\",\"classNames\"],[[35,0],\"ortho-share\"]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sharedOrtho\",\"ortho-viewer\"]}",
    "meta": {
      "moduleName": "clients/templates/components/share/only-ortho.hbs"
    }
  });

  _exports.default = _default;
});