define("clients/models/esri-account", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    username: (0, _model.attr)('string'),
    token: (0, _model.attr)('string'),
    authorization_code: (0, _model.attr)('string'),
    organization_name: (0, _model.attr)('string'),
    client: (0, _model.belongsTo)('client'),
    token_expiry_date: (0, _model.attr)('date'),
    tokenIsExpired: Ember.computed('token_expiry_date', function () {
      return moment().diff(this.get('token_expiry_date')) > 0;
    }),
    baseArcgisUrl: Ember.computed('organization_name', function () {
      return `https://${this.organization_name}.maps.arcgis.com`;
    })
  });

  _exports.default = _default;
});