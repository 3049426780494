define("clients/services/session_account", ["exports", "jwt-decode"], function (_exports, _jwtDecode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    canManageInsightsUsers: false,
    account: Ember.computed('decodedPermissions', 'session.data.authenticated.{accessToken,idToken,token}', 'session.isAuthenticated', function () {
      if (this.session.isAuthenticated || this.session.data.authenticated.token) {
        return this.store.queryRecord('client', {}).then(model => {
          if (this.decodedPermissions) {
            this.setAccountPermissions(model, this.decodedPermissions);
          }

          return model;
        }).catch(() => {
          this.session.invalidate();
          return null;
        });
      } else {
        return null;
      }
    }),
    headers: Ember.computed('session.data.authenticated.{accessToken,idToken,token,email}', 'session.isAuthenticated', function () {
      if (this.session.isAuthenticated) {
        if (this.session.data.authenticated.token) {
          return {
            "Authorization": `Token token=\"${this.session.data.authenticated.token}\", email=\"${this.session.data.authenticated.email}\"`
          };
        } else {
          return {
            "Authorization": `Bearer ${this.session.data.authenticated.accessToken}`,
            "X-OAuth-User-ID-Token": this.session.data.authenticated.idToken
          };
        }
      } else {
        this.session.invalidate();
        return {};
      }
    }),
    decodedPermissions: Ember.computed('session.{data.authenticated.accessToken,isAuthenticated}', function () {
      if (this.session.isAuthenticated) {
        if (this.session.data.authenticated.accessToken) {
          const token = this.session.data.authenticated.accessToken;
          var decoded = (0, _jwtDecode.default)(token);
          return decoded.permissions;
        }

        return null;
      }

      return null;
    }),
    setAccountPermissions: function (account, permissions) {
      if (account.is_insights) {
        this.set('canManageInsightsUsers', permissions.includes('create:user'));
      }
    }
  });

  _exports.default = _default;
});