define("clients/components/registration-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    metrics: Ember.inject.service(),
    step: 1,
    router: Ember.inject.service(),

    init() {
      this._super();

      this.metrics.trackEvent({
        event: 'event',
        category: 'Client Dashboard Introduction',
        action: 'click',
        label: 'Modal Step 1'
      });
    },

    actions: {
      gotoStep(step) {
        this.set('step', step);
        this.metrics.trackEvent({
          event: 'event',
          category: 'Client Dashboard Introduction',
          action: 'click',
          label: `Modal Step ${this.step} from Step Dots`
        });
      },

      hideShowRegisterModal() {
        this.metrics.trackEvent({
          event: 'event',
          category: 'Client Dashboard Introduction',
          action: 'click',
          label: `Closed Modal at step ${this.step}`
        });
        this.hideShowRegisterModal();
      },

      nextRegisterModalStep() {
        this.set('step', this.step + 1);

        if (this.step <= 3) {
          this.metrics.trackEvent({
            event: 'event',
            category: 'Client Dashboard Introduction',
            action: 'click',
            label: `Modal Step ${this.step}`
          });
        } else {
          this.metrics.trackEvent({
            event: 'event',
            category: 'Client Dashboard Introduction',
            action: 'click',
            label: 'Go to Order Flow From Introduction Modal'
          });
          this.goToOrderFlow();
        }
      }

    }
  });

  _exports.default = _default;
});