define("clients/app", ["exports", "ember-resolver", "ember-load-initializers", "clients/config/environment", "jquery"], function (_exports, _emberResolver, _emberLoadInitializers, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  _jquery.default.ajaxSetup({
    headers: {
      'X-API-TOKEN': _environment.default.api.app_token
    }
  });

  Ember.Router.reopen({
    renderTemplate() {
      const toolTips = () => (0, _jquery.default)('[data-toggle="tooltip"]').tooltip();

      Ember.run.scheduleOnce('afterRender', toolTips);
      return this._super();
    }

  });

  class App extends Ember.Application {
    constructor() {
      super(...arguments);

      _defineProperty(this, "modulePrefix", _environment.default.modulePrefix);

      _defineProperty(this, "podModulePrefix", _environment.default.podModulePrefix);

      _defineProperty(this, "Resolver", _emberResolver.default);
    }

  }

  _exports.default = App;
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
});