define("clients/helpers/DBMap/meters-to-acres", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.metersToAcres = _exports.default = void 0;

  const metersToAcres = function () {
    const area = parseFloat(arguments.length <= 0 ? undefined : arguments[0]);

    if (area != null && typeof area != 'undefined' && !isNaN(area)) {
      const val = (area * 0.0002471).toFixed(2);
      let acres = `${val} acre`;

      if (val !== '1.00') {
        acres += 's';
      }

      return acres;
    } else {
      return '';
    }
  };

  _exports.metersToAcres = metersToAcres;

  var _default = Ember.Helper.helper(metersToAcres);

  _exports.default = _default;
});