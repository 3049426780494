define("clients/components/select-custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SelectCustomComponent;
  SelectCustomComponent = Ember.Component.extend({
    content: null,
    prompt: null,
    optionValuePath: 'id',
    optionLabelPath: 'fullName',
    action: Ember.K,
    classNames: ['form-group'],
    selectClass: 'form-control input-lg',
    assignSelectedObject: true,
    init: function () {
      this._super();

      if (!this.get('content')) {
        return this.set('content', []);
      }
    },
    actions: {
      changeValue: function () {
        var changeCallback, content, contentIndex, hasPrompt, selectEl, selectedIndex, selectedObject, selectedValue;
        selectEl = this.$('select')[0];
        selectedIndex = selectEl.selectedIndex;
        content = this.get('content');
        hasPrompt = !!this.get('prompt');
        contentIndex = hasPrompt ? selectedIndex - 1 : selectedIndex;
        selectedObject = content.objectsAt([contentIndex])[0];
        selectedValue = selectedObject.get(this.optionValuePath);
        changeCallback = this.get('action');

        if (this.assignSelectedObject) {
          return changeCallback(selectedObject);
        } else {
          return changeCallback(selectedValue);
        }
      }
    }
  });
  var _default = SelectCustomComponent;
  _exports.default = _default;
});