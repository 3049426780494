define("clients/data/sample_images/cre_starter_package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": [{
      "id": "DBI5556284910",
      "type": "image",
      "attributes": {
        "name": "DBI-39-ANug2vfN-downloadable-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015518-a4956aa1edb151314c55c6819add91a2d9895444/downloadable-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015518-a4956aa1edb151314c55c6819add91a2d9895444/large_1920-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxOC1hNDk1NmFhMWVkYjE1MTMxNGM1NWM2ODE5YWRkOTFhMmQ5ODk1NDQ0L2xhcmdlXzE5MjAtYWNkNDY5Mjg0YTY0ZWVmYWVhZGM5YWM3ZDA3OGUyZTVjMGI3NTZmOC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxOC1hNDk1NmFhMWVkYjE1MTMxNGM1NWM2ODE5YWRkOTFhMmQ5ODk1NDQ0L2xhcmdlXzE5MjAtYWNkNDY5Mjg0YTY0ZWVmYWVhZGM5YWM3ZDA3OGUyZTVjMGI3NTZmOC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:25Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060787",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI0198292139",
      "type": "image",
      "attributes": {
        "name": "DBI-38-3QV9xoqS-downloadable-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015517-3beb496b5e005bdde1bedb748ad0a12eb8dbaa2e/downloadable-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015517-3beb496b5e005bdde1bedb748ad0a12eb8dbaa2e/large_1920-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxNy0zYmViNDk2YjVlMDA1YmRkZTFiZWRiNzQ4YWQwYTEyZWI4ZGJhYTJlL2xhcmdlXzE5MjAtOWU3ZTg3NzVmYTM0Mjc0ZDRmZmE5MWNhMDBlZTk3ZDhkZWM4NWMwZS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxNy0zYmViNDk2YjVlMDA1YmRkZTFiZWRiNzQ4YWQwYTEyZWI4ZGJhYTJlL2xhcmdlXzE5MjAtOWU3ZTg3NzVmYTM0Mjc0ZDRmZmE5MWNhMDBlZTk3ZDhkZWM4NWMwZS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:24Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060787",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6659132060",
      "type": "image",
      "attributes": {
        "name": "DBI-37-MhYQZS23-downloadable-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015516-871293dd0c96136f66a6587fd594abbcbb06e553/downloadable-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015516-871293dd0c96136f66a6587fd594abbcbb06e553/large_1920-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxNi04NzEyOTNkZDBjOTYxMzZmNjZhNjU4N2ZkNTk0YWJiY2JiMDZlNTUzL2xhcmdlXzE5MjAtN2M5MTdhZmRjZTIwNjhjNTdiZDlmNGZjNmFjM2NlYTA3YmM3NzM3Ny5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxNi04NzEyOTNkZDBjOTYxMzZmNjZhNjU4N2ZkNTk0YWJiY2JiMDZlNTUzL2xhcmdlXzE5MjAtN2M5MTdhZmRjZTIwNjhjNTdiZDlmNGZjNmFjM2NlYTA3YmM3NzM3Ny5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:23Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060787",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI0124388920",
      "type": "image",
      "attributes": {
        "name": "DBI-36-7kGDTEy8-downloadable-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015515-b4ffc64f5fa08dddc393a7247a485e9e985abe03/downloadable-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015515-b4ffc64f5fa08dddc393a7247a485e9e985abe03/large_1920-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxNS1iNGZmYzY0ZjVmYTA4ZGRkYzM5M2E3MjQ3YTQ4NWU5ZTk4NWFiZTAzL2xhcmdlXzE5MjAtN2QyMDJjYjIwZGExM2IxZDFjZDc0ZTgzMTc5ODhhYjI2Y2E3YjJmZC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxNS1iNGZmYzY0ZjVmYTA4ZGRkYzM5M2E3MjQ3YTQ4NWU5ZTk4NWFiZTAzL2xhcmdlXzE5MjAtN2QyMDJjYjIwZGExM2IxZDFjZDc0ZTgzMTc5ODhhYjI2Y2E3YjJmZC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:22Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060789",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI7707493802",
      "type": "image",
      "attributes": {
        "name": "DBI-35-XUpRlnTM-downloadable-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015514-708eb1738aed9eb464638f55872d26ba76844d0f/downloadable-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015514-708eb1738aed9eb464638f55872d26ba76844d0f/large_1920-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxNC03MDhlYjE3MzhhZWQ5ZWI0NjQ2MzhmNTU4NzJkMjZiYTc2ODQ0ZDBmL2xhcmdlXzE5MjAtNTM1ZmY3YjQ3OTliNTRmMDg2ZWYxMzQxN2UwYmIyNjliMzkyNTUxOS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxNC03MDhlYjE3MzhhZWQ5ZWI0NjQ2MzhmNTU4NzJkMjZiYTc2ODQ0ZDBmL2xhcmdlXzE5MjAtNTM1ZmY3YjQ3OTliNTRmMDg2ZWYxMzQxN2UwYmIyNjliMzkyNTUxOS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:21Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060789",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI9544229767",
      "type": "image",
      "attributes": {
        "name": "DBI-34-i4Ok33fX-downloadable-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015513-d5f17e593f84020aeec019d8defe7354ca32c7f2/downloadable-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015513-d5f17e593f84020aeec019d8defe7354ca32c7f2/large_1920-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxMy1kNWYxN2U1OTNmODQwMjBhZWVjMDE5ZDhkZWZlNzM1NGNhMzJjN2YyL2xhcmdlXzE5MjAtZTBlMGVjNjMwMjVlMTY1NjIwZWE1ZWYzMDU3NDZmN2UyNTcxYjcxMC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxMy1kNWYxN2U1OTNmODQwMjBhZWVjMDE5ZDhkZWZlNzM1NGNhMzJjN2YyL2xhcmdlXzE5MjAtZTBlMGVjNjMwMjVlMTY1NjIwZWE1ZWYzMDU3NDZmN2UyNTcxYjcxMC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:20Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060787",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3750641461",
      "type": "image",
      "attributes": {
        "name": "DBI-33-Lq4iG8Ry-downloadable-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015512-d5f17e593f84020aeec019d8defe7354ca32c7f2/downloadable-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015512-d5f17e593f84020aeec019d8defe7354ca32c7f2/large_1920-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxMi1kNWYxN2U1OTNmODQwMjBhZWVjMDE5ZDhkZWZlNzM1NGNhMzJjN2YyL2xhcmdlXzE5MjAtMDYzMmRhNmQzOTQ0NTYxZmE3MzQyODlmNDM3ZGFkNjRjMWM4YmUxMi5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxMi1kNWYxN2U1OTNmODQwMjBhZWVjMDE5ZDhkZWZlNzM1NGNhMzJjN2YyL2xhcmdlXzE5MjAtMDYzMmRhNmQzOTQ0NTYxZmE3MzQyODlmNDM3ZGFkNjRjMWM4YmUxMi5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:20Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060789",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI7841076370",
      "type": "image",
      "attributes": {
        "name": "DBI-32-u2CV2bmf-downloadable-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015511-1c201cd5d55b39f9dbf1c063bd30a86606254d79/downloadable-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015511-1c201cd5d55b39f9dbf1c063bd30a86606254d79/large_1920-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxMS0xYzIwMWNkNWQ1NWIzOWY5ZGJmMWMwNjNiZDMwYTg2NjA2MjU0ZDc5L2xhcmdlXzE5MjAtNTY2YzU4MDU0ZWIxMDM2NDBkYjk5Y2UzYzQwZDQwMWQ1ZTBkOTY0Ni5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxMS0xYzIwMWNkNWQ1NWIzOWY5ZGJmMWMwNjNiZDMwYTg2NjA2MjU0ZDc5L2xhcmdlXzE5MjAtNTY2YzU4MDU0ZWIxMDM2NDBkYjk5Y2UzYzQwZDQwMWQ1ZTBkOTY0Ni5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:19Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060788",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI2466869493",
      "type": "image",
      "attributes": {
        "name": "DBI-31-BOjX20OI-downloadable-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015510-4b6f46c87ecdf2a60688f8f30ffaca2dd17b1aea/downloadable-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015510-4b6f46c87ecdf2a60688f8f30ffaca2dd17b1aea/large_1920-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxMC00YjZmNDZjODdlY2RmMmE2MDY4OGY4ZjMwZmZhY2EyZGQxN2IxYWVhL2xhcmdlXzE5MjAtYjAzYzk0ZmRjMjUyNTAyYWE5ZjcwZmVhMWE2MGY4NmE2YTc1Y2NiMC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUxMC00YjZmNDZjODdlY2RmMmE2MDY4OGY4ZjMwZmZhY2EyZGQxN2IxYWVhL2xhcmdlXzE5MjAtYjAzYzk0ZmRjMjUyNTAyYWE5ZjcwZmVhMWE2MGY4NmE2YTc1Y2NiMC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:18Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060787",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI2890931169",
      "type": "image",
      "attributes": {
        "name": "DBI-30-gT2A6BW9-downloadable-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015509-8194a9d3175a1412b23bf2d33727ba95da08f837/downloadable-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015509-8194a9d3175a1412b23bf2d33727ba95da08f837/large_1920-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwOS04MTk0YTlkMzE3NWExNDEyYjIzYmYyZDMzNzI3YmE5NWRhMDhmODM3L2xhcmdlXzE5MjAtODk0ZDRjODk5OGI5NDQwMzA0MTA4ZjA5MmRlZTVkZDViMTFkOTRiNy5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwOS04MTk0YTlkMzE3NWExNDEyYjIzYmYyZDMzNzI3YmE5NWRhMDhmODM3L2xhcmdlXzE5MjAtODk0ZDRjODk5OGI5NDQwMzA0MTA4ZjA5MmRlZTVkZDViMTFkOTRiNy5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:17Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060788",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI9442703147",
      "type": "image",
      "attributes": {
        "name": "DBI-29-BM194O9v-downloadable-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015508-25c7c48e3c2ccc4304a89080c413f8923a9ff117/downloadable-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015508-25c7c48e3c2ccc4304a89080c413f8923a9ff117/large_1920-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwOC0yNWM3YzQ4ZTNjMmNjYzQzMDRhODkwODBjNDEzZjg5MjNhOWZmMTE3L2xhcmdlXzE5MjAtNDRhZDZhZjExM2E3ZDRmNGI4NDFlMWUxYjU3ZTJmZmQ3YWVjZDQ2YS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwOC0yNWM3YzQ4ZTNjMmNjYzQzMDRhODkwODBjNDEzZjg5MjNhOWZmMTE3L2xhcmdlXzE5MjAtNDRhZDZhZjExM2E3ZDRmNGI4NDFlMWUxYjU3ZTJmZmQ3YWVjZDQ2YS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:16Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060788",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4080184116",
      "type": "image",
      "attributes": {
        "name": "DBI-28-AnqfHPhJ-downloadable-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015507-2e451e84211211b40626550f116b94f9f0f3ddf3/downloadable-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015507-2e451e84211211b40626550f116b94f9f0f3ddf3/large_1920-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwNy0yZTQ1MWU4NDIxMTIxMWI0MDYyNjU1MGYxMTZiOTRmOWYwZjNkZGYzL2xhcmdlXzE5MjAtMDY4Mjc5M2U3OTk2ODUxYzFkYTRjODFiNTk2M2Y5MDhjNWMyYTUzOS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwNy0yZTQ1MWU4NDIxMTIxMWI0MDYyNjU1MGYxMTZiOTRmOWYwZjNkZGYzL2xhcmdlXzE5MjAtMDY4Mjc5M2U3OTk2ODUxYzFkYTRjODFiNTk2M2Y5MDhjNWMyYTUzOS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:15Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060788",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI5596724201",
      "type": "image",
      "attributes": {
        "name": "DBI-27-Uy8s1nd2-downloadable-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015506-a7f8bb9d4bd631c7023684e50f0726309bcc6063/downloadable-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015506-a7f8bb9d4bd631c7023684e50f0726309bcc6063/large_1920-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwNi1hN2Y4YmI5ZDRiZDYzMWM3MDIzNjg0ZTUwZjA3MjYzMDliY2M2MDYzL2xhcmdlXzE5MjAtZmFmYjE3YTFhYmI1NGRjOGEyZTcwYTUyNDBhZjcwMmY2ZmYwNTljNS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwNi1hN2Y4YmI5ZDRiZDYzMWM3MDIzNjg0ZTUwZjA3MjYzMDliY2M2MDYzL2xhcmdlXzE5MjAtZmFmYjE3YTFhYmI1NGRjOGEyZTcwYTUyNDBhZjcwMmY2ZmYwNTljNS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:14Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060788",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6577104448",
      "type": "image",
      "attributes": {
        "name": "DBI-26-eznvSVXq-downloadable-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015505-cf6c8dc917698adc7547561bff3ef0535d4c6003/downloadable-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015505-cf6c8dc917698adc7547561bff3ef0535d4c6003/large_1920-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwNS1jZjZjOGRjOTE3Njk4YWRjNzU0NzU2MWJmZjNlZjA1MzVkNGM2MDAzL2xhcmdlXzE5MjAtZDg0NDQ1NzAzYTVlZTY5OTEzZGVhMTBiOWNmMTFhOTJmZjA5NDg3MC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwNS1jZjZjOGRjOTE3Njk4YWRjNzU0NzU2MWJmZjNlZjA1MzVkNGM2MDAzL2xhcmdlXzE5MjAtZDg0NDQ1NzAzYTVlZTY5OTEzZGVhMTBiOWNmMTFhOTJmZjA5NDg3MC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:13Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060788",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3808108928",
      "type": "image",
      "attributes": {
        "name": "DBI-25-GFYGEwIH-downloadable-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015504-cf6c8dc917698adc7547561bff3ef0535d4c6003/downloadable-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015504-cf6c8dc917698adc7547561bff3ef0535d4c6003/large_1920-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwNC1jZjZjOGRjOTE3Njk4YWRjNzU0NzU2MWJmZjNlZjA1MzVkNGM2MDAzL2xhcmdlXzE5MjAtNTE4ZThmN2I0MGQ3ZTY1ODY4NzM2YTcyMGU0N2Y5YWNjZWIzN2U0NC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwNC1jZjZjOGRjOTE3Njk4YWRjNzU0NzU2MWJmZjNlZjA1MzVkNGM2MDAzL2xhcmdlXzE5MjAtNTE4ZThmN2I0MGQ3ZTY1ODY4NzM2YTcyMGU0N2Y5YWNjZWIzN2U0NC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:13Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060787",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI9847294316",
      "type": "image",
      "attributes": {
        "name": "DBI-23-DGog6O5t-downloadable-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015502-8aed9b700afbf74cbc2273a86fc39c0c83bce306/downloadable-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015502-8aed9b700afbf74cbc2273a86fc39c0c83bce306/large_1920-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwMi04YWVkOWI3MDBhZmJmNzRjYmMyMjczYTg2ZmMzOWMwYzgzYmNlMzA2L2xhcmdlXzE5MjAtMmYwNjEzOTQxODI4ODJiOTQ1OTc1YmRiOGZhMTRkNWY5MzYxMTJjYS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwMi04YWVkOWI3MDBhZmJmNzRjYmMyMjczYTg2ZmMzOWMwYzgzYmNlMzA2L2xhcmdlXzE5MjAtMmYwNjEzOTQxODI4ODJiOTQ1OTc1YmRiOGZhMTRkNWY5MzYxMTJjYS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:11Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060788",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3788815565",
      "type": "image",
      "attributes": {
        "name": "DBI-21-vsTYs4t6-downloadable-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015500-abbb841df8008f22338be83ea7d37681fd534a2d/downloadable-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015500-abbb841df8008f22338be83ea7d37681fd534a2d/large_1920-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwMC1hYmJiODQxZGY4MDA4ZjIyMzM4YmU4M2VhN2QzNzY4MWZkNTM0YTJkL2xhcmdlXzE5MjAtZWI2NmNjYjVmYTRiMzQ5NjZkODM1ZDFmYTU3YzU5ZDc3YWY1OWUwMS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTUwMC1hYmJiODQxZGY4MDA4ZjIyMzM4YmU4M2VhN2QzNzY4MWZkNTM0YTJkL2xhcmdlXzE5MjAtZWI2NmNjYjVmYTRiMzQ5NjZkODM1ZDFmYTU3YzU5ZDc3YWY1OWUwMS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:31:09Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060789",
            "type": "shot"
          }
        }
      }
    }]
  };
  _exports.default = _default;
});