define("clients/templates/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xZm4mL7x",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\"],[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"hideShowRegisterModal\",\"goToOrderFlow\"],[[30,[36,1],[[32,0],\"hideShowRegisterModal\"],null],[30,[36,1],[[32,0],[35,0],\"goToOrderFlow\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"send\",\"action\",\"registration-modal\",\"-outlet\",\"component\",\"showRegisterModal\",\"if\"]}",
    "meta": {
      "moduleName": "clients/templates/dashboard.hbs"
    }
  });

  _exports.default = _default;
});