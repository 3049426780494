define("clients/templates/components/ellipse-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W6EK6poT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"ellipse-checkbox \",[34,1]]]],[12],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"img\"],[24,0,\"checkbox-icon\"],[24,\"src\",\"/assets/images/Ellipse_checkmark.svg\"],[4,[38,0],[[32,0],\"toggleCheckbox\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"img\"],[24,0,\"checkbox-icon\"],[24,\"src\",\"/assets/images/Ellipse_checkbox.svg\"],[4,[38,0],[[32,0],\"toggleCheckbox\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[11,\"span\"],[4,[38,0],[[32,0],\"toggleCheckbox\"],null],[12],[1,[34,4]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"classes\",\"value\",\"if\",\"label\"]}",
    "meta": {
      "moduleName": "clients/templates/components/ellipse-checkbox.hbs"
    }
  });

  _exports.default = _default;
});