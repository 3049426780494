define("clients/data/map_features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    featureOptions: {
      LINE: {
        geometry_type: 'LineString',
        feature_type: 'line_string',
        catalogEnabled: true,
        iconName: 'line',
        defaultName: 'Line',
        mapColor: '',
        keyColor: '',
        defaultOptions: {
          paths: null,
          strokeOpacity: 1,
          strokeWeight: 6,
          editable: true,
          draggable: true,
          zIndex: 2
        }
      },
      PROPERTYAREA: {
        no_fly_zone: false,
        // TODO: remove it. Update to feature_type property (EN-4375)
        geometry_type: 'Polygon',
        feature_type: 'polygon',
        catalogEnabled: true,
        iconName: 'polygon',
        defaultName: 'Property Area',
        mapColor: '',
        keyColor: '',
        defaultOptions: {
          paths: null,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillOpacity: 0.7,
          editable: true,
          draggable: true,
          zIndex: 2
        }
      },
      NOFLYZONE: {
        no_fly_zone: true,
        // TODO: remove it. Update to feature_type property (EN-4375)
        geometry_type: 'Polygon',
        feature_type: 'no_fly_zone',
        iconName: 'nfz',
        defaultName: 'No Fly Zone',
        mapColor: '#F26649',
        keyColor: 'transparent',
        defaultOptions: {
          paths: null,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillOpacity: 0.7,
          editable: true,
          draggable: true,
          zIndex: 2
        }
      },
      POINTOFINTEREST: {
        no_fly_zone: false,
        geometry_type: 'Point',
        feature_type: 'point_of_interest',
        iconName: 'point',
        defaultName: 'Point of Interest',
        catalogEnabled: true,
        mapColor: 'transparent',
        keyColor: 'transparent',
        defaultOptions: {
          paths: null,
          draggable: true,
          icon: '/assets/images/map/point_of_interest_pin.svg',
          zIndex: 3
        }
      },
      PANORAMA: {
        geometry_type: 'Point',
        feature_type: 'panorama',
        defaultName: 'Panorama',
        iconName: 'panorama_default',
        mapColor: 'transparent',
        keyColor: 'transparent',
        defaultOptions: {
          paths: null,
          draggable: true,
          icon: '/assets/images/map/panorama_pin.svg',
          zIndex: 3
        }
      }
    },
    selectedFeatureOptions: {
      strokeOpacity: 1,
      strokeWeight: 2,
      fillOpacity: 0.7,
      editable: true,
      draggable: true,
      zIndex: 2
    },
    disabledFeatureOptions: {
      strokeOpacity: 0.7,
      strokeWeight: 2,
      fillOpacity: 0.3,
      editable: false,
      draggable: false,
      zIndex: 1
    },
    featureColors: ['#009662', '#5B4DD3', '#FFCB05', '#3FC0C2', '#028AFF'],
    noteIcons: ['/assets/images/map/icon-note-1.png', '/assets/images/map/icon-note-2.png', '/assets/images/map/icon-note-3.png', '/assets/images/map/icon-note-4.png', '/assets/images/map/icon-note-5.png', '/assets/images/map/icon-note-6.png', '/assets/images/map/icon-note-7.png', '/assets/images/map/icon-note-8.png', '/assets/images/map/icon-note-9.png'],
    mapStylesData: [{
      "stylers": [{
        "saturation": -100
      }, {
        "gamma": 1
      }]
    }, {
      "elementType": "labels.text.stroke",
      "stylers": [{
        "color": "#000000"
      }]
    }, {
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#FFFFFF"
      }]
    }, {
      "feature_type": "road",
      "elementType": "geometry",
      "stylers": [{
        "visibility": "simplified"
      }]
    }, {
      "feature_type": "water",
      "stylers": [{
        "visibility": "on"
      }, {
        "saturation": 50
      }, {
        "gamma": 0
      }, {
        "hue": "#50a5d1"
      }]
    }, {
      "feature_type": "transit.station",
      "elementType": "labels.icon",
      "stylers": [{
        "gamma": 1
      }, {
        "saturation": 50
      }]
    }, {
      "feature_type": "administrative",
      "elementType": "labels",
      "stylers": [{
        "visibility:": "off"
      }]
    }, {
      "feature_type": "poi",
      "elementType": "labels",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "feature_type": "water",
      "elementType": "labels",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "feature_type": "transit",
      "elementType": "labels",
      "stylers": [{
        "visibility": "off"
      }]
    }]
  };
  _exports.default = _default;
});