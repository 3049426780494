define("clients/helpers/DBMap/meters-to-feet-sqr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.metersToFeetSqr = _exports.default = void 0;

  const metersToFeetSqr = function () {
    const area = parseFloat(arguments.length <= 0 ? undefined : arguments[0]);

    if (area != null && typeof area != 'undefined' && !isNaN(area)) {
      const val = Math.round(area * 10.76391).toLocaleString();
      return `${val} sqr feet`;
    } else {
      return '';
    }
  };

  _exports.metersToFeetSqr = metersToFeetSqr;

  var _default = Ember.Helper.helper(metersToFeetSqr);

  _exports.default = _default;
});