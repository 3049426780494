define("clients/templates/components/ortho-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/I4i+EHj",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,1,[31,[\"ortho\",[34,0,[\"shot\",\"id\"]]]]],[15,0,[31,[\"ortho \",[30,[36,1],[[35,0,[\"final\"]],\"asset-final\"],null]]]],[12],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"ortho\",\"if\"]}",
    "meta": {
      "moduleName": "clients/templates/components/ortho-viewer.hbs"
    }
  });

  _exports.default = _default;
});