define("clients/components/order-flow/select-package", ["exports", "clients/config/environment", "clients/mixins/google-map-package-mixin"], function (_exports, _environment, _googleMapPackageMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var PickPackageComponent,
      indexOf = [].indexOf || function (item) {
    for (var i = 0, l = this.length; i < l; i++) {
      if (i in this && this[i] === item) return i;
    }

    return -1;
  };

  PickPackageComponent = Ember.Component.extend(_googleMapPackageMixin.default, {
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    metrics: Ember.inject.service(),
    verticalSelected: null,
    trackingId: Ember.computed('model.client.id', function () {
      if (this.get('model.client.id')) {
        return Number(this.get('model.client.id').substring(3));
      } else {
        return 'loggedout';
      }
    }),
    volumePricingRequestLink: "https://dbcustomers.zendesk.com/hc/en-us/requests/new?ticket_form_id=114093984671",
    showVolumePricingVerticals: ['Insurance', 'Property Management', 'Construction'],
    verticalToAssign: null,
    orderFlowPackages: Ember.computed('model.packages', 'model.orderFlow', function () {
      if (this.get('model.orderFlow') === 'clientUpload') {
        return this.get('model.packages').filterBy('order_flow', 'client_upload');
      } else {
        return this.get('model.packages').filterBy('order_flow', 'standard');
      }
    }),
    previousPackage: null,
    didInsertElement: function () {
      if (this.get('model.mission.package')) {
        this.set('showInput', false);
        return this.set('previousPackage', this.get('model.mission.package'));
      }
    },
    loggerTracking: Ember.on('init', function () {
      return this.get('metrics').trackPage({
        hitType: 'pageview',
        page: '/order_flow/pick_package'
      });
    }),
    selectedAddodns: Ember.computed('model.selectedAddonIds', function () {
      return this.get('model.selectedAddonIds') || [];
    }),
    listenedSelectedAddons: Ember.computed('selectedAddodns.[]', function () {
      return this.get('selectedAddodns');
    }),
    isSampleModalShown: false,
    init: function () {
      this._super();

      if (this.get('model.mission.package')) {
        this.set('verticalSelected', this.get('model.mission.package.vertical.name'));
      } else {
        this.set('verticalSelected', this.get('orderFlowPackages.firstObject.vertical.name'));
      }

      if (!this.get('session.isAuthenticated') && this.get('packageVerticals.length') === 1) {
        return this.set('verticalToAssign', this.get('orderFlowPackages.firstObject.vertical'));
      }
    },
    packageVerticals: Ember.computed('orderFlowPackages', function () {
      var allPackages, i, item, len, ref, verticals;
      allPackages = this.get('orderFlowPackages');
      verticals = [];

      if (allPackages) {
        for (i = 0, len = allPackages.length; i < len; i++) {
          item = allPackages[i];

          if (ref = item.get('vertical.name'), indexOf.call(verticals, ref) < 0) {
            verticals.push(item.get('vertical.name'));
          }
        }
      }

      return verticals;
    }),
    verticalToPackages: Ember.computed('orderFlowPackages', 'packageVerticals', function () {
      var i, len, mapping, ref, vertical;
      mapping = {};
      ref = this.get('packageVerticals');

      for (i = 0, len = ref.length; i < len; i++) {
        vertical = ref[i];
        mapping[vertical] = this.get('orderFlowPackages').filter(function (item) {
          if (item.get('vertical.name') === vertical) {
            item.set('displayName', item.get('name') + " - $" + item.get('priceInDollars'));
            return true;
          }

          return false;
        });
      }

      return mapping;
    }),
    selectedVerticalToPackages: Ember.computed('verticalSelected', 'verticalToPackages', function () {
      var clientPackages, defaultPackage, organizationPackages, otherPackages, packages, sortedPackages;
      packages = this.get('verticalToPackages')[this.get('verticalSelected')];
      clientPackages = packages.filter(function (el) {
        return el.get('client.id');
      }).sortBy('priceInDollars');
      organizationPackages = packages.filter(function (el) {
        return el.get('organization.id');
      }).sortBy('priceInDollars');
      otherPackages = packages.filter(function (el) {
        return !el.get('client.id') && !el.get('organization.id');
      }).sortBy('position', 'priceInDollars');
      sortedPackages = clientPackages.concat(organizationPackages, otherPackages).uniq();

      if (!this.get('model.mission.package')) {
        defaultPackage = 1;

        if (sortedPackages.length < 2) {
          defaultPackage = 0;
        }

        this.set('model.mission.package', sortedPackages[defaultPackage]);
      }

      return sortedPackages;
    }),
    selectVerticalText: Ember.computed('verticalSelected', function () {
      if (this.get('verticalSelected') === 'Commercial Real Estate' || this.get('verticalSelected') === 'Residential Real Estate') {
        return "Zeitview selects the most applicable shot list based on the property type and chosen package.";
      } else {
        return 'Please select your package.';
      }
    }),
    showVolumePricing: Ember.computed('verticalSelected', function () {
      return this.get('showVolumePricingVerticals').indexOf(this.get('verticalSelected')) >= 0;
    }),
    addonsIncludesInClientVerticals: Ember.computed('model.package_addons', 'model.org_package', 'model.client.available_addons', function () {
      if (Ember.isPresent(this.get('model.client.id'))) {
        return this.get('model.client.available_addons');
      } else {
        return this.get('model.package_addons').filter(function (_this) {
          return function (addon) {
            return addon.get('verticals').toArray().includes(_this.get('model.org_package.vertical'));
          };
        }(this));
      }
    }),
    selectedPackageAddons: Ember.computed('model.package_addons', 'model.mission.package', 'model.mission.package.addons', function () {
      if (!Ember.isPresent(this.get('model.mission.package'))) {
        return [];
      }

      return this.get('addonsIncludesInClientVerticals').map(function (_this) {
        return function (addon) {
          addon.set('disabled', !_this.get('model.mission.package.addons').toArray().includes(addon));
          return addon;
        };
      }(this));
    }),
    isPackageAddonsAny: Ember.computed('selectedPackageAddons.[]', function () {
      return Ember.isPresent(this.get('selectedPackageAddons'));
    }),
    actions: {
      changeVertical: function (vertical) {
        var defaultPackage;

        if (vertical !== this.get('verticalSelected')) {
          this.set('verticalSelected', vertical);
          defaultPackage = 1;

          if (this.get('selectedVerticalToPackages').length < 2) {
            defaultPackage = 0;
          }

          return this.set('model.mission.package', this.get('selectedVerticalToPackages')[defaultPackage]);
        }
      },
      increaseOrderStep: function () {
        if (this.get('session.isAuthenticated')) {
          if (this.get('model.mission.package') !== this.get('previousPackage')) {
            this.filterFeaturesByPackage(this.get('model.mission'));
          }

          return this.increaseOrderStepAction();
        } else {
          return this.set('showRegisterModal', true);
        }
      },
      toggleModal: function () {
        return this.set('showRegisterModal', false);
      },
      authenticated: function () {
        if (this.get('sessionAccount.account')) {
          return this.get('sessionAccount.account').then(function (_this) {
            return function (account) {
              _this.set('model.client', account);

              return _this.increaseOrderStepAction();
            };
          }(this));
        }
      },
      selectPackage: function (newPackage) {
        this.get('metrics').trackEvent({
          event: 'event',
          category: 'orderflow',
          action: 'click-select-package',
          label: newPackage.get('id'),
          value: this.get('trackingId')
        });
        this.set('model.mission.package', newPackage);
        this.set('model.selectedAddonIds', []);
        return this.set('selectedAddodns', []);
      },
      addAddonToMission: function (addonId) {
        this.get('metrics').trackEvent({
          event: 'event',
          category: 'orderflow',
          action: 'click-select-add-on',
          label: addonId,
          value: this.get('trackingId')
        });

        if (this.get('selectedAddodns').includes(addonId)) {
          this.set('selectedAddodns', this.get('selectedAddodns').removeObject(addonId));
        } else {
          this.set('selectedAddodns', this.get('selectedAddodns').addObject(addonId));
        }

        return this.set('model.selectedAddonIds', this.get('selectedAddodns'));
      },
      openSampleModal: function (addonId) {
        this.get('metrics').trackEvent({
          event: 'event',
          category: 'orderflow',
          action: 'click-sample-link-addon',
          label: addonId,
          value: this.get('trackingId')
        });
        return this.set('isSampleModalShown', true);
      },
      closeSampleModal: function () {
        return this.set('isSampleModalShown', false);
      }
    }
  });
  var _default = PickPackageComponent;
  _exports.default = _default;
});