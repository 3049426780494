define("clients/components/date-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DateRangeComponent;
  DateRangeComponent = Ember.Component.extend({
    didRender: function () {
      var cb, end, start;
      start = moment(this.get('startDate'));
      end = moment(this.get('endDate'));

      cb = function (start, end) {
        return $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
      };

      $('#reportrange').daterangepicker({
        startDate: start,
        endDate: end,
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
      }, cb);
      return cb(start, end);
    },
    actions: {
      setDateRange: function () {
        return this.sendAction('applyAction');
      }
    }
  });
  var _default = DateRangeComponent;
  _exports.default = _default;
});