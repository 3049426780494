define("clients/components/image-asset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    thumbnailObserver: Ember.observer('image.file.thumbnail', function () {
      // use setProperties to not trigger any ember data state changes
      return this.image.setProperties({
        generatingNativeThumbnail: false,
        nativeThumbnail: this.image.file?.thumbnail
      });
    }),
    actions: {
      deleteAsset(asset) {
        if (window.confirm('Are you sure? This will permanently remove this asset.')) {
          return asset.destroyRecord().then(() => alert('The asset was deleted successfully'), () => alert('There was an issue deleting this asset'));
        }
      }

    }
  });

  _exports.default = _default;
});