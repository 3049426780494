define("clients/components/order-flow/multi-step-order-flow", ["exports", "clients/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MultiStepOrderFlowComponent;
  MultiStepOrderFlowComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    orderStep: Ember.computed('session.isAuthenticated', 'model.client.client_upload_available', function () {
      if (this.get('session.isAuthenticated') && this.get('model.client.client_upload_available')) {
        return -1;
      } else {
        return 0;
      }
    }),
    isClientUpload: Ember.computed.equal('model.orderFlow', 'clientUpload'),
    isEsriAccount: Ember.computed('model.client.esri_account', function () {
      return Ember.isPresent(this.get('model.client') && this.get('model.client.esri_account'));
    }),
    orderStepsTotal: Ember.computed('isClientUpload', function () {
      if (this.get('isClientUpload')) {
        return 7;
      } else {
        return 6;
      }
    }),
    hashes: Ember.computed('isClientUpload', function () {
      if (this.get('isClientUpload')) {
        return ["#propertyInfo", "#package", "#siteDetails", "#missionDetails", "#payment", "#summary", "#upload", "#confirmation"];
      } else {
        return ["#propertyInfo", "#package", "#siteDetails", "#missionDetails", "#payment", "#summary", "#confirmation"];
      }
    }),
    invoiceCustomer: Ember.computed('session.isAuthenticated', 'model.client.invoiceable', function () {
      return this.get('session.isAuthenticated') && this.get('model.client.invoiceable');
    }),
    isUpsellActive: Ember.computed('model.upsellAddonId', function () {
      return Ember.isPresent(this.get('model.upsellAddonId'));
    }),
    packageAddons: Ember.computed('model.package_addons', 'model.org_package', 'model.client.available_addons', function () {
      if (Ember.isPresent(this.get('model.client.id'))) {
        return this.get('model.client.available_addons');
      } else {
        return this.get('model.package_addons').filter(function (_this) {
          return function (addon) {
            return addon.get('verticals').toArray().includes(_this.get('model.org_package.vertical'));
          };
        }(this));
      }
    }),
    firstUpsellAddon: Ember.computed('model.client.upsell_addon_ids.[]', 'packageAddons', function () {
      return this.get('packageAddons').filter(function (_this) {
        return function (addon) {
          return _this.get('model.client.upsell_addon_ids').includes(parseInt(addon.id));
        };
      }(this))[0];
    }),
    upsellable: Ember.computed('model.client.upsellable', function () {
      return this.get('model.client.upsellable');
    }),
    init: function () {
      var script;

      this._super();

      script = document.createElement('script');
      script.setAttribute("id", "ze-snippet");
      script.src = "https://static.zdassets.com/ekr/snippet.js?key=" + _environment.default.zendesk_key;
      return document.head.appendChild(script);
    },
    didInsertElement: function () {
      if (typeof $zopim !== "undefined" && $zopim !== null && _environment.default.environment !== 'test') {
        $zopim.livechat.window.show();
        return $zopim.livechat.window.toggle();
      }
    },
    didDestroyElement: function () {
      if (typeof $zopim !== "undefined" && $zopim !== null && _environment.default.environment !== 'test') {
        return $zopim.livechat.window.hide();
      }
    },
    addUpsellLineItems: function () {
      var model, upselLineItemsParams;
      model = this.get('model');
      upselLineItemsParams = model.selectedAddonIds.map(function (addonId) {
        var addon;
        addon = model.package_addons.findBy('id', addonId);
        return {
          origin_id: addonId,
          origin_type: 'PackageAddon',
          amount: model.upsellAddonId === addonId ? 0 : addon.price
        };
      });
      return model.mission.set('upsell_line_items_attributes', upselLineItemsParams);
    },
    actions: {
      updateAirspace: function (airspace) {
        return this.set('airspace', airspace);
      },
      increaseOrderStep: function () {
        var containerDiv, increase;
        increase = 1;

        if (this.get('orderStep') === 2 && this.get('isClientUpload') || this.get('orderStep') === 3 && this.get('invoiceCustomer')) {
          increase = 2;
        }

        if (this.get('orderStep') === 2 && this.get('isClientUpload') && this.get('invoiceCustomer')) {
          increase = 3;
        }

        this.set('orderStep', this.get('orderStep') + increase);
        window.location = this.get('hashes')[this.get('orderStep')];
        containerDiv = document.getElementsByClassName('orderflow-steps-container')[0];

        if (containerDiv) {
          return containerDiv.scrollTop = 0;
        }
      },
      setOrderStep: function (orderStep) {
        var containerDiv;

        if (orderStep < this.get('orderStep')) {
          this.set('orderStep', orderStep);
          window.location = this.get('hashes')[this.get('orderStep')];
          containerDiv = document.getElementsByClassName('orderflow-steps-container')[0];

          if (containerDiv) {
            return containerDiv.scrollTop = 0;
          }
        }
      },
      placeOrder: function () {
        var model;
        model = this.get('model');
        model.mission.set('location', model.location);

        if (model.selectedAddonIds && model.selectedAddonIds.length !== 0) {
          this.addUpsellLineItems();
        }

        return model.mission.get('location').save().then(function (_this) {
          return function (response) {
            console.log('success');
            return model.mission.save().then(function (response) {
              if (model.mission.get('onsite_contact')) {
                return model.mission.get('onsite_contact').save().then(function (response) {
                  return _this.send('processSchedulingContactSave', model.mission);
                }, function (response) {});
              } else {
                return _this.send('processSchedulingContactSave', model.mission);
              }
            }, function (response) {});
          };
        }(this), function (response) {
          return console.log('unsuccess');
        });
      },
      newOrder: function () {
        if (window.location.pathname === '/new_order') {
          window.location.reload();
          window.location.href = "";
        }

        return window.location = '/new_order';
      },
      processSchedulingContactSave: function (mission) {
        if (mission.get('scheduling_contact_line')) {
          return mission.get('scheduling_contact').save().then(function (_this) {
            return function (response) {
              if (_this.get('isClientUpload') && _this.get('orderStep') === 5) {
                return _this.get('goToUploadAction')(_this.get('model'));
              }

              _this.set('orderStep', _this.get('orderStep') + 1);

              return window.location = _this.get('hashes')[_this.get('orderStep')];
            };
          }(this), function (response) {});
        } else {
          if (this.get('isClientUpload') && this.get('orderStep') === 5) {
            return this.get('goToUploadAction')(this.get('model'));
          }

          this.set('orderStep', this.get('orderStep') + 1);
          return window.location = this.get('hashes')[this.get('orderStep')];
        }
      },
      activateUpsellOffer: function (addonId) {
        this.set('model.upsellAddonId', addonId);
        return this.get('metrics').trackEvent({
          event: 'event',
          category: 'Upsell',
          action: 'click',
          label: 'Accept upsell offer on order flow'
        });
      },
      setOrderFlow: function (orderFlow) {
        return this.set('model.orderFlow', orderFlow);
      },
      goToInsightsAction: function () {
        return this.goToInsights();
      },
      contactAboutInsightsAction: function () {
        return this.contactAboutInsights();
      },
      closeInsightsBannerAction: function () {
        return this.closeInsightsBanner();
      }
    }
  });
  var _default = MultiStepOrderFlowComponent;
  _exports.default = _default;
});