define("clients/components/order-flow/airspace-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showRestrictedAirspaceModal: false,
    promptShown: false,
    init: function () {
      this._super();

      document.addEventListener('click', event => {
        if (this.promptShown && !event.target.className.includes('question-bubble')) {
          this.set('promptShown', false);
        }
      });
    },
    fullyRestricted: Ember.computed.equal('airspace.restriction_level', 'fully_restricted'),
    partiallyRestricted: Ember.computed.equal('airspace.restriction_level', 'partially_restricted'),
    actions: {
      toggleRestrictedAirspaceInfoModal: function () {
        this.toggleProperty('showRestrictedAirspaceModal');
      },
      togglePrompt: function () {
        this.toggleProperty('promptShown');
      }
    }
  });

  _exports.default = _default;
});