define("clients/controllers/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    cookies: Ember.inject.service(),
    queryParams: ['code'],
    metrics: Ember.inject.service(),
    code: null,
    client: Ember.computed.reads('model.account'),
    esriAccount: Ember.observer('code', 'client', function () {
      const code = this.code;
      const client = this.client;

      if (code && client) {
        var esriAccount;
        esriAccount = client.get('esri_account') || client.store.createRecord('esri-account', {
          client: client,
          authorization_code: code
        });
        esriAccount.save().then(() => {
          this.set('code', null);
          this.send('trackEvent', 'Connected ArcGIS Account');
          const cookieService = this.cookies;
          const cookies = cookieService.read();

          if (cookies.isOnboardingCustomer) {
            cookieService.clear('isOnboardingCustomer');
            this.transitionToRoute('order-flow');
          }
        }).catch(error => {
          this.set('code', null);

          if (!esriAccount.get('id')) {
            esriAccount.destroyRecord();
          }

          Ember.run.later(() => {
            window.alert(error.errors[0].detail);
          }, 1);
        });
      }
    }),
    actions: {
      toggleShowForm: function () {
        this.set('showContactForm', true);
        this.send('trackEvent', 'Opened Integrations Submission Form');
      },
      trackEvent: function (event) {
        this.metrics.trackEvent({
          event: 'event',
          category: 'Client Integrations',
          action: event,
          label: undefined
        });
      }
    }
  });

  _exports.default = _default;
});