define("clients/components/map-feature-item", ["exports", "clients/helpers/DBMap/format-length", "clients/helpers/DBMap/format-area"], function (_exports, _formatLength, _formatArea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['map-feature-item'],
    displayName: Ember.computed('mapFeatureItem.map_feature.{geometry.type,name}', function () {
      return this.get('mapFeatureItem.map_feature.name') + ' - ' + this.get('mapFeatureItem.map_feature.geometry.type');
    }),
    featureDetails: Ember.computed('mapFeatureItem.feature_details', function () {
      let calculator_debug_info = this.get('mapFeatureItem.feature_details');

      if (calculator_debug_info.area_in_meters) {
        return (0, _formatArea.formatArea)(calculator_debug_info.area_in_meters);
      } else {
        return (0, _formatLength.formatLength)(calculator_debug_info.length_in_meters);
      }
    })
  });

  _exports.default = _default;
});