define("clients/components/input-readonly-select-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputReadonlySelectAllComponent;
  InputReadonlySelectAllComponent = Ember.TextField.extend({
    readonly: true,
    type: 'text',
    didInsertElement: function () {
      return Ember.run.schedule('afterRender', this, function () {
        return this.$().select();
      });
    },
    click: function () {
      return this.$().select();
    }
  });
  var _default = InputReadonlySelectAllComponent;
  _exports.default = _default;
});