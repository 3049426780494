define("clients/templates/components/input-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UArRR+ce",
    "block": "{\"symbols\":[\"search\"],\"statements\":[[6,[37,15],null,[[\"onClickOutside\"],[[30,[36,3],[[32,0],\"outsideClick\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"db-input-autocomplete\"],[12],[2,\"\\n    \"],[1,[30,[36,10],null,[[\"type\",\"value\",\"focus-in\",\"key-up\",\"class\",\"autocomplete\"],[\"text\",[35,9],[30,[36,3],[[32,0],\"showSuggestions\"],null],[30,[36,3],[[32,0],\"debouncedUpdateSearchValue\"],null],[30,[36,8],[\"form-control input-md\",[35,7]],null],false]]]],[2,\"\\n\"],[6,[37,14],[[30,[36,13],[[35,12],[35,11]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"db-input-autocomplete-results\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[11,\"div\"],[24,0,\"search-result\"],[4,[38,3],[[32,0],\"selectItem\",[30,[36,2],[[30,[36,1],[[32,1],[35,0]],null],[32,1,[\"text\"]]],null]],null],[12],[2,\"\\n            \"],[10,\"span\"],[14,0,\"title\"],[12],[1,[30,[36,2],[[30,[36,1],[[32,1],[35,0]],null],[32,1,[\"text\"]]],null]],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"matchAttribute\",\"get\",\"either\",\"action\",\"searchResults\",\"-track-array\",\"each\",\"additionalClass\",\"concat-string\",\"searchValue\",\"input\",\"isShowingSuggestions\",\"isSearchResultPresent\",\"and\",\"if\",\"click-outside\"]}",
    "meta": {
      "moduleName": "clients/templates/components/input-autocomplete.hbs"
    }
  });

  _exports.default = _default;
});