define("clients/routes/sample-mission", ["exports", "clients/mixins/css-class-namespace", "clients/data/guest-sample-missions", "clients/data/guest-sample-images"], function (_exports, _cssClassNamespace, _guestSampleMissions, _guestSampleImages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cssClassNamespace.default, {
    metrics: Ember.inject.service(),

    model(params) {
      const sampleMissionId = params.mission_id;
      const sampleMission = _guestSampleMissions.default[sampleMissionId];
      const sampleImages = _guestSampleImages.default[sampleMissionId];
      const missionId = sampleMission.data.id;
      this.store.pushPayload(sampleMission);
      this.store.pushPayload(sampleImages);
      const mission = this.store.peekRecord('mission', missionId);
      return {
        mission: mission,
        sampleMissionId: sampleMissionId
      };
    },

    setupController(controller, model) {
      this._super(controller, model);

      this.controllerFor('application').set('showNavBar', true);
      this.controllerFor('application').set('hideSidebar', true);
      return this.controllerFor('application').set('minimizedSidebar', false);
    },

    actions: {
      trackGoToOrderFlow: function (mission) {
        this.metrics.trackEvent({
          event: 'event',
          category: 'Sample Mission Header Logged Out',
          action: 'click',
          label: "Go to Order Flow From Logged Out Sample Mission"
        });
        this.transitionTo(`/book/${mission.get('package.slug')}`);
      }
    }
  });

  _exports.default = _default;
});