define("clients/validations/contact", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    'name': (0, _validators.validatePresence)(true),
    'phone': [(0, _validators.validateFormat)({
      regex: /^([+ .\-0-9()]){10,20}$/,
      message: "Please enter a valid phone number"
    })]
  };
  _exports.default = _default;
});