define("clients/models/activity-log", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created_at: (0, _model.attr)('date'),
    action: (0, _model.attr)('string'),
    reason: (0, _model.attr)('string'),
    blurb: (0, _model.attr)('string'),
    additional_info: (0, _model.attr)('Hash')
  });

  _exports.default = _default;
});