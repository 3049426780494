define("clients/helpers/titleize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TitleizeHelper;
  TitleizeHelper = Ember.Helper.helper(function (arg) {
    var string;
    string = arg[0];
    return string.split('_').map(function (st) {
      return st.charAt(0).toUpperCase() + st.slice(1);
    }).join(' ');
  });
  var _default = TitleizeHelper;
  _exports.default = _default;
});