define("clients/components/mission-group/create-modal", ["exports", "clients/components/mission-group/base-modal"], function (_exports, _baseModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModal.default.extend({
    groupName: '',
    GMTEventAttrs: {
      label: 'Create new mission group'
    },
    saveRecord: function () {
      const group = this.store.createRecord('mission-group', {
        name: this.groupName
      });
      group.get('missions').pushObjects(this.selectedMissions);
      return group.save();
    },
    actions: {
      openMoveModal() {
        this.openMoveModal();
      }

    }
  });

  _exports.default = _default;
});