define("clients/initializers/ember-stripe-service", ["exports", "ember-stripe-service/utils/stripe-mock", "clients/config/environment"], function (_exports, _stripeMock, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    const application = arguments[1] || arguments[0];
    let stripeConfig = _environment.default.stripe || {};
    stripeConfig.debug = stripeConfig.debug || _environment.default.LOG_STRIPE_SERVICE;
    application.register('config:stripe', stripeConfig, {
      instantiate: false
    });
    application.inject('service:stripe', 'config', 'config:stripe');

    if (stripeConfig.debug) {
      /* eslint-disable no-console */
      console.log('StripeService: initialize');
    }

    if (!stripeConfig.publishableKey) {
      throw new Ember.Error("StripeService: Missing Stripe key, please set `ENV.stripe.publishableKey` in config.environment.js");
    }

    if (typeof FastBoot !== 'undefined' || stripeConfig.mock) {
      window.Stripe = _stripeMock.default;
    }
  }

  var _default = {
    name: 'ember-stripe-stripe',
    initialize: initialize
  };
  _exports.default = _default;
});