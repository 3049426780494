define("clients/data/sample_missions/cre_starter_package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": {
      "id": "DBM2797682589",
      "type": "missions",
      "attributes": {
        "instructions": "+ Scope: Aerial Images and Videos\n\n+ Time: Between 11am - 2pm\n\n+ Schedule: Within 48-72 hours; Weather permitting",
        "status": "complete",
        "client_id": "DBC3006554",
        "payment": {
          "id": null,
          "processor": null,
          "card": "Visa - 3969"
        },
        "external_assets_url": null,
        "owned_by_current_client": true,
        "contact_email": "clients@zeitview.com",
        "created_on": "2019-12-04T01:14:45Z",
        "is_pano_mission": false,
        "logo_url": null,
        "blurb": null,
        "paid": true,
        "paid_on": "2019-12-04T01:39:36Z",
        "price": 36900,
        "archive_url": null,
        "edit_url": null,
        "customer_instructions": null,
        "scheduled_at_start": "2019-12-04T20:00:00Z",
        "scheduled_at_end": "2019-12-04T21:00:00Z",
        "parent_id": null,
        "reshoot_mission_id": null,
        "rejection_reason": null,
        "rejection_notes": null,
        "reference_id": "CRE Starter Package Sample",
        "cloud_reqs": "less_than_50",
        "scheduling_requirement": "not_needed",
        "arcgis_feature_layer": null,
        "geo_properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-93.797345818524, 41.592623738175], [-93.796102088361, 41.592607690333], [-93.795337658791, 41.592614820771], [-93.795528095631, 41.593488528785], [-93.79628159266, 41.59350959129], [-93.796326986508, 41.593675082158], [-93.796879114156, 41.593669064316], [-93.796908618455, 41.593242689551], [-93.797345818524, 41.592623738175]]]
            },
            "properties": {
              "name": "Polygon #1",
              "notes": "",
              "number": 1,
              "area": "14302.0",
              "bounds": {
                "south": 41.592607690333,
                "west": -93.797345818524,
                "north": 41.593675082158,
                "east": -93.795337658791
              },
              "center": {
                "lat": 41.593141386246,
                "lng": -93.796341738658
              }
            },
            "id": 1
          }]
        }
      },
      "relationships": {
        "location": {
          "data": {
            "id": "281730",
            "type": "locations"
          }
        },
        "package": {
          "data": {
            "id": "19181",
            "type": "packages"
          }
        },
        "credit_card": {
          "data": {
            "id": "4898",
            "type": "credit_cards"
          }
        },
        "onsite_contact": {
          "data": {
            "id": "49555",
            "type": "onsite_contacts"
          }
        },
        "scheduling_contact": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "images": {
          "data": [{
            "id": "DBI5556284910",
            "type": "images"
          }, {
            "id": "DBI0198292139",
            "type": "images"
          }, {
            "id": "DBI6659132060",
            "type": "images"
          }, {
            "id": "DBI0124388920",
            "type": "images"
          }, {
            "id": "DBI7707493802",
            "type": "images"
          }, {
            "id": "DBI9544229767",
            "type": "images"
          }, {
            "id": "DBI3750641461",
            "type": "images"
          }, {
            "id": "DBI7841076370",
            "type": "images"
          }, {
            "id": "DBI2466869493",
            "type": "images"
          }, {
            "id": "DBI2890931169",
            "type": "images"
          }, {
            "id": "DBI9442703147",
            "type": "images"
          }, {
            "id": "DBI4080184116",
            "type": "images"
          }, {
            "id": "DBI5596724201",
            "type": "images"
          }, {
            "id": "DBI6577104448",
            "type": "images"
          }, {
            "id": "DBI3808108928",
            "type": "images"
          }, {
            "id": "DBI9847294316",
            "type": "images"
          }, {
            "id": "DBI3788815565",
            "type": "images"
          }]
        },
        "videos": {
          "data": [{
            "id": "DBV7578211739",
            "type": "videos"
          }, {
            "id": "DBV1107089390",
            "type": "videos"
          }, {
            "id": "DBV1146774300",
            "type": "videos"
          }, {
            "id": "DBV6366056555",
            "type": "videos"
          }, {
            "id": "DBV0676400554",
            "type": "videos"
          }, {
            "id": "DBV5665195607",
            "type": "videos"
          }, {
            "id": "DBV6900257772",
            "type": "videos"
          }, {
            "id": "DBV6049248834",
            "type": "videos"
          }, {
            "id": "DBV1558614244",
            "type": "videos"
          }, {
            "id": "DBV7500845184",
            "type": "videos"
          }, {
            "id": "DBV1767531265",
            "type": "videos"
          }, {
            "id": "DBV6499691486",
            "type": "videos"
          }, {
            "id": "DBV9795722273",
            "type": "videos"
          }, {
            "id": "DBV7885416812",
            "type": "videos"
          }, {
            "id": "DBV9833327584",
            "type": "videos"
          }, {
            "id": "DBV2958901198",
            "type": "videos"
          }, {
            "id": "DBV4246165317",
            "type": "videos"
          }, {
            "id": "DBV0655570721",
            "type": "videos"
          }, {
            "id": "DBV2752408662",
            "type": "videos"
          }, {
            "id": "DBV7634751337",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        },
        "attachments": {
          "data": []
        },
        "ortho_photo_maps": {
          "data": []
        },
        "shares": {
          "data": [{
            "id": "98c97bf0dc62f35e08cf643111be9438",
            "type": "shares"
          }, {
            "id": "c5347c4785542fcb22e930ac98c40063",
            "type": "shares"
          }]
        },
        "main_image": {
          "data": {
            "id": "DBI5556284910",
            "type": "images"
          }
        },
        "collaborators": {
          "data": []
        },
        "parent": {
          "data": null
        },
        "shots": {
          "data": [{
            "id": "1060790",
            "type": "shots"
          }, {
            "id": "1060789",
            "type": "shots"
          }, {
            "id": "1060788",
            "type": "shots"
          }, {
            "id": "1060787",
            "type": "shots"
          }, {
            "id": "1060786",
            "type": "shots"
          }, {
            "id": "1060785",
            "type": "shots"
          }, {
            "id": "1060784",
            "type": "shots"
          }, {
            "id": "1060783",
            "type": "shots"
          }, {
            "id": "1060782",
            "type": "shots"
          }, {
            "id": "1060781",
            "type": "shots"
          }, {
            "id": "1060780",
            "type": "shots"
          }]
        },
        "invoice_line_items": {
          "data": [{
            "id": "DBINV338990458617592",
            "type": "invoice_line_items"
          }]
        }
      }
    },
    "included": [{
      "id": "281730",
      "type": "locations",
      "attributes": {
        "name": "6400 Westown Pkwy, West Des Moines, IA 50266, USA",
        "latitude": 41.5933811,
        "longitude": -93.7962948,
        "address": "6400 Westown Parkway",
        "address2": "",
        "city": "West Des Moines",
        "state": "Iowa",
        "postal_code": "50266",
        "country": "United States",
        "formatted_address": "6400 Westown Pkwy, West Des Moines, IA 50266, USA",
        "timezone_id": "America/Chicago",
        "properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-93.797345818524, 41.592623738175], [-93.796102088361, 41.592607690333], [-93.795337658791, 41.592614820771], [-93.795528095631, 41.593488528785], [-93.79628159266, 41.59350959129], [-93.796326986508, 41.593675082158], [-93.796879114156, 41.593669064316], [-93.796908618455, 41.593242689551], [-93.797345818524, 41.592623738175]]]
            },
            "properties": {
              "name": "Polygon #1",
              "notes": "",
              "number": 1,
              "area": "14302.0",
              "bounds": {
                "south": 41.592607690333,
                "west": -93.797345818524,
                "north": 41.593675082158,
                "east": -93.795337658791
              },
              "center": {
                "lat": 41.593141386246,
                "lng": -93.796341738658
              }
            },
            "id": 1
          }]
        }
      }
    }, {
      "id": "19181",
      "type": "packages",
      "attributes": {
        "name": "Starter Package",
        "description": "High-quality aerial images and video showcasing your property and the surrounding area at multiple angles and altitudes.",
        "price": 36900,
        "cloud_reqs": "less_than_50",
        "position": 3,
        "slug": "cre-starter-pack"
      },
      "relationships": {
        "vertical": {
          "data": {
            "id": "1",
            "type": "verticals"
          }
        },
        "organization": {
          "data": null
        },
        "client": {
          "data": null
        },
        "catalog_items": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "verticals",
      "attributes": {
        "name": "Commercial Real Estate",
        "short_name": "CRE"
      }
    }, {
      "id": "4898",
      "type": "credit_cards",
      "attributes": {
        "brand": "Visa",
        "last_4": "3969",
        "exp_month": 2,
        "exp_year": 2024,
        "stripe_id": "card_1FW88bJo202eeWudwLnrPXPF"
      }
    }, {
      "id": "49555",
      "type": "onsite_contacts",
      "attributes": {
        "name": "John Smith",
        "call_action": "Call When You Arrive",
        "phone": "123-456-7890",
        "note": null
      }
    }, {
      "id": "DBC3006554",
      "type": "clients",
      "attributes": {
        "first_name": "Demo",
        "last_name": "Zeitview",
        "phone": "",
        "email": "clients@zeitview.com",
        "token": "qC65CqCmbKfMZvIU2gRb_uD7Sqw",
        "company_name": null,
        "invoiceable": false
      },
      "relationships": {
        "hidden_sample_missions": {
          "data": [{
            "id": "*",
            "type": "hidden_sample_mission"
          }]
        }
      }
    }, {
      "id": "DBI5556284910",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015518-a4956aa1edb151314c55c6819add91a2d9895444/original-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
        "name": "DBI-39-ANug2vfN-downloadable-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
        "source_type": "edit",
        "formatted_size": "7.09 MB",
        "size": 7430429,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593294444444,
        "gps_longitude": -93.794052777778,
        "gps_altitude": 295.7,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015518-a4956aa1edb151314c55c6819add91a2d9895444/large_1920-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015518-a4956aa1edb151314c55c6819add91a2d9895444/medium_1024-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015518-a4956aa1edb151314c55c6819add91a2d9895444/small_640-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015518-a4956aa1edb151314c55c6819add91a2d9895444/four_three_640-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015518-a4956aa1edb151314c55c6819add91a2d9895444/square_640-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015518-a4956aa1edb151314c55c6819add91a2d9895444/downloadable-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG"
        },
        "created_on": "2019-12-04T01:31:25Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060787",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0198292139",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015517-3beb496b5e005bdde1bedb748ad0a12eb8dbaa2e/original-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
        "name": "DBI-38-3QV9xoqS-downloadable-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
        "source_type": "edit",
        "formatted_size": "7.39 MB",
        "size": 7747773,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.59275,
        "gps_longitude": -93.794780555556,
        "gps_altitude": 297.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015517-3beb496b5e005bdde1bedb748ad0a12eb8dbaa2e/large_1920-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015517-3beb496b5e005bdde1bedb748ad0a12eb8dbaa2e/medium_1024-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015517-3beb496b5e005bdde1bedb748ad0a12eb8dbaa2e/small_640-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015517-3beb496b5e005bdde1bedb748ad0a12eb8dbaa2e/four_three_640-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015517-3beb496b5e005bdde1bedb748ad0a12eb8dbaa2e/square_640-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015517-3beb496b5e005bdde1bedb748ad0a12eb8dbaa2e/downloadable-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG"
        },
        "created_on": "2019-12-04T01:31:24Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060787",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6659132060",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015516-871293dd0c96136f66a6587fd594abbcbb06e553/original-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
        "name": "DBI-37-MhYQZS23-downloadable-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
        "source_type": "edit",
        "formatted_size": "7.3 MB",
        "size": 7652996,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593497222222,
        "gps_longitude": -93.797836111111,
        "gps_altitude": 290.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015516-871293dd0c96136f66a6587fd594abbcbb06e553/large_1920-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015516-871293dd0c96136f66a6587fd594abbcbb06e553/medium_1024-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015516-871293dd0c96136f66a6587fd594abbcbb06e553/small_640-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015516-871293dd0c96136f66a6587fd594abbcbb06e553/four_three_640-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015516-871293dd0c96136f66a6587fd594abbcbb06e553/square_640-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015516-871293dd0c96136f66a6587fd594abbcbb06e553/downloadable-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG"
        },
        "created_on": "2019-12-04T01:31:23Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060787",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0124388920",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015515-b4ffc64f5fa08dddc393a7247a485e9e985abe03/original-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
        "name": "DBI-36-7kGDTEy8-downloadable-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
        "source_type": "edit",
        "formatted_size": "6.91 MB",
        "size": 7245785,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593527777778,
        "gps_longitude": -93.797575,
        "gps_altitude": 274.2,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015515-b4ffc64f5fa08dddc393a7247a485e9e985abe03/large_1920-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015515-b4ffc64f5fa08dddc393a7247a485e9e985abe03/medium_1024-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015515-b4ffc64f5fa08dddc393a7247a485e9e985abe03/small_640-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015515-b4ffc64f5fa08dddc393a7247a485e9e985abe03/four_three_640-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015515-b4ffc64f5fa08dddc393a7247a485e9e985abe03/square_640-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015515-b4ffc64f5fa08dddc393a7247a485e9e985abe03/downloadable-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG"
        },
        "created_on": "2019-12-04T01:31:22Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060789",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7707493802",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015514-708eb1738aed9eb464638f55872d26ba76844d0f/original-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
        "name": "DBI-35-XUpRlnTM-downloadable-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
        "source_type": "edit",
        "formatted_size": "7.01 MB",
        "size": 7354035,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593836111111,
        "gps_longitude": -93.797358333333,
        "gps_altitude": 268.8,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015514-708eb1738aed9eb464638f55872d26ba76844d0f/large_1920-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015514-708eb1738aed9eb464638f55872d26ba76844d0f/medium_1024-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015514-708eb1738aed9eb464638f55872d26ba76844d0f/small_640-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015514-708eb1738aed9eb464638f55872d26ba76844d0f/four_three_640-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015514-708eb1738aed9eb464638f55872d26ba76844d0f/square_640-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015514-708eb1738aed9eb464638f55872d26ba76844d0f/downloadable-535ff7b4799b54f086ef13417e0bb269b3925519.JPG"
        },
        "created_on": "2019-12-04T01:31:21Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060789",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9544229767",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015513-d5f17e593f84020aeec019d8defe7354ca32c7f2/original-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
        "name": "DBI-34-i4Ok33fX-downloadable-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
        "source_type": "edit",
        "formatted_size": "7.24 MB",
        "size": 7590756,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.594547222222,
        "gps_longitude": -93.797491666667,
        "gps_altitude": 268.9,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015513-d5f17e593f84020aeec019d8defe7354ca32c7f2/large_1920-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015513-d5f17e593f84020aeec019d8defe7354ca32c7f2/medium_1024-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015513-d5f17e593f84020aeec019d8defe7354ca32c7f2/small_640-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015513-d5f17e593f84020aeec019d8defe7354ca32c7f2/four_three_640-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015513-d5f17e593f84020aeec019d8defe7354ca32c7f2/square_640-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015513-d5f17e593f84020aeec019d8defe7354ca32c7f2/downloadable-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG"
        },
        "created_on": "2019-12-04T01:31:20Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060787",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3750641461",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015512-d5f17e593f84020aeec019d8defe7354ca32c7f2/original-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
        "name": "DBI-33-Lq4iG8Ry-downloadable-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
        "source_type": "edit",
        "formatted_size": "6.59 MB",
        "size": 6910822,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.592494444444,
        "gps_longitude": -93.796258333333,
        "gps_altitude": 274,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015512-d5f17e593f84020aeec019d8defe7354ca32c7f2/large_1920-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015512-d5f17e593f84020aeec019d8defe7354ca32c7f2/medium_1024-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015512-d5f17e593f84020aeec019d8defe7354ca32c7f2/small_640-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015512-d5f17e593f84020aeec019d8defe7354ca32c7f2/four_three_640-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015512-d5f17e593f84020aeec019d8defe7354ca32c7f2/square_640-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015512-d5f17e593f84020aeec019d8defe7354ca32c7f2/downloadable-0632da6d3944561fa734289f437dad64c1c8be12.JPG"
        },
        "created_on": "2019-12-04T01:31:20Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060789",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7841076370",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015511-1c201cd5d55b39f9dbf1c063bd30a86606254d79/original-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
        "name": "DBI-32-u2CV2bmf-downloadable-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
        "source_type": "edit",
        "formatted_size": "6.77 MB",
        "size": 7097909,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593319444444,
        "gps_longitude": -93.793633333333,
        "gps_altitude": 338,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015511-1c201cd5d55b39f9dbf1c063bd30a86606254d79/large_1920-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015511-1c201cd5d55b39f9dbf1c063bd30a86606254d79/medium_1024-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015511-1c201cd5d55b39f9dbf1c063bd30a86606254d79/small_640-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015511-1c201cd5d55b39f9dbf1c063bd30a86606254d79/four_three_640-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015511-1c201cd5d55b39f9dbf1c063bd30a86606254d79/square_640-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015511-1c201cd5d55b39f9dbf1c063bd30a86606254d79/downloadable-566c58054eb103640db99ce3c40d401d5e0d9646.JPG"
        },
        "created_on": "2019-12-04T01:31:19Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060788",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2466869493",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015510-4b6f46c87ecdf2a60688f8f30ffaca2dd17b1aea/original-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
        "name": "DBI-31-BOjX20OI-downloadable-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
        "source_type": "edit",
        "formatted_size": "7.09 MB",
        "size": 7429308,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.592686111111,
        "gps_longitude": -93.797641666667,
        "gps_altitude": 290.5,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015510-4b6f46c87ecdf2a60688f8f30ffaca2dd17b1aea/large_1920-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015510-4b6f46c87ecdf2a60688f8f30ffaca2dd17b1aea/medium_1024-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015510-4b6f46c87ecdf2a60688f8f30ffaca2dd17b1aea/small_640-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015510-4b6f46c87ecdf2a60688f8f30ffaca2dd17b1aea/four_three_640-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015510-4b6f46c87ecdf2a60688f8f30ffaca2dd17b1aea/square_640-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015510-4b6f46c87ecdf2a60688f8f30ffaca2dd17b1aea/downloadable-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG"
        },
        "created_on": "2019-12-04T01:31:18Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060787",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2890931169",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015509-8194a9d3175a1412b23bf2d33727ba95da08f837/original-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
        "name": "DBI-30-gT2A6BW9-downloadable-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
        "source_type": "edit",
        "formatted_size": "7.2 MB",
        "size": 7546071,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.595555555556,
        "gps_longitude": -93.793655555556,
        "gps_altitude": 337.9,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015509-8194a9d3175a1412b23bf2d33727ba95da08f837/large_1920-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015509-8194a9d3175a1412b23bf2d33727ba95da08f837/medium_1024-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015509-8194a9d3175a1412b23bf2d33727ba95da08f837/small_640-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015509-8194a9d3175a1412b23bf2d33727ba95da08f837/four_three_640-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015509-8194a9d3175a1412b23bf2d33727ba95da08f837/square_640-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015509-8194a9d3175a1412b23bf2d33727ba95da08f837/downloadable-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG"
        },
        "created_on": "2019-12-04T01:31:17Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060788",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9442703147",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015508-25c7c48e3c2ccc4304a89080c413f8923a9ff117/original-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
        "name": "DBI-29-BM194O9v-downloadable-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
        "source_type": "edit",
        "formatted_size": "7.37 MB",
        "size": 7725460,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.592311111111,
        "gps_longitude": -93.798858333333,
        "gps_altitude": 335.3,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015508-25c7c48e3c2ccc4304a89080c413f8923a9ff117/large_1920-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015508-25c7c48e3c2ccc4304a89080c413f8923a9ff117/medium_1024-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015508-25c7c48e3c2ccc4304a89080c413f8923a9ff117/small_640-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015508-25c7c48e3c2ccc4304a89080c413f8923a9ff117/four_three_640-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015508-25c7c48e3c2ccc4304a89080c413f8923a9ff117/square_640-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015508-25c7c48e3c2ccc4304a89080c413f8923a9ff117/downloadable-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG"
        },
        "created_on": "2019-12-04T01:31:16Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060788",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4080184116",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015507-2e451e84211211b40626550f116b94f9f0f3ddf3/original-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
        "name": "DBI-28-AnqfHPhJ-downloadable-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
        "source_type": "edit",
        "formatted_size": "6.77 MB",
        "size": 7094349,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593408333333,
        "gps_longitude": -93.798991666667,
        "gps_altitude": 336,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015507-2e451e84211211b40626550f116b94f9f0f3ddf3/large_1920-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015507-2e451e84211211b40626550f116b94f9f0f3ddf3/medium_1024-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015507-2e451e84211211b40626550f116b94f9f0f3ddf3/small_640-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015507-2e451e84211211b40626550f116b94f9f0f3ddf3/four_three_640-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015507-2e451e84211211b40626550f116b94f9f0f3ddf3/square_640-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015507-2e451e84211211b40626550f116b94f9f0f3ddf3/downloadable-0682793e7996851c1da4c81b5963f908c5c2a539.JPG"
        },
        "created_on": "2019-12-04T01:31:15Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060788",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI5596724201",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015506-a7f8bb9d4bd631c7023684e50f0726309bcc6063/original-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
        "name": "DBI-27-Uy8s1nd2-downloadable-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
        "source_type": "edit",
        "formatted_size": "7.22 MB",
        "size": 7575328,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.596194444444,
        "gps_longitude": -93.796425,
        "gps_altitude": 338.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015506-a7f8bb9d4bd631c7023684e50f0726309bcc6063/large_1920-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015506-a7f8bb9d4bd631c7023684e50f0726309bcc6063/medium_1024-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015506-a7f8bb9d4bd631c7023684e50f0726309bcc6063/small_640-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015506-a7f8bb9d4bd631c7023684e50f0726309bcc6063/four_three_640-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015506-a7f8bb9d4bd631c7023684e50f0726309bcc6063/square_640-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015506-a7f8bb9d4bd631c7023684e50f0726309bcc6063/downloadable-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG"
        },
        "created_on": "2019-12-04T01:31:14Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060788",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6577104448",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015505-cf6c8dc917698adc7547561bff3ef0535d4c6003/original-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
        "name": "DBI-26-eznvSVXq-downloadable-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
        "source_type": "edit",
        "formatted_size": "6.94 MB",
        "size": 7272541,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.595638888889,
        "gps_longitude": -93.793852777778,
        "gps_altitude": 337.9,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015505-cf6c8dc917698adc7547561bff3ef0535d4c6003/large_1920-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015505-cf6c8dc917698adc7547561bff3ef0535d4c6003/medium_1024-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015505-cf6c8dc917698adc7547561bff3ef0535d4c6003/small_640-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015505-cf6c8dc917698adc7547561bff3ef0535d4c6003/four_three_640-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015505-cf6c8dc917698adc7547561bff3ef0535d4c6003/square_640-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015505-cf6c8dc917698adc7547561bff3ef0535d4c6003/downloadable-d84445703a5ee69913dea10b9cf11a92ff094870.JPG"
        },
        "created_on": "2019-12-04T01:31:13Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060788",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3808108928",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015504-cf6c8dc917698adc7547561bff3ef0535d4c6003/original-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
        "name": "DBI-25-GFYGEwIH-downloadable-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
        "source_type": "edit",
        "formatted_size": "6.73 MB",
        "size": 7060582,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593022222222,
        "gps_longitude": -93.798544444444,
        "gps_altitude": 338,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015504-cf6c8dc917698adc7547561bff3ef0535d4c6003/large_1920-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015504-cf6c8dc917698adc7547561bff3ef0535d4c6003/medium_1024-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015504-cf6c8dc917698adc7547561bff3ef0535d4c6003/small_640-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015504-cf6c8dc917698adc7547561bff3ef0535d4c6003/four_three_640-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015504-cf6c8dc917698adc7547561bff3ef0535d4c6003/square_640-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015504-cf6c8dc917698adc7547561bff3ef0535d4c6003/downloadable-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG"
        },
        "created_on": "2019-12-04T01:31:13Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060787",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9847294316",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015502-8aed9b700afbf74cbc2273a86fc39c0c83bce306/original-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
        "name": "DBI-23-DGog6O5t-downloadable-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
        "source_type": "edit",
        "formatted_size": "6.86 MB",
        "size": 7190165,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.595311111111,
        "gps_longitude": -93.798283333333,
        "gps_altitude": 356.8,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015502-8aed9b700afbf74cbc2273a86fc39c0c83bce306/large_1920-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015502-8aed9b700afbf74cbc2273a86fc39c0c83bce306/medium_1024-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015502-8aed9b700afbf74cbc2273a86fc39c0c83bce306/small_640-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015502-8aed9b700afbf74cbc2273a86fc39c0c83bce306/four_three_640-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015502-8aed9b700afbf74cbc2273a86fc39c0c83bce306/square_640-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015502-8aed9b700afbf74cbc2273a86fc39c0c83bce306/downloadable-2f061394182882b945975bdb8fa14d5f936112ca.JPG"
        },
        "created_on": "2019-12-04T01:31:11Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060788",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3788815565",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015500-abbb841df8008f22338be83ea7d37681fd534a2d/original-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
        "name": "DBI-21-vsTYs4t6-downloadable-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
        "source_type": "edit",
        "formatted_size": "6.99 MB",
        "size": 7328091,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593958333333,
        "gps_longitude": -93.797041666667,
        "gps_altitude": 254.3,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015500-abbb841df8008f22338be83ea7d37681fd534a2d/large_1920-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015500-abbb841df8008f22338be83ea7d37681fd534a2d/medium_1024-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015500-abbb841df8008f22338be83ea7d37681fd534a2d/small_640-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015500-abbb841df8008f22338be83ea7d37681fd534a2d/four_three_640-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015500-abbb841df8008f22338be83ea7d37681fd534a2d/square_640-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015500-abbb841df8008f22338be83ea7d37681fd534a2d/downloadable-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG"
        },
        "created_on": "2019-12-04T01:31:09Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060789",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV7578211739",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-20-o7pD6PO1-DL-DBUV011-Trucking-Left.MOV",
        "source_type": "raw",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174427-d54295ffbf973c9ff755a419ec5b02dd3e4a065b/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174427-d54295ffbf973c9ff755a419ec5b02dd3e4a065b/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174427-d54295ffbf973c9ff755a419ec5b02dd3e4a065b/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174427-d54295ffbf973c9ff755a419ec5b02dd3e4a065b/DL-DBV-20-o7pD6PO1-DL-DBUV011-Trucking-Left.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060783",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV1107089390",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-19-phcTaDFn-DL-DBUV015-New-Dolly_1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174426-1365b2cf00bf3c5985fb9d8d94ac0dd9532eb9cc/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174426-1365b2cf00bf3c5985fb9d8d94ac0dd9532eb9cc/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174426-1365b2cf00bf3c5985fb9d8d94ac0dd9532eb9cc/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174426-1365b2cf00bf3c5985fb9d8d94ac0dd9532eb9cc/DL-DBV-19-phcTaDFn-DL-DBUV015-New-Dolly_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060780",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV1146774300",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-18-7WQmQGRA-DL-DBUV013-New-Dolly-1_1.MOV",
        "source_type": "raw",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174425-64a98acd04d7be614ff290f576cafb2fbffb8c9c/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174425-64a98acd04d7be614ff290f576cafb2fbffb8c9c/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174425-64a98acd04d7be614ff290f576cafb2fbffb8c9c/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174425-64a98acd04d7be614ff290f576cafb2fbffb8c9c/DL-DBV-18-7WQmQGRA-DL-DBUV013-New-Dolly-1_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060780",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV6366056555",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-17-d5xCOc7J-DL-DBUV014-New-Dolly-2_1.MOV",
        "source_type": "raw",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174424-6c2ad6d775086da549a684ea36fa97745ea0d30f/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174424-6c2ad6d775086da549a684ea36fa97745ea0d30f/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174424-6c2ad6d775086da549a684ea36fa97745ea0d30f/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174424-6c2ad6d775086da549a684ea36fa97745ea0d30f/DL-DBV-17-d5xCOc7J-DL-DBUV014-New-Dolly-2_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060780",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV0676400554",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-16-luMan2AL-DL-DBUV006-Dolly.MOV",
        "source_type": "raw",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174423-35a2697511f50a04e7af27ce6524bf63551ff5e8/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174423-35a2697511f50a04e7af27ce6524bf63551ff5e8/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174423-35a2697511f50a04e7af27ce6524bf63551ff5e8/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174423-35a2697511f50a04e7af27ce6524bf63551ff5e8/DL-DBV-16-luMan2AL-DL-DBUV006-Dolly.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060780",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV5665195607",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-15-0l4kQUVg-DL-DBUV004-Dolly-2_1.MOV",
        "source_type": "raw",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174422-4f0a2957c8334f2244c442bde73d7a63879767f7/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174422-4f0a2957c8334f2244c442bde73d7a63879767f7/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174422-4f0a2957c8334f2244c442bde73d7a63879767f7/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174422-4f0a2957c8334f2244c442bde73d7a63879767f7/DL-DBV-15-0l4kQUVg-DL-DBUV004-Dolly-2_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060780",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV6900257772",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-14-A4snVswW-DL-DBUV011-Trucking-Left_1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174421-380a466c81e55b6cd9c813dc59215c3579fa6f85/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174421-380a466c81e55b6cd9c813dc59215c3579fa6f85/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174421-380a466c81e55b6cd9c813dc59215c3579fa6f85/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174421-380a466c81e55b6cd9c813dc59215c3579fa6f85/DL-DBV-14-A4snVswW-DL-DBUV011-Trucking-Left_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060783",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV6049248834",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-13-TQ79w70n-DL-DBUV021-New-Trucking-Right_1.MOV",
        "source_type": "raw",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174420-5ce8c901874832dae67e545653471327700d2fac/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174420-5ce8c901874832dae67e545653471327700d2fac/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174420-5ce8c901874832dae67e545653471327700d2fac/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174420-5ce8c901874832dae67e545653471327700d2fac/DL-DBV-13-TQ79w70n-DL-DBUV021-New-Trucking-Right_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060783",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV1558614244",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-12-8lUHezVQ-DL-DBUV020-New-Trucking-Left_1.MOV",
        "source_type": "raw",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174419-019d805cf9beb6ab543005fc6ee376c9f012ba5d/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174419-019d805cf9beb6ab543005fc6ee376c9f012ba5d/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174419-019d805cf9beb6ab543005fc6ee376c9f012ba5d/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174419-019d805cf9beb6ab543005fc6ee376c9f012ba5d/DL-DBV-12-8lUHezVQ-DL-DBUV020-New-Trucking-Left_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060783",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV7500845184",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-11-4nXC102l-DL-DBUV008-Orbit-2_1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174418-8c7ee805c071ab5634a2cc002a3d722f91c59110/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174418-8c7ee805c071ab5634a2cc002a3d722f91c59110/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174418-8c7ee805c071ab5634a2cc002a3d722f91c59110/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174418-8c7ee805c071ab5634a2cc002a3d722f91c59110/DL-DBV-11-4nXC102l-DL-DBUV008-Orbit-2_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060782",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV1767531265",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-10-m0fdUPNK-DL-DBUV017-New-Orbit-2_1.MOV",
        "source_type": "raw",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174417-c20a5a8d282011a80a61241c8357c66048f253b7/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174417-c20a5a8d282011a80a61241c8357c66048f253b7/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174417-c20a5a8d282011a80a61241c8357c66048f253b7/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174417-c20a5a8d282011a80a61241c8357c66048f253b7/DL-DBV-10-m0fdUPNK-DL-DBUV017-New-Orbit-2_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060782",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV6499691486",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-9-x3lpsXwF-DL-DBUV016-New-Orbit-1_1.MOV",
        "source_type": "raw",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174416-021a0b126c45d764b5143829b03ee11af5305b30/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174416-021a0b126c45d764b5143829b03ee11af5305b30/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174416-021a0b126c45d764b5143829b03ee11af5305b30/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174416-021a0b126c45d764b5143829b03ee11af5305b30/DL-DBV-9-x3lpsXwF-DL-DBUV016-New-Orbit-1_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060782",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV9795722273",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-8-2T2VMbh3-DL-DBUV007-Orbit-1_1.MOV",
        "source_type": "raw",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174415-0d8f0cf9ce62549b370f10580cde6ca5f70659ba/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174415-0d8f0cf9ce62549b370f10580cde6ca5f70659ba/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174415-0d8f0cf9ce62549b370f10580cde6ca5f70659ba/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174415-0d8f0cf9ce62549b370f10580cde6ca5f70659ba/DL-DBV-8-2T2VMbh3-DL-DBUV007-Orbit-1_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060782",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV7885416812",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-7-TnXGUf7l-DL-DBUV018-New-Orbit-3_1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174414-1f3a6bd9212ebca6a282ce91e3fcffc4cf5d77b0/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174414-1f3a6bd9212ebca6a282ce91e3fcffc4cf5d77b0/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174414-1f3a6bd9212ebca6a282ce91e3fcffc4cf5d77b0/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174414-1f3a6bd9212ebca6a282ce91e3fcffc4cf5d77b0/DL-DBV-7-TnXGUf7l-DL-DBUV018-New-Orbit-3_1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060782",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV9833327584",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-6-Pibkw1uF-DL-DBUV012-Trucking-Right.MOV",
        "source_type": "raw",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174413-1aee9f347c3656a4ac015c9d0bbe406beaf0980a/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174413-1aee9f347c3656a4ac015c9d0bbe406beaf0980a/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174413-1aee9f347c3656a4ac015c9d0bbe406beaf0980a/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174413-1aee9f347c3656a4ac015c9d0bbe406beaf0980a/DL-DBV-6-Pibkw1uF-DL-DBUV012-Trucking-Right.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060781",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV2958901198",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-5-PAxVWbtG-DL-DBUV000-Birdseye.MOV",
        "source_type": "raw",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174412-af7b7f1d0d30242da5b5c624c1db7f0dd648ae33/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174412-af7b7f1d0d30242da5b5c624c1db7f0dd648ae33/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174412-af7b7f1d0d30242da5b5c624c1db7f0dd648ae33/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174412-af7b7f1d0d30242da5b5c624c1db7f0dd648ae33/DL-DBV-5-PAxVWbtG-DL-DBUV000-Birdseye.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060784",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV4246165317",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-4-4dQvqcS6-DL-DBUV001-Crane.MOV",
        "source_type": "raw",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174411-78aa1260a7540189df55ddaf65cb3430e7edc49e/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174411-78aa1260a7540189df55ddaf65cb3430e7edc49e/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174411-78aa1260a7540189df55ddaf65cb3430e7edc49e/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174411-78aa1260a7540189df55ddaf65cb3430e7edc49e/DL-DBV-4-4dQvqcS6-DL-DBUV001-Crane.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060786",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV0655570721",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-3-0CV1mWZR-DL-DBUV003-Creative.MOV",
        "source_type": "raw",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174410-b17012ef0198012c92fd0f99f7729b09ffeb664c/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174410-b17012ef0198012c92fd0f99f7729b09ffeb664c/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174410-b17012ef0198012c92fd0f99f7729b09ffeb664c/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174410-b17012ef0198012c92fd0f99f7729b09ffeb664c/DL-DBV-3-0CV1mWZR-DL-DBUV003-Creative.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060786",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV2752408662",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-2-JuKkT7KA-DL-DBUV019-New-Rise-Up.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174409-ae81def8562731f17d549d791f2a62fda6756e34/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174409-ae81def8562731f17d549d791f2a62fda6756e34/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174409-ae81def8562731f17d549d791f2a62fda6756e34/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174409-ae81def8562731f17d549d791f2a62fda6756e34/DL-DBV-2-JuKkT7KA-DL-DBUV019-New-Rise-Up.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060785",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV7634751337",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-1-VL3Z1xgg-DL-DBUV002-Creative-2.MOV",
        "source_type": "raw",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174408-6f3646fbdb9f9b25df34c3da76643223b56e8cd1/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174408-6f3646fbdb9f9b25df34c3da76643223b56e8cd1/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174408-6f3646fbdb9f9b25df34c3da76643223b56e8cd1/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174408-6f3646fbdb9f9b25df34c3da76643223b56e8cd1/DL-DBV-1-VL3Z1xgg-DL-DBUV002-Creative-2.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060786",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "98c97bf0dc62f35e08cf643111be9438",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-13T08:25:57Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "c5347c4785542fcb22e930ac98c40063",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-05T06:58:00Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "1060790",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278127",
        "shot_type_id": 22,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Final Assets"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "22",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "22",
      "type": "shot_types",
      "attributes": {
        "name": "Final Assets",
        "description": "Use this shot to upload final edited assets for the customer. Not for use by pilots.\n",
        "video": null,
        "slug": "final-assets",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060789",
      "type": "shots",
      "attributes": {
        "instructions": "(3) images of the front of building (straight on, angled left, angled right) from just below roof level with the *full building in frame.* Images with little to no roof in frame are a big point of emphasis our customers. If you need to adjust, back away from the property rather than increasing altitude *so that we don\u2019t see the top of the roof.*",
        "mission_id": "278127",
        "shot_type_id": 17,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Image - Front Close Up (full building in frame)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "17",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI0124388920",
            "type": "images"
          }, {
            "id": "DBI7707493802",
            "type": "images"
          }, {
            "id": "DBI3750641461",
            "type": "images"
          }, {
            "id": "DBI3788815565",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "17",
      "type": "shot_types",
      "attributes": {
        "name": "Image - Front Close Up (full building in frame)",
        "description": "* *If there are power lines do your best to avoid having them in frame. It is okay to fly higher or lower than roof level to avoid the power lines in the images.* ",
        "video": null,
        "slug": "front_shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "14",
            "type": "shot_type_sample_images"
          }, {
            "id": "15",
            "type": "shot_type_sample_images"
          }, {
            "id": "16",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1060788",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "278127",
        "shot_type_id": 24,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Images - Cardinal Directions (350 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "24",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI9847294316",
            "type": "images"
          }, {
            "id": "DBI7841076370",
            "type": "images"
          }, {
            "id": "DBI2890931169",
            "type": "images"
          }, {
            "id": "DBI4080184116",
            "type": "images"
          }, {
            "id": "DBI9442703147",
            "type": "images"
          }, {
            "id": "DBI5596724201",
            "type": "images"
          }, {
            "id": "DBI6577104448",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "24",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (350 ft above ground level)",
        "description": "*If there are altitude restrictions in this airspace, please take these images at the highest allowable altitude instead of 350'. *",
        "video": null,
        "slug": "images-cardinal-directions-350-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "23",
            "type": "shot_type_sample_images"
          }, {
            "id": "24",
            "type": "shot_type_sample_images"
          }, {
            "id": "25",
            "type": "shot_type_sample_images"
          }, {
            "id": "26",
            "type": "shot_type_sample_images"
          }, {
            "id": "27",
            "type": "shot_type_sample_images"
          }, {
            "id": "28",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1060787",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "278127",
        "shot_type_id": 13,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Images - Cardinal Directions (100 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "13",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI9544229767",
            "type": "images"
          }, {
            "id": "DBI5556284910",
            "type": "images"
          }, {
            "id": "DBI0198292139",
            "type": "images"
          }, {
            "id": "DBI2466869493",
            "type": "images"
          }, {
            "id": "DBI6659132060",
            "type": "images"
          }, {
            "id": "DBI3808108928",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "13",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (100 ft above ground level)",
        "description": "",
        "video": "",
        "slug": "images-cardinal-directions-100-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "17",
            "type": "shot_type_sample_images"
          }, {
            "id": "18",
            "type": "shot_type_sample_images"
          }, {
            "id": "19",
            "type": "shot_type_sample_images"
          }, {
            "id": "20",
            "type": "shot_type_sample_images"
          }, {
            "id": "21",
            "type": "shot_type_sample_images"
          }, {
            "id": "22",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1060786",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: focus on some point of interest or sign\n",
        "mission_id": "278127",
        "shot_type_id": 54,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Creative Video Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "54",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV4246165317",
            "type": "videos"
          }, {
            "id": "DBV0655570721",
            "type": "videos"
          }, {
            "id": "DBV7634751337",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "54",
      "type": "shot_types",
      "attributes": {
        "name": "Creative Video Shot",
        "description": null,
        "video": null,
        "slug": "getty-video-getty-video",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060785",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: Rise + Reverse Away facing the front of the subject property.  \n",
        "mission_id": "278127",
        "shot_type_id": 10,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Rise + Reverse Away Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "10",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV2752408662",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "10",
      "type": "shot_types",
      "attributes": {
        "name": "Rise + Reverse Away Shot",
        "description": "",
        "video": "https://youtu.be/PwOvzZqQWkg",
        "slug": "rise-reverse-away-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060784",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: Looking straight down at the center of the property, rise up vertically to maximum altitude. Go slow and steady. This will reveal property and property boundaries. If the property it too large to be revealed just show as much as you can from maximum altitude.  \n",
        "mission_id": "278127",
        "shot_type_id": 9,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Birds Eye Shots"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "9",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV2958901198",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "9",
      "type": "shot_types",
      "attributes": {
        "name": "Birds Eye Shots",
        "description": "",
        "video": "",
        "slug": "birds-eye-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060783",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Truck (*left to right*) across the front of the property. The front of the property should take up the frame from ground to top of roof. Adjust distance accordingly.  \n",
        "mission_id": "278127",
        "shot_type_id": 2,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Trucking Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "2",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV7578211739",
            "type": "videos"
          }, {
            "id": "DBV6900257772",
            "type": "videos"
          }, {
            "id": "DBV1558614244",
            "type": "videos"
          }, {
            "id": "DBV6049248834",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "2",
      "type": "shot_types",
      "attributes": {
        "name": "Trucking Shot",
        "description": "",
        "video": "https://youtu.be/m-r79WAcDNs",
        "slug": "trucking-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060782",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Large + high altitude Contextual Orbit shot around property (counterclockwise)  \n*Must have entire subject property in frame and your camera must be angled up enough so that we also have the horizon in frame.* The point is to provide context of the property in relation to the surrounding area. This will require you to fly at a higher altitude and farther off the property to get the required shot. Typically shot around 300 ft. Orbit should be slow + steady. No jerky camera movements.  \n",
        "mission_id": "278127",
        "shot_type_id": 8,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Orbit Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "8",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV1767531265",
            "type": "videos"
          }, {
            "id": "DBV9795722273",
            "type": "videos"
          }, {
            "id": "DBV6499691486",
            "type": "videos"
          }, {
            "id": "DBV7885416812",
            "type": "videos"
          }, {
            "id": "DBV7500845184",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "8",
      "type": "shot_types",
      "attributes": {
        "name": "Orbit Shot",
        "description": "",
        "video": "https://youtu.be/MzdF92gWXyU",
        "slug": "orbit-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060781",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Truck (*right to left*) across the front of the property. The front of the property should take up the frame from ground to top of roof. Adjust the distance accordingly.  \n",
        "mission_id": "278127",
        "shot_type_id": 2,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Trucking Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "2",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV9833327584",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "1060780",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Slowly fly towards the front of the property at a constant altitude (10 feet over roof level) with your camera pointing straight (can have slight angle downward if needed). Keep the horizon flat. Fly completely over the property and the end of the property lot.  \n",
        "mission_id": "278127",
        "shot_type_id": 1,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Dolly Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "1",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV1107089390",
            "type": "videos"
          }, {
            "id": "DBV1146774300",
            "type": "videos"
          }, {
            "id": "DBV6366056555",
            "type": "videos"
          }, {
            "id": "DBV0676400554",
            "type": "videos"
          }, {
            "id": "DBV5665195607",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "shot_types",
      "attributes": {
        "name": "Dolly Shot",
        "description": "",
        "video": "https://youtu.be/H80sEIUHa7Y",
        "slug": "dolly-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "DBINV338990458617592",
      "type": "invoice_line_items",
      "attributes": {
        "reason": "base_package_price",
        "description": null,
        "amount": 36900,
        "created_on": "2019-12-04T01:14:45Z",
        "display_name": "Base Package Price"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2797682589",
            "type": "missions"
          }
        },
        "work_item": {
          "data": null
        }
      }
    }]
  };
  _exports.default = _default;
});