define("clients/components/validated-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ValidatedInputComponent;
  ValidatedInputComponent = Ember.Component.extend({
    showError: Ember.computed('showErrorsOnBlur', function () {
      return !this.get('showErrorsOnBlur');
    }),
    actions: {
      validateProperty: function (changeset, property) {
        changeset.validate(property);

        if (this.get('showErrorsOnBlur')) {
          if (changeset.get('error.' + property)) {
            return this.set('showError', true);
          } else {
            return this.set('showError', false);
          }
        }
      }
    }
  });
  var _default = ValidatedInputComponent;
  _exports.default = _default;
});