define("clients/templates/missions/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sDizmeHE",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"model\",\"goToUploadAction\",\"newClient\"],[[35,1],[30,[36,0],[[32,0],\"goToUpload\"],null],true]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"order-flow/multi-step-order-flow\"]}",
    "meta": {
      "moduleName": "clients/templates/missions/new.hbs"
    }
  });

  _exports.default = _default;
});