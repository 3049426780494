define("clients/components/mission-list-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionListGroupComponent;
  MissionListGroupComponent = Ember.Component.extend({
    expanded: false,
    filteredMissions: Ember.computed('location', 'missions.[]', function () {
      var sortAttribute;
      sortAttribute = '';

      if (this.get('current_date_column') === 'order_placed') {
        sortAttribute = 'created_on';
      } else {
        sortAttribute = 'scheduled_at_start';
      }

      return this.get('missions').filter(function (_this) {
        return function (mission) {
          return mission.get('location.formatted_address') === _this.get('location.formatted_address');
        };
      }(this));
    }),
    missionsHead: Ember.computed('filteredMissions', function () {
      return this.get('filteredMissions').get('firstObject');
    }),
    missionsTail: Ember.computed('filteredMissions', function () {
      return this.get('filteredMissions').slice(1, this.get('filteredMissions.length'));
    }),
    hasBundle: Ember.computed('filteredMissions.[]', function () {
      return this.get('filteredMissions').length > 1;
    }),
    actions: {
      toggleShowAll: function () {
        this.set('showAll', !this.get('showAll'));
        return '';
      },
      showMission: function (mission) {
        return this.action(mission);
      },
      pay: function (mission) {
        return this.payAction(mission);
      },
      toggleMission: function (mission_id, checked) {
        return this.toggleMissionAction(mission_id, checked);
      },
      unselectAll: function () {
        return this.unselectAllAction();
      },
      openContextMenu: function (mission, event) {
        return this.openContextMenuAction(mission, event);
      }
    }
  });
  var _default = MissionListGroupComponent;
  _exports.default = _default;
});