define("clients/models/shot-list", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    shot_type_ids: (0, _model.attr)('array'),
    shot_types: (0, _model.hasMany)('shot-type', {
      async: false
    })
  });

  _exports.default = _default;
});