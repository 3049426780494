define("clients/models/mission-group", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('String'),
    missions: (0, _model.hasMany)('mission', {
      async: true
    }),
    missionsCount: (0, _model.attr)('Number'),
    totalMissionsCount: (0, _model.attr)('Number'),
    mission_ids: (0, _model.attr)('array')
  });

  _exports.default = _default;
});