define("clients/data/guest-sample-missions", ["exports", "clients/data/sample_missions/introductory_sample_mission", "clients/data/sample_missions/rre_images_package", "clients/data/sample_missions/rre_starter_package", "clients/data/sample_missions/rre_pro_package", "clients/data/sample_missions/rre_pano_package", "clients/data/sample_missions/cre_pano_package", "clients/data/sample_missions/cre_shopping_center", "clients/data/sample_missions/cre_regional_center", "clients/data/sample_missions/cre_images_package", "clients/data/sample_missions/cre_images_shopping", "clients/data/sample_missions/cre_images_regional", "clients/data/sample_missions/cre_starter_package", "clients/data/sample_missions/cre_starter_shopping", "clients/data/sample_missions/cre_starter_regional", "clients/data/sample_missions/cre_pro_package", "clients/data/sample_missions/cre_pro_shopping", "clients/data/sample_missions/cre_pro_regional"], function (_exports, _introductory_sample_mission, _rre_images_package, _rre_starter_package, _rre_pro_package, _rre_pano_package, _cre_pano_package, _cre_shopping_center, _cre_regional_center, _cre_images_package, _cre_images_shopping, _cre_images_regional, _cre_starter_package, _cre_starter_shopping, _cre_starter_regional, _cre_pro_package, _cre_pro_shopping, _cre_pro_regional) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cre-sample-1": _introductory_sample_mission.default,
    "rre-images-package": _rre_images_package.default,
    "rre-starter-package": _rre_starter_package.default,
    "rre-pro-package": _rre_pro_package.default,
    "rre-pano-package": _rre_pano_package.default,
    "cre-pano-package": _cre_pano_package.default,
    "cre-shopping-center": _cre_shopping_center.default,
    "cre-regional-center": _cre_regional_center.default,
    "cre-images-package": _cre_images_package.default,
    "cre-images-shopping": _cre_images_shopping.default,
    "cre-images-regional": _cre_images_regional.default,
    "cre-starter-package": _cre_starter_package.default,
    "cre-starter-shopping": _cre_starter_shopping.default,
    "cre-starter-regional": _cre_starter_regional.default,
    "cre-pro-package": _cre_pro_package.default,
    "cre-pro-shopping": _cre_pro_shopping.default,
    "cre-pro-regional": _cre_pro_regional.default
  };
  _exports.default = _default;
});