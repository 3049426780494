define("clients/helpers/is-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isMobileHelper;
  isMobileHelper = Ember.Helper.helper(function () {
    var android, ios, userAgent;
    userAgent = navigator.userAgent || navigator.vendor || window.opera;
    android = /android/i.test(userAgent);
    userAgent = navigator.userAgent || navigator.vendor || window.opera;
    ios = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    return android || ios;
  });
  var _default = isMobileHelper;
  _exports.default = _default;
});