define("clients/components/mission-view/mission-assets-list-view", ["exports", "clients/components/mission-view/mission-assets-base-view"], function (_exports, _missionAssetsBaseView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionAssetsListViewComponent;
  MissionAssetsListViewComponent = _missionAssetsBaseView.default.extend({
    didInsertElement: function () {
      return $('#list-gallery').lightGallery({
        mode: 'lg-slide',
        videojs: true,
        autoplayFirstVideo: false,
        download: true,
        selector: '.clickable-area.gallery'
      });
    },
    actions: {
      toggleShowHiddenAssets: function (shot, field) {
        return shot.set(field, !shot.get(field));
      },
      toggleOrthoModal: function (ortho) {
        return this.sendAction('orthoModalToggle', ortho);
      }
    }
  });
  var _default = MissionAssetsListViewComponent;
  _exports.default = _default;
});