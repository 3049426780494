define("clients/helpers/and", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AndHelper;
  AndHelper = Ember.Helper.helper(function (arg) {
    var leftSide, rightSide;
    leftSide = arg[0], rightSide = arg[1];
    return leftSide && rightSide;
  });
  var _default = AndHelper;
  _exports.default = _default;
});