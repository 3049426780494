define("clients/helpers/is-greater", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let isGreaterHelper = Ember.Helper.helper(function (_ref) {
    let [leftSide, rightSide] = _ref;
    return leftSide > rightSide;
  });
  var _default = isGreaterHelper;
  _exports.default = _default;
});