define("clients/data/sample_missions/cre_starter_shopping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": {
      "id": "DBM4316427392",
      "type": "missions",
      "attributes": {
        "instructions": "* Scope: Aerial Images and Videos\n* Time: Between 11am - 2pm\n* Schedule: Within 48-72 hours; Weather permitting",
        "status": "complete",
        "client_id": "DBC3006554",
        "payment": {
          "id": null,
          "processor": null,
          "card": "Visa - 3969"
        },
        "external_assets_url": null,
        "owned_by_current_client": true,
        "contact_email": "clients@zeitview.com",
        "created_on": "2019-12-04T02:02:04Z",
        "is_pano_mission": false,
        "logo_url": null,
        "blurb": null,
        "paid": true,
        "paid_on": "2019-12-04T02:38:25Z",
        "price": 41900,
        "archive_url": null,
        "edit_url": null,
        "customer_instructions": "Weekend only. Fly between 5 - 6 pm",
        "scheduled_at_start": null,
        "scheduled_at_end": null,
        "parent_id": null,
        "reshoot_mission_id": null,
        "rejection_reason": null,
        "rejection_notes": null,
        "reference_id": "CRE Starter Package - Shopping Centers Sample",
        "cloud_reqs": "less_than_50",
        "scheduling_requirement": "not_needed",
        "arcgis_feature_layer": null,
        "geo_properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-84.686003282942, 34.037611377646], [-84.683160956206, 34.037113498778], [-84.683160956206, 34.038246932449], [-84.685783341803, 34.039055973314], [-84.686003282942, 34.037611377646]]]
            },
            "properties": {
              "name": "Polygon #1",
              "notes": "",
              "number": 1,
              "area": "36905.3",
              "bounds": {
                "south": 34.037113498778,
                "west": -84.686003282942,
                "north": 34.039055973314,
                "east": -84.683160956206
              },
              "center": {
                "lat": 34.038084736046,
                "lng": -84.684582119574
              }
            },
            "id": 1
          }]
        }
      },
      "relationships": {
        "location": {
          "data": {
            "id": "284946",
            "type": "locations"
          }
        },
        "package": {
          "data": {
            "id": "19163",
            "type": "packages"
          }
        },
        "credit_card": {
          "data": {
            "id": "4898",
            "type": "credit_cards"
          }
        },
        "onsite_contact": {
          "data": {
            "id": "49557",
            "type": "onsite_contacts"
          }
        },
        "scheduling_contact": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "images": {
          "data": [{
            "id": "DBI0961388337",
            "type": "images"
          }, {
            "id": "DBI4605215017",
            "type": "images"
          }, {
            "id": "DBI3314317848",
            "type": "images"
          }, {
            "id": "DBI6461351850",
            "type": "images"
          }, {
            "id": "DBI8547453569",
            "type": "images"
          }, {
            "id": "DBI8927383984",
            "type": "images"
          }, {
            "id": "DBI9616606698",
            "type": "images"
          }, {
            "id": "DBI0000867195",
            "type": "images"
          }, {
            "id": "DBI8347933228",
            "type": "images"
          }, {
            "id": "DBI4351558565",
            "type": "images"
          }, {
            "id": "DBI2854602987",
            "type": "images"
          }, {
            "id": "DBI3471752043",
            "type": "images"
          }, {
            "id": "DBI6976490758",
            "type": "images"
          }, {
            "id": "DBI4430421258",
            "type": "images"
          }, {
            "id": "DBI1035800300",
            "type": "images"
          }, {
            "id": "DBI6787202082",
            "type": "images"
          }, {
            "id": "DBI7935423026",
            "type": "images"
          }, {
            "id": "DBI3258187517",
            "type": "images"
          }, {
            "id": "DBI6107705341",
            "type": "images"
          }]
        },
        "videos": {
          "data": [{
            "id": "DBV8411397514",
            "type": "videos"
          }, {
            "id": "DBV6067672905",
            "type": "videos"
          }, {
            "id": "DBV8287810577",
            "type": "videos"
          }, {
            "id": "DBV0759352987",
            "type": "videos"
          }, {
            "id": "DBV2828768194",
            "type": "videos"
          }, {
            "id": "DBV8667643715",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        },
        "attachments": {
          "data": []
        },
        "ortho_photo_maps": {
          "data": []
        },
        "shares": {
          "data": [{
            "id": "df9ac65f0a4e577e4ddc35130f64a649",
            "type": "shares"
          }]
        },
        "main_image": {
          "data": {
            "id": "DBI0961388337",
            "type": "images"
          }
        },
        "collaborators": {
          "data": []
        },
        "parent": {
          "data": null
        },
        "shots": {
          "data": [{
            "id": "1060824",
            "type": "shots"
          }, {
            "id": "1060823",
            "type": "shots"
          }, {
            "id": "1060822",
            "type": "shots"
          }, {
            "id": "1060821",
            "type": "shots"
          }, {
            "id": "1060820",
            "type": "shots"
          }, {
            "id": "1060819",
            "type": "shots"
          }, {
            "id": "1060818",
            "type": "shots"
          }, {
            "id": "1060817",
            "type": "shots"
          }, {
            "id": "1060816",
            "type": "shots"
          }, {
            "id": "1060815",
            "type": "shots"
          }, {
            "id": "1060814",
            "type": "shots"
          }]
        },
        "invoice_line_items": {
          "data": [{
            "id": "DBINV572420989532449",
            "type": "invoice_line_items"
          }]
        }
      }
    },
    "included": [{
      "id": "284946",
      "type": "locations",
      "attributes": {
        "name": "3384 Cobb Pkwy NW, Acworth, GA 30101, USA",
        "latitude": 34.0368356,
        "longitude": -84.6849469,
        "address": "3384 Cobb Parkway Northwest",
        "address2": "",
        "city": "Acworth",
        "state": "Georgia",
        "postal_code": "30101",
        "country": "United States",
        "formatted_address": "3384 Cobb Pkwy NW, Acworth, GA 30101, USA",
        "timezone_id": "America/New_York",
        "properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-84.686266139426, 34.03691790271], [-84.683874423804, 34.036428910564], [-84.68361156732, 34.037123469444], [-84.683134134116, 34.037231237951], [-84.683134134116, 34.037820182511], [-84.686073020377, 34.037882417011], [-84.686266139426, 34.03691790271]]]
            },
            "properties": {
              "name": "Polygon #1",
              "notes": "",
              "number": 1,
              "area": "34241.1",
              "bounds": {
                "south": 34.036428910564,
                "west": -84.686266139426,
                "north": 34.037882417011,
                "east": -84.683134134116
              },
              "center": {
                "lat": 34.037155663787,
                "lng": -84.684700136771
              }
            },
            "id": 1
          }]
        }
      }
    }, {
      "id": "19163",
      "type": "packages",
      "attributes": {
        "name": "Starter Package - Shopping Centers (Up to 400,000 sq ft)",
        "description": "High-quality aerial images and video showcasing your Shopping Center, its storefronts, and the surrounding area.   ",
        "price": 41900,
        "cloud_reqs": "less_than_50",
        "position": 7,
        "slug": "cre-starter-pack-shopping-center"
      },
      "relationships": {
        "vertical": {
          "data": {
            "id": "1",
            "type": "verticals"
          }
        },
        "organization": {
          "data": null
        },
        "client": {
          "data": null
        },
        "catalog_items": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "verticals",
      "attributes": {
        "name": "Commercial Real Estate",
        "short_name": "CRE"
      }
    }, {
      "id": "4898",
      "type": "credit_cards",
      "attributes": {
        "brand": "Visa",
        "last_4": "3969",
        "exp_month": 2,
        "exp_year": 2024,
        "stripe_id": "card_1FW88bJo202eeWudwLnrPXPF"
      }
    }, {
      "id": "49557",
      "type": "onsite_contacts",
      "attributes": {
        "name": "John Smith",
        "call_action": "Call When You Arrive",
        "phone": "123-456-7890",
        "note": null
      }
    }, {
      "id": "DBC3006554",
      "type": "clients",
      "attributes": {
        "first_name": "Demo",
        "last_name": "Zeitview",
        "phone": "",
        "email": "clients@zeitview.com",
        "token": "qC65CqCmbKfMZvIU2gRb_uD7Sqw",
        "company_name": null,
        "invoiceable": false
      },
      "relationships": {
        "hidden_sample_missions": {
          "data": [{
            "id": "*",
            "type": "hidden_sample_mission"
          }]
        }
      }
    }, {
      "id": "DBI0961388337",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034066-c762a251a99348bd0bd133a0796152f69138932b/original-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
        "name": "DBI-38-FkYLNGeL-CRE-Images-Package---Shopping-Centers-Sample_DBI-53-1e11H3VD-Lakeside-Marketplace_DBU94-DJI_0077.jpeg",
        "source_type": "edit",
        "formatted_size": "2.42 MB",
        "size": 2540186,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034066-c762a251a99348bd0bd133a0796152f69138932b/large_1920-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034066-c762a251a99348bd0bd133a0796152f69138932b/medium_1024-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034066-c762a251a99348bd0bd133a0796152f69138932b/small_640-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034066-c762a251a99348bd0bd133a0796152f69138932b/four_three_640-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034066-c762a251a99348bd0bd133a0796152f69138932b/square_640-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034066-c762a251a99348bd0bd133a0796152f69138932b/downloadable-62155bd10193342d2fbb88f6512029f76865071a.jpeg"
        },
        "created_on": "2019-12-06T00:40:48Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060824",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4605215017",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034065-c762a251a99348bd0bd133a0796152f69138932b/original-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
        "name": "DBI-37-AZvttwEQ-CRE-Images-Package---Shopping-Centers-Sample_DBI-58-9MohZxUx-Lakeside-Marketplace_DBU99-DJI_0088.jpeg",
        "source_type": "edit",
        "formatted_size": "2.49 MB",
        "size": 2609129,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034065-c762a251a99348bd0bd133a0796152f69138932b/large_1920-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034065-c762a251a99348bd0bd133a0796152f69138932b/medium_1024-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034065-c762a251a99348bd0bd133a0796152f69138932b/small_640-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034065-c762a251a99348bd0bd133a0796152f69138932b/four_three_640-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034065-c762a251a99348bd0bd133a0796152f69138932b/square_640-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034065-c762a251a99348bd0bd133a0796152f69138932b/downloadable-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg"
        },
        "created_on": "2019-12-06T00:40:48Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060824",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3314317848",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034064-c762a251a99348bd0bd133a0796152f69138932b/original-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
        "name": "DBI-36-sJQ99AF1-CRE-Images-Package---Shopping-Centers-Sample_DBI-49-GBkeyipm-Lakeside-Marketplace_DBU90-DJI_0073.jpeg",
        "source_type": "edit",
        "formatted_size": "2.49 MB",
        "size": 2608538,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034064-c762a251a99348bd0bd133a0796152f69138932b/large_1920-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034064-c762a251a99348bd0bd133a0796152f69138932b/medium_1024-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034064-c762a251a99348bd0bd133a0796152f69138932b/small_640-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034064-c762a251a99348bd0bd133a0796152f69138932b/four_three_640-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034064-c762a251a99348bd0bd133a0796152f69138932b/square_640-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034064-c762a251a99348bd0bd133a0796152f69138932b/downloadable-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg"
        },
        "created_on": "2019-12-06T00:40:48Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060824",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6461351850",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034063-7ffe9fbe01faa163c7a12c15f21fa7b60d41f0d7/original-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
        "name": "DBI-34-9w29wmK1-CRE-Images-Package---Shopping-Centers-Sample_DBI-52-KjcTo8Vv-Lakeside-Marketplace_DBU93-DJI_0076.jpeg",
        "source_type": "edit",
        "formatted_size": "2.54 MB",
        "size": 2658148,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034063-7ffe9fbe01faa163c7a12c15f21fa7b60d41f0d7/large_1920-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034063-7ffe9fbe01faa163c7a12c15f21fa7b60d41f0d7/medium_1024-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034063-7ffe9fbe01faa163c7a12c15f21fa7b60d41f0d7/small_640-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034063-7ffe9fbe01faa163c7a12c15f21fa7b60d41f0d7/four_three_640-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034063-7ffe9fbe01faa163c7a12c15f21fa7b60d41f0d7/square_640-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034063-7ffe9fbe01faa163c7a12c15f21fa7b60d41f0d7/downloadable-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg"
        },
        "created_on": "2019-12-06T00:40:47Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060824",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8547453569",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034062-51d4e6de606876d12b191be9b410173bb4e9af41/original-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
        "name": "DBI-33-WlYhJAs3-CRE-Images-Package---Shopping-Centers-Sample_DBI-54-8efSASmw-Lakeside-Marketplace_DBU95-DJI_0079.jpeg",
        "source_type": "edit",
        "formatted_size": "2.46 MB",
        "size": 2581268,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034062-51d4e6de606876d12b191be9b410173bb4e9af41/large_1920-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034062-51d4e6de606876d12b191be9b410173bb4e9af41/medium_1024-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034062-51d4e6de606876d12b191be9b410173bb4e9af41/small_640-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034062-51d4e6de606876d12b191be9b410173bb4e9af41/four_three_640-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034062-51d4e6de606876d12b191be9b410173bb4e9af41/square_640-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034062-51d4e6de606876d12b191be9b410173bb4e9af41/downloadable-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg"
        },
        "created_on": "2019-12-06T00:40:22Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060823",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8927383984",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034061-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/original-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
        "name": "DBI-32-LVQaqJwL-CRE-Images-Package---Shopping-Centers-Sample_DBI-47-Mqr74Qkf-Lakeside-Marketplace_DBU88-DJI_0070.jpeg",
        "source_type": "edit",
        "formatted_size": "2.08 MB",
        "size": 2178978,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034061-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/large_1920-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034061-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/medium_1024-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034061-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/small_640-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034061-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/four_three_640-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034061-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/square_640-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034061-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/downloadable-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg"
        },
        "created_on": "2019-12-06T00:40:21Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060823",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9616606698",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034058-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/original-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
        "name": "DBI-29-mZdGmnhV-CRE-Images-Package---Shopping-Centers-Sample_DBI-57-TgwqvXuM-Lakeside-Marketplace_DBU98-DJI_0087.jpeg",
        "source_type": "edit",
        "formatted_size": "2.39 MB",
        "size": 2507677,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034058-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/large_1920-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034058-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/medium_1024-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034058-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/small_640-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034058-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/four_three_640-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034058-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/square_640-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034058-3cd2ec73d3e1909505eb98c60ef723ee2527ee09/downloadable-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg"
        },
        "created_on": "2019-12-06T00:40:21Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060823",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0000867195",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034047-db7dee70410497eaf8f8246821098cc34b833cf8/original-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
        "name": "DBI-18-NEbZA7L2-CRE-Images-Package---Shopping-Centers-Sample_DBI-27-JGEYmlMO-Lakeside-Marketplace_DBU68-E_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.33 MB",
        "size": 2439645,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034047-db7dee70410497eaf8f8246821098cc34b833cf8/large_1920-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034047-db7dee70410497eaf8f8246821098cc34b833cf8/medium_1024-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034047-db7dee70410497eaf8f8246821098cc34b833cf8/small_640-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034047-db7dee70410497eaf8f8246821098cc34b833cf8/four_three_640-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034047-db7dee70410497eaf8f8246821098cc34b833cf8/square_640-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034047-db7dee70410497eaf8f8246821098cc34b833cf8/downloadable-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg"
        },
        "created_on": "2019-12-06T00:39:38Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060821",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8347933228",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034046-db7dee70410497eaf8f8246821098cc34b833cf8/original-762384d67608457afc248fde66c893da7f918496.jpeg",
        "name": "DBI-17-vENyagRr-CRE-Images-Package---Shopping-Centers-Sample_DBI-35-3rht1dJV-Lakeside-Marketplace_DBU76-FRONT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.04 MB",
        "size": 2138776,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034046-db7dee70410497eaf8f8246821098cc34b833cf8/large_1920-762384d67608457afc248fde66c893da7f918496.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034046-db7dee70410497eaf8f8246821098cc34b833cf8/medium_1024-762384d67608457afc248fde66c893da7f918496.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034046-db7dee70410497eaf8f8246821098cc34b833cf8/small_640-762384d67608457afc248fde66c893da7f918496.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034046-db7dee70410497eaf8f8246821098cc34b833cf8/four_three_640-762384d67608457afc248fde66c893da7f918496.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034046-db7dee70410497eaf8f8246821098cc34b833cf8/square_640-762384d67608457afc248fde66c893da7f918496.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034046-db7dee70410497eaf8f8246821098cc34b833cf8/downloadable-762384d67608457afc248fde66c893da7f918496.jpeg"
        },
        "created_on": "2019-12-06T00:39:38Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060821",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4351558565",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034045-db7dee70410497eaf8f8246821098cc34b833cf8/original-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
        "name": "DBI-16-bmwyEZPR-CRE-Images-Package---Shopping-Centers-Sample_DBI-34-Yb0qjasr-Lakeside-Marketplace_DBU75-W_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.33 MB",
        "size": 2444404,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034045-db7dee70410497eaf8f8246821098cc34b833cf8/large_1920-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034045-db7dee70410497eaf8f8246821098cc34b833cf8/medium_1024-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034045-db7dee70410497eaf8f8246821098cc34b833cf8/small_640-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034045-db7dee70410497eaf8f8246821098cc34b833cf8/four_three_640-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034045-db7dee70410497eaf8f8246821098cc34b833cf8/square_640-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034045-db7dee70410497eaf8f8246821098cc34b833cf8/downloadable-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg"
        },
        "created_on": "2019-12-06T00:39:38Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060821",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2854602987",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034044-db7dee70410497eaf8f8246821098cc34b833cf8/original-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
        "name": "DBI-15-HwKAXnfl-CRE-Images-Package---Shopping-Centers-Sample_DBI-31-zGjKtYXQ-Lakeside-Marketplace_DBU72-S_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.17 MB",
        "size": 2279977,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034044-db7dee70410497eaf8f8246821098cc34b833cf8/large_1920-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034044-db7dee70410497eaf8f8246821098cc34b833cf8/medium_1024-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034044-db7dee70410497eaf8f8246821098cc34b833cf8/small_640-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034044-db7dee70410497eaf8f8246821098cc34b833cf8/four_three_640-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034044-db7dee70410497eaf8f8246821098cc34b833cf8/square_640-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034044-db7dee70410497eaf8f8246821098cc34b833cf8/downloadable-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg"
        },
        "created_on": "2019-12-06T00:39:38Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060821",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3471752043",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034043-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/original-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
        "name": "DBI-14-BdRTdv2X-CRE-Images-Package---Shopping-Centers-Sample_DBI-29-Q4idBa2p-Lakeside-Marketplace_DBU70-NE_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.43 MB",
        "size": 2545508,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034043-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/large_1920-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034043-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/medium_1024-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034043-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/small_640-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034043-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/four_three_640-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034043-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/square_640-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034043-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/downloadable-492d0b22f10d900ef123841826e66de9705918d8.jpeg"
        },
        "created_on": "2019-12-06T00:39:37Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060821",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6976490758",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034042-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/original-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
        "name": "DBI-13-Zry0H7jF-CRE-Images-Package---Shopping-Centers-Sample_DBI-28-zNfK8rO0-Lakeside-Marketplace_DBU69-N_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.11 MB",
        "size": 2212920,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034042-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/large_1920-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034042-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/medium_1024-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034042-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/small_640-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034042-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/four_three_640-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034042-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/square_640-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034042-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/downloadable-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg"
        },
        "created_on": "2019-12-06T00:39:37Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060821",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4430421258",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034041-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/original-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
        "name": "DBI-12-26Ioip2B-CRE-Images-Package---Shopping-Centers-Sample_DBI-32-nu7toZ8x-Lakeside-Marketplace_DBU73-SE_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.26 MB",
        "size": 2366480,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034041-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/large_1920-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034041-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/medium_1024-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034041-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/small_640-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034041-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/four_three_640-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034041-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/square_640-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034041-6c561fcb0c8a43e3e21a6bde4cf1ab33eddcd2aa/downloadable-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg"
        },
        "created_on": "2019-12-06T00:39:37Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060821",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI1035800300",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034040-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/original-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
        "name": "DBI-30-KOWpKD9Z-CRE-Images-Package---Shopping-Centers-Sample_DBI-50-paYmqqka-Lakeside-Marketplace_DBU91-DJI_0074.jpeg",
        "source_type": "edit",
        "formatted_size": "2.46 MB",
        "size": 2579540,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034040-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/large_1920-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034040-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/medium_1024-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034040-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/small_640-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034040-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/four_three_640-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034040-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/square_640-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034040-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/downloadable-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg"
        },
        "created_on": "2019-12-06T00:37:52Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060820",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6787202082",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034039-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/original-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
        "name": "DBI-29-181as2rw-CRE-Images-Package---Shopping-Centers-Sample_DBI-36-wOLh2iGJ-Lakeside-Marketplace_DBU77-DJI_0055.jpeg",
        "source_type": "edit",
        "formatted_size": "2.25 MB",
        "size": 2358806,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034039-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/large_1920-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034039-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/medium_1024-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034039-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/small_640-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034039-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/four_three_640-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034039-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/square_640-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034039-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/downloadable-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg"
        },
        "created_on": "2019-12-06T00:37:52Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060820",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7935423026",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034038-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/original-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
        "name": "DBI-28-JTkImIEF-CRE-Images-Package---Shopping-Centers-Sample_DBI-51-n6hpnhi5-Lakeside-Marketplace_DBU92-DJI_0075.jpeg",
        "source_type": "edit",
        "formatted_size": "2.21 MB",
        "size": 2322350,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034038-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/large_1920-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034038-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/medium_1024-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034038-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/small_640-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034038-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/four_three_640-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034038-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/square_640-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034038-3fcb67bb93a06b6dbaa820d0cc755cfab1f8fe51/downloadable-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg"
        },
        "created_on": "2019-12-06T00:37:52Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060820",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3258187517",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034037-1d3180ce79d42efddb4018d6ba92408214f1f6af/original-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
        "name": "DBI-27-CDklnGBi-CRE-Images-Package---Shopping-Centers-Sample_DBI-33-egnffXbz-Lakeside-Marketplace_DBU74-SW_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.08 MB",
        "size": 2183094,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034037-1d3180ce79d42efddb4018d6ba92408214f1f6af/large_1920-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034037-1d3180ce79d42efddb4018d6ba92408214f1f6af/medium_1024-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034037-1d3180ce79d42efddb4018d6ba92408214f1f6af/small_640-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034037-1d3180ce79d42efddb4018d6ba92408214f1f6af/four_three_640-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034037-1d3180ce79d42efddb4018d6ba92408214f1f6af/square_640-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034037-1d3180ce79d42efddb4018d6ba92408214f1f6af/downloadable-58b56ed2c054c513b803908e2c621724881381a3.jpeg"
        },
        "created_on": "2019-12-06T00:37:51Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060820",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6107705341",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034036-1d3180ce79d42efddb4018d6ba92408214f1f6af/original-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
        "name": "DBI-26-RHIdKsod-CRE-Images-Package---Shopping-Centers-Sample_DBI-30-6Cem6m7R-Lakeside-Marketplace_DBU71-NW_100FTR.jpeg",
        "source_type": "edit",
        "formatted_size": "2.2 MB",
        "size": 2305414,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034036-1d3180ce79d42efddb4018d6ba92408214f1f6af/large_1920-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034036-1d3180ce79d42efddb4018d6ba92408214f1f6af/medium_1024-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034036-1d3180ce79d42efddb4018d6ba92408214f1f6af/small_640-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034036-1d3180ce79d42efddb4018d6ba92408214f1f6af/four_three_640-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034036-1d3180ce79d42efddb4018d6ba92408214f1f6af/square_640-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034036-1d3180ce79d42efddb4018d6ba92408214f1f6af/downloadable-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg"
        },
        "created_on": "2019-12-06T00:37:51Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060820",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV8411397514",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-7-KRPNQDHY-DL-DBUV010-Orbit-Part-1.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "b7c32ccf14721c68da97a48f9e27e61f",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174436-e95ab4a40f1d5dff928ae40e99d12165c027eecb/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/174436-e95ab4a40f1d5dff928ae40e99d12165c027eecb/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174436-e95ab4a40f1d5dff928ae40e99d12165c027eecb/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174436-e95ab4a40f1d5dff928ae40e99d12165c027eecb/DL-DBV-7-KRPNQDHY-DL-DBUV010-Orbit-Part-1.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060814",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV6067672905",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-21-2FLV8bsk-DL-DBUV024-Dolly-Shot-Wide.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "b548c788fe9dd7b8fdb6677bee4971bd",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174435-d8e4ec939d775e90890aecfd92663b82fe52d495/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/174435-d8e4ec939d775e90890aecfd92663b82fe52d495/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174435-d8e4ec939d775e90890aecfd92663b82fe52d495/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174435-d8e4ec939d775e90890aecfd92663b82fe52d495/DL-DBV-21-2FLV8bsk-DL-DBUV024-Dolly-Shot-Wide.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060816",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV8287810577",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-20-mJSlb84v-DL-DBUV033-Custom.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "3795f3773017ab03119e15923c31e2c6",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174434-d09542fc1559bb61ac19d0fa14081ea2bef8c1d7/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/174434-d09542fc1559bb61ac19d0fa14081ea2bef8c1d7/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174434-d09542fc1559bb61ac19d0fa14081ea2bef8c1d7/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174434-d09542fc1559bb61ac19d0fa14081ea2bef8c1d7/DL-DBV-20-mJSlb84v-DL-DBUV033-Custom.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060817",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV0759352987",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-22-v1dNUNfo-DL-CRE-Shopping-Center---Starter-Package_DBV-28-DBUV031-Rise--Reverse-Away-final-assets.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "c43f25cc719cd71629f7628a76ee2fc4",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174433-74af5f7d3a52ed5691864550520f8407a0c008ad/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/174433-74af5f7d3a52ed5691864550520f8407a0c008ad/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174433-74af5f7d3a52ed5691864550520f8407a0c008ad/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174433-74af5f7d3a52ed5691864550520f8407a0c008ad/DL-DBV-22-v1dNUNfo-DL-CRE-Shopping-Center---Starter-Package_DBV-28-DBUV031-Rise--Reverse-Away-final-assets.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060815",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV2828768194",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-20-JmK1IHw2-DL-CRE-Shopping-Center---Starter-Package_DBV-27-DBUV030-Birds-Eye-Shot-final-assets.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "9feaae11a0b4c7e63a2ed96373dc1b78",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174432-bb15df4ddb2c0b758db1d4e21a78664dd91e5eac/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/174432-bb15df4ddb2c0b758db1d4e21a78664dd91e5eac/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174432-bb15df4ddb2c0b758db1d4e21a78664dd91e5eac/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174432-bb15df4ddb2c0b758db1d4e21a78664dd91e5eac/DL-DBV-20-JmK1IHw2-DL-CRE-Shopping-Center---Starter-Package_DBV-27-DBUV030-Birds-Eye-Shot-final-assets.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060818",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV8667643715",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-20-IyTPdjE7-DL-CRE-Shopping-Center---Starter-Package_DBV-26-DBUV027-Trucking-R-to-L-Close-final-assets.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "4538282fefda2ba7a76ae05f4d5de1a1",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174431-19464592f63c4c84792e9a7fb17a76c458285a62/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/174431-19464592f63c4c84792e9a7fb17a76c458285a62/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174431-19464592f63c4c84792e9a7fb17a76c458285a62/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174431-19464592f63c4c84792e9a7fb17a76c458285a62/DL-DBV-20-IyTPdjE7-DL-CRE-Shopping-Center---Starter-Package_DBV-26-DBUV027-Trucking-R-to-L-Close-final-assets.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060819",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "df9ac65f0a4e577e4ddc35130f64a649",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-05T06:58:56Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "1060824",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278129",
        "shot_type_id": 148,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Low Level Corner Shots"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "148",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI4605215017",
            "type": "images"
          }, {
            "id": "DBI0961388337",
            "type": "images"
          }, {
            "id": "DBI3314317848",
            "type": "images"
          }, {
            "id": "DBI6461351850",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "148",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Low Level Corner Shots",
        "description": "Images from just below roof level that are angled down the lineup of buildings. We need one image from each end of the building. If there are multiple building or if it is an L-shape or U-shaped property we need an image from each corner. For example, an L-shaped building will have at least 4 images and a U-shaped building with have at least 6 images. \n\nImages with little to no roof in frame are a big point of emphasis our customers. If you need to adjust, back away from the property rather than increasing altitude *so that we don't see the top of the roof.*\n**[Click here for example images](http://bit.ly/2wNIc1D)**",
        "video": null,
        "slug": "images_-_low_level_corner_shots",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060823",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278129",
        "shot_type_id": 147,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Anchor Tenants"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "147",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI9616606698",
            "type": "images"
          }, {
            "id": "DBI8547453569",
            "type": "images"
          }, {
            "id": "DBI8927383984",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "147",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Anchor Tenants",
        "description": "Straight on image of each anchor tenant from just below roof level. *An anchor tenant is grocery store, department store, or other large retailer.* Images with little to no roof in frame are a big point of emphasis our customers. If you need to adjust, back away from the property rather than increasing altitude *so that we don't see the top of the roof.*",
        "video": null,
        "slug": "images_-_anchor_tenants",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060822",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278129",
        "shot_type_id": 22,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Final Assets"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "22",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "22",
      "type": "shot_types",
      "attributes": {
        "name": "Final Assets",
        "description": "Use this shot to upload final edited assets for the customer. Not for use by pilots.\n",
        "video": null,
        "slug": "final-assets",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060821",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "278129",
        "shot_type_id": 24,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (350 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "24",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI0000867195",
            "type": "images"
          }, {
            "id": "DBI8347933228",
            "type": "images"
          }, {
            "id": "DBI4351558565",
            "type": "images"
          }, {
            "id": "DBI2854602987",
            "type": "images"
          }, {
            "id": "DBI4430421258",
            "type": "images"
          }, {
            "id": "DBI3471752043",
            "type": "images"
          }, {
            "id": "DBI6976490758",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "24",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (350 ft above ground level)",
        "description": "*If there are altitude restrictions in this airspace, please take these images at the highest allowable altitude instead of 350'. *",
        "video": null,
        "slug": "images-cardinal-directions-350-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "23",
            "type": "shot_type_sample_images"
          }, {
            "id": "24",
            "type": "shot_type_sample_images"
          }, {
            "id": "25",
            "type": "shot_type_sample_images"
          }, {
            "id": "26",
            "type": "shot_type_sample_images"
          }, {
            "id": "27",
            "type": "shot_type_sample_images"
          }, {
            "id": "28",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1060820",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "278129",
        "shot_type_id": 13,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (100 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "13",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI1035800300",
            "type": "images"
          }, {
            "id": "DBI7935423026",
            "type": "images"
          }, {
            "id": "DBI6787202082",
            "type": "images"
          }, {
            "id": "DBI3258187517",
            "type": "images"
          }, {
            "id": "DBI6107705341",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "13",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (100 ft above ground level)",
        "description": "",
        "video": "",
        "slug": "images-cardinal-directions-100-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "17",
            "type": "shot_type_sample_images"
          }, {
            "id": "18",
            "type": "shot_type_sample_images"
          }, {
            "id": "19",
            "type": "shot_type_sample_images"
          }, {
            "id": "20",
            "type": "shot_type_sample_images"
          }, {
            "id": "21",
            "type": "shot_type_sample_images"
          }, {
            "id": "22",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1060819",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Truck (*left to right* or *right to left*) just below roof level **_across all storefronts_**. The tenants should be clearly visible. The full front of the storefront should be in frame. We do not want to see the top of the roof. Adjust distance accordingly.  \n",
        "mission_id": "278129",
        "shot_type_id": 2,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Trucking Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "2",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV8667643715",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "2",
      "type": "shot_types",
      "attributes": {
        "name": "Trucking Shot",
        "description": "",
        "video": "https://youtu.be/m-r79WAcDNs",
        "slug": "trucking-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060818",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: Looking straight down at the center of the property, rise up vertically to maximum altitude. Go slow and steady. This will reveal property and property boundaries. If the property it too large to be revealed just show as much as you can from maximum altitude.  \n",
        "mission_id": "278129",
        "shot_type_id": 9,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Birds Eye Shots"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "9",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV2828768194",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "9",
      "type": "shot_types",
      "attributes": {
        "name": "Birds Eye Shots",
        "description": "",
        "video": "",
        "slug": "birds-eye-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060817",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: focus on some point of interest or sign\n",
        "mission_id": "278129",
        "shot_type_id": 54,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Creative Video Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "54",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV8287810577",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "54",
      "type": "shot_types",
      "attributes": {
        "name": "Creative Video Shot",
        "description": null,
        "video": null,
        "slug": "getty-video-getty-video",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060816",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Slowly fly towards the front of the property at a constant altitude (10 feet over roof level) with your camera pointing straight (can have slight angle downward if needed). Keep the horizon flat. Fly completely over property and the end of the property lot.  \n",
        "mission_id": "278129",
        "shot_type_id": 1,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Dolly Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "1",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV6067672905",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "shot_types",
      "attributes": {
        "name": "Dolly Shot",
        "description": "",
        "video": "https://youtu.be/H80sEIUHa7Y",
        "slug": "dolly-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060815",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: Rise + Reverse Away facing the front of the subject property.  \n",
        "mission_id": "278129",
        "shot_type_id": 10,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Rise + Reverse Away Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "10",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV0759352987",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "10",
      "type": "shot_types",
      "attributes": {
        "name": "Rise + Reverse Away Shot",
        "description": "",
        "video": "https://youtu.be/PwOvzZqQWkg",
        "slug": "rise-reverse-away-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060814",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Large + high altitude Contextual Orbit shot around property (counterclockwise)  \n*Must have entire subject property in frame and your camera must be angled up enough so that we also have the horizon in frame.* The point is to provide context of the property in relation to the surrounding area. This will require you to fly at a higher altitude and farther off the property to get the required shot. Typically shot around 300 ft. Orbit should be slow + steady. No jerky camera movements.  \n",
        "mission_id": "278129",
        "shot_type_id": 8,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Orbit Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "8",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV8411397514",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "8",
      "type": "shot_types",
      "attributes": {
        "name": "Orbit Shot",
        "description": "",
        "video": "https://youtu.be/MzdF92gWXyU",
        "slug": "orbit-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "DBINV572420989532449",
      "type": "invoice_line_items",
      "attributes": {
        "reason": "base_package_price",
        "description": null,
        "amount": 41900,
        "created_on": "2019-12-04T02:02:04Z",
        "display_name": "Base Package Price"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4316427392",
            "type": "missions"
          }
        },
        "work_item": {
          "data": null
        }
      }
    }]
  };
  _exports.default = _default;
});