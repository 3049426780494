define("clients/data/sample_missions/cre_regional_center", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": {
      "id": "DBM6516057996",
      "type": "missions",
      "attributes": {
        "instructions": "* Scope: Panorama tiles\n* Time: Between 11am - 2pm\n* Schedule: Within 48-72 hours; Weather permitting",
        "status": "complete",
        "client_id": "DBC3006554",
        "payment": {
          "id": null,
          "processor": null,
          "card": "Visa - 3969"
        },
        "external_assets_url": null,
        "owned_by_current_client": true,
        "contact_email": "clients@zeitview.com",
        "created_on": "2019-12-05T21:31:22Z",
        "is_pano_mission": false,
        "logo_url": null,
        "blurb": null,
        "paid": true,
        "paid_on": "2019-12-06T00:42:32Z",
        "price": 9900,
        "archive_url": null,
        "edit_url": null,
        "customer_instructions": null,
        "scheduled_at_start": "2019-12-06T18:00:00Z",
        "scheduled_at_end": "2019-12-06T19:00:00Z",
        "parent_id": null,
        "reshoot_mission_id": null,
        "rejection_reason": null,
        "rejection_notes": null,
        "reference_id": "CRE Pano Package - Regional Centers Sample",
        "cloud_reqs": "less_than_50",
        "scheduling_requirement": "not_needed",
        "arcgis_feature_layer": null,
        "geo_properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-75.63467021272, 40.027396827543], [-75.630658442853, 40.02825123073], [-75.632847125409, 40.031003603011], [-75.635464146588, 40.029820628099], [-75.63467021272, 40.027396827543]]]
            },
            "properties": {
              "name": "Polygon #1",
              "notes": "",
              "number": 1,
              "area": "95809.6",
              "bounds": {
                "south": 40.027396827543,
                "west": -75.635464146588,
                "north": 40.031003603011,
                "east": -75.630658442853
              },
              "center": {
                "lat": 40.029200215277,
                "lng": -75.63306129472
              }
            },
            "id": 1
          }]
        }
      },
      "relationships": {
        "location": {
          "data": {
            "id": "285084",
            "type": "locations"
          }
        },
        "package": {
          "data": {
            "id": "19160",
            "type": "packages"
          }
        },
        "credit_card": {
          "data": {
            "id": "4898",
            "type": "credit_cards"
          }
        },
        "onsite_contact": {
          "data": {
            "id": "49817",
            "type": "onsite_contacts"
          }
        },
        "scheduling_contact": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": [{
            "id": "cre-regional-center-panorama",
            "type": "panoramas"
          }]
        },
        "attachments": {
          "data": []
        },
        "ortho_photo_maps": {
          "data": []
        },
        "shares": {
          "data": [{
            "id": "911861ff85feafdf3c1ef6f7a93feefa",
            "type": "shares"
          }, {
            "id": "cefd45c6e0c8024dfd5aff386e681aaa",
            "type": "shares"
          }]
        },
        "main_image": {
          "data": {
            "id": "DBI2245445361",
            "type": "images"
          }
        },
        "collaborators": {
          "data": []
        },
        "parent": {
          "data": null
        },
        "shots": {
          "data": [{
            "id": "1061653",
            "type": "shots"
          }, {
            "id": "1061652",
            "type": "shots"
          }]
        },
        "invoice_line_items": {
          "data": [{
            "id": "DBINV311185557141614",
            "type": "invoice_line_items"
          }]
        }
      }
    },
    "included": [{
      "id": "285084",
      "type": "locations",
      "attributes": {
        "name": "229 Lincoln Hwy, Exton, PA 19341, USA",
        "latitude": 40.0285348,
        "longitude": -75.6315378,
        "address": "229 Lincoln Highway",
        "address2": null,
        "city": "Exton",
        "state": "Pennsylvania",
        "postal_code": "19341",
        "country": "United States",
        "formatted_address": "229 Lincoln Hwy, Exton, PA 19341, USA",
        "timezone_id": "America/New_York",
        "properties": {}
      }
    }, {
      "id": "19160",
      "type": "packages",
      "attributes": {
        "name": "Pano Package - Regional Centers (400,000+ sq ft)",
        "description": "Beautiful interactive panorama of your Regional Center and surrounding area. Includes an easily shareable page and embeddable link for social media and websites (not downloadable).",
        "price": 9900,
        "cloud_reqs": "less_than_50",
        "position": 9,
        "slug": "cre-pano-pack-lg-shopping-center"
      },
      "relationships": {
        "vertical": {
          "data": {
            "id": "1",
            "type": "verticals"
          }
        },
        "organization": {
          "data": null
        },
        "client": {
          "data": null
        },
        "catalog_items": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "verticals",
      "attributes": {
        "name": "Commercial Real Estate",
        "short_name": "CRE"
      }
    }, {
      "id": "4898",
      "type": "credit_cards",
      "attributes": {
        "brand": "Visa",
        "last_4": "3969",
        "exp_month": 2,
        "exp_year": 2024,
        "stripe_id": "card_1FW88bJo202eeWudwLnrPXPF"
      }
    }, {
      "id": "49817",
      "type": "onsite_contacts",
      "attributes": {
        "name": "John Smith",
        "call_action": "Call When You Arrive",
        "phone": "123-456-7890",
        "note": null
      }
    }, {
      "id": "DBC3006554",
      "type": "clients",
      "attributes": {
        "first_name": "Demo",
        "last_name": "Zeitview",
        "phone": "",
        "email": "clients@zeitview.com",
        "token": "qC65CqCmbKfMZvIU2gRb_uD7Sqw",
        "company_name": null,
        "invoiceable": false
      },
      "relationships": {
        "hidden_sample_missions": {
          "data": [{
            "id": "*",
            "type": "hidden_sample_mission"
          }]
        }
      }
    }, {
      "id": "cre-regional-center-panorama",
      "type": "panoramas",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "missions/DBM6516057996/panorama/DBP7671789749-pano.zip",
        "source_type": "edit",
        "share_token": "825f47a820b569e2dd5a4f7b0bf317c3",
        "formatted_size": null,
        "size": null,
        "height": null,
        "width": null,
        "resolution": null,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "created_on": "2019-12-05T21:34:13Z",
        "access_key": "df6d2d60-51a6-41bd-8ecb-97af9f7867f3",
        "pano_base_url": "https://cdn.dronebase.com/assets/mission/panoramas/75796-df6d2d60-51a6-41bd-8ecb-97af9f7867f3",
        "watermark": false
      },
      "relationships": {
        "shot": {
          "data": {
            "id": "1061653",
            "type": "shots"
          }
        },
        "mission": {
          "data": {
            "id": "DBM6516057996",
            "type": "missions"
          }
        },
        "hotspots": {
          "data": []
        }
      }
    }, {
      "id": "911861ff85feafdf3c1ef6f7a93feefa",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-06T01:22:53Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM6516057996",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "cefd45c6e0c8024dfd5aff386e681aaa",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-06T01:22:47Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM6516057996",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "1061653",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278407",
        "shot_type_id": 23,
        "pilot_comment": null,
        "post_processing_status": "completed",
        "origin_type": "package",
        "name": "Panorama Tiles"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "23",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": [{
            "id": "cre-regional-center-panorama",
            "type": "panoramas"
          }]
        }
      }
    }, {
      "id": "23",
      "type": "shot_types",
      "attributes": {
        "name": "Panorama Tiles",
        "description": "+ Do not upload a stitched panorama. We need the individual images so that we can stitch the panorama. \n\nWe recommend using DJI Go for panoramas. To take a panorama with DJI Go:\n\n1. Go to \u201cCamera settings\u201d\n2. Select \u201cPhoto\u201d\n3. Select \u201cPano\u201d\n4. Select \u201cSphere\u201d\n\nPanorama tiles\n+ (1) set of panorama tiles\n+ How to take a set of panorama tiles: [Click here](http://bit.ly/2ONpr6h) \n+ Turn off auto exposure\n+ Auto white balance must be off\n+ Shoot in 4:3 aspect ratio (3:2 is also acceptable)\n+ Altitude: 100' (unless another altitude is listed for the mission)\n+ [Example 1](https://bit.ly/30s09R6)\u00a0- This panorama is taken from too far away. The subject property is the Rite Aid on the other side of the street.\u00a0\n+ [Example 2](https://bit.ly/2Hz6Acw)\u00a0- This panorama is taken from a perfect distance. You can see the front of the Family Dollar clearly and it perfectly showcases the property.\u00a0",
        "video": null,
        "slug": "pano_tiles",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061652",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278407",
        "shot_type_id": 22,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Final Assets"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "22",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "22",
      "type": "shot_types",
      "attributes": {
        "name": "Final Assets",
        "description": "Use this shot to upload final edited assets for the customer. Not for use by pilots.\n",
        "video": null,
        "slug": "final-assets",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "DBINV311185557141614",
      "type": "invoice_line_items",
      "attributes": {
        "reason": "base_package_price",
        "description": null,
        "amount": 9900,
        "created_on": "2019-12-05T21:31:22Z",
        "display_name": "Base Package Price"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6516057996",
            "type": "missions"
          }
        },
        "work_item": {
          "data": null
        }
      }
    }]
  };
  _exports.default = _default;
});