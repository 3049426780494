define("clients/components/charts/flights-canceled-chart", ["exports", "clients/components/charts/base-chart"], function (_exports, _baseChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseChart.default.extend({
    classNames: ['client-chart flights-canceled-chart'],
    title: '<span data-toggle="tooltip" data-placement="top" data-container="body" ' + 'title="Total number of orders that have been canceled. Orders may be canceled due to airspace restrictions, ' + 'prolonged unsafe weather, etc."> ' + 'Orders Canceled ' + '<i class="fa fa-question-circle"></i> </span>',
    containerName: 'canceled-chart',
    chartColor: 'rgb(243, 174, 161)',
    tooltipOptions: {
      borderColor: "#f3afa2",
      backgroundColor: 'white',
      style: {
        color: '#1d2d47'
      },
      formatter: function () {
        let pluralEnd;

        if (this.y === 1) {
          pluralEnd = '';
        } else {
          pluralEnd = 's';
        }

        return Highcharts.dateFormat('%b %d, %Y', this.x) + '<br>' + this.y + ' order' + pluralEnd;
      }
    },
    didInsertElement: function () {
      const chart = this._super();

      return this.set('chart', chart);
    }
  });

  _exports.default = _default;
});