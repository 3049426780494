define("clients/controllers/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DashboardController;
  DashboardController = Ember.Controller.extend({
    packages: Ember.computed(function () {
      return this.store.query('package', {
        "public": true
      });
    }),
    actions: {
      hideShowRegisterModal: function () {
        return this.toggleProperty('showRegisterModal');
      }
    }
  });
  var _default = DashboardController;
  _exports.default = _default;
});