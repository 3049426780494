define("clients/serializers/location", ["exports", "clients/serializers/application", "ember-data-save-relationships", "ember-data"], function (_exports, _application, _emberDataSaveRelationships, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const LocationSerializer = _application.default.extend(_emberDataSaveRelationships.default, {
    attrs: {
      watchers: {
        serialize: true,
        deserialize: true
      }
    }
  });

  var _default = LocationSerializer;
  _exports.default = _default;
});