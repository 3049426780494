define("clients/mirage/factories/location", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name() {
      return _faker.default.address.streetAddress();
    },

    latitude() {
      return _faker.default.address.latitude();
    },

    longitude() {
      return _faker.default.address.longitude();
    },

    address() {
      return _faker.default.address.streetAddress();
    },

    formatted_address() {
      return _faker.default.address.streetAddress();
    },

    city() {
      return _faker.default.address.city();
    },

    state() {
      return _faker.default.address.state();
    },

    postal_code() {
      return _faker.default.address.zipCode();
    },

    country() {
      return _faker.default.address.country();
    },

    timezone_id: 'America/Los_Angeles',
    temp: false
  });

  _exports.default = _default;
});