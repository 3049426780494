define("clients/components/order-flow/confirmation", ["exports", "clients/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    metrics: Ember.inject.service(),
    call_actions: {
      not_needed: 'The pilot doesn\'t need to speak with anyone',
      when_you_arrive: 'The pilot should call when they arrive on site',
      in_case_of_emergency: 'Only if pilot runs into issues on site'
    },
    isInsights: Ember.computed('model.client.is_insights_customer', function () {
      return this.model.client.is_insights_customer;
    }),
    watchers: Ember.computed('model.location.watchers', function () {
      return this.get('model.location.watchers').map(watcher => watcher.name).join(', ');
    }),

    init() {
      this._super(...arguments);

      return this.get('metrics').trackPage({
        hitType: 'pageview',
        page: '/order_flow/summary'
      });
    },

    callAction: Ember.computed('call_actions', 'model.mission.onsite_contact.call_action', function () {
      return this.call_actions[this.get('model.mission.onsite_contact.call_action')];
    }),
    showPackageDescription: Ember.computed('model.mission.package.{description,description_bullet_points.length}', function () {
      return this.get('model.mission.package.description') || this.get('model.mission.package.description_bullet_points.length') > 0;
    }),
    addonsForCreation: Ember.computed('model.{package_addons,selectedAddonIds}', function () {
      if (this.get('model.selectedAddonIds')) {
        return this.get('model.package_addons').filter(addon => {
          return this.get('model.selectedAddonIds').includes(addon.id);
        });
      } else {
        return [];
      }
    }),
    isClientUpload: Ember.computed.equal('model.orderFlow', 'clientUpload'),
    isAreaOutsideOfRange: Ember.computed('model.features.@each.areaOutsideOfRange', function () {
      return this.model.features.some(feature => feature.areaOutsideOfRange);
    }),
    totalPrice: Ember.computed('model.features.@each.price', 'model.mission.package.{hasCatalogItems,price}', function () {
      if (this.model.mission.package.hasCatalogItems) {
        return this.model.features.reduce((totalPrice, feature) => totalPrice + feature.price, 0);
      } else {
        return this.model.mission.package.price;
      }
    }),
    packageFeatures: Ember.computed('model.features', function () {
      return this.model.features.filter(feature => ['polygon', 'point_of_interest', 'panorama'].includes(feature.feature_type));
    }),
    isRestrictedAirspace: Ember.computed('airspace.restriction_level', function () {
      return this.get('airspace.restriction_level') !== 'not_restricted';
    }),
    actions: {
      placeOrder() {
        this.set('submitting', true);
        return this.placeOrderAction();
      }

    }
  });

  _exports.default = _default;
});