define("clients/templates/components/pano-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s2X4eBl5",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,1,[31,[\"pano\",[34,0,[\"access_key\"]]]]],[14,0,\"panorama\"],[15,\"data-hotspots\",[31,[[34,0,[\"hotspotsNames\"]]]]],[12],[2,\"\\n  \"],[10,\"noscript\"],[12],[2,\"ERROR: Javascript not activated\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"panorama\"]}",
    "meta": {
      "moduleName": "clients/templates/components/pano-viewer.hbs"
    }
  });

  _exports.default = _default;
});