define("clients/data/sample_missions/cre_images_package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": {
      "id": "DBM0997460780",
      "type": "missions",
      "attributes": {
        "instructions": "+ Scope: Aerial Images\n\n+ Time: Between 11am - 2pm\n\n+ Schedule: Within 48-72 hours; Weather permitting",
        "status": "complete",
        "client_id": "DBC3006554",
        "payment": {
          "id": null,
          "processor": null,
          "card": "Visa - 3969"
        },
        "external_assets_url": null,
        "owned_by_current_client": true,
        "contact_email": "clients@zeitview.com",
        "created_on": "2019-12-04T00:26:49Z",
        "is_pano_mission": false,
        "logo_url": null,
        "blurb": null,
        "paid": true,
        "paid_on": "2019-12-04T01:08:44Z",
        "price": 19900,
        "archive_url": null,
        "edit_url": null,
        "customer_instructions": "Weekend only. Fly between 5 - 6 pm",
        "scheduled_at_start": "2019-12-04T19:00:00Z",
        "scheduled_at_end": "2019-12-04T20:00:00Z",
        "parent_id": null,
        "reshoot_mission_id": null,
        "rejection_reason": null,
        "rejection_notes": null,
        "reference_id": "CRE Images Package Sample",
        "cloud_reqs": "less_than_50",
        "scheduling_requirement": "not_needed",
        "arcgis_feature_layer": null,
        "geo_properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-93.797324360852, 41.592635774054], [-93.796192876057, 41.592641791993], [-93.795340341, 41.592631762095], [-93.795517366795, 41.593504576408], [-93.796340397553, 41.593673076211], [-93.79694885159, 41.593669064316], [-93.796884478574, 41.593144397398], [-93.797324360852, 41.592635774054]]]
            },
            "properties": {
              "name": "Polygon #1",
              "notes": "",
              "number": 1,
              "area": "14354.3",
              "bounds": {
                "south": 41.592631762095,
                "west": -93.797324360852,
                "north": 41.593673076211,
                "east": -93.795340341
              },
              "center": {
                "lat": 41.593152419153,
                "lng": -93.796332350926
              }
            },
            "id": 1
          }]
        }
      },
      "relationships": {
        "location": {
          "data": {
            "id": "281730",
            "type": "locations"
          }
        },
        "package": {
          "data": {
            "id": "4554",
            "type": "packages"
          }
        },
        "credit_card": {
          "data": {
            "id": "4898",
            "type": "credit_cards"
          }
        },
        "onsite_contact": {
          "data": {
            "id": "49553",
            "type": "onsite_contacts"
          }
        },
        "scheduling_contact": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "images": {
          "data": [{
            "id": "DBI7868243802",
            "type": "images"
          }, {
            "id": "DBI9851503354",
            "type": "images"
          }, {
            "id": "DBI3373701272",
            "type": "images"
          }, {
            "id": "DBI9442565594",
            "type": "images"
          }, {
            "id": "DBI0585383129",
            "type": "images"
          }, {
            "id": "DBI8190229373",
            "type": "images"
          }, {
            "id": "DBI9790317860",
            "type": "images"
          }, {
            "id": "DBI0598249328",
            "type": "images"
          }, {
            "id": "DBI5435602622",
            "type": "images"
          }, {
            "id": "DBI6820766652",
            "type": "images"
          }, {
            "id": "DBI4207056700",
            "type": "images"
          }, {
            "id": "DBI4508217312",
            "type": "images"
          }, {
            "id": "DBI9693465294",
            "type": "images"
          }, {
            "id": "DBI1894158245",
            "type": "images"
          }, {
            "id": "DBI3207432501",
            "type": "images"
          }, {
            "id": "DBI1418906834",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        },
        "attachments": {
          "data": []
        },
        "ortho_photo_maps": {
          "data": []
        },
        "shares": {
          "data": [{
            "id": "c6aca1970a05ed08f24cd95a5180fd9f",
            "type": "shares"
          }, {
            "id": "70096d2ff89d8120238a8a3af5425083",
            "type": "shares"
          }]
        },
        "main_image": {
          "data": {
            "id": "DBI7868243802",
            "type": "images"
          }
        },
        "collaborators": {
          "data": []
        },
        "parent": {
          "data": null
        },
        "shots": {
          "data": [{
            "id": "1060741",
            "type": "shots"
          }, {
            "id": "1060740",
            "type": "shots"
          }, {
            "id": "1060739",
            "type": "shots"
          }]
        },
        "invoice_line_items": {
          "data": [{
            "id": "DBINV756684985225828",
            "type": "invoice_line_items"
          }]
        }
      }
    },
    "included": [{
      "id": "281730",
      "type": "locations",
      "attributes": {
        "name": "6400 Westown Pkwy, West Des Moines, IA 50266, USA",
        "latitude": 41.5933811,
        "longitude": -93.7962948,
        "address": "6400 Westown Parkway",
        "address2": "",
        "city": "West Des Moines",
        "state": "Iowa",
        "postal_code": "50266",
        "country": "United States",
        "formatted_address": "6400 Westown Pkwy, West Des Moines, IA 50266, USA",
        "timezone_id": "America/Chicago",
        "properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-93.797345818524, 41.592623738175], [-93.796102088361, 41.592607690333], [-93.795337658791, 41.592614820771], [-93.795528095631, 41.593488528785], [-93.79628159266, 41.59350959129], [-93.796326986508, 41.593675082158], [-93.796879114156, 41.593669064316], [-93.796908618455, 41.593242689551], [-93.797345818524, 41.592623738175]]]
            },
            "properties": {
              "name": "Polygon #1",
              "notes": "",
              "number": 1,
              "area": "14302.0",
              "bounds": {
                "south": 41.592607690333,
                "west": -93.797345818524,
                "north": 41.593675082158,
                "east": -93.795337658791
              },
              "center": {
                "lat": 41.593141386246,
                "lng": -93.796341738658
              }
            },
            "id": 1
          }]
        }
      }
    }, {
      "id": "4554",
      "type": "packages",
      "attributes": {
        "name": "Images Package",
        "description": "A set of high-res unedited images capturing the property at multiple angles and altitudes.   ",
        "price": 19900,
        "cloud_reqs": "less_than_50",
        "position": 2,
        "slug": "cre-images-pack"
      },
      "relationships": {
        "vertical": {
          "data": {
            "id": "1",
            "type": "verticals"
          }
        },
        "organization": {
          "data": null
        },
        "client": {
          "data": null
        },
        "catalog_items": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "verticals",
      "attributes": {
        "name": "Commercial Real Estate",
        "short_name": "CRE"
      }
    }, {
      "id": "4898",
      "type": "credit_cards",
      "attributes": {
        "brand": "Visa",
        "last_4": "3969",
        "exp_month": 2,
        "exp_year": 2024,
        "stripe_id": "card_1FW88bJo202eeWudwLnrPXPF"
      }
    }, {
      "id": "49553",
      "type": "onsite_contacts",
      "attributes": {
        "name": "John Smith",
        "call_action": "Call When You Arrive",
        "phone": "123-456-7890",
        "note": null
      }
    }, {
      "id": "DBC3006554",
      "type": "clients",
      "attributes": {
        "first_name": "Demo",
        "last_name": "Zeitview",
        "phone": "",
        "email": "clients@zeitview.com",
        "token": "qC65CqCmbKfMZvIU2gRb_uD7Sqw",
        "company_name": null,
        "invoiceable": false
      },
      "relationships": {
        "hidden_sample_missions": {
          "data": [{
            "id": "*",
            "type": "hidden_sample_mission"
          }]
        }
      }
    }, {
      "id": "DBI7868243802",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015272-bf0896c4d73e8e13a26f7acc52e7b3c5383cc6a1/original-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
        "name": "DBI-19-XpCBs4aQ-downloadable-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
        "source_type": "edit",
        "formatted_size": "7.39 MB",
        "size": 7747773,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.59275,
        "gps_longitude": -93.794780555556,
        "gps_altitude": 297.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015272-bf0896c4d73e8e13a26f7acc52e7b3c5383cc6a1/large_1920-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015272-bf0896c4d73e8e13a26f7acc52e7b3c5383cc6a1/medium_1024-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015272-bf0896c4d73e8e13a26f7acc52e7b3c5383cc6a1/small_640-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015272-bf0896c4d73e8e13a26f7acc52e7b3c5383cc6a1/four_three_640-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015272-bf0896c4d73e8e13a26f7acc52e7b3c5383cc6a1/square_640-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015272-bf0896c4d73e8e13a26f7acc52e7b3c5383cc6a1/downloadable-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG"
        },
        "created_on": "2019-12-04T00:32:03Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060739",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9851503354",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015271-d75f6be6629fe7bf16c4ec6a4a733fed3e491cf0/original-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
        "name": "DBI-18-B5K3IrK1-downloadable-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
        "source_type": "edit",
        "formatted_size": "7.09 MB",
        "size": 7430429,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593294444444,
        "gps_longitude": -93.794052777778,
        "gps_altitude": 295.7,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015271-d75f6be6629fe7bf16c4ec6a4a733fed3e491cf0/large_1920-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015271-d75f6be6629fe7bf16c4ec6a4a733fed3e491cf0/medium_1024-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015271-d75f6be6629fe7bf16c4ec6a4a733fed3e491cf0/small_640-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015271-d75f6be6629fe7bf16c4ec6a4a733fed3e491cf0/four_three_640-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015271-d75f6be6629fe7bf16c4ec6a4a733fed3e491cf0/square_640-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015271-d75f6be6629fe7bf16c4ec6a4a733fed3e491cf0/downloadable-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG"
        },
        "created_on": "2019-12-04T00:31:58Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060739",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3373701272",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015270-b598df222da4b3b7ed97a09900d9f0b447bcd8b1/original-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
        "name": "DBI-17-QZligIyp-downloadable-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
        "source_type": "edit",
        "formatted_size": "7.3 MB",
        "size": 7652996,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593497222222,
        "gps_longitude": -93.797836111111,
        "gps_altitude": 290.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015270-b598df222da4b3b7ed97a09900d9f0b447bcd8b1/large_1920-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015270-b598df222da4b3b7ed97a09900d9f0b447bcd8b1/medium_1024-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015270-b598df222da4b3b7ed97a09900d9f0b447bcd8b1/small_640-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015270-b598df222da4b3b7ed97a09900d9f0b447bcd8b1/four_three_640-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015270-b598df222da4b3b7ed97a09900d9f0b447bcd8b1/square_640-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015270-b598df222da4b3b7ed97a09900d9f0b447bcd8b1/downloadable-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG"
        },
        "created_on": "2019-12-04T00:31:55Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060739",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9442565594",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015269-ade1d3bfbf7d08e52ac5b5e3634c910481416454/original-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
        "name": "DBI-16-9iI8Bbsf-downloadable-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
        "source_type": "edit",
        "formatted_size": "6.91 MB",
        "size": 7245785,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593527777778,
        "gps_longitude": -93.797575,
        "gps_altitude": 274.2,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015269-ade1d3bfbf7d08e52ac5b5e3634c910481416454/large_1920-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015269-ade1d3bfbf7d08e52ac5b5e3634c910481416454/medium_1024-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015269-ade1d3bfbf7d08e52ac5b5e3634c910481416454/small_640-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015269-ade1d3bfbf7d08e52ac5b5e3634c910481416454/four_three_640-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015269-ade1d3bfbf7d08e52ac5b5e3634c910481416454/square_640-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015269-ade1d3bfbf7d08e52ac5b5e3634c910481416454/downloadable-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG"
        },
        "created_on": "2019-12-04T00:31:51Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060741",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0585383129",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015268-52bdf627be51481a4949d6346db954643e907ee4/original-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
        "name": "DBI-15-irJ5EBOl-downloadable-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
        "source_type": "edit",
        "formatted_size": "7.01 MB",
        "size": 7354035,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593836111111,
        "gps_longitude": -93.797358333333,
        "gps_altitude": 268.8,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015268-52bdf627be51481a4949d6346db954643e907ee4/large_1920-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015268-52bdf627be51481a4949d6346db954643e907ee4/medium_1024-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015268-52bdf627be51481a4949d6346db954643e907ee4/small_640-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015268-52bdf627be51481a4949d6346db954643e907ee4/four_three_640-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015268-52bdf627be51481a4949d6346db954643e907ee4/square_640-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015268-52bdf627be51481a4949d6346db954643e907ee4/downloadable-535ff7b4799b54f086ef13417e0bb269b3925519.JPG"
        },
        "created_on": "2019-12-04T00:31:48Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060741",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8190229373",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015265-f0e7df4031f62904e1860ec71501dde855788c63/original-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
        "name": "DBI-14-lNDw3KXx-downloadable-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
        "source_type": "edit",
        "formatted_size": "7.24 MB",
        "size": 7590756,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.594547222222,
        "gps_longitude": -93.797491666667,
        "gps_altitude": 268.9,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015265-f0e7df4031f62904e1860ec71501dde855788c63/large_1920-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015265-f0e7df4031f62904e1860ec71501dde855788c63/medium_1024-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015265-f0e7df4031f62904e1860ec71501dde855788c63/small_640-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015265-f0e7df4031f62904e1860ec71501dde855788c63/four_three_640-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015265-f0e7df4031f62904e1860ec71501dde855788c63/square_640-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015265-f0e7df4031f62904e1860ec71501dde855788c63/downloadable-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG"
        },
        "created_on": "2019-12-04T00:31:38Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060739",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9790317860",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015264-aad854d404dea388f7d580ece909e517d265e0ed/original-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
        "name": "DBI-13-eNmlRL9T-downloadable-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
        "source_type": "edit",
        "formatted_size": "6.59 MB",
        "size": 6910822,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.592494444444,
        "gps_longitude": -93.796258333333,
        "gps_altitude": 274,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015264-aad854d404dea388f7d580ece909e517d265e0ed/large_1920-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015264-aad854d404dea388f7d580ece909e517d265e0ed/medium_1024-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015264-aad854d404dea388f7d580ece909e517d265e0ed/small_640-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015264-aad854d404dea388f7d580ece909e517d265e0ed/four_three_640-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015264-aad854d404dea388f7d580ece909e517d265e0ed/square_640-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015264-aad854d404dea388f7d580ece909e517d265e0ed/downloadable-0632da6d3944561fa734289f437dad64c1c8be12.JPG"
        },
        "created_on": "2019-12-04T00:31:31Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060741",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0598249328",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015263-86aa1a3f7e895a6b8ba84aa383b13d817af7e187/original-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
        "name": "DBI-12-2IbnxuCI-downloadable-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
        "source_type": "edit",
        "formatted_size": "6.77 MB",
        "size": 7097909,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593319444444,
        "gps_longitude": -93.793633333333,
        "gps_altitude": 338,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015263-86aa1a3f7e895a6b8ba84aa383b13d817af7e187/large_1920-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015263-86aa1a3f7e895a6b8ba84aa383b13d817af7e187/medium_1024-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015263-86aa1a3f7e895a6b8ba84aa383b13d817af7e187/small_640-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015263-86aa1a3f7e895a6b8ba84aa383b13d817af7e187/four_three_640-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015263-86aa1a3f7e895a6b8ba84aa383b13d817af7e187/square_640-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015263-86aa1a3f7e895a6b8ba84aa383b13d817af7e187/downloadable-566c58054eb103640db99ce3c40d401d5e0d9646.JPG"
        },
        "created_on": "2019-12-04T00:31:22Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060740",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI5435602622",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015261-afba8b56119189935d99d69e9c982f5600ad14de/original-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
        "name": "DBI-11-zVY9u9aq-downloadable-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
        "source_type": "edit",
        "formatted_size": "7.2 MB",
        "size": 7546071,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.595555555556,
        "gps_longitude": -93.793655555556,
        "gps_altitude": 337.9,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015261-afba8b56119189935d99d69e9c982f5600ad14de/large_1920-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015261-afba8b56119189935d99d69e9c982f5600ad14de/medium_1024-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015261-afba8b56119189935d99d69e9c982f5600ad14de/small_640-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015261-afba8b56119189935d99d69e9c982f5600ad14de/four_three_640-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015261-afba8b56119189935d99d69e9c982f5600ad14de/square_640-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015261-afba8b56119189935d99d69e9c982f5600ad14de/downloadable-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG"
        },
        "created_on": "2019-12-04T00:31:19Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060740",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6820766652",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015260-a4fbb358d0d3331d2a5332871851cf089ddf6920/original-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
        "name": "DBI-10-PcBLVD5x-downloadable-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
        "source_type": "edit",
        "formatted_size": "7.09 MB",
        "size": 7429308,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.592686111111,
        "gps_longitude": -93.797641666667,
        "gps_altitude": 290.5,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015260-a4fbb358d0d3331d2a5332871851cf089ddf6920/large_1920-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015260-a4fbb358d0d3331d2a5332871851cf089ddf6920/medium_1024-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015260-a4fbb358d0d3331d2a5332871851cf089ddf6920/small_640-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015260-a4fbb358d0d3331d2a5332871851cf089ddf6920/four_three_640-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015260-a4fbb358d0d3331d2a5332871851cf089ddf6920/square_640-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015260-a4fbb358d0d3331d2a5332871851cf089ddf6920/downloadable-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG"
        },
        "created_on": "2019-12-04T00:31:16Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060739",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4207056700",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015259-cb56b2672919b36fbec5598784fdf9839f550f64/original-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
        "name": "DBI-9-b0AWSTJf-downloadable-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
        "source_type": "edit",
        "formatted_size": "7.37 MB",
        "size": 7725460,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.592311111111,
        "gps_longitude": -93.798858333333,
        "gps_altitude": 335.3,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015259-cb56b2672919b36fbec5598784fdf9839f550f64/large_1920-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015259-cb56b2672919b36fbec5598784fdf9839f550f64/medium_1024-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015259-cb56b2672919b36fbec5598784fdf9839f550f64/small_640-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015259-cb56b2672919b36fbec5598784fdf9839f550f64/four_three_640-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015259-cb56b2672919b36fbec5598784fdf9839f550f64/square_640-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015259-cb56b2672919b36fbec5598784fdf9839f550f64/downloadable-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG"
        },
        "created_on": "2019-12-04T00:31:13Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060740",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4508217312",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015258-f2fb4b316f85b261dd93007b0cc81de029ff001f/original-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
        "name": "DBI-8-oR7qxGxI-downloadable-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
        "source_type": "edit",
        "formatted_size": "6.77 MB",
        "size": 7094349,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593408333333,
        "gps_longitude": -93.798991666667,
        "gps_altitude": 336,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015258-f2fb4b316f85b261dd93007b0cc81de029ff001f/large_1920-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015258-f2fb4b316f85b261dd93007b0cc81de029ff001f/medium_1024-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015258-f2fb4b316f85b261dd93007b0cc81de029ff001f/small_640-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015258-f2fb4b316f85b261dd93007b0cc81de029ff001f/four_three_640-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015258-f2fb4b316f85b261dd93007b0cc81de029ff001f/square_640-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015258-f2fb4b316f85b261dd93007b0cc81de029ff001f/downloadable-0682793e7996851c1da4c81b5963f908c5c2a539.JPG"
        },
        "created_on": "2019-12-04T00:31:10Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060740",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9693465294",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015256-3e0c2e4f659accfd40926d19d757af07218668f0/original-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
        "name": "DBI-6-FU5Y7USx-downloadable-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
        "source_type": "edit",
        "formatted_size": "7.22 MB",
        "size": 7575328,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.596194444444,
        "gps_longitude": -93.796425,
        "gps_altitude": 338.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015256-3e0c2e4f659accfd40926d19d757af07218668f0/large_1920-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015256-3e0c2e4f659accfd40926d19d757af07218668f0/medium_1024-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015256-3e0c2e4f659accfd40926d19d757af07218668f0/small_640-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015256-3e0c2e4f659accfd40926d19d757af07218668f0/four_three_640-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015256-3e0c2e4f659accfd40926d19d757af07218668f0/square_640-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015256-3e0c2e4f659accfd40926d19d757af07218668f0/downloadable-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG"
        },
        "created_on": "2019-12-04T00:31:03Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060740",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI1894158245",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015255-d9ecb45d70389e5e170d030aa5e44a05ed1a914e/original-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
        "name": "DBI-5-Snj0eIkf-downloadable-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
        "source_type": "edit",
        "formatted_size": "6.73 MB",
        "size": 7060582,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593022222222,
        "gps_longitude": -93.798544444444,
        "gps_altitude": 338,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015255-d9ecb45d70389e5e170d030aa5e44a05ed1a914e/large_1920-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015255-d9ecb45d70389e5e170d030aa5e44a05ed1a914e/medium_1024-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015255-d9ecb45d70389e5e170d030aa5e44a05ed1a914e/small_640-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015255-d9ecb45d70389e5e170d030aa5e44a05ed1a914e/four_three_640-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015255-d9ecb45d70389e5e170d030aa5e44a05ed1a914e/square_640-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015255-d9ecb45d70389e5e170d030aa5e44a05ed1a914e/downloadable-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG"
        },
        "created_on": "2019-12-04T00:30:59Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060739",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3207432501",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015252-c9965a4e495716826f552bbc06ec1c0604ce551b/original-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
        "name": "DBI-3-sGVG93vy-downloadable-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
        "source_type": "edit",
        "formatted_size": "6.86 MB",
        "size": 7190165,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.595311111111,
        "gps_longitude": -93.798283333333,
        "gps_altitude": 356.8,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015252-c9965a4e495716826f552bbc06ec1c0604ce551b/large_1920-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015252-c9965a4e495716826f552bbc06ec1c0604ce551b/medium_1024-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015252-c9965a4e495716826f552bbc06ec1c0604ce551b/small_640-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015252-c9965a4e495716826f552bbc06ec1c0604ce551b/four_three_640-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015252-c9965a4e495716826f552bbc06ec1c0604ce551b/square_640-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015252-c9965a4e495716826f552bbc06ec1c0604ce551b/downloadable-2f061394182882b945975bdb8fa14d5f936112ca.JPG"
        },
        "created_on": "2019-12-04T00:30:54Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060740",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI1418906834",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015250-353958fc7f50243aec4d8cb4202576cdb44ef603/original-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
        "name": "DBI-1-YnsqlImr-downloadable-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
        "source_type": "edit",
        "formatted_size": "6.99 MB",
        "size": 7328091,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": 41.593958333333,
        "gps_longitude": -93.797041666667,
        "gps_altitude": 254.3,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015250-353958fc7f50243aec4d8cb4202576cdb44ef603/large_1920-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015250-353958fc7f50243aec4d8cb4202576cdb44ef603/medium_1024-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015250-353958fc7f50243aec4d8cb4202576cdb44ef603/small_640-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015250-353958fc7f50243aec4d8cb4202576cdb44ef603/four_three_640-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015250-353958fc7f50243aec4d8cb4202576cdb44ef603/square_640-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015250-353958fc7f50243aec4d8cb4202576cdb44ef603/downloadable-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG"
        },
        "created_on": "2019-12-04T00:30:50Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060741",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "c6aca1970a05ed08f24cd95a5180fd9f",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-13T07:50:10Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "70096d2ff89d8120238a8a3af5425083",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-04T23:44:46Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "1060741",
      "type": "shots",
      "attributes": {
        "instructions": "(3) images of the front of building (straight on, angled left, angled right) from just below roof level with the *full building in frame.* Images with little to no roof in frame are a big point of emphasis our customers. If you need to adjust, back away from the property rather than increasing altitude *so that we don\u2019t see the top of the roof.*",
        "mission_id": "278123",
        "shot_type_id": 17,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Image - Front Close Up (full building in frame)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "17",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI0585383129",
            "type": "images"
          }, {
            "id": "DBI9442565594",
            "type": "images"
          }, {
            "id": "DBI1418906834",
            "type": "images"
          }, {
            "id": "DBI9790317860",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "17",
      "type": "shot_types",
      "attributes": {
        "name": "Image - Front Close Up (full building in frame)",
        "description": "* *If there are power lines do your best to avoid having them in frame. It is okay to fly higher or lower than roof level to avoid the power lines in the images.* ",
        "video": null,
        "slug": "front_shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "14",
            "type": "shot_type_sample_images"
          }, {
            "id": "15",
            "type": "shot_type_sample_images"
          }, {
            "id": "16",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1060740",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "278123",
        "shot_type_id": 24,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Images - Cardinal Directions (350 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "24",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI5435602622",
            "type": "images"
          }, {
            "id": "DBI9693465294",
            "type": "images"
          }, {
            "id": "DBI4508217312",
            "type": "images"
          }, {
            "id": "DBI0598249328",
            "type": "images"
          }, {
            "id": "DBI3207432501",
            "type": "images"
          }, {
            "id": "DBI4207056700",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "24",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (350 ft above ground level)",
        "description": "*If there are altitude restrictions in this airspace, please take these images at the highest allowable altitude instead of 350'. *",
        "video": null,
        "slug": "images-cardinal-directions-350-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "23",
            "type": "shot_type_sample_images"
          }, {
            "id": "24",
            "type": "shot_type_sample_images"
          }, {
            "id": "25",
            "type": "shot_type_sample_images"
          }, {
            "id": "26",
            "type": "shot_type_sample_images"
          }, {
            "id": "27",
            "type": "shot_type_sample_images"
          }, {
            "id": "28",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1060739",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "278123",
        "shot_type_id": 13,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "ad_hoc",
        "name": "Images - Cardinal Directions (100 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "13",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI1894158245",
            "type": "images"
          }, {
            "id": "DBI6820766652",
            "type": "images"
          }, {
            "id": "DBI7868243802",
            "type": "images"
          }, {
            "id": "DBI3373701272",
            "type": "images"
          }, {
            "id": "DBI9851503354",
            "type": "images"
          }, {
            "id": "DBI8190229373",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "13",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (100 ft above ground level)",
        "description": "",
        "video": "",
        "slug": "images-cardinal-directions-100-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "17",
            "type": "shot_type_sample_images"
          }, {
            "id": "18",
            "type": "shot_type_sample_images"
          }, {
            "id": "19",
            "type": "shot_type_sample_images"
          }, {
            "id": "20",
            "type": "shot_type_sample_images"
          }, {
            "id": "21",
            "type": "shot_type_sample_images"
          }, {
            "id": "22",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "DBINV756684985225828",
      "type": "invoice_line_items",
      "attributes": {
        "reason": "base_package_price",
        "description": null,
        "amount": 19900,
        "created_on": "2019-12-04T00:26:49Z",
        "display_name": "Base Package Price"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM0997460780",
            "type": "missions"
          }
        },
        "work_item": {
          "data": null
        }
      }
    }]
  };
  _exports.default = _default;
});