define("clients/routes/charts", ["exports", "clients/mixins/inner-application-route-setup-controller-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _innerApplicationRouteSetupControllerMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ChartsRoute;
  ChartsRoute = Ember.Route.extend(_authenticatedRouteMixin.default, _innerApplicationRouteSetupControllerMixin.default, {
    sessionAccount: Ember.inject.service(),
    statsService: Ember.inject.service(),
    startTime: moment().utc().subtract(30, 'days').format('YYYY-MM-DD HH:mm').toString(),
    endTime: moment().utc().format('YYYY-MM-DD HH:mm').toString(),
    show_org_missions: false,
    model: function (params) {
      var chartParams;
      chartParams = {
        date_start: this.get('startTime'),
        date_end: this.get('endTime'),
        show_org_missions: this.get('show_org_missions')
      };
      return Ember.RSVP.hash({
        orders: this.get('statsService').orders(chartParams),
        flights: this.get('statsService').flights(chartParams),
        cancels: this.get('statsService').cancels(chartParams),
        packages: this.get('statsService').packages(chartParams),
        metaInformation: this.get('statsService').metaInformation({
          show_org_missions: this.get('show_org_missions')
        }),
        client: this.sessionAccount.account,
        show_org_missions: this.get('show_org_missions'),
        clientCancellations: this.get('statsService').cancelReasons(chartParams)
      });
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      this.controllerFor('application').set('showQuickOrder', true);
      this.controllerFor('charts').set('statsLoaded', true);
      return this.get('sessionAccount.account').then(function (_this) {
        return function (client) {
          _this.controllerFor('application').set('showInsightsBanner', client.get('feature_flags.show_insights_banner'));

          _this.controllerFor('application').set('clientHasComposerReports', client.get('has_composer_reports'));

          return _this.controllerFor('application').set('canManageInsightsUsers', _this.sessionAccount.canManageInsightsUsers);
        };
      }(this));
    },
    actions: {
      refreshModel: function (timeParams) {
        if (timeParams.startDate) {
          this.set('startTime', timeParams.startDate.format('YYYY-MM-DD HH:mm').toString());
        } else {
          this.set('startTime', null);
        }

        this.set('endTime', timeParams.endDate.format('YYYY-MM-DD HH:mm').toString());
        return this.refresh();
      },
      toggleShowOrgMissions: function () {
        this.set('show_org_missions', !this.get('show_org_missions'));
        return this.refresh();
      }
    }
  });
  var _default = ChartsRoute;
  _exports.default = _default;
});