define("clients/models/credit-card", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    provider_token: (0, _model.attr)('string'),
    brand: (0, _model.attr)('string'),
    last_4: (0, _model.attr)('string'),
    exp_month: (0, _model.attr)('number'),
    exp_year: (0, _model.attr)('number'),
    stripe_id: (0, _model.attr)('string')
  });

  _exports.default = _default;
});