define("clients/controllers/account/edit", ["exports", "clients/validations/client", "ember-changeset-validations", "ember-changeset"], function (_exports, _client, _emberChangesetValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    saveState: 'success',
    modelObserver: Ember.observer('model', function () {
      if (this.get('model')) {
        const changeset = new _emberChangeset.default(this.get('model'), (0, _emberChangesetValidations.default)(_client.default), _client.default);
        return this.set('changeset', changeset);
      }
    }),
    arcgisLink: Ember.computed('model.esri_account.username', function () {
      if (this.get('model.esri_account.username')) {
        return this.get('model.esri_account.baseArcgisUrl') + "/home/index.html";
      }
    }),
    actions: {
      toggleEditing: function () {
        this.set('isEditing', !this.get('isEditing'));
        return false;
      },
      saveAccount: function () {
        this.changeset.validate().then(() => {
          if (this.changeset.get('isInvalid')) {
            this.set('saveState', 'error');
            Ember.run.later(() => this.set('saveState', 'initial'), 500);
          } else {
            this.set('saveState', 'inProgress');
            this.changeset.save().then(() => {
              this.set('session.data.authenticated.email', this.changeset.get('email'));
              this.get('session').set('data.email', this.changeset.get('email'));
              Ember.run.later(() => {
                this.set('saveState', 'success');
                Ember.run.later(() => this.set('saveState', 'initial'), 500);
              }, 500);
            }).catch(errors => {
              this.get('model').rollbackAttributes();
              Ember.run.later(() => {
                errors.errors.forEach(error => {
                  const pointer = error.source.pointer.split('/');
                  const key = pointer[pointer.length - 1];
                  this.changeset.addError(key, {
                    value: this.changeset.get(key),
                    validation: [error.detail]
                  });
                });
                this.set('saveState', 'error');
                Ember.run.later(() => this.set('saveState', 'initial'), 500);
              }, 500);
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});