define("clients/components/mission-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mission-list-item-container'],
    isChecked: Ember.computed('mission', 'selectedMissions.[]', function () {
      return this.selectedMissions.includes(this.get('mission'));
    }),
    missionAddressSecondLine: Ember.computed('mission.location.{address,formatted_address,name}', function () {
      let address = this.get('mission.location.formatted_address') || this.get('mission.location.name') || this.get('mission.location.address');

      if (address) {
        let addressComponents = address.split(',');
        addressComponents.shift();
        return addressComponents.join();
      } else {
        return '';
      }
    }),
    roofConditionWorstValue: Ember.computed('exists', 'mission.composer_report.sections.length', function () {
      if (this.get('mission.composer_report.sections.length') > 0) {
        return Math.min(...this.get('mission.composer_report.sections').getEach('roof_condition_value').filter(this.exists));
      } else {
        return null;
      }
    }),
    roofConditionIconShown: Ember.computed('mission.invoiced', 'roofConditionWorstValue', function () {
      return this.get('roofConditionWorstValue') != null && this.get('mission.invoiced');
    }),
    roofConditionString: Ember.computed('mission.{has_roof_report_deliverable,invoiced}', 'roofConditionWorstValue', function () {
      if (this.get('mission.has_roof_report_deliverable')) {
        if (this.get('mission.invoiced')) {
          switch (this.get('roofConditionWorstValue')) {
            case 0:
              return 'Below average';

            case 1:
              return 'Average';

            case 2:
              return 'Above average';

            default:
              return 'N/A';
          }
        } else {
          return '-';
        }
      } else {
        return '-';
      }
    }),
    showLinkToInsightsPage: Ember.computed('firstMissionWithoutReport', 'mission', function () {
      return this.get('firstMissionWithoutReport') == this.get('mission');
    }),
    exists: function (item) {
      return item != null && item != undefined;
    },
    actions: {
      toggleExpanded: function () {
        this.set('expanded', !this.get('expanded'));
      },
      toggleShowAll: function () {
        this.set('showAll', !this.get('showAll'));
        return '';
      },
      showMission: function () {
        this.action(this.get('mission'));
      },
      pay: function () {
        this.payAction(this.get('mission'));
      },
      hideSampleMission: function () {
        this.hideSampleMission(this.get('mission'));
      },
      toggleMission: function (checked) {
        this.toggleMissionAction(this.get('mission'), checked);
      },
      onCheckboxRightClick: function (event) {
        event.preventDefault();

        if (!this.isChecked) {
          this.unselectAllAction();
        }
      },
      openContextMenu: function (mission, event) {
        this.openContextMenuAction(mission, event);
      }
    }
  });

  _exports.default = _default;
});