define("clients/components/order-flow/confirmation-feature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['confirmation-feature-container'],
    catalogItemsEnabled: Ember.computed.and('package.catalog_items.length', 'feature.catalogEnabled'),
    catalogItems: Ember.computed('feature.catalog_item_ids', 'package.catalog_items', function () {
      return this.package.catalog_items.filter(item => {
        return this.feature.catalog_item_ids && this.feature.catalog_item_ids.includes(item.id);
      });
    }),
    panoDeliverableAttributes: Ember.computed('package.panoramaShotType.id', 'feature.shot_type_attributes', function () {
      return this.feature.shot_type_attributes[this.get('package.panoramaShotType.id')];
    }),
    featureType: Ember.computed('feature.object', 'feature_type', function () {
      if (this.feature.object.getGeometry().getType() === 'Polygon') {
        return 'polygon';
      } else if (this.feature_type === 'panorama') {
        return 'panorama';
      } else {
        return 'unknown';
      }
    })
  });

  _exports.default = _default;
});