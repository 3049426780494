define("clients/templates/components/charts/flights-canceled-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FIY/hmlJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"chart-container\"],[14,1,\"canceled-chart\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "clients/templates/components/charts/flights-canceled-chart.hbs"
    }
  });

  _exports.default = _default;
});