define("clients/components/order-flow/package-selection-list", ["exports", "swiper"], function (_exports, _swiper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["package-selection"],
    swiper: null,
    metrics: Ember.inject.service(),
    trackingId: Ember.computed('client.id', function () {
      if (this.get('client.id')) {
        return Number(this.get('client.id').substring(3));
      } else {
        return 'loggedout';
      }
    }),
    didInsertElement: function () {
      _swiper.default.use([_swiper.Navigation, _swiper.Pagination]);

      this.set('swiper', new _swiper.default('.swiper-container', {
        pagination: {
          clickable: true,
          el: '.swiper-pagination',
          type: 'bullets'
        },
        simulateTouch: false,
        autoHeight: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }));
      const slide = this.packages.indexOf(this.selectedItem) / 4;
      document.getElementsByClassName('swiper-pagination-bullet')[parseInt(slide, 10)].click();
    },
    packageSlides: Ember.computed('packages', function () {
      const packages = this.packages;
      const size = 4;
      return Array.from({
        length: Math.ceil(packages.length / size)
      }, (v, i) => packages.slice(i * size, i * size + size));
    }),
    actions: {
      selectPackage: function (newPackage) {
        this.selectPackageAction(newPackage);
      },
      clickNavButton: function () {
        this.metrics.trackEvent({
          event: 'event',
          category: 'orderflow',
          action: 'click-pkg-carousel',
          value: this.trackingId
        });
        var activeSlide = document.getElementsByClassName('swiper-pagination-bullet-active')[0].getAttribute('aria-label').split('slide ')[1];
        document.getElementsByClassName('swiper-pagination-bullet')[activeSlide - 1].click();
        return false;
      }
    }
  });

  _exports.default = _default;
});