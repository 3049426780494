define("clients/adapters/scheduling-contact", ["exports", "clients/adapters/application", "clients/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + '/' + _environment.default.api.namespace + '/clients',
    urlTemplate: '{+namespace}/missions/{missionId}/scheduling_contacts/{id}',
    urlSegments: {
      missionId: function (type, id, snapshot, query) {
        if (query == undefined) {
          return snapshot.belongsTo('mission', {
            id: true
          });
        } else {
          return query.missionId;
        }
      }
    }
  });

  _exports.default = _default;
});