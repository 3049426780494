define("clients/helpers/DBMap/format-area", ["exports", "clients/helpers/DBMap/meters-to-acres", "clients/helpers/DBMap/meters-to-feet-sqr"], function (_exports, _metersToAcres, _metersToFeetSqr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatArea = _exports.default = void 0;

  const formatArea = function () {
    const area = parseFloat(arguments.length <= 0 ? undefined : arguments[0]);

    if (area != null && typeof area != 'undefined' && !isNaN(area)) {
      return `${(0, _metersToAcres.metersToAcres)(area)} (${(0, _metersToFeetSqr.metersToFeetSqr)(area)})`;
    } else {
      return '';
    }
  };

  _exports.formatArea = formatArea;

  var _default = Ember.Helper.helper(formatArea);

  _exports.default = _default;
});