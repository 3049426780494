define("clients/components/mission-view/mission-details-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notClientUpload: Ember.computed('mission.package.order_flow', function () {
      return this.get('mission.package.order_flow') == 'standard';
    }),
    belowAverageFeatures: Ember.computed('mission.composer_report.sections', function () {
      return this.findFeatures(0);
    }),
    averageFeatures: Ember.computed('mission.composer_report.sections', function () {
      return this.findFeatures(1);
    }),
    aboveAverageFeatures: Ember.computed('mission.composer_report.sections', function () {
      return this.findFeatures(2);
    }),
    showRoofConditions: Ember.computed.and('mission.has_roof_report_deliverable', 'mission.invoiced'),
    findFeatures: function (val) {
      if (this.get('mission.composer_report.sections.length')) {
        return this.get('mission.composer_report.sections').getEach('roof_condition_value').filter(function (_val) {
          return _val == val;
        });
      } else {
        return [];
      }
    }
  });

  _exports.default = _default;
});