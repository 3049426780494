define("clients/components/mission-list", ["exports", "clients/data/sample_mission_list", "clients/config/environment", "jquery"], function (_exports, _sample_mission_list, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MissionListComponent = Ember.Component.extend({
    sample_mission_list: _sample_mission_list.default,
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    cursorService: Ember.inject.service(),
    upcomingMissions: ['created', 'scheduling_call', 'confirmed', 'pilots_notified', 'pilot_accepted'],
    inProgressMissions: ['pilot_accepted', 'flight_complete', 'assets_uploaded', 'processing_shots', 'assets_classified', 'in_production'],
    completedMissions: ['invoice_needed', 'invoiced', 'awaiting_payment', 'complete'],
    showContextMenu: false,
    contextMenuTop: 0,
    contextMenuLeft: 0,

    onClickBody() {
      this.set('showContextMenu', false);
    },

    init() {
      this._super(...arguments);

      this.set('onClickBodyEvent', this.onClickBody.bind(this));
      this.get('sessionAccount.account').then(account => {
        if (!account.get('has_composer_reports')) {
          this.set('firstMissionWithoutReport', this.get('missions').firstObject);
        }
      });
    },

    didInsertElement() {
      this._super(...arguments);

      document.addEventListener('click', this.onClickBodyEvent);
    },

    willDestroyElement() {
      this._super(...arguments);

      document.removeEventListener('click', this.onClickBodyEvent);
    },

    isAllSelected: Ember.computed('missions.[]', 'selectedMissions.[]', function () {
      return this.missions.length !== 0 && this.missions.every(mission => this.selectedMissions.indexOf(mission) !== -1);
    }),
    isIndeterminated: Ember.computed('isAllSelected', 'missions', 'selectedMissions.[]', function () {
      return !this.isAllSelected && this.missions.any(mission => this.selectedMissions.indexOf(mission) !== -1);
    }),
    groupByLocation: Ember.computed('sort_attribute', function () {
      return !(this.sort_attribute && this.sort_attribute !== 'location');
    }),
    locationGroups: Ember.computed('missions.[]', 'sort_direction', function () {
      const locations = this.missions.map(mission => {
        return mission.get('location');
      }).uniqBy('formatted_address').sortBy('formatted_address');

      if (this.sort_direction === 'desc') {
        return locations;
      } else {
        return locations.reverse();
      }
    }),
    sampleMissionList: Ember.computed('client.hidden_sample_missions.[]', 'completedMissions', 'currentGroup', 'inProgressMissions', 'missionFilter', 'page', 'sample_mission_list', 'totalPages', 'upcomingMissions', function () {
      let status = [];

      if (this.missionFilter === 'upcoming') {
        status = this.upcomingMissions;
      }

      if (this.missionFilter === 'inProgress') {
        status = this.inProgressMissions;
      }

      if (this.missionFilter === 'completed') {
        status = this.completedMissions;
      }

      if (this.missionFilter === 'canceled') {
        status = ['canceled'];
      }

      if ((this.totalPages === parseInt(this.page, 10) || this.totalPages === 0) && Ember.isEmpty(this.currentGroup)) {
        const hiddenMissionList = [];

        if (Ember.isPresent(this.get('client.hidden_sample_missions'))) {
          this.get('client.hidden_sample_missions').toArray().forEach(hiddenSampleMission => hiddenMissionList.push(hiddenSampleMission.get('id')));

          if (hiddenMissionList.includes('*')) {
            return [];
          }
        }

        const list = [];
        Object.keys(this.sample_mission_list).forEach(key => {
          if (Ember.isEmpty(hiddenMissionList) || !hiddenMissionList.includes(key)) {
            const missionData = this.sample_mission_list[key];
            const {
              id
            } = missionData.data;
            let mission = this.store.peekRecord('mission', id);

            if (!mission) {
              this.store.pushPayload(missionData);
              mission = this.store.peekRecord('mission', id);
            }

            mission.set('sampleMissionId', key);

            if (this.missionFilter === 'all' || status.includes(mission.get('status'))) {
              return list.push(mission);
            }
          }
        });
        return list;
      } else {
        return [];
      }
    }),
    filteredGroups: Ember.computed('groups', 'hideEmptyGroups', function () {
      let scope = this.groups;

      if (this.hideEmptyGroups) {
        scope = scope.filter(g => {
          return g.get('missionsCount') !== 0;
        });
      }

      return scope;
    }),
    actions: {
      setDateColumn(val, sort_attribute) {
        const hash = {
          'showDateOptions': false
        };

        if (this.current_date_column !== val) {
          hash.current_date_column = val;
          hash.sort_attribute = sort_attribute;
          hash.sort_direction = 'desc';
          hash.page = 1;
        }

        return this.setProperties(hash);
      },

      setSortAttribute(attribute, direction) {
        return this.setProperties({
          'sort_attribute': attribute,
          'sort_direction': direction,
          'page': 1
        });
      },

      toggleSortDirection() {
        if (this.sort_direction === 'asc') {
          this.set('sort_direction', 'desc');
        } else {
          this.set('sort_direction', 'asc');
        }

        return this.sort_direction;
      },

      showDateOptions(show) {
        return this.set('showDateOptions', show);
      },

      showMission(mission) {
        return this.action(mission);
      },

      pay(mission) {
        return this.payAction(mission);
      },

      hideSampleMission(mission) {
        const id = mission.get('sampleMissionId');
        mission.deleteRecord();
        return _jquery.default.ajax({
          url: `${_environment.default.api.host}/v3/clients/hidden_sample_missions`,
          type: 'POST',
          dataType: 'json',
          headers: this.get('sessionAccount.headers'),
          data: {
            id
          }
        }).then(response => {
          this.store.pushPayload(response);
          const sample = this.store.peekRecord('hidden_sample_mission', response.data.id);
          const sampleMissions = this.get('client.hidden_sample_missions');
          sampleMissions.pushObject(sample);
          return this.set('client.hidden_sample_missions', sampleMissions);
        });
      },

      goToGroup(group) {
        this.set('currentGroup', group);
        this.set('selectedMissions', []);
      },

      goToMissions() {
        this.set('currentGroup', null);
        this.set('selectedMissions', []);
      },

      toggleMission(mission, checked) {
        if (checked) {
          this.selectedMissions.addObject(mission);
        } else {
          this.selectedMissions.removeObject(mission);
        }
      },

      toggleAllMission(event) {
        event.target.checked = false;

        if (this.isIndeterminated || this.isAllSelected) {
          this.selectedMissions.removeObjects(this.missions);
        } else {
          this.selectedMissions.addObjects(this.missions);
        }
      },

      unselectAll() {
        this.selectedMissions.removeObjects(this.missions);
        this.set('showContextMenu', false);
      },

      openContextMenu(mission, event) {
        const cursor = this.cursorService.position(this.element, event);

        if (!this.selectedMissions.includes(mission)) {
          this.set('selectedMissions', [mission]);
        }

        this.setProperties({
          showContextMenu: true,
          contextMenuLeft: cursor.canvasX + 20,
          contextMenuTop: cursor.canvasY + 215
        });
        event.preventDefault();
        return false;
      },

      showMoveToModal() {
        this.showMoveToModalAction();
      },

      showRemoveFromModal() {
        this.showRemoveFromModalAction();
      },

      showRenameGroupModal(group) {
        this.showRenameGroupModalAction(group);
      },

      showRemoveGroupModal(group) {
        this.showRemoveGroupModalAction(group);
      }

    }
  });
  var _default = MissionListComponent;
  _exports.default = _default;
});