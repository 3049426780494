define("clients/data/sample_missions/cre_images_shopping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": {
      "id": "DBM5297589249",
      "type": "missions",
      "attributes": {
        "instructions": "* Scope: Aerial Images\n* Time: Between 11am - 2pm\n* Schedule: Within 48-72 hours; Weather permitting",
        "status": "complete",
        "client_id": "DBC3006554",
        "payment": {
          "id": null,
          "processor": null,
          "card": "Visa - 3969"
        },
        "external_assets_url": null,
        "owned_by_current_client": true,
        "contact_email": "clients@zeitview.com",
        "created_on": "2019-12-04T01:47:22Z",
        "is_pano_mission": false,
        "logo_url": null,
        "blurb": null,
        "paid": true,
        "paid_on": "2019-12-04T01:56:45Z",
        "price": 21900,
        "archive_url": null,
        "edit_url": null,
        "customer_instructions": null,
        "scheduled_at_start": "2019-12-04T18:00:00Z",
        "scheduled_at_end": "2019-12-04T19:00:00Z",
        "parent_id": null,
        "reshoot_mission_id": null,
        "rejection_reason": null,
        "rejection_notes": null,
        "reference_id": "CRE Images Package - Shopping Centers Sample",
        "cloud_reqs": "less_than_50",
        "scheduling_requirement": "not_needed",
        "arcgis_feature_layer": null,
        "geo_properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-84.686019376196, 34.03677565074], [-84.683048303427, 34.036277766967], [-84.683187778296, 34.037811291864], [-84.68595500318, 34.037713494691], [-84.686019376196, 34.03677565074]]]
            },
            "properties": {
              "name": "Polygon #1",
              "notes": "",
              "number": 1,
              "area": "36330.0",
              "bounds": {
                "south": 34.036277766967,
                "west": -84.686019376196,
                "north": 34.037811291864,
                "east": -84.683048303427
              },
              "center": {
                "lat": 34.037044529416,
                "lng": -84.684533839812
              }
            },
            "id": 1
          }]
        }
      },
      "relationships": {
        "location": {
          "data": {
            "id": "284946",
            "type": "locations"
          }
        },
        "package": {
          "data": {
            "id": "19161",
            "type": "packages"
          }
        },
        "credit_card": {
          "data": {
            "id": "4898",
            "type": "credit_cards"
          }
        },
        "onsite_contact": {
          "data": {
            "id": "49556",
            "type": "onsite_contacts"
          }
        },
        "scheduling_contact": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "images": {
          "data": [{
            "id": "DBI2317149084",
            "type": "images"
          }, {
            "id": "DBI3451367597",
            "type": "images"
          }, {
            "id": "DBI2330296799",
            "type": "images"
          }, {
            "id": "DBI4505460324",
            "type": "images"
          }, {
            "id": "DBI7421574683",
            "type": "images"
          }, {
            "id": "DBI3224295262",
            "type": "images"
          }, {
            "id": "DBI8992966358",
            "type": "images"
          }, {
            "id": "DBI7921509806",
            "type": "images"
          }, {
            "id": "DBI0836929217",
            "type": "images"
          }, {
            "id": "DBI2133703089",
            "type": "images"
          }, {
            "id": "DBI4628517795",
            "type": "images"
          }, {
            "id": "DBI9592283108",
            "type": "images"
          }, {
            "id": "DBI8882101535",
            "type": "images"
          }, {
            "id": "DBI2691805426",
            "type": "images"
          }, {
            "id": "DBI7984291648",
            "type": "images"
          }, {
            "id": "DBI6868906804",
            "type": "images"
          }, {
            "id": "DBI5778387274",
            "type": "images"
          }, {
            "id": "DBI9148706485",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        },
        "attachments": {
          "data": []
        },
        "ortho_photo_maps": {
          "data": []
        },
        "shares": {
          "data": [{
            "id": "7fdb472d2ebad2658d4e4d1eb4206c63",
            "type": "shares"
          }]
        },
        "main_image": {
          "data": {
            "id": "DBI2317149084",
            "type": "images"
          }
        },
        "collaborators": {
          "data": []
        },
        "parent": {
          "data": null
        },
        "shots": {
          "data": [{
            "id": "1060813",
            "type": "shots"
          }, {
            "id": "1060812",
            "type": "shots"
          }, {
            "id": "1060811",
            "type": "shots"
          }, {
            "id": "1060810",
            "type": "shots"
          }, {
            "id": "1060809",
            "type": "shots"
          }]
        },
        "invoice_line_items": {
          "data": [{
            "id": "DBINV469671178485113",
            "type": "invoice_line_items"
          }]
        }
      }
    },
    "included": [{
      "id": "284946",
      "type": "locations",
      "attributes": {
        "name": "3384 Cobb Pkwy NW, Acworth, GA 30101, USA",
        "latitude": 34.0368356,
        "longitude": -84.6849469,
        "address": "3384 Cobb Parkway Northwest",
        "address2": "",
        "city": "Acworth",
        "state": "Georgia",
        "postal_code": "30101",
        "country": "United States",
        "formatted_address": "3384 Cobb Pkwy NW, Acworth, GA 30101, USA",
        "timezone_id": "America/New_York",
        "properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-84.686266139426, 34.03691790271], [-84.683874423804, 34.036428910564], [-84.68361156732, 34.037123469444], [-84.683134134116, 34.037231237951], [-84.683134134116, 34.037820182511], [-84.686073020377, 34.037882417011], [-84.686266139426, 34.03691790271]]]
            },
            "properties": {
              "name": "Polygon #1",
              "notes": "",
              "number": 1,
              "area": "34241.1",
              "bounds": {
                "south": 34.036428910564,
                "west": -84.686266139426,
                "north": 34.037882417011,
                "east": -84.683134134116
              },
              "center": {
                "lat": 34.037155663787,
                "lng": -84.684700136771
              }
            },
            "id": 1
          }]
        }
      }
    }, {
      "id": "19161",
      "type": "packages",
      "attributes": {
        "name": "Images Package - Shopping Centers (Up to 400,000 sq ft)",
        "description": "A set of high-res unedited images capturing your Shopping Center, its storefronts, and the surrounding area.",
        "price": 21900,
        "cloud_reqs": "less_than_50",
        "position": 6,
        "slug": "cre-images-pack-shopping-center"
      },
      "relationships": {
        "vertical": {
          "data": {
            "id": "1",
            "type": "verticals"
          }
        },
        "organization": {
          "data": null
        },
        "client": {
          "data": null
        },
        "catalog_items": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "verticals",
      "attributes": {
        "name": "Commercial Real Estate",
        "short_name": "CRE"
      }
    }, {
      "id": "4898",
      "type": "credit_cards",
      "attributes": {
        "brand": "Visa",
        "last_4": "3969",
        "exp_month": 2,
        "exp_year": 2024,
        "stripe_id": "card_1FW88bJo202eeWudwLnrPXPF"
      }
    }, {
      "id": "49556",
      "type": "onsite_contacts",
      "attributes": {
        "name": "John Smith",
        "call_action": "Call When You Arrive",
        "phone": "123-456-7890",
        "note": null
      }
    }, {
      "id": "DBC3006554",
      "type": "clients",
      "attributes": {
        "first_name": "Demo",
        "last_name": "Zeitview",
        "phone": "",
        "email": "clients@zeitview.com",
        "token": "qC65CqCmbKfMZvIU2gRb_uD7Sqw",
        "company_name": null,
        "invoiceable": false
      },
      "relationships": {
        "hidden_sample_missions": {
          "data": [{
            "id": "*",
            "type": "hidden_sample_mission"
          }]
        }
      }
    }, {
      "id": "DBI2317149084",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034035-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/original-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
        "name": "DBI-32-gK0ObgUy-CRE-Images-Package---Shopping-Centers-Sample_DBI-50-paYmqqka-Lakeside-Marketplace_DBU91-DJI_0074.jpeg",
        "source_type": "edit",
        "formatted_size": "2.46 MB",
        "size": 2579540,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034035-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/large_1920-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034035-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/medium_1024-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034035-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/small_640-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034035-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/four_three_640-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034035-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/square_640-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034035-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/downloadable-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg"
        },
        "created_on": "2019-12-06T00:35:33Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060809",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3451367597",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034034-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/original-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
        "name": "DBI-31-8hFSEJCS-CRE-Images-Package---Shopping-Centers-Sample_DBI-51-n6hpnhi5-Lakeside-Marketplace_DBU92-DJI_0075.jpeg",
        "source_type": "edit",
        "formatted_size": "2.21 MB",
        "size": 2322350,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034034-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/large_1920-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034034-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/medium_1024-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034034-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/small_640-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034034-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/four_three_640-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034034-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/square_640-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034034-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/downloadable-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg"
        },
        "created_on": "2019-12-06T00:35:33Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060809",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2330296799",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034033-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/original-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
        "name": "DBI-30-GC1JJxd9-CRE-Images-Package---Shopping-Centers-Sample_DBI-30-6Cem6m7R-Lakeside-Marketplace_DBU71-NW_100FTR.jpeg",
        "source_type": "edit",
        "formatted_size": "2.2 MB",
        "size": 2305414,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034033-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/large_1920-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034033-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/medium_1024-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034033-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/small_640-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034033-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/four_three_640-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034033-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/square_640-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034033-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/downloadable-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg"
        },
        "created_on": "2019-12-06T00:35:33Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060809",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4505460324",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034032-fbab27b389279926b1fe2c978d0b6be05792c1d1/original-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
        "name": "DBI-29-t4fxwRHh-CRE-Images-Package---Shopping-Centers-Sample_DBI-33-egnffXbz-Lakeside-Marketplace_DBU74-SW_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.08 MB",
        "size": 2183094,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034032-fbab27b389279926b1fe2c978d0b6be05792c1d1/large_1920-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034032-fbab27b389279926b1fe2c978d0b6be05792c1d1/medium_1024-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034032-fbab27b389279926b1fe2c978d0b6be05792c1d1/small_640-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034032-fbab27b389279926b1fe2c978d0b6be05792c1d1/four_three_640-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034032-fbab27b389279926b1fe2c978d0b6be05792c1d1/square_640-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034032-fbab27b389279926b1fe2c978d0b6be05792c1d1/downloadable-58b56ed2c054c513b803908e2c621724881381a3.jpeg"
        },
        "created_on": "2019-12-06T00:35:32Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060809",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7421574683",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034031-fbab27b389279926b1fe2c978d0b6be05792c1d1/original-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
        "name": "DBI-28-UBt6wEgk-CRE-Images-Package---Shopping-Centers-Sample_DBI-36-wOLh2iGJ-Lakeside-Marketplace_DBU77-DJI_0055.jpeg",
        "source_type": "edit",
        "formatted_size": "2.25 MB",
        "size": 2358806,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034031-fbab27b389279926b1fe2c978d0b6be05792c1d1/large_1920-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034031-fbab27b389279926b1fe2c978d0b6be05792c1d1/medium_1024-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034031-fbab27b389279926b1fe2c978d0b6be05792c1d1/small_640-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034031-fbab27b389279926b1fe2c978d0b6be05792c1d1/four_three_640-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034031-fbab27b389279926b1fe2c978d0b6be05792c1d1/square_640-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034031-fbab27b389279926b1fe2c978d0b6be05792c1d1/downloadable-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg"
        },
        "created_on": "2019-12-06T00:35:32Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060809",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3224295262",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034030-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/original-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
        "name": "DBI-27-yurqBHxQ-CRE-Images-Package---Shopping-Centers-Sample_DBI-32-nu7toZ8x-Lakeside-Marketplace_DBU73-SE_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.26 MB",
        "size": 2366480,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034030-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/large_1920-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034030-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/medium_1024-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034030-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/small_640-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034030-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/four_three_640-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034030-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/square_640-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034030-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/downloadable-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg"
        },
        "created_on": "2019-12-06T00:35:08Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060810",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8992966358",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034029-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/original-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
        "name": "DBI-26-xkMNCzyn-CRE-Images-Package---Shopping-Centers-Sample_DBI-27-JGEYmlMO-Lakeside-Marketplace_DBU68-E_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.33 MB",
        "size": 2439645,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034029-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/large_1920-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034029-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/medium_1024-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034029-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/small_640-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034029-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/four_three_640-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034029-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/square_640-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034029-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/downloadable-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg"
        },
        "created_on": "2019-12-06T00:35:08Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060810",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7921509806",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034028-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/original-762384d67608457afc248fde66c893da7f918496.jpeg",
        "name": "DBI-25-j4MZZ1YP-CRE-Images-Package---Shopping-Centers-Sample_DBI-35-3rht1dJV-Lakeside-Marketplace_DBU76-FRONT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.04 MB",
        "size": 2138776,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034028-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/large_1920-762384d67608457afc248fde66c893da7f918496.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034028-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/medium_1024-762384d67608457afc248fde66c893da7f918496.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034028-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/small_640-762384d67608457afc248fde66c893da7f918496.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034028-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/four_three_640-762384d67608457afc248fde66c893da7f918496.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034028-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/square_640-762384d67608457afc248fde66c893da7f918496.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034028-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/downloadable-762384d67608457afc248fde66c893da7f918496.jpeg"
        },
        "created_on": "2019-12-06T00:35:08Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060810",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0836929217",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034027-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/original-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
        "name": "DBI-24-G2gxYzRH-CRE-Images-Package---Shopping-Centers-Sample_DBI-31-zGjKtYXQ-Lakeside-Marketplace_DBU72-S_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.17 MB",
        "size": 2279977,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034027-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/large_1920-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034027-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/medium_1024-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034027-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/small_640-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034027-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/four_three_640-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034027-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/square_640-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034027-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/downloadable-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg"
        },
        "created_on": "2019-12-06T00:35:08Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060810",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2133703089",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034026-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/original-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
        "name": "DBI-23-dkG5GpcU-CRE-Images-Package---Shopping-Centers-Sample_DBI-29-Q4idBa2p-Lakeside-Marketplace_DBU70-NE_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.43 MB",
        "size": 2545508,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034026-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/large_1920-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034026-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/medium_1024-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034026-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/small_640-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034026-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/four_three_640-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034026-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/square_640-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034026-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/downloadable-492d0b22f10d900ef123841826e66de9705918d8.jpeg"
        },
        "created_on": "2019-12-06T00:35:08Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060810",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4628517795",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034025-22de49c05d57e3c4788293dcdedb20e5b8b44b71/original-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
        "name": "DBI-22-YcUKwyAg-CRE-Images-Package---Shopping-Centers-Sample_DBI-28-zNfK8rO0-Lakeside-Marketplace_DBU69-N_100FT.jpeg",
        "source_type": "edit",
        "formatted_size": "2.11 MB",
        "size": 2212920,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034025-22de49c05d57e3c4788293dcdedb20e5b8b44b71/large_1920-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034025-22de49c05d57e3c4788293dcdedb20e5b8b44b71/medium_1024-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034025-22de49c05d57e3c4788293dcdedb20e5b8b44b71/small_640-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034025-22de49c05d57e3c4788293dcdedb20e5b8b44b71/four_three_640-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034025-22de49c05d57e3c4788293dcdedb20e5b8b44b71/square_640-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034025-22de49c05d57e3c4788293dcdedb20e5b8b44b71/downloadable-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg"
        },
        "created_on": "2019-12-06T00:35:07Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060810",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9592283108",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034023-89a2c1a60cd8900e07974f682c7af1fee870ed9a/original-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
        "name": "DBI-20-gvSRdW0J-CRE-Images-Package---Shopping-Centers-Sample_DBI-53-1e11H3VD-Lakeside-Marketplace_DBU94-DJI_0077.jpeg",
        "source_type": "edit",
        "formatted_size": "2.42 MB",
        "size": 2540186,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034023-89a2c1a60cd8900e07974f682c7af1fee870ed9a/large_1920-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034023-89a2c1a60cd8900e07974f682c7af1fee870ed9a/medium_1024-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034023-89a2c1a60cd8900e07974f682c7af1fee870ed9a/small_640-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034023-89a2c1a60cd8900e07974f682c7af1fee870ed9a/four_three_640-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034023-89a2c1a60cd8900e07974f682c7af1fee870ed9a/square_640-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034023-89a2c1a60cd8900e07974f682c7af1fee870ed9a/downloadable-62155bd10193342d2fbb88f6512029f76865071a.jpeg"
        },
        "created_on": "2019-12-06T00:33:31Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060811",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8882101535",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034022-89a2c1a60cd8900e07974f682c7af1fee870ed9a/original-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
        "name": "DBI-19-aKIIgLIl-CRE-Images-Package---Shopping-Centers-Sample_DBI-58-9MohZxUx-Lakeside-Marketplace_DBU99-DJI_0088.jpeg",
        "source_type": "edit",
        "formatted_size": "2.49 MB",
        "size": 2609129,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034022-89a2c1a60cd8900e07974f682c7af1fee870ed9a/large_1920-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034022-89a2c1a60cd8900e07974f682c7af1fee870ed9a/medium_1024-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034022-89a2c1a60cd8900e07974f682c7af1fee870ed9a/small_640-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034022-89a2c1a60cd8900e07974f682c7af1fee870ed9a/four_three_640-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034022-89a2c1a60cd8900e07974f682c7af1fee870ed9a/square_640-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034022-89a2c1a60cd8900e07974f682c7af1fee870ed9a/downloadable-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg"
        },
        "created_on": "2019-12-06T00:33:31Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060811",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2691805426",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034021-1bd2c6437ac897665459a9c25903ce2a7032b722/original-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
        "name": "DBI-18-PulvLpNG-CRE-Images-Package---Shopping-Centers-Sample_DBI-49-GBkeyipm-Lakeside-Marketplace_DBU90-DJI_0073.jpeg",
        "source_type": "edit",
        "formatted_size": "2.49 MB",
        "size": 2608538,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034021-1bd2c6437ac897665459a9c25903ce2a7032b722/large_1920-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034021-1bd2c6437ac897665459a9c25903ce2a7032b722/medium_1024-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034021-1bd2c6437ac897665459a9c25903ce2a7032b722/small_640-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034021-1bd2c6437ac897665459a9c25903ce2a7032b722/four_three_640-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034021-1bd2c6437ac897665459a9c25903ce2a7032b722/square_640-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034021-1bd2c6437ac897665459a9c25903ce2a7032b722/downloadable-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg"
        },
        "created_on": "2019-12-06T00:33:30Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060811",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7984291648",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034018-6e5cf2457eadcb2589cd41077de4d14b15cc7aba/original-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
        "name": "DBI-73-MZALruFK-CRE-Images-Package---Shopping-Centers-Sample_DBI-54-8efSASmw-Lakeside-Marketplace_DBU95-DJI_0079.jpeg",
        "source_type": "edit",
        "formatted_size": "2.46 MB",
        "size": 2581268,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034018-6e5cf2457eadcb2589cd41077de4d14b15cc7aba/large_1920-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034018-6e5cf2457eadcb2589cd41077de4d14b15cc7aba/medium_1024-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034018-6e5cf2457eadcb2589cd41077de4d14b15cc7aba/small_640-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034018-6e5cf2457eadcb2589cd41077de4d14b15cc7aba/four_three_640-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034018-6e5cf2457eadcb2589cd41077de4d14b15cc7aba/square_640-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034018-6e5cf2457eadcb2589cd41077de4d14b15cc7aba/downloadable-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg"
        },
        "created_on": "2019-12-06T00:33:03Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060812",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6868906804",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034014-9471aae912392c4b313cfbd0e1f47f2a3ae22b66/original-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
        "name": "DBI-69-B2qeBf2I-CRE-Images-Package---Shopping-Centers-Sample_DBI-57-TgwqvXuM-Lakeside-Marketplace_DBU98-DJI_0087.jpeg",
        "source_type": "edit",
        "formatted_size": "2.39 MB",
        "size": 2507677,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034014-9471aae912392c4b313cfbd0e1f47f2a3ae22b66/large_1920-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034014-9471aae912392c4b313cfbd0e1f47f2a3ae22b66/medium_1024-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034014-9471aae912392c4b313cfbd0e1f47f2a3ae22b66/small_640-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034014-9471aae912392c4b313cfbd0e1f47f2a3ae22b66/four_three_640-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034014-9471aae912392c4b313cfbd0e1f47f2a3ae22b66/square_640-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034014-9471aae912392c4b313cfbd0e1f47f2a3ae22b66/downloadable-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg"
        },
        "created_on": "2019-12-06T00:33:01Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060812",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI5778387274",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10034010-4967bc7330a467a77bc9d688ff5277e2956d89b2/original-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
        "name": "DBI-65-TGxaiTB0-CRE-Images-Package---Shopping-Centers-Sample_DBI-42-9rot8SIa-Lakeside-Marketplace_DBU83-DJI_0063.jpeg",
        "source_type": "edit",
        "formatted_size": "1.87 MB",
        "size": 1961119,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034010-4967bc7330a467a77bc9d688ff5277e2956d89b2/large_1920-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10034010-4967bc7330a467a77bc9d688ff5277e2956d89b2/medium_1024-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10034010-4967bc7330a467a77bc9d688ff5277e2956d89b2/small_640-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10034010-4967bc7330a467a77bc9d688ff5277e2956d89b2/four_three_640-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10034010-4967bc7330a467a77bc9d688ff5277e2956d89b2/square_640-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10034010-4967bc7330a467a77bc9d688ff5277e2956d89b2/downloadable-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg"
        },
        "created_on": "2019-12-06T00:32:59Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060812",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9148706485",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10015587-b0b979106ab692c2314c45699254293173dfc7c2/original-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
        "name": "DBI-3-6PyOoS8a-CRE-Shopping-Center---Images-Package_DBI-6-downloadable-8c8bd726f1e7c2e7ab07cd153987ce194dcce521-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "1.88 MB",
        "size": 1967253,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015587-b0b979106ab692c2314c45699254293173dfc7c2/large_1920-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10015587-b0b979106ab692c2314c45699254293173dfc7c2/medium_1024-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10015587-b0b979106ab692c2314c45699254293173dfc7c2/small_640-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10015587-b0b979106ab692c2314c45699254293173dfc7c2/four_three_640-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10015587-b0b979106ab692c2314c45699254293173dfc7c2/square_640-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10015587-b0b979106ab692c2314c45699254293173dfc7c2/downloadable-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg"
        },
        "created_on": "2019-12-04T01:49:32Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1060810",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "7fdb472d2ebad2658d4e4d1eb4206c63",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-05T06:48:36Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "1060813",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278128",
        "shot_type_id": 22,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Final Assets"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "22",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "22",
      "type": "shot_types",
      "attributes": {
        "name": "Final Assets",
        "description": "Use this shot to upload final edited assets for the customer. Not for use by pilots.\n",
        "video": null,
        "slug": "final-assets",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060812",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278128",
        "shot_type_id": 147,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Anchor Tenants"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "147",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI7984291648",
            "type": "images"
          }, {
            "id": "DBI6868906804",
            "type": "images"
          }, {
            "id": "DBI5778387274",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "147",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Anchor Tenants",
        "description": "Straight on image of each anchor tenant from just below roof level. *An anchor tenant is grocery store, department store, or other large retailer.* Images with little to no roof in frame are a big point of emphasis our customers. If you need to adjust, back away from the property rather than increasing altitude *so that we don't see the top of the roof.*",
        "video": null,
        "slug": "images_-_anchor_tenants",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060811",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278128",
        "shot_type_id": 148,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Low Level Corner Shots"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "148",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI9592283108",
            "type": "images"
          }, {
            "id": "DBI8882101535",
            "type": "images"
          }, {
            "id": "DBI2691805426",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "148",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Low Level Corner Shots",
        "description": "Images from just below roof level that are angled down the lineup of buildings. We need one image from each end of the building. If there are multiple building or if it is an L-shape or U-shaped property we need an image from each corner. For example, an L-shaped building will have at least 4 images and a U-shaped building with have at least 6 images. \n\nImages with little to no roof in frame are a big point of emphasis our customers. If you need to adjust, back away from the property rather than increasing altitude *so that we don't see the top of the roof.*\n**[Click here for example images](http://bit.ly/2wNIc1D)**",
        "video": null,
        "slug": "images_-_low_level_corner_shots",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060810",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "278128",
        "shot_type_id": 24,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (350 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "24",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI3224295262",
            "type": "images"
          }, {
            "id": "DBI8992966358",
            "type": "images"
          }, {
            "id": "DBI7921509806",
            "type": "images"
          }, {
            "id": "DBI0836929217",
            "type": "images"
          }, {
            "id": "DBI2133703089",
            "type": "images"
          }, {
            "id": "DBI4628517795",
            "type": "images"
          }, {
            "id": "DBI9148706485",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "24",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (350 ft above ground level)",
        "description": "*If there are altitude restrictions in this airspace, please take these images at the highest allowable altitude instead of 350'. *",
        "video": null,
        "slug": "images-cardinal-directions-350-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "23",
            "type": "shot_type_sample_images"
          }, {
            "id": "24",
            "type": "shot_type_sample_images"
          }, {
            "id": "25",
            "type": "shot_type_sample_images"
          }, {
            "id": "26",
            "type": "shot_type_sample_images"
          }, {
            "id": "27",
            "type": "shot_type_sample_images"
          }, {
            "id": "28",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1060809",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "278128",
        "shot_type_id": 13,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (100 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "13",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI2317149084",
            "type": "images"
          }, {
            "id": "DBI3451367597",
            "type": "images"
          }, {
            "id": "DBI4505460324",
            "type": "images"
          }, {
            "id": "DBI2330296799",
            "type": "images"
          }, {
            "id": "DBI7421574683",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "13",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (100 ft above ground level)",
        "description": "",
        "video": "",
        "slug": "images-cardinal-directions-100-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "17",
            "type": "shot_type_sample_images"
          }, {
            "id": "18",
            "type": "shot_type_sample_images"
          }, {
            "id": "19",
            "type": "shot_type_sample_images"
          }, {
            "id": "20",
            "type": "shot_type_sample_images"
          }, {
            "id": "21",
            "type": "shot_type_sample_images"
          }, {
            "id": "22",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "DBINV469671178485113",
      "type": "invoice_line_items",
      "attributes": {
        "reason": "base_package_price",
        "description": null,
        "amount": 21900,
        "created_on": "2019-12-04T01:47:22Z",
        "display_name": "Base Package Price"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "missions"
          }
        },
        "work_item": {
          "data": null
        }
      }
    }]
  };
  _exports.default = _default;
});