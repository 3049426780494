define("clients/services/missions-csv-downloader", ["exports", "ember-data", "clients/config/environment", "jquery"], function (_exports, _emberData, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionsCsvDownoader;
  MissionsCsvDownoader = Ember.Service.extend({
    sessionAccount: Ember.inject.service(),
    load: function (params) {
      this.set('params', params);
      return _jquery.default.ajax({
        url: _environment.default.api.host + "/v2/clients/missions/csv",
        type: 'GET',
        dataType: 'text',
        headers: this.get('sessionAccount.headers'),
        data: params
      }).then(function (_this) {
        return function (response) {
          var URL, anchor, document, documentName;
          document = window.document, URL = window.URL;
          anchor = document.createElement('a');
          documentName = _this.get('documentNameCompiled');
          anchor.download = documentName;
          anchor.href = URL.createObjectURL(new Blob([response], {
            type: 'text/csv'
          }));
          document.body.appendChild(anchor);
          anchor.click();
          return anchor.remove();
        };
      }(this));
    },
    documentName: Ember.observer('params', function () {
      var date, documentName, filter, filterKey, formattedQuery, params, query, statusesFilter;
      params = this.get('params');
      statusesFilter = {
        "created,confirmed,pilots_notified,pilot_accepted": 'upcoming',
        "pilot_accepted,flight_complete,assets_uploaded,processing_shots,assets_classified,in_production": 'in_progress',
        "invoice_needed,invoiced,awaiting_payment,complete": 'completed',
        "canceled": 'canceled',
        "": 'all'
      };

      if (params.statuses) {
        filterKey = params.statuses;
      } else {
        filterKey = '';
      }

      filter = statusesFilter[filterKey];
      query = params.q;
      date = moment().format('YYYY_MM_DD');
      documentName = "zeitview" + '_';

      if (query) {
        formattedQuery = query.replace(/ /g, '_').toLowerCase();
        documentName += formattedQuery + '_';
      }

      if (filter) {
        documentName += filter + '_';
      }

      if (params['show_org_missions']) {
        documentName += params['show_org_missions'] + '_';
      }

      documentName += date + '.csv';
      return this.set('documentNameCompiled', documentName);
    })
  });
  var _default = MissionsCsvDownoader;
  _exports.default = _default;
});