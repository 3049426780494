define("clients/components/mission-group/base-modal", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    metrics: Ember.inject.service(),
    isSaving: false,
    baseGMTEventAttrs: {
      event: 'event',
      action: 'click',
      category: 'Dashboard',
      objectType: 'missionGroup'
    },
    GMTEventAttrs: {},
    reloadModelAction: function () {},
    saveRecord: function () {},

    didRender() {
      this._super(...arguments);

      (0, _jquery.default)('#group-input').focus();
    },

    createGMTEvent: function (response) {
      if (Object.keys(this.GMTEventAttrs).length === 0) {
        return;
      }

      let objectId = null;

      if (this.GMTobjectId) {
        objectId = this.GMTobjectId;
      }

      if (response.id) {
        objectId = response.id;
      }

      if (objectId) {
        this.set('baseGMTEventAttrs.objectId', objectId);
      }

      this.metrics.trackEvent(Ember.assign(this.baseGMTEventAttrs, this.GMTEventAttrs));
    },
    actions: {
      close() {
        return this.set('showModal', false);
      },

      save() {
        this.set('isSaving', true);
        this.saveRecord().then(response => {
          this.createGMTEvent(response);
          this.setProperties({
            isSaving: false,
            selectedMissions: [],
            showModal: false
          });
          this.reloadModelAction();
        }, () => {
          this.set('isSaving', false);
          alert('Sorry, there was an error saving your changes.');
        });
      }

    }
  });

  _exports.default = _default;
});