define("clients/components/mission-map", ["exports", "clients/mixins/google-map-init-mixin", "clients/data/map_features", "lodash/function", "lodash/cloneDeep"], function (_exports, _googleMapInitMixin, _map_features, _function2, _cloneDeep2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionMapComponent;
  MissionMapComponent = Ember.Component.extend(_googleMapInitMixin.default, {
    metrics: Ember.inject.service(),
    classNames: ['mission-map'],
    features: [],
    message: null,
    DEFAULT_ZOOM: 18,
    activeDrawFeature: null,
    autoSave: false,
    colorIndex: 0,
    smallScreen: Ember.on('init', function () {
      var deviceWidth;
      deviceWidth = window.orientation === 0 ? window.screen.width : window.screen.height;

      if (navigator.userAgent.indexOf('Android') >= 0 && window.devicePixelRatio) {
        deviceWidth = deviceWidth / window.devicePixelRatio;
      }

      return deviceWidth <= 767;
    }),
    didInsertElement: function () {
      var _mapFeatureData;

      this._super();

      _mapFeatureData = (0, _cloneDeep2.default)(_map_features.default);
      this.mapStyles = _mapFeatureData.mapStylesData;
      this.featureColors = _mapFeatureData.featureColors;
      this.featureOptions = _mapFeatureData.featureOptions;
      this.selectedFeatureOptions = _mapFeatureData.selectedFeatureOptions;
      this.disabledFeatureOptions = _mapFeatureData.disabledFeatureOptions;
      this.noteIcons = _mapFeatureData.noteIcons;
      return $('.search-field').val(this.get('model.location.name'));
    },
    initMap: function () {
      var options;
      options = {
        zoom: this.DEFAULT_ZOOM,
        tilt: 0,
        styles: this.mapStyles,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT
        },
        streetViewControl: false,
        scaleControl: false,
        panControl: false,
        draggable: true,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.LARGE,
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        disableDoubleClickZoom: false,
        fullscreenControl: false,
        mapTypeId: google.maps.MapTypeId['HYBRID']
      };
      this.set('features', []);

      if (!this.map) {
        this.map = new google.maps.Map(document.getElementById('map_canvas'), options);
      }

      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        map: this.map
      });
      this.drawingManager.addListener('polygoncomplete', function (_this) {
        return function (polygon) {
          _this.addPolygon(polygon);

          polygon.setVisible(false);

          _this.drawingManager.setDrawingMode(null);

          return Ember.$('.mission-map-control').removeClass('active');
        };
      }(this));
      this.drawingManager.addListener('markercomplete', function (_this) {
        return function (marker) {
          _this.addNote(marker);

          marker.setVisible(false);

          _this.drawingManager.setDrawingMode(null);

          return Ember.$('.mission-map-control').removeClass('active');
        };
      }(this));
      this.Feature = Ember.Object.extend({
        id: null,
        type: null,
        object: null,
        name: '',
        notes: '',
        number: '',
        area: null,
        center: null
      });
      this.map.data.addListener('addfeature', function (_this) {
        return function (event) {
          options = _this.lookUpMapOptions(event.feature);
          Object.assign(options.defaultOptions, _this.get('disabledFeatureOptions'));

          _this.map.data.overrideStyle(event.feature, options.defaultOptions);

          _this.map.data.overrideStyle(event.feature, {
            fillColor: options.mapColor,
            strokeColor: options.mapColor
          });

          return _this.get('features').pushObject(_this.Feature.create({
            id: event.feature.getId(),
            object: event.feature,
            area: event.feature.getProperty('area'),
            name: event.feature.getProperty('name'),
            notes: event.feature.getProperty('notes'),
            number: event.feature.getProperty('number')
          }));
        };
      }(this));
      this.map.data.addListener('setproperty', function (_this) {
        return function (event) {
          var polygon;

          if (event.newValue === event.oldValue) {
            return;
          }

          polygon = _this.get('features').findBy('object', event.feature);
          return _this.saveData();
        };
      }(this));
      this.map.data.addListener('setgeometry', _function2.default.debounce(function (_this) {
        return function (event) {
          if (event.feature.getGeometry().getType() === 'Polygon') {
            return _this.setGeometryData(event.feature);
          }
        };
      }(this), 1000));
      this.markers = [];
      return this.loadData();
    },
    lookUpMapOptions: function (feature) {
      var hasCatalogItem, image, options;
      hasCatalogItem = this.hasCatalogItem(feature.getProperty('catalog_item_ids'));

      switch (feature.getGeometry().getType()) {
        case 'Polygon':
          options = this.get('featureOptions').PROPERTYAREA;
          options.mapColor = this.getNextColor();
          break;

        case 'Point':
          if (feature.getProperty('feature_type') === 'panorama' || hasCatalogItem) {
            options = this.get('featureOptions').PANORAMA;
          } else {
            options = this.get('featureOptions').POINTOFINTEREST;
          }

          if (feature.getProperty('number')) {
            image = {
              url: this.get('noteIcons')[feature.getProperty('number') - 1]
            };
            options.defaultOptions.icon = image;
          }

      }

      return options;
    },
    getNextColor: function () {
      this.set('colorIndex', this.colorIndex + 1);
      return this.featureColors[this.colorIndex % 5];
    },
    loadData: function () {
      var latLng;

      if (Number(this.get('model.location.latitude')) && Number(this.get('model.location.longitude'))) {
        latLng = new google.maps.LatLng({
          lat: Number(this.get('model.location.latitude')),
          lng: Number(this.get('model.location.longitude'))
        });
        this.addMarker(latLng);

        if (this.get('model.mission.geo_properties') && this.get('model.mission.geo_properties.features') && this.get('model.mission.geo_properties.features').length) {
          return this.loadGeoJson(this.get('model.mission.geo_properties'));
        } else {
          return this.map.setCenter(latLng);
        }
      } else {
        return this.defaultLocation();
      }
    },
    clearMap: function () {
      this.get('features').forEach(function (_this) {
        return function (f) {
          return _this.map.data.remove(f.object);
        };
      }(this));
      this.get('features').clear();
      this.markers.forEach(function (marker) {
        return marker.setMap(null);
      });
      return this.markers.clear();
    },
    loadGeoJson: function (json) {
      this.set('loadingData', true);
      return this.addGeoJsonProxy(json).then(function (_this) {
        return function (response) {
          return _this.set('loadingData', false);
        };
      }(this), function (_this) {
        return function (response) {
          _this.set('loadingData', false);

          return console.log(JSON.stringify(response));
        };
      }(this));
    },
    addGeoJsonProxy: function (json) {
      return new Ember.RSVP.Promise(function (_this) {
        return function (resolve, reject) {
          var feature_array;

          if (feature_array = _this.map.data.addGeoJson(json)) {
            _this.fitAllFeatures();

            return resolve(feature_array);
          } else {
            return reject('Failed loading geoJson into data layer');
          }
        };
      }(this));
    },
    fitAllFeatures: function () {
      var bounds;
      bounds = new google.maps.LatLngBounds();
      this.get('features').forEach(function (feature) {
        if (feature.object.getGeometry().getType() === 'Polygon') {
          return feature.object.getGeometry().getArray().forEach(function (path) {
            return path.getArray().forEach(function (latLng) {
              return bounds.extend(latLng);
            });
          });
        } else if (feature.object.getGeometry().getType() === 'Point') {
          return bounds.extend(feature.object.getGeometry().get());
        }
      });
      return this.map.fitBounds(bounds);
    },
    addMarker: function (latLng, arg) {
      var animation, draggable, icon, label, marker, ref;
      ref = arg != null ? arg : {}, icon = ref.icon, draggable = ref.draggable, label = ref.label;

      if (icon == null) {
        icon = {
          url: '/assets/images/map-icon-pin.svg',
          scaledSize: new google.maps.Size(34, 34)
        };
      }

      if (draggable == null) {
        draggable = false;
      }

      animation = google.maps.Animation.DROP;
      marker = new google.maps.Marker({
        map: this.map,
        icon: icon,
        position: latLng,
        draggable: draggable,
        animation: animation,
        label: label,
        crossOnDrag: false
      });
      this.markers.push(marker);
      $('#map_canvas').removeClass('collapsed');
      $('#map_controls').show();
      $('#map_instructions').show();
    },
    formatAcres: function (area) {
      var acres;
      acres = Math.round(area * 0.0002471);

      if (acres < 1) {
        return "< 1 acre";
      } else if (acres === 1) {
        return acres + " acre";
      } else if (acres > 1) {
        return acres + " acres";
      } else {
        return '';
      }
    },
    toggleFullscreen: function () {
      var distanceToTop, mapHeight, navHeight;

      if (this.get('initialMapHeight')) {
        Ember.$('nav').show();
        mapHeight = this.get('initialMapHeight');
        navHeight = this.get('initialNavHeight');
        this.set('initialMapHeight', null);
        distanceToTop = Ember.$('#map_canvas').offset().top - Ember.$('nav').height();

        if (this.get('smallScreen')) {
          this.map.setOptions({
            gestureHandling: 'auto'
          });
        }
      } else {
        Ember.$('nav').hide();
        this.set('initialMapHeight', Ember.$('#map_canvas').height());
        mapHeight = Ember.$(window).height();
        distanceToTop = Ember.$('#map_canvas').offset().top;

        if (this.get('smallScreen')) {
          this.map.setOptions({
            gestureHandling: 'greedy'
          });
        }
      }

      return Ember.$('#map_canvas').animate({
        height: mapHeight
      }, 100).promise().then(function (_this) {
        return function () {
          google.maps.event.trigger(_this.map, 'resize');
          return Ember.$('html,body').animate({
            scrollTop: distanceToTop
          }, 800).promise().then(function () {});
        };
      }(this));
    },
    hasCatalogItem: function (catalog_item_ids) {
      var catalog_item;

      if (!catalog_item_ids) {
        return false;
      }

      catalog_item = this.get('model.mission.package.catalog_items').find(function (item) {
        return catalog_item_ids.includes(item.id);
      });
      return catalog_item != null ? catalog_item.has_pano_shot_type : void 0;
    }
  });
  var _default = MissionMapComponent;
  _exports.default = _default;
});