define("clients/components/order-flow/property-info", ["exports", "clients/mixins/google-map-init-mixin", "clients/data/map_features", "tz-lookup", "clients/config/environment", "jquery"], function (_exports, _googleMapInitMixin, _map_features, _tzLookup, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_googleMapInitMixin.default, {
    metrics: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    hasLocation: Ember.computed.or('model.location.address', 'model.location.latitude'),
    hasGeoLocation: Ember.computed.and('model.location.longitude', 'model.location.latitude'),
    trackingId: Ember.computed('model.client.id', function () {
      if (this.model.client && this.model.client.id) {
        return Number(this.model.client.id.substring(3));
      } else {
        return 'loggedout';
      }
    }),
    locationChanged: Ember.observer('typedLatLng', function () {
      return this.fetchUsers();
    }),
    orgClientsWithAccess: Ember.computed("orgClientsBySearch", function () {
      return this.orgClientsBySearch.filter(client => client.constHasAccess).slice(0).sort((a, b) => a.name.localeCompare(b.name));
    }),
    orgClientsWithoutAccess: Ember.computed("orgClientsBySearch", function () {
      return this.orgClientsBySearch.filter(client => !client.constHasAccess).slice(0).sort((a, b) => a.name.localeCompare(b.name));
    }),
    orgClientsBySearch: Ember.computed("model.orgClients", "clientName", function () {
      if (this.model.orgClients) {
        if (this.clientName) {
          return this.orgClients.filter(client => {
            return client.name.toLowerCase().includes(this.clientName.toLowerCase()) || client.email.toLowerCase().includes(this.clientName.toLowerCase());
          });
        }

        return this.orgClients;
      }

      return [];
    }),
    isClientUpload: Ember.computed.equal('model.orderFlow', 'clientUpload'),
    classNames: ['mission-map'],
    message: null,
    DEFAULT_ZOOM: 18,
    autoSave: false,
    mapStyles: _map_features.default.mapStylesData,
    typedLatLng: null,
    orgClients: Ember.computed('model.orgClients', function () {
      return this.model.orgClients || [];
    }),
    showOrgClients: false,
    clientName: '',
    clientsChanged: Ember.computed('model.changedClients', function () {
      return this.model.changedClients || [];
    }),
    store: Ember.inject.service(),
    isInsights: Ember.computed('model.client.is_insights_customer', function () {
      return this.model.client.is_insights_customer;
    }),
    orgClientsChanged: Ember.observer('model.orgClients', function () {
      let clientsChanged = this.orgClients.filter(client => client.isWatcher != client.constIsWatching || client.hasAccess != client.constHasAccess);
      this.set('model.changedClients', clientsChanged);
      let watchers = clientsChanged.map(client => this.store.createRecord('watcher', { ...client,
        location: this.get('model.location')
      }));
      this.set('model.location.watchers', watchers);
    }),
    usersLink: _environment.default.insights_endpoint + "/users",
    init: function () {
      this._super();

      this.metrics.trackPage({
        hitType: 'pageview',
        page: '/order_flow/location'
      });

      if (this.sessionAccount.account) {
        this.sessionAccount.account.then(account => {
          this.set('account', account);

          if (account.get('esri_account.tokenIsExpired')) {
            return _jquery.default.ajax({
              url: `${_environment.default.api.host}/v1/clients/esri_accounts/${this.get('account.esri_account.id')}/renew_token`,
              type: 'POST',
              dataType: 'json',
              headers: this.get('sessionAccount.headers')
            }).then(() => {
              return this.account.esri_account.reload().then(() => {
                return Ember.run.scheduleOnce('afterRender', this, this.loadMap);
              });
            });
          }
        });
      } else {
        Ember.run.scheduleOnce('afterRender', this, this.loadMap);
      }
    },
    initMap: function () {
      const options = {
        zoom: this.DEFAULT_ZOOM,
        tilt: 0,
        styles: this.mapStyles,
        mapTypeControl: false,
        streetViewControl: false,
        scaleControl: false,
        panControl: false,
        draggable: true,
        zoomControl: false,
        disableDoubleClickZoom: false,
        fullscreenControl: false,
        mapTypeId: google.maps.MapTypeId['HYBRID']
      };

      if (!this.map) {
        this.map = new google.maps.Map(document.getElementById('map_canvas'), options);
      }

      this.markers = [];
      this.setupAutocomplete();
      return this.loadData();
    },
    isLatLong: function () {
      const input = (0, _jquery.default)('.search-field')[0];
      const inputString = input.value.replace(/\s/g, '');
      const latLong = inputString.split(',');

      if (latLong.length !== 2) {
        return false;
      }

      const invalidNumber = latLong.find(elem => {
        return isNaN(elem) || elem.length === 0;
      });

      if (invalidNumber) {
        return false;
      } else {
        return latLong;
      }
    },
    setupAutocomplete: function () {
      const inputSelector = (0, _jquery.default)('.search-field');
      const input = inputSelector[0];
      inputSelector.on('keyup', () => {
        if (input.value === "") {
          this.set('addressBarFilled', false);
        } else if ((0, _jquery.default)('.pac-item').length > 0) {
          this.set('addressBarFilled', true);
        } else {
          this.set('addressBarFilled', this.isLatLong());
        }
      });
      const searchBox = new google.maps.places.SearchBox(input);
      this.map.addListener('bounds_changed', () => {
        return searchBox.setBounds(this.map.getBounds());
      });
      searchBox.addListener('places_changed', () => {
        this.set('message', null);

        if (searchBox.getPlaces().length === 0) {
          this.metrics.trackEvent({
            event: 'event',
            category: 'Mission Map',
            action: 'autocomplete',
            label: 'Location Search - No Address Found'
          });
          this.set('message', 'We could not find a location based on your search.');
        }

        const place = searchBox.getPlaces()[0];

        if (!place.address_components) {
          this.processSearch();
        } else {
          this.set('typedLatLng', null);
          this.metrics.trackEvent({
            event: 'event',
            category: 'orderflow',
            action: 'search-location-auto',
            value: this.get('trackingId')
          });
          this.dropPin(place);
        }
      });
    },
    processSearch: function () {
      this.set('message', null);

      if (!(0, _jquery.default)('.search-field')[0].value) {
        return false;
      }

      const latLong = this.isLatLong();
      const geocoder = new google.maps.Geocoder();
      let location = {};

      if (latLong) {
        this.metrics.trackEvent({
          event: 'event',
          category: 'Mission Map',
          action: 'autocomplete',
          label: 'Location Search - Using user input` lat/long'
        });
        location = {
          'location': new google.maps.LatLng(latLong[0], latLong[1])
        };
      } else if ((0, _jquery.default)('.pac-item').length > 0) {
        location = {
          'address': (0, _jquery.default)('.pac-item')[0].outerText
        };
      }

      geocoder.geocode(location, response => {
        this.set('message', 'We found a place close to your search. Please verify.');
        this.metrics.trackEvent({
          event: 'event',
          category: 'orderflow',
          action: 'search-location-manual',
          value: this.get('trackingId')
        });
        const place = response[0];

        if (latLong) {
          const lat_lng = new google.maps.LatLng(latLong[0], latLong[1]);
          this.set('typedLatLng', lat_lng);
          place.geometry.location = this.get('typedLatLng');
        } else {
          this.set('typedLatLng', null);
        }

        this.dropPin(place);
      }, () => {
        this.metrics.trackEvent({
          event: 'event',
          category: 'Mission Map',
          action: 'autocomplete',
          label: 'Location Search - No Address Found via geocode'
        });
        this.set('message', 'We could not find a location based on your search.');
      });
    },
    fetchUsers: function () {
      self = this;
      return _jquery.default.ajax({
        url: `${_environment.default.api.host}/v1/clients/organizations/mgmt_clients`,
        type: 'GET',
        dataType: 'json',
        headers: this.get('sessionAccount.headers'),
        data: {
          latitude: this.model.location.latitude,
          longitude: this.model.location.longitude
        }
      }).done(function (data) {
        if (Ember.isPresent(Object.keys(data))) {
          let clients = data.map(orgClient => {
            return {
              constIsWatching: orgClient.is_watcher,
              isWatcher: orgClient.is_watcher,
              hasAccess: orgClient.has_access,
              constHasAccess: orgClient.has_access,
              email: orgClient.email,
              name: orgClient.name
            };
          });
          self.set('model.orgClients', clients);
        }
      });
    },
    dropPin: function (place) {
      this.map.setCenter(place.geometry.location);
      this.map.setZoom(this.DEFAULT_ZOOM);
      this.clearMap();
      this.setAddress(place);
      this.addMarker(this.map.getCenter());
      this.checkAirspace(place);
      this.fetchUsers();
    },
    loadData: function () {
      if (Number(this.model.location.latitude) && Number(this.model.location.longitude)) {
        const latLng = new google.maps.LatLng({
          lat: Number(this.model.location.latitude),
          lng: Number(this.model.location.longitude)
        });
        this.addMarker(latLng);
        return this.map.setCenter(latLng);
      } else {
        return this.defaultLocation();
      }
    },
    clearMap: function () {
      this.markers.forEach(marker => {
        return marker.setMap(null);
      });
      return this.markers.clear();
    },
    defaultLocation: function () {
      const lat_lng = new google.maps.LatLng(37.2350, -115.8111);
      const inputSelector = (0, _jquery.default)('.search-field');
      this.clearMap();
      this.map.setZoom(3);
      this.map.setCenter(lat_lng);
      inputSelector.attr('placeholder', 'Enter Location');
      inputSelector[0].focus();
    },
    setAddress: function (place) {
      const inputSelector = (0, _jquery.default)('.search-field');
      this.set('addressesDontMatch', false);
      this.set('model.location.longitude', place.geometry.location.lng());
      this.set('model.location.latitude', place.geometry.location.lat());
      let complete_address = place.formatted_address;

      if (place.name && complete_address) {
        if (complete_address.toLowerCase().lastIndexOf(place.name.toLowerCase(), 0) !== 0) {
          complete_address = place.name + ", " + complete_address;
        }
      }

      this.set('model.location.name', complete_address);
      this.set('model.location.formatted_address', complete_address);
      inputSelector.val(complete_address);

      if (this.typedLatLng) {
        inputSelector.val(this.typedLatLng.lat() + ',' + this.typedLatLng.lng());
      }

      let address_components = {};

      _jquery.default.each(place.address_components, (k, v1) => {
        _jquery.default.each(v1.types, (k2, v2) => {
          if (v2 === 'country') {
            address_components[v2] = v1.short_name;
          } else {
            address_components[v2] = v1.long_name;
          }
        });
      });

      const timezoneId = (0, _tzLookup.default)(this.model.location.latitude, this.model.location.longitude);
      this.set('model.location.timezone_id', timezoneId);

      if (address_components.street_number && address_components.route) {
        this.set('model.location.address', address_components.street_number + " " + address_components.route);
      } else if (address_components.route) {
        this.set('model.location.address', "" + address_components.route);
      } else {
        this.set('model.location.address', '');
      }

      const city = address_components.locality || address_components.administrative_area_level_3 || address_components.neighborhood;

      if (city) {
        this.set('model.location.city', city);
      } else {
        this.set('model.location.city', '');
      }

      if (address_components.administrative_area_level_1) {
        this.set('model.location.state', address_components.administrative_area_level_1);
      } else {
        this.set('model.location.state', '');
      }

      if (address_components.country) {
        this.set('model.location.country', address_components.country);
      } else {
        this.set('model.location.country', '');
      }

      if (address_components.postal_code) {
        this.set('model.location.postal_code', address_components.postal_code);
      } else {
        this.set('model.location.postal_code', '');
      }
    },
    addMarker: function (latLng) {
      let {
        icon,
        draggable,
        label
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (icon == null) {
        icon = {
          url: '/assets/images/map-icon-pin.svg',
          scaledSize: new google.maps.Size(34, 34)
        };
      }

      if (draggable == null) {
        draggable = false;
      }

      const animation = google.maps.Animation.DROP;
      const marker = new google.maps.Marker({
        map: this.map,
        icon: icon,
        position: latLng,
        draggable: draggable,
        animation: animation,
        label: label,
        crossOnDrag: false
      });
      this.markers.push(marker);
      return marker;
    },
    checkAirspace: function (place) {
      _jquery.default.ajax({
        headers: this.sessionAccount.headers,
        url: _environment.default.api.host + "/v3/clients/airspaces/check",
        data: {
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng()
        }
      }).then(data => {
        return this.updateAirspace(data);
      });
    },
    actions: {
      updateAirspace: function (data) {
        this.updateAirspace(data);
      },
      processSearch: function () {
        this.processSearch();
      },
      fetchUsers: function () {
        this.fetchUsers();
      },
      showMatchingClients: function () {
        this.set('showOrgClients', true);
      },
      hideMatchingClients: function () {
        this.set('showOrgClients', false);
      },
      selectClient: function (client) {
        let clients = this.orgClients.map(orgClient => {
          if (orgClient.email == client.email) {
            return { ...orgClient,
              hasAccess: !client.hasAccess,
              isWatcher: !client.hasAccess
            };
          }

          return orgClient;
        });
        this.set('model.orgClients', clients);
      },
      toggleWatch: function (client) {
        let clients = this.orgClients.map(orgClient => {
          if (orgClient.email == client.email) {
            return { ...orgClient,
              isWatcher: !client.isWatcher
            };
          }

          return orgClient;
        });
        this.set('model.orgClients', clients);
      },
      removeClient: function (client) {
        let clients = this.orgClients.map(orgClient => {
          if (orgClient.email == client.email) {
            return { ...orgClient,
              isWatcher: client.constIsWatching,
              hasAccess: client.constHasAccess
            };
          }

          return orgClient;
        });
        this.set('model.orgClients', clients);
      },
      clearAddress: function () {
        this.set('model.location.name', '');
        this.set('model.location.timezone_id', null);
        this.set('model.location.latitude', '');
        this.set('model.location.longitude', '');
        this.set('model.location.address', '');
        this.set('model.location.city', '');
        this.set('model.location.state', '');
        this.set('model.location.postal_code', '');
        this.set('model.location.country', '');
        this.set('model.location.formatted_address', '');
        this.set('addressesDontMatch', false);
        this.set('addressBarFilled', false);
        this.clearMap();
        (0, _jquery.default)('#search-field').val('').focus();
      },
      increaseOrderStep: function (skipCheck) {
        const valueFromInput = (0, _jquery.default)('.search-field').val();
        const modelAddress = this.model.location.formatted_address;
        const latLong = this.model.location.latitude + ',' + this.model.location.longitude;

        if (skipCheck) {
          return this.increaseOrderStepAction();
        }

        if (valueFromInput === modelAddress || valueFromInput === latLong) {
          this.increaseOrderStepAction();
        } else {
          this.set('addressesDontMatch', true);
          (0, _jquery.default)('.orderflow-steps-container')[0].scrollTop = 0;
        }
      }
    }
  });

  _exports.default = _default;
});