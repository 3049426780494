define("clients/mirage/models/mission", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Model.extend({
    location: (0, _emberCliMirage.belongsTo)(),
    package: (0, _emberCliMirage.belongsTo)(),
    missionGroups: (0, _emberCliMirage.hasMany)(),
    currency: (0, _emberCliMirage.belongsTo)()
  });

  _exports.default = _default;
});