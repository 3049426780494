define("clients/templates/components/mission-group/base-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LQNsF2/A",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"group-header\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[1,[34,0]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[\"loading-data \",[30,[36,2],[[35,1],\"hidden\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"box\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sk-circle\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"spinner\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect1\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect2\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect3\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect4\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect5\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"title\",\"isSaving\",\"unless\"]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-group/base-modal.hbs"
    }
  });

  _exports.default = _default;
});