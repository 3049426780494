define("clients/helpers/DBMap/format-length", ["exports", "clients/helpers/DBMap/meters-to-feet"], function (_exports, _metersToFeet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatLength = _exports.default = void 0;

  const formatLength = function () {
    const length = arguments.length <= 0 ? undefined : arguments[0];

    if (length != null && typeof length != 'undefined') {
      let formatted = `${length} meter`;

      if (length !== 1.0) {
        formatted += 's';
      }

      return `${formatted} (${(0, _metersToFeet.metersToFeet)(length)})`;
    } else {
      return '';
    }
  };

  _exports.formatLength = formatLength;

  var _default = Ember.Helper.helper(formatLength);

  _exports.default = _default;
});