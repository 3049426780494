define("clients/templates/components/mission-plan-shot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NQMMtg2n",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"map-list-shot\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"content\",\"optionValuePath\",\"optionLabelPath\",\"value\"],[[35,1],\"id\",\"name\",[35,0,[\"id\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"placeholder\",\"value\",\"class\",\"rows\"],[\"Notes\",[35,0,[\"notes\"]],\"controls\",2]]]],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-xs btn-danger pull-right\"],[24,4,\"button\"],[4,[38,4],[[32,0],\"remove_shot\",[35,0]],null],[12],[2,\"remove\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"shot\",\"shot_list\",\"mission-plan-shot-select\",\"textarea\",\"action\"]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-plan-shot.hbs"
    }
  });

  _exports.default = _default;
});