define("clients/components/order-flow/weather-examples-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var WeatherExamplesModalComponent;
  WeatherExamplesModalComponent = Ember.Component.extend({
    isFinished: false,
    actions: {
      close: function () {
        $('.weather-examples-modal').addClass('is-closing');
        return Ember.run.later(function (_this) {
          return function () {
            if (_this.get('showModal')) {
              return _this.set('showModal', false);
            }
          };
        }(this), 300);
      },
      weatherSelected: function () {
        this.set('isFinished', true);
        return Ember.run.later(function (_this) {
          return function () {
            return _this.send('close');
          };
        }(this), 1000);
      }
    }
  });
  var _default = WeatherExamplesModalComponent;
  _exports.default = _default;
});