define("clients/models/bucket", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    attachments: (0, _model.hasMany)('attachment', {
      async: false
    }),
    mission: (0, _model.belongsTo)('mission'),
    bucket_type: (0, _model.belongsTo)('bucket_type'),
    promotedAttachments: Ember.computed('attachments.[]', function () {
      return this.attachments.filterBy('final', true).filterBy('processing_status', 'ready');
    })
  });

  _exports.default = _default;
});