define("clients/templates/components/mission-list-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vGixbtw/",
    "block": "{\"symbols\":[\"mission\"],\"statements\":[[10,\"div\"],[14,0,\"mission-list-group\"],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"showOrgMissions\",\"current_date_column\",\"mission\",\"expanded\",\"hasBundle\",\"action\",\"payAction\",\"selectedMissions\",\"toggleMissionAction\",\"unselectAllAction\",\"openContextMenuAction\"],[[35,3],[35,2],[35,10],[35,9],[35,8],[30,[36,0],[[32,0],\"showMission\"],null],[30,[36,0],[[32,0],\"pay\"],null],[35,1],[30,[36,0],[[32,0],\"toggleMission\"],null],[30,[36,0],[[32,0],\"unselectAll\"],null],[30,[36,0],[[32,0],\"openContextMenu\"],null]]]]],[2,\"\\n\"],[6,[37,11],[[35,9]],null,[[\"default\"],[{\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,4],null,[[\"showOrgMissions\",\"current_date_column\",\"mission\",\"missionTail\",\"action\",\"payAction\",\"selectedMissions\",\"toggleMissionAction\",\"unselectAllAction\",\"openContextMenuAction\"],[[35,3],[35,2],[32,1],\"true\",[30,[36,0],[[32,0],\"showMission\"],null],[30,[36,0],[[32,0],\"pay\"],null],[35,1],[30,[36,0],[[32,0],\"toggleMission\"],null],[30,[36,0],[[32,0],\"unselectAll\"],null],[30,[36,0],[[32,0],\"openContextMenu\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"selectedMissions\",\"current_date_column\",\"showOrgMissions\",\"mission-list-item\",\"missionsTail\",\"-track-array\",\"each\",\"hasBundle\",\"expanded\",\"missionsHead\",\"if\"]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-list-group.hbs"
    }
  });

  _exports.default = _default;
});