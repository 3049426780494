define("clients/models/image", ["exports", "@ember-data/model", "clients/models/shareable"], function (_exports, _model, _shareable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _shareable.default.extend({
    name: (0, _model.attr)('string'),
    version_urls: (0, _model.attr)(),
    processing: (0, _model.attr)('boolean'),
    mission: (0, _model.belongsTo)('mission', {
      async: false
    }),
    shot: (0, _model.belongsTo)('shot'),
    source_type: (0, _model.attr)('string'),
    file: null,
    uploadNumber: null,
    isSelected: false,
    generatingNativeThumbnail: false,
    nativeThumbnail: null,
    uploaded: false,
    shortName: Ember.computed('name', function () {
      if (this.get('name').length > 24) {
        return this.get('name').substring(0, 19) + '...' + this.get('name').substring(this.get('name').length - 8);
      } else {
        return this.get('name');
      }
    }),
    hasProperId: Ember.computed('name', 'id', function () {
      return this.get('id').indexOf('DB') > -1;
    }),
    downloadUrl: Ember.computed.reads('version_urls.download'),
    sanatizedName: Ember.computed('name', function () {
      if (!this.get('name').match(/DJI/)) {
        return this.get('name').replace(/DBUA?\d*-/, '').replace(/\.[a-zA-z]*$/, '').replace(/_/g, ' ').replace(/\.(.*)/, '');
      }

      return null;
    }),
    thumbnail: Ember.computed.reads('version_urls.square_640'),
    collabThumbnail: Ember.computed.reads('version_urls.small_640'),
    jpgUrl: Ember.computed('downloadUrl', 'version_urls.large_1920', function () {
      if (this.get('downloadUrl')) {
        if (this.get('downloadUrl').toLowerCase().indexOf(".jpg") === -1) {
          return this.get('version_urls.large_1920');
        } else {
          return this.get('downloadUrl');
        }
      }

      return null;
    }),
    isPromoted: Ember.computed.equal('source_type', 'edit'),
    saveAsUpdate: function () {
      this.send('willCommit');
      this.send('didCommit');
      return this.save();
    }
  });

  _exports.default = _default;
});