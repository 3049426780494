define("clients/serializers/credit_card", ["exports", "clients/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CreditCardSerializer;
  CreditCardSerializer = _application.default.extend();
  var _default = CreditCardSerializer;
  _exports.default = _default;
});