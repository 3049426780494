define("clients/components/order-flow/site-details", ["exports", "clients/mixins/google-map-features-mixin", "jquery"], function (_exports, _googleMapFeaturesMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_googleMapFeaturesMixin.default, {
    sessionAccount: Ember.inject.service(),
    isClientUpload: Ember.computed.equal('model.orderFlow', 'clientUpload'),
    hasLocation: Ember.computed.or('model.location.address', 'model.location.latitude'),
    hasGeoLocation: Ember.computed.and('model.location.longitude', 'model.location.latitude'),
    classNames: ['order-flow-information', 'site-details'],
    DEFAULT_ZOOM: 18,
    activeTab: 'missionDetails',
    initMap: function () {
      const mapOptions = {
        disableDoubleClickZoom: false,
        draggable: true,
        fullscreenControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT
        },
        mapTypeId: google.maps.MapTypeId.HYBRID,
        panControl: false,
        scaleControl: false,
        streetViewControl: false,
        styles: this.mapStyles,
        tilt: 0,
        zoom: this.DEFAULT_ZOOM,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        }
      };

      if (!this.map) {
        this.map = new google.maps.Map((0, _jquery.default)('#map_canvas')[0], mapOptions);
      }

      this.markers = [];
      this.setupListeners();
      this.setupInfoWindow();
      this.loadData();
    },
    actions: {
      changeToPanoramaIcon(selectedFeature) {
        this.changeToPanoramaIcon(selectedFeature);
      },

      changeToPointIcon(selectedFeature) {
        this.changeToPointIcon(selectedFeature);
      },

      increaseOrderStep: function () {
        const isValidCurrentFeature = !this.selectedFeature || this.validateFeature(this.selectedFeature);
        this.validateFeatures();

        if (isValidCurrentFeature && this.errors.length === 0) {
          this.increaseOrderStepAction();
        }
      },
      activateTab: function (tabName) {
        this.set('activeTab', tabName);
      },
      addPanorama: function () {
        this.addFeature(this.featureOptions.PANORAMA);
      },
      addPointOfInterest: function () {
        this.addFeature(this.featureOptions.POINTOFINTEREST);
      },

      addPolygon() {
        this.addFeature(this.featureOptions.PROPERTYAREA);
      },

      updatePolygon() {
        this.updateFeature();
      },

      removePolygon(feature) {
        this.removeFeature(feature);
      }

    }
  });

  _exports.default = _default;
});