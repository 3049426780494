define("clients/routes/four-oh-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FourOhFourRoute;
  FourOhFourRoute = Ember.Route.extend({
    setupController: function (controller, model) {
      this._super(controller, model);

      this.controllerFor('application').set('showNavBar', true);
      return this.controllerFor('application').set('minimizedSidebar', false);
    },
    redirect: function () {
      if (window.location.pathname !== '/four-oh-four') {
        return this.transitionTo('/four-oh-four');
      }
    }
  });
  var _default = FourOhFourRoute;
  _exports.default = _default;
});