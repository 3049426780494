define("clients/templates/sample-mission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XNNiy+nn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"top-banner sample-mission\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    You are viewing an example Mission. Ready to place your first order?\\n  \"],[13],[2,\"\\n  \"],[8,\"button\",[[4,[38,1],[[32,0],\"trackGoToOrderFlow\",[35,0,[\"mission\"]]],[[\"bubbles\"],[false]]]],[[\"@type\"],[\"secondary\"]],[[\"default\"],[{\"statements\":[[2,\"Order now\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"sample-mission-padding\"],[12],[2,\"\\n  \"],[1,[30,[36,2],null,[[\"mission\",\"sampleMission\",\"sampleMissionId\"],[[35,0,[\"mission\"]],true,[35,0,[\"sampleMissionId\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action\",\"mission-view/mission-view\"]}",
    "meta": {
      "moduleName": "clients/templates/sample-mission.hbs"
    }
  });

  _exports.default = _default;
});