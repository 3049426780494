define("clients/components/order-flow/payment-info", ["exports", "clients/validations/credit_card", "ember-changeset-validations", "ember-changeset"], function (_exports, _credit_card, _emberChangesetValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PaymentInfoComponent;
  PaymentInfoComponent = Ember.Component.extend(_credit_card.default, {
    metrics: Ember.inject.service(),
    creditCard: Ember.computed('model.mission', function () {
      var cc;
      cc = this.get('model.mission.credit_card');

      if (cc == null) {
        return this.store.createRecord('credit_card');
      }
    }),
    loggerTracking: Ember.on('init', function () {
      return this.get('metrics').trackPage({
        hitType: 'pageview',
        page: '/order_flow/payment'
      });
    }),
    tagName: 'form',
    role: 'form',
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    stripe: Ember.inject.service(),
    defaultCard: Ember.computed('model.client.credit_cards.[]', function () {
      return this.get('model.client.credit_cards.lastObject');
    }),
    last_4: Ember.computed('credit_card.number', function () {
      if (this.get('credit_card.number').length > 4) {
        return this.get('credit_card.number').substring(this.get('credit_card.number').length - 4);
      } else {
        return "credit card";
      }
    }),
    initChangeSet: Ember.on('init', function () {
      this.set('credit_card', this.get('store').createRecord('credit_card'));
      this.changeset = new _emberChangeset.default(this.get('credit_card') ? this.get('credit_card') : this.get('store').createRecord('credit_card'), (0, _emberChangesetValidations.default)(_credit_card.default), _credit_card.default);
      return this.set('useDefaultCard', true);
    }),
    creditCardParams: function () {
      var expireDate;
      expireDate = this.get('credit_card.exp_date').replace(' ', '').split('/');
      return {
        number: this.get('credit_card.number').replace(' ', ''),
        exp_month: Number(expireDate[0]),
        exp_year: Number(expireDate[1]),
        cvc: this.get('credit_card.cvc')
      };
    },
    authCreditCard: function () {
      var card;
      card = this.get('enteredCard') || this.get('defaultCard');
      this.set('model.mission.credit_card', card);
      return this.increaseOrderStepAction();
    },
    submitCreditCard: function () {
      this.set('saveState', 'inProgress');
      this.changeset.set('number', this.get('credit_card.number'));
      this.changeset.set('exp_date', this.get('credit_card.exp_date'));
      this.changeset.set('cvc', this.get('credit_card.cvc'));
      this.changeset.set('processing', null);
      return this.changeset.validate().then(function (_this) {
        return function () {
          var i, input, inputs, len;

          if (_this.changeset.get('isInvalid')) {
            inputs = document.getElementsByTagName('input');

            for (i = 0, len = inputs.length; i < len; i++) {
              input = inputs[i];
              input.focus();
              input.blur();
            }

            _this.set('saveState', 'error');

            Ember.run.later(function () {
              if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
                return _this.set('saveState', 'initial');
              }
            }, 1000);
            return;
          }

          _this.set('cardAdded', true);

          return _this.get('stripe').card.createToken(_this.creditCardParams()).then(function (response) {
            var creditCard;
            creditCard = _this.get('credit_card');
            creditCard.set('provider_token', response.id);
            creditCard.set('stripe_id', response.card.id);
            return creditCard.save().then(function (response) {
              _this.set('saveState', 'success');

              Ember.run.later(function () {
                if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
                  return _this.set('saveState', 'initial');
                }
              }, 1000);

              _this.set('enteredCard', creditCard);

              return _this.authCreditCard();
            }, function (response) {
              _this.set('saveState', 'error');

              Ember.run.later(function () {
                if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
                  return _this.set('saveState', 'initial');
                }
              }, 1000);

              _this.set('cardAdded', false);

              _this.set('errors', response.errors);

              return _this.set('showErrorModal', true);
            });
          }, function (response) {
            _this.set('saveState', 'error');

            Ember.run.later(function () {
              if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
                return _this.set('saveState', 'initial');
              }
            }, 1000);

            _this.set('cardAdded', false);

            return _this.changeset.pushErrors('processing', response.error.message);
          });
        };
      }(this));
    },
    actions: {
      toggleShowTooltip: function () {
        return this.set('showTooltip', !this.get('showTooltip'));
      },
      toggleModal: function () {
        return this.set('showErrorModal', !this.get('showErrorModal'));
      },
      toggleUseDefaultCard: function () {
        return this.set('useDefaultCard', !this.get('useDefaultCard'));
      },
      increaseOrderStep: function () {
        if (this.get('useDefaultCard') && this.get('defaultCard')) {
          return this.authCreditCard();
        } else {
          return this.submitCreditCard();
        }
      }
    }
  });
  var _default = PaymentInfoComponent;
  _exports.default = _default;
});