define("clients/utils/trim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const trim = (() => {
    if (String.prototype.trim) {
      return string => (string || '').trim();
    } else {
      const rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
      return string => (string || '').replace(rtrim, '');
    }
  })();

  var _default = trim;
  _exports.default = _default;
});