define("clients/components/mission-view/mission-assets-thumb-view", ["exports", "clients/components/mission-view/mission-assets-base-view"], function (_exports, _missionAssetsBaseView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionAssetsThumbViewComponent;
  MissionAssetsThumbViewComponent = _missionAssetsBaseView.default.extend({
    didInsertElement: function () {
      $('#thumb-gallery').lightGallery({
        mode: 'lg-slide',
        videojs: true,
        autoplayFirstVideo: false,
        videoMaxWidth: 1024,
        download: true,
        selector: 'a.gallery'
      });
      return this.send('setHeight');
    },
    actions: {
      download: function (asset) {
        return window.location = asset.get('downloadUrl');
      },
      toggleShowHiddenAssets: function (shot, field) {
        shot.set(field, !shot.get(field));
        return Ember.run.later(function (_this) {
          return function () {
            return _this.send('setHeight', shot.id);
          };
        }(this), 2);
      },
      setHeight: function (shotId) {
        var margin;

        if (shotId == null) {
          shotId = null;
        }

        margin = parseInt($('.item-thumb').first().css('margin-right'));

        if (shotId) {
          return $('#shot-' + shotId).children('.item-thumb').each(function (index, el) {
            return el.style.marginBottom = margin * 2 + 'px';
          });
        } else {
          return $('.item-thumb').each(function (index, el) {
            return el.style.marginBottom = margin * 2 + 'px';
          });
        }
      },
      toggleOrthoModal: function (ortho) {
        return this.sendAction('orthoModalToggle', ortho);
      }
    }
  });
  var _default = MissionAssetsThumbViewComponent;
  _exports.default = _default;
});