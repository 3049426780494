define("clients/templates/components/admin-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yOtbdotq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"top-admin-notification\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"admin-text-wrapper\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"admin-text\"],[12],[2,\"\\n      YOU ARE LOGGED IN AS \"],[1,[34,0]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"vertical-divider\"],[12],[13],[2,\"\\n      \"],[11,\"span\"],[24,0,\"logout-link\"],[4,[38,1],[[32,0],\"adminLogout\"],null],[12],[2,\" LOG OUT \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"userName\",\"action\"]}",
    "meta": {
      "moduleName": "clients/templates/components/admin-notification.hbs"
    }
  });

  _exports.default = _default;
});