define("clients/data/sample_missions/rre_images_package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": {
      "id": "DBM6666248298",
      "type": "missions",
      "attributes": {
        "instructions": null,
        "status": "complete",
        "client_id": "DBC3006554",
        "payment": {
          "id": null,
          "processor": null,
          "card": null
        },
        "external_assets_url": null,
        "owned_by_current_client": true,
        "contact_email": "clients@zeitview.com",
        "created_on": "2019-12-02T19:36:26Z",
        "is_pano_mission": false,
        "logo_url": null,
        "blurb": null,
        "paid": true,
        "paid_on": "2019-12-02T19:46:40Z",
        "price": 19900,
        "archive_url": null,
        "edit_url": null,
        "customer_instructions": "Weekend only. Fly between 12 \u2013 2pm.",
        "scheduled_at_start": "2019-12-03T19:00:00Z",
        "scheduled_at_end": "2019-12-03T20:00:00Z",
        "parent_id": null,
        "reshoot_mission_id": null,
        "rejection_reason": null,
        "rejection_notes": null,
        "reference_id": "RRE Images Sample Mission",
        "cloud_reqs": "less_than_50",
        "scheduling_requirement": "not_needed",
        "arcgis_feature_layer": null,
        "geo_properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-118.49759433232, 34.012016399506], [-118.50167525563, 34.008512347399], [-118.49890721593, 34.00633790776], [-118.49493358098, 34.009432944044], [-118.49759433232, 34.012016399506]]]
            },
            "properties": {
              "type": "Property Area",
              "name": "Property Area #1",
              "notes": "",
              "area": 190419,
              "bounds": {
                "south": 34.00633790776,
                "west": -118.50167525563,
                "north": 34.012016399506,
                "east": -118.49493358098
              },
              "center": {
                "lat": 34.009177153633,
                "lng": -118.49830441831
              },
              "shots": null
            },
            "id": 1
          }, {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [-118.49713497518, 34.011347223214]
            },
            "properties": {
              "type": "Point of Interest",
              "name": "Point of Interest #1",
              "notes": "Pier and Beach",
              "shots": null
            },
            "id": 2
          }]
        }
      },
      "relationships": {
        "location": {
          "data": {
            "id": "253458",
            "type": "locations"
          }
        },
        "package": {
          "data": {
            "id": "20038",
            "type": "packages"
          }
        },
        "credit_card": {
          "data": null
        },
        "onsite_contact": {
          "data": {
            "id": "49416",
            "type": "onsite_contacts"
          }
        },
        "scheduling_contact": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "images": {
          "data": [{
            "id": "DBI0397520340",
            "type": "images"
          }, {
            "id": "DBI9071784788",
            "type": "images"
          }, {
            "id": "DBI7900429376",
            "type": "images"
          }, {
            "id": "DBI4005900027",
            "type": "images"
          }, {
            "id": "DBI3407408158",
            "type": "images"
          }, {
            "id": "DBI0157719244",
            "type": "images"
          }, {
            "id": "DBI0233896126",
            "type": "images"
          }, {
            "id": "DBI2981756806",
            "type": "images"
          }, {
            "id": "DBI8730820718",
            "type": "images"
          }, {
            "id": "DBI9443880650",
            "type": "images"
          }, {
            "id": "DBI5691912337",
            "type": "images"
          }, {
            "id": "DBI4415144282",
            "type": "images"
          }, {
            "id": "DBI7291158664",
            "type": "images"
          }, {
            "id": "DBI0217114478",
            "type": "images"
          }, {
            "id": "DBI3071786784",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        },
        "attachments": {
          "data": []
        },
        "ortho_photo_maps": {
          "data": []
        },
        "shares": {
          "data": [{
            "id": "d76874c840d83e474b946c65e17ba4dd",
            "type": "shares"
          }, {
            "id": "50a4cfce11faac38a9da800b8d99aed9",
            "type": "shares"
          }]
        },
        "main_image": {
          "data": {
            "id": "DBI0397520340",
            "type": "images"
          }
        },
        "collaborators": {
          "data": []
        },
        "parent": {
          "data": null
        },
        "shots": {
          "data": [{
            "id": "1059857",
            "type": "shots"
          }, {
            "id": "1059856",
            "type": "shots"
          }, {
            "id": "1059855",
            "type": "shots"
          }, {
            "id": "1059854",
            "type": "shots"
          }]
        },
        "invoice_line_items": {
          "data": [{
            "id": "DBINV757390701889653",
            "type": "invoice_line_items"
          }]
        }
      }
    },
    "included": [{
      "id": "253458",
      "type": "locations",
      "attributes": {
        "name": "19 Misty Vale Rd, Sandy Hook, CT 06482, USA",
        "latitude": 41.4123638,
        "longitude": -73.2534763,
        "address": "19 Misty Vale Road",
        "address2": "",
        "city": "Newtown",
        "state": "Connecticut",
        "postal_code": "06482",
        "country": "United States",
        "formatted_address": "19 Misty Vale Rd, Sandy Hook, CT 06482, USA",
        "timezone_id": "America/New_York",
        "properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-118.49759433232, 34.012016399506], [-118.50167525563, 34.008512347399], [-118.49890721593, 34.00633790776], [-118.49493358098, 34.009432944044], [-118.49759433232, 34.012016399506]]]
            },
            "properties": {
              "type": "Property Area",
              "name": "Property Area #1",
              "notes": "",
              "area": 190419,
              "bounds": {
                "south": 34.00633790776,
                "west": -118.50167525563,
                "north": 34.012016399506,
                "east": -118.49493358098
              },
              "center": {
                "lat": 34.009177153633,
                "lng": -118.49830441831
              },
              "shots": null
            },
            "id": 1
          }, {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [-118.49713497518, 34.011347223214]
            },
            "properties": {
              "type": "Point of Interest",
              "name": "Point of Interest #1",
              "notes": "Pier and Beach",
              "shots": null
            },
            "id": 2
          }]
        }
      }
    }, {
      "id": "20038",
      "type": "packages",
      "attributes": {
        "name": "RRE Images Package Sample ",
        "description": null,
        "price": 19900,
        "cloud_reqs": "less_than_50",
        "position": null,
        "slug": "rre-images-pack"
      },
      "relationships": {
        "vertical": {
          "data": {
            "id": "2",
            "type": "verticals"
          }
        },
        "organization": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "catalog_items": {
          "data": []
        }
      }
    }, {
      "id": "2",
      "type": "verticals",
      "attributes": {
        "name": "Residential Real Estate",
        "short_name": "RRE"
      }
    }, {
      "id": "49416",
      "type": "onsite_contacts",
      "attributes": {
        "name": null,
        "call_action": null,
        "phone": null,
        "note": null
      }
    }, {
      "id": "DBC3006554",
      "type": "clients",
      "attributes": {
        "first_name": "Demo",
        "last_name": "Zeitview",
        "phone": "",
        "email": "clients@zeitview.com",
        "token": "qC65CqCmbKfMZvIU2gRb_uD7Sqw",
        "company_name": null,
        "invoiceable": false
      },
      "relationships": {
        "hidden_sample_missions": {
          "data": [{
            "id": "*",
            "type": "hidden_sample_mission"
          }]
        }
      }
    }, {
      "id": "DBI0397520340",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10029617-3130debd76ff5e143a0174320e0c2e031e6aab66/original-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
        "name": "DBI-16-h1p4ac6z-downloadable-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
        "source_type": "edit",
        "formatted_size": "4.58 MB",
        "size": 4801035,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355583333333,
        "gps_longitude": -73.396008333333,
        "gps_altitude": 216.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10029617-3130debd76ff5e143a0174320e0c2e031e6aab66/large_1920-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10029617-3130debd76ff5e143a0174320e0c2e031e6aab66/medium_1024-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10029617-3130debd76ff5e143a0174320e0c2e031e6aab66/small_640-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10029617-3130debd76ff5e143a0174320e0c2e031e6aab66/four_three_640-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10029617-3130debd76ff5e143a0174320e0c2e031e6aab66/square_640-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10029617-3130debd76ff5e143a0174320e0c2e031e6aab66/downloadable-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG"
        },
        "created_on": "2019-12-05T20:54:43Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059856",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9071784788",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10001268-e232cf7363c09d417502e63b6ac79f783e43aa31/original-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
        "name": "DBI-15-HLXfh4Oj-DBUV011-DBM0011938845-100-06.JPG",
        "source_type": "edit",
        "formatted_size": "4.67 MB",
        "size": 4894254,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355383333333,
        "gps_longitude": -73.395783333333,
        "gps_altitude": 142.2,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10001268-e232cf7363c09d417502e63b6ac79f783e43aa31/large_1920-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10001268-e232cf7363c09d417502e63b6ac79f783e43aa31/medium_1024-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10001268-e232cf7363c09d417502e63b6ac79f783e43aa31/small_640-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10001268-e232cf7363c09d417502e63b6ac79f783e43aa31/four_three_640-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10001268-e232cf7363c09d417502e63b6ac79f783e43aa31/square_640-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10001268-e232cf7363c09d417502e63b6ac79f783e43aa31/downloadable-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG"
        },
        "created_on": "2019-12-02T19:43:37Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059855",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7900429376",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10001267-36a93f34435bfc6e1983f6388e590f140b42da83/original-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
        "name": "DBI-14-XlMSwmdM-DBU14-DBM0011938845_100_08.JPG",
        "source_type": "edit",
        "formatted_size": "4.52 MB",
        "size": 4737475,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355919444444,
        "gps_longitude": -73.396211111111,
        "gps_altitude": 157.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10001267-36a93f34435bfc6e1983f6388e590f140b42da83/large_1920-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10001267-36a93f34435bfc6e1983f6388e590f140b42da83/medium_1024-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10001267-36a93f34435bfc6e1983f6388e590f140b42da83/small_640-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10001267-36a93f34435bfc6e1983f6388e590f140b42da83/four_three_640-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10001267-36a93f34435bfc6e1983f6388e590f140b42da83/square_640-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10001267-36a93f34435bfc6e1983f6388e590f140b42da83/downloadable-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG"
        },
        "created_on": "2019-12-02T19:43:33Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059855",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4005900027",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10001266-c427064f662be596e5445d51ad852aacc22cbf79/original-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
        "name": "DBI-13-iBuGiRo5-DBU13-DBM0011938845_100_07.JPG",
        "source_type": "edit",
        "formatted_size": "4.42 MB",
        "size": 4638336,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355772222222,
        "gps_longitude": -73.396416666667,
        "gps_altitude": 145.3,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10001266-c427064f662be596e5445d51ad852aacc22cbf79/large_1920-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10001266-c427064f662be596e5445d51ad852aacc22cbf79/medium_1024-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10001266-c427064f662be596e5445d51ad852aacc22cbf79/small_640-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10001266-c427064f662be596e5445d51ad852aacc22cbf79/four_three_640-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10001266-c427064f662be596e5445d51ad852aacc22cbf79/square_640-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10001266-c427064f662be596e5445d51ad852aacc22cbf79/downloadable-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG"
        },
        "created_on": "2019-12-02T19:43:27Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059855",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3407408158",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10001265-a46b469dd136abc3549d9e0ed66c3bd8d98a416d/original-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
        "name": "DBI-12-4SRLa7ST-DBU11-DBM0011938845_100_05.JPG",
        "source_type": "edit",
        "formatted_size": "4.63 MB",
        "size": 4855984,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355555555556,
        "gps_longitude": -73.396355555556,
        "gps_altitude": 142,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10001265-a46b469dd136abc3549d9e0ed66c3bd8d98a416d/large_1920-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10001265-a46b469dd136abc3549d9e0ed66c3bd8d98a416d/medium_1024-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10001265-a46b469dd136abc3549d9e0ed66c3bd8d98a416d/small_640-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10001265-a46b469dd136abc3549d9e0ed66c3bd8d98a416d/four_three_640-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10001265-a46b469dd136abc3549d9e0ed66c3bd8d98a416d/square_640-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10001265-a46b469dd136abc3549d9e0ed66c3bd8d98a416d/downloadable-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG"
        },
        "created_on": "2019-12-02T19:43:25Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059855",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0157719244",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10001264-b59ada1b2e477c93b8caa4d31e16f580f2596e89/original-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
        "name": "DBI-11-TyMesdTQ-DBU10-DBM0011938845_100_04.JPG",
        "source_type": "edit",
        "formatted_size": "4.56 MB",
        "size": 4781833,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.3555,
        "gps_longitude": -73.396011111111,
        "gps_altitude": 138.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10001264-b59ada1b2e477c93b8caa4d31e16f580f2596e89/large_1920-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10001264-b59ada1b2e477c93b8caa4d31e16f580f2596e89/medium_1024-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10001264-b59ada1b2e477c93b8caa4d31e16f580f2596e89/small_640-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10001264-b59ada1b2e477c93b8caa4d31e16f580f2596e89/four_three_640-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10001264-b59ada1b2e477c93b8caa4d31e16f580f2596e89/square_640-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10001264-b59ada1b2e477c93b8caa4d31e16f580f2596e89/downloadable-522213e7e7d5eb7e7652b936adf67054e121094a.JPG"
        },
        "created_on": "2019-12-02T19:43:23Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059855",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0233896126",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10001263-b9c0228865bbcfcc0a3e0dfca85ebbecfad799a0/original-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
        "name": "DBI-10-jAcz8tTn-DBU9-DBM0011938845_100_03.JPG",
        "source_type": "edit",
        "formatted_size": "4.51 MB",
        "size": 4726912,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355333333333,
        "gps_longitude": -73.396083333333,
        "gps_altitude": 140.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10001263-b9c0228865bbcfcc0a3e0dfca85ebbecfad799a0/large_1920-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10001263-b9c0228865bbcfcc0a3e0dfca85ebbecfad799a0/medium_1024-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10001263-b9c0228865bbcfcc0a3e0dfca85ebbecfad799a0/small_640-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10001263-b9c0228865bbcfcc0a3e0dfca85ebbecfad799a0/four_three_640-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10001263-b9c0228865bbcfcc0a3e0dfca85ebbecfad799a0/square_640-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10001263-b9c0228865bbcfcc0a3e0dfca85ebbecfad799a0/downloadable-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG"
        },
        "created_on": "2019-12-02T19:43:21Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059855",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2981756806",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10001260-7e86d87e09acaf53fbc8b9c557214b7b3a6f7f6c/original-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
        "name": "DBI-9-UxTGHgze-DBU8-DBM0011938845_100_02.JPG",
        "source_type": "edit",
        "formatted_size": "4.37 MB",
        "size": 4582821,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355719444444,
        "gps_longitude": -73.395705555556,
        "gps_altitude": 123.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10001260-7e86d87e09acaf53fbc8b9c557214b7b3a6f7f6c/large_1920-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10001260-7e86d87e09acaf53fbc8b9c557214b7b3a6f7f6c/medium_1024-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10001260-7e86d87e09acaf53fbc8b9c557214b7b3a6f7f6c/small_640-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10001260-7e86d87e09acaf53fbc8b9c557214b7b3a6f7f6c/four_three_640-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10001260-7e86d87e09acaf53fbc8b9c557214b7b3a6f7f6c/square_640-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10001260-7e86d87e09acaf53fbc8b9c557214b7b3a6f7f6c/downloadable-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG"
        },
        "created_on": "2019-12-02T19:43:17Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059855",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8730820718",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10001259-f411831416f36571fc1c1cc1f3f2678dc5279ac5/original-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
        "name": "DBI-8-eX9BmUwk-DBU7-DBM0011938845_100_01.JPG",
        "source_type": "edit",
        "formatted_size": "4.42 MB",
        "size": 4638672,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355897222222,
        "gps_longitude": -73.396075,
        "gps_altitude": 149.3,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10001259-f411831416f36571fc1c1cc1f3f2678dc5279ac5/large_1920-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10001259-f411831416f36571fc1c1cc1f3f2678dc5279ac5/medium_1024-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10001259-f411831416f36571fc1c1cc1f3f2678dc5279ac5/small_640-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10001259-f411831416f36571fc1c1cc1f3f2678dc5279ac5/four_three_640-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10001259-f411831416f36571fc1c1cc1f3f2678dc5279ac5/square_640-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10001259-f411831416f36571fc1c1cc1f3f2678dc5279ac5/downloadable-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG"
        },
        "created_on": "2019-12-02T19:43:16Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059855",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9443880650",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10001241-a5498b2076e876bacde7b91e207831c2b65fd9a0/original-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
        "name": "DBI-6-OD89DZQn-DBU6-DBM0011938845_10_06.JPG",
        "source_type": "edit",
        "formatted_size": "4.5 MB",
        "size": 4718345,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355719444444,
        "gps_longitude": -73.396205555556,
        "gps_altitude": 119.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10001241-a5498b2076e876bacde7b91e207831c2b65fd9a0/large_1920-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10001241-a5498b2076e876bacde7b91e207831c2b65fd9a0/medium_1024-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10001241-a5498b2076e876bacde7b91e207831c2b65fd9a0/small_640-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10001241-a5498b2076e876bacde7b91e207831c2b65fd9a0/four_three_640-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10001241-a5498b2076e876bacde7b91e207831c2b65fd9a0/square_640-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10001241-a5498b2076e876bacde7b91e207831c2b65fd9a0/downloadable-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG"
        },
        "created_on": "2019-12-02T19:42:46Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059857",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI5691912337",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10001240-a5498b2076e876bacde7b91e207831c2b65fd9a0/original-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
        "name": "DBI-5-tOlYzpvd-DBU5-DBM0011938845_10_05.JPG",
        "source_type": "edit",
        "formatted_size": "4.35 MB",
        "size": 4565311,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355588888889,
        "gps_longitude": -73.396216666667,
        "gps_altitude": 119.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10001240-a5498b2076e876bacde7b91e207831c2b65fd9a0/large_1920-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10001240-a5498b2076e876bacde7b91e207831c2b65fd9a0/medium_1024-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10001240-a5498b2076e876bacde7b91e207831c2b65fd9a0/small_640-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10001240-a5498b2076e876bacde7b91e207831c2b65fd9a0/four_three_640-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10001240-a5498b2076e876bacde7b91e207831c2b65fd9a0/square_640-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10001240-a5498b2076e876bacde7b91e207831c2b65fd9a0/downloadable-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG"
        },
        "created_on": "2019-12-02T19:42:46Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059857",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4415144282",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10001239-6212cff9e6df6034ba6ec4d4269e5b2581460559/original-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
        "name": "DBI-4-D2iBGBpD-DBU4-DBM0011938845_10_04.JPG",
        "source_type": "edit",
        "formatted_size": "4.34 MB",
        "size": 4550027,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355433333333,
        "gps_longitude": -73.396030555556,
        "gps_altitude": 116.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10001239-6212cff9e6df6034ba6ec4d4269e5b2581460559/large_1920-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10001239-6212cff9e6df6034ba6ec4d4269e5b2581460559/medium_1024-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10001239-6212cff9e6df6034ba6ec4d4269e5b2581460559/small_640-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10001239-6212cff9e6df6034ba6ec4d4269e5b2581460559/four_three_640-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10001239-6212cff9e6df6034ba6ec4d4269e5b2581460559/square_640-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10001239-6212cff9e6df6034ba6ec4d4269e5b2581460559/downloadable-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG"
        },
        "created_on": "2019-12-02T19:42:45Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059857",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7291158664",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10001238-6212cff9e6df6034ba6ec4d4269e5b2581460559/original-91116a8f05403c970ead6159446a774f35a328aa.JPG",
        "name": "DBI-3-sU2LRax9-DBU3-DBM0011938845_10_03.JPG",
        "source_type": "edit",
        "formatted_size": "4.3 MB",
        "size": 4506834,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355472222222,
        "gps_longitude": -73.395783333333,
        "gps_altitude": 116.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10001238-6212cff9e6df6034ba6ec4d4269e5b2581460559/large_1920-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10001238-6212cff9e6df6034ba6ec4d4269e5b2581460559/medium_1024-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10001238-6212cff9e6df6034ba6ec4d4269e5b2581460559/small_640-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10001238-6212cff9e6df6034ba6ec4d4269e5b2581460559/four_three_640-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10001238-6212cff9e6df6034ba6ec4d4269e5b2581460559/square_640-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10001238-6212cff9e6df6034ba6ec4d4269e5b2581460559/downloadable-91116a8f05403c970ead6159446a774f35a328aa.JPG"
        },
        "created_on": "2019-12-02T19:42:45Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059857",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0217114478",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10001237-145065530dd17652965adda52e6bd7c5bac50342/original-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
        "name": "DBI-2-nqAtZf0s-DBU2-DBM0011938845_10_02.JPG",
        "source_type": "edit",
        "formatted_size": "4.06 MB",
        "size": 4257980,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355738888889,
        "gps_longitude": -73.395716666667,
        "gps_altitude": 115.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10001237-145065530dd17652965adda52e6bd7c5bac50342/large_1920-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10001237-145065530dd17652965adda52e6bd7c5bac50342/medium_1024-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10001237-145065530dd17652965adda52e6bd7c5bac50342/small_640-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10001237-145065530dd17652965adda52e6bd7c5bac50342/four_three_640-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10001237-145065530dd17652965adda52e6bd7c5bac50342/square_640-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10001237-145065530dd17652965adda52e6bd7c5bac50342/downloadable-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG"
        },
        "created_on": "2019-12-02T19:42:44Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059857",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3071786784",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10001235-9110a3cd6d4ed705fca6c036fb474db58c3b4ad6/original-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
        "name": "DBI-1-Q7vW1Xih-DBU1-DBM0011938845_10_01.JPG",
        "source_type": "edit",
        "formatted_size": "4.22 MB",
        "size": 4422001,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355761111111,
        "gps_longitude": -73.396094444445,
        "gps_altitude": 115.9,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10001235-9110a3cd6d4ed705fca6c036fb474db58c3b4ad6/large_1920-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10001235-9110a3cd6d4ed705fca6c036fb474db58c3b4ad6/medium_1024-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10001235-9110a3cd6d4ed705fca6c036fb474db58c3b4ad6/small_640-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10001235-9110a3cd6d4ed705fca6c036fb474db58c3b4ad6/four_three_640-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10001235-9110a3cd6d4ed705fca6c036fb474db58c3b4ad6/square_640-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10001235-9110a3cd6d4ed705fca6c036fb474db58c3b4ad6/downloadable-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG"
        },
        "created_on": "2019-12-02T19:42:43Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059857",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "d76874c840d83e474b946c65e17ba4dd",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-04T23:22:19Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "50a4cfce11faac38a9da800b8d99aed9",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-04T23:22:12Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "1059857",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and you may need to adjust altitude as you work around obstacles (trees, power lines, etc).*  \n",
        "mission_id": "277952",
        "shot_type_id": 12,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (10 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "12",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI3071786784",
            "type": "images"
          }, {
            "id": "DBI9443880650",
            "type": "images"
          }, {
            "id": "DBI5691912337",
            "type": "images"
          }, {
            "id": "DBI4415144282",
            "type": "images"
          }, {
            "id": "DBI7291158664",
            "type": "images"
          }, {
            "id": "DBI0217114478",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "12",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (10 ft above ground level)",
        "description": "*If there are power lines do your best to avoid having them in frame. It is okay to fly higher or lower than roof level to avoid the power lines in the images.* ",
        "video": "",
        "slug": "images-cardinal-directions-10-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "35",
            "type": "shot_type_sample_images"
          }, {
            "id": "36",
            "type": "shot_type_sample_images"
          }, {
            "id": "37",
            "type": "shot_type_sample_images"
          }, {
            "id": "38",
            "type": "shot_type_sample_images"
          }, {
            "id": "39",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1059856",
      "type": "shots",
      "attributes": {
        "instructions": "(1) image from from above looking straight down at the property with the full home/lot in frame.",
        "mission_id": "277952",
        "shot_type_id": 18,
        "pilot_comment": "Not needed ",
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Image - Birds Eye "
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "18",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI0397520340",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "18",
      "type": "shot_types",
      "attributes": {
        "name": "Image - Birds Eye ",
        "description": "",
        "video": null,
        "slug": "image-birds-eye-photo-looking-straight-down-entire-property-in-frame",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "12",
            "type": "shot_type_sample_images"
          }, {
            "id": "13",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1059855",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "277952",
        "shot_type_id": 13,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (100 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "13",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI9071784788",
            "type": "images"
          }, {
            "id": "DBI7900429376",
            "type": "images"
          }, {
            "id": "DBI0157719244",
            "type": "images"
          }, {
            "id": "DBI4005900027",
            "type": "images"
          }, {
            "id": "DBI3407408158",
            "type": "images"
          }, {
            "id": "DBI0233896126",
            "type": "images"
          }, {
            "id": "DBI2981756806",
            "type": "images"
          }, {
            "id": "DBI8730820718",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "13",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (100 ft above ground level)",
        "description": "",
        "video": "",
        "slug": "images-cardinal-directions-100-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "17",
            "type": "shot_type_sample_images"
          }, {
            "id": "18",
            "type": "shot_type_sample_images"
          }, {
            "id": "19",
            "type": "shot_type_sample_images"
          }, {
            "id": "20",
            "type": "shot_type_sample_images"
          }, {
            "id": "21",
            "type": "shot_type_sample_images"
          }, {
            "id": "22",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1059854",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "277952",
        "shot_type_id": 22,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Final Assets"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "22",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "22",
      "type": "shot_types",
      "attributes": {
        "name": "Final Assets",
        "description": "Use this shot to upload final edited assets for the customer. Not for use by pilots.\n",
        "video": null,
        "slug": "final-assets",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "DBINV757390701889653",
      "type": "invoice_line_items",
      "attributes": {
        "reason": "base_package_price",
        "description": null,
        "amount": 19900,
        "created_on": "2019-12-02T19:36:26Z",
        "display_name": "Base Package Price"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6666248298",
            "type": "missions"
          }
        },
        "work_item": {
          "data": null
        }
      }
    }]
  };
  _exports.default = _default;
});