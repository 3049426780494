define("clients/adapters/mission", ["exports", "clients/adapters/application", "clients/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionAdapter;
  MissionAdapter = _application.default.extend({
    namespace: 'v2/clients'
  });
  var _default = MissionAdapter;
  _exports.default = _default;
});