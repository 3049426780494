define("clients/routes/missions/plan", ["exports", "clients/data/sample_mission", "clients/data/shot_list", "clients/mixins/css-class-namespace"], function (_exports, _sample_mission, _shot_list, _cssClassNamespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionsPlanRoute;
  MissionsPlanRoute = Ember.Route.extend(_cssClassNamespace.default, {
    model: function (params) {
      return Ember.RSVP.hash({
        location: this.store.peekAll('location').findBy('temp', true) || this.store.createRecord('location', {
          temp: true
        }),
        sample_mission: _sample_mission.default,
        features: Ember.A([]),
        shot_list: _shot_list.default
      });
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      this.controllerFor('application').set('showNavBar', true);
      return this.controllerFor('application').set('minimizedSidebar', false);
    },
    actions: {
      undo: function (model_object) {
        return this.store.pushPayload(model_object);
      },
      "continue": function () {
        return this.transitionTo('missions.new', this.paramsFor('missions.plan')["package"]);
      },
      willTransition: function (transition) {
        if (this.controller.get('model.location.isNew')) {
          if (!this.controller.get('model.location.name')) {
            return this.controller.get('model.location').deleteRecord();
          }
        }
      }
    }
  });
  var _default = MissionsPlanRoute;
  _exports.default = _default;
});