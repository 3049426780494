define("clients/routes/insights", ["exports", "clients/mixins/inner-application-route-setup-controller-mixin"], function (_exports, _innerApplicationRouteSetupControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_innerApplicationRouteSetupControllerMixin.default, {
    sessionAccount: Ember.inject.service(),
    setupController: function (controller, model) {
      this._super(controller, model);

      this.get('sessionAccount.account').then(() => {
        this.controllerFor('application').set('showInsightsBanner', false);
        this.controllerFor('application').set('canManageInsightsUsers', this.sessionAccount.canManageInsightsUsers);
      });
    }
  });

  _exports.default = _default;
});