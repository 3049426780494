define("clients/models/bucket-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created_at: (0, _model.attr)('date'),
    name: (0, _model.attr)('string'),
    extension_list: (0, _model.attr)('array')
  });

  _exports.default = _default;
});