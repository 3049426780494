define("clients/controllers/organization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortedClients: Ember.computed('model.organization.clients.[]', function () {
      return this.get('model.organization.clients').sortBy('fullName');
    })
  });

  _exports.default = _default;
});