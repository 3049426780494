define("clients/templates/components/share/share-orthos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ujPUFgap",
    "block": "{\"symbols\":[\"ortho\"],\"statements\":[[6,[37,4],[[35,1,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"mission-share-shot\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bucket-header\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"bucket-name\"],[12],[2,\"Orthomosaic\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],null,[[\"ortho\",\"classNames\"],[[32,1],\"ortho-share\"]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"ortho-viewer\",\"orthos\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "clients/templates/components/share/share-orthos.hbs"
    }
  });

  _exports.default = _default;
});