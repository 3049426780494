define("clients/adapters/video", ["exports", "clients/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VideoAdapter;
  VideoAdapter = _application.default.extend();
  var _default = VideoAdapter;
  _exports.default = _default;
});