define("clients/routes/missions/edit", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "clients/data/shot_list", "clients/mixins/css-class-namespace"], function (_exports, _authenticatedRouteMixin, _shot_list, _cssClassNamespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionsEditRoute;
  MissionsEditRoute = Ember.Route.extend(_authenticatedRouteMixin.default, _cssClassNamespace.default, {
    sessionAccount: Ember.inject.service(),
    model: function (params) {
      return Ember.RSVP.hash({
        mission: this.get('store').find('mission', params.mission_id),
        location: this.get('store').find('mission', params.mission_id).then(function (mission) {
          return mission.get('location');
        }),
        features: Ember.A([]),
        shot_list: _shot_list.default
      });
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('currentRoute', this);
      this.controllerFor('application').set('showNavBar', true);
      this.controllerFor('application').set('minimizedSidebar', true);
      return this.controllerFor('application').set('canManageInsightsUsers', this.sessionAccount.canManageInsightsUsers);
    },
    actions: {
      redirectToMission: function (mission_id) {
        return this.transitionTo('missions.show', mission_id);
      }
    }
  });
  var _default = MissionsEditRoute;
  _exports.default = _default;
});