define("clients/routes/organization", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "clients/mixins/inner-application-route-setup-controller-mixin"], function (_exports, _authenticatedRouteMixin, _innerApplicationRouteSetupControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationRoute;
  OrganizationRoute = Ember.Route.extend(_authenticatedRouteMixin.default, _innerApplicationRouteSetupControllerMixin.default, {
    sessionAccount: Ember.inject.service(),
    model: function () {
      return Ember.RSVP.hash({
        account: this.get('sessionAccount.account'),
        organization: this.get('store').queryRecord('organization', {})
      });
    },
    redirect: function (model, transition) {
      if (!model.account.get('is_organization_owner')) {
        return this.transitionTo('dashboard.locations.index');
      }
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      return this.get('sessionAccount.account').then(function (_this) {
        return function (client) {
          _this.controllerFor('application').set('showInsightsBanner', client.get('feature_flags.show_insights_banner'));

          _this.controllerFor('application').set('clientHasComposerReports', client.get('has_composer_reports'));

          return _this.controllerFor('application').set('canManageInsightsUsers', _this.sessionAccount.canManageInsightsUsers);
        };
      }(this));
    }
  });
  var _default = OrganizationRoute;
  _exports.default = _default;
});