define("clients/components/DBMap/sidebar-feature", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['sidebar-feature-container'],
    supportedGeometry: Ember.computed('feature.object', function () {
      return this.feature.object.getGeometry().getType();
    }),
    catalogOptions: Ember.computed('feature.{catalog_item_ids,no_fly_zone,object}', 'package.catalog_items.[]', 'supportedGeometry', function () {
      if (this.feature.no_fly_zone) {
        return [];
      } else {
        return this.package.catalog_items.filter(item => {
          if (this.supportedGeometry === 'Polygon' && item.has_pano_shot_type) {
            return;
          }

          const supported_geometry = item.supported_geometry.split(',');
          return supported_geometry.includes(this.supportedGeometry);
        }).map(item => {
          const exists = this.feature.catalog_item_ids.find(catalog_id => catalog_id === item.id);
          return Ember.Object.create({
            item: item,
            selected: !!exists
          });
        });
      }
    }),
    catalogItemsEnabled: Ember.computed.and('package.catalog_items.length', 'feature.catalogEnabled'),
    panoDeliverableAttributes: Ember.computed('package.panoramaShotType.id', 'feature.{shot_type_attributes,shot_type_ids.[]}', function () {
      return this.feature.shot_type_attributes[this.get('package.panoramaShotType.id')];
    }),
    catalogItemPanoAttributes: Ember.computed('catalogItemPanorama', 'feature.{shot_type_attributes,shot_type_ids.[]}', function () {
      return this.feature.shot_type_attributes[this.get('catalogItemPanorama.panoShotType.id')];
    }),
    catalogItemPanorama: Ember.computed('feature.catalog_item_ids.[]', function () {
      const catalog_item_ids = this.feature.catalog_item_ids;
      const catalog_items = this.package.catalog_items;
      const catalog_item = catalog_items.find(item => catalog_item_ids.includes(item.id));
      return catalog_item && catalog_item.has_pano_shot_type ? catalog_item : null;
    }),
    nameChanged: Ember.observer('feature.name', function () {
      this.feature.set('errors', this.feature.errors.filter(error => error.key !== 'name'));
      this.feature.object.setProperty('name', this.feature.name);
    }),
    notesChanged: Ember.observer('feature.notes', function () {
      this.feature.object.setProperty('notes', this.feature.notes);
    }),
    shotTypeAttributesChanged: Ember.observer('feature.shot_type_attributes', function () {
      return this.feature.object.setProperty('shot_type_attributes', this.feature.shot_type_attributes);
    }),
    nameErrors: Ember.computed('feature.errors', function () {
      const error = this.feature.errors.find(error => error.key === 'name');
      return error && error.validation || [];
    }),
    otherErrors: Ember.computed('feature.errors.[]', function () {
      const errors = this.feature.errors.filter(error => error.key !== 'name');
      return errors.map(error => error.validation.join('\n'));
    }),
    isPanorama: Ember.computed.equal('feature.feature_type', 'panorama'),

    didInsertElement() {
      (0, _jquery.default)("input[type='text']").focus();
    },

    actions: {
      delete() {
        this.removePolygon(this.feature);
      },

      save() {
        this.updatePolygon();
      },

      enable() {
        this.parentView.enableFeature(this.feature);
      },

      disable() {
        this.parentView.disableFeature(this.feature);
      },

      removeCatalogItem(id) {
        this.get('catalogOptions').find(option => {
          if (option.item.id === id) {
            option.set('selected', false);
            return true;
          }
        });
        const hasCatalogItemSelected = this.get('catalogOptions').find(option => option.selected && option.item.has_pano_shot_type);

        if (!hasCatalogItemSelected && this.get('feature.feature_type') === "point_of_interest") {
          this.changeToPointIcon(this.get('feature'));
          this.set('feature.iconName', 'point');
        }

        this.set('feature.catalog_item_ids', this.feature.catalog_item_ids.filter(catalog_id => catalog_id !== id));
        this.feature.object.setProperty('catalog_item_ids', this.feature.catalog_item_ids);
        this.parentView.getFeaturePrice(this.feature);
      },

      addCatalogItem(target) {
        this.feature.set('errors', this.feature.errors.filter(error => error.key !== 'catalog_item_ids'));
        const hasCatalogItemSelected = this.get('catalogOptions').find(option => option.selected && option.item.has_pano_shot_type);

        if (hasCatalogItemSelected && this.get('feature.feature_type') === "point_of_interest") {
          this.changeToPanoramaIcon(this.get('feature'));
          this.set('feature.iconName', 'panorama_default');
          this.get('feature.shot_type_attributes')[hasCatalogItemSelected.item.panoShotType.id] = {
            altitude: hasCatalogItemSelected.item.panoShotType.altitude || 100
          };
        }

        if (!this.feature.catalog_item_ids.find(catalog_id => catalog_id === target.item.id)) {
          this.feature.catalog_item_ids.pushObject(target.item.id);
        }

        this.feature.object.setProperty('catalog_item_ids', this.feature.catalog_item_ids);
        this.parentView.getFeaturePrice(this.feature);
      }

    }
  });

  _exports.default = _default;
});