define("clients/data/sample_missions/cre_pro_regional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": {
      "id": "DBM8258483024",
      "type": "missions",
      "attributes": {
        "instructions": null,
        "status": "complete",
        "client_id": "DBC3006554",
        "payment": {
          "id": null,
          "processor": null,
          "card": null
        },
        "external_assets_url": null,
        "owned_by_current_client": true,
        "contact_email": "clients@zeitview.com",
        "created_on": "2019-12-04T20:56:02Z",
        "is_pano_mission": false,
        "logo_url": null,
        "blurb": null,
        "paid": true,
        "paid_on": "2019-12-04T21:15:02Z",
        "price": 61900,
        "archive_url": null,
        "edit_url": null,
        "customer_instructions": "Weekend only. Fly between 12 - 2 pm.",
        "scheduled_at_start": "2019-12-05T17:00:00Z",
        "scheduled_at_end": "2019-12-05T18:00:00Z",
        "parent_id": null,
        "reshoot_mission_id": null,
        "rejection_reason": null,
        "rejection_notes": null,
        "reference_id": "CRE Pro Package - Regional Centers Sample",
        "cloud_reqs": "less_than_50",
        "scheduling_requirement": "not_needed",
        "arcgis_feature_layer": null,
        "geo_properties": {}
      },
      "relationships": {
        "location": {
          "data": {
            "id": "284966",
            "type": "locations"
          }
        },
        "package": {
          "data": {
            "id": "20043",
            "type": "packages"
          }
        },
        "credit_card": {
          "data": null
        },
        "onsite_contact": {
          "data": {
            "id": "49607",
            "type": "onsite_contacts"
          }
        },
        "scheduling_contact": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "images": {
          "data": [{
            "id": "DBI1505554304",
            "type": "images"
          }, {
            "id": "DBI4207177340",
            "type": "images"
          }, {
            "id": "DBI7267099998",
            "type": "images"
          }, {
            "id": "DBI6616819914",
            "type": "images"
          }, {
            "id": "DBI9274311063",
            "type": "images"
          }, {
            "id": "DBI9783078570",
            "type": "images"
          }, {
            "id": "DBI2152323218",
            "type": "images"
          }, {
            "id": "DBI6737889115",
            "type": "images"
          }, {
            "id": "DBI5174885880",
            "type": "images"
          }, {
            "id": "DBI6167130276",
            "type": "images"
          }, {
            "id": "DBI5674132679",
            "type": "images"
          }, {
            "id": "DBI0137613176",
            "type": "images"
          }, {
            "id": "DBI2283783730",
            "type": "images"
          }, {
            "id": "DBI4442375169",
            "type": "images"
          }, {
            "id": "DBI0598577972",
            "type": "images"
          }]
        },
        "videos": {
          "data": [{
            "id": "DBV0064539144",
            "type": "videos"
          }, {
            "id": "DBV9966053464",
            "type": "videos"
          }, {
            "id": "DBV3651426912",
            "type": "videos"
          }, {
            "id": "DBV7987581213",
            "type": "videos"
          }, {
            "id": "DBV9685236979",
            "type": "videos"
          }, {
            "id": "DBV5406405741",
            "type": "videos"
          }, {
            "id": "DBV0343291432",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        },
        "attachments": {
          "data": []
        },
        "ortho_photo_maps": {
          "data": []
        },
        "shares": {
          "data": [{
            "id": "ac0549ceed33dcbc7fc6404733f6aaaa",
            "type": "shares"
          }]
        },
        "main_image": {
          "data": {
            "id": "DBI1505554304",
            "type": "images"
          }
        },
        "collaborators": {
          "data": []
        },
        "parent": {
          "data": null
        },
        "shots": {
          "data": [{
            "id": "1061139",
            "type": "shots"
          }, {
            "id": "1061138",
            "type": "shots"
          }, {
            "id": "1061137",
            "type": "shots"
          }, {
            "id": "1061136",
            "type": "shots"
          }, {
            "id": "1061135",
            "type": "shots"
          }, {
            "id": "1061134",
            "type": "shots"
          }, {
            "id": "1061133",
            "type": "shots"
          }, {
            "id": "1061132",
            "type": "shots"
          }, {
            "id": "1061131",
            "type": "shots"
          }, {
            "id": "1061130",
            "type": "shots"
          }, {
            "id": "1061129",
            "type": "shots"
          }]
        },
        "invoice_line_items": {
          "data": [{
            "id": "DBINV147225004181178",
            "type": "invoice_line_items"
          }]
        }
      }
    },
    "included": [{
      "id": "284966",
      "type": "locations",
      "attributes": {
        "name": "9129 W Atlantic Blvd, Coral Springs, FL 33071, USA",
        "latitude": 26.2410392,
        "longitude": -80.2492081,
        "address": "9129 West Atlantic Boulevard",
        "address2": "",
        "city": "Coral Springs",
        "state": "Florida",
        "postal_code": "33071",
        "country": "United States",
        "formatted_address": "9129 W Atlantic Blvd, Coral Springs, FL 33071, USA",
        "timezone_id": "America/New_York",
        "properties": {}
      }
    }, {
      "id": "20043",
      "type": "packages",
      "attributes": {
        "name": "CRE Pro Package - Regional & Super Regional Centers (400,000+ sq ft)",
        "description": null,
        "price": 61900,
        "cloud_reqs": "less_than_50",
        "position": null,
        "slug": "cre-pro-pack-lg-shopping-center"
      },
      "relationships": {
        "vertical": {
          "data": {
            "id": "1",
            "type": "verticals"
          }
        },
        "organization": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "catalog_items": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "verticals",
      "attributes": {
        "name": "Commercial Real Estate",
        "short_name": "CRE"
      }
    }, {
      "id": "49607",
      "type": "onsite_contacts",
      "attributes": {
        "name": null,
        "call_action": null,
        "phone": null,
        "note": null
      }
    }, {
      "id": "DBC3006554",
      "type": "clients",
      "attributes": {
        "first_name": "Demo",
        "last_name": "Zeitview",
        "phone": "",
        "email": "clients@zeitview.com",
        "token": "qC65CqCmbKfMZvIU2gRb_uD7Sqw",
        "company_name": null,
        "invoiceable": false
      },
      "relationships": {
        "hidden_sample_missions": {
          "data": [{
            "id": "*",
            "type": "hidden_sample_mission"
          }]
        }
      }
    }, {
      "id": "DBI1505554304",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022368-1003cf9a22dbfb9ba267d938a116052c8c60c4ab/original-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
        "name": "DBI-22-V9nkmsa8-CRE-Regional-Center---Starter-Package_DBI-15-DBU-32-AaIO2KQt-DJI_0077-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.35 MB",
        "size": 4557060,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022368-1003cf9a22dbfb9ba267d938a116052c8c60c4ab/large_1920-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022368-1003cf9a22dbfb9ba267d938a116052c8c60c4ab/medium_1024-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022368-1003cf9a22dbfb9ba267d938a116052c8c60c4ab/small_640-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022368-1003cf9a22dbfb9ba267d938a116052c8c60c4ab/four_three_640-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022368-1003cf9a22dbfb9ba267d938a116052c8c60c4ab/square_640-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022368-1003cf9a22dbfb9ba267d938a116052c8c60c4ab/downloadable-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg"
        },
        "created_on": "2019-12-04T21:07:31Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061135",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4207177340",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022366-5f2b325f2468666de2a7af88b112cdae15e49bb4/original-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
        "name": "DBI-21-z6cgycsK-CRE-Regional-Center---Starter-Package_DBI-14-DBU-31-nDr9vnn7-DJI_0079-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.37 MB",
        "size": 4587481,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022366-5f2b325f2468666de2a7af88b112cdae15e49bb4/large_1920-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022366-5f2b325f2468666de2a7af88b112cdae15e49bb4/medium_1024-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022366-5f2b325f2468666de2a7af88b112cdae15e49bb4/small_640-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022366-5f2b325f2468666de2a7af88b112cdae15e49bb4/four_three_640-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022366-5f2b325f2468666de2a7af88b112cdae15e49bb4/square_640-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022366-5f2b325f2468666de2a7af88b112cdae15e49bb4/downloadable-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg"
        },
        "created_on": "2019-12-04T21:07:29Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061135",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7267099998",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022365-c993c8fef7ddd6250f7d4aa1330c770a86920628/original-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
        "name": "DBI-20-McgeF12j-CRE-Regional-Center---Starter-Package_DBI-13-DBU-30-qk1GYZQK-DJI_0081-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.37 MB",
        "size": 4580837,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022365-c993c8fef7ddd6250f7d4aa1330c770a86920628/large_1920-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022365-c993c8fef7ddd6250f7d4aa1330c770a86920628/medium_1024-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022365-c993c8fef7ddd6250f7d4aa1330c770a86920628/small_640-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022365-c993c8fef7ddd6250f7d4aa1330c770a86920628/four_three_640-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022365-c993c8fef7ddd6250f7d4aa1330c770a86920628/square_640-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022365-c993c8fef7ddd6250f7d4aa1330c770a86920628/downloadable-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg"
        },
        "created_on": "2019-12-04T21:07:26Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061135",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6616819914",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022363-c69a5813645a0097e78ec5b550f2e28f8554af22/original-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
        "name": "DBI-19-WmreJIQ1-CRE-Regional-Center---Starter-Package_DBI-12-DBU-29-3Aqq1tOp-DJI_0083-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.45 MB",
        "size": 4667854,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022363-c69a5813645a0097e78ec5b550f2e28f8554af22/large_1920-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022363-c69a5813645a0097e78ec5b550f2e28f8554af22/medium_1024-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022363-c69a5813645a0097e78ec5b550f2e28f8554af22/small_640-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022363-c69a5813645a0097e78ec5b550f2e28f8554af22/four_three_640-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022363-c69a5813645a0097e78ec5b550f2e28f8554af22/square_640-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022363-c69a5813645a0097e78ec5b550f2e28f8554af22/downloadable-9381ddd567cc4c93c31a116da8e9494746210344.jpeg"
        },
        "created_on": "2019-12-04T21:07:24Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061135",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9274311063",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022362-c0eb3e1beb45e6f1b2655821266d1c9439f29c81/original-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
        "name": "DBI-18-OvqxK0IR-CRE-Regional-Center---Starter-Package_DBI-11-DBU-28-iFOZ3lMK-DJI_0084-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.25 MB",
        "size": 4456519,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022362-c0eb3e1beb45e6f1b2655821266d1c9439f29c81/large_1920-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022362-c0eb3e1beb45e6f1b2655821266d1c9439f29c81/medium_1024-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022362-c0eb3e1beb45e6f1b2655821266d1c9439f29c81/small_640-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022362-c0eb3e1beb45e6f1b2655821266d1c9439f29c81/four_three_640-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022362-c0eb3e1beb45e6f1b2655821266d1c9439f29c81/square_640-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022362-c0eb3e1beb45e6f1b2655821266d1c9439f29c81/downloadable-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg"
        },
        "created_on": "2019-12-04T21:07:22Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061135",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9783078570",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022360-9fdcb8341f358f3602883148fb92b755a574df5a/original-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
        "name": "DBI-17-6UZRyFWk-CRE-Regional-Center---Starter-Package_DBI-10-DBU-27-tedIIENP-DJI_0086-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.23 MB",
        "size": 4435590,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022360-9fdcb8341f358f3602883148fb92b755a574df5a/large_1920-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022360-9fdcb8341f358f3602883148fb92b755a574df5a/medium_1024-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022360-9fdcb8341f358f3602883148fb92b755a574df5a/small_640-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022360-9fdcb8341f358f3602883148fb92b755a574df5a/four_three_640-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022360-9fdcb8341f358f3602883148fb92b755a574df5a/square_640-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022360-9fdcb8341f358f3602883148fb92b755a574df5a/downloadable-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg"
        },
        "created_on": "2019-12-04T21:07:20Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2152323218",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022359-9f2c37b83b239d139828c394256b674789a2e464/original-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
        "name": "DBI-16-3jQtyo1J-CRE-Regional-Center---Starter-Package_DBI-9-DBU-26-9JPjFFF3-DJI_0088-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.09 MB",
        "size": 4288810,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022359-9f2c37b83b239d139828c394256b674789a2e464/large_1920-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022359-9f2c37b83b239d139828c394256b674789a2e464/medium_1024-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022359-9f2c37b83b239d139828c394256b674789a2e464/small_640-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022359-9f2c37b83b239d139828c394256b674789a2e464/four_three_640-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022359-9f2c37b83b239d139828c394256b674789a2e464/square_640-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022359-9f2c37b83b239d139828c394256b674789a2e464/downloadable-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg"
        },
        "created_on": "2019-12-04T21:07:18Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6737889115",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022358-1cef0ba8f61c91d92a655d00edc5738b02fdb141/original-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
        "name": "DBI-15-qcrKph0k-CRE-Regional-Center---Starter-Package_DBI-8-DBU-25-SCuaiqDO-DJI_0090-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.1 MB",
        "size": 4294087,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022358-1cef0ba8f61c91d92a655d00edc5738b02fdb141/large_1920-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022358-1cef0ba8f61c91d92a655d00edc5738b02fdb141/medium_1024-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022358-1cef0ba8f61c91d92a655d00edc5738b02fdb141/small_640-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022358-1cef0ba8f61c91d92a655d00edc5738b02fdb141/four_three_640-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022358-1cef0ba8f61c91d92a655d00edc5738b02fdb141/square_640-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022358-1cef0ba8f61c91d92a655d00edc5738b02fdb141/downloadable-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg"
        },
        "created_on": "2019-12-04T21:07:16Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI5174885880",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022356-75711c8ad8c7982212d6c6742fec9c70c588c751/original-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
        "name": "DBI-14-XJP06Ctp-CRE-Regional-Center---Starter-Package_DBI-7-DBU-23-U1pJS1iQ-DJI_0094-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.17 MB",
        "size": 4377579,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022356-75711c8ad8c7982212d6c6742fec9c70c588c751/large_1920-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022356-75711c8ad8c7982212d6c6742fec9c70c588c751/medium_1024-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022356-75711c8ad8c7982212d6c6742fec9c70c588c751/small_640-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022356-75711c8ad8c7982212d6c6742fec9c70c588c751/four_three_640-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022356-75711c8ad8c7982212d6c6742fec9c70c588c751/square_640-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022356-75711c8ad8c7982212d6c6742fec9c70c588c751/downloadable-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg"
        },
        "created_on": "2019-12-04T21:07:14Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6167130276",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022355-247157e106aa07bed6f9c76ade3acac598a49810/original-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
        "name": "DBI-13-RkM4EEEJ-CRE-Regional-Center---Starter-Package_DBI-6-DBU-22-vkFgtxlh-DJI_0096-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.16 MB",
        "size": 4364315,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022355-247157e106aa07bed6f9c76ade3acac598a49810/large_1920-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022355-247157e106aa07bed6f9c76ade3acac598a49810/medium_1024-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022355-247157e106aa07bed6f9c76ade3acac598a49810/small_640-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022355-247157e106aa07bed6f9c76ade3acac598a49810/four_three_640-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022355-247157e106aa07bed6f9c76ade3acac598a49810/square_640-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022355-247157e106aa07bed6f9c76ade3acac598a49810/downloadable-b36a59269151b78278571fc56570818271c1b0a7.jpeg"
        },
        "created_on": "2019-12-04T21:07:12Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI5674132679",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022354-a22691eb137540a6ae34d11ef1638f06decba488/original-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
        "name": "DBI-12-AgrcnstU-CRE-Regional-Center---Starter-Package_DBI-5-DBU-19-Qq63FFL2-DJI_0101-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "5.23 MB",
        "size": 5488296,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022354-a22691eb137540a6ae34d11ef1638f06decba488/large_1920-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022354-a22691eb137540a6ae34d11ef1638f06decba488/medium_1024-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022354-a22691eb137540a6ae34d11ef1638f06decba488/small_640-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022354-a22691eb137540a6ae34d11ef1638f06decba488/four_three_640-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022354-a22691eb137540a6ae34d11ef1638f06decba488/square_640-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022354-a22691eb137540a6ae34d11ef1638f06decba488/downloadable-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg"
        },
        "created_on": "2019-12-04T21:07:10Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0137613176",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022352-5aafe7b6999e3de2b542e951e9a8b5ed126fbe7a/original-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
        "name": "DBI-11-Afzwc88E-CRE-Regional-Center---Starter-Package_DBI-4-DBU-12-A8Pl3OqY-DJI_0115-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.29 MB",
        "size": 4501638,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022352-5aafe7b6999e3de2b542e951e9a8b5ed126fbe7a/large_1920-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022352-5aafe7b6999e3de2b542e951e9a8b5ed126fbe7a/medium_1024-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022352-5aafe7b6999e3de2b542e951e9a8b5ed126fbe7a/small_640-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022352-5aafe7b6999e3de2b542e951e9a8b5ed126fbe7a/four_three_640-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022352-5aafe7b6999e3de2b542e951e9a8b5ed126fbe7a/square_640-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022352-5aafe7b6999e3de2b542e951e9a8b5ed126fbe7a/downloadable-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg"
        },
        "created_on": "2019-12-04T21:07:07Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2283783730",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022351-18598cacab63bb9ade90e940872dde3c0b14837d/original-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
        "name": "DBI-10-4waBrkAz-CRE-Regional-Center---Starter-Package_DBI-3-DBU-9-zay9WAga-DJI_0120-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.31 MB",
        "size": 4519337,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022351-18598cacab63bb9ade90e940872dde3c0b14837d/large_1920-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022351-18598cacab63bb9ade90e940872dde3c0b14837d/medium_1024-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022351-18598cacab63bb9ade90e940872dde3c0b14837d/small_640-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022351-18598cacab63bb9ade90e940872dde3c0b14837d/four_three_640-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022351-18598cacab63bb9ade90e940872dde3c0b14837d/square_640-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022351-18598cacab63bb9ade90e940872dde3c0b14837d/downloadable-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg"
        },
        "created_on": "2019-12-04T21:07:05Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4442375169",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022350-5a5d13a59fd0df0ebd901216d60713d55741c32b/original-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
        "name": "DBI-9-fUJCyNn4-CRE-Regional-Center---Starter-Package_DBI-2-DBU-7-zxNP2A12-DJI_0124-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.3 MB",
        "size": 4513335,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022350-5a5d13a59fd0df0ebd901216d60713d55741c32b/large_1920-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022350-5a5d13a59fd0df0ebd901216d60713d55741c32b/medium_1024-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022350-5a5d13a59fd0df0ebd901216d60713d55741c32b/small_640-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022350-5a5d13a59fd0df0ebd901216d60713d55741c32b/four_three_640-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022350-5a5d13a59fd0df0ebd901216d60713d55741c32b/square_640-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022350-5a5d13a59fd0df0ebd901216d60713d55741c32b/downloadable-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg"
        },
        "created_on": "2019-12-04T21:07:03Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0598577972",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10022349-308274507fc14e040a4ea50a08b0c916cdbca8d8/original-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
        "name": "DBI-8-9pOANazT-CRE-Regional-Center---Starter-Package_DBI-1-DBU-2-pXdHUx0i-DJI_0134-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.49 MB",
        "size": 4703872,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022349-308274507fc14e040a4ea50a08b0c916cdbca8d8/large_1920-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10022349-308274507fc14e040a4ea50a08b0c916cdbca8d8/medium_1024-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10022349-308274507fc14e040a4ea50a08b0c916cdbca8d8/small_640-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10022349-308274507fc14e040a4ea50a08b0c916cdbca8d8/four_three_640-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10022349-308274507fc14e040a4ea50a08b0c916cdbca8d8/square_640-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10022349-308274507fc14e040a4ea50a08b0c916cdbca8d8/downloadable-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg"
        },
        "created_on": "2019-12-04T21:07:00Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061135",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV0064539144",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-7-HhorpP0Y-DL-CRE-Regional-Center---Starter-Package_DBV-20-DBUV003-DJI-0149-trucking.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174509-03c586a95a69664e9ad2232974df6cbf0fe442d1/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174509-03c586a95a69664e9ad2232974df6cbf0fe442d1/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174509-03c586a95a69664e9ad2232974df6cbf0fe442d1/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174509-03c586a95a69664e9ad2232974df6cbf0fe442d1/DL-DBV-7-HhorpP0Y-DL-CRE-Regional-Center---Starter-Package_DBV-20-DBUV003-DJI-0149-trucking.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061134",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV9966053464",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-6-orSLPJzn-DL-CRE-Regional-Center---Starter-Package_DBV-23-DBUV006-DJI-0151-trucking.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174508-280edc751f60ea712966546589d5ce62b3b6d72b/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174508-280edc751f60ea712966546589d5ce62b3b6d72b/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174508-280edc751f60ea712966546589d5ce62b3b6d72b/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174508-280edc751f60ea712966546589d5ce62b3b6d72b/DL-DBV-6-orSLPJzn-DL-CRE-Regional-Center---Starter-Package_DBV-23-DBUV006-DJI-0151-trucking.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061134",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV3651426912",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-4-5nSy5Gza-DL-CRE-Regional-Center---Pro-Package_DBV-16-CRE_Regional_Center-final-assets.mp4",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": "bd7d6fa3f2e14ac3eba69708ecd6f328",
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174507-bb6fe55c58ef73ebab7b0363ecc8579ea9362609/poster_0000.jpg",
          "mp4_high": "https://cdn.dronebase.com/assets/mission/videos/174507-bb6fe55c58ef73ebab7b0363ecc8579ea9362609/1024-high.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174507-bb6fe55c58ef73ebab7b0363ecc8579ea9362609/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174507-bb6fe55c58ef73ebab7b0363ecc8579ea9362609/DL-DBV-4-5nSy5Gza-DL-CRE-Regional-Center---Pro-Package_DBV-16-CRE_Regional_Center-final-assets.mp4"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061137",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV7987581213",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-5-xeIz3cAF-DL-CRE-Regional-Center---Starter-Package_DBV-16-DBUV000-DJI-0147-creative.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174506-347e2a38b00a25c1ecaea8193869cce91b399e65/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174506-347e2a38b00a25c1ecaea8193869cce91b399e65/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174506-347e2a38b00a25c1ecaea8193869cce91b399e65/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174506-347e2a38b00a25c1ecaea8193869cce91b399e65/DL-DBV-5-xeIz3cAF-DL-CRE-Regional-Center---Starter-Package_DBV-16-DBUV000-DJI-0147-creative.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061132",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV9685236979",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-4-k2TQxgbH-DL-CRE-Regional-Center---Starter-Package_DBV-21-DBUV004-DJI-0145-Dolly.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174505-aef28d026d77357571500f4e458e91bf549a6b6e/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174505-aef28d026d77357571500f4e458e91bf549a6b6e/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174505-aef28d026d77357571500f4e458e91bf549a6b6e/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174505-aef28d026d77357571500f4e458e91bf549a6b6e/DL-DBV-4-k2TQxgbH-DL-CRE-Regional-Center---Starter-Package_DBV-21-DBUV004-DJI-0145-Dolly.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061131",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV5406405741",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-3-d9FvBUlZ-DL-CRE-Regional-Center---Starter-Package_DBV-17-DBUV001-DJI-0146-risereverse.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174504-a3c51ed0fd8b1b65ee61ec3bf279f7d369aa5b55/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174504-a3c51ed0fd8b1b65ee61ec3bf279f7d369aa5b55/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174504-a3c51ed0fd8b1b65ee61ec3bf279f7d369aa5b55/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174504-a3c51ed0fd8b1b65ee61ec3bf279f7d369aa5b55/DL-DBV-3-d9FvBUlZ-DL-CRE-Regional-Center---Starter-Package_DBV-17-DBUV001-DJI-0146-risereverse.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061130",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV0343291432",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-2-Ad2B5565-DL-CRE-Regional-Center---Starter-Package_DBV-18-DBUV002-DJI-0141-final-orbit.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174503-b0e461b437a9293dc9a839c0af5966e417c481f0/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174503-b0e461b437a9293dc9a839c0af5966e417c481f0/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174503-b0e461b437a9293dc9a839c0af5966e417c481f0/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174503-b0e461b437a9293dc9a839c0af5966e417c481f0/DL-DBV-2-Ad2B5565-DL-CRE-Regional-Center---Starter-Package_DBV-18-DBUV002-DJI-0141-final-orbit.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061129",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "ac0549ceed33dcbc7fc6404733f6aaaa",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-05T07:05:52Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "1061139",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278184",
        "shot_type_id": 148,
        "pilot_comment": "Not needed.",
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Low Level Corner Shots"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "148",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "148",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Low Level Corner Shots",
        "description": "Images from just below roof level that are angled down the lineup of buildings. We need one image from each end of the building. If there are multiple building or if it is an L-shape or U-shaped property we need an image from each corner. For example, an L-shaped building will have at least 4 images and a U-shaped building with have at least 6 images. \n\nImages with little to no roof in frame are a big point of emphasis our customers. If you need to adjust, back away from the property rather than increasing altitude *so that we don't see the top of the roof.*\n**[Click here for example images](http://bit.ly/2wNIc1D)**",
        "video": null,
        "slug": "images_-_low_level_corner_shots",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061138",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278184",
        "shot_type_id": 147,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Anchor Tenants"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "147",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "147",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Anchor Tenants",
        "description": "Straight on image of each anchor tenant from just below roof level. *An anchor tenant is grocery store, department store, or other large retailer.* Images with little to no roof in frame are a big point of emphasis our customers. If you need to adjust, back away from the property rather than increasing altitude *so that we don't see the top of the roof.*",
        "video": null,
        "slug": "images_-_anchor_tenants",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061137",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278184",
        "shot_type_id": 22,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Final Assets"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "22",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV3651426912",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "22",
      "type": "shot_types",
      "attributes": {
        "name": "Final Assets",
        "description": "Use this shot to upload final edited assets for the customer. Not for use by pilots.\n",
        "video": null,
        "slug": "final-assets",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061136",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "278184",
        "shot_type_id": 24,
        "pilot_comment": "",
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (350 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "24",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI4442375169",
            "type": "images"
          }, {
            "id": "DBI9783078570",
            "type": "images"
          }, {
            "id": "DBI6737889115",
            "type": "images"
          }, {
            "id": "DBI2152323218",
            "type": "images"
          }, {
            "id": "DBI5674132679",
            "type": "images"
          }, {
            "id": "DBI5174885880",
            "type": "images"
          }, {
            "id": "DBI2283783730",
            "type": "images"
          }, {
            "id": "DBI0137613176",
            "type": "images"
          }, {
            "id": "DBI6167130276",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "24",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (350 ft above ground level)",
        "description": "*If there are altitude restrictions in this airspace, please take these images at the highest allowable altitude instead of 350'. *",
        "video": null,
        "slug": "images-cardinal-directions-350-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "23",
            "type": "shot_type_sample_images"
          }, {
            "id": "24",
            "type": "shot_type_sample_images"
          }, {
            "id": "25",
            "type": "shot_type_sample_images"
          }, {
            "id": "26",
            "type": "shot_type_sample_images"
          }, {
            "id": "27",
            "type": "shot_type_sample_images"
          }, {
            "id": "28",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1061135",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "278184",
        "shot_type_id": 13,
        "pilot_comment": "",
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (100 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "13",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI7267099998",
            "type": "images"
          }, {
            "id": "DBI9274311063",
            "type": "images"
          }, {
            "id": "DBI1505554304",
            "type": "images"
          }, {
            "id": "DBI4207177340",
            "type": "images"
          }, {
            "id": "DBI6616819914",
            "type": "images"
          }, {
            "id": "DBI0598577972",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "13",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (100 ft above ground level)",
        "description": "",
        "video": "",
        "slug": "images-cardinal-directions-100-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "17",
            "type": "shot_type_sample_images"
          }, {
            "id": "18",
            "type": "shot_type_sample_images"
          }, {
            "id": "19",
            "type": "shot_type_sample_images"
          }, {
            "id": "20",
            "type": "shot_type_sample_images"
          }, {
            "id": "21",
            "type": "shot_type_sample_images"
          }, {
            "id": "22",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1061134",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Truck (*left to right* or *right to left*) just below roof level **_across all storefronts_**. The tenants should be clearly visible. The full front of the storefront should be in frame. We do not want to see the top of the roof. Adjust distance accordingly.  \n",
        "mission_id": "278184",
        "shot_type_id": 2,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Trucking Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "2",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV0064539144",
            "type": "videos"
          }, {
            "id": "DBV9966053464",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "2",
      "type": "shot_types",
      "attributes": {
        "name": "Trucking Shot",
        "description": "",
        "video": "https://youtu.be/m-r79WAcDNs",
        "slug": "trucking-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061133",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: Looking straight down at the center of the property, rise up vertically to maximum altitude. Go slow and steady. This will reveal property and property boundaries. If the property it too large to be revealed just show as much as you can from maximum altitude.  \n",
        "mission_id": "278184",
        "shot_type_id": 9,
        "pilot_comment": "Not needed",
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Birds Eye Shots"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "9",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "9",
      "type": "shot_types",
      "attributes": {
        "name": "Birds Eye Shots",
        "description": "",
        "video": "",
        "slug": "birds-eye-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061132",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: focus on some point of interest or sign\n",
        "mission_id": "278184",
        "shot_type_id": 54,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Creative Video Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "54",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV7987581213",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "54",
      "type": "shot_types",
      "attributes": {
        "name": "Creative Video Shot",
        "description": null,
        "video": null,
        "slug": "getty-video-getty-video",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061131",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Slowly fly towards the front of the property at a constant altitude (10 feet over roof level) with your camera pointing straight (can have slight angle downward if needed). Keep the horizon flat. Fly completely over property and the end of the property lot.  \n",
        "mission_id": "278184",
        "shot_type_id": 1,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Dolly Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "1",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV9685236979",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "shot_types",
      "attributes": {
        "name": "Dolly Shot",
        "description": "",
        "video": "https://youtu.be/H80sEIUHa7Y",
        "slug": "dolly-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061130",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: Rise + Reverse Away facing the front of the subject property.  \n",
        "mission_id": "278184",
        "shot_type_id": 10,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Rise + Reverse Away Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "10",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV5406405741",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "10",
      "type": "shot_types",
      "attributes": {
        "name": "Rise + Reverse Away Shot",
        "description": "",
        "video": "https://youtu.be/PwOvzZqQWkg",
        "slug": "rise-reverse-away-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061129",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Large + high altitude Contextual Orbit shot around property (counterclockwise)  \n*Must have entire subject property in frame and your camera must be angled up enough so that we also have the horizon in frame.* The point is to provide context of the property in relation to the surrounding area. This will require you to fly at a higher altitude and farther off the property to get the required shot. Typically shot around 300 ft. Orbit should be slow + steady. No jerky camera movements.  \n",
        "mission_id": "278184",
        "shot_type_id": 8,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Orbit Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "8",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV0343291432",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "8",
      "type": "shot_types",
      "attributes": {
        "name": "Orbit Shot",
        "description": "",
        "video": "https://youtu.be/MzdF92gWXyU",
        "slug": "orbit-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "DBINV147225004181178",
      "type": "invoice_line_items",
      "attributes": {
        "reason": "base_package_price",
        "description": null,
        "amount": 61900,
        "created_on": "2019-12-04T20:56:02Z",
        "display_name": "Base Package Price"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "missions"
          }
        },
        "work_item": {
          "data": null
        }
      }
    }]
  };
  _exports.default = _default;
});