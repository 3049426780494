define("clients/mirage/factories/package", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: 'Test package',
    price: 123,
    description: '123',
    slug: 'slug-slug',
    description_bullet_points: ['1', '2'],
    order_flow: 'standard',
    clientUpload: (0, _emberCliMirage.trait)({
      order_flow: 'client_upload'
    })
  });

  _exports.default = _default;
});