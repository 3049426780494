define("clients/components/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: 'button',
    classNames: ['zv-button'],
    classNameBindings: ['mode', 'type', 'size', 'disabled:disabled'],
    _modeValues: Ember.A(['default', 'dark']),
    _modeDefault: 'default',
    mode: 'default',
    _typeValues: Ember.A(['primary', 'secondary', 'text', 'link']),
    _typeDefault: 'primary',
    type: 'primary',
    _sizeValues: Ember.A(['small', 'medium', 'large', 'extra-large']),
    _sizeDefault: 'large',
    size: 'large',
    disabled: false,
    // to imitate a link
    href: null,
    target: null,

    // could be _blank only
    didReceiveAttrs() {
      this._super(...arguments);

      this.validateArgument('mode');
      this.validateArgument('type');
      this.validateArgument('size');
    },

    validateArgument: function (argName) {
      let validType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'string';
      const value = this.get(argName);
      const validList = this.get(`_${argName}Values`);
      const defaultValue = this.get(`_${argName}Default`);

      if (typeof value !== validType) {
        console.log('Undefined', argName, 'argument type:', typeof value, '. It should be a', validType, '. Set default value', defaultValue);
        this.set(argName, defaultValue);
      } else if (!validList.includes(value)) {
        console.log('Invalid', argName, 'argument value:', value, '. It should be one of the list:', validList, '. Set default value ', defaultValue);
        this.set(argName, defaultValue);
      }
    }
  });

  _exports.default = _default;
});