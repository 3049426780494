define("clients/components/collaborator-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CollaboratorListComponent;
  CollaboratorListComponent = Ember.Component.extend({
    actions: {
      "delete": function (collaborator, mission) {
        return collaborator.destroyRecord();
      },
      reset: function () {
        this.set('createMode', false);
        return this.reset();
      },
      add: function (mission) {
        if (this.get('createMode') && this.get('newEmail') && this.get('newFirstName') && this.get('newLastName')) {
          console.log("creating collab");
          return this.createCollaborator(mission);
        } else {
          console.log("creating search");
          return this.search().then(function (_this) {
            return function () {
              return _this.createCollaborator(mission);
            };
          }(this));
        }
      }
    },
    search: function () {
      this.get('mission').store.query('collaborator', {
        email: this.get('newEmail')
      }).then(function (_this) {
        return function (records) {
          return new Ember.RSVP.Promise(function (resolve, reject) {
            var user;

            if (records.get('length') > 0) {
              user = records.objectAt(0);

              _this.set('newEmail', user.get('email'));

              _this.set('newFirstName', user.get('first_name'));

              _this.set('newLastName', user.get('last_name'));

              _this.set('createMode', false);

              return resolve();
            } else {
              _this.set('createMode', true);

              _this.reset();

              return reject();
            }
          });
        };
      }(this));
      return {
        reset: function () {
          this.set('newFirstName', '');
          return this.set('newLastName', '');
        },
        createCollaborator: function (mission) {
          var collaborator;
          collaborator = mission.store.createRecord('collaborator', {
            email: this.get('newEmail'),
            mission: mission
          });
          collaborator.set('first_name', this.get('newFirstName'));
          collaborator.set('last_name', this.get('newLastName'));
          collaborator.save();
          this.set('newEmail', '');
          this.set('createMode', false);
          return this.reset();
        }
      };
    }
  });
  var _default = CollaboratorListComponent;
  _exports.default = _default;
});