define("clients/components/share-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ShareBar;
  ShareBar = Ember.Component.extend({
    actions: {
      closeShareBar: function () {
        this.get('shareBarModel').set('showShareBar', false);
        return this.sendAction('closeShareBarAction');
      },
      getLink: function () {
        return $('.topbar-code-input').show();
      },
      login: function () {
        return this.sendAction('loginAction', window.location);
      }
    }
  });
  var _default = ShareBar;
  _exports.default = _default;
});