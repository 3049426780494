define("clients/validations/map-polygon", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    'name': (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'The property area name can’t be blank.'
    }),
    'catalog_item_ids': (0, _validators.validateLength)({
      min: 1,
      message: 'You must add at least one catalog item.'
    })
  };
  _exports.default = _default;
});