define("clients/components/order-flow/scheduling", ["exports", "clients/validations/contact", "ember-changeset-validations", "ember-changeset"], function (_exports, _contact, _emberChangesetValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SchedulingComponent;
  SchedulingComponent = Ember.Component.extend({
    metrics: Ember.inject.service(),
    store: Ember.inject.service(),
    call_actions: [{
      id: 'not_needed',
      name: 'The pilot doesn\'t need to speak with anyone',
      header: 'No'
    }, {
      id: 'when_you_arrive',
      name: 'The pilot should call when they arrive on site',
      header: 'Yes'
    }, {
      id: 'in_case_of_emergency',
      name: 'Only if the pilot runs into issues on site',
      header: 'Yes'
    }],
    timeOptions: [{
      value: {
        'hour': 8,
        'minutes': 0
      },
      displayName: "8:00 am"
    }, {
      value: {
        'hour': 8,
        'minutes': 30
      },
      displayName: "8:30 am"
    }, {
      value: {
        'hour': 9,
        'minutes': 0
      },
      displayName: "9:00 am"
    }, {
      value: {
        'hour': 9,
        'minutes': 30
      },
      displayName: "9:30 am"
    }, {
      value: {
        'hour': 10,
        'minutes': 0
      },
      displayName: "10:00 am"
    }, {
      value: {
        'hour': 10,
        'minutes': 30
      },
      displayName: "10:30 am"
    }, {
      value: {
        'hour': 11,
        'minutes': 0
      },
      displayName: "11:00 am"
    }, {
      value: {
        'hour': 11,
        'minutes': 30
      },
      displayName: "11:30 am"
    }, {
      value: {
        'hour': 12,
        'minutes': 0
      },
      displayName: "12:00 pm"
    }, {
      value: {
        'hour': 12,
        'minutes': 30
      },
      displayName: "12:30 pm"
    }, {
      value: {
        'hour': 13,
        'minutes': 0
      },
      displayName: "1:00 pm"
    }, {
      value: {
        'hour': 13,
        'minutes': 30
      },
      displayName: "1:30 pm"
    }, {
      value: {
        'hour': 14,
        'minutes': 0
      },
      displayName: "2:00 pm"
    }, {
      value: {
        'hour': 14,
        'minutes': 30
      },
      displayName: "2:30 pm"
    }, {
      value: {
        'hour': 15,
        'minutes': 0
      },
      displayName: "3:00 pm"
    }, {
      value: {
        'hour': 15,
        'minutes': 30
      },
      displayName: "3:30 pm"
    }, {
      value: {
        'hour': 16,
        'minutes': 0
      },
      displayName: "4:00 pm"
    }, {
      value: {
        'hour': 16,
        'minutes': 30
      },
      displayName: "4:30 pm"
    }, {
      value: {
        'hour': 17,
        'minutes': 0
      },
      displayName: "5:00 pm"
    }, {
      value: {
        'hour': 17,
        'minutes': 30
      },
      displayName: "5:30 pm"
    }, {
      value: {
        'hour': 18,
        'minutes': 0
      },
      displayName: "6:00 pm"
    }],
    init: function () {
      var displayTime, offseted_time, recommended, selectedTime;

      this._super();

      if (!this.get('model.mission.cloud_reqs')) {
        recommended = this.get('recommendedCloudReqsOption');
        this.set('model.mission.cloud_reqs', recommended);
      }

      if (this.get('model.mission.scheduling_requirement') === 'scheduled') {
        offseted_time = moment.tz(this.get('model.mission.scheduled_at_start'), this.get('model.location.timezone_id'));
        this.set('displayScheduledAt', offseted_time.format('MM/DD/YYYY'));
        displayTime = offseted_time.format('h:mm a');
        selectedTime = this.get('timeOptions').filter(function (option) {
          return option.displayName === displayTime;
        });
        return this.set('scheduledAtStart', selectedTime[0]);
      }
    },
    initChangeSets: Ember.on('init', function () {
      var onsiteContact, schedulingContact;
      schedulingContact = this.get('model.mission.scheduling_contact');
      this.schedulingContactChangeset = new _emberChangeset.default(schedulingContact, (0, _emberChangesetValidations.default)(_contact.default), _contact.default);

      if (schedulingContact.get('name') && schedulingContact.get('phone')) {
        this.schedulingContactChangeset.name = schedulingContact.get('name');
        this.schedulingContactChangeset.phone = schedulingContact.get('phone');
      }

      onsiteContact = this.get('model.mission.onsite_contact');
      this.onsiteContactChangeset = new _emberChangeset.default(onsiteContact, (0, _emberChangesetValidations.default)(_contact.default), _contact.default);

      if (onsiteContact.get('name') && onsiteContact.get('phone')) {
        this.onsiteContactChangeset.name = onsiteContact.get('name');
        return this.onsiteContactChangeset.phone = onsiteContact.get('phone');
      }
    }),
    loggerTracking: Ember.on('init', function () {
      return this.get('metrics').trackPage({
        hitType: 'pageview',
        page: '/order_flow/mission_details'
      });
    }),
    initDateTimePicker: Ember.on('init', function () {
      if (!this.get('scheduledAtStart')) {
        this.set('scheduledAtStart', this.timeOptions[6]);
      }

      return Ember.run.schedule("afterRender", this, function () {
        return $('#scheduleTimeDatePicker').datetimepicker({
          focusOnShow: false,
          allowInputToggle: true,
          viewMode: 'days',
          format: 'MM/DD/YYYY',
          useCurrent: false,
          ignoreReadonly: true,
          minDate: moment().add(2, 'days').startOf('day'),
          maxDate: moment().add(3, 'weeks').endOf('day'),
          widgetPositioning: {
            horizontal: 'auto',
            vertical: 'bottom'
          },
          icons: {
            date: "fa fa-calendar",
            previous: "fa fa-angle-left",
            next: "fa fa-angle-right"
          }
        });
      });
    }),
    updateScheduledAt: Ember.observer('displayScheduledAt', 'scheduledAtStart', 'scheduledAtEnd', function () {
      var date, local_start_time, start_time, start_time_utc, time, timezone;

      if (!this.get('displayScheduledAt') || this.get('displayScheduledAt').trim() === "") {
        return false;
      }

      this.set('calendarSelected', false);
      date = this.get('displayScheduledAt');
      time = this.get('scheduledAtStart').displayName;
      timezone = moment.tz(date, this.get('model.location.timezone_id')).format('Z');
      start_time = moment(date + ' ' + time + ' ' + timezone);
      start_time_utc = start_time.utc();
      local_start_time = start_time_utc.format();
      console.log(local_start_time);
      return this.set('model.mission.scheduled_at_start', local_start_time);
    }),
    dateTimeClearer: Ember.observer('model.mission.scheduling_requirement', function () {
      if (this.get('model.mission.scheduling_requirement') !== "scheduled") {
        this.set('model.mission.scheduled_at_start', null);
        return this.set('displayScheduledAt', null);
      }
    }),
    showOnsiteContactInputs: Ember.computed('userOnsiteContactChoice', 'schedulingContactLine', function () {
      return !this.get('schedulingContactLine') || this.get('userOnsiteContactChoice') || this.get('userEnteredOnsiteContact');
    }),
    userEnteredOnsiteContact: Ember.computed('onsiteContactChangeset.name', 'onsiteContactChangeset.phone', function () {
      if (this.get('onsiteContactChangeset.name') && this.get('onsiteContactChangeset.phone')) {
        return this.get('onsiteContactChangeset.name') !== this.get('schedulingContactChangeset.name') || this.get('onsiteContactChangeset.phone') !== this.get('schedulingContactChangeset.phone');
      }
    }),
    schedulingContactLine: Ember.computed('schedulingContactChangeset.name', 'schedulingContactChangeset.phone', 'userOnsiteContactChoice', function () {
      this.set('model.mission.scheduling_contact.name', this.get('schedulingContactChangeset.name'));
      this.set('model.mission.scheduling_contact.phone', this.get('schedulingContactChangeset.phone'));

      if (!this.get('userOnsiteContactChoice') && !this.get('userEnteredOnsiteContact') && this.get('model.mission.scheduling_contact.name') && this.get('model.mission.scheduling_contact.phone')) {
        this.set('onsiteContactChangeset.name', this.get('model.mission.scheduling_contact.name'));
        this.set('onsiteContactChangeset.phone', this.get('model.mission.scheduling_contact.phone'));
      }

      return this.get('model.mission.scheduling_contact_line');
    }),
    pageComplete: Ember.computed('model.mission.onsite_contact', 'model.mission.onsite_contact.call_action', 'onsiteContactChangeset.name', 'onsiteContactChangeset.phone', 'callAction', 'model.mission.scheduling_requirement', 'model.mission.scheduled_at_start', 'schedulingContactChangeset.name', 'schedulingContactChangeset.phone', function () {
      var onsiteContactSpecified, onsiteFilled, scheduleCompleted;
      onsiteContactSpecified = this.get('model.mission.onsite_contact');
      scheduleCompleted = this.get('model.mission.scheduling_requirement') === 'scheduled' ? !!this.get('model.mission.scheduled_at_start') : this.get('model.mission.scheduling_requirement') === 'call_to_schedule' ? this.get('schedulingContactChangeset.name') && this.get('schedulingContactChangeset.phone') ? this.get('schedulingContactChangeset.isValid') : void 0 : true;
      onsiteFilled = onsiteContactSpecified ? this.get('model.mission.onsite_contact.call_action') !== 'not_needed' ? this.get('onsiteContactChangeset.name') && this.get('onsiteContactChangeset.phone') ? this.get('onsiteContactChangeset.isValid') : void 0 : true : true;
      return scheduleCompleted && onsiteFilled;
    }),
    recommendedCloudReqsOption: Ember.computed('model.packages.[]', function () {
      var customPacksReqs, customReqs, defaultPacksReqs, defaultReqs, orgPacksReqs, orgReqs, packages, recommended;
      packages = this.get('model.packages');
      customPacksReqs = packages.filter(function (el) {
        return el.get('client.id');
      }).getEach('cloud_reqs');
      orgPacksReqs = packages.filter(function (el) {
        return el.get('organization.id');
      }).getEach('cloud_reqs');
      defaultPacksReqs = packages.filter(function (el) {
        return !el.get('client.id') && !el.get('organization.id');
      }).getEach('cloud_reqs');
      customReqs = this.cloudReqsWithOccurances(customPacksReqs);
      orgReqs = this.cloudReqsWithOccurances(orgPacksReqs);
      defaultReqs = this.cloudReqsWithOccurances(defaultPacksReqs);
      recommended = this.keyWithMaxValue(customReqs) || this.keyWithMaxValue(orgReqs) || this.keyWithMaxValue(defaultReqs);
      return recommended;
    }),
    cloudReqsWithOccurances: function (array) {
      var occurances;
      occurances = {
        "less_than_100": 0,
        "less_than_50": 0,
        "less_than_10": 0
      };
      array.forEach(function (el) {
        return occurances[el] = occurances[el] + 1;
      });
      return occurances;
    },
    keyWithMaxValue: function (hash) {
      var max;
      max = Object.keys(hash).reduce(function (a, b) {
        if (hash[a] >= hash[b]) {
          return a;
        } else {
          return b;
        }
      });

      if (hash[max] > 0) {
        return max;
      } else {
        return null;
      }
    },
    showCalendar: Ember.computed('model.mission.scheduling_requirement', function () {
      return this.get('model.mission.scheduling_requirement') === 'scheduled';
    }),
    airspaceRestricted: Ember.computed('airpace.restriction_level', function () {
      return this.get('airpace.restriction_level') !== 'not_restricted';
    }),
    actions: {
      toggleWeatherExamples: function () {
        return this.set('showWeatherExamples', !this.get('showWeatherExamples'));
      },
      increaseOrderStep: function () {
        if (this.get('model.mission.scheduling_requirement') === 'call_to_schedule') {
          this.send('validateChangesetSetContact', this.schedulingContactChangeset, this.get('model.mission.scheduling_contact'));
        }

        this.send('validateChangesetSetContact', this.onsiteContactChangeset, this.get('model.mission.onsite_contact'));
        return this.increaseOrderStepAction();
      },
      toggleCalendarSelected: function () {
        return this.set('calendarSelected', !this.get('calendarSelected'));
      },
      callActionChanged: function (value) {
        return this.set('model.mission.onsite_contact.call_action', value);
      },
      showOnsiteContactInputs: function () {
        this.set('userOnsiteContactChoice', true);
        return this.get('onsiteContactChangeset').rollback();
      },
      validateChangesetSetContact: function (changeset, contact) {
        return changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            contact.set('name', changeset.get('name'));
            return contact.set('phone', changeset.get('phone'));
          }
        });
      }
    }
  });
  var _default = SchedulingComponent;
  _exports.default = _default;
});