define("clients/templates/components/charts/packages-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lhDBOi9g",
    "block": "{\"symbols\":[\"package_statistic\"],\"statements\":[[10,\"div\"],[14,0,\"chart-container table-chart\"],[14,1,\"packages-chart\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"chart-title\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,\"data-toggle\",\"tooltip\"],[14,\"data-placement\",\"top\"],[14,\"data-container\",\"body\"],[15,\"title\",[34,1]],[12],[2,\"\\n      Orders by Package \"],[10,\"i\"],[14,0,\"fa fa-question-circle\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"chart-content\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"data\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"package-line\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"name\"],[12],[2,\" \"],[1,[32,1,[\"name\"]]],[2,\" \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"price\"],[12],[1,[30,[36,0],[[32,1,[\"price\"]]],[[\"precision\"],[0]]]],[2,\"  \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"count\"],[12],[2,\" \"],[1,[32,1,[\"missions_count\"]]],[2,\" \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"percentage\"],[12],[2,\" \"],[1,[32,1,[\"percentage\"]]],[2,\"% \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"chart-total\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"total-title\"],[12],[2,\" Average flight value \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"value\"],[12],[2,\" \"],[1,[30,[36,0],[[35,2,[\"total\"]]],[[\"precision\"],[0]]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"format-money\",\"tooltipText\",\"content\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "clients/templates/components/charts/packages-chart.hbs"
    }
  });

  _exports.default = _default;
});