define("clients/mixins/mission-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionShareMixin;
  MissionShareMixin = Ember.Mixin.create({
    actions: {
      shareShareable: function (shareable, promise) {
        var share;

        if (share = shareable.get('shares.firstObject')) {
          return promise.resolve(share);
        } else {
          share = this.get('store').createRecord('share', {
            shareable: shareable
          });
          return share.save().then(function (response) {
            return promise.resolve(response);
          }, function (response) {
            share.destroyRecord();
            return promise.reject(response);
          });
        }
      }
    }
  });
  var _default = MissionShareMixin;
  _exports.default = _default;
});