define("clients/models/share-bar-model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    showShareBar: false,
    showShareOptions: false,
    showVideoShareOptions: false,
    shareUrl: '',
    mission: null,
    hasPano: false,
    assetUrl: ''
  });

  _exports.default = _default;
});