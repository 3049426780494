define("clients/controllers/missions/show", ["exports", "clients/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionsShowController;
  MissionsShowController = Ember.Controller.extend({
    metrics: Ember.inject.service(),
    queryParams: ['new'],
    showModal: false,
    activeTab: 'orderInfo',
    client: Ember.computed('model', function () {
      return this.get('model.client');
    }),
    mission: Ember.computed('model', function () {
      return this.get('model.mission');
    }),
    creditCard: Ember.computed('model', function () {
      var cc;
      cc = this.get('model.credit_card');

      if (cc == null) {
        return this.store.createRecord('credit_card');
      }
    }),
    paid: Ember.computed('mission.paid', function () {
      return this.get('mission.paid');
    }),
    isShowingModal: Ember.computed('mission.status', 'paid', 'showModal', function () {
      if (this.get('mission.status') === 'awaiting_payment' && this.get('isMissionOwner')) {
        return !this.get('paid') && this.get('showModal');
      }

      return false;
    }),
    isMissionOwner: Ember.computed('client', 'mission.client', function () {
      return this.get('mission.client.id') === this.get('client.id');
    }),
    editedPanos: Ember.computed('mission.panoramas.[]', function () {
      return this.get('mission.panoramas').filterBy('source_type', 'edit');
    }),
    editedVideosHead: Ember.computed('editedVideos', function () {
      return this.get('editedVideos')[0];
    }),
    editedVideosTail: Ember.computed('editedVideos', function () {
      return this.get('editedVideos').slice(1, this.get('editedVideos').length);
    }),
    editedVideos: Ember.computed('mission.videos.[]', function () {
      return this.get('mission.videos').filterBy('source_type', 'edit');
    }),
    rawVideos: Ember.computed('mission.videos.[]', function () {
      return this.get('mission.videos').filterBy('source_type', 'raw');
    }),
    showPanos: Ember.computed('activeTab', 'editedPanos', function () {
      return this.get('activeTab') === 'editedAssets' && this.get('editedPanos').length > 0;
    }),
    showHighlightVideo: Ember.computed('activeTab', 'editedVideos', function () {
      return this.get('activeTab') === 'editedAssets' && this.get('editedVideos').length > 0;
    }),
    showReceipt: Ember.computed('mission', function () {
      return this.get('mission.payment.processor') === 'stripe' && this.get('mission.status') === 'complete';
    }),
    galleryOptions: {
      hideShare: true,
      showHideOpacity: true,
      getThumbBoundsFn: false
    },
    actions: {
      trackGoToOrderFlow: function () {
        return this.get('metrics').trackEvent({
          event: 'event',
          category: 'Sample Mission Header',
          action: 'click',
          label: "Go to Order Flow From Sample Mission"
        });
      },
      switchTab: function (tab) {
        return this.set('activeTab', tab);
      },
      toggleModal: function () {
        if (!(!this.get('paid') && this.get('mission.status') === 'awaiting_payment')) {
          this.set('showModal', !this.get('showModal'));
          return '';
        }
      },
      reloadMission: function () {
        return this.send('reloadModel');
      },
      goToUpload: function (missionId) {
        return this.transitionToRoute('missions.upload', missionId);
      }
    }
  });
  var _default = MissionsShowController;
  _exports.default = _default;
});