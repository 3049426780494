define("clients/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(_ref) {
      let [date, format, timezone_id] = _ref;
      const offseted_time = moment.tz(date, timezone_id);
      return offseted_time.format(format);
    }

  });

  _exports.default = _default;
});