define("clients/controllers/charts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DashboardChartsController;
  DashboardChartsController = Ember.Controller.extend({
    startDate: moment().utc().subtract(30, 'days').format('YYYY-MM-DD HH:mm').toString(),
    endDate: moment().utc().format('YYYY-MM-DD HH:mm').toString(),
    tooltipsFormat: 'days',
    durationOptions: [{
      displayName: 'Last 7 days',
      value: {
        startDate: moment().utc().subtract(7, 'days'),
        endDate: moment().utc()
      }
    }, {
      displayName: 'Last 30 days',
      value: {
        startDate: moment().utc().subtract(30, 'days'),
        endDate: moment().utc()
      }
    }, {
      displayName: 'This month',
      value: {
        startDate: moment().utc().startOf('month'),
        endDate: moment().utc()
      }
    }, {
      displayName: 'Last month',
      value: {
        startDate: moment().utc().subtract(1, 'month').startOf('month'),
        endDate: moment().utc().subtract(1, 'month').endOf('month')
      }
    }, {
      displayName: 'This quarter',
      value: {
        startDate: moment().utc().startOf('quarter'),
        endDate: moment().utc()
      }
    }, {
      displayName: 'Last quarter',
      value: {
        startDate: moment().utc().subtract(3, 'month').startOf('quarter'),
        endDate: moment().utc().subtract(3, 'month').endOf('quarter')
      }
    }, {
      displayName: 'This year',
      value: {
        startDate: moment().utc().startOf('year'),
        endDate: moment().utc()
      }
    }, {
      displayName: 'Last year',
      value: {
        startDate: moment().utc().subtract(1, 'year').startOf('year'),
        endDate: moment().utc().subtract(1, 'year').endOf('year')
      }
    }, {
      displayName: 'All Time',
      value: {
        endDate: moment().utc()
      }
    }],
    durationSelectedObserver: Ember.observer('durationSelected', function () {
      var selectedDuration, startDate;
      selectedDuration = this.get('durationSelected').value;
      startDate = selectedDuration.startDate;
      this.set('startDate', startDate);
      this.set('endDate', selectedDuration.endDate);

      if (this.get('endDate').diff(this.get('startDate'), 'days') > 31) {
        this.set('tooltipsFormat', 'weeks');
      } else if (this.get('endDate').diff(this.get('startDate'), 'days') > 1) {
        this.set('tooltipsFormat', 'days');
      } else {
        this.set('tooltipsFormat', 'hours');
      }

      this.set('statsLoaded', false);
      return this.send('refreshModel', {
        startDate: selectedDuration.startDate,
        endDate: selectedDuration.endDate
      });
    }),
    durationSelected: {
      displayName: 'Last 30 days',
      value: {
        startDate: moment().utc().subtract(30, 'days'),
        endDate: moment().utc()
      }
    },
    formattedDuration: Ember.computed('startDate', 'endDate', 'model.metaInformation', function () {
      var endMoment, startMoment;

      if (this.get('startDate')) {
        startMoment = moment(this.get('startDate')).utc();
      } else {
        startMoment = moment(this.get('model.metaInformation').statistic_starts).utc();
      }

      endMoment = moment(this.get('endDate')).utc();

      if (startMoment.format('MMM D, YYYY').toString() === endMoment.format('MMM D, YYYY').toString()) {
        return endMoment.format('MMM D, YYYY').toString();
      } else if (startMoment.year() === endMoment.year()) {
        return startMoment.format('MMM D').toString() + ' - ' + endMoment.format('MMM D, YYYY').toString();
      } else {
        return startMoment.format('MMM D, YYYY').toString() + ' - ' + endMoment.format('MMM D, YYYY').toString();
      }
    }),
    modelObserver: Ember.observer('model', function () {
      return this.set('statsLoaded', true);
    })
  });
  var _default = DashboardChartsController;
  _exports.default = _default;
});