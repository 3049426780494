define("clients/components/slider", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['slider-container'],
    inputId: 'db-slider',
    min: 0,
    max: 100,
    step: 1,
    value: 10,
    valuePercent: Ember.computed('max', 'min', 'value', function () {
      return parseFloat(((this.value - this.min) / (this.max - this.min) * 100).toFixed(2));
    }),
    unit: '',
    sliderElement: null,
    minValueElement: null,
    valueElement: null,
    maxValueElement: null,
    thumbWidth: 14,

    // should be equal to $thumb-width in slider.scss (in px)
    updateStyles() {
      // to fill left slider part
      this.element.style.setProperty('--value-thumb-percent', `${this.valuePercent}%`); // to arrange current value text

      const textOffset = parseFloat((this.valueElement.clientWidth / 2 / this.element.clientWidth * 100).toFixed(2));
      const thumbOffset = parseFloat((this.thumbWidth / this.element.clientWidth * 100).toFixed(2)) / 100 * this.valuePercent;
      const thumbCorrection = this.thumbWidth / 2;
      this.element.style.setProperty('--value-text-left', `calc(${this.valuePercent - textOffset - thumbOffset}% + ${thumbCorrection}px)`); // to avoid intersection with man and max text

      const space = 5; //px

      const minValueRight = this.minValueElement.offsetLeft + this.minValueElement.clientWidth + space;

      if (minValueRight > this.valueElement.offsetLeft) {
        this.element.style.setProperty('--value-text-left', `${minValueRight}px`);
      }

      const valueRight = this.valueElement.offsetLeft + this.valueElement.clientWidth + space;

      if (valueRight > this.maxValueElement.offsetLeft) {
        this.element.style.setProperty('--value-text-left', `${this.maxValueElement.offsetLeft - this.valueElement.clientWidth - space}px`);
      } // on first render we don't have width for some reason...


      if (!this.valueElement.clientWidth) {
        this.element.style.setProperty('--value-text-left', `calc(${this.valuePercent}% + ${thumbCorrection}px)`);
      }
    },

    didRender() {
      this._super(...arguments);

      this.sliderElement = (0, _jquery.default)(`#${this.inputId}`)[0];
      this.minValueElement = (0, _jquery.default)('.min-value')[0];
      this.valueElement = (0, _jquery.default)('.value')[0];
      this.maxValueElement = (0, _jquery.default)('.max-value')[0];
      this.updateStyles();

      this.sliderElement.oninput = event => {
        this.set('value', parseFloat(event.target.value));
        this.updateStyles();

        if (this.onChange) {
          this.onChange(this.value);
        }
      };
    }

  });

  _exports.default = _default;
});