define("clients/models/collaborator", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    first_name: (0, _model.attr)('string'),
    last_name: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    mission: (0, _model.belongsTo)('mission', {
      async: false
    }),
    fullName: Ember.computed('first_name', 'last_name', function () {
      return this.get('first_name') + " " + this.get('last_name');
    })
  });

  _exports.default = _default;
});