define("clients/templates/components/DBMap/altitude-instructions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qBxg/5+A",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[34,0]],[12],[2,\"\\n  Not sure what altitude you need?\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[34,1]],[12],[2,\"\\n  The standard altitude for a Zeitview panorama is 100’.\\n  However, your properties and preferences may vary from location to location.\\n  Click each link to view examples of panoramas captured at\\n  \"],[8,\"button\",[[16,6,[30,[36,3],[[35,2],\"100\"],null]],[24,\"target\",\"_blank\"]],[[\"@tagName\",\"@type\",\"@size\"],[\"a\",\"link\",\"medium\"]],[[\"default\"],[{\"statements\":[[2,\"100’\"]],\"parameters\":[]}]]],[2,\",\\n  \"],[8,\"button\",[[16,6,[30,[36,3],[[35,2],\"200\"],null]],[24,\"target\",\"_blank\"]],[[\"@tagName\",\"@type\",\"@size\"],[\"a\",\"link\",\"medium\"]],[[\"default\"],[{\"statements\":[[2,\"200’\"]],\"parameters\":[]}]]],[2,\",\\n  \"],[8,\"button\",[[16,6,[30,[36,3],[[35,2],\"300\"],null]],[24,\"target\",\"_blank\"]],[[\"@tagName\",\"@type\",\"@size\"],[\"a\",\"link\",\"medium\"]],[[\"default\"],[{\"statements\":[[2,\"300’\"]],\"parameters\":[]}]]],[2,\", and\\n  \"],[8,\"button\",[[16,6,[30,[36,3],[[35,2],\"400\"],null]],[24,\"target\",\"_blank\"]],[[\"@tagName\",\"@type\",\"@size\"],[\"a\",\"link\",\"medium\"]],[[\"default\"],[{\"statements\":[[2,\"400’\"]],\"parameters\":[]}]]],[2,\" to determine your desired altitude.\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"b\"],[12],[2,\"Note\"],[13],[2,\": If there is an obstruction at the indicated panorama location, such as a tree or light pole,\\n  the panorama altitude may be slightly adjusted to maintain a safe operation.\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"_labelClasses\",\"_bodyClasses\",\"_assetLinks\",\"get\"]}",
    "meta": {
      "moduleName": "clients/templates/components/DBMap/altitude-instructions.hbs"
    }
  });

  _exports.default = _default;
});