define("clients/templates/components/connect-to-esri", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "01GnDicx",
    "block": "{\"symbols\":[],\"statements\":[[8,\"button\",[[24,1,\"esri-sign-in\"],[4,[38,0],[[32,0],\"buttonClicked\"],null]],[[\"@size\"],[\"extra-large\"]],[[\"default\"],[{\"statements\":[[2,\"Connect your ArcGIS account\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "clients/templates/components/connect-to-esri.hbs"
    }
  });

  _exports.default = _default;
});