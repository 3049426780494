define("clients/templates/order-flow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DfcaP2Mr",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"model\",\"goToUploadAction\",\"closeInsightsBanner\",\"goToInsights\",\"contactAboutInsights\"],[[35,1],[30,[36,0],[[32,0],\"goToUpload\"],null],[30,[36,0],[[32,0],\"closeInsightsBanner\"],null],[30,[36,0],[[32,0],\"goToInsights\"],null],[30,[36,0],[[32,0],\"contactAboutInsights\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"order-flow/multi-step-order-flow\"]}",
    "meta": {
      "moduleName": "clients/templates/order-flow.hbs"
    }
  });

  _exports.default = _default;
});