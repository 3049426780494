define("clients/components/order-flow/client-upload", ["exports", "jquery", "clients/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    allowedExtensions: Ember.computed('model.mission.require_raw_images', function () {
      if (this.get('model.mission.require_raw_images')) {
        return "mov mp4 dng";
      } else {
        return "mov mp4 jpg jpeg tif tiff png";
      }
    }),
    currentImages: Ember.computed.reads('model.mission.images'),
    currentVideos: Ember.computed.reads('model.mission.videos'),
    currentAssets: Ember.computed.union('currentImages', 'currentVideos'),
    isAssetsPresent: Ember.computed('currentAssets.{[],@each.name,@each.progress}', function () {
      const newUploadedFiles = this.currentAssets.filterBy('file');

      if (newUploadedFiles.length === 0) {
        return this.currentAssets.length > 0;
      } else {
        return newUploadedFiles.length === newUploadedFiles.filterBy('file.progress', 100).length;
      }
    }),
    sampleUploadModal: false,
    submitInProgress: false,
    actions: {
      submitWithAssets() {
        const missionId = this.get('model.mission.id');
        const url = `${_environment.default.api.host}/v2/clients/missions/${missionId}/move_to_assets_uploaded`;
        this.set('submitInProgress', true);
        return _jquery.default.ajax({
          url,
          type: 'PATCH',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(() => {
          if (this.isOrderFlow) {
            return this.submitMissionAction();
          } else {
            return this.goToMissionAction(missionId);
          }
        }).always(() => {
          this.set('submitInProgress', false);
        });
      },

      toggleSampleUpload() {
        this.toggleProperty('sampleUploadModal');
      }

    }
  });

  _exports.default = _default;
});