define("clients/components/mission-group/move-modal", ["exports", "clients/components/mission-group/base-modal", "jquery", "clients/config/environment"], function (_exports, _baseModal, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const MoveToMissionGroupModalComponent = _baseModal.default.extend({
    selectedGroup: null,
    GMTEventAttrs: {
      label: 'Move mission to group'
    },
    saveRecord: function () {
      const data = {
        missions_to_add: this.selectedMissions.map(mission => mission.get('id'))
      };
      return _jquery.default.ajax({
        url: `${_environment.default.api.host}/v3/clients/mission_groups/${this.get('selectedGroup.id')}`,
        headers: this.get('sessionAccount.headers'),
        type: 'PUT',
        data: data,
        dataType: 'json'
      });
    },
    actions: {
      selectGroup(group) {
        this.set('selectedGroup', group);
      },

      openCreateModal() {
        this.openCreateModal();
      }

    }
  });

  var _default = MoveToMissionGroupModalComponent;
  _exports.default = _default;
});