define("clients/components/open-pano", ["exports", "clients/config/environment", "clients/data/pano_shares"], function (_exports, _environment, _pano_shares) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OpenPanoComponent;
  OpenPanoComponent = Ember.Component.extend({
    metrics: Ember.inject.service(),
    buttonText: null,
    classNames: ["asset-share"],
    store: Ember.inject.service(),
    shareLink: Ember.computed('share', function () {
      return this.get('pano.shares.firstObject.id') || _pano_shares.default[this.get('pano.id')];
    }),
    init: function () {
      this._super();

      return this.get('currentShare');
    },
    currentShare: Ember.computed('pano.shares', function () {
      var share;

      if (!(share = this.get('pano').get('shares.firstObject'))) {
        share = this.get('store').createRecord('share', {
          shareable: this.get('pano')
        });
        return share.save();
      }
    })
  });
  var _default = OpenPanoComponent;
  _exports.default = _default;
});