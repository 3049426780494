define("clients/data/sample_images/cre_pro_regional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": [{
      "id": "DBI1505554304",
      "type": "image",
      "attributes": {
        "name": "DBI-22-V9nkmsa8-CRE-Regional-Center---Starter-Package_DBI-15-DBU-32-AaIO2KQt-DJI_0077-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022368-1003cf9a22dbfb9ba267d938a116052c8c60c4ab/downloadable-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022368-1003cf9a22dbfb9ba267d938a116052c8c60c4ab/large_1920-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM2OC0xMDAzY2Y5YTIyZGJmYjliYTI2N2Q5MzhhMTE2MDUyYzhjNjBjNGFiL2xhcmdlXzE5MjAtOWQ5ZDBmYjk0NzU0ZDJhNGE1MWQxYmZjNWVjNTEwZGZiN2M0N2Y1OC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM2OC0xMDAzY2Y5YTIyZGJmYjliYTI2N2Q5MzhhMTE2MDUyYzhjNjBjNGFiL2xhcmdlXzE5MjAtOWQ5ZDBmYjk0NzU0ZDJhNGE1MWQxYmZjNWVjNTEwZGZiN2M0N2Y1OC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T21:07:31Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061135",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4207177340",
      "type": "image",
      "attributes": {
        "name": "DBI-21-z6cgycsK-CRE-Regional-Center---Starter-Package_DBI-14-DBU-31-nDr9vnn7-DJI_0079-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022366-5f2b325f2468666de2a7af88b112cdae15e49bb4/downloadable-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022366-5f2b325f2468666de2a7af88b112cdae15e49bb4/large_1920-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM2Ni01ZjJiMzI1ZjI0Njg2NjZkZTJhN2FmODhiMTEyY2RhZTE1ZTQ5YmI0L2xhcmdlXzE5MjAtZTMzZjQ0ODllNjM3OTA4M2MzNzU3ZGE5ZWE5NmNlMWM3Y2E3NjcyOC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM2Ni01ZjJiMzI1ZjI0Njg2NjZkZTJhN2FmODhiMTEyY2RhZTE1ZTQ5YmI0L2xhcmdlXzE5MjAtZTMzZjQ0ODllNjM3OTA4M2MzNzU3ZGE5ZWE5NmNlMWM3Y2E3NjcyOC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T21:07:29Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061135",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI7267099998",
      "type": "image",
      "attributes": {
        "name": "DBI-20-McgeF12j-CRE-Regional-Center---Starter-Package_DBI-13-DBU-30-qk1GYZQK-DJI_0081-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022365-c993c8fef7ddd6250f7d4aa1330c770a86920628/downloadable-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022365-c993c8fef7ddd6250f7d4aa1330c770a86920628/large_1920-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM2NS1jOTkzYzhmZWY3ZGRkNjI1MGY3ZDRhYTEzMzBjNzcwYTg2OTIwNjI4L2xhcmdlXzE5MjAtMGU2ZGY2MzFkZWVlYWQ4YTA5YjIzMjE5NmViODQzNTJiOWRkYjI2Yi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM2NS1jOTkzYzhmZWY3ZGRkNjI1MGY3ZDRhYTEzMzBjNzcwYTg2OTIwNjI4L2xhcmdlXzE5MjAtMGU2ZGY2MzFkZWVlYWQ4YTA5YjIzMjE5NmViODQzNTJiOWRkYjI2Yi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T21:07:26Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061135",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6616819914",
      "type": "image",
      "attributes": {
        "name": "DBI-19-WmreJIQ1-CRE-Regional-Center---Starter-Package_DBI-12-DBU-29-3Aqq1tOp-DJI_0083-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022363-c69a5813645a0097e78ec5b550f2e28f8554af22/downloadable-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022363-c69a5813645a0097e78ec5b550f2e28f8554af22/large_1920-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM2My1jNjlhNTgxMzY0NWEwMDk3ZTc4ZWM1YjU1MGYyZTI4Zjg1NTRhZjIyL2xhcmdlXzE5MjAtOTM4MWRkZDU2N2NjNGM5M2MzMWExMTZkYThlOTQ5NDc0NjIxMDM0NC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM2My1jNjlhNTgxMzY0NWEwMDk3ZTc4ZWM1YjU1MGYyZTI4Zjg1NTRhZjIyL2xhcmdlXzE5MjAtOTM4MWRkZDU2N2NjNGM5M2MzMWExMTZkYThlOTQ5NDc0NjIxMDM0NC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T21:07:24Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061135",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI9274311063",
      "type": "image",
      "attributes": {
        "name": "DBI-18-OvqxK0IR-CRE-Regional-Center---Starter-Package_DBI-11-DBU-28-iFOZ3lMK-DJI_0084-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022362-c0eb3e1beb45e6f1b2655821266d1c9439f29c81/downloadable-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022362-c0eb3e1beb45e6f1b2655821266d1c9439f29c81/large_1920-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM2Mi1jMGViM2UxYmViNDVlNmYxYjI2NTU4MjEyNjZkMWM5NDM5ZjI5YzgxL2xhcmdlXzE5MjAtMmEwMzVmOTRlOWExOTRjZGFhYTBjZTc4NGUxMmEzMDM3YTMzYjE2NS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM2Mi1jMGViM2UxYmViNDVlNmYxYjI2NTU4MjEyNjZkMWM5NDM5ZjI5YzgxL2xhcmdlXzE5MjAtMmEwMzVmOTRlOWExOTRjZGFhYTBjZTc4NGUxMmEzMDM3YTMzYjE2NS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T21:07:22Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061135",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI9783078570",
      "type": "image",
      "attributes": {
        "name": "DBI-17-6UZRyFWk-CRE-Regional-Center---Starter-Package_DBI-10-DBU-27-tedIIENP-DJI_0086-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022360-9fdcb8341f358f3602883148fb92b755a574df5a/downloadable-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022360-9fdcb8341f358f3602883148fb92b755a574df5a/large_1920-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM2MC05ZmRjYjgzNDFmMzU4ZjM2MDI4ODMxNDhmYjkyYjc1NWE1NzRkZjVhL2xhcmdlXzE5MjAtNzBkYWU5YjI0ODM1YzFkOGFhNGQ3NTg4ZGI1NmMyNzUzMDBjMTM3Yi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM2MC05ZmRjYjgzNDFmMzU4ZjM2MDI4ODMxNDhmYjkyYjc1NWE1NzRkZjVhL2xhcmdlXzE5MjAtNzBkYWU5YjI0ODM1YzFkOGFhNGQ3NTg4ZGI1NmMyNzUzMDBjMTM3Yi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T21:07:20Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI2152323218",
      "type": "image",
      "attributes": {
        "name": "DBI-16-3jQtyo1J-CRE-Regional-Center---Starter-Package_DBI-9-DBU-26-9JPjFFF3-DJI_0088-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022359-9f2c37b83b239d139828c394256b674789a2e464/downloadable-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022359-9f2c37b83b239d139828c394256b674789a2e464/large_1920-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1OS05ZjJjMzdiODNiMjM5ZDEzOTgyOGMzOTQyNTZiNjc0Nzg5YTJlNDY0L2xhcmdlXzE5MjAtODdlZTc1ZTFhMWVkMzk2ODYzNmY2YTk0YTU4Nzk1MzllOTIzZjhlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1OS05ZjJjMzdiODNiMjM5ZDEzOTgyOGMzOTQyNTZiNjc0Nzg5YTJlNDY0L2xhcmdlXzE5MjAtODdlZTc1ZTFhMWVkMzk2ODYzNmY2YTk0YTU4Nzk1MzllOTIzZjhlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T21:07:18Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6737889115",
      "type": "image",
      "attributes": {
        "name": "DBI-15-qcrKph0k-CRE-Regional-Center---Starter-Package_DBI-8-DBU-25-SCuaiqDO-DJI_0090-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022358-1cef0ba8f61c91d92a655d00edc5738b02fdb141/downloadable-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022358-1cef0ba8f61c91d92a655d00edc5738b02fdb141/large_1920-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1OC0xY2VmMGJhOGY2MWM5MWQ5MmE2NTVkMDBlZGM1NzM4YjAyZmRiMTQxL2xhcmdlXzE5MjAtMGJjMWU3ZmZkZjY1NDc2ZjY0MWI5ZjYxYWZiY2VjYjg2MDM0YmM0OS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1OC0xY2VmMGJhOGY2MWM5MWQ5MmE2NTVkMDBlZGM1NzM4YjAyZmRiMTQxL2xhcmdlXzE5MjAtMGJjMWU3ZmZkZjY1NDc2ZjY0MWI5ZjYxYWZiY2VjYjg2MDM0YmM0OS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T21:07:16Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI5174885880",
      "type": "image",
      "attributes": {
        "name": "DBI-14-XJP06Ctp-CRE-Regional-Center---Starter-Package_DBI-7-DBU-23-U1pJS1iQ-DJI_0094-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022356-75711c8ad8c7982212d6c6742fec9c70c588c751/downloadable-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022356-75711c8ad8c7982212d6c6742fec9c70c588c751/large_1920-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1Ni03NTcxMWM4YWQ4Yzc5ODIyMTJkNmM2NzQyZmVjOWM3MGM1ODhjNzUxL2xhcmdlXzE5MjAtN2JjNDE2ZjQ3ZGM3ZTNhNjA5ZmFiNDViYzBkZWIxMjZmYTJlZjFlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1Ni03NTcxMWM4YWQ4Yzc5ODIyMTJkNmM2NzQyZmVjOWM3MGM1ODhjNzUxL2xhcmdlXzE5MjAtN2JjNDE2ZjQ3ZGM3ZTNhNjA5ZmFiNDViYzBkZWIxMjZmYTJlZjFlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T21:07:14Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6167130276",
      "type": "image",
      "attributes": {
        "name": "DBI-13-RkM4EEEJ-CRE-Regional-Center---Starter-Package_DBI-6-DBU-22-vkFgtxlh-DJI_0096-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022355-247157e106aa07bed6f9c76ade3acac598a49810/downloadable-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022355-247157e106aa07bed6f9c76ade3acac598a49810/large_1920-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1NS0yNDcxNTdlMTA2YWEwN2JlZDZmOWM3NmFkZTNhY2FjNTk4YTQ5ODEwL2xhcmdlXzE5MjAtYjM2YTU5MjY5MTUxYjc4Mjc4NTcxZmM1NjU3MDgxODI3MWMxYjBhNy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1NS0yNDcxNTdlMTA2YWEwN2JlZDZmOWM3NmFkZTNhY2FjNTk4YTQ5ODEwL2xhcmdlXzE5MjAtYjM2YTU5MjY5MTUxYjc4Mjc4NTcxZmM1NjU3MDgxODI3MWMxYjBhNy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T21:07:12Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI5674132679",
      "type": "image",
      "attributes": {
        "name": "DBI-12-AgrcnstU-CRE-Regional-Center---Starter-Package_DBI-5-DBU-19-Qq63FFL2-DJI_0101-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022354-a22691eb137540a6ae34d11ef1638f06decba488/downloadable-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022354-a22691eb137540a6ae34d11ef1638f06decba488/large_1920-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1NC1hMjI2OTFlYjEzNzU0MGE2YWUzNGQxMWVmMTYzOGYwNmRlY2JhNDg4L2xhcmdlXzE5MjAtZjJhMTI3ZDFhNWEzY2Y5NGE5YTI5ZGZkODE0YWZlYjcxYjg5YzY4YS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1NC1hMjI2OTFlYjEzNzU0MGE2YWUzNGQxMWVmMTYzOGYwNmRlY2JhNDg4L2xhcmdlXzE5MjAtZjJhMTI3ZDFhNWEzY2Y5NGE5YTI5ZGZkODE0YWZlYjcxYjg5YzY4YS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T21:07:10Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI0137613176",
      "type": "image",
      "attributes": {
        "name": "DBI-11-Afzwc88E-CRE-Regional-Center---Starter-Package_DBI-4-DBU-12-A8Pl3OqY-DJI_0115-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022352-5aafe7b6999e3de2b542e951e9a8b5ed126fbe7a/downloadable-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022352-5aafe7b6999e3de2b542e951e9a8b5ed126fbe7a/large_1920-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1Mi01YWFmZTdiNjk5OWUzZGUyYjU0MmU5NTFlOWE4YjVlZDEyNmZiZTdhL2xhcmdlXzE5MjAtNTM5N2IzN2I1MTAyZmYzZTVlMDc0NGI4YzYyNDA4ZjAyMDZiZWRjOS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1Mi01YWFmZTdiNjk5OWUzZGUyYjU0MmU5NTFlOWE4YjVlZDEyNmZiZTdhL2xhcmdlXzE5MjAtNTM5N2IzN2I1MTAyZmYzZTVlMDc0NGI4YzYyNDA4ZjAyMDZiZWRjOS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T21:07:07Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI2283783730",
      "type": "image",
      "attributes": {
        "name": "DBI-10-4waBrkAz-CRE-Regional-Center---Starter-Package_DBI-3-DBU-9-zay9WAga-DJI_0120-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022351-18598cacab63bb9ade90e940872dde3c0b14837d/downloadable-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022351-18598cacab63bb9ade90e940872dde3c0b14837d/large_1920-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1MS0xODU5OGNhY2FiNjNiYjlhZGU5MGU5NDA4NzJkZGUzYzBiMTQ4MzdkL2xhcmdlXzE5MjAtMGUyNGUyODliZjAxNWM2YTlkYjMxMmJlYmFiZWNjN2I2NmYzYzNmNS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1MS0xODU5OGNhY2FiNjNiYjlhZGU5MGU5NDA4NzJkZGUzYzBiMTQ4MzdkL2xhcmdlXzE5MjAtMGUyNGUyODliZjAxNWM2YTlkYjMxMmJlYmFiZWNjN2I2NmYzYzNmNS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T21:07:05Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4442375169",
      "type": "image",
      "attributes": {
        "name": "DBI-9-fUJCyNn4-CRE-Regional-Center---Starter-Package_DBI-2-DBU-7-zxNP2A12-DJI_0124-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022350-5a5d13a59fd0df0ebd901216d60713d55741c32b/downloadable-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022350-5a5d13a59fd0df0ebd901216d60713d55741c32b/large_1920-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1MC01YTVkMTNhNTlmZDBkZjBlYmQ5MDEyMTZkNjA3MTNkNTU3NDFjMzJiL2xhcmdlXzE5MjAtYzUyNzY2ZmQ3YTYyNGQxYzg2NGE2ZDBmZDk5YzBmNDE0MzVjMDI5ZS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM1MC01YTVkMTNhNTlmZDBkZjBlYmQ5MDEyMTZkNjA3MTNkNTU3NDFjMzJiL2xhcmdlXzE5MjAtYzUyNzY2ZmQ3YTYyNGQxYzg2NGE2ZDBmZDk5YzBmNDE0MzVjMDI5ZS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T21:07:03Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061136",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI0598577972",
      "type": "image",
      "attributes": {
        "name": "DBI-8-9pOANazT-CRE-Regional-Center---Starter-Package_DBI-1-DBU-2-pXdHUx0i-DJI_0134-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022349-308274507fc14e040a4ea50a08b0c916cdbca8d8/downloadable-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022349-308274507fc14e040a4ea50a08b0c916cdbca8d8/large_1920-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM0OS0zMDgyNzQ1MDdmYzE0ZTA0MGE0ZWE1MGEwOGIwYzkxNmNkYmNhOGQ4L2xhcmdlXzE5MjAtM2JjZmM1NzdlZTRkNTE1ZTEwYmEzNjgzMzc4MjE4YTk0MTQ5YjcwMi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjM0OS0zMDgyNzQ1MDdmYzE0ZTA0MGE0ZWE1MGEwOGIwYzkxNmNkYmNhOGQ4L2xhcmdlXzE5MjAtM2JjZmM1NzdlZTRkNTE1ZTEwYmEzNjgzMzc4MjE4YTk0MTQ5YjcwMi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T21:07:00Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM8258483024",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061135",
            "type": "shot"
          }
        }
      }
    }]
  };
  _exports.default = _default;
});