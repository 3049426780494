define("clients/routes/missions/upload", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "clients/mixins/css-class-namespace"], function (_exports, _authenticatedRouteMixin, _cssClassNamespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _cssClassNamespace.default, {
    queryParams: {
      order_flow: {
        refreshModel: true,
        replace: true
      }
    },
    model: function (params) {
      return Ember.RSVP.hash({
        mission: this.store.find('mission', params.mission_id).then(mission => {
          return mission;
        }),
        isOrderFlow: params.order_flow || false
      });
    },
    afterModel: function (model) {
      const acceptableStatuses = ['upload_required'];
      const mission = model.mission;

      if (mission.package.order_flow !== 'client_upload' || !acceptableStatuses.includes(mission.status)) {
        this.transitionTo('fourOhFour', {});
      }
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      this.controllerFor('application').set('showNavBar', true);
      this.controllerFor('application').set('minimizedSidebar', false);
    },
    actions: {
      redirectToMission: function (mission_id) {
        this.transitionTo('missions.show', mission_id);
      }
    }
  });

  _exports.default = _default;
});