define("clients/routes/shares/ortho", ["exports", "clients/mixins/share-header-mixin", "clients/mixins/css-class-namespace"], function (_exports, _shareHeaderMixin, _cssClassNamespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cssClassNamespace.default, _shareHeaderMixin.default, {
    setupController: function (controller, model) {
      this._super(controller, model);

      this.controllerFor('application').set('collabMode', true);
      const mission = controller.set('mission', model.share.get('shareable.mission'));
      this.set('title', mission.get('location.name') + ' - Zeitview');
      this.setHeaderTags(mission.get('location.name'), mission.get('meta_image_url'));
    },
    model: function (params) {
      return Ember.RSVP.hash({
        share: this.store.findRecord('share', params.token, {
          adapterOptions: {
            shareType: 'ortho'
          }
        }),
        shareToken: params.token
      });
    },
    actions: {
      willTransition: function () {
        this.controllerFor('application').set('showNavBar', false);
        this.controllerFor('application').set('collabMode', false);
      }
    }
  });

  _exports.default = _default;
});