define("clients/data/sample_missions/cre_pano_package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": {
      "id": "DBM6328071656",
      "type": "missions",
      "attributes": {
        "instructions": "+ Scope: Panorama Tiles\n\n+ Time: Between 11am - 2pm\n\n+ Schedule: Within 48-72 hours; Weather permitting",
        "status": "complete",
        "client_id": "DBC3006554",
        "payment": {
          "id": null,
          "processor": null,
          "card": "Visa - 3969"
        },
        "external_assets_url": null,
        "owned_by_current_client": true,
        "contact_email": "clients@zeitview.com",
        "created_on": "2019-12-03T21:04:10Z",
        "is_pano_mission": false,
        "logo_url": null,
        "blurb": null,
        "paid": true,
        "paid_on": "2019-12-04T00:09:35Z",
        "price": 9900,
        "archive_url": null,
        "edit_url": null,
        "customer_instructions": "Weekend only. Fly between 12 - 3 pm\n",
        "scheduled_at_start": "2019-12-04T21:00:00Z",
        "scheduled_at_end": "2019-12-04T22:00:00Z",
        "parent_id": null,
        "reshoot_mission_id": null,
        "rejection_reason": null,
        "rejection_notes": null,
        "reference_id": "CRE Pano Package Example",
        "cloud_reqs": "less_than_50",
        "scheduling_requirement": "not_needed",
        "arcgis_feature_layer": null,
        "geo_properties": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[-118.50470331244, 34.020286742984], [-118.50428570266, 34.019859902707], [-118.50264419074, 34.021073484804], [-118.50309398703, 34.021358041158], [-118.50470331244, 34.020286742984]]]
            },
            "properties": {
              "name": "Polygon #1",
              "notes": "",
              "number": 1,
              "area": "11027.3",
              "bounds": {
                "south": 34.019859902707,
                "west": -118.50470331244,
                "north": 34.021358041158,
                "east": -118.50264419074
              },
              "center": {
                "lat": 34.020608971933,
                "lng": -118.50367375159
              }
            },
            "id": 1
          }]
        }
      },
      "relationships": {
        "location": {
          "data": {
            "id": "284924",
            "type": "locations"
          }
        },
        "package": {
          "data": {
            "id": "9591",
            "type": "packages"
          }
        },
        "credit_card": {
          "data": {
            "id": "4898",
            "type": "credit_cards"
          }
        },
        "onsite_contact": {
          "data": {
            "id": "49517",
            "type": "onsite_contacts"
          }
        },
        "scheduling_contact": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": [{
            "id": "cre-pano-package-panorama",
            "type": "panoramas"
          }]
        },
        "attachments": {
          "data": []
        },
        "ortho_photo_maps": {
          "data": []
        },
        "shares": {
          "data": [{
            "id": "c2ae8f561622b36fb0d95e8fb83b2b95",
            "type": "shares"
          }]
        },
        "main_image": {
          "data": {
            "id": "DBI5863228161",
            "type": "images"
          }
        },
        "collaborators": {
          "data": []
        },
        "parent": {
          "data": null
        },
        "shots": {
          "data": [{
            "id": "1060552",
            "type": "shots"
          }, {
            "id": "1060551",
            "type": "shots"
          }]
        },
        "invoice_line_items": {
          "data": [{
            "id": "DBINV297654091735184",
            "type": "invoice_line_items"
          }]
        }
      }
    },
    "included": [{
      "id": "284924",
      "type": "locations",
      "attributes": {
        "name": "205 Washington Ave, Santa Monica, CA 90403, USA",
        "latitude": 34.0207557,
        "longitude": -118.5033197,
        "address": "205 Washington Avenue",
        "address2": null,
        "city": "Santa Monica",
        "state": "California",
        "postal_code": "90403",
        "country": "United States",
        "formatted_address": "205 Washington Ave, Santa Monica, CA 90403, USA",
        "timezone_id": "America/Los_Angeles",
        "properties": {}
      }
    }, {
      "id": "9591",
      "type": "packages",
      "attributes": {
        "name": "Pano Package",
        "description": "Beautiful interactive panorama of your property and surrounding area. Includes an easily shareable page and embeddable link for social media and websites (not downloadable).",
        "price": 9900,
        "cloud_reqs": "less_than_50",
        "position": 1,
        "slug": "cre-pano-pack"
      },
      "relationships": {
        "vertical": {
          "data": {
            "id": "1",
            "type": "verticals"
          }
        },
        "organization": {
          "data": null
        },
        "client": {
          "data": null
        },
        "catalog_items": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "verticals",
      "attributes": {
        "name": "Commercial Real Estate",
        "short_name": "CRE"
      }
    }, {
      "id": "4898",
      "type": "credit_cards",
      "attributes": {
        "brand": "Visa",
        "last_4": "3969",
        "exp_month": 2,
        "exp_year": 2024,
        "stripe_id": "card_1FW88bJo202eeWudwLnrPXPF"
      }
    }, {
      "id": "49517",
      "type": "onsite_contacts",
      "attributes": {
        "name": "Joe Smith",
        "call_action": "Call When You Arrive",
        "phone": "123-456-7890",
        "note": ""
      }
    }, {
      "id": "DBC3006554",
      "type": "clients",
      "attributes": {
        "first_name": "Demo",
        "last_name": "Zeitview",
        "phone": "",
        "email": "clients@zeitview.com",
        "token": "qC65CqCmbKfMZvIU2gRb_uD7Sqw",
        "company_name": null,
        "invoiceable": false
      },
      "relationships": {
        "hidden_sample_missions": {
          "data": [{
            "id": "*",
            "type": "hidden_sample_mission"
          }]
        }
      }
    }, {
      "id": "cre-pano-package-panorama",
      "type": "panoramas",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "missions/DBM6328071656/panorama/DBP9173489300-pano.zip",
        "source_type": "edit",
        "share_token": "98a8a83c518cf38519a1ffed904369ec",
        "formatted_size": null,
        "size": null,
        "height": null,
        "width": null,
        "resolution": null,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "created_on": "2019-12-03T21:08:10Z",
        "access_key": "23427d10-130c-430d-a077-f677d841c683",
        "pano_base_url": "https://cdn.dronebase.com/assets/mission/panoramas/75575-23427d10-130c-430d-a077-f677d841c683",
        "watermark": false
      },
      "relationships": {
        "shot": {
          "data": {
            "id": "1060552",
            "type": "shots"
          }
        },
        "mission": {
          "data": {
            "id": "DBM6328071656",
            "type": "missions"
          }
        },
        "hotspots": {
          "data": []
        }
      }
    }, {
      "id": "c2ae8f561622b36fb0d95e8fb83b2b95",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-05T06:42:20Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM6328071656",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "1060552",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278087",
        "shot_type_id": 23,
        "pilot_comment": null,
        "post_processing_status": "completed",
        "origin_type": "package",
        "name": "Panorama Tiles"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "23",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": [{
            "id": "cre-pano-package-panorama",
            "type": "panoramas"
          }]
        }
      }
    }, {
      "id": "23",
      "type": "shot_types",
      "attributes": {
        "name": "Panorama Tiles",
        "description": "+ Do not upload a stitched panorama. We need the individual images so that we can stitch the panorama. \n\nWe recommend using DJI Go for panoramas. To take a panorama with DJI Go:\n\n1. Go to \u201cCamera settings\u201d\n2. Select \u201cPhoto\u201d\n3. Select \u201cPano\u201d\n4. Select \u201cSphere\u201d\n\nPanorama tiles\n+ (1) set of panorama tiles\n+ How to take a set of panorama tiles: [Click here](http://bit.ly/2ONpr6h) \n+ Turn off auto exposure\n+ Auto white balance must be off\n+ Shoot in 4:3 aspect ratio (3:2 is also acceptable)\n+ Altitude: 100' (unless another altitude is listed for the mission)\n+ [Example 1](https://bit.ly/30s09R6)\u00a0- This panorama is taken from too far away. The subject property is the Rite Aid on the other side of the street.\u00a0\n+ [Example 2](https://bit.ly/2Hz6Acw)\u00a0- This panorama is taken from a perfect distance. You can see the front of the Family Dollar clearly and it perfectly showcases the property.\u00a0",
        "video": null,
        "slug": "pano_tiles",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1060551",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278087",
        "shot_type_id": 22,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Final Assets"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "22",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "22",
      "type": "shot_types",
      "attributes": {
        "name": "Final Assets",
        "description": "Use this shot to upload final edited assets for the customer. Not for use by pilots.\n",
        "video": null,
        "slug": "final-assets",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "DBINV297654091735184",
      "type": "invoice_line_items",
      "attributes": {
        "reason": "base_package_price",
        "description": null,
        "amount": 9900,
        "created_on": "2019-12-03T21:04:10Z",
        "display_name": "Base Package Price"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM6328071656",
            "type": "missions"
          }
        },
        "work_item": {
          "data": null
        }
      }
    }]
  };
  _exports.default = _default;
});