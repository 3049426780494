define("clients/adapters/client", ["exports", "clients/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClientAdapter;
  ClientAdapter = _application.default.extend({
    accountUrl: function () {
      return this.urlPrefix() + "/clients/account";
    },
    queryRecord: function () {
      return this.ajax(this.accountUrl(), 'GET');
    },
    updateRecord: function (store, type, snapshot) {
      var data, serializer;
      data = {};
      serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      return this.ajax(this.accountUrl(), 'PATCH', {
        data: data
      });
    }
  });
  var _default = ClientAdapter;
  _exports.default = _default;
});