define("clients/helpers/capitalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CapitalizeHelper;
  CapitalizeHelper = Ember.Helper.helper(function (arg) {
    var string;
    string = arg[0];
    return string.toUpperCase();
  });
  var _default = CapitalizeHelper;
  _exports.default = _default;
});