define("clients/data/sample_images/cre_pro_shopping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": [{
      "id": "DBI8597164825",
      "type": "image",
      "attributes": {
        "name": "DBI-31-uSG9cLNH-CRE-Images-Package---Shopping-Centers-Sample_DBI-52-KjcTo8Vv-Lakeside-Marketplace_DBU93-DJI_0076.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034112-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/downloadable-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034112-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/large_1920-09a1772eda56403b53f7b96eff92dc466cc7b75e.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDExMi0zNDMwYzJlMjJhNmNlNmIwNmMxMGQ3YWI3NmZkMmIxNjI2MGU3YzcxL2xhcmdlXzE5MjAtMDlhMTc3MmVkYTU2NDAzYjUzZjdiOTZlZmY5MmRjNDY2Y2M3Yjc1ZS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDExMi0zNDMwYzJlMjJhNmNlNmIwNmMxMGQ3YWI3NmZkMmIxNjI2MGU3YzcxL2xhcmdlXzE5MjAtMDlhMTc3MmVkYTU2NDAzYjUzZjdiOTZlZmY5MmRjNDY2Y2M3Yjc1ZS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:52:12Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061113",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8760574137",
      "type": "image",
      "attributes": {
        "name": "DBI-30-0QuUnSKR-CRE-Images-Package---Shopping-Centers-Sample_DBI-58-9MohZxUx-Lakeside-Marketplace_DBU99-DJI_0088.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034111-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/downloadable-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034111-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/large_1920-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDExMS0zNDMwYzJlMjJhNmNlNmIwNmMxMGQ3YWI3NmZkMmIxNjI2MGU3YzcxL2xhcmdlXzE5MjAtZGEzMmQyYzZmZmUwMGY2M2JjYmU2NjIxNmY1N2E0MDgzMmZkYTY0OS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDExMS0zNDMwYzJlMjJhNmNlNmIwNmMxMGQ3YWI3NmZkMmIxNjI2MGU3YzcxL2xhcmdlXzE5MjAtZGEzMmQyYzZmZmUwMGY2M2JjYmU2NjIxNmY1N2E0MDgzMmZkYTY0OS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:52:12Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061113",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6642380667",
      "type": "image",
      "attributes": {
        "name": "DBI-29-74eJjHKp-CRE-Images-Package---Shopping-Centers-Sample_DBI-49-GBkeyipm-Lakeside-Marketplace_DBU90-DJI_0073.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034110-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/downloadable-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034110-3430c2e22a6ce6b06c10d7ab76fd2b16260e7c71/large_1920-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDExMC0zNDMwYzJlMjJhNmNlNmIwNmMxMGQ3YWI3NmZkMmIxNjI2MGU3YzcxL2xhcmdlXzE5MjAtMzFmN2Y0N2NmNzk4OGI3YzgzYjJkODQ5NDkxYTBlMjljNWJkMTZlMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDExMC0zNDMwYzJlMjJhNmNlNmIwNmMxMGQ3YWI3NmZkMmIxNjI2MGU3YzcxL2xhcmdlXzE5MjAtMzFmN2Y0N2NmNzk4OGI3YzgzYjJkODQ5NDkxYTBlMjljNWJkMTZlMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:52:12Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061113",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8376309490",
      "type": "image",
      "attributes": {
        "name": "DBI-28-5bts7zq4-CRE-Images-Package---Shopping-Centers-Sample_DBI-38-GTIEV2gg-Lakeside-Marketplace_DBU79-DJI_0059.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034109-76eb74c083f750365ded847e5c017e4ac68cc868/downloadable-12ed543fd505bfe44d19beeb9ea970d13fadcc95.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034109-76eb74c083f750365ded847e5c017e4ac68cc868/large_1920-12ed543fd505bfe44d19beeb9ea970d13fadcc95.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDEwOS03NmViNzRjMDgzZjc1MDM2NWRlZDg0N2U1YzAxN2U0YWM2OGNjODY4L2xhcmdlXzE5MjAtMTJlZDU0M2ZkNTA1YmZlNDRkMTliZWViOWVhOTcwZDEzZmFkY2M5NS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDEwOS03NmViNzRjMDgzZjc1MDM2NWRlZDg0N2U1YzAxN2U0YWM2OGNjODY4L2xhcmdlXzE5MjAtMTJlZDU0M2ZkNTA1YmZlNDRkMTliZWViOWVhOTcwZDEzZmFkY2M5NS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:52:11Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061113",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3685638867",
      "type": "image",
      "attributes": {
        "name": "DBI-27-QevIdklB-CRE-Images-Package---Shopping-Centers-Sample_DBI-53-1e11H3VD-Lakeside-Marketplace_DBU94-DJI_0077.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034108-76eb74c083f750365ded847e5c017e4ac68cc868/downloadable-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034108-76eb74c083f750365ded847e5c017e4ac68cc868/large_1920-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDEwOC03NmViNzRjMDgzZjc1MDM2NWRlZDg0N2U1YzAxN2U0YWM2OGNjODY4L2xhcmdlXzE5MjAtNjIxNTViZDEwMTkzMzQyZDJmYmI4OGY2NTEyMDI5Zjc2ODY1MDcxYS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDEwOC03NmViNzRjMDgzZjc1MDM2NWRlZDg0N2U1YzAxN2U0YWM2OGNjODY4L2xhcmdlXzE5MjAtNjIxNTViZDEwMTkzMzQyZDJmYmI4OGY2NTEyMDI5Zjc2ODY1MDcxYS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:52:11Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061113",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI9261501463",
      "type": "image",
      "attributes": {
        "name": "DBI-26-5vNHBdMU-CRE-Images-Package---Shopping-Centers-Sample_DBI-54-8efSASmw-Lakeside-Marketplace_DBU95-DJI_0079.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034107-a2324af53fd5d53f4816b54a805d9498173ca44c/downloadable-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034107-a2324af53fd5d53f4816b54a805d9498173ca44c/large_1920-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDEwNy1hMjMyNGFmNTNmZDVkNTNmNDgxNmI1NGE4MDVkOTQ5ODE3M2NhNDRjL2xhcmdlXzE5MjAtZGEyYmVhMzE5YjMzMTliNWU2MmVmOWQ0MmU4ZmQ4MzExMmQ1NGE4Ny5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDEwNy1hMjMyNGFmNTNmZDVkNTNmNDgxNmI1NGE4MDVkOTQ5ODE3M2NhNDRjL2xhcmdlXzE5MjAtZGEyYmVhMzE5YjMzMTliNWU2MmVmOWQ0MmU4ZmQ4MzExMmQ1NGE4Ny5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:51:45Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4080429092",
      "type": "image",
      "attributes": {
        "name": "DBI-25-2UUcjuQr-CRE-Images-Package---Shopping-Centers-Sample_DBI-47-Mqr74Qkf-Lakeside-Marketplace_DBU88-DJI_0070.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034106-a2324af53fd5d53f4816b54a805d9498173ca44c/downloadable-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034106-a2324af53fd5d53f4816b54a805d9498173ca44c/large_1920-e64341159a25bb4a98684dd5ab2664b91eef4bed.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDEwNi1hMjMyNGFmNTNmZDVkNTNmNDgxNmI1NGE4MDVkOTQ5ODE3M2NhNDRjL2xhcmdlXzE5MjAtZTY0MzQxMTU5YTI1YmI0YTk4Njg0ZGQ1YWIyNjY0YjkxZWVmNGJlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDEwNi1hMjMyNGFmNTNmZDVkNTNmNDgxNmI1NGE4MDVkOTQ5ODE3M2NhNDRjL2xhcmdlXzE5MjAtZTY0MzQxMTU5YTI1YmI0YTk4Njg0ZGQ1YWIyNjY0YjkxZWVmNGJlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:51:45Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI5623489935",
      "type": "image",
      "attributes": {
        "name": "DBI-22-aqr7pW7a-CRE-Images-Package---Shopping-Centers-Sample_DBI-57-TgwqvXuM-Lakeside-Marketplace_DBU98-DJI_0087.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034103-423d12e006060c11e792c9d6f3badb33a351ca6a/downloadable-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034103-423d12e006060c11e792c9d6f3badb33a351ca6a/large_1920-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDEwMy00MjNkMTJlMDA2MDYwYzExZTc5MmM5ZDZmM2JhZGIzM2EzNTFjYTZhL2xhcmdlXzE5MjAtMTg4YzRkOWI5ZWY5Nzc3NTVkZTE4YmViZDE4NzA3YmI3NzJmMDhjMS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDEwMy00MjNkMTJlMDA2MDYwYzExZTc5MmM5ZDZmM2JhZGIzM2EzNTFjYTZhL2xhcmdlXzE5MjAtMTg4YzRkOWI5ZWY5Nzc3NTVkZTE4YmViZDE4NzA3YmI3NzJmMDhjMS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:51:44Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3311858014",
      "type": "image",
      "attributes": {
        "name": "DBI-20-3WlxudO4-CRE-Images-Package---Shopping-Centers-Sample_DBI-48-jE4NmqfY-Lakeside-Marketplace_DBU89-DJI_0071.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034101-423d12e006060c11e792c9d6f3badb33a351ca6a/downloadable-d6b51620db80cf004109697928574792eca08fcd.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034101-423d12e006060c11e792c9d6f3badb33a351ca6a/large_1920-d6b51620db80cf004109697928574792eca08fcd.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDEwMS00MjNkMTJlMDA2MDYwYzExZTc5MmM5ZDZmM2JhZGIzM2EzNTFjYTZhL2xhcmdlXzE5MjAtZDZiNTE2MjBkYjgwY2YwMDQxMDk2OTc5Mjg1NzQ3OTJlY2EwOGZjZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDEwMS00MjNkMTJlMDA2MDYwYzExZTc5MmM5ZDZmM2JhZGIzM2EzNTFjYTZhL2xhcmdlXzE5MjAtZDZiNTE2MjBkYjgwY2YwMDQxMDk2OTc5Mjg1NzQ3OTJlY2EwOGZjZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:51:44Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI1245210655",
      "type": "image",
      "attributes": {
        "name": "DBI-18-PKjrfr2q-CRE-Images-Package---Shopping-Centers-Sample_DBI-42-9rot8SIa-Lakeside-Marketplace_DBU83-DJI_0063.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034099-7d29e35325db10e0280864ba9830b2d57e0c2ac4/downloadable-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034099-7d29e35325db10e0280864ba9830b2d57e0c2ac4/large_1920-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA5OS03ZDI5ZTM1MzI1ZGIxMGUwMjgwODY0YmE5ODMwYjJkNTdlMGMyYWM0L2xhcmdlXzE5MjAtMjVkNjgxNWI0NzA4ZmEyNjBmNGQxYzgwOWYwZGJmYzU1YmIxOTAwYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA5OS03ZDI5ZTM1MzI1ZGIxMGUwMjgwODY0YmE5ODMwYjJkNTdlMGMyYWM0L2xhcmdlXzE5MjAtMjVkNjgxNWI0NzA4ZmEyNjBmNGQxYzgwOWYwZGJmYzU1YmIxOTAwYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:51:43Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI5179860475",
      "type": "image",
      "attributes": {
        "name": "DBI-14-PU5eB4oU-CRE-Images-Package---Shopping-Centers-Sample_DBI-45-TzJSLqDA-Lakeside-Marketplace_DBU86-DJI_0067.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034095-7d29e35325db10e0280864ba9830b2d57e0c2ac4/downloadable-9eb54f47001de7b12d8ccecf55838e302a083c0c.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034095-7d29e35325db10e0280864ba9830b2d57e0c2ac4/large_1920-9eb54f47001de7b12d8ccecf55838e302a083c0c.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA5NS03ZDI5ZTM1MzI1ZGIxMGUwMjgwODY0YmE5ODMwYjJkNTdlMGMyYWM0L2xhcmdlXzE5MjAtOWViNTRmNDcwMDFkZTdiMTJkOGNjZWNmNTU4MzhlMzAyYTA4M2MwYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA5NS03ZDI5ZTM1MzI1ZGIxMGUwMjgwODY0YmE5ODMwYjJkNTdlMGMyYWM0L2xhcmdlXzE5MjAtOWViNTRmNDcwMDFkZTdiMTJkOGNjZWNmNTU4MzhlMzAyYTA4M2MwYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:51:43Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI5829111494",
      "type": "image",
      "attributes": {
        "name": "DBI-13-pLwYaThP-CRE-Images-Package---Shopping-Centers-Sample_DBI-43-0iuykUPm-Lakeside-Marketplace_DBU84-DJI_0064.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034094-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/downloadable-0d9a3633314f04a9482ef068e811bfa0dcc80c5c.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034094-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/large_1920-0d9a3633314f04a9482ef068e811bfa0dcc80c5c.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA5NC1jNjFkNGMwOTNiMDk1NDU4ZjMwNzYxZTBiNWE4YzBlNmJmMDlmNDhlL2xhcmdlXzE5MjAtMGQ5YTM2MzMzMTRmMDRhOTQ4MmVmMDY4ZTgxMWJmYTBkY2M4MGM1Yy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA5NC1jNjFkNGMwOTNiMDk1NDU4ZjMwNzYxZTBiNWE4YzBlNmJmMDlmNDhlL2xhcmdlXzE5MjAtMGQ5YTM2MzMzMTRmMDRhOTQ4MmVmMDY4ZTgxMWJmYTBkY2M4MGM1Yy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:51:42Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI0738293409",
      "type": "image",
      "attributes": {
        "name": "DBI-12-BTeNJg55-CRE-Images-Package---Shopping-Centers-Sample_DBI-41-gyYgLLgd-Lakeside-Marketplace_DBU82-DJI_0062.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034093-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/downloadable-6b558fc504d057a630e1f639a01abd4d8df65b0c.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034093-c61d4c093b095458f30761e0b5a8c0e6bf09f48e/large_1920-6b558fc504d057a630e1f639a01abd4d8df65b0c.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA5My1jNjFkNGMwOTNiMDk1NDU4ZjMwNzYxZTBiNWE4YzBlNmJmMDlmNDhlL2xhcmdlXzE5MjAtNmI1NThmYzUwNGQwNTdhNjMwZTFmNjM5YTAxYWJkNGQ4ZGY2NWIwYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA5My1jNjFkNGMwOTNiMDk1NDU4ZjMwNzYxZTBiNWE4YzBlNmJmMDlmNDhlL2xhcmdlXzE5MjAtNmI1NThmYzUwNGQwNTdhNjMwZTFmNjM5YTAxYWJkNGQ4ZGY2NWIwYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:51:42Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061112",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8035671465",
      "type": "image",
      "attributes": {
        "name": "DBI-11-yUNFQKEv-CRE-Images-Package---Shopping-Centers-Sample_DBI-32-nu7toZ8x-Lakeside-Marketplace_DBU73-SE_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034091-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/downloadable-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034091-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/large_1920-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA5MS0xZmJmMmM2YzFhNzg0NDgwNTQwOWFjNDQzYzdjYmQ5Y2ZmZDE3ZmU3L2xhcmdlXzE5MjAtZGI0MWY3ZjhkMjg2MjBkMjI4MWJlYWRiY2UzN2E4NGM0MGY4YzhhNy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA5MS0xZmJmMmM2YzFhNzg0NDgwNTQwOWFjNDQzYzdjYmQ5Y2ZmZDE3ZmU3L2xhcmdlXzE5MjAtZGI0MWY3ZjhkMjg2MjBkMjI4MWJlYWRiY2UzN2E4NGM0MGY4YzhhNy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:51:07Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8170083453",
      "type": "image",
      "attributes": {
        "name": "DBI-10-WCDSA7ZA-CRE-Images-Package---Shopping-Centers-Sample_DBI-35-3rht1dJV-Lakeside-Marketplace_DBU76-FRONT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034090-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/downloadable-762384d67608457afc248fde66c893da7f918496.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034090-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/large_1920-762384d67608457afc248fde66c893da7f918496.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA5MC0xZmJmMmM2YzFhNzg0NDgwNTQwOWFjNDQzYzdjYmQ5Y2ZmZDE3ZmU3L2xhcmdlXzE5MjAtNzYyMzg0ZDY3NjA4NDU3YWZjMjQ4ZmRlNjZjODkzZGE3ZjkxODQ5Ni5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA5MC0xZmJmMmM2YzFhNzg0NDgwNTQwOWFjNDQzYzdjYmQ5Y2ZmZDE3ZmU3L2xhcmdlXzE5MjAtNzYyMzg0ZDY3NjA4NDU3YWZjMjQ4ZmRlNjZjODkzZGE3ZjkxODQ5Ni5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:51:07Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI5293278666",
      "type": "image",
      "attributes": {
        "name": "DBI-9-Gj9NU7aO-CRE-Images-Package---Shopping-Centers-Sample_DBI-34-Yb0qjasr-Lakeside-Marketplace_DBU75-W_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034089-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/downloadable-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034089-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/large_1920-9731f0cbd9aff1ad889244b959223ec8b46d8cb2.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA4OS0xZmJmMmM2YzFhNzg0NDgwNTQwOWFjNDQzYzdjYmQ5Y2ZmZDE3ZmU3L2xhcmdlXzE5MjAtOTczMWYwY2JkOWFmZjFhZDg4OTI0NGI5NTkyMjNlYzhiNDZkOGNiMi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA4OS0xZmJmMmM2YzFhNzg0NDgwNTQwOWFjNDQzYzdjYmQ5Y2ZmZDE3ZmU3L2xhcmdlXzE5MjAtOTczMWYwY2JkOWFmZjFhZDg4OTI0NGI5NTkyMjNlYzhiNDZkOGNiMi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:51:07Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI0356717204",
      "type": "image",
      "attributes": {
        "name": "DBI-8-CSbtEuJz-CRE-Images-Package---Shopping-Centers-Sample_DBI-31-zGjKtYXQ-Lakeside-Marketplace_DBU72-S_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034088-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/downloadable-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034088-1fbf2c6c1a7844805409ac443c7cbd9cffd17fe7/large_1920-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA4OC0xZmJmMmM2YzFhNzg0NDgwNTQwOWFjNDQzYzdjYmQ5Y2ZmZDE3ZmU3L2xhcmdlXzE5MjAtN2I2NWM2YWFjODBiMWViZDRjMjQ1YzViYzkyZWFjN2FmYzQyYTJlMC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA4OC0xZmJmMmM2YzFhNzg0NDgwNTQwOWFjNDQzYzdjYmQ5Y2ZmZDE3ZmU3L2xhcmdlXzE5MjAtN2I2NWM2YWFjODBiMWViZDRjMjQ1YzViYzkyZWFjN2FmYzQyYTJlMC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:51:07Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI1025313174",
      "type": "image",
      "attributes": {
        "name": "DBI-7-VLUhPlSX-CRE-Images-Package---Shopping-Centers-Sample_DBI-29-Q4idBa2p-Lakeside-Marketplace_DBU70-NE_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034087-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/downloadable-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034087-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/large_1920-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA4Ny0wODc0ZTRkZGQ1MTlkOGJlOGMxNGNhZDNhMzkzYTJmOGUzNDc5MmY0L2xhcmdlXzE5MjAtNDkyZDBiMjJmMTBkOTAwZWYxMjM4NDE4MjZlNjZkZTk3MDU5MThkOC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA4Ny0wODc0ZTRkZGQ1MTlkOGJlOGMxNGNhZDNhMzkzYTJmOGUzNDc5MmY0L2xhcmdlXzE5MjAtNDkyZDBiMjJmMTBkOTAwZWYxMjM4NDE4MjZlNjZkZTk3MDU5MThkOC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:51:06Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI9457110968",
      "type": "image",
      "attributes": {
        "name": "DBI-6-Dvh5h2U7-CRE-Images-Package---Shopping-Centers-Sample_DBI-28-zNfK8rO0-Lakeside-Marketplace_DBU69-N_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034086-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/downloadable-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034086-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/large_1920-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA4Ni0wODc0ZTRkZGQ1MTlkOGJlOGMxNGNhZDNhMzkzYTJmOGUzNDc5MmY0L2xhcmdlXzE5MjAtODQxMDQwNDNmNmJiOWJkZmZlMTM0NGZlMjJjMDgxMDY3MmY2MDU4ZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA4Ni0wODc0ZTRkZGQ1MTlkOGJlOGMxNGNhZDNhMzkzYTJmOGUzNDc5MmY0L2xhcmdlXzE5MjAtODQxMDQwNDNmNmJiOWJkZmZlMTM0NGZlMjJjMDgxMDY3MmY2MDU4ZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:51:06Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI7618504574",
      "type": "image",
      "attributes": {
        "name": "DBI-5-s0lgtIaA-CRE-Images-Package---Shopping-Centers-Sample_DBI-27-JGEYmlMO-Lakeside-Marketplace_DBU68-E_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034085-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/downloadable-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034085-0874e4ddd519d8be8c14cad3a393a2f8e34792f4/large_1920-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA4NS0wODc0ZTRkZGQ1MTlkOGJlOGMxNGNhZDNhMzkzYTJmOGUzNDc5MmY0L2xhcmdlXzE5MjAtZmQzNDg2NWI2ZDFhOGQ3ODRlYzY3MWU3MjJiZGQwMWQzZmE0MmY3ZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA4NS0wODc0ZTRkZGQ1MTlkOGJlOGMxNGNhZDNhMzkzYTJmOGUzNDc5MmY0L2xhcmdlXzE5MjAtZmQzNDg2NWI2ZDFhOGQ3ODRlYzY3MWU3MjJiZGQwMWQzZmE0MmY3ZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:51:06Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI7679823879",
      "type": "image",
      "attributes": {
        "name": "DBI-21-rXN32TWY-CRE-Images-Package---Shopping-Centers-Sample_DBI-30-6Cem6m7R-Lakeside-Marketplace_DBU71-NW_100FTR.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034080-36566a8ff97222ea4975224e6b56a8954fe96d46/downloadable-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034080-36566a8ff97222ea4975224e6b56a8954fe96d46/large_1920-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA4MC0zNjU2NmE4ZmY5NzIyMmVhNDk3NTIyNGU2YjU2YTg5NTRmZTk2ZDQ2L2xhcmdlXzE5MjAtOTk5ZDRmYTBhNTVlNjkzZTA3NWM2ZGJhOTc2OTJjN2IwZjVmZmNiMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA4MC0zNjU2NmE4ZmY5NzIyMmVhNDk3NTIyNGU2YjU2YTg5NTRmZTk2ZDQ2L2xhcmdlXzE5MjAtOTk5ZDRmYTBhNTVlNjkzZTA3NWM2ZGJhOTc2OTJjN2IwZjVmZmNiMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:50:26Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061109",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6864505553",
      "type": "image",
      "attributes": {
        "name": "DBI-20-2Z0jrRkq-CRE-Images-Package---Shopping-Centers-Sample_DBI-33-egnffXbz-Lakeside-Marketplace_DBU74-SW_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034079-1b952b83c02d14cc0155b73890b90ac4b92b8445/downloadable-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034079-1b952b83c02d14cc0155b73890b90ac4b92b8445/large_1920-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA3OS0xYjk1MmI4M2MwMmQxNGNjMDE1NWI3Mzg5MGI5MGFjNGI5MmI4NDQ1L2xhcmdlXzE5MjAtNThiNTZlZDJjMDU0YzUxM2I4MDM5MDhlMmM2MjE3MjQ4ODEzODFhMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA3OS0xYjk1MmI4M2MwMmQxNGNjMDE1NWI3Mzg5MGI5MGFjNGI5MmI4NDQ1L2xhcmdlXzE5MjAtNThiNTZlZDJjMDU0YzUxM2I4MDM5MDhlMmM2MjE3MjQ4ODEzODFhMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:50:25Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061109",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3494678406",
      "type": "image",
      "attributes": {
        "name": "DBI-19-dYgr8DCm-CRE-Images-Package---Shopping-Centers-Sample_DBI-51-n6hpnhi5-Lakeside-Marketplace_DBU92-DJI_0075.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034078-1b952b83c02d14cc0155b73890b90ac4b92b8445/downloadable-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034078-1b952b83c02d14cc0155b73890b90ac4b92b8445/large_1920-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA3OC0xYjk1MmI4M2MwMmQxNGNjMDE1NWI3Mzg5MGI5MGFjNGI5MmI4NDQ1L2xhcmdlXzE5MjAtZDM1YWYxMTZhZDhmZmFjNmEzOTI1NjRlMWUzY2Q1YWQ5OWQ3OTNkYS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDA3OC0xYjk1MmI4M2MwMmQxNGNjMDE1NWI3Mzg5MGI5MGFjNGI5MmI4NDQ1L2xhcmdlXzE5MjAtZDM1YWYxMTZhZDhmZmFjNmEzOTI1NjRlMWUzY2Q1YWQ5OWQ3OTNkYS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:50:25Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061109",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4782590917",
      "type": "image",
      "attributes": {
        "name": "DBI-7-J9Nh4NdZ-CRE-Shopping-Center---Pro-Package_DBI-13-downloadable-9056869b7405a854fb6e71d59ed653af89190af0-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022108-eb6ef2c27f8fb4b45bde10561d857c3dc6504c7f/downloadable-0b5bc5f30c8c20722a0637f7d935eae70b78a4b7.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022108-eb6ef2c27f8fb4b45bde10561d857c3dc6504c7f/large_1920-0b5bc5f30c8c20722a0637f7d935eae70b78a4b7.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjEwOC1lYjZlZjJjMjdmOGZiNGI0NWJkZTEwNTYxZDg1N2MzZGM2NTA0YzdmL2xhcmdlXzE5MjAtMGI1YmM1ZjMwYzhjMjA3MjJhMDYzN2Y3ZDkzNWVhZTcwYjc4YTRiNy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjEwOC1lYjZlZjJjMjdmOGZiNGI0NWJkZTEwNTYxZDg1N2MzZGM2NTA0YzdmL2xhcmdlXzE5MjAtMGI1YmM1ZjMwYzhjMjA3MjJhMDYzN2Y3ZDkzNWVhZTcwYjc4YTRiNy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T20:56:15Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8221785687",
      "type": "image",
      "attributes": {
        "name": "DBI-4-hyJfkApL-CRE-Shopping-Center---Pro-Package_DBI-6-downloadable-8c8bd726f1e7c2e7ab07cd153987ce194dcce521-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022104-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/downloadable-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022104-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/large_1920-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjEwNC0yYTVjY2EzMTdkYmRjMWFlMDQ1OWI5ZWE0YTY1MTAyMDVkZWU3NzdiL2xhcmdlXzE5MjAtZTRlNzM4ZGI5ZTBkN2E4NmM5MDgzOWM5YWIyODM3YWFmZWRjYjNmZi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjEwNC0yYTVjY2EzMTdkYmRjMWFlMDQ1OWI5ZWE0YTY1MTAyMDVkZWU3NzdiL2xhcmdlXzE5MjAtZTRlNzM4ZGI5ZTBkN2E4NmM5MDgzOWM5YWIyODM3YWFmZWRjYjNmZi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T20:56:14Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4989095648",
      "type": "image",
      "attributes": {
        "name": "DBI-1-KMWB9elc-CRE-Shopping-Center---Pro-Package_DBI-2-downloadable-2c3d73a0c4507f991902810165ca6c438fd2323b-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10022100-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/downloadable-070d74e7e137fc3171cc5f92907287c14b9acb1b.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10022100-2a5cca317dbdc1ae0459b9ea4a6510205dee777b/large_1920-070d74e7e137fc3171cc5f92907287c14b9acb1b.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjEwMC0yYTVjY2EzMTdkYmRjMWFlMDQ1OWI5ZWE0YTY1MTAyMDVkZWU3NzdiL2xhcmdlXzE5MjAtMDcwZDc0ZTdlMTM3ZmMzMTcxY2M1ZjkyOTA3Mjg3YzE0YjlhY2IxYi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMjEwMC0yYTVjY2EzMTdkYmRjMWFlMDQ1OWI5ZWE0YTY1MTAyMDVkZWU3NzdiL2xhcmdlXzE5MjAtMDcwZDc0ZTdlMTM3ZmMzMTcxY2M1ZjkyOTA3Mjg3YzE0YjlhY2IxYi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T20:56:14Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7795792701",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061110",
            "type": "shot"
          }
        }
      }
    }]
  };
  _exports.default = _default;
});