define("clients/components/mission-plan-shot-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SelectShotComponent;
  SelectShotComponent = Ember.Component.extend({
    metrics: Ember.inject.service(),
    content: null,
    prompt: null,
    optionValuePath: 'value',
    optionLabelPath: 'label',
    action: Ember.K,
    classNames: ['form-group'],
    selectClass: 'form-control',
    value: null,
    init: function () {
      this._super();

      if (!this.get('content')) {
        return this.set('content', []);
      }
    },
    didInsertElement: function () {
      return Ember.run.schedule('afterRender', this, function () {
        return this.$('select').val(this.get('value'));
      });
    },
    actions: {
      updateValue: function () {
        if (this.value) {
          return this.parentView.updateShot(this.$('select').val(), this.$('select option:selected').text());
        } else {
          this.parentView.addShot(this.$('select').val(), this.$('select option:selected').text());
          this.$('select').val(null);
          return this.get('metrics').trackEvent({
            category: 'Plan Mission',
            action: 'click',
            label: 'Add Shot'
          });
        }
      }
    }
  });
  var _default = SelectShotComponent;
  _exports.default = _default;
});