define("clients/templates/organization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RhfcNT+1",
    "block": "{\"symbols\":[\"client\"],\"statements\":[[10,\"div\"],[14,0,\"organization-page\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"org-header\"],[12],[2,\"\\n    Account List\\n    \"],[10,\"span\"],[14,0,\"clients-amount\"],[12],[2,\"\\n      (\"],[1,[35,0,[\"organization\",\"clients\",\"length\"]]],[2,\")\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"org-description\"],[12],[2,\"\\n      Below are all the accounts in your organization.\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"org-table\"],[12],[2,\"\\n    \"],[10,\"table\"],[14,0,\"organization-accounts-table\"],[12],[2,\"\\n      \"],[10,\"thead\"],[14,0,\"org-accounts-header\"],[12],[2,\"\\n        \"],[10,\"th\"],[14,0,\"header-col name-col\"],[12],[2,\" Name \"],[13],[2,\"\\n        \"],[10,\"th\"],[14,0,\"header-col email-col\"],[12],[2,\" Email \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"tbody\"],[14,0,\"org-accounts-body\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[2,\" \"],[1,[32,1,[\"fullName\"]]],[13],[2,\"\\n            \"],[10,\"td\"],[12],[2,\" \"],[1,[32,1,[\"email\"]]],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"sortedClients\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "clients/templates/organization.hbs"
    }
  });

  _exports.default = _default;
});