define("clients/helpers/string-includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var StringIncludesHelper;
  StringIncludesHelper = Ember.Helper.helper(function (arg) {
    var str, substring;
    str = arg[0], substring = arg[1];
    return str.indexOf(substring) >= 0;
  });
  var _default = StringIncludesHelper;
  _exports.default = _default;
});