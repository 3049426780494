define("clients/transforms/array", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ArrayTransform;
  ArrayTransform = _emberData.default.Transform.extend({
    deserialize: function (serialized) {
      return serialized;
    },
    serialize: function (deserialized) {
      return deserialized;
    }
  });
  var _default = ArrayTransform;
  _exports.default = _default;
});