define("clients/data/sample_images/rre_starter_package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": [{
      "id": "DBI7267645428",
      "type": "image",
      "attributes": {
        "name": "DBI-15-cyJ8ZSAX-DBUV011-DBM0011938845-100-06.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10002881-b1a8f34d25d386665f6de98cff4a9d6eb12e701e/downloadable-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002881-b1a8f34d25d386665f6de98cff4a9d6eb12e701e/large_1920-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg4MS1iMWE4ZjM0ZDI1ZDM4NjY2NWY2ZGU5OGNmZjRhOWQ2ZWIxMmU3MDFlL2xhcmdlXzE5MjAtMDBjZDY5MzI5ZDc5ODY1ZjZjYTE0YjQzNjQ2MDM1ZjIzYjFkYzcwZC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg4MS1iMWE4ZjM0ZDI1ZDM4NjY2NWY2ZGU5OGNmZjRhOWQ2ZWIxMmU3MDFlL2xhcmdlXzE5MjAtMDBjZDY5MzI5ZDc5ODY1ZjZjYTE0YjQzNjQ2MDM1ZjIzYjFkYzcwZC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-02T23:00:26Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI5053415798",
      "type": "image",
      "attributes": {
        "name": "DBI-14-59uTf3fD-DBU14-DBM0011938845_100_08.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10002879-7b8fade7a4f2ce94d034beb36b73608a5b383804/downloadable-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002879-7b8fade7a4f2ce94d034beb36b73608a5b383804/large_1920-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3OS03YjhmYWRlN2E0ZjJjZTk0ZDAzNGJlYjM2YjczNjA4YTViMzgzODA0L2xhcmdlXzE5MjAtOWI4NzcwN2QyNjMzZTBhNGJiOWFlOGEzNjZkYzljYWEwNDU2YmVkNS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3OS03YjhmYWRlN2E0ZjJjZTk0ZDAzNGJlYjM2YjczNjA4YTViMzgzODA0L2xhcmdlXzE5MjAtOWI4NzcwN2QyNjMzZTBhNGJiOWFlOGEzNjZkYzljYWEwNDU2YmVkNS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-02T23:00:20Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI7475659806",
      "type": "image",
      "attributes": {
        "name": "DBI-13-PLuoy2Ue-DBU13-DBM0011938845_100_07.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10002878-cc27296a97fc19573a80cd6593b5c43a7622070f/downloadable-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002878-cc27296a97fc19573a80cd6593b5c43a7622070f/large_1920-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3OC1jYzI3Mjk2YTk3ZmMxOTU3M2E4MGNkNjU5M2I1YzQzYTc2MjIwNzBmL2xhcmdlXzE5MjAtZDdhMjQyYjIwMDJjZjM3MjYxNjJhZWE2YjRkMjFkYjZkMzQxY2I3ZS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3OC1jYzI3Mjk2YTk3ZmMxOTU3M2E4MGNkNjU5M2I1YzQzYTc2MjIwNzBmL2xhcmdlXzE5MjAtZDdhMjQyYjIwMDJjZjM3MjYxNjJhZWE2YjRkMjFkYjZkMzQxY2I3ZS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-02T23:00:19Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI1488550160",
      "type": "image",
      "attributes": {
        "name": "DBI-12-gJzOgwAa-DBU11-DBM0011938845_100_05.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10002877-cc27296a97fc19573a80cd6593b5c43a7622070f/downloadable-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002877-cc27296a97fc19573a80cd6593b5c43a7622070f/large_1920-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3Ny1jYzI3Mjk2YTk3ZmMxOTU3M2E4MGNkNjU5M2I1YzQzYTc2MjIwNzBmL2xhcmdlXzE5MjAtNjE3ODgxNDM3YjQ1ZDBlNjc2YjczNzU0YTZkOGU1ZjdkNjRiY2MyZi5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3Ny1jYzI3Mjk2YTk3ZmMxOTU3M2E4MGNkNjU5M2I1YzQzYTc2MjIwNzBmL2xhcmdlXzE5MjAtNjE3ODgxNDM3YjQ1ZDBlNjc2YjczNzU0YTZkOGU1ZjdkNjRiY2MyZi5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-02T23:00:19Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI2133540155",
      "type": "image",
      "attributes": {
        "name": "DBI-11-cDJAk0PO-DBU10-DBM0011938845_100_04.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10002876-f8fd117ed02c9c85e2ce00616fa36574cc005d70/downloadable-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002876-f8fd117ed02c9c85e2ce00616fa36574cc005d70/large_1920-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3Ni1mOGZkMTE3ZWQwMmM5Yzg1ZTJjZTAwNjE2ZmEzNjU3NGNjMDA1ZDcwL2xhcmdlXzE5MjAtNTIyMjEzZTdlN2Q1ZWI3ZTc2NTJiOTM2YWRmNjcwNTRlMTIxMDk0YS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3Ni1mOGZkMTE3ZWQwMmM5Yzg1ZTJjZTAwNjE2ZmEzNjU3NGNjMDA1ZDcwL2xhcmdlXzE5MjAtNTIyMjEzZTdlN2Q1ZWI3ZTc2NTJiOTM2YWRmNjcwNTRlMTIxMDk0YS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-02T23:00:18Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI9063953685",
      "type": "image",
      "attributes": {
        "name": "DBI-9-GG9BpHr1-DBU8-DBM0011938845_100_02.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10002875-f8fd117ed02c9c85e2ce00616fa36574cc005d70/downloadable-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002875-f8fd117ed02c9c85e2ce00616fa36574cc005d70/large_1920-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3NS1mOGZkMTE3ZWQwMmM5Yzg1ZTJjZTAwNjE2ZmEzNjU3NGNjMDA1ZDcwL2xhcmdlXzE5MjAtYzYxNDY0ZWViMDI4ODEyOWM3NWI1ZjIzZGYyYTEzMzc1OWFhM2E4Ny5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3NS1mOGZkMTE3ZWQwMmM5Yzg1ZTJjZTAwNjE2ZmEzNjU3NGNjMDA1ZDcwL2xhcmdlXzE5MjAtYzYxNDY0ZWViMDI4ODEyOWM3NWI1ZjIzZGYyYTEzMzc1OWFhM2E4Ny5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-02T23:00:18Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6131290743",
      "type": "image",
      "attributes": {
        "name": "DBI-10-pes5qTL6-DBU9-DBM0011938845_100_03.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10002874-ba8e022bcace437eb012c696aa9f708d0fd4c704/downloadable-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002874-ba8e022bcace437eb012c696aa9f708d0fd4c704/large_1920-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3NC1iYThlMDIyYmNhY2U0MzdlYjAxMmM2OTZhYTlmNzA4ZDBmZDRjNzA0L2xhcmdlXzE5MjAtYmI0N2RiM2ZmZTc2M2MxYWQ5OGU3N2Y3MGU2Mzk2ZjM0YWRjMDlhOC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3NC1iYThlMDIyYmNhY2U0MzdlYjAxMmM2OTZhYTlmNzA4ZDBmZDRjNzA0L2xhcmdlXzE5MjAtYmI0N2RiM2ZmZTc2M2MxYWQ5OGU3N2Y3MGU2Mzk2ZjM0YWRjMDlhOC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-02T23:00:18Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI2728011740",
      "type": "image",
      "attributes": {
        "name": "DBI-8-NqvnpVWG-DBU7-DBM0011938845_100_01.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10002872-feb60d516e8eef99872e7b60c9d1abe1484cdcd7/downloadable-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002872-feb60d516e8eef99872e7b60c9d1abe1484cdcd7/large_1920-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3Mi1mZWI2MGQ1MTZlOGVlZjk5ODcyZTdiNjBjOWQxYWJlMTQ4NGNkY2Q3L2xhcmdlXzE5MjAtNzZhYjAzNzBhODRlYTJkNzJkZTVhNDRmYjBmYzAwNzAwYjFkNTUzMC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3Mi1mZWI2MGQ1MTZlOGVlZjk5ODcyZTdiNjBjOWQxYWJlMTQ4NGNkY2Q3L2xhcmdlXzE5MjAtNzZhYjAzNzBhODRlYTJkNzJkZTVhNDRmYjBmYzAwNzAwYjFkNTUzMC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-02T23:00:16Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI0308550610",
      "type": "image",
      "attributes": {
        "name": "DBI-7-zr795osN-DBU15-DBM0011938845_Birds_Eye.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10002870-ca7b313340c30a56e5ea6f079300995b0015f474/downloadable-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002870-ca7b313340c30a56e5ea6f079300995b0015f474/large_1920-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3MC1jYTdiMzEzMzQwYzMwYTU2ZTVlYTZmMDc5MzAwOTk1YjAwMTVmNDc0L2xhcmdlXzE5MjAtMWVkZDk3NmYyMDc4YWI4N2JiMDI2N2Y5YWU0MDRkOWExYjc3NGYwNS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg3MC1jYTdiMzEzMzQwYzMwYTU2ZTVlYTZmMDc5MzAwOTk1YjAwMTVmNDc0L2xhcmdlXzE5MjAtMWVkZDk3NmYyMDc4YWI4N2JiMDI2N2Y5YWU0MDRkOWExYjc3NGYwNS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-02T23:00:13Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1059958",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4513507924",
      "type": "image",
      "attributes": {
        "name": "DBI-6-nYDsWRFb-DBU6-DBM0011938845_10_06.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10002869-ca7b313340c30a56e5ea6f079300995b0015f474/downloadable-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002869-ca7b313340c30a56e5ea6f079300995b0015f474/large_1920-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg2OS1jYTdiMzEzMzQwYzMwYTU2ZTVlYTZmMDc5MzAwOTk1YjAwMTVmNDc0L2xhcmdlXzE5MjAtMmNlZWE5MmVhYTUwMDVkNTAyOWIwNTgxMDZlOTllOGU3YjBkMzY1Yy5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg2OS1jYTdiMzEzMzQwYzMwYTU2ZTVlYTZmMDc5MzAwOTk1YjAwMTVmNDc0L2xhcmdlXzE5MjAtMmNlZWE5MmVhYTUwMDVkNTAyOWIwNTgxMDZlOTllOGU3YjBkMzY1Yy5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-02T23:00:13Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1059958",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6474875722",
      "type": "image",
      "attributes": {
        "name": "DBI-5-SHIakN7h-DBU5-DBM0011938845_10_05.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10002868-111362a32e6b85a66554d44b48f70ae98a55a9c3/downloadable-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002868-111362a32e6b85a66554d44b48f70ae98a55a9c3/large_1920-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg2OC0xMTEzNjJhMzJlNmI4NWE2NjU1NGQ0NGI0OGY3MGFlOThhNTVhOWMzL2xhcmdlXzE5MjAtZTZkN2VmYTk4NWNmZTgyMjE5MzU1MmNlNWY0MTkwZWFhMTljNWNhMi5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg2OC0xMTEzNjJhMzJlNmI4NWE2NjU1NGQ0NGI0OGY3MGFlOThhNTVhOWMzL2xhcmdlXzE5MjAtZTZkN2VmYTk4NWNmZTgyMjE5MzU1MmNlNWY0MTkwZWFhMTljNWNhMi5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-02T23:00:12Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1059958",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI0732352285",
      "type": "image",
      "attributes": {
        "name": "DBI-4-zG2C3bAz-DBU4-DBM0011938845_10_04.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10002867-111362a32e6b85a66554d44b48f70ae98a55a9c3/downloadable-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002867-111362a32e6b85a66554d44b48f70ae98a55a9c3/large_1920-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg2Ny0xMTEzNjJhMzJlNmI4NWE2NjU1NGQ0NGI0OGY3MGFlOThhNTVhOWMzL2xhcmdlXzE5MjAtZGJmNWI5MGJjYmU4MjQ0MWIzOTQxMjAxOGVkYWRiZGU4ZGJlMTYzNy5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg2Ny0xMTEzNjJhMzJlNmI4NWE2NjU1NGQ0NGI0OGY3MGFlOThhNTVhOWMzL2xhcmdlXzE5MjAtZGJmNWI5MGJjYmU4MjQ0MWIzOTQxMjAxOGVkYWRiZGU4ZGJlMTYzNy5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-02T23:00:12Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1059958",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI1215508812",
      "type": "image",
      "attributes": {
        "name": "DBI-3-TSjOtv2d-DBU3-DBM0011938845_10_03.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10002866-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/downloadable-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002866-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/large_1920-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg2Ni1kNDdiMWYyNzAwZDY2YmJkMTI1NmM4Y2RiMWM2NjNjMjFmYzViNTU3L2xhcmdlXzE5MjAtOTExMTZhOGYwNTQwM2M5NzBlYWQ2MTU5NDQ2YTc3NGYzNWEzMjhhYS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg2Ni1kNDdiMWYyNzAwZDY2YmJkMTI1NmM4Y2RiMWM2NjNjMjFmYzViNTU3L2xhcmdlXzE5MjAtOTExMTZhOGYwNTQwM2M5NzBlYWQ2MTU5NDQ2YTc3NGYzNWEzMjhhYS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-02T23:00:11Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1059958",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI1286169930",
      "type": "image",
      "attributes": {
        "name": "DBI-2-suCDiKmD-DBU2-DBM0011938845_10_02.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10002865-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/downloadable-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002865-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/large_1920-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg2NS1kNDdiMWYyNzAwZDY2YmJkMTI1NmM4Y2RiMWM2NjNjMjFmYzViNTU3L2xhcmdlXzE5MjAtMDhlODhhNmQ5NWVjODdkYjVkYWUyODI1YWJjZTY2ZDNjNzA2OGQxNS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg2NS1kNDdiMWYyNzAwZDY2YmJkMTI1NmM4Y2RiMWM2NjNjMjFmYzViNTU3L2xhcmdlXzE5MjAtMDhlODhhNmQ5NWVjODdkYjVkYWUyODI1YWJjZTY2ZDNjNzA2OGQxNS5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-02T23:00:11Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1059958",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI9611859391",
      "type": "image",
      "attributes": {
        "name": "DBI-1-S7zPb7Hj-DBU1-DBM0011938845_10_01.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10002864-3fa5c0dd4de7618f61362d63172a69bf8667befe/downloadable-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002864-3fa5c0dd4de7618f61362d63172a69bf8667befe/large_1920-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg2NC0zZmE1YzBkZDRkZTc2MThmNjEzNjJkNjMxNzJhNjliZjg2NjdiZWZlL2xhcmdlXzE5MjAtZDJiNzgxOTkzNGQ5Mzk2ZTgyNjhhMTMzNzk4OGQwYjdiYzEyMWUxMC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0=",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAwMjg2NC0zZmE1YzBkZDRkZTc2MThmNjEzNjJkNjMxNzJhNjliZjg2NjdiZWZlL2xhcmdlXzE5MjAtZDJiNzgxOTkzNGQ5Mzk2ZTgyNjhhMTMzNzk4OGQwYjdiYzEyMWUxMC5KUEciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY29udGFpbiIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19"
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-02T23:00:10Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1059958",
            "type": "shot"
          }
        }
      }
    }]
  };
  _exports.default = _default;
});