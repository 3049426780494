define("clients/helpers/includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.includes = includes;

  // Format the number to the given number of decimal places
  function includes(_ref) {
    let [obj, el] = _ref;
    return obj.includes(el);
  }

  var _default = Ember.Helper.helper(includes);

  _exports.default = _default;
});