define("clients/serializers/mission-type", ["exports", "clients/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionTypeSerializer;
  MissionTypeSerializer = _application.default.extend({
    attrs: {
      packages: {
        embedded: 'always'
      }
    }
  });
  var _default = MissionTypeSerializer;
  _exports.default = _default;
});