define("clients/adapters/image", ["exports", "clients/adapters/application", "clients/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + '/' + _environment.default.api.namespace + '/clients/',
    queryUrlTemplate: '{+namespace}{+sharesNamespace}missions/{missionId}/images{+shareTokenParam}',
    urlSegments: {
      missionId: function (type, id, snapshot, query) {
        if (Ember.isBlank(query)) {
          return snapshot.belongsTo('mission', {
            id: true
          });
        }

        return query.missionId;
      },
      sharesNamespace: function (type, id, snapshot, query) {
        if (Ember.isBlank(query) || Ember.isBlank(query.shareToken)) {
          return '';
        }

        return "shares/";
      },
      shareTokenParam: function (type, id, snapshot, query) {
        if (Ember.isBlank(query) || Ember.isBlank(query.shareToken)) {
          return '';
        }

        return "?share_token=" + query.shareToken;
      }
    }
  });

  _exports.default = _default;
});