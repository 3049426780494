define("clients/services/stats_service", ["exports", "ember-data", "clients/config/environment", "jquery"], function (_exports, _emberData, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var StatsService;
  StatsService = Ember.Service.extend({
    sessionAccount: Ember.inject.service(),
    orders: function (params) {
      return this.request('orders', params);
    },
    flights: function (params) {
      return this.request('flights', params);
    },
    turnaround: function (params) {
      return this.request('turnaround', params);
    },
    cancels: function (params) {
      return this.request('cancels', params);
    },
    packages: function (params) {
      return this.request('packages', params);
    },
    metaInformation: function (params) {
      return this.request('meta_information', params);
    },
    cancelReasons: function (params) {
      return this.request('cancel_reasons', params);
    },
    request: function (path, params) {
      return _jquery.default.ajax({
        url: _environment.default.api.host + "/v1/clients/stats/" + path,
        type: 'GET',
        dataType: 'json',
        headers: this.get('sessionAccount.headers'),
        data: params
      }).then(function (response) {
        return response;
      });
    }
  });
  var _default = StatsService;
  _exports.default = _default;
});