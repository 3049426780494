define("clients/adapters/credit_card", ["exports", "clients/adapters/application", "clients/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CreditCardAdapter;
  CreditCardAdapter = _application.default.extend({
    namespace: _environment.default.api.namespace + '/clients',
    pathForType: function (modelName) {
      return 'credit_cards';
    }
  });
  var _default = CreditCardAdapter;
  _exports.default = _default;
});