define("clients/components/mission-view/mission-timeline-view", ["exports", "clients/data/sample_timeline"], function (_exports, _sample_timeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionTimelineViewComponent;
  MissionTimelineViewComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    rollbar: Ember.inject.service(),
    timeline: [],
    init: function () {
      this._super();

      return this.loadTimeLine();
    },
    loadTimeLine: function () {
      if (this.get('sampleMission')) {
        this.get('store').pushPayload(_sample_timeline.default);
        return this.setTimeLine(this.get('store').peekAll('activity-log').toArray());
      } else {
        return this.get('store').query('activity-log', {
          missionId: this.get('mission').id
        }).then(function (_this) {
          return function (response) {
            return _this.setTimeLine(response.toArray());
          };
        }(this), function (_this) {
          return function (error) {
            return _this.get('rollbar').error('failed to loadTimeLine', error);
          };
        }(this));
      }
    },
    setTimeLine: function (logs) {
      var items;
      items = logs.map(function (_this) {
        return function (event, index) {
          if (index === 0 && event.get('action') === 'rejected' && _this.get('mission.rejection_reason')) {
            return _this.get('parseEvent')(event, 'Mission required a reshoot', _this.get('mission.rejection_reason'));
          } else if (event.get('action') === 'created' && _this.get('mission.parent')) {
            return _this.get('parseEvent')(event, 'Reshoot Mission was created', _this.get('mission.parent.rejection_reason'), 'reshoot');
          } else if (event.get('reason') === 'Initial Schedule') {
            return _this.get('parseEvent')(event, _this.get('humanizeState')(event.get('action'), event.get('blurb'), event.get('additional_info')));
          } else {
            return _this.get('parseEvent')(event, _this.get('humanizeState')(event.get('action'), event.get('blurb'), event.get('additional_info')), event.get('reason'));
          }
        };
      }(this)).filter(this.get('releventEvent'));

      if (items.length > 1) {
        items[0].className += ' first';
        items[items.length - 1].className += ' last';

        if (items.length > 3) {
          this.set('compactView', true);
          this.set('accordianButton', 'See more');
        }
      } else {
        items[0].className = 'singular';
      }

      this.set('timeline', items);
      return Ember.run.schedule('afterRender', this, function () {
        return $('[data-toggle="tooltip"]').tooltip();
      });
    },
    actions: {
      toggleCompactTimeline: function () {
        if (this.get('compactView')) {
          this.set('compactView', false);
          return this.set('accordianButton', 'See less');
        } else {
          this.set('compactView', true);
          return this.set('accordianButton', 'See more');
        }
      }
    },
    releventEvent: function (event) {
      return event.action;
    },
    parseEvent: function (event, action, reason, className) {
      if (className == null) {
        className = event.get('action');
      }

      return {
        action: action,
        reason: reason,
        className: className,
        blurb: event.get('blurb'),
        date: new Date(event.get('created_at'))
      };
    },
    humanizeState: function (state, blurb, additional_info) {
      switch (state) {
        case 'created':
          return 'Mission was created';

        case 'scheduling_call':
          return 'Zeitview calling to schedule flight';

        case 'confirmed':
          return 'Mission is confirmed';

        case 'pilots_notified':
          return 'Pilot has been notified';

        case 'pilot_accepted':
          return 'Pilot has been confirmed';

        case 'flight_complete':
          return 'Flight was completed';

        case 'assets_uploaded':
          break;

        case 'assets_classified':
          return 'Assets pending review';

        case 'in_production':
          return 'Mission is in production';

        case 'awaiting_payment':
          return 'Assets are ready';

        case 'invoice_needed':
          return 'Assets are ready';

        case 'invoiced':
          return 'Invoice was sent';

        case 'images_uploaded_to_arcgis':
          return 'Published to ArcGIS';

        case 'complete':
          return 'Mission is complete';

        case 'canceled':
          return 'Mission was canceled';

        case 'unfulfilled':
          return 'Mission was canceled';

        case 'rejected':
          return 'Mission was canceled';

        case 'put_on_hold':
          return 'Mission on hold';

        case 'pilot_arrived':
          return 'Pilot has arrived on site';

        case 'pilot_departed':
          return 'Pilot has departed';

        case 'resumed':
          return 'Mission resumed';

        case 'upload_required':
          return 'Upload required';

        case 'rescheduled':
          if (blurb === 'Initial Pilot Schedule') {
            return 'Flight has been scheduled';
          }

          if (blurb === 'Removed Mission Schedule') {
            return 'Rescheduling';
          }

          if (blurb === 'The pilot is no longer available.' && additional_info.to === null) {
            return 'Rescheduling';
          }

          return 'Mission rescheduled to ' + moment(additional_info.to).format('M/DD');
      }
    }
  });
  var _default = MissionTimelineViewComponent;
  _exports.default = _default;
});