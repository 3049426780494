define("clients/data/pano_shares", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    'rre-pano-package-panorama': 'a3a4050db443f5d472c50b8e2630aeaa',
    'cre-pano-package-panorama': '6ebce4b7a8c4ec45f4f893dad21ca98c',
    'cre-shopping-center-panorama': '8804658270d3a6f0fc2931977940785f',
    'cre-regional-center-panorama': '5abe06dd360e014237156cfeb9450fd9'
  };
  _exports.default = _default;
});