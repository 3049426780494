define("clients/templates/components/asset-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZB+0HQP6",
    "block": "{\"symbols\":[],\"statements\":[[8,\"button\",[[4,[38,0],[[32,0],\"openSharePage\"],null]],[[\"@size\"],[\"extra-large\"]],[[\"default\"],[{\"statements\":[[1,[34,1]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"buttonText\"]}",
    "meta": {
      "moduleName": "clients/templates/components/asset-share.hbs"
    }
  });

  _exports.default = _default;
});