define("clients/serializers/share", ["exports", "clients/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ShareSerializer;
  ShareSerializer = _application.default.extend({
    attrs: {
      shareable: {
        deserialize: 'records'
      }
    }
  });
  var _default = ShareSerializer;
  _exports.default = _default;
});