define("clients/templates/components/suggested-group-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9q9x632I",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"mission-list-item suggested-group\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mission-group-icon\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/dashboard/mission-group/group_icon.svg\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mission-group-name\"],[12],[2,\"\\n    \"],[1,[35,0,[\"name\"]]],[2,\" (\"],[1,[35,0,[\"missionsCount\"]]],[2,\")\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"mission-group-links \",[30,[36,2],[[35,1],\"hide\"],null]]]],[12],[2,\"\\n    Suggested Group\\n    \"],[8,\"button\",[[4,[38,3],[[32,0],\"keepSuggestegGroup\"],null]],[[\"@type\",\"@size\"],[\"link\",\"medium\"]],[[\"default\"],[{\"statements\":[[2,\"Keep\"]],\"parameters\":[]}]]],[2,\" or\\n    \"],[8,\"button\",[[4,[38,3],[[32,0],\"removeSuggestedGroup\"],null]],[[\"@type\",\"@size\"],[\"link\",\"medium\"]],[[\"default\"],[{\"statements\":[[2,\"Remove\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"group\",\"isSaving\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "clients/templates/components/suggested-group-item.hbs"
    }
  });

  _exports.default = _default;
});