define("clients/models/work-item", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    map_feature: (0, _model.belongsTo)('map-feature', {
      async: false
    }),
    catalog_item: (0, _model.belongsTo)('catalog-item', {
      async: false
    }),
    calculator_debug_info: (0, _model.attr)()
  });

  _exports.default = _default;
});