define("clients/helpers/asset-share-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.assetShareHeader = void 0;
  var AssetShareHeaderHelper, assetShareHeader;
  _exports.assetShareHeader = assetShareHeader;

  _exports.assetShareHeader = assetShareHeader = function (arg) {
    var shareable, text;
    shareable = arg[0];
    text = shareable.get('constructor.modelName') === 'mission' ? "Share link to all final assets for property <b>" + shareable.get('location.name') + "</b>" : "Share link to " + shareable.get('constructor.modelName') + " for property <b>" + shareable.get('mission.location.name') + "</b>";
    return new Ember.String.htmlSafe(text);
  };

  AssetShareHeaderHelper = Ember.Helper.helper(assetShareHeader);
  var _default = AssetShareHeaderHelper;
  _exports.default = _default;
});