define("clients/mirage/serializers/suggested-group", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Serializer.extend({
    attrs: ['id', 'name', 'mission_ids']
  });

  _exports.default = _default;
});