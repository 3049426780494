define("clients/components/mission-view/asset-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AssetListItemComponent;
  AssetListItemComponent = Ember.Component.extend({
    classNames: ['list-item'],
    classNameBindings: ['getClassNames'],
    getClassNames: Ember.computed('visible', function () {
      if (!this.get('visible')) {
        return 'hidden';
      }
    }),
    actions: {
      download: function (asset) {
        return window.location = asset.get('downloadUrl');
      },
      downloadOrtho: function (asset, type) {
        return window.location = asset.get('downloadable' + type);
      },
      toggleStar: function (asset) {
        return asset.set('starred', !asset.get('starred'));
      },
      toggleOrthoModal: function (ortho) {
        return this.sendAction('orthoModalToggle', ortho);
      }
    }
  });
  var _default = AssetListItemComponent;
  _exports.default = _default;
});