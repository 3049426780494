define("clients/templates/missions/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kgwF1cqv",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[6,[37,7],null,[[\"translucentOverlay\",\"close\",\"containerClassNames\"],[true,\"toggleModal\",\"credit-card-form-modal\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,6],null,[[\"credit_card\",\"mission\",\"client\",\"showModal\",\"reloadMission\"],[[35,5],[35,4],[35,3],[35,2],[30,[36,0],[[32,0],\"reloadMission\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,9],[[35,10,[\"sampleMission\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"top-banner\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n      You are viewing an example Mission. Ready to place your first order?\\n    \"],[13],[2,\"\\n\"],[6,[37,1],null,[[\"route\"],[\"order-flow\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"button\",[[4,[38,0],[[32,0],\"trackGoToOrderFlow\"],null]],[[\"@type\"],[\"secondary\"]],[[\"default\"],[{\"statements\":[[2,\"Order now\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sample-mission-padding\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,11],null,[[\"mission\",\"sampleMission\",\"sampleMissionId\",\"client\",\"goToUploadAction\"],[[35,4],[35,10,[\"sampleMission\"]],[35,10,[\"sampleMissionId\"]],[35,3],[30,[36,0],[[32,0],\"goToUpload\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"link-to\",\"showModal\",\"client\",\"mission\",\"creditCard\",\"credit-card-form\",\"modal-dialog\",\"isShowingModal\",\"if\",\"model\",\"mission-view/mission-view\"]}",
    "meta": {
      "moduleName": "clients/templates/missions/show.hbs"
    }
  });

  _exports.default = _default;
});