define("clients/models/attachment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created_at: (0, _model.attr)('date'),
    name: (0, _model.attr)('string'),
    is_roof_report: (0, _model.attr)('boolean'),
    mission: (0, _model.belongsTo)('mission'),
    version_urls: (0, _model.attr)(),
    source_type: (0, _model.attr)('string'),
    processing_status: (0, _model.attr)('string'),
    downloadUrl: Ember.computed.reads('version_urls.download'),
    previewUrl: Ember.computed('downloadUrl', function () {
      return this.downloadUrl + '?Content-Disposition=inline&Content-Type=inline';
    }),
    final: Ember.computed('source_type', function () {
      return this.source_type == 'edit';
    }),
    isProcessing: Ember.computed('processing_status', function () {
      return this.processing_status == 'processing';
    }),
    size: (0, _model.attr)(),
    formatted_size: Ember.computed('size', function () {
      var size = this.size;
      var i = Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
    }),
    short_name: Ember.computed('name', function () {
      var shortName = this.name.substring(this.name.indexOf('-') + 1); //remove first DBPDF or DBDOC

      shortName = shortName.substring(0, shortName.lastIndexOf('.'));
      return shortName;
    }),
    file_type: Ember.computed('name', function () {
      return this.name.substring(this.name.lastIndexOf('.')).toUpperCase();
    })
  });

  _exports.default = _default;
});