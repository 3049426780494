define("clients/models/share", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    shareable: (0, _model.belongsTo)('shareable', {
      polymorphic: true,
      async: false,
      inverse: 'shares'
    }),
    panorama: (0, _model.belongsTo)('panorama', {
      async: false
    }),
    link: Ember.computed('id', 'shareable.constructor.modelName', function () {
      const shareable = this.get('shareable.constructor.modelName');
      return window.location.origin + ("/" + shareable.charAt(0) + "/" + this.get('id'));
    })
  });

  _exports.default = _default;
});