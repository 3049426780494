define("clients/templates/components/mission-map-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hmjzdSqv",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,1,\"map_container\"],[24,0,\"col-md-12 google-map-height-adjust\"],[4,[38,0],[[32,0],\"toggleShowExpanded\"],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,1,\"map_canvas\"],[15,0,[30,[36,2],[[35,1],\"selected\"],null]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"hideExpandedMap\",\"unless\"]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-map-thumbnail.hbs"
    }
  });

  _exports.default = _default;
});