define("clients/data/sample_images/cre_starter_regional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": [{
      "id": "DBI4779923656",
      "type": "image",
      "attributes": {
        "name": "DBI-15-4mBqeyGs-CRE-Regional-Center---Starter-Package_DBI-9-DBU-26-9JPjFFF3-DJI_0088-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021202-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/downloadable-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021202-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/large_1920-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTIwMi0xOTlhYTBkMTQ2MWExMWEzY2I5NWU3YTVjYTA1M2IxZTNkYTkyMGE4L2xhcmdlXzE5MjAtODdlZTc1ZTFhMWVkMzk2ODYzNmY2YTk0YTU4Nzk1MzllOTIzZjhlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTIwMi0xOTlhYTBkMTQ2MWExMWEzY2I5NWU3YTVjYTA1M2IxZTNkYTkyMGE4L2xhcmdlXzE5MjAtODdlZTc1ZTFhMWVkMzk2ODYzNmY2YTk0YTU4Nzk1MzllOTIzZjhlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:29:03Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI7277532583",
      "type": "image",
      "attributes": {
        "name": "DBI-14-46ffC2iO-CRE-Regional-Center---Starter-Package_DBI-13-DBU-30-qk1GYZQK-DJI_0081-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021201-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/downloadable-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021201-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/large_1920-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTIwMS0xOTlhYTBkMTQ2MWExMWEzY2I5NWU3YTVjYTA1M2IxZTNkYTkyMGE4L2xhcmdlXzE5MjAtMGU2ZGY2MzFkZWVlYWQ4YTA5YjIzMjE5NmViODQzNTJiOWRkYjI2Yi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTIwMS0xOTlhYTBkMTQ2MWExMWEzY2I5NWU3YTVjYTA1M2IxZTNkYTkyMGE4L2xhcmdlXzE5MjAtMGU2ZGY2MzFkZWVlYWQ4YTA5YjIzMjE5NmViODQzNTJiOWRkYjI2Yi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:29:03Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3690715925",
      "type": "image",
      "attributes": {
        "name": "DBI-13-Gi9B5z0W-CRE-Regional-Center---Starter-Package_DBI-5-DBU-19-Qq63FFL2-DJI_0101-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021200-d1a476eda2c34f98fe87c504b9f514b2261621c5/downloadable-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021200-d1a476eda2c34f98fe87c504b9f514b2261621c5/large_1920-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTIwMC1kMWE0NzZlZGEyYzM0Zjk4ZmU4N2M1MDRiOWY1MTRiMjI2MTYyMWM1L2xhcmdlXzE5MjAtZjJhMTI3ZDFhNWEzY2Y5NGE5YTI5ZGZkODE0YWZlYjcxYjg5YzY4YS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTIwMC1kMWE0NzZlZGEyYzM0Zjk4ZmU4N2M1MDRiOWY1MTRiMjI2MTYyMWM1L2xhcmdlXzE5MjAtZjJhMTI3ZDFhNWEzY2Y5NGE5YTI5ZGZkODE0YWZlYjcxYjg5YzY4YS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:29:02Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8709327156",
      "type": "image",
      "attributes": {
        "name": "DBI-12-gyFBFtPD-CRE-Regional-Center---Starter-Package_DBI-8-DBU-25-SCuaiqDO-DJI_0090-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021199-200b69aa9399c99a8dcc6d4ef7a334e7a6f57a91/downloadable-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021199-200b69aa9399c99a8dcc6d4ef7a334e7a6f57a91/large_1920-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5OS0yMDBiNjlhYTkzOTljOTlhOGRjYzZkNGVmN2EzMzRlN2E2ZjU3YTkxL2xhcmdlXzE5MjAtMGJjMWU3ZmZkZjY1NDc2ZjY0MWI5ZjYxYWZiY2VjYjg2MDM0YmM0OS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5OS0yMDBiNjlhYTkzOTljOTlhOGRjYzZkNGVmN2EzMzRlN2E2ZjU3YTkxL2xhcmdlXzE5MjAtMGJjMWU3ZmZkZjY1NDc2ZjY0MWI5ZjYxYWZiY2VjYjg2MDM0YmM0OS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:28:54Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8587170468",
      "type": "image",
      "attributes": {
        "name": "DBI-11-NGZ4qWmh-CRE-Regional-Center---Starter-Package_DBI-3-DBU-9-zay9WAga-DJI_0120-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021198-eecf95cc5cd0d7c1417a739870e73904e5b85f90/downloadable-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021198-eecf95cc5cd0d7c1417a739870e73904e5b85f90/large_1920-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5OC1lZWNmOTVjYzVjZDBkN2MxNDE3YTczOTg3MGU3MzkwNGU1Yjg1ZjkwL2xhcmdlXzE5MjAtMGUyNGUyODliZjAxNWM2YTlkYjMxMmJlYmFiZWNjN2I2NmYzYzNmNS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5OC1lZWNmOTVjYzVjZDBkN2MxNDE3YTczOTg3MGU3MzkwNGU1Yjg1ZjkwL2xhcmdlXzE5MjAtMGUyNGUyODliZjAxNWM2YTlkYjMxMmJlYmFiZWNjN2I2NmYzYzNmNS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:28:53Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI2407573859",
      "type": "image",
      "attributes": {
        "name": "DBI-10-4tEIOaWs-CRE-Regional-Center---Starter-Package_DBI-12-DBU-29-3Aqq1tOp-DJI_0083-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021197-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/downloadable-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021197-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/large_1920-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5Ny0zYTZjYmYwZWM1YTlhNGZmMjhkZjQ1ZDExNDkwZmE0YTJlMzAzYjRlL2xhcmdlXzE5MjAtOTM4MWRkZDU2N2NjNGM5M2MzMWExMTZkYThlOTQ5NDc0NjIxMDM0NC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5Ny0zYTZjYmYwZWM1YTlhNGZmMjhkZjQ1ZDExNDkwZmE0YTJlMzAzYjRlL2xhcmdlXzE5MjAtOTM4MWRkZDU2N2NjNGM5M2MzMWExMTZkYThlOTQ5NDc0NjIxMDM0NC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:28:52Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI1564669762",
      "type": "image",
      "attributes": {
        "name": "DBI-9-K7mzZHDc-CRE-Regional-Center---Starter-Package_DBI-14-DBU-31-nDr9vnn7-DJI_0079-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021196-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/downloadable-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021196-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/large_1920-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5Ni0zYTZjYmYwZWM1YTlhNGZmMjhkZjQ1ZDExNDkwZmE0YTJlMzAzYjRlL2xhcmdlXzE5MjAtZTMzZjQ0ODllNjM3OTA4M2MzNzU3ZGE5ZWE5NmNlMWM3Y2E3NjcyOC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5Ni0zYTZjYmYwZWM1YTlhNGZmMjhkZjQ1ZDExNDkwZmE0YTJlMzAzYjRlL2xhcmdlXzE5MjAtZTMzZjQ0ODllNjM3OTA4M2MzNzU3ZGE5ZWE5NmNlMWM3Y2E3NjcyOC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:28:52Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8255512277",
      "type": "image",
      "attributes": {
        "name": "DBI-8-whckEn5c-CRE-Regional-Center---Starter-Package_DBI-6-DBU-22-vkFgtxlh-DJI_0096-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021195-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/downloadable-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021195-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/large_1920-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5NS0zYTZjYmYwZWM1YTlhNGZmMjhkZjQ1ZDExNDkwZmE0YTJlMzAzYjRlL2xhcmdlXzE5MjAtYjM2YTU5MjY5MTUxYjc4Mjc4NTcxZmM1NjU3MDgxODI3MWMxYjBhNy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5NS0zYTZjYmYwZWM1YTlhNGZmMjhkZjQ1ZDExNDkwZmE0YTJlMzAzYjRlL2xhcmdlXzE5MjAtYjM2YTU5MjY5MTUxYjc4Mjc4NTcxZmM1NjU3MDgxODI3MWMxYjBhNy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:28:52Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI5187111239",
      "type": "image",
      "attributes": {
        "name": "DBI-7-RePA1MxX-CRE-Regional-Center---Starter-Package_DBI-4-DBU-12-A8Pl3OqY-DJI_0115-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021194-94bf7a968bbfa8b65f9d089e9d7201f679425a27/downloadable-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021194-94bf7a968bbfa8b65f9d089e9d7201f679425a27/large_1920-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5NC05NGJmN2E5NjhiYmZhOGI2NWY5ZDA4OWU5ZDcyMDFmNjc5NDI1YTI3L2xhcmdlXzE5MjAtNTM5N2IzN2I1MTAyZmYzZTVlMDc0NGI4YzYyNDA4ZjAyMDZiZWRjOS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5NC05NGJmN2E5NjhiYmZhOGI2NWY5ZDA4OWU5ZDcyMDFmNjc5NDI1YTI3L2xhcmdlXzE5MjAtNTM5N2IzN2I1MTAyZmYzZTVlMDc0NGI4YzYyNDA4ZjAyMDZiZWRjOS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:28:51Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4610606498",
      "type": "image",
      "attributes": {
        "name": "DBI-6-DDs0V79b-CRE-Regional-Center---Starter-Package_DBI-15-DBU-32-AaIO2KQt-DJI_0077-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021193-979e3e493152e0e6215b4c6f52a6529da606ef27/downloadable-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021193-979e3e493152e0e6215b4c6f52a6529da606ef27/large_1920-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5My05NzllM2U0OTMxNTJlMGU2MjE1YjRjNmY1MmE2NTI5ZGE2MDZlZjI3L2xhcmdlXzE5MjAtOWQ5ZDBmYjk0NzU0ZDJhNGE1MWQxYmZjNWVjNTEwZGZiN2M0N2Y1OC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5My05NzllM2U0OTMxNTJlMGU2MjE1YjRjNmY1MmE2NTI5ZGE2MDZlZjI3L2xhcmdlXzE5MjAtOWQ5ZDBmYjk0NzU0ZDJhNGE1MWQxYmZjNWVjNTEwZGZiN2M0N2Y1OC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:28:50Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI0225061738",
      "type": "image",
      "attributes": {
        "name": "DBI-5-DL4fyEvd-CRE-Regional-Center---Starter-Package_DBI-10-DBU-27-tedIIENP-DJI_0086-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021192-979e3e493152e0e6215b4c6f52a6529da606ef27/downloadable-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021192-979e3e493152e0e6215b4c6f52a6529da606ef27/large_1920-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5Mi05NzllM2U0OTMxNTJlMGU2MjE1YjRjNmY1MmE2NTI5ZGE2MDZlZjI3L2xhcmdlXzE5MjAtNzBkYWU5YjI0ODM1YzFkOGFhNGQ3NTg4ZGI1NmMyNzUzMDBjMTM3Yi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5Mi05NzllM2U0OTMxNTJlMGU2MjE1YjRjNmY1MmE2NTI5ZGE2MDZlZjI3L2xhcmdlXzE5MjAtNzBkYWU5YjI0ODM1YzFkOGFhNGQ3NTg4ZGI1NmMyNzUzMDBjMTM3Yi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:28:50Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI7081470574",
      "type": "image",
      "attributes": {
        "name": "DBI-4-MwppozNT-CRE-Regional-Center---Starter-Package_DBI-11-DBU-28-iFOZ3lMK-DJI_0084-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021191-d419173961b2d79d1ea71c1d66952e79bd772428/downloadable-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021191-d419173961b2d79d1ea71c1d66952e79bd772428/large_1920-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5MS1kNDE5MTczOTYxYjJkNzlkMWVhNzFjMWQ2Njk1MmU3OWJkNzcyNDI4L2xhcmdlXzE5MjAtMmEwMzVmOTRlOWExOTRjZGFhYTBjZTc4NGUxMmEzMDM3YTMzYjE2NS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5MS1kNDE5MTczOTYxYjJkNzlkMWVhNzFjMWQ2Njk1MmU3OWJkNzcyNDI4L2xhcmdlXzE5MjAtMmEwMzVmOTRlOWExOTRjZGFhYTBjZTc4NGUxMmEzMDM3YTMzYjE2NS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:28:49Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8841706661",
      "type": "image",
      "attributes": {
        "name": "DBI-3-SFFpgcY3-CRE-Regional-Center---Starter-Package_DBI-2-DBU-7-zxNP2A12-DJI_0124-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021190-d419173961b2d79d1ea71c1d66952e79bd772428/downloadable-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021190-d419173961b2d79d1ea71c1d66952e79bd772428/large_1920-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5MC1kNDE5MTczOTYxYjJkNzlkMWVhNzFjMWQ2Njk1MmU3OWJkNzcyNDI4L2xhcmdlXzE5MjAtYzUyNzY2ZmQ3YTYyNGQxYzg2NGE2ZDBmZDk5YzBmNDE0MzVjMDI5ZS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE5MC1kNDE5MTczOTYxYjJkNzlkMWVhNzFjMWQ2Njk1MmU3OWJkNzcyNDI4L2xhcmdlXzE5MjAtYzUyNzY2ZmQ3YTYyNGQxYzg2NGE2ZDBmZDk5YzBmNDE0MzVjMDI5ZS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:28:49Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI2642568022",
      "type": "image",
      "attributes": {
        "name": "DBI-2-gMOHKOH0-CRE-Regional-Center---Starter-Package_DBI-1-DBU-2-pXdHUx0i-DJI_0134-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021189-d052a69251b62962026e453659c2cf8c73275118/downloadable-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021189-d052a69251b62962026e453659c2cf8c73275118/large_1920-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE4OS1kMDUyYTY5MjUxYjYyOTYyMDI2ZTQ1MzY1OWMyY2Y4YzczMjc1MTE4L2xhcmdlXzE5MjAtM2JjZmM1NzdlZTRkNTE1ZTEwYmEzNjgzMzc4MjE4YTk0MTQ5YjcwMi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE4OS1kMDUyYTY5MjUxYjYyOTYyMDI2ZTQ1MzY1OWMyY2Y4YzczMjc1MTE4L2xhcmdlXzE5MjAtM2JjZmM1NzdlZTRkNTE1ZTEwYmEzNjgzMzc4MjE4YTk0MTQ5YjcwMi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:28:48Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI5403745235",
      "type": "image",
      "attributes": {
        "name": "DBI-1-0x9qNIJm-CRE-Regional-Center---Starter-Package_DBI-7-DBU-23-U1pJS1iQ-DJI_0094-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021188-d052a69251b62962026e453659c2cf8c73275118/downloadable-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021188-d052a69251b62962026e453659c2cf8c73275118/large_1920-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE4OC1kMDUyYTY5MjUxYjYyOTYyMDI2ZTQ1MzY1OWMyY2Y4YzczMjc1MTE4L2xhcmdlXzE5MjAtN2JjNDE2ZjQ3ZGM3ZTNhNjA5ZmFiNDViYzBkZWIxMjZmYTJlZjFlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTE4OC1kMDUyYTY5MjUxYjYyOTYyMDI2ZTQ1MzY1OWMyY2Y4YzczMjc1MTE4L2xhcmdlXzE5MjAtN2JjNDE2ZjQ3ZGM3ZTNhNjA5ZmFiNDViYzBkZWIxMjZmYTJlZjFlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:28:48Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shot"
          }
        }
      }
    }]
  };
  _exports.default = _default;
});