define("clients/models/drone", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    optional_cameras: (0, _model.hasMany)('drone-camera', {
      async: false
    }),
    stock_cameras: (0, _model.hasMany)('drone-camera', {
      async: false
    }),
    drone_manufacturer: (0, _model.belongsTo)('drone-manufacturer'),
    full_name: Ember.computed('name', 'drone_manufacturer.name', function () {
      return this.get('drone_manufacturer.name') + ' ' + this.get('name');
    })
  });

  _exports.default = _default;
});