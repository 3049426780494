define("clients/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KGsGRNRb",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"headTags\"],[[35,0,[\"headTags\"]]]]]],[2,\"\\n\\n\"],[10,\"title\"],[12],[1,[35,0,[\"title\"]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"head-tags\"]}",
    "meta": {
      "moduleName": "clients/templates/head.hbs"
    }
  });

  _exports.default = _default;
});