define("clients/templates/components/mission-group/create-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S6S6F0IL",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],null,[[\"title\",\"isSaving\"],[\"Create New Group\",[35,6]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"Please enter the new Group name to add the (\"],[1,[35,1,[\"length\"]]],[2,\") Missions to:\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"group-input\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"type\",\"value\",\"id\",\"class\",\"placeholder\"],[\"text\",[35,3],\"group-input\",\"form-control input-lg\",\"Enter Name for Group\"]]]],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"button\",[[4,[38,0],[[32,0],\"openMoveModal\"],null]],[[\"@type\",\"@size\"],[\"link\",\"medium\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-arrow-right\"],[12],[13],[2,\"Move to an existing Group\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"group-buttons\"],[12],[2,\"\\n      \"],[8,\"button\",[[4,[38,0],[[32,0],\"close\"],null]],[[\"@type\"],[\"secondary\"]],[[\"default\"],[{\"statements\":[[2,\"Cancel\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"button\",[[16,\"disabled\",[30,[36,5],[[35,3]],null]],[4,[38,0],[[32,0],\"save\"],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Add\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"selectedMissions\",\"if\",\"groupName\",\"input\",\"is-empty\",\"isSaving\",\"mission-group/base-modal\"]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-group/create-modal.hbs"
    }
  });

  _exports.default = _default;
});