define("clients/templates/components/mission-view/add-ref-id-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oPGGltzU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ref-id-edit-header\"],[12],[2,\"\\n  \"],[10,\"h3\"],[12],[1,[34,3]],[2,\" Reference ID\"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\" A reference or claim number for your internal use \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"loading-data \",[30,[36,5],[[35,4],\"hidden\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"box\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sk-circle\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"spinner\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect1\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect2\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect3\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect4\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect5\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ref-id-edit-section\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mission-order-reference-id\"],[12],[2,\"\\n      \"],[1,[30,[36,1],null,[[\"type\",\"value\",\"class\",\"placeholder\"],[\"text\",[35,0],\"form-control\",\"(e.g. JI2465715)\"]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ref-id-actions\"],[12],[2,\"\\n      \"],[8,\"button\",[[4,[38,2],[[32,0],\"close\"],null]],[[\"@type\"],[\"secondary\"]],[[\"default\"],[{\"statements\":[[2,\"Cancel\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"button\",[[4,[38,2],[[32,0],\"save\"],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Ok\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"referenceId\",\"input\",\"action\",\"label\",\"isSaving\",\"unless\"]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-view/add-ref-id-modal.hbs"
    }
  });

  _exports.default = _default;
});