define("clients/components/mission-group/delete-modal", ["exports", "clients/components/mission-group/base-modal"], function (_exports, _baseModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const DeleteMissionGroupModalComponent = _baseModal.default.extend({
    GMTEventAttrs: {
      label: 'Delete a mission group'
    },
    saveRecord: function () {
      this.set('GMTobjectId', this.get('group.id'));
      return this.group.destroyRecord();
    }
  });

  var _default = DeleteMissionGroupModalComponent;
  _exports.default = _default;
});