define("clients/models/client", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    first_name: (0, _model.attr)('string'),
    last_name: (0, _model.attr)('string'),
    company_name: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    token: (0, _model.attr)('string'),
    invoiceable: (0, _model.attr)('boolean'),
    organization_slug: (0, _model.attr)('string'),
    signup_source: (0, _model.attr)('string'),
    is_organization_owner: (0, _model.attr)('boolean'),
    is_insights_customer: (0, _model.attr)('boolean'),
    vertical: (0, _model.belongsTo)('vertical', {
      serialize: true
    }),
    upsellable: (0, _model.attr)('boolean'),
    client_upload_available: (0, _model.attr)('boolean'),
    has_composer_reports: (0, _model.attr)('boolean'),
    upsell_addon_ids: (0, _model.attr)('array'),
    feature_flags: (0, _model.attr)('hash'),
    verticals: (0, _model.hasMany)('vertical', {
      async: false
    }),
    available_addons: (0, _model.hasMany)('package-addon', {
      async: false
    }),
    hidden_sample_missions: (0, _model.hasMany)('hidden_sample_mission', {
      async: false
    }),
    collaborators: (0, _model.hasMany)('collaborators', {
      async: false
    }),
    credit_cards: (0, _model.hasMany)('credit_cards', {
      async: false
    }),
    organization: (0, _model.belongsTo)('organization', {
      async: false
    }),
    esri_account: (0, _model.belongsTo)('esri_account', {
      async: false
    }),
    fullName: Ember.computed('first_name', 'last_name', function () {
      return `${this.first_name} ${this.last_name}`;
    }),
    is_insights_buildings: Ember.computed('organization.insight_access', function () {
      return this.get('organization.insight_access') && this.get('organization.insight_access') === 'Buildings';
    }),
    is_insights: Ember.computed('organization.insight_access', function () {
      return this.get('organization.insight_access') && this.get('organization.insight_access') != 'None';
    })
  });

  _exports.default = _default;
});