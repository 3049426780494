define("clients/helpers/expand-line-breaks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var expandLineBreaksHelper;
  expandLineBreaksHelper = Ember.Helper.helper(function (arg) {
    var text;
    text = arg[0];
    return text.replace(/\n/g, '<br />');
  });
  var _default = expandLineBreaksHelper;
  _exports.default = _default;
});