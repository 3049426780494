define("clients/models/location", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    latitude: (0, _model.attr)('string'),
    longitude: (0, _model.attr)('string'),
    address: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    postal_code: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    formatted_address: (0, _model.attr)('string'),
    temp: (0, _model.attr)('boolean'),
    timezone_id: (0, _model.attr)('string'),
    number: (0, _model.attr)('string'),
    missions: (0, _model.hasMany)('missions', {
      async: false
    }),
    watchers: (0, _model.hasMany)('watcher')
  });

  _exports.default = _default;
});