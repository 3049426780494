define("clients/models/shot-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    video: (0, _model.attr)('string'),
    template: (0, _model.attr)('string'),
    shot_list: (0, _model.belongsTo)('shot-list'),
    shots: (0, _model.hasMany)('shot', {
      async: false
    }),
    altitude: (0, _model.attr)('number')
  });

  _exports.default = _default;
});