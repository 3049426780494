define("clients/components/mission-view/mission-assets-base-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionAssetsBaseViewComponent;
  MissionAssetsBaseViewComponent = Ember.Component.extend({
    showAssetsBucket: Ember.computed('mission.promotedAttachments', 'mission.arcgisLink', function () {
      return Ember.isPresent(this.get('mission.promotedAttachments')) || Ember.isPresent(this.get('mission.arcgisLink'));
    }),
    availableAssetsSubHeader: Ember.computed('mission.promotedAttachments', 'mission.arcgisLink', function () {
      var assetsCount, result;
      result = '';
      assetsCount = this.get('mission.promotedAttachments.length');

      switch (false) {
        case assetsCount !== 1:
          result += assetsCount + "file";
          break;

        case !(assetsCount > 1):
          result += assetsCount + "files";
      }

      if (this.get('mission.arcgisLink')) {
        result += " 1 link";
      }

      return result;
    })
  });
  var _default = MissionAssetsBaseViewComponent;
  _exports.default = _default;
});