define("clients/mixins/google-map-package-mixin", ["exports", "lodash/cloneDeep"], function (_exports, _cloneDeep2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    filterFeaturesByPackage(mission) {
      if (!mission.geo_properties) {
        return true;
      }

      const newPackage = mission.package;
      let newGeoProperties = (0, _cloneDeep2.default)(mission.geo_properties);
      let filteredFeatures = newGeoProperties.features;

      if (!newPackage.panoramaShotType) {
        filteredFeatures = filteredFeatures.filter(feature => feature.properties.feature_type !== 'panorama');
      }

      if (!newPackage.hasRoofReport && !newPackage.hasCatalogItems) {
        const firstPolygon = filteredFeatures.find(feature => feature.properties.feature_type === 'polygon');
        filteredFeatures = filteredFeatures.filter(feature => feature.properties.feature_type !== 'polygon');

        if (firstPolygon) {
          filteredFeatures.unshift(firstPolygon);
        }
      }

      if (!newPackage.hasCatalogItems) {
        filteredFeatures.forEach(feature => {
          if (feature.properties.feature_type === 'panorama') {
            feature.properties.altitude = null;
          }
        });
      } // Catalog items are uniq for package


      filteredFeatures.forEach(feature => {
        if (feature.properties.feature_type === 'polygon') {
          feature.properties.catalog_item_ids = [];
        }
      });
      newGeoProperties.features = filteredFeatures;
      return mission.set('geo_properties', newGeoProperties);
    }

  });

  _exports.default = _default;
});