define("clients/models/panorama", ["exports", "clients/models/shareable", "@ember-data/model"], function (_exports, _shareable, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _shareable.default.extend({
    name: (0, _model.attr)('string'),
    access_key: (0, _model.attr)('string'),
    mission: (0, _model.belongsTo)('mission'),
    source_type: (0, _model.attr)('string'),
    size: (0, _model.attr)('string'),
    formatted_size: (0, _model.attr)('string'),
    pano_base_url: (0, _model.attr)('string'),
    remote_url: (0, _model.attr)('string'),
    share_token: (0, _model.attr)('string'),
    shot: (0, _model.belongsTo)('shot', {
      async: false
    }),
    isPromoted: Ember.computed.equal('source_type', 'edit'),
    previewImageUrl: Ember.computed.reads('shot.images.firstObject.version_urls.small_640')
  });

  _exports.default = _default;
});