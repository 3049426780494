define("clients/data/sample_missions/cre_starter_regional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": {
      "id": "DBM4251104484",
      "type": "missions",
      "attributes": {
        "instructions": "* Scope: Aerial Images and Videos\n* Time: Between 11am - 2pm\n* Schedule: Within 48-72 hours; Weather permitting",
        "status": "complete",
        "client_id": "DBC3006554",
        "payment": {
          "id": null,
          "processor": null,
          "card": null
        },
        "external_assets_url": null,
        "owned_by_current_client": true,
        "contact_email": "clients@zeitview.com",
        "created_on": "2019-12-04T19:18:39Z",
        "is_pano_mission": false,
        "logo_url": null,
        "blurb": null,
        "paid": true,
        "paid_on": "2019-12-04T20:03:43Z",
        "price": 51900,
        "archive_url": null,
        "edit_url": null,
        "customer_instructions": "Weekend only. Fly between 12 - 2 pm.",
        "scheduled_at_start": "2019-12-05T16:00:00Z",
        "scheduled_at_end": "2019-12-05T17:00:00Z",
        "parent_id": null,
        "reshoot_mission_id": null,
        "rejection_reason": null,
        "rejection_notes": null,
        "reference_id": "CRE Starter Package - Regional Centers Sample ",
        "cloud_reqs": "less_than_50",
        "scheduling_requirement": "not_needed",
        "arcgis_feature_layer": null,
        "geo_properties": {}
      },
      "relationships": {
        "location": {
          "data": {
            "id": "284966",
            "type": "locations"
          }
        },
        "package": {
          "data": {
            "id": "19164",
            "type": "packages"
          }
        },
        "credit_card": {
          "data": null
        },
        "onsite_contact": {
          "data": {
            "id": "49591",
            "type": "onsite_contacts"
          }
        },
        "scheduling_contact": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "images": {
          "data": [{
            "id": "DBI4779923656",
            "type": "images"
          }, {
            "id": "DBI7277532583",
            "type": "images"
          }, {
            "id": "DBI3690715925",
            "type": "images"
          }, {
            "id": "DBI8709327156",
            "type": "images"
          }, {
            "id": "DBI8587170468",
            "type": "images"
          }, {
            "id": "DBI2407573859",
            "type": "images"
          }, {
            "id": "DBI1564669762",
            "type": "images"
          }, {
            "id": "DBI8255512277",
            "type": "images"
          }, {
            "id": "DBI5187111239",
            "type": "images"
          }, {
            "id": "DBI4610606498",
            "type": "images"
          }, {
            "id": "DBI0225061738",
            "type": "images"
          }, {
            "id": "DBI7081470574",
            "type": "images"
          }, {
            "id": "DBI8841706661",
            "type": "images"
          }, {
            "id": "DBI2642568022",
            "type": "images"
          }, {
            "id": "DBI5403745235",
            "type": "images"
          }]
        },
        "videos": {
          "data": [{
            "id": "DBV9441475697",
            "type": "videos"
          }, {
            "id": "DBV6964641717",
            "type": "videos"
          }, {
            "id": "DBV4178063257",
            "type": "videos"
          }, {
            "id": "DBV4957141293",
            "type": "videos"
          }, {
            "id": "DBV2027538613",
            "type": "videos"
          }, {
            "id": "DBV1627704118",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        },
        "attachments": {
          "data": []
        },
        "ortho_photo_maps": {
          "data": []
        },
        "shares": {
          "data": [{
            "id": "03cce4062eda062312eeb9205fd56c55",
            "type": "shares"
          }]
        },
        "main_image": {
          "data": {
            "id": "DBI4779923656",
            "type": "images"
          }
        },
        "collaborators": {
          "data": []
        },
        "parent": {
          "data": null
        },
        "shots": {
          "data": [{
            "id": "1061040",
            "type": "shots"
          }, {
            "id": "1061039",
            "type": "shots"
          }, {
            "id": "1061038",
            "type": "shots"
          }, {
            "id": "1061037",
            "type": "shots"
          }, {
            "id": "1061036",
            "type": "shots"
          }, {
            "id": "1061035",
            "type": "shots"
          }, {
            "id": "1061034",
            "type": "shots"
          }, {
            "id": "1061033",
            "type": "shots"
          }]
        },
        "invoice_line_items": {
          "data": [{
            "id": "DBINV332487670352125",
            "type": "invoice_line_items"
          }]
        }
      }
    },
    "included": [{
      "id": "284966",
      "type": "locations",
      "attributes": {
        "name": "9129 W Atlantic Blvd, Coral Springs, FL 33071, USA",
        "latitude": 26.2410392,
        "longitude": -80.2492081,
        "address": "9129 West Atlantic Boulevard",
        "address2": "",
        "city": "Coral Springs",
        "state": "Florida",
        "postal_code": "33071",
        "country": "United States",
        "formatted_address": "9129 W Atlantic Blvd, Coral Springs, FL 33071, USA",
        "timezone_id": "America/New_York",
        "properties": {}
      }
    }, {
      "id": "19164",
      "type": "packages",
      "attributes": {
        "name": "Starter Package - Regional Centers (400,000+ sq ft)",
        "description": "High-quality aerial images and video captured at an increased altitude designed to showcase your Regional Center and the surrounding area.   ",
        "price": 51900,
        "cloud_reqs": "less_than_50",
        "position": 11,
        "slug": "cre-starter-pack-lg-shopping-center"
      },
      "relationships": {
        "vertical": {
          "data": {
            "id": "1",
            "type": "verticals"
          }
        },
        "organization": {
          "data": null
        },
        "client": {
          "data": null
        },
        "catalog_items": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "verticals",
      "attributes": {
        "name": "Commercial Real Estate",
        "short_name": "CRE"
      }
    }, {
      "id": "49591",
      "type": "onsite_contacts",
      "attributes": {
        "name": null,
        "call_action": null,
        "phone": null,
        "note": null
      }
    }, {
      "id": "DBC3006554",
      "type": "clients",
      "attributes": {
        "first_name": "Demo",
        "last_name": "Zeitview",
        "phone": "",
        "email": "clients@zeitview.com",
        "token": "qC65CqCmbKfMZvIU2gRb_uD7Sqw",
        "company_name": null,
        "invoiceable": false
      },
      "relationships": {
        "hidden_sample_missions": {
          "data": [{
            "id": "*",
            "type": "hidden_sample_mission"
          }]
        }
      }
    }, {
      "id": "DBI4779923656",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021202-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/original-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
        "name": "DBI-15-4mBqeyGs-CRE-Regional-Center---Starter-Package_DBI-9-DBU-26-9JPjFFF3-DJI_0088-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.09 MB",
        "size": 4288810,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021202-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/large_1920-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021202-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/medium_1024-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021202-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/small_640-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021202-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/four_three_640-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021202-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/square_640-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021202-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/downloadable-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg"
        },
        "created_on": "2019-12-04T19:29:03Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7277532583",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021201-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/original-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
        "name": "DBI-14-46ffC2iO-CRE-Regional-Center---Starter-Package_DBI-13-DBU-30-qk1GYZQK-DJI_0081-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.37 MB",
        "size": 4580837,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021201-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/large_1920-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021201-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/medium_1024-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021201-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/small_640-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021201-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/four_three_640-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021201-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/square_640-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021201-199aa0d1461a11a3cb95e7a5ca053b1e3da920a8/downloadable-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg"
        },
        "created_on": "2019-12-04T19:29:03Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI3690715925",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021200-d1a476eda2c34f98fe87c504b9f514b2261621c5/original-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
        "name": "DBI-13-Gi9B5z0W-CRE-Regional-Center---Starter-Package_DBI-5-DBU-19-Qq63FFL2-DJI_0101-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "5.23 MB",
        "size": 5488296,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021200-d1a476eda2c34f98fe87c504b9f514b2261621c5/large_1920-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021200-d1a476eda2c34f98fe87c504b9f514b2261621c5/medium_1024-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021200-d1a476eda2c34f98fe87c504b9f514b2261621c5/small_640-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021200-d1a476eda2c34f98fe87c504b9f514b2261621c5/four_three_640-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021200-d1a476eda2c34f98fe87c504b9f514b2261621c5/square_640-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021200-d1a476eda2c34f98fe87c504b9f514b2261621c5/downloadable-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg"
        },
        "created_on": "2019-12-04T19:29:02Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8709327156",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021199-200b69aa9399c99a8dcc6d4ef7a334e7a6f57a91/original-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
        "name": "DBI-12-gyFBFtPD-CRE-Regional-Center---Starter-Package_DBI-8-DBU-25-SCuaiqDO-DJI_0090-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.1 MB",
        "size": 4294087,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021199-200b69aa9399c99a8dcc6d4ef7a334e7a6f57a91/large_1920-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021199-200b69aa9399c99a8dcc6d4ef7a334e7a6f57a91/medium_1024-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021199-200b69aa9399c99a8dcc6d4ef7a334e7a6f57a91/small_640-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021199-200b69aa9399c99a8dcc6d4ef7a334e7a6f57a91/four_three_640-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021199-200b69aa9399c99a8dcc6d4ef7a334e7a6f57a91/square_640-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021199-200b69aa9399c99a8dcc6d4ef7a334e7a6f57a91/downloadable-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg"
        },
        "created_on": "2019-12-04T19:28:54Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8587170468",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021198-eecf95cc5cd0d7c1417a739870e73904e5b85f90/original-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
        "name": "DBI-11-NGZ4qWmh-CRE-Regional-Center---Starter-Package_DBI-3-DBU-9-zay9WAga-DJI_0120-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.31 MB",
        "size": 4519337,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021198-eecf95cc5cd0d7c1417a739870e73904e5b85f90/large_1920-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021198-eecf95cc5cd0d7c1417a739870e73904e5b85f90/medium_1024-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021198-eecf95cc5cd0d7c1417a739870e73904e5b85f90/small_640-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021198-eecf95cc5cd0d7c1417a739870e73904e5b85f90/four_three_640-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021198-eecf95cc5cd0d7c1417a739870e73904e5b85f90/square_640-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021198-eecf95cc5cd0d7c1417a739870e73904e5b85f90/downloadable-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg"
        },
        "created_on": "2019-12-04T19:28:53Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2407573859",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021197-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/original-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
        "name": "DBI-10-4tEIOaWs-CRE-Regional-Center---Starter-Package_DBI-12-DBU-29-3Aqq1tOp-DJI_0083-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.45 MB",
        "size": 4667854,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021197-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/large_1920-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021197-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/medium_1024-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021197-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/small_640-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021197-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/four_three_640-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021197-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/square_640-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021197-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/downloadable-9381ddd567cc4c93c31a116da8e9494746210344.jpeg"
        },
        "created_on": "2019-12-04T19:28:52Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI1564669762",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021196-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/original-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
        "name": "DBI-9-K7mzZHDc-CRE-Regional-Center---Starter-Package_DBI-14-DBU-31-nDr9vnn7-DJI_0079-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.37 MB",
        "size": 4587481,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021196-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/large_1920-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021196-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/medium_1024-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021196-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/small_640-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021196-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/four_three_640-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021196-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/square_640-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021196-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/downloadable-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg"
        },
        "created_on": "2019-12-04T19:28:52Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8255512277",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021195-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/original-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
        "name": "DBI-8-whckEn5c-CRE-Regional-Center---Starter-Package_DBI-6-DBU-22-vkFgtxlh-DJI_0096-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.16 MB",
        "size": 4364315,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021195-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/large_1920-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021195-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/medium_1024-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021195-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/small_640-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021195-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/four_three_640-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021195-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/square_640-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021195-3a6cbf0ec5a9a4ff28df45d11490fa4a2e303b4e/downloadable-b36a59269151b78278571fc56570818271c1b0a7.jpeg"
        },
        "created_on": "2019-12-04T19:28:52Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI5187111239",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021194-94bf7a968bbfa8b65f9d089e9d7201f679425a27/original-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
        "name": "DBI-7-RePA1MxX-CRE-Regional-Center---Starter-Package_DBI-4-DBU-12-A8Pl3OqY-DJI_0115-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.29 MB",
        "size": 4501638,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021194-94bf7a968bbfa8b65f9d089e9d7201f679425a27/large_1920-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021194-94bf7a968bbfa8b65f9d089e9d7201f679425a27/medium_1024-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021194-94bf7a968bbfa8b65f9d089e9d7201f679425a27/small_640-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021194-94bf7a968bbfa8b65f9d089e9d7201f679425a27/four_three_640-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021194-94bf7a968bbfa8b65f9d089e9d7201f679425a27/square_640-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021194-94bf7a968bbfa8b65f9d089e9d7201f679425a27/downloadable-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg"
        },
        "created_on": "2019-12-04T19:28:51Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4610606498",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021193-979e3e493152e0e6215b4c6f52a6529da606ef27/original-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
        "name": "DBI-6-DDs0V79b-CRE-Regional-Center---Starter-Package_DBI-15-DBU-32-AaIO2KQt-DJI_0077-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.35 MB",
        "size": 4557060,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021193-979e3e493152e0e6215b4c6f52a6529da606ef27/large_1920-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021193-979e3e493152e0e6215b4c6f52a6529da606ef27/medium_1024-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021193-979e3e493152e0e6215b4c6f52a6529da606ef27/small_640-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021193-979e3e493152e0e6215b4c6f52a6529da606ef27/four_three_640-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021193-979e3e493152e0e6215b4c6f52a6529da606ef27/square_640-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021193-979e3e493152e0e6215b4c6f52a6529da606ef27/downloadable-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg"
        },
        "created_on": "2019-12-04T19:28:50Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0225061738",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021192-979e3e493152e0e6215b4c6f52a6529da606ef27/original-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
        "name": "DBI-5-DL4fyEvd-CRE-Regional-Center---Starter-Package_DBI-10-DBU-27-tedIIENP-DJI_0086-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.23 MB",
        "size": 4435590,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021192-979e3e493152e0e6215b4c6f52a6529da606ef27/large_1920-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021192-979e3e493152e0e6215b4c6f52a6529da606ef27/medium_1024-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021192-979e3e493152e0e6215b4c6f52a6529da606ef27/small_640-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021192-979e3e493152e0e6215b4c6f52a6529da606ef27/four_three_640-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021192-979e3e493152e0e6215b4c6f52a6529da606ef27/square_640-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021192-979e3e493152e0e6215b4c6f52a6529da606ef27/downloadable-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg"
        },
        "created_on": "2019-12-04T19:28:50Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7081470574",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021191-d419173961b2d79d1ea71c1d66952e79bd772428/original-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
        "name": "DBI-4-MwppozNT-CRE-Regional-Center---Starter-Package_DBI-11-DBU-28-iFOZ3lMK-DJI_0084-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.25 MB",
        "size": 4456519,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021191-d419173961b2d79d1ea71c1d66952e79bd772428/large_1920-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021191-d419173961b2d79d1ea71c1d66952e79bd772428/medium_1024-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021191-d419173961b2d79d1ea71c1d66952e79bd772428/small_640-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021191-d419173961b2d79d1ea71c1d66952e79bd772428/four_three_640-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021191-d419173961b2d79d1ea71c1d66952e79bd772428/square_640-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021191-d419173961b2d79d1ea71c1d66952e79bd772428/downloadable-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg"
        },
        "created_on": "2019-12-04T19:28:49Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI8841706661",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021190-d419173961b2d79d1ea71c1d66952e79bd772428/original-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
        "name": "DBI-3-SFFpgcY3-CRE-Regional-Center---Starter-Package_DBI-2-DBU-7-zxNP2A12-DJI_0124-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.3 MB",
        "size": 4513335,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021190-d419173961b2d79d1ea71c1d66952e79bd772428/large_1920-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021190-d419173961b2d79d1ea71c1d66952e79bd772428/medium_1024-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021190-d419173961b2d79d1ea71c1d66952e79bd772428/small_640-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021190-d419173961b2d79d1ea71c1d66952e79bd772428/four_three_640-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021190-d419173961b2d79d1ea71c1d66952e79bd772428/square_640-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021190-d419173961b2d79d1ea71c1d66952e79bd772428/downloadable-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg"
        },
        "created_on": "2019-12-04T19:28:49Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2642568022",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021189-d052a69251b62962026e453659c2cf8c73275118/original-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
        "name": "DBI-2-gMOHKOH0-CRE-Regional-Center---Starter-Package_DBI-1-DBU-2-pXdHUx0i-DJI_0134-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.49 MB",
        "size": 4703872,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021189-d052a69251b62962026e453659c2cf8c73275118/large_1920-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021189-d052a69251b62962026e453659c2cf8c73275118/medium_1024-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021189-d052a69251b62962026e453659c2cf8c73275118/small_640-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021189-d052a69251b62962026e453659c2cf8c73275118/four_three_640-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021189-d052a69251b62962026e453659c2cf8c73275118/square_640-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021189-d052a69251b62962026e453659c2cf8c73275118/downloadable-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg"
        },
        "created_on": "2019-12-04T19:28:48Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI5403745235",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10021188-d052a69251b62962026e453659c2cf8c73275118/original-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
        "name": "DBI-1-0x9qNIJm-CRE-Regional-Center---Starter-Package_DBI-7-DBU-23-U1pJS1iQ-DJI_0094-final-assets.jpeg",
        "source_type": "edit",
        "formatted_size": "4.17 MB",
        "size": 4377579,
        "height": 3648,
        "width": 4864,
        "resolution": 72,
        "gps_latitude": null,
        "gps_longitude": null,
        "gps_altitude": null,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021188-d052a69251b62962026e453659c2cf8c73275118/large_1920-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10021188-d052a69251b62962026e453659c2cf8c73275118/medium_1024-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10021188-d052a69251b62962026e453659c2cf8c73275118/small_640-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10021188-d052a69251b62962026e453659c2cf8c73275118/four_three_640-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10021188-d052a69251b62962026e453659c2cf8c73275118/square_640-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "download": "https://cdn.dronebase.com/assets/mission/images/10021188-d052a69251b62962026e453659c2cf8c73275118/downloadable-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg"
        },
        "created_on": "2019-12-04T19:28:48Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061039",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV9441475697",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-21-vmqhiTOf-DL-CRE-Regional-Center---Starter-Package_DBV-16-DBUV000-DJI-0147-creative.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174499-4d75347b3a3b17021314444c4d196a3ebeb9a00f/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174499-4d75347b3a3b17021314444c4d196a3ebeb9a00f/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174499-4d75347b3a3b17021314444c4d196a3ebeb9a00f/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174499-4d75347b3a3b17021314444c4d196a3ebeb9a00f/DL-DBV-21-vmqhiTOf-DL-CRE-Regional-Center---Starter-Package_DBV-16-DBUV000-DJI-0147-creative.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061038",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV6964641717",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-20-rm5ywDQX-DL-CRE-Regional-Center---Starter-Package_DBV-17-DBUV001-DJI-0146-risereverse.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174498-028bb69a434f79ddb36d04ae36128076ad840dd1/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174498-028bb69a434f79ddb36d04ae36128076ad840dd1/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174498-028bb69a434f79ddb36d04ae36128076ad840dd1/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174498-028bb69a434f79ddb36d04ae36128076ad840dd1/DL-DBV-20-rm5ywDQX-DL-CRE-Regional-Center---Starter-Package_DBV-17-DBUV001-DJI-0146-risereverse.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061037",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV4178063257",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-19-bP7dfz1x-DL-CRE-Regional-Center---Starter-Package_DBV-23-DBUV006-DJI-0151-trucking.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174497-6fa289299c4dcd7e77f94fddc7287e94fa78c07c/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174497-6fa289299c4dcd7e77f94fddc7287e94fa78c07c/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174497-6fa289299c4dcd7e77f94fddc7287e94fa78c07c/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174497-6fa289299c4dcd7e77f94fddc7287e94fa78c07c/DL-DBV-19-bP7dfz1x-DL-CRE-Regional-Center---Starter-Package_DBV-23-DBUV006-DJI-0151-trucking.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061036",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV4957141293",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-18-8NNRxV4H-DL-CRE-Regional-Center---Starter-Package_DBV-18-DBUV002-DJI-0141-final-orbit.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174496-dcb399b59b71cb10e184078fa6b4f9e70f38de56/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174496-dcb399b59b71cb10e184078fa6b4f9e70f38de56/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174496-dcb399b59b71cb10e184078fa6b4f9e70f38de56/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174496-dcb399b59b71cb10e184078fa6b4f9e70f38de56/DL-DBV-18-8NNRxV4H-DL-CRE-Regional-Center---Starter-Package_DBV-18-DBUV002-DJI-0141-final-orbit.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061035",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV2027538613",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-17-wWJwzA5B-DL-CRE-Regional-Center---Starter-Package_DBV-20-DBUV003-DJI-0149-trucking.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174495-ce971250d003dc0c40d1abcae8432755fd5057a9/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174495-ce971250d003dc0c40d1abcae8432755fd5057a9/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174495-ce971250d003dc0c40d1abcae8432755fd5057a9/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174495-ce971250d003dc0c40d1abcae8432755fd5057a9/DL-DBV-17-wWJwzA5B-DL-CRE-Regional-Center---Starter-Package_DBV-20-DBUV003-DJI-0149-trucking.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061034",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV1627704118",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-16-5wtr3OD2-DL-CRE-Regional-Center---Starter-Package_DBV-21-DBUV004-DJI-0145-Dolly.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174494-6e4ea5b54a678fd1cd98121f11e952ce88141ba2/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174494-6e4ea5b54a678fd1cd98121f11e952ce88141ba2/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174494-6e4ea5b54a678fd1cd98121f11e952ce88141ba2/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174494-6e4ea5b54a678fd1cd98121f11e952ce88141ba2/DL-DBV-16-5wtr3OD2-DL-CRE-Regional-Center---Starter-Package_DBV-21-DBUV004-DJI-0145-Dolly.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1061033",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "03cce4062eda062312eeb9205fd56c55",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-05T07:01:14Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "1061040",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "278166",
        "shot_type_id": 22,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Final Assets"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "22",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "22",
      "type": "shot_types",
      "attributes": {
        "name": "Final Assets",
        "description": "Use this shot to upload final edited assets for the customer. Not for use by pilots.\n",
        "video": null,
        "slug": "final-assets",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061039",
      "type": "shots",
      "attributes": {
        "instructions": "(16) images taken from equal increments around the property. *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "278166",
        "shot_type_id": 24,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (350 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "24",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI8841706661",
            "type": "images"
          }, {
            "id": "DBI2642568022",
            "type": "images"
          }, {
            "id": "DBI5187111239",
            "type": "images"
          }, {
            "id": "DBI2407573859",
            "type": "images"
          }, {
            "id": "DBI8587170468",
            "type": "images"
          }, {
            "id": "DBI3690715925",
            "type": "images"
          }, {
            "id": "DBI4779923656",
            "type": "images"
          }, {
            "id": "DBI7277532583",
            "type": "images"
          }, {
            "id": "DBI8709327156",
            "type": "images"
          }, {
            "id": "DBI1564669762",
            "type": "images"
          }, {
            "id": "DBI4610606498",
            "type": "images"
          }, {
            "id": "DBI8255512277",
            "type": "images"
          }, {
            "id": "DBI0225061738",
            "type": "images"
          }, {
            "id": "DBI7081470574",
            "type": "images"
          }, {
            "id": "DBI5403745235",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "24",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (350 ft above ground level)",
        "description": "*If there are altitude restrictions in this airspace, please take these images at the highest allowable altitude instead of 350'. *",
        "video": null,
        "slug": "images-cardinal-directions-350-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "23",
            "type": "shot_type_sample_images"
          }, {
            "id": "24",
            "type": "shot_type_sample_images"
          }, {
            "id": "25",
            "type": "shot_type_sample_images"
          }, {
            "id": "26",
            "type": "shot_type_sample_images"
          }, {
            "id": "27",
            "type": "shot_type_sample_images"
          }, {
            "id": "28",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1061038",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: focus on some point of interest.\n",
        "mission_id": "278166",
        "shot_type_id": 54,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Creative Video Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "54",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV9441475697",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "54",
      "type": "shot_types",
      "attributes": {
        "name": "Creative Video Shot",
        "description": null,
        "video": null,
        "slug": "getty-video-getty-video",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061037",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: Rise + Reverse Away facing the front of the subject property.  \n",
        "mission_id": "278166",
        "shot_type_id": 10,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Rise + Reverse Away Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "10",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV6964641717",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "10",
      "type": "shot_types",
      "attributes": {
        "name": "Rise + Reverse Away Shot",
        "description": "",
        "video": "https://youtu.be/PwOvzZqQWkg",
        "slug": "rise-reverse-away-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061036",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Truck (*left to right*) across the front of the property. *Adjust altitude as needed so that you fly above any trees or obstacles.*  \n",
        "mission_id": "278166",
        "shot_type_id": 2,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Trucking Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "2",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV4178063257",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "2",
      "type": "shot_types",
      "attributes": {
        "name": "Trucking Shot",
        "description": "",
        "video": "https://youtu.be/m-r79WAcDNs",
        "slug": "trucking-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061035",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Large + high altitude Contextual Orbit shot around property (counterclockwise)  \n*Must have entire subject property in frame and your camera must be angled up enough so that we also have the horizon in frame.*  \nThe point is to provide context of the property in relation to the surrounding area. This will require you to fly at a higher altitude and farther off the property to get the required shot. Typically shot around 300 ft. Orbit should be slow + steady. No jerky camera movements.   \n",
        "mission_id": "278166",
        "shot_type_id": 8,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Orbit Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "8",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV4957141293",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "8",
      "type": "shot_types",
      "attributes": {
        "name": "Orbit Shot",
        "description": "",
        "video": "https://youtu.be/MzdF92gWXyU",
        "slug": "orbit-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1061034",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Truck (*right to left*) across the front of the property. *Adjust altitude as needed so that you fly above any trees or obstacles.*  \n",
        "mission_id": "278166",
        "shot_type_id": 2,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Trucking Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "2",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV2027538613",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "1061033",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Slowly fly towards the front of the property at a constant altitude with your camera pointing straight (can have slight angle downward if needed). Keep the horizon flat. Fly completely over property and the end of the property lot. *The front will be where the main entrance or road way is to the property.* \n",
        "mission_id": "278166",
        "shot_type_id": 1,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Dolly Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "1",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV1627704118",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "shot_types",
      "attributes": {
        "name": "Dolly Shot",
        "description": "",
        "video": "https://youtu.be/H80sEIUHa7Y",
        "slug": "dolly-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "DBINV332487670352125",
      "type": "invoice_line_items",
      "attributes": {
        "reason": "base_package_price",
        "description": null,
        "amount": 51900,
        "created_on": "2019-12-04T19:18:39Z",
        "display_name": "Base Package Price"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4251104484",
            "type": "missions"
          }
        },
        "work_item": {
          "data": null
        }
      }
    }]
  };
  _exports.default = _default;
});