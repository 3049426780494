define("clients/components/main-navigation", ["exports", "clients/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth0: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    metrics: Ember.inject.service(),
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }).readOnly(),
    profileMenuVisible: false,
    tagName: 'nav',
    classNames: ["navbar", "navbar-inverse", "navbar-fixed-top"],

    init() {
      this._super();

      if (this.sessionAccount.account) {
        this.sessionAccount.account.then(account => {
          this.set('account', account);
        });
      }

      this.set('currentPath', this.router.currentPath);
      (0, _jquery.default)('.ember-view').click(function () {});
      return document.addEventListener('click', event => {
        const targetClass = event.target.className.toString().trim();
        const doNotClear = ['username', 'profile-icon', 'profile-icon nav-tooltip profile-icon-active', 'pilot-information', 'username username-active', 'profile-icon profile-icon-active', 'pilot-menu', 'is-org-owner'];

        if (!Array.from(doNotClear).includes(targetClass)) {
          if (this && (!this.get('isDestroyed') || !this.get('isDestroying'))) {
            return this.set('profileMenuVisible', false);
          }
        }
      });
    },

    didRender() {
      if (this.controllerFor) {
        return this.controllerFor('application').get('currentRouteName').currentPath === 'missions.new';
      }
    },

    click(e) {
      const el = (0, _jquery.default)('.navbar-collapse.in');

      if (el.length > 0 && e.target.className !== 'dropdown-toggle') {
        return el.collapse('hide');
      }
    },

    actions: {
      invalidateSession() {
        this.metrics.trackEvent({
          event: 'event',
          category: 'Authentication',
          action: 'click',
          label: 'Logout'
        });

        if (this.session.isAuthenticated) {
          this.auth0.logout();
        } else {
          return this.session.invalidate();
        }
      },

      toggleProfileMenu() {
        return this.set('profileMenuVisible', !this.profileMenuVisible);
      }

    }
  });

  _exports.default = _default;
});