define("clients/routes/missions/new", ["exports", "clients/config/environment", "clients/mixins/css-class-namespace"], function (_exports, _environment, _cssClassNamespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionsNewRoute;
  MissionsNewRoute = Ember.Route.extend(_cssClassNamespace.default, {
    session: Ember.inject.service(),
    cookies: Ember.inject.service(),
    queryParams: {
      org: {
        refreshModel: true
      }
    },
    model: function (params) {
      var data, maxAge, mission;
      mission = this.store.createRecord('mission');

      if (!mission.get('onsite_contact')) {
        this.store.createRecord('onsite_contact', {
          mission: mission,
          call_action: 'not_needed'
        });
      }

      if (!mission.get('scheduling_contact')) {
        this.store.createRecord('scheduling_contact', {
          mission: mission
        });
      }

      data = null;

      if (params["package"]) {
        data = {
          target_package: params["package"]
        };
      }

      if (params['org']) {
        maxAge = 60 * 60 * 24 * 180;
        this.get('cookies').write('org_on_signup', params['org'], {
          maxAge: maxAge
        });
      }

      return Ember.RSVP.hash({
        mission: mission,
        client: this.store.createRecord('client'),
        packages: this.store.query('package', data),
        location: this.store.peekAll('location').findBy('temp', true) || this.store.createRecord('location'),
        params: params,
        org_package: this.store.find('package', params['package']),
        package_addons: Ember.$.ajax({
          url: _environment.default.api.host + "/v1/public/package_addons",
          dataType: 'json'
        }).then(function (_this) {
          return function (response) {
            _this.store.pushPayload('package-addon', response);

            return _this.store.peekAll('package-addon');
          };
        }(this))
      });
    },
    afterModel: function (model, transition) {
      if (!model.org_package) {
        console.log("Error, can't find package: " + model.params["package"]);
        return this.transitionTo('fourOhFour', {});
      }
    },
    setupController: function (controller, model) {
      var loadedPackages, selected_package;

      this._super(controller, model);

      this.controllerFor('application').set('showNavBar', true);
      this.controllerFor('application').set('hideSidebar', true);
      this.controllerFor('application').set('minimizedSidebar', false);
      selected_package = model.org_package;

      if (selected_package) {
        model.mission.set('package', selected_package);
        model.client.set('organization', model.org_package.get('organization'));
        loadedPackages = [];
        model.packages.forEach(function (mission_package) {
          return loadedPackages.push(mission_package);
        });
        return model.packages = loadedPackages;
      }
    },
    authenticatedObserver: Ember.observer('session.data.authenticated.token', function () {
      var showNavBar;
      showNavBar = !!this.get('session.data.authenticated.token');
      return this.controllerFor('application').set('showNavBar', showNavBar);
    }),
    redirect: function (model, transition) {
      if (!!this.get('session.data.authenticated.token')) {
        return this.transitionTo('order-flow');
      }
    },
    actions: {
      goToUpload: function (model) {
        return this.replaceWith('missions.upload', {
          mission_id: model.mission.id,
          order_flow: true
        });
      }
    }
  });
  var _default = MissionsNewRoute;
  _exports.default = _default;
});