define("clients/components/mission-group/rename-modal", ["exports", "clients/components/mission-group/base-modal"], function (_exports, _baseModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModal.default.extend({
    groupName: null,

    init() {
      this._super();

      this.set('groupName', this.group.name);
    },

    saveRecord: function () {
      this.group.set('name', this.groupName);
      return this.group.save();
    }
  });

  _exports.default = _default;
});