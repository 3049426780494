define("clients/services/packages", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Packages;
  Packages = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    all: null,
    onInit: Ember.on('init', function () {
      return this.get('store').query('package', {
        available: true,
        client_id: this.get('session.data.authenticated.profile.sub'),
        client_token: this.get('session.data.authenticated.token')
      }).then(function (_this) {
        return function (response) {
          return _this.set('all', response.sortBy('vertical.short_name', 'name', 'price'));
        };
      }(this));
    })
  });
  var _default = Packages;
  _exports.default = _default;
});