define("clients/components/account-info-editable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function () {
      this.element.querySelectorAll('input').forEach(el => {
        el.addEventListener('keyup', e => {
          this.changeset.validate().then(() => {
            if (e.keyCode === 13 && !this.changeset.get('isInvalid')) {
              $('.btnSubmit').addClass('clicked');
              this.saveAccount();
            }
          });
        });
      });
    }
  });

  _exports.default = _default;
});