define("clients/components/charts/base-chart", ["exports", "accounting/format-money", "clients/data/colors"], function (_exports, _formatMoney, _colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['client-chart'],
    title: null,
    subtitle: null,
    containerName: 'container',
    chartType: 'area',
    xAxisFormatter: function () {
      return Highcharts.dateFormat('%b %d', this.value);
    },
    dailyAxisFormatter: function () {
      return Highcharts.dateFormat('%b %d', this.value);
    },
    hourlyAxisFormatter: function () {
      return Highcharts.dateFormat('%I:%M %P', this.value);
    },
    tooltipOptions: {
      borderColor: "#efefef",
      backgroundColor: 'white',
      style: {
        color: '#1d2d47'
      },
      formatter: function () {
        return Highcharts.dateFormat('%b %d, %Y', this.x) + '<br>' + (0, _formatMoney.default)(this.y, {
          precision: 0
        });
      }
    },
    chartColor: _colors.purple400,
    hourlyTooltipFormatter: function () {
      var pluralEnd;

      if (this.y === 1) {
        pluralEnd = '';
      } else {
        pluralEnd = 's';
      }

      return Highcharts.dateFormat('%I:%M %P', this.x) + '<br>' + this.y + ' order' + pluralEnd;
    },
    dailyTooltipFormatter: function () {
      var pluralEnd;

      if (this.y === 1) {
        pluralEnd = '';
      } else {
        pluralEnd = 's';
      }

      return Highcharts.dateFormat('%b %d, %Y', this.x) + '<br>' + this.y + ' order' + pluralEnd;
    },
    didInsertElement: function () {
      let height;

      if ($(document).outerWidth() > 1000) {
        height = 9 / 16 * 100 + '%';
      } else {
        height = 3 / 4 * 100 + '%';
      }

      return Highcharts.chart(this.get('containerName'), {
        chart: {
          type: this.get('chartType'),
          height: height,
          marginBottom: 25
        },
        title: {
          text: this.get('title'),
          align: 'left',
          useHTML: true,
          x: 0,
          y: 10,
          margin: 30
        },
        subtitle: {
          text: this.get('subtitle'),
          align: 'left',
          x: 0,
          y: 50,
          margin: 40
        },
        xAxis: {
          type: 'datetime',
          labels: {
            formatter: this.get('xAxisFormatter')
          }
        },
        yAxis: {
          title: {
            enabled: false
          },
          min: 0,
          minRange: 1,
          allowDecimals: false
        },
        legend: {
          enabled: false
        },
        tooltip: this.get('tooltipOptions'),
        plotOptions: {
          area: {
            stacking: 'normal',
            lineColor: this.get('chartColor'),
            lineWidth: 2,
            marker: {
              lineWidth: 1,
              lineColor: this.get('chartColor'),
              fillColor: 'white',
              enabled: false
            }
          },
          column: {
            pointWidth: 40
          },
          line: {
            color: this.get('chartColor'),
            marker: {
              enabled: false
            }
          }
        },
        series: [{
          data: this.get('content')
        }]
      });
    },
    contentDidChange: Ember.observer('statsLoaded', function () {
      let tooltipFormatter, xAxisFormatter;
      const chart = this.get('chart');
      chart.series[0].update({
        data: this.get('content')
      });

      if (this.get('tooltipsFormat') === 'weeks') {
        const endDate = this.get('endDate');

        tooltipFormatter = function () {
          const tooltipStartDate = this.x;
          let tooltipEndDate = moment(tooltipStartDate).utc().endOf('week').valueOf();

          if (tooltipEndDate > moment(endDate).utc().valueOf()) {
            tooltipEndDate = moment(endDate).utc().valueOf();
          }

          let pluralEnd;

          if (this.y === 1) {
            pluralEnd = '';
          } else {
            pluralEnd = 's';
          }

          return Highcharts.dateFormat('%b %d', tooltipStartDate) + ' - ' + Highcharts.dateFormat('%b %d, %Y', tooltipEndDate) + '<br>' + this.y + ' order' + pluralEnd;
        };

        xAxisFormatter = this.get('dailyAxisFormatter');
      } else if (this.get('tooltipsFormat') === 'days') {
        tooltipFormatter = this.get('dailyTooltipFormatter');
        xAxisFormatter = this.get('dailyAxisFormatter');
      } else {
        tooltipFormatter = this.get('hourlyTooltipFormatter');
        xAxisFormatter = this.get('hourlyAxisFormatter');
      }

      return chart.update({
        tooltip: {
          formatter: tooltipFormatter
        },
        subtitle: {
          text: this.get('subtitle')
        },
        xAxis: {
          labels: {
            formatter: xAxisFormatter
          }
        }
      });
    })
  });

  _exports.default = _default;
});