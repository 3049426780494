define("clients/helpers/DBMap/format-altitude", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatAltitude = _exports.default = void 0;

  const formatAltitude = function () {
    const altitude = parseFloat(arguments.length <= 0 ? undefined : arguments[0]);

    if (altitude != null && typeof altitude != 'undefined' && !isNaN(altitude)) {
      return `${altitude} ft`;
    } else {
      return '';
    }
  };

  _exports.formatAltitude = formatAltitude;

  var _default = Ember.Helper.helper(formatAltitude);

  _exports.default = _default;
});