define("clients/models/mission-composer-report-section", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    report: (0, _model.belongsTo)('mission-composer-report', {
      async: false
    }),
    roof_condition_text: (0, _model.attr)('string'),
    roof_condition_value: (0, _model.attr)('number')
  });

  _exports.default = _default;
});