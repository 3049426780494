define("clients/mirage/factories/mission", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    id(i) {
      return `DBM123${i}123`;
    },

    price: 19900.0,
    status: 'Some_status',

    afterCreate(mission, server) {
      mission.update({
        location: server.create('location')
      });
    },

    uploadRequired: (0, _emberCliMirage.trait)({
      afterCreate(mission, server) {
        mission.update({
          status: 'upload_required',
          package: server.create('package', 'clientUpload')
        });
      }

    })
  });

  _exports.default = _default;
});