define("clients/components/charts/cancellations-by-reason", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['client-chart', 'cancel-reason-chart'],
    tooltipText: 'Orders cancelled, categorized by reason'
  });

  _exports.default = _default;
});