define("clients/data/sample_images/cre_images_shopping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": [{
      "id": "DBI2317149084",
      "type": "image",
      "attributes": {
        "name": "DBI-32-gK0ObgUy-CRE-Images-Package---Shopping-Centers-Sample_DBI-50-paYmqqka-Lakeside-Marketplace_DBU91-DJI_0074.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034035-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/downloadable-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034035-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/large_1920-9cc8b3cdf2b587cdc7b0c4fcedf8d28fb46e39dc.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzNS1iMDM4OGU5ZWFkMTBmNGU2ZDRiMTAyMmNhNzFmY2VhYzY3YjBhNmNiL2xhcmdlXzE5MjAtOWNjOGIzY2RmMmI1ODdjZGM3YjBjNGZjZWRmOGQyOGZiNDZlMzlkYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzNS1iMDM4OGU5ZWFkMTBmNGU2ZDRiMTAyMmNhNzFmY2VhYzY3YjBhNmNiL2xhcmdlXzE5MjAtOWNjOGIzY2RmMmI1ODdjZGM3YjBjNGZjZWRmOGQyOGZiNDZlMzlkYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:35:33Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060809",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3451367597",
      "type": "image",
      "attributes": {
        "name": "DBI-31-8hFSEJCS-CRE-Images-Package---Shopping-Centers-Sample_DBI-51-n6hpnhi5-Lakeside-Marketplace_DBU92-DJI_0075.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034034-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/downloadable-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034034-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/large_1920-d35af116ad8ffac6a392564e1e3cd5ad99d793da.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzNC1iMDM4OGU5ZWFkMTBmNGU2ZDRiMTAyMmNhNzFmY2VhYzY3YjBhNmNiL2xhcmdlXzE5MjAtZDM1YWYxMTZhZDhmZmFjNmEzOTI1NjRlMWUzY2Q1YWQ5OWQ3OTNkYS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzNC1iMDM4OGU5ZWFkMTBmNGU2ZDRiMTAyMmNhNzFmY2VhYzY3YjBhNmNiL2xhcmdlXzE5MjAtZDM1YWYxMTZhZDhmZmFjNmEzOTI1NjRlMWUzY2Q1YWQ5OWQ3OTNkYS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:35:33Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060809",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI2330296799",
      "type": "image",
      "attributes": {
        "name": "DBI-30-GC1JJxd9-CRE-Images-Package---Shopping-Centers-Sample_DBI-30-6Cem6m7R-Lakeside-Marketplace_DBU71-NW_100FTR.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034033-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/downloadable-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034033-b0388e9ead10f4e6d4b1022ca71fceac67b0a6cb/large_1920-999d4fa0a55e693e075c6dba97692c7b0f5ffcb3.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzMy1iMDM4OGU5ZWFkMTBmNGU2ZDRiMTAyMmNhNzFmY2VhYzY3YjBhNmNiL2xhcmdlXzE5MjAtOTk5ZDRmYTBhNTVlNjkzZTA3NWM2ZGJhOTc2OTJjN2IwZjVmZmNiMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzMy1iMDM4OGU5ZWFkMTBmNGU2ZDRiMTAyMmNhNzFmY2VhYzY3YjBhNmNiL2xhcmdlXzE5MjAtOTk5ZDRmYTBhNTVlNjkzZTA3NWM2ZGJhOTc2OTJjN2IwZjVmZmNiMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:35:33Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060809",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4505460324",
      "type": "image",
      "attributes": {
        "name": "DBI-29-t4fxwRHh-CRE-Images-Package---Shopping-Centers-Sample_DBI-33-egnffXbz-Lakeside-Marketplace_DBU74-SW_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034032-fbab27b389279926b1fe2c978d0b6be05792c1d1/downloadable-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034032-fbab27b389279926b1fe2c978d0b6be05792c1d1/large_1920-58b56ed2c054c513b803908e2c621724881381a3.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzMi1mYmFiMjdiMzg5Mjc5OTI2YjFmZTJjOTc4ZDBiNmJlMDU3OTJjMWQxL2xhcmdlXzE5MjAtNThiNTZlZDJjMDU0YzUxM2I4MDM5MDhlMmM2MjE3MjQ4ODEzODFhMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzMi1mYmFiMjdiMzg5Mjc5OTI2YjFmZTJjOTc4ZDBiNmJlMDU3OTJjMWQxL2xhcmdlXzE5MjAtNThiNTZlZDJjMDU0YzUxM2I4MDM5MDhlMmM2MjE3MjQ4ODEzODFhMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:35:32Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060809",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI7421574683",
      "type": "image",
      "attributes": {
        "name": "DBI-28-UBt6wEgk-CRE-Images-Package---Shopping-Centers-Sample_DBI-36-wOLh2iGJ-Lakeside-Marketplace_DBU77-DJI_0055.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034031-fbab27b389279926b1fe2c978d0b6be05792c1d1/downloadable-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034031-fbab27b389279926b1fe2c978d0b6be05792c1d1/large_1920-a5218f5870b46e3d9b235c0eb4fbeaa545ec5b2c.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzMS1mYmFiMjdiMzg5Mjc5OTI2YjFmZTJjOTc4ZDBiNmJlMDU3OTJjMWQxL2xhcmdlXzE5MjAtYTUyMThmNTg3MGI0NmUzZDliMjM1YzBlYjRmYmVhYTU0NWVjNWIyYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzMS1mYmFiMjdiMzg5Mjc5OTI2YjFmZTJjOTc4ZDBiNmJlMDU3OTJjMWQxL2xhcmdlXzE5MjAtYTUyMThmNTg3MGI0NmUzZDliMjM1YzBlYjRmYmVhYTU0NWVjNWIyYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:35:32Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060809",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3224295262",
      "type": "image",
      "attributes": {
        "name": "DBI-27-yurqBHxQ-CRE-Images-Package---Shopping-Centers-Sample_DBI-32-nu7toZ8x-Lakeside-Marketplace_DBU73-SE_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034030-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/downloadable-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034030-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/large_1920-db41f7f8d28620d2281beadbce37a84c40f8c8a7.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzMC0xNGVhNmYyYjNhMmY4ZTM5NGQ4MzcwMWRjODJmMzBmNGJjNWNjODE0L2xhcmdlXzE5MjAtZGI0MWY3ZjhkMjg2MjBkMjI4MWJlYWRiY2UzN2E4NGM0MGY4YzhhNy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAzMC0xNGVhNmYyYjNhMmY4ZTM5NGQ4MzcwMWRjODJmMzBmNGJjNWNjODE0L2xhcmdlXzE5MjAtZGI0MWY3ZjhkMjg2MjBkMjI4MWJlYWRiY2UzN2E4NGM0MGY4YzhhNy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:35:08Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060810",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8992966358",
      "type": "image",
      "attributes": {
        "name": "DBI-26-xkMNCzyn-CRE-Images-Package---Shopping-Centers-Sample_DBI-27-JGEYmlMO-Lakeside-Marketplace_DBU68-E_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034029-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/downloadable-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034029-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/large_1920-fd34865b6d1a8d784ec671e722bdd01d3fa42f7d.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyOS0xNGVhNmYyYjNhMmY4ZTM5NGQ4MzcwMWRjODJmMzBmNGJjNWNjODE0L2xhcmdlXzE5MjAtZmQzNDg2NWI2ZDFhOGQ3ODRlYzY3MWU3MjJiZGQwMWQzZmE0MmY3ZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyOS0xNGVhNmYyYjNhMmY4ZTM5NGQ4MzcwMWRjODJmMzBmNGJjNWNjODE0L2xhcmdlXzE5MjAtZmQzNDg2NWI2ZDFhOGQ3ODRlYzY3MWU3MjJiZGQwMWQzZmE0MmY3ZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:35:08Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060810",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI7921509806",
      "type": "image",
      "attributes": {
        "name": "DBI-25-j4MZZ1YP-CRE-Images-Package---Shopping-Centers-Sample_DBI-35-3rht1dJV-Lakeside-Marketplace_DBU76-FRONT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034028-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/downloadable-762384d67608457afc248fde66c893da7f918496.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034028-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/large_1920-762384d67608457afc248fde66c893da7f918496.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyOC0xNGVhNmYyYjNhMmY4ZTM5NGQ4MzcwMWRjODJmMzBmNGJjNWNjODE0L2xhcmdlXzE5MjAtNzYyMzg0ZDY3NjA4NDU3YWZjMjQ4ZmRlNjZjODkzZGE3ZjkxODQ5Ni5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyOC0xNGVhNmYyYjNhMmY4ZTM5NGQ4MzcwMWRjODJmMzBmNGJjNWNjODE0L2xhcmdlXzE5MjAtNzYyMzg0ZDY3NjA4NDU3YWZjMjQ4ZmRlNjZjODkzZGE3ZjkxODQ5Ni5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:35:08Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060810",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI0836929217",
      "type": "image",
      "attributes": {
        "name": "DBI-24-G2gxYzRH-CRE-Images-Package---Shopping-Centers-Sample_DBI-31-zGjKtYXQ-Lakeside-Marketplace_DBU72-S_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034027-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/downloadable-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034027-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/large_1920-7b65c6aac80b1ebd4c245c5bc92eac7afc42a2e0.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyNy0xNGVhNmYyYjNhMmY4ZTM5NGQ4MzcwMWRjODJmMzBmNGJjNWNjODE0L2xhcmdlXzE5MjAtN2I2NWM2YWFjODBiMWViZDRjMjQ1YzViYzkyZWFjN2FmYzQyYTJlMC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyNy0xNGVhNmYyYjNhMmY4ZTM5NGQ4MzcwMWRjODJmMzBmNGJjNWNjODE0L2xhcmdlXzE5MjAtN2I2NWM2YWFjODBiMWViZDRjMjQ1YzViYzkyZWFjN2FmYzQyYTJlMC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:35:08Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060810",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI2133703089",
      "type": "image",
      "attributes": {
        "name": "DBI-23-dkG5GpcU-CRE-Images-Package---Shopping-Centers-Sample_DBI-29-Q4idBa2p-Lakeside-Marketplace_DBU70-NE_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034026-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/downloadable-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034026-14ea6f2b3a2f8e394d83701dc82f30f4bc5cc814/large_1920-492d0b22f10d900ef123841826e66de9705918d8.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyNi0xNGVhNmYyYjNhMmY4ZTM5NGQ4MzcwMWRjODJmMzBmNGJjNWNjODE0L2xhcmdlXzE5MjAtNDkyZDBiMjJmMTBkOTAwZWYxMjM4NDE4MjZlNjZkZTk3MDU5MThkOC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyNi0xNGVhNmYyYjNhMmY4ZTM5NGQ4MzcwMWRjODJmMzBmNGJjNWNjODE0L2xhcmdlXzE5MjAtNDkyZDBiMjJmMTBkOTAwZWYxMjM4NDE4MjZlNjZkZTk3MDU5MThkOC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:35:08Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060810",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4628517795",
      "type": "image",
      "attributes": {
        "name": "DBI-22-YcUKwyAg-CRE-Images-Package---Shopping-Centers-Sample_DBI-28-zNfK8rO0-Lakeside-Marketplace_DBU69-N_100FT.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034025-22de49c05d57e3c4788293dcdedb20e5b8b44b71/downloadable-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034025-22de49c05d57e3c4788293dcdedb20e5b8b44b71/large_1920-84104043f6bb9bdffe1344fe22c0810672f6058d.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyNS0yMmRlNDljMDVkNTdlM2M0Nzg4MjkzZGNkZWRiMjBlNWI4YjQ0YjcxL2xhcmdlXzE5MjAtODQxMDQwNDNmNmJiOWJkZmZlMTM0NGZlMjJjMDgxMDY3MmY2MDU4ZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyNS0yMmRlNDljMDVkNTdlM2M0Nzg4MjkzZGNkZWRiMjBlNWI4YjQ0YjcxL2xhcmdlXzE5MjAtODQxMDQwNDNmNmJiOWJkZmZlMTM0NGZlMjJjMDgxMDY3MmY2MDU4ZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:35:07Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060810",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI9592283108",
      "type": "image",
      "attributes": {
        "name": "DBI-20-gvSRdW0J-CRE-Images-Package---Shopping-Centers-Sample_DBI-53-1e11H3VD-Lakeside-Marketplace_DBU94-DJI_0077.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034023-89a2c1a60cd8900e07974f682c7af1fee870ed9a/downloadable-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034023-89a2c1a60cd8900e07974f682c7af1fee870ed9a/large_1920-62155bd10193342d2fbb88f6512029f76865071a.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyMy04OWEyYzFhNjBjZDg5MDBlMDc5NzRmNjgyYzdhZjFmZWU4NzBlZDlhL2xhcmdlXzE5MjAtNjIxNTViZDEwMTkzMzQyZDJmYmI4OGY2NTEyMDI5Zjc2ODY1MDcxYS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyMy04OWEyYzFhNjBjZDg5MDBlMDc5NzRmNjgyYzdhZjFmZWU4NzBlZDlhL2xhcmdlXzE5MjAtNjIxNTViZDEwMTkzMzQyZDJmYmI4OGY2NTEyMDI5Zjc2ODY1MDcxYS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:33:31Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060811",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8882101535",
      "type": "image",
      "attributes": {
        "name": "DBI-19-aKIIgLIl-CRE-Images-Package---Shopping-Centers-Sample_DBI-58-9MohZxUx-Lakeside-Marketplace_DBU99-DJI_0088.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034022-89a2c1a60cd8900e07974f682c7af1fee870ed9a/downloadable-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034022-89a2c1a60cd8900e07974f682c7af1fee870ed9a/large_1920-da32d2c6ffe00f63bcbe66216f57a40832fda649.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyMi04OWEyYzFhNjBjZDg5MDBlMDc5NzRmNjgyYzdhZjFmZWU4NzBlZDlhL2xhcmdlXzE5MjAtZGEzMmQyYzZmZmUwMGY2M2JjYmU2NjIxNmY1N2E0MDgzMmZkYTY0OS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyMi04OWEyYzFhNjBjZDg5MDBlMDc5NzRmNjgyYzdhZjFmZWU4NzBlZDlhL2xhcmdlXzE5MjAtZGEzMmQyYzZmZmUwMGY2M2JjYmU2NjIxNmY1N2E0MDgzMmZkYTY0OS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:33:31Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060811",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI2691805426",
      "type": "image",
      "attributes": {
        "name": "DBI-18-PulvLpNG-CRE-Images-Package---Shopping-Centers-Sample_DBI-49-GBkeyipm-Lakeside-Marketplace_DBU90-DJI_0073.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034021-1bd2c6437ac897665459a9c25903ce2a7032b722/downloadable-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034021-1bd2c6437ac897665459a9c25903ce2a7032b722/large_1920-31f7f47cf7988b7c83b2d849491a0e29c5bd16e3.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyMS0xYmQyYzY0MzdhYzg5NzY2NTQ1OWE5YzI1OTAzY2UyYTcwMzJiNzIyL2xhcmdlXzE5MjAtMzFmN2Y0N2NmNzk4OGI3YzgzYjJkODQ5NDkxYTBlMjljNWJkMTZlMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAyMS0xYmQyYzY0MzdhYzg5NzY2NTQ1OWE5YzI1OTAzY2UyYTcwMzJiNzIyL2xhcmdlXzE5MjAtMzFmN2Y0N2NmNzk4OGI3YzgzYjJkODQ5NDkxYTBlMjljNWJkMTZlMy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:33:30Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060811",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI7984291648",
      "type": "image",
      "attributes": {
        "name": "DBI-73-MZALruFK-CRE-Images-Package---Shopping-Centers-Sample_DBI-54-8efSASmw-Lakeside-Marketplace_DBU95-DJI_0079.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034018-6e5cf2457eadcb2589cd41077de4d14b15cc7aba/downloadable-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034018-6e5cf2457eadcb2589cd41077de4d14b15cc7aba/large_1920-da2bea319b3319b5e62ef9d42e8fd83112d54a87.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAxOC02ZTVjZjI0NTdlYWRjYjI1ODljZDQxMDc3ZGU0ZDE0YjE1Y2M3YWJhL2xhcmdlXzE5MjAtZGEyYmVhMzE5YjMzMTliNWU2MmVmOWQ0MmU4ZmQ4MzExMmQ1NGE4Ny5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAxOC02ZTVjZjI0NTdlYWRjYjI1ODljZDQxMDc3ZGU0ZDE0YjE1Y2M3YWJhL2xhcmdlXzE5MjAtZGEyYmVhMzE5YjMzMTliNWU2MmVmOWQ0MmU4ZmQ4MzExMmQ1NGE4Ny5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:33:03Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060812",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6868906804",
      "type": "image",
      "attributes": {
        "name": "DBI-69-B2qeBf2I-CRE-Images-Package---Shopping-Centers-Sample_DBI-57-TgwqvXuM-Lakeside-Marketplace_DBU98-DJI_0087.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034014-9471aae912392c4b313cfbd0e1f47f2a3ae22b66/downloadable-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034014-9471aae912392c4b313cfbd0e1f47f2a3ae22b66/large_1920-188c4d9b9ef977755de18bebd18707bb772f08c1.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAxNC05NDcxYWFlOTEyMzkyYzRiMzEzY2ZiZDBlMWY0N2YyYTNhZTIyYjY2L2xhcmdlXzE5MjAtMTg4YzRkOWI5ZWY5Nzc3NTVkZTE4YmViZDE4NzA3YmI3NzJmMDhjMS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAxNC05NDcxYWFlOTEyMzkyYzRiMzEzY2ZiZDBlMWY0N2YyYTNhZTIyYjY2L2xhcmdlXzE5MjAtMTg4YzRkOWI5ZWY5Nzc3NTVkZTE4YmViZDE4NzA3YmI3NzJmMDhjMS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:33:01Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060812",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI5778387274",
      "type": "image",
      "attributes": {
        "name": "DBI-65-TGxaiTB0-CRE-Images-Package---Shopping-Centers-Sample_DBI-42-9rot8SIa-Lakeside-Marketplace_DBU83-DJI_0063.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10034010-4967bc7330a467a77bc9d688ff5277e2956d89b2/downloadable-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10034010-4967bc7330a467a77bc9d688ff5277e2956d89b2/large_1920-25d6815b4708fa260f4d1c809f0dbfc55bb1900c.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAxMC00OTY3YmM3MzMwYTQ2N2E3N2JjOWQ2ODhmZjUyNzdlMjk1NmQ4OWIyL2xhcmdlXzE5MjAtMjVkNjgxNWI0NzA4ZmEyNjBmNGQxYzgwOWYwZGJmYzU1YmIxOTAwYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAzNDAxMC00OTY3YmM3MzMwYTQ2N2E3N2JjOWQ2ODhmZjUyNzdlMjk1NmQ4OWIyL2xhcmdlXzE5MjAtMjVkNjgxNWI0NzA4ZmEyNjBmNGQxYzgwOWYwZGJmYzU1YmIxOTAwYy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-06T00:32:59Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060812",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI9148706485",
      "type": "image",
      "attributes": {
        "name": "DBI-3-6PyOoS8a-CRE-Shopping-Center---Images-Package_DBI-6-downloadable-8c8bd726f1e7c2e7ab07cd153987ce194dcce521-final-assets.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10015587-b0b979106ab692c2314c45699254293173dfc7c2/downloadable-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10015587-b0b979106ab692c2314c45699254293173dfc7c2/large_1920-e4e738db9e0d7a86c90839c9ab2837aafedcb3ff.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTU4Ny1iMGI5NzkxMDZhYjY5MmMyMzE0YzQ1Njk5MjU0MjkzMTczZGZjN2MyL2xhcmdlXzE5MjAtZTRlNzM4ZGI5ZTBkN2E4NmM5MDgzOWM5YWIyODM3YWFmZWRjYjNmZi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAxNTU4Ny1iMGI5NzkxMDZhYjY5MmMyMzE0YzQ1Njk5MjU0MjkzMTczZGZjN2MyL2xhcmdlXzE5MjAtZTRlNzM4ZGI5ZTBkN2E4NmM5MDgzOWM5YWIyODM3YWFmZWRjYjNmZi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T01:49:32Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM5297589249",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1060810",
            "type": "shot"
          }
        }
      }
    }]
  };
  _exports.default = _default;
});