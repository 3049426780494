define("clients/adapters/currency", ["exports", "clients/adapters/application", "clients/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: `${_environment.default.api.namespace}/clients`
  });

  _exports.default = _default;
});