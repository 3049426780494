define("clients/models/shot", ["exports", "@ember-data/model", "ember-inflector"], function (_exports, _model, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    instructions: (0, _model.attr)('string'),
    pilot_comment: (0, _model.attr)('string'),
    image_archive_url: (0, _model.attr)('string'),
    mission: (0, _model.belongsTo)('mission', {
      async: false
    }),
    shot_type: (0, _model.belongsTo)('shot_type', {
      async: false
    }),
    template: (0, _model.belongsTo)('template', {
      async: false
    }),
    images: (0, _model.hasMany)('images', {
      async: true
    }),
    videos: (0, _model.hasMany)('videos', {
      async: false
    }),
    panoramas: (0, _model.hasMany)('panoramas', {
      async: false
    }),
    ortho_photo_map: (0, _model.hasMany)('ortho_photo_map', {
      async: false
    }),
    name: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string'),
    assetCount: Ember.computed('images', 'videos', function () {
      var ref, ref1;
      return (((ref = this.videos.filterBy('isPromoted', true)) != null ? ref.length : void 0) || 0) + (((ref1 = this.images.filterBy('isPromoted', true)) != null ? ref1.length : void 0) || 0);
    }),
    assetCountText: Ember.computed('assetCount', 'images.length', 'panoramas', 'videos.length', 'videos', function () {
      var string = [];

      if (this.assetCount === 0) {
        return 'No assets';
      }

      const imageCount = this.get('images.length') || 0;
      const videosCount = this.get('videos.length') || 0;

      if (videosCount) {
        string.push((0, _emberInflector.pluralize)(videosCount, ' video'));
      }

      if (imageCount) {
        string.push((0, _emberInflector.pluralize)(imageCount, ' image'));
      }

      return string.join(', ');
    }),
    promotedAssets: Ember.computed('images.[]', 'videos.[]', 'panoramas.[]', function () {
      var assets, images, videos;
      videos = this.videos.filterBy('source_type', 'edit');
      images = this.images.filterBy('isPromoted', true);
      assets = [];
      videos.forEach(function (video) {
        video.set('assetType', 'video');
        return assets.pushObject(video);
      });
      images.forEach(function (image) {
        image.set('assetType', 'image');
        return assets.pushObject(image);
      });
      return assets;
    }),
    firstlyShownAssets: Ember.computed('promotedAssets.length', function () {
      if (this.get('promotedAssets.length') > 0) {
        return this.promotedAssets.slice(0, 8);
      } else {
        return [];
      }
    }),
    hiddenAssets: Ember.computed('promotedAssets.length', function () {
      if (this.get('promotedAssets.length') > 8) {
        return this.promotedAssets.slice(8, this.get('promotedAssets.length'));
      } else {
        return [];
      }
    }),
    visibleAssets: Ember.computed.reads('firstlyShownAssets'),
    firstlyShownListAssets: Ember.computed('promotedAssets.length', function () {
      if (this.get('promotedAssets.length') > 0) {
        return this.promotedAssets.slice(0, 50);
      } else {
        return [];
      }
    }),
    hiddenListAssets: Ember.computed('promotedAssets.length', function () {
      if (this.get('promotedAssets.length') > 50) {
        return this.promotedAssets.slice(50, this.get('promotedAssets.length'));
      } else {
        return [];
      }
    }),
    visibleListAssets: Ember.computed.reads('firstlyShownListAssets')
  });

  _exports.default = _default;
});