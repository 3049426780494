define("clients/models/watcher", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    isWatcher: (0, _model.attr)('boolean'),
    hasAccess: (0, _model.attr)('boolean'),
    constIsWatching: (0, _model.attr)('boolean'),
    constHasAccess: (0, _model.attr)('boolean'),
    location: (0, _model.belongsTo)('location', {
      async: false
    })
  });

  _exports.default = _default;
});