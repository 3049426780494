define("clients/templates/components/share/only-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H/DynjPi",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"shot\",\"currentShare\"],[[35,1],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sharedVideo\",\"shot\",\"share/share-shot\"]}",
    "meta": {
      "moduleName": "clients/templates/components/share/only-video.hbs"
    }
  });

  _exports.default = _default;
});