define("clients/data/colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    gray25: '#FAFCFE',
    gray50: '#F0F3F7',
    gray100: '#E6EBF0',
    gray200: '#BEC5D2',
    gray300: '#8D95A2',
    gray400: '#545B65',
    gray500: '#42474E',
    gray700: '#2D343E',
    gray800: '#232A34',
    gray900: '#181A1C',
    purple700: '#4B3EC1',
    purple600: '#5B4DD3',
    purple500: '#5B48FB',
    purple400: '#7363FB',
    purple300: '#9C91FC',
    purple200: '#CDC7FE',
    purple100: '#EAE9F8',
    purple50: '#F4F4FB'
  };
  _exports.default = _default;
});