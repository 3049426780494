define("clients/templates/components/map-feature-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bXNA4f6h",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"div\"],[14,0,\"row-1\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"name col-1\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[35,1,[\"map_feature\",\"geometry\",\"type\"]],\"Polygon\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[14,\"src\",\"/assets/images/map/polygon.svg\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"img\"],[14,\"src\",\"/assets/images/map/line.svg\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[1,[34,4]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"size col-1\"],[12],[1,[34,5]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"line-items col-1\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,1,[\"items\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[1,[32,1,[\"work_item\",\"catalog_item\",\"name\"]]],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"amount\"],[12],[2,\"(\"],[1,[30,[36,0],[[32,1,[\"amount\"]],[32,1,[\"currency\"]]],null]],[2,\")\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"format-price\",\"mapFeatureItem\",\"is-equal\",\"if\",\"displayName\",\"featureDetails\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "clients/templates/components/map-feature-item.hbs"
    }
  });

  _exports.default = _default;
});