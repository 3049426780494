define("clients/components/esri-map", ["exports", "clients/config/environment", "clients/mixins/google-map-init-mixin", "proj4", "tz-lookup", "jquery"], function (_exports, _environment, _googleMapInitMixin, _proj, _tzLookup, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_googleMapInitMixin.default, {
    esriLoader: Ember.inject.service('esri-loader'),
    sessionAccount: Ember.inject.service(),
    metrics: Ember.inject.service(),
    isShowTutorialModal: false,
    isShowMap: Ember.computed('model.savedLocation', function () {
      return Ember.isEmpty(this.get('model.savedLocation'));
    }),
    isMapsListCollapsed: false,
    foundedAddressees: null,
    isDrawingPolygon: false,
    mapIsLoaded: false,
    preferredAddressLat: Ember.computed('preferredAddress.geometry.location', function () {
      if (this.preferredAddress) {
        return this.get('preferredAddress.geometry.location').lat();
      }

      return null;
    }),
    preferredAddressLng: Ember.computed('preferredAddress.geometry.location', function () {
      if (this.preferredAddress) {
        return this.get('preferredAddress.geometry.location').lng();
      }

      return null;
    }),
    preferredAddressAttributes: Ember.computed('preferredAddress', function () {
      if (this.preferredAddress) {
        return this.retrievePlaceAttributes(this.preferredAddress);
      }

      return null;
    }),

    checkAirspace(lat, lng) {
      _jquery.default.ajax({
        headers: this.get('sessionAccount.headers'),
        url: `${_environment.default.api.host}/v3/clients/airspaces/check`,
        data: {
          latitude: lat,
          longitude: lng
        }
      }).then(data => {
        this.updateAirspace(data);
      });
    },

    retrievePlaceAttributes(place) {
      let address_components = {};

      _jquery.default.each(place.address_components, (k, v1) => {
        return _jquery.default.each(v1.types, (k2, v2) => {
          if (v2 === 'country') {
            return address_components[v2] = v1.short_name;
          } else {
            return address_components[v2] = v1.long_name;
          }
        });
      });

      return address_components;
    },

    initMap() {// just to mute GoogleMapInitMixin error
    },

    didInsertElement() {
      this._super();

      if (this.tokenIsExpired()) {
        _jquery.default.ajax({
          url: `${_environment.default.api.host}/v1/clients/esri_accounts/${this.get('esri_account.id')}/renew_token`,
          type: 'POST',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(() => {
          this.esri_account.reload().then(() => {
            Ember.run.scheduleOnce('afterRender', this, this.initEsriMap);
          });
        });
      } else {
        Ember.run.scheduleOnce('afterRender', this, this.initEsriMap);
      }
    },

    willDestroyElement: function () {
      if (this._view) {
        this._view.container = null;
        delete this._view;
      }
    },
    newOrder: function (latitude, longitude) {
      this.addressFromCoordinates(latitude, longitude, function (results, self) {
        self.set('foundedAddressees', results);
        self.checkAirspace(latitude, longitude);
        self.showSummary();
      });
    },

    showSummary() {
      this.set('isShowMap', false);
      this.set('preferredAddress', this.foundedAddressees[0]);
      this.setModelAddress();
    },

    setModelAddress() {
      const timezoneId = (0, _tzLookup.default)(this.preferredAddressLat, this.preferredAddressLng);
      const address_components = this.preferredAddressAttributes;
      this.set('model.location.latitude', this.preferredAddressLat);
      this.set('model.location.longitude', this.preferredAddressLng);

      if (address_components.street_number && address_components.route) {
        this.set('model.location.address', `${address_components.street_number} ${address_components.route}`);
      } else if (address_components.country && address_components.locality) {
        this.set('model.location.address', `${address_components.locality}, ${address_components.country}`);
      } else {
        this.set('model.location.address', address_components.route || '');
      }

      this.set('model.location.formatted_address', this.get('preferredAddress.formatted_address'));
      this.set('formattedAddressSuggest', this.get('preferredAddress.formatted_address'));
      const city = address_components.locality || address_components.administrative_area_level_3 || address_components.neighborhood;
      this.set('model.location.city', city || '');
      this.set('model.location.state', address_components.administrative_area_level_1 || '');
      this.set('model.location.country', address_components.country || '');
      this.set('model.location.postal_code', address_components.postal_code || '');
      this.set('model.location.timezone_id', timezoneId);
      this.filterSearchResults();
    },

    filterSearchResults() {
      const city = this.get('model.location.city');
      let filtered = this.foundedAddressees.filter(el => {
        if (el.formatted_address) {
          return el.formatted_address.indexOf(city) > 0;
        } else {
          return el.text.indexOf(city) > 0;
        }
      });
      this.set('foundedAddressees', filtered);
    },

    addressFromCoordinates(latitude, longitude, callback) {
      const geocoder = new google.maps.Geocoder();
      const location = {
        'location': new google.maps.LatLng(latitude, longitude)
      };
      geocoder.geocode(location, results => {
        callback(results, this);
      });
    },

    addressFromString(addressString, callback) {
      const geocoder = new google.maps.Geocoder();
      const location = {
        'address': addressString
      };
      geocoder.geocode(location, results => {
        callback(results, this);
      });
    },

    initEsriMap: function () {
      this.esriLoader.loadModules(["esri/identity/OAuthInfo", "esri/identity/IdentityManager", "esri/Map", "esri/WebMap", "esri/views/MapView", "esri/widgets/Sketch/SketchViewModel", "esri/layers/GraphicsLayer", "esri/widgets/LayerList", "esri/widgets/Expand", "esri/widgets/Search", "dojo/dom", "dojo/dom-construct", "esri/portal/Portal", "dojo/query"], {
        css: true
      }).then(modules => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        const [OAuthInfo, esriId, Map, WebMap, MapView, SketchViewModel, GraphicsLayer, LayerList, Expand, Search, dom, domConstruct, Portal, query] = modules;
        const info = new OAuthInfo({
          appId: _environment.default.esri_app_id,
          popup: false
        });
        esriId.registerOAuthInfos([info]);
        esriId.registerToken({
          server: "https://www.arcgis.com/sharing/rest/",
          userId: this.get('esri_account.username'),
          token: this.get('esri_account.token'),
          ssl: true
        }); // Auto check if user signed in at Esri portal.

        esriId.checkSignInStatus(info.portalUrl + "/sharing").then(cred => {
          if (cred && cred.userId) {
            Ember.run.later(() => {
              if (Ember.isPresent(this.get('model.savedLocation'))) {
                this._view.center = this.get('model.savedLocation');
                this._view.zoom = this.get('model.savedMapZoom');
                this.set('formattedAddressSuggest', this.get('model.location.formatted_address'));
                this.set('preferredAddressLat', this.get('model.location.latitude'));
                this.set('preferredAddressLng', this.get('model.location.longitude'));
              }
            }, 1000);
          }
        }); // Add evenets to maps list

        const addMapsListEvents = () => {
          query('.map-name').on('click', e => {
            let mapContainer = e.target.closest('.loaded-map');
            let mapId = mapContainer.dataset.mapId;
            this.set('model.mission.lastEsriMapId', mapId);
            let map = new WebMap({
              portalItem: {
                id: mapId
              }
            });
            this._view.map = map;
            map.when(() => {
              this._view.viewpoint = map.initialViewProperties.viewpoint;
              map.remove(polygonGraphicsLayer);
              map.add(polygonGraphicsLayer); // hack because layerList is not triggered on layer toggle :(

              Ember.run.later(() => {
                query('.esri-layer-list__item-label').on('click', () => {
                  this.send('trackEvent', 'Toggle Layer Visibility');
                });
              }, 300);
            });
          });
        }; // Display user webmaps


        const displayLoadedMaps = (maps, setClientHasMaps) => {
          let mapsHtml;

          if (maps.results.length === 0) {
            mapsHtml = "";

            if (setClientHasMaps) {
              this.set('clientHasMaps', false);
            }
          } else {
            if (setClientHasMaps) {
              this.set('clientHasMaps', true);
            }

            mapsHtml = maps.results.map(m => {
              let result = `<div class='loaded-map' data-map-id='${m.id}'>\n`;
              result += "<div class='map-name'>\n";
              result += "<span class='dronebase-esri-icon-map'></span>\n";
              result += `<span class='map-name-title'>${m.title}</span>\n`;
              result += `<span class='edit-link'>Edit</span>\n`;
              result += "</div>\n";
              result += "<div class='map-layers'></div>";
              result += '</div>';
              return result;
            });
          }

          const mapsBlock = dom.byId('mapsBlock');
          var child = mapsBlock.lastElementChild;

          while (child) {
            mapsBlock.removeChild(child);
            child = mapsBlock.lastElementChild;
          }

          domConstruct.place(mapsHtml.join('\n'), mapsBlock);
          addMapsListEvents();
          query('.loaded-map .map-name').on('click', e => {
            if ((0, _jquery.default)(e.target).closest('.loaded-map').hasClass('selected')) {
              (0, _jquery.default)(e.target).closest('.loaded-map').removeClass('selected');
              (0, _jquery.default)('#mapsBlock').removeClass('has-selected');
              (0, _jquery.default)('.map-layers').addClass('hide');
            } else {
              this.send('trackEvent', 'Select User Map');
              (0, _jquery.default)('.loaded-map.selected').removeClass('selected');
              (0, _jquery.default)(e.target).closest('.loaded-map').addClass('selected');
              (0, _jquery.default)('#mapsBlock').addClass('has-selected');
              (0, _jquery.default)('.map-layers').removeClass('hide');
            }
          });
        };

        this.set('displayLoadedMaps', displayLoadedMaps);

        const receivePointGeodataAndGoToNewtStep = geom => {
          this.newOrder(geom.latitude, geom.longitude);
        }; // Load user web maps


        const portal = new Portal();
        portal.authMode = "immediate";
        portal.load().then(() => {
          let queryParams = {
            query: `owner:${portal.user.username} AND type: Web Map`,
            sortField: "title",
            sortOrder: "asc"
          };
          this.set('portal', portal);
          portal.queryItems(queryParams).then(results => {
            displayLoadedMaps(results, true);
          });
        });
        /****************************************************
           * Set up the map view
        ****************************************************/

        const polygonGraphicsLayer = new GraphicsLayer({
          listMode: 'hide'
        });

        if (Ember.isPresent(this.model.savedMapObject)) {
          polygonGraphicsLayer.add(this.get('model.savedMapObject'));
        }

        let map;

        if (Ember.isPresent(this.get('model.mission.lastEsriMapId'))) {
          map = new WebMap({
            portalItem: {
              id: this.get('model.mission.lastEsriMapId')
            }
          });
        } else {
          map = new Map({
            basemap: "topo-vector",
            layers: [polygonGraphicsLayer]
          });
        }

        if (map.isInstanceOf(WebMap)) {
          map.when(() => {
            this._view.viewpoint = map.initialViewProperties.viewpoint;
            map.remove(polygonGraphicsLayer);
            map.add(polygonGraphicsLayer);
          });
        }

        this._view = new MapView({
          container: "dbEsriMap",
          map: map,
          center: [-99.83059, 35.87093],
          zoom: 4
        });

        this._view.watch('updating', evt => {
          if (evt === true) {
            this.set('mapIsLoaded', false);
          } else {
            this.set('mapIsLoaded', true);
          }
        }); // create a collapsable layer list widget


        const layerList = new LayerList({
          container: document.createElement("div"),
          view: this._view
        }); // add the input form as a map view element

        const aoiElem = dom.byId("aoi-form");
        const aoiFormLayers = dom.byId("esri-layers-list-widget");
        domConstruct.place(layerList.domNode, aoiFormLayers);
        const aoiExpand = new Expand({
          expandIconClass: "esri-icon-layer-list",
          view: this._view,
          content: aoiElem,
          expanded: true,
          group: "top-left"
        }); // create a collapsable search widget

        const searchWidget = new Search({
          container: document.createElement("div"),
          view: this._view,
          popupOpenOnSelect: false
        });
        this.set('searchWidget', searchWidget);
        searchWidget.on('search-start', () => {
          this.send('trackEvent', 'Search for New Address');
        });
        searchWidget.on('suggest-complete', event => {
          let queryParams = {
            query: `owner:${this.portal.user.username} AND type: Web Map AND title: ${event.searchTerm}*`,
            sortField: "title",
            sortOrder: "asc"
          };
          this.portal.queryItems(queryParams).then(maps => {
            let mapsHtml;

            if (maps.results.length === 0) {
              mapsHtml = "";
            } else {
              mapsHtml = maps.results.map(m => {
                let result = `<div class='loaded-map' data-map-id='${m.id}'>\n`;
                result += "<div class='map-name'>\n";
                result += "<span class='dronebase-esri-icon-map'></span>\n";
                result += `<span class='map-name-title'>${m.title}</span>\n`;
                result += "</div>\n";
                result += "<div class='map-layers'></div>";
                result += '</div>';
                return result;
              }).join('\n');
            }

            const suggestions = document.getElementsByClassName('esri-search__suggestions-menu')[0];
            const mapsSearchBlock = document.getElementById('maps-search-block');

            if (mapsSearchBlock) {
              suggestions.removeChild(mapsSearchBlock);
            }

            let div = document.createElement('div');
            div.id = 'maps-search-block';
            div.className = 'maps-search-block';
            div.innerHTML = mapsHtml;
            suggestions.prepend(div);
            addMapsListEvents();
            query('.loaded-map .map-name').on('click', e => {
              if ((0, _jquery.default)(e.target).closest('.loaded-map').hasClass('selected')) {
                (0, _jquery.default)(e.target).closest('.loaded-map').removeClass('selected');
                (0, _jquery.default)('.map-layers').addClass('hide');
              } else {
                this.send('trackEvent', 'Select User Map');
                (0, _jquery.default)('.loaded-map.selected').removeClass('selected');
                (0, _jquery.default)(e.target).closest('.loaded-map').addClass('selected');
                (0, _jquery.default)('.map-layers').removeClass('hide');
              }
            });
          });
        }); // show Polygon drawer on search

        searchWidget.on("select-result", event => {
          this.set('isDrawingPolygon', true);
          [searchExpand, aoiExpand].forEach(expand => {
            expand.collapse();
          });
          var newOrderAction;
          newOrderAction = this._view.popup.actions.items.filter(item => {
            return item.id === 'newOrder';
          })[0];
          newOrderAction.visible = false;

          this._view.popup.open({
            location: event.result.feature.geometry,
            title: `<div class='esri-popup__formatted-title'>${event.result.name}</div>`,
            content: `<div class='esri-popup__formatted-content'><i class='esri-icon-polygon'></i><div>Please draw a ` + 'polygon that completely surrounds the building or property to shoot</div></div>'
          });

          sketchViewModel.create("polygon");
          this.set('model.savedMapObject', null);
        });
        const searchExpand = new Expand({
          expandIconClass: "esri-icon-search",
          view: this._view,
          content: searchWidget.domNode,
          group: "top-left"
        });
        /****************************************************
           * Initialize the drawing objects
        ****************************************************/
        // create the sketch view model

        const sketchViewModel = new SketchViewModel({
          view: this._view,
          layer: polygonGraphicsLayer,
          polygonSymbol: {
            type: "simple-fill",
            color: [63, 192, 194, 0.5],
            outline: {
              color: [2, 138, 255],
              width: 1
            }
          },
          defaultUpdateOptions: {
            enableRotation: false,
            enableScaling: false,
            multipleSelectionEnabled: false,
            toggleToolOnClick: false
          },
          updateOnGraphicClick: false
        }); // add a button to initiate drawing

        this._view.ui.add("select-by-polygon", "top-left");

        const drawPolygonButton = dom.byId("select-by-polygon"); // initiate drawing when the button is clicked

        drawPolygonButton.addEventListener("click", () => {
          this.set('isDrawingPolygon', true);
          [searchExpand, aoiExpand].forEach(expand => {
            expand.collapse();
          }); // this._view.popup.close();

          sketchViewModel.create("polygon");
          this.set('model.savedMapObject', null);
        });

        const setPolygonTitle = function (addressesAttributes, self) {
          if (addressesAttributes.length > 0) {
            self._view.popup.title = addressesAttributes[0].formatted_address;
          }
        }; // Listen to sketchViewModel's create event.


        sketchViewModel.on("create", event => {
          if (event.state === 'cancel') {
            this.set('isDrawingPolygon', false);
          }

          if (event.state === 'start') {
            this._view.popup.close();

            this.send('trackEvent', 'Draw New Polygon');
            this.set('isDrawingPolygon', true);
          }

          if (event.state === 'active') {
            this.set('isDrawingPolygon', true);
          }

          if (event.state === "complete") {
            this.set('isDrawingPolygon', false);

            const removePolygonAction = this._view.popup.actions.items.filter(item => {
              return item.id === 'removePolygon';
            })[0];

            const newOrderAction = this._view.popup.actions.items.filter(item => {
              return item.id === 'newOrder';
            })[0];

            removePolygonAction.visible = true;
            newOrderAction.visible = true; // we need this delay to Esri finish all processes with polygon

            Ember.run.later(() => {
              this._view.popup.open({
                location: event.mapPoint,
                features: [event.graphic],
                title: 'Polygon',
                highlightEnabled: false
              });

              this.addressFromCoordinates(event.graphic.geometry.centroid.latitude, event.graphic.geometry.centroid.longitude, setPolygonTitle);
            }, 500);
          }
        }); // In case if we will need to open the popup by click on custom polygon

        this._view.on('click', event => {
          const removePolygonAction = this._view.popup.actions.items.filter(item => {
            return item.id === 'removePolygon';
          })[0];

          if (removePolygonAction) {
            removePolygonAction.visible = false;
          }

          this._view.hitTest(event).then(response => {
            if (response.results.length) {
              const eventModel = response.results.filter(res => {
                return res.graphic.layer.type === 'graphics';
              })[0];

              if (eventModel) {
                if (removePolygonAction) {
                  removePolygonAction.visible = true;
                }

                this.send('trackEvent', 'Select Polygon');

                this._view.popup.open({
                  location: eventModel.mapPoint,
                  features: [eventModel.graphic],
                  title: 'Polygon',
                  highlightEnabled: false
                });

                this.addressFromCoordinates(eventModel.graphic.geometry.centroid.latitude, eventModel.graphic.geometry.centroid.longitude, setPolygonTitle);
              }
            }
          });
        });

        this._view.ui.add([searchExpand, aoiExpand], {
          position: "top-left",
          index: 0
        });

        this._view.when(() => {
          aoiElem.classList.remove('hide');
          aoiFormLayers.classList.remove('hide');
        }); // add ACTIONS (remove polygon, fly here)


        this._view.popup.actions.add({
          title: 'Remove',
          id: 'removePolygon',
          className: 'remove-user-draw-polygon',
          visible: false
        }); // Allow user to select displayed polygons through a popup (vs drawing)


        this._view.popup.actions.add({
          title: "Fly Here",
          id: "newOrder",
          className: 'order-drone-flight-action',
          visible: true
        });

        var zoomAction = this._view.popup.actions.items.filter(item => {
          return item.id === 'zoom-to-feature';
        })[0];

        this._view.popup.actions.remove(zoomAction);

        this._view.popup.on("trigger-action", event => {
          if (event.action.id === 'newOrder') {
            this.send('trackEvent', 'Clicked Fly Here');
            const selectGraphic = event.target.selectedFeature;
            let geom;

            if (selectGraphic.geometry.type === 'polyline') {
              geom = this.addPolylineToMissionLocationAndReturnCenter(selectGraphic.geometry);
              var simplePolylineSymbol = {
                type: "simple-line",
                color: [63, 192, 194, 0.5],
                outline: {
                  color: [2, 138, 255],
                  width: 2
                }
              };
              selectGraphic.symbol = simplePolylineSymbol;
            } else {
              geom = selectGraphic.geometry.centroid || selectGraphic.geometry;
            }

            if (selectGraphic.geometry.type === 'point') {
              var simpleMarkerSymbol = {
                type: "simple-marker",
                color: [63, 192, 194, 0.5],
                outline: {
                  color: [2, 138, 255],
                  width: 1
                }
              };
              selectGraphic.symbol = simpleMarkerSymbol;
            }

            if (selectGraphic.geometry.type === 'polygon') {
              this.addPolygonToMissionLocation(selectGraphic.geometry);
              var simpleFillSymbol = {
                type: "simple-fill",
                color: [63, 192, 194, 0.5],
                outline: {
                  color: [2, 138, 255],
                  width: 1
                }
              };
              selectGraphic.symbol = simpleFillSymbol;
            }

            this.set('model.savedMapObject', selectGraphic);
            Ember.run.later(() => {
              this._view.goTo(selectGraphic.geometry);

              this.set('model.savedMapZoom', this._view.zoom);
            }, 300);
            this.set('model.savedLocation', geom);
            receivePointGeodataAndGoToNewtStep(geom);
          }

          if (event.action.id === 'removePolygon') {
            const selectGraphic = event.target.selectedFeature;
            polygonGraphicsLayer.remove(selectGraphic);

            this._view.popup.close();
          }
        });
      });
    },
    tokenIsExpired: function () {
      return moment() - moment(this.get('esri_account.token_expiry_date')) > 0;
    },

    addPolylineToMissionLocationAndReturnCenter(polyline) {
      const polylinePoints = polyline.paths[0];
      const latLngPolylinePoints = this.convertToLatLng(polylinePoints, 'EPSG:3857');
      const bounds = this.receiveGeometryBounds(latLngPolylinePoints);
      const centroid = polyline.getPoint(0, parseInt(latLngPolylinePoints.length / 2, 10));
      const polylineProperties = {
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: latLngPolylinePoints
          },
          properties: {
            name: 'Line #1',
            notes: '',
            center: {
              lat: centroid.latitude,
              lng: centroid.longitude
            },
            bounds: bounds
          },
          id: 1
        }]
      };
      this.set('model.mission.geo_properties', polylineProperties);
      return centroid;
    },

    addPolygonToMissionLocation(polygon) {
      const polygonPoints = polygon.rings[0];
      const latLngPolygonPoints = this.convertToLatLng(polygonPoints, 'EPSG:3857');
      const bounds = this.receiveGeometryBounds(latLngPolygonPoints);
      const centroid = polygon.centroid;
      const googleLatLng = latLngPolygonPoints.map(point => {
        return new google.maps.LatLng({
          lat: point[1],
          lng: point[0]
        });
      });
      const polygonProperties = {
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [latLngPolygonPoints]
          },
          properties: {
            name: 'Polygon #1',
            notes: '',
            area: google.maps.geometry.spherical.computeArea(googleLatLng).toFixed(1),
            center: {
              lat: centroid.latitude,
              lng: centroid.longitude
            },
            bounds: bounds
          },
          id: 1
        }]
      };
      this.set('model.mission.geo_properties', polygonProperties);
    },

    convertToLatLng(points, fromFormat) {
      return points.map(point => {
        return (0, _proj.default)(fromFormat).inverse(point);
      });
    },

    receiveGeometryBounds(points) {
      const polygonLatitudes = points.map(point => {
        return point[0];
      });
      const polygonLongitudes = points.map(point => {
        return point[1];
      });
      const southPoint = Math.max(...polygonLongitudes);
      const westPoint = Math.max(...polygonLatitudes);
      const northPoint = Math.min(...polygonLongitudes);
      const eastPoint = Math.min(...polygonLatitudes);
      return {
        south: southPoint,
        west: westPoint,
        north: northPoint,
        east: eastPoint
      };
    },

    searchMapInputObserver: Ember.observer('searchMapInput', function () {
      let queryParams = {
        query: `owner:${this.portal.user.username} AND type: Web Map AND title: ${this.searchMapInput}*`,
        sortField: "title",
        sortOrder: "asc"
      };
      this.portal.queryItems(queryParams).then(results => {
        this.displayLoadedMaps(results, false);
      });
    }),
    actions: {
      toggleEsriTutorialModal() {
        this.toggleProperty('isShowTutorialModal');
      },

      backToEdit() {
        this.set('isShowMap', true);
        this.set('airspace', null);
      },

      next() {
        this.increaseOrderStepAction();
      },

      toggleEsriCollapse() {
        this.toggleProperty('isMapsListCollapsed');
      },

      changeMissionAddress(value) {
        this.set('preferredAddress.formatted_address', value);
        var city = this.get('model.location.city');

        if (value.indexOf(city) > 0) {
          this.addressFromString(value, results => {
            this.set('preferredAddress', results[0]);
            this.checkAirspace(this.get('preferredAddress.geometry.location').lat(), this.get('preferredAddress.geometry.location').lng());
            this.setModelAddress();
          });
        }
      },

      trackEvent: function (event) {
        this.metrics.trackEvent({
          event: 'event',
          category: 'ArcGIS Order Flow Map Tool',
          action: event,
          label: undefined
        });
      },
      searchAddresses: function () {
        if (this.get('model.location.formatted_address') != this.formattedAddressSuggest) {
          this.searchWidget.suggest(this.formattedAddressSuggest).then(response => {
            this.set('foundedAddressees', response.results[0].results);
            this.filterSearchResults();
          });
        }
      },
      clearSearchMapText: function () {
        this.set('searchMapInput', '');
      }
    }
  });

  _exports.default = _default;
});