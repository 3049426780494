define("clients/templates/components/user-hint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hCRsVJ4d",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"i\"],[14,0,\"fa fa-times user-hint\"],[12],[13],[2,\"\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "clients/templates/components/user-hint.hbs"
    }
  });

  _exports.default = _default;
});