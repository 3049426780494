define("clients/templates/components/mission-map-expanded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "863rmlmg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"map_container\"],[14,0,\"col-md-12 google-map-height-adjust\"],[12],[2,\"\\n  \"],[11,\"a\"],[24,0,\"close-map\"],[4,[38,0],[[32,0],\"toggleShowExpanded\"],null],[12],[10,\"i\"],[14,0,\"icon-Xmark\"],[12],[13],[13],[2,\"\\n  \"],[10,\"div\"],[14,1,\"map_expanded_canvas\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-map-expanded.hbs"
    }
  });

  _exports.default = _default;
});