define("clients/components/open-ortho", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    metrics: Ember.inject.service(),
    buttonText: null,
    classNames: ["asset-share"],
    store: Ember.inject.service(),
    shareLink: Ember.computed.or('share.link', 'proxyShareLink'),

    init() {
      this._super();

      this.currentShare;
    },

    currentShare: Ember.computed('ortho.shares', function () {
      //For now only support one share per shareable
      let share;

      if (!(share = this.ortho.get('shares.firstObject'))) {
        share = this.store.createRecord('share', {
          shareable: this.ortho
        });
        share.save();
        return share;
      } else {
        return this.ortho.get('shares.firstObject');
      }
    })
  });

  _exports.default = _default;
});