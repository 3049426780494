define("clients/templates/missions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aCUdGqS0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"mission_map_plan\"],[14,0,\"row google-map-height-adjust\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-12 google-map-height-adjust\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"model\",\"redirectAction\"],[[35,2],[30,[36,1],[[32,0],\"redirectToMission\"],[[\"target\"],[[35,0]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currentRoute\",\"action\",\"model\",\"mission-plan-map\"]}",
    "meta": {
      "moduleName": "clients/templates/missions/edit.hbs"
    }
  });

  _exports.default = _default;
});