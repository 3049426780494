define("clients/templates/components/mission-group/move-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ps6tF53f",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],null,[[\"title\",\"isSaving\"],[\"Move to Group\",[35,6]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    Please select a Group to add the (\"],[1,[35,0,[\"length\"]]],[2,\") selected Missions to:\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"group-input\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"content\",\"optionLabelPath\",\"action\",\"prompt\",\"selectClass\"],[[35,2],\"name\",[30,[36,1],[[32,0],\"selectGroup\"],null],\"Select\",\"form-control input-lg\"]]]],[2,\"\\n    \"],[8,\"button\",[[4,[38,1],[[32,0],\"openCreateModal\"],null]],[[\"@type\",\"@size\"],[\"link\",\"medium\"]],[[\"default\"],[{\"statements\":[[2,\"+ Create new Group\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"group-buttons\"],[12],[2,\"\\n      \"],[8,\"button\",[[4,[38,1],[[32,0],\"close\"],null]],[[\"@type\"],[\"secondary\"]],[[\"default\"],[{\"statements\":[[2,\"Cancel\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"button\",[[16,\"disabled\",[30,[36,5],[[35,4]],null]],[4,[38,1],[[32,0],\"save\"],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Confirm\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"selectedMissions\",\"action\",\"groups\",\"select-custom\",\"selectedGroup\",\"is-empty\",\"isSaving\",\"mission-group/base-modal\"]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-group/move-modal.hbs"
    }
  });

  _exports.default = _default;
});