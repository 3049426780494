define("clients/validations/client", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    'first_name': (0, _validators.validatePresence)(true),
    'last_name': (0, _validators.validatePresence)(true),
    'email': (0, _validators.validateFormat)({
      type: 'email'
    }),
    'company_name': (0, _validators.validatePresence)(true)
  };
  _exports.default = _default;
});