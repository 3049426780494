define("clients/components/mission-plan-shot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionPlanShotComponent;
  MissionPlanShotComponent = Ember.Component.extend({
    updateShot: function (id, name) {
      var _name;

      _name = Ember.$.trim(name);
      this.set('shot.name', _name);
      return this.set('shot.id', id);
    },
    actions: {
      remove_shot: function (shot) {
        return this.parentView.removeShot(shot);
      }
    }
  });
  var _default = MissionPlanShotComponent;
  _exports.default = _default;
});