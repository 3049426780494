define("clients/serializers/shareable", ["exports", "clients/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ShareableSerializer;
  ShareableSerializer = _application.default.extend({
    attrs: {
      shares: {
        deserialize: 'records'
      }
    }
  });
  var _default = ShareableSerializer;
  _exports.default = _default;
});