define("clients/components/ellipse-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EllipseCheckboxComponent;
  EllipseCheckboxComponent = Ember.Component.extend({
    value: false,
    label: "Change me",
    classes: '',
    actions: {
      toggleCheckbox: function () {
        return this.sendAction('toggle');
      }
    }
  });
  var _default = EllipseCheckboxComponent;
  _exports.default = _default;
});