define("clients/controllers/order-flow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      goToUpload(model) {
        return this.transitionToRoute('missions.upload', model.mission.id, {
          queryParams: {
            order_flow: true
          }
        });
      },

      closeInsightsBanner: function () {
        let client = this.get('model.client');
        client.set('feature_flags.show_insights_banner', false);
        client.save();
        this.get('controller').set('showInsightsBottomBanner', false);
      },
      goToInsights: function () {
        this.transitionTo('insights');
      },
      contactAboutInsights: function () {
        window.open('https://dronebase.com/insights-roof-reports#roof-report-pricing');
      }
    }
  });

  _exports.default = _default;
});