define("clients/services/cursor-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    position(element, event) {
      let totalOffsetX = 0;
      let totalOffsetY = 0;
      let canvasX = 0;
      let canvasY = 0;
      let currentElement = element;

      do {
        totalOffsetX += currentElement.offsetLeft - currentElement.scrollLeft;
        totalOffsetY += currentElement.offsetTop - currentElement.scrollTop;
        currentElement = currentElement.offsetParent;
      } while (currentElement);

      canvasX = event.pageX - totalOffsetX;
      canvasY = event.pageY - totalOffsetY;
      return {
        canvasX,
        canvasY
      };
    }

  });

  _exports.default = _default;
});