define("clients/components/user-hint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UserHintComponent;
  UserHintComponent = Ember.Component.extend({
    didInsertElement: function () {
      return Ember.run.schedule('afterRender', this, function () {
        this.$(".user-hint").click(function (e) {
          e.preventDefault();

          _this.$().toggleClass('user-hint-hidden');

          return _this.$(e.target).toggleClass('fa-question-circle').toggleClass('fa-times-circle').siblings("." + _this.get('hintTextClass')).toggleClass(_this.get('hintTextClass') + "-hidden");
        });
        return this.$(".user-hint").trigger('click');
      });
    },
    willClearRender: function () {
      return this.$(".user-hint").off('click');
    }
  });
  var _default = UserHintComponent;
  _exports.default = _default;
});