define("clients/templates/components/order-flow/confirmation-feature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9uJUJqmn",
    "block": "{\"symbols\":[\"catalogItem\"],\"statements\":[[10,\"span\"],[15,0,[31,[\"title \",[34,6]]]],[12],[2,\"\\n  \"],[1,[35,0,[\"name\"]]],[2,\"\\n\"],[6,[37,3],[[35,4,[\"altitude\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    / \"],[1,[30,[36,5],[[35,4,[\"altitude\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"span\"],[14,0,\"price\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,7],[[35,0,[\"price\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    (Included in the package)\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[35,0,[\"price\"]],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"      (\"],[1,[30,[36,1],[[35,0,[\"price\"]]],null]],[2,\")\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,10],[[30,[36,9],[[30,[36,9],[[35,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"catalog-item\"],[12],[2,\"- \"],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"feature\",\"format-dollar\",\"is-greater\",\"if\",\"panoDeliverableAttributes\",\"DBMap/format-altitude\",\"featureType\",\"is-empty\",\"catalogItems\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "clients/templates/components/order-flow/confirmation-feature.hbs"
    }
  });

  _exports.default = _default;
});