define("clients/components/quick-order", ["exports", "ember-data", "clients/mixins/google-map-init-mixin", "jquery"], function (_exports, _emberData, _googleMapInitMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var QuickOrderComponent;
  QuickOrderComponent = Ember.Component.extend(_googleMapInitMixin.default, {
    metrics: Ember.inject.service(),
    packages: Ember.inject.service(),
    store: Ember.inject.service(),
    classNameBindings: ['showQuickOrder:quick-order'],
    showQuickOrder: false,
    package_id: null,
    searchField: null,
    location: {
      longitude: null,
      latitude: null,
      name: null,
      address: null,
      city: null,
      state: null,
      country: null,
      postal_code: null
    },
    hasValidLocation: Ember.computed.notEmpty('location.name'),
    hasValidPackage: Ember.computed.notEmpty('package_id'),
    availablePackages: Ember.computed('packages', function () {
      return this.get('packages.all');
    }),
    setAddress: function (place) {
      var address_components, city, complete_address;
      this.set('location.longitude', place.geometry.location.lng());
      this.set('location.latitude', place.geometry.location.lat());
      complete_address = place.formatted_address;

      if (place.name && complete_address) {
        if (!complete_address.toLowerCase().lastIndexOf(place.name.toLowerCase(), 0) === 0) {
          complete_address = place.name + ", " + complete_address;
        }
      }

      this.set('location.name', complete_address);
      this.set('location.formatted_address', complete_address);
      this.set('searchField', complete_address);
      address_components = {};

      _jquery.default.each(place.address_components, function (k, v1) {
        return _jquery.default.each(v1.types, function (k2, v2) {
          if (v2 === 'country') {
            return address_components[v2] = v1.short_name;
          } else {
            return address_components[v2] = v1.long_name;
          }
        });
      });

      if (address_components.street_number && address_components.route) {
        this.set('location.address', address_components.street_number + " " + address_components.route);
      } else if (address_components.route) {
        this.set('location.address', "" + address_components.route);
      } else {
        this.set('location.address', '');
      }

      if (city = address_components.locality || address_components.administrative_area_level_3 || address_components.neighborhood) {
        this.set('location.city', city);
      } else {
        this.set('location.city', '');
      }

      if (address_components.administrative_area_level_1) {
        this.set('location.state', address_components.administrative_area_level_1);
      } else {
        this.set('location.state', '');
      }

      if (address_components.country) {
        this.set('location.country', address_components.country);
      } else {
        this.set('location.country', '');
      }

      if (address_components.postal_code) {
        this.set('location.postal_code', address_components.postal_code);
      } else {
        this.set('location.postal_code', '');
      }

      return this.$('.search-field').attr('placeholder', 'Enter Location');
    },
    findAddressForPlace: function (place) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var geocoder, lat_lng;
        lat_lng = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
        geocoder = new google.maps.Geocoder();
        return geocoder.geocode({
          'location': lat_lng
        }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results.length > 0) {
              return resolve(results[0]);
            } else {
              _this.get('metrics').trackEvent({
                event: 'event',
                category: 'Quick Order',
                action: 'click',
                label: 'Geo Locate - Geocoder Fail'
              });

              return reject('No results');
            }
          } else {
            _this.get('metrics').trackEvent({
              event: 'event',
              category: 'Quick Order',
              action: 'click',
              label: 'Geo Locate - Geocoder Fail'
            });

            return reject('geocoder is not reponding');
          }
        });
      });
    },
    initMap: function () {
      var _this, input, searchBox;

      _this = this;
      input = this.$('.search-field')[0];
      searchBox = new google.maps.places.SearchBox(input);
      input.addEventListener('keydown', function (e) {
        if (e.keyCode === 13) {
          return e.preventDefault();
        }
      });
      return searchBox.addListener('places_changed', function () {
        var place;

        _this.set('message', null);

        if (searchBox.getPlaces().length === 0) {
          _this.get('metrics').trackEvent({
            event: 'event',
            category: 'Quick Order',
            action: 'autocomplete',
            label: 'Location Search - No Address Found'
          });

          _this.set('message', 'We could not find a location based on your search.');

          return;
        }

        place = searchBox.getPlaces()[0];

        if (!place.address_components) {
          return _this.findAddressForPlace(place).then(function (response) {
            _this.set('message', 'We found a place close to your search. Please verify.');

            _this.get('metrics').trackEvent({
              event: 'event',
              category: 'Quick Order',
              action: 'autocomplete',
              label: 'Location Search - Found Address via geocode'
            });

            return _this.setAddress(response);
          }, function (response) {
            _this.get('metrics').trackEvent({
              event: 'event',
              category: 'Quick Order',
              action: 'autocomplete',
              label: 'Location Search - No Address Found via geocode'
            });

            _this.set('message', 'We could not find a location based on your search.');
          });
        } else {
          return _this.setAddress(place);
        }
      });
    },
    actions: {
      submitOrder: function () {
        var _location, _package, _this, location, package_id;

        if (!(this.get('hasValidPackage') && this.get('hasValidLocation'))) {
          return;
        }

        this.get('metrics').trackEvent({
          event: 'event',
          category: 'Quick Order',
          action: 'click',
          label: 'Submit Order'
        });
        _this = this;
        location = this.get('location');
        package_id = this.get('package_id');
        _location = this.get('store').createRecord('location', location);
        _package = this.get('store').peekRecord('package', package_id);
        return _location.save().then(function (location) {
          var mission;
          mission = _this.get('store').createRecord('mission');
          mission.set('location', location);
          mission.set('package', _package);
          return mission.save().then(function (mission) {
            return _this.sendAction('action', 'missions.show', mission.id);
          });
        });
      }
    }
  });
  var _default = QuickOrderComponent;
  _exports.default = _default;
});