define("clients/controllers/shares/pano", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['full_screen'],
    full_screen: null,
    init: function () {
      this._super();

      this.set('shareBarModel', this.store.createRecord('share-bar-model'));
      this.get('shareBarModel').set('shareUrl', window.location);
      this.get('shareBarModel').set('hasPano', true);
    },
    currentShare: Ember.computed.reads('model.share.shareable')
  });

  _exports.default = _default;
});