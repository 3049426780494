define("clients/adapters/cancellation", ["exports", "clients/adapters/application", "clients/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.namespace + '/clients'
  });

  _exports.default = _default;
});