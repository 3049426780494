define("clients/components/mission-map-expanded", ["exports", "clients/mixins/google-map-init-mixin", "clients/data/map_features"], function (_exports, _googleMapInitMixin, _map_features) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionMapExpandedComponent;
  MissionMapExpandedComponent = Ember.Component.extend(_googleMapInitMixin.default, {
    googleInit: Ember.inject.service(),
    geoJsonConverter: Ember.inject.service(),
    classNames: ['form-group', 'mission-map-expanded'],
    colorIndex: 0,
    mapStyles: _map_features.default.mapStylesData,
    featureColors: _map_features.default.featureColors,
    featureOptions: _map_features.default.featureOptions,
    disabledFeatureOptions: _map_features.default.disabledFeatureOptions,
    initMap: function () {
      var _this, geoProperties, latLng, mapOptions;

      _this = this;
      this.set('features', Ember.A([]));
      mapOptions = {
        zoom: 18,
        tilt: 0,
        streetViewControl: false,
        scaleControl: false,
        panControl: false,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.TOP_LEFT
        },
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_LEFT
        },
        draggable: true,
        scrollwheel: false,
        styles: this.mapStyles,
        fullscreenControl: false,
        mapTypeId: google.maps.MapTypeId['HYBRID']
      };

      if (!this.map) {
        this.map = new google.maps.Map(document.getElementById('map_expanded_canvas'), mapOptions);
      }

      this.map.data.addListener('addfeature', function (_this) {
        return function (event) {
          var options;
          options = _this.lookUpMapOptions(event.feature);
          Object.assign(options.defaultOptions, _this.get('disabledFeatureOptions'));

          _this.map.data.overrideStyle(event.feature, options.defaultOptions);

          return _this.map.data.overrideStyle(event.feature, {
            fillColor: options.mapColor,
            strokeColor: options.mapColor
          });
        };
      }(this));

      if (this.get('model.location.latitude') && this.get('model.location.longitude')) {
        latLng = new google.maps.LatLng({
          lat: Number(this.get('model.location.latitude')),
          lng: Number(this.get('model.location.longitude'))
        });
        this.map.setCenter(latLng);
        this.addMarker(this.map.getCenter());
      }

      if (this.get('model.map_features.length')) {
        geoProperties = this.geoJsonConverter.toGeoJson(this.get('model.map_features'));
        return this.addGeoJson(geoProperties);
      }
    },
    lookUpMapOptions: function (feature) {
      var geometryType, isNoFlyZone, keyColor, mapColor, options;
      geometryType = feature.getGeometry().getType();
      isNoFlyZone = feature.getProperty('type') === 'No Fly Zone' || feature.getProperty('no_fly_zone');

      options = function () {
        switch (geometryType) {
          case 'Polygon':
            if (isNoFlyZone) {
              return this.get('featureOptions').NOFLYZONE;
            } else {
              return this.get('featureOptions').PROPERTYAREA;
            }

            break;

          case 'LineString':
            return this.get('featureOptions').LINE;

          case 'Point':
            if (feature.getProperty('feature_type') === 'panorama') {
              return options = this.get('featureOptions').PANORAMA;
            } else {
              return options = this.get('featureOptions').POINTOFINTEREST;
            }

        }
      }.call(this);

      if (isNoFlyZone) {
        mapColor = '#F26649';
        keyColor = 'transparent';
      } else if (geometryType === 'LineString' || geometryType === 'Polygon') {
        mapColor = this.getNextColor();
      } else {
        mapColor = 'transparent';
      }

      options.mapColor = mapColor;
      options.keyColor = keyColor || mapColor;
      return options;
    },
    getNextColor: function (feature) {
      this.set('colorIndex', this.get('colorIndex') + 1);
      return this.get('featureColors')[this.get('colorIndex') % 5];
    },
    addMarker: function (position) {
      var icon, marker;
      icon = {
        url: '../assets/images/map-icon-pin.svg',
        scaledSize: new google.maps.Size(34, 34)
      };
      return marker = new google.maps.Marker({
        map: this.map,
        icon: icon,
        position: position,
        draggable: false,
        animation: google.maps.Animation.DROP
      });
    },
    addGeoJson: function (json) {
      var _this, bounds, features;

      _this = this;
      features = this.map.data.addGeoJson(json);
      bounds = new google.maps.LatLngBounds();
      features.forEach(function (feature) {
        if (feature.getGeometry().getType() === 'Polygon') {
          return feature.getGeometry().getArray().forEach(function (path) {
            return path.getArray().forEach(function (latLng) {
              return bounds.extend(latLng);
            });
          });
        } else if (feature.getGeometry().getType() === 'Point') {
          return bounds.extend(feature.getGeometry().get());
        } else if (feature.getGeometry().getType() === 'LineString') {
          return feature.getGeometry().getArray().forEach(function (latLng) {
            return bounds.extend(latLng);
          });
        }
      });
      return bounds;
    },
    actions: {
      toggleShowExpanded: function () {
        return this.set('hideExpandedMap', !this.get('hideExpandedMap'));
      }
    }
  });
  var _default = MissionMapExpandedComponent;
  _exports.default = _default;
});