define("clients/models/package", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    price: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string'),
    position: (0, _model.attr)('number'),
    cloud_reqs: (0, _model.attr)('string'),
    description_bullet_points: (0, _model.attr)('array'),
    sample_link: (0, _model.attr)('string'),
    order_flow: (0, _model.attr)('string'),
    vertical: (0, _model.belongsTo)('vertical', {
      async: false
    }),
    organization: (0, _model.belongsTo)('organization', {
      async: false
    }),
    client: (0, _model.belongsTo)('client', {
      async: true
    }),
    catalog_items: (0, _model.hasMany)('catalog_items', {
      async: false
    }),
    deliverable_work_types: (0, _model.hasMany)('deliverable_work_types', {
      async: false
    }),
    addons: (0, _model.hasMany)('package-addon', {
      async: false
    }),
    shot_list: (0, _model.belongsTo)('shot_list', {
      async: false
    }),
    currency: (0, _model.belongsTo)('currency', {
      async: false
    }),
    hasLineCatalogItem: Ember.computed('catalog_items', 'catalog_items.[]', function () {
      return this.get('catalog_items').filter(item => item.supported_geometry === "LineString").length > 0;
    }),
    priceInDollars: Ember.computed('price', function () {
      return this.price / 100;
    }),
    fullName: Ember.computed('name', 'vertical.short_name', function () {
      return this.vertical.short_name + " " + this.name;
    }),
    nameAndPrice: Ember.computed('priceInDollars', 'fullName', function () {
      return this.fullName + " - $" + this.priceInDollars;
    }),
    panoramaShotType: Ember.computed('shot_list.shot_types.length', function () {
      if (this.get('shot_list.shot_types.length')) {
        return this.shot_list.shot_types.find(shot_type => shot_type.template === 'pano_tiles');
      }

      return null;
    }),
    hasCatalogItems: Ember.computed.gt('catalog_items.length', 0),
    hasRoofReport: Ember.computed('deliverable_work_types.@each.name', function () {
      return this.deliverable_work_types.getEach('name').includes('Roof Report');
    })
  });

  _exports.default = _default;
});