define("clients/controllers/missions/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    actions: {
      goToUpload(model) {
        return this.transitionToRoute('missions.upload', model.mission.id, {
          queryParams: {
            order_flow: true
          }
        });
      }

    }
  });

  _exports.default = _default;
});