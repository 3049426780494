define("clients/utils/uploader/file_proxy", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global plupload */
  const mergeDefaults = function (defaults, options) {
    const unsetKeys = Ember.A(Object.keys(defaults)).removeObjects(Object.keys(options));
    const settings = Ember.copy(options, true);

    for (let key of Array.from(unsetKeys)) {
      settings[key] = defaults[key];
    }

    return settings;
  };

  const settingsToConfig = function (settings) {
    let filePrefix;
    let {
      url,
      method,
      accepts,
      contentType,
      headers,
      data,
      maxRetries,
      chunkSize,
      multipart,
      fileKey
    } = mergeDefaults({
      method: 'POST',
      accepts: ['application/json', 'text/javascript'],
      contentType: this.type,
      headers: {},
      data: {},
      maxRetries: 10,
      multipart: true,
      fileKey: 'file'
    }, settings);
    const dronebasePrefixes = {
      "image": "DBI",
      "video": "DBV"
    };

    if (headers.Accept === null) {
      if (!Ember.Array.detect(accepts)) {
        accepts = Ember.A([accepts]).compact();
      }

      headers.Accept = accepts.join(',');
    }

    if (contentType) {
      if (multipart) {
        data['Content-Type'] = contentType;
      } else {
        headers['Content-Type'] = contentType;
      }
    }

    let randomString = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let count = 0;

    while (count < 8) {
      randomString += possible.charAt(Math.floor(Math.random() * possible.length));
      count++;
    }

    const base_key = data.key;

    if (this.get('content.type')) {
      const fileType = this.get('content.type').split('/')[0];
      filePrefix = `${dronebasePrefixes[fileType]}-${this.uploadNumber}-${randomString}`;
    } else {
      filePrefix = `DBU-${this.uploadNumber}-${randomString}`;
    }

    const maxPrefix = this.maxFilenameLength - (this.sanitizedName.length + 1);

    if (filePrefix.length > maxPrefix) {
      filePrefix = filePrefix.substring(0, maxPrefix);
    }

    this.set('finalFilename', [filePrefix, this.sanitizedName].join('-'));
    data.key = data.key + this.finalFilename;
    console.log(base_key);
    return {
      url,
      method,
      headers,
      multipart,
      multipart_params: data,
      max_retries: maxRetries,
      chunk_size: chunkSize,
      file_data_name: fileKey,
      base_key
    };
  };

  const sanitizeFilename = function (filename, maxFilenameLength) {
    const name = filename.replace(/[^a-zA-Z\d\s\-_\.]/g, '').replace(/\s/g, '_');
    name;
    return checkFilenameLength(name, maxFilenameLength);
  };

  var checkFilenameLength = function (name, maxLength) {
    if (name.length > maxLength) {
      const extentionStarts = name.lastIndexOf('.');
      const extentionLength = name.length - extentionStarts;
      const maxNameLength = maxLength - extentionLength;
      return name.substring(0, maxNameLength) + name.substring(extentionStarts, name.length);
    } else {
      return name;
    }
  };

  const FileProxy = Ember.Object.extend({
    id: Ember.computed.reads('content.id'),
    name: Ember.computed.alias('content.name'),
    size: Ember.computed.reads('content.size'),
    type: Ember.computed.reads('content.type'),
    uploadNumber: null,
    maxFilenameLength: 200,
    sanitizedName: Ember.computed('content.name', 'maxFilenameLength', 'uploadNumber', function () {
      return sanitizeFilename(this.get('content.name'), this.maxFilenameLength);
    }),
    thumbnail: null,
    // eslint-disable-next-line ember/require-computed-property-dependencies
    progress: Ember.computed({
      get() {
        return this.get('content.percent');
      }

    }),
    // eslint-disable-next-line ember/require-computed-property-dependencies
    status: Ember.computed({
      get() {
        return this.get('content.status');
      }

    }),
    uploading: Ember.computed('status', function () {
      return this.status === plupload.UPLOADING;
    }),

    notifyPropertyChanges() {
      this.notifyPropertyChange('progress');
      return this.notifyPropertyChange('status');
    },

    prepareForUpload(urls, fileType, authHeaders) {
      const s3AuthUrl = urls['s3AuthUrl'];
      const fileCheckUrl = urls['fileCheckUrl'];
      this._deferred = Ember.RSVP.defer(`File: '${this.id}' Upload file`);

      if (this.status === plupload.FAILED) {
        this.set('content.status', plupload.QUEUED);
        this.notifyPropertyChange('status');
      }

      this.set('s3AuthUrl', s3AuthUrl);
      this.set('fileCheckUrl', fileCheckUrl);
      this.set('file_type', fileType);
      this.set('authHeaders', authHeaders);

      this._deferred.promise.then(function () {}, //NoOp
      error => console.log(`Upload error in ${error}`));

      return this._deferred.promise;
    },

    fetchS3SignedUrl(chunkedUpload, target_key) {
      const _this = this;

      const authPromise = _jquery.default.ajax({
        url: _this.get('s3AuthUrl'),
        type: 'POST',
        dataType: 'json',
        headers: _this.get('authHeaders'),
        data: {
          type: _this.get('file_type'),
          chunkedUpload
        }
      }).then(response => _this.settings = settingsToConfig.call(_this, {
        url: response.url,
        data: response.credentials
      }), response => console.log(`Error s3 signed URL: ${JSON.stringify(response.errors)}`));
      /* for chunked uploads, we want to perform the file existense check, before resolving the promise */


      if (chunkedUpload) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          authPromise.then(function (response) {
            _jquery.default.ajax({
              url: _this.get('fileCheckUrl'),
              type: 'GET',
              dataType: 'json',
              data: {
                key: `${response.base_key}${target_key}`
              },
              headers: _this.get('authHeaders')
            }).then(function (response2) {
              _this.settings.uploaded_chunks = response2.uploaded_keys;
              return resolve(response);
            }, function (response2) {
              console.log(`Error s3 signed URL: ${JSON.stringify(response2.errors)}`);
              return reject(response);
            });

            return false;
          }, response => reject(response));
          return false;
        });
      } else {
        return authPromise;
      }
    }

  });
  var _default = FileProxy;
  _exports.default = _default;
});