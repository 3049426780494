define("clients/adapters/pano_mission", ["exports", "clients/adapters/application", "clients/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PanoMissionAdapter;
  PanoMissionAdapter = _application.default.extend({
    namespace: _environment.default.api.namespace + '/clients'
  });
  var _default = PanoMissionAdapter;
  _exports.default = _default;
});