define("clients/mixins/s3-asset-upload", ["exports", "jquery", "clients/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    sessionAccount: Ember.inject.service(),
    headers: {},

    lookupAsset(fileType, file) {
      let asset = null; // can't peekRecord as it is not saved (odd that peekAll works.)

      this.store.peekAll(fileType).filter(function (a) {
        if (a.get('id') === file.get('id')) {
          return asset = a;
        }
      });
      return asset;
    },

    xhrAPISuccessResponse(asset, response) {
      let shot = null;

      if (asset.get('shot.id')) {
        shot = asset.get('shot');
      } else if (response.data.relationships.shot.data) {
        shot = this.get('store').peekRecord('shot', response.data.relationships.shot.data.id);
      }

      let newAsset = this.get('store').createRecord(asset.get('constructor.modelName'), Ember.merge(asset.toJSON(), {
        id: response.data.id,
        name: response.data.attributes.name,
        uploaded: true,
        nativeThumbnail: asset.nativeThumbnail,
        mission: asset.get('mission'),
        shot: shot
      }));
      asset.deleteRecord();

      if (newAsset.get('mission.id')) {
        newAsset.saveAsUpdate();
      }
    },

    xhrS3SuccessAPIRequest(asset, response, mission_id, shot_id, timecode, fileType, dateCreated) {
      if (asset) {
        // use setProperties to not trigger any ember data state changes
        asset.setProperties({
          uploading: true
        });
      }

      const url = `${_environment.default.api.host}/v1/clients/missions/${mission_id}/${fileType}s`;
      const uploadData = {
        url,
        type: 'POST',
        dataType: 'json',
        data: {
          shot_id,
          asset_url: unescape(response.full_url),
          chunked: response.chunked,
          base_key: unescape(response.base_key),
          source_type: 'edit',
          original_file_name: asset.get('file').get('name'),
          original_created_at: dateCreated
        },
        headers: this.get('sessionAccount.headers')
      };

      if (timecode) {
        uploadData.data.timecode = timecode;
      }

      return _jquery.default.ajax(uploadData);
    },

    actions: {
      addAsset(file, timecode) {
        const _this = this;

        const mission_id = this.get('mission.id');
        const shot_id = this.get('mission.clientUploadShot.id');
        const s3AuthUrl = `${_environment.default.api.host}/v1/clients/missions/${mission_id}/s3_upload_request`;
        const fileCheckUrl = `${_environment.default.api.host}/v1/clients/missions/${mission_id}/file_check`;

        if (file != null) {
          const fileType = file.get('type').match(/video|image/)[0];

          const asset = _this.lookupAsset(fileType, file);

          return file.prepareForUpload({
            s3AuthUrl,
            fileCheckUrl
          }, fileType, this.get('sessionAccount.headers')).then(function (response) {
            if (fileType === 'image') {
              const img = new mOxie.Image();

              img.onload = function () {
                let dateCreated;

                if (this.meta && this.meta.exif && this.meta.exif.DateTimeOriginal) {
                  let date = this.meta.exif.DateTimeOriginal;
                  date = date.replace(':', '/');
                  date = date.replace(':', '/');
                  date += " " + moment(asset.get('file').content.getSource().lastModifiedDate).format('ZZ');
                  dateCreated = moment(date).format('YYYY-MM-DD HH:mm:ss ZZ');
                } else {
                  dateCreated = moment(asset.get('file').content.getSource().lastModifiedDate).format('YYYY-MM-DD HH:mm:ss ZZ');
                }

                return _this.xhrS3SuccessAPIRequest(asset, response, mission_id, shot_id, timecode, fileType, dateCreated).then(function (response) {
                  img.destroy();

                  if (asset) {
                    _this.xhrAPISuccessResponse(asset, response);
                  }
                }, error => console.log(`Error posting upload to the api: ${JSON.stringify(error)}`));
              };

              img.onerror = function () {
                const dateCreated = moment(asset.get('file').content.getSource().lastModifiedDate).format('YYYY-MM-DD HH:mm:ss ZZ');
                return _this.xhrS3SuccessAPIRequest(asset, response, mission_id, shot_id, timecode, fileType, dateCreated).then(function (response) {
                  img.destroy();

                  if (asset) {
                    _this.xhrAPISuccessResponse(asset, response);
                  }
                }, error => console.log(`Error posting upload to the api: ${JSON.stringify(error)}`));
              };

              return img.load(asset.get('file').content.getSource());
            } else {
              const dateCreated = moment(asset.get('file').content.getSource().lastModifiedDate).format('YYYY-MM-DD HH:mm:ss ZZ');
              return _this.xhrS3SuccessAPIRequest(asset, response, mission_id, shot_id, timecode, fileType, dateCreated).then(function (response) {
                if (asset) {
                  _this.xhrAPISuccessResponse(asset, response);
                }
              }, error => console.log(`Error posting upload to the api: ${JSON.stringify(error)}`));
            }
          }, function (error) {
            let xmlDoc = _jquery.default.parseXML(error.response);

            if (xmlDoc) {
              const $xml = (0, _jquery.default)(xmlDoc);
              return console.log(`Error preparing file for upload:(xml) ${$xml.find('Error').text()}`);
            } else {
              return console.log(`Error preparing file for upload: ${JSON.stringify(error)}`);
            }
          });
        }
      },

      removeAsset(uploader, file) {
        return uploader.removeFile(file.content);
      },

      startUpload(uploader) {
        return uploader.start();
      },

      willTransition() {
        this.set('headers', {});
        return this.set('s3SignedUrls', {});
      }

    }
  });

  _exports.default = _default;
});