define("clients/data/sample_images/cre_images_regional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": [{
      "id": "DBI0258551092",
      "type": "image",
      "attributes": {
        "name": "DBI-15-yG7xN1eX-CRE-Regional-Center---Images-Package_DBI-12-DBU-29-3Aqq1tOp-DJI_0083-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021089-fdfed117ce9157aeaf6f11a2181496d9f10ee836/downloadable-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021089-fdfed117ce9157aeaf6f11a2181496d9f10ee836/large_1920-9381ddd567cc4c93c31a116da8e9494746210344.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4OS1mZGZlZDExN2NlOTE1N2FlYWY2ZjExYTIxODE0OTZkOWYxMGVlODM2L2xhcmdlXzE5MjAtOTM4MWRkZDU2N2NjNGM5M2MzMWExMTZkYThlOTQ5NDc0NjIxMDM0NC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4OS1mZGZlZDExN2NlOTE1N2FlYWY2ZjExYTIxODE0OTZkOWYxMGVlODM2L2xhcmdlXzE5MjAtOTM4MWRkZDU2N2NjNGM5M2MzMWExMTZkYThlOTQ5NDc0NjIxMDM0NC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:19:15Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3990349857",
      "type": "image",
      "attributes": {
        "name": "DBI-14-Bfa1LHcj-CRE-Regional-Center---Images-Package_DBI-9-DBU-26-9JPjFFF3-DJI_0088-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021088-fdfed117ce9157aeaf6f11a2181496d9f10ee836/downloadable-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021088-fdfed117ce9157aeaf6f11a2181496d9f10ee836/large_1920-87ee75e1a1ed3968636f6a94a5879539e923f8ed.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4OC1mZGZlZDExN2NlOTE1N2FlYWY2ZjExYTIxODE0OTZkOWYxMGVlODM2L2xhcmdlXzE5MjAtODdlZTc1ZTFhMWVkMzk2ODYzNmY2YTk0YTU4Nzk1MzllOTIzZjhlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4OC1mZGZlZDExN2NlOTE1N2FlYWY2ZjExYTIxODE0OTZkOWYxMGVlODM2L2xhcmdlXzE5MjAtODdlZTc1ZTFhMWVkMzk2ODYzNmY2YTk0YTU4Nzk1MzllOTIzZjhlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:19:15Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI0320895451",
      "type": "image",
      "attributes": {
        "name": "DBI-13-oPEjAqpk-CRE-Regional-Center---Images-Package_DBI-7-DBU-23-U1pJS1iQ-DJI_0094-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021087-a6c1bbae5facfddffb04ab55b328cf4251b388ee/downloadable-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021087-a6c1bbae5facfddffb04ab55b328cf4251b388ee/large_1920-7bc416f47dc7e3a609fab45bc0deb126fa2ef1ed.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4Ny1hNmMxYmJhZTVmYWNmZGRmZmIwNGFiNTViMzI4Y2Y0MjUxYjM4OGVlL2xhcmdlXzE5MjAtN2JjNDE2ZjQ3ZGM3ZTNhNjA5ZmFiNDViYzBkZWIxMjZmYTJlZjFlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4Ny1hNmMxYmJhZTVmYWNmZGRmZmIwNGFiNTViMzI4Y2Y0MjUxYjM4OGVlL2xhcmdlXzE5MjAtN2JjNDE2ZjQ3ZGM3ZTNhNjA5ZmFiNDViYzBkZWIxMjZmYTJlZjFlZC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:19:14Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6632239860",
      "type": "image",
      "attributes": {
        "name": "DBI-12-pYyLWHDa-CRE-Regional-Center---Images-Package_DBI-6-DBU-22-vkFgtxlh-DJI_0096-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021086-a6c1bbae5facfddffb04ab55b328cf4251b388ee/downloadable-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021086-a6c1bbae5facfddffb04ab55b328cf4251b388ee/large_1920-b36a59269151b78278571fc56570818271c1b0a7.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4Ni1hNmMxYmJhZTVmYWNmZGRmZmIwNGFiNTViMzI4Y2Y0MjUxYjM4OGVlL2xhcmdlXzE5MjAtYjM2YTU5MjY5MTUxYjc4Mjc4NTcxZmM1NjU3MDgxODI3MWMxYjBhNy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4Ni1hNmMxYmJhZTVmYWNmZGRmZmIwNGFiNTViMzI4Y2Y0MjUxYjM4OGVlL2xhcmdlXzE5MjAtYjM2YTU5MjY5MTUxYjc4Mjc4NTcxZmM1NjU3MDgxODI3MWMxYjBhNy5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:19:14Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI7310852668",
      "type": "image",
      "attributes": {
        "name": "DBI-11-sONNfeUY-CRE-Regional-Center---Images-Package_DBI-3-DBU-9-zay9WAga-DJI_0120-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021085-956461940ababed50c5fb1ad1fef8b3dc7196093/downloadable-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021085-956461940ababed50c5fb1ad1fef8b3dc7196093/large_1920-0e24e289bf015c6a9db312bebabecc7b66f3c3f5.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4NS05NTY0NjE5NDBhYmFiZWQ1MGM1ZmIxYWQxZmVmOGIzZGM3MTk2MDkzL2xhcmdlXzE5MjAtMGUyNGUyODliZjAxNWM2YTlkYjMxMmJlYmFiZWNjN2I2NmYzYzNmNS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4NS05NTY0NjE5NDBhYmFiZWQ1MGM1ZmIxYWQxZmVmOGIzZGM3MTk2MDkzL2xhcmdlXzE5MjAtMGUyNGUyODliZjAxNWM2YTlkYjMxMmJlYmFiZWNjN2I2NmYzYzNmNS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:19:13Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8334414843",
      "type": "image",
      "attributes": {
        "name": "DBI-10-97854Lb7-CRE-Regional-Center---Images-Package_DBI-2-DBU-7-zxNP2A12-DJI_0124-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021084-956461940ababed50c5fb1ad1fef8b3dc7196093/downloadable-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021084-956461940ababed50c5fb1ad1fef8b3dc7196093/large_1920-c52766fd7a624d1c864a6d0fd99c0f41435c029e.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4NC05NTY0NjE5NDBhYmFiZWQ1MGM1ZmIxYWQxZmVmOGIzZGM3MTk2MDkzL2xhcmdlXzE5MjAtYzUyNzY2ZmQ3YTYyNGQxYzg2NGE2ZDBmZDk5YzBmNDE0MzVjMDI5ZS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4NC05NTY0NjE5NDBhYmFiZWQ1MGM1ZmIxYWQxZmVmOGIzZGM3MTk2MDkzL2xhcmdlXzE5MjAtYzUyNzY2ZmQ3YTYyNGQxYzg2NGE2ZDBmZDk5YzBmNDE0MzVjMDI5ZS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:19:13Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI9683101024",
      "type": "image",
      "attributes": {
        "name": "DBI-9-mjTFtBND-CRE-Regional-Center---Images-Package_DBI-15-DBU-32-AaIO2KQt-DJI_0077-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021083-956461940ababed50c5fb1ad1fef8b3dc7196093/downloadable-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021083-956461940ababed50c5fb1ad1fef8b3dc7196093/large_1920-9d9d0fb94754d2a4a51d1bfc5ec510dfb7c47f58.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4My05NTY0NjE5NDBhYmFiZWQ1MGM1ZmIxYWQxZmVmOGIzZGM3MTk2MDkzL2xhcmdlXzE5MjAtOWQ5ZDBmYjk0NzU0ZDJhNGE1MWQxYmZjNWVjNTEwZGZiN2M0N2Y1OC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4My05NTY0NjE5NDBhYmFiZWQ1MGM1ZmIxYWQxZmVmOGIzZGM3MTk2MDkzL2xhcmdlXzE5MjAtOWQ5ZDBmYjk0NzU0ZDJhNGE1MWQxYmZjNWVjNTEwZGZiN2M0N2Y1OC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:19:13Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6155600222",
      "type": "image",
      "attributes": {
        "name": "DBI-8-2tyAECIe-CRE-Regional-Center---Images-Package_DBI-14-DBU-31-nDr9vnn7-DJI_0079-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021082-685dedf0433522b617f1c2fa0f3fe0bffb206bba/downloadable-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021082-685dedf0433522b617f1c2fa0f3fe0bffb206bba/large_1920-e33f4489e6379083c3757da9ea96ce1c7ca76728.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4Mi02ODVkZWRmMDQzMzUyMmI2MTdmMWMyZmEwZjNmZTBiZmZiMjA2YmJhL2xhcmdlXzE5MjAtZTMzZjQ0ODllNjM3OTA4M2MzNzU3ZGE5ZWE5NmNlMWM3Y2E3NjcyOC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4Mi02ODVkZWRmMDQzMzUyMmI2MTdmMWMyZmEwZjNmZTBiZmZiMjA2YmJhL2xhcmdlXzE5MjAtZTMzZjQ0ODllNjM3OTA4M2MzNzU3ZGE5ZWE5NmNlMWM3Y2E3NjcyOC5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:19:12Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI1264167062",
      "type": "image",
      "attributes": {
        "name": "DBI-7-uKTW7qvN-CRE-Regional-Center---Images-Package_DBI-11-DBU-28-iFOZ3lMK-DJI_0084-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021081-685dedf0433522b617f1c2fa0f3fe0bffb206bba/downloadable-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021081-685dedf0433522b617f1c2fa0f3fe0bffb206bba/large_1920-2a035f94e9a194cdaaa0ce784e12a3037a33b165.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4MS02ODVkZWRmMDQzMzUyMmI2MTdmMWMyZmEwZjNmZTBiZmZiMjA2YmJhL2xhcmdlXzE5MjAtMmEwMzVmOTRlOWExOTRjZGFhYTBjZTc4NGUxMmEzMDM3YTMzYjE2NS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4MS02ODVkZWRmMDQzMzUyMmI2MTdmMWMyZmEwZjNmZTBiZmZiMjA2YmJhL2xhcmdlXzE5MjAtMmEwMzVmOTRlOWExOTRjZGFhYTBjZTc4NGUxMmEzMDM3YTMzYjE2NS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:19:12Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI5968025748",
      "type": "image",
      "attributes": {
        "name": "DBI-6-tOmjfmFS-CRE-Regional-Center---Images-Package_DBI-10-DBU-27-tedIIENP-DJI_0086-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021080-685dedf0433522b617f1c2fa0f3fe0bffb206bba/downloadable-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021080-685dedf0433522b617f1c2fa0f3fe0bffb206bba/large_1920-70dae9b24835c1d8aa4d7588db56c275300c137b.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4MC02ODVkZWRmMDQzMzUyMmI2MTdmMWMyZmEwZjNmZTBiZmZiMjA2YmJhL2xhcmdlXzE5MjAtNzBkYWU5YjI0ODM1YzFkOGFhNGQ3NTg4ZGI1NmMyNzUzMDBjMTM3Yi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA4MC02ODVkZWRmMDQzMzUyMmI2MTdmMWMyZmEwZjNmZTBiZmZiMjA2YmJhL2xhcmdlXzE5MjAtNzBkYWU5YjI0ODM1YzFkOGFhNGQ3NTg4ZGI1NmMyNzUzMDBjMTM3Yi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:19:12Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3549421714",
      "type": "image",
      "attributes": {
        "name": "DBI-5-3HxXhZdb-CRE-Regional-Center---Images-Package_DBI-8-DBU-25-SCuaiqDO-DJI_0090-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021079-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/downloadable-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021079-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/large_1920-0bc1e7ffdf65476f641b9f61afbcecb86034bc49.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA3OS1mNmEzZDFiNDIzYWQ0NWRjYmY4MzQ3NWZiMjc3ZGM2MDY0ZGQ2ZGEzL2xhcmdlXzE5MjAtMGJjMWU3ZmZkZjY1NDc2ZjY0MWI5ZjYxYWZiY2VjYjg2MDM0YmM0OS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA3OS1mNmEzZDFiNDIzYWQ0NWRjYmY4MzQ3NWZiMjc3ZGM2MDY0ZGQ2ZGEzL2xhcmdlXzE5MjAtMGJjMWU3ZmZkZjY1NDc2ZjY0MWI5ZjYxYWZiY2VjYjg2MDM0YmM0OS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:19:11Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4820957677",
      "type": "image",
      "attributes": {
        "name": "DBI-4-0ssGaiMq-CRE-Regional-Center---Images-Package_DBI-5-DBU-19-Qq63FFL2-DJI_0101-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021078-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/downloadable-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021078-f6a3d1b423ad45dcbf83475fb277dc6064dd6da3/large_1920-f2a127d1a5a3cf94a9a29dfd814afeb71b89c68a.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA3OC1mNmEzZDFiNDIzYWQ0NWRjYmY4MzQ3NWZiMjc3ZGM2MDY0ZGQ2ZGEzL2xhcmdlXzE5MjAtZjJhMTI3ZDFhNWEzY2Y5NGE5YTI5ZGZkODE0YWZlYjcxYjg5YzY4YS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA3OC1mNmEzZDFiNDIzYWQ0NWRjYmY4MzQ3NWZiMjc3ZGM2MDY0ZGQ2ZGEzL2xhcmdlXzE5MjAtZjJhMTI3ZDFhNWEzY2Y5NGE5YTI5ZGZkODE0YWZlYjcxYjg5YzY4YS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:19:11Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4782435528",
      "type": "image",
      "attributes": {
        "name": "DBI-3-ijrvURwK-CRE-Regional-Center---Images-Package_DBI-1-DBU-2-pXdHUx0i-DJI_0134-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021077-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/downloadable-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021077-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/large_1920-3bcfc577ee4d515e10ba3683378218a94149b702.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA3Ny1iZDY0N2Y3ZThhYTk5YjBhZjhjNDAxYzQzMTQyN2E1MzgxZmQ2YmQyL2xhcmdlXzE5MjAtM2JjZmM1NzdlZTRkNTE1ZTEwYmEzNjgzMzc4MjE4YTk0MTQ5YjcwMi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA3Ny1iZDY0N2Y3ZThhYTk5YjBhZjhjNDAxYzQzMTQyN2E1MzgxZmQ2YmQyL2xhcmdlXzE5MjAtM2JjZmM1NzdlZTRkNTE1ZTEwYmEzNjgzMzc4MjE4YTk0MTQ5YjcwMi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:19:09Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8651571151",
      "type": "image",
      "attributes": {
        "name": "DBI-2-tSzHIopR-CRE-Regional-Center---Images-Package_DBI-13-DBU-30-qk1GYZQK-DJI_0081-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021076-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/downloadable-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021076-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/large_1920-0e6df631deeead8a09b232196eb84352b9ddb26b.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA3Ni1iZDY0N2Y3ZThhYTk5YjBhZjhjNDAxYzQzMTQyN2E1MzgxZmQ2YmQyL2xhcmdlXzE5MjAtMGU2ZGY2MzFkZWVlYWQ4YTA5YjIzMjE5NmViODQzNTJiOWRkYjI2Yi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA3Ni1iZDY0N2Y3ZThhYTk5YjBhZjhjNDAxYzQzMTQyN2E1MzgxZmQ2YmQyL2xhcmdlXzE5MjAtMGU2ZGY2MzFkZWVlYWQ4YTA5YjIzMjE5NmViODQzNTJiOWRkYjI2Yi5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:19:09Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI7022167498",
      "type": "image",
      "attributes": {
        "name": "DBI-1-PyLlS7HV-CRE-Regional-Center---Images-Package_DBI-4-DBU-12-A8Pl3OqY-DJI_0115-images-cardinal-directions-350-ft-above-ground-level.jpeg",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/10021075-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/downloadable-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10021075-bd647f7e8aa99b0af8c401c431427a5381fd6bd2/large_1920-5397b37b5102ff3e5e0744b8c62408f0206bedc9.jpeg",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA3NS1iZDY0N2Y3ZThhYTk5YjBhZjhjNDAxYzQzMTQyN2E1MzgxZmQ2YmQyL2xhcmdlXzE5MjAtNTM5N2IzN2I1MTAyZmYzZTVlMDc0NGI4YzYyNDA4ZjAyMDZiZWRjOS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6Imluc2lkZSIsImhlaWdodCI6NjQwLCJ3aWR0aCI6NjQwfX19",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy8xMDAyMTA3NS1iZDY0N2Y3ZThhYTk5YjBhZjhjNDAxYzQzMTQyN2E1MzgxZmQ2YmQyL2xhcmdlXzE5MjAtNTM5N2IzN2I1MTAyZmYzZTVlMDc0NGI4YzYyNDA4ZjAyMDZiZWRjOS5qcGVnIiwiZWRpdHMiOnsicmVzaXplIjp7ImZpdCI6ImNvbnRhaW4iLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ=="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-12-04T19:19:09Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM2326557882",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1061032",
            "type": "shot"
          }
        }
      }
    }]
  };
  _exports.default = _default;
});