define("clients/helpers/DBMap/meters-to-feet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.metersToFeet = _exports.default = void 0;

  const metersToFeet = function () {
    const length = arguments.length <= 0 ? undefined : arguments[0];

    if (length != null && typeof length != 'undefined') {
      const val = Math.round(length * 3.2808).toLocaleString();
      return `${val} feet`;
    } else {
      return '';
    }
  };

  _exports.metersToFeet = metersToFeet;

  var _default = Ember.Helper.helper(metersToFeet);

  _exports.default = _default;
});