define("clients/components/charts/turnaround-chart", ["exports", "clients/components/charts/base-chart"], function (_exports, _baseChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TotalFlightsChart;
  TotalFlightsChart = _baseChart.default.extend({
    classNames: ['client-chart turnaround-chart'],
    title: 'Days average turnaround time',
    containerName: 'turnaround-chart',
    chartColor: 'rgba(62, 190, 192, 0.5)',
    chartType: 'column',
    tooltipOptions: {
      borderColor: "rgba(62, 190, 192)",
      backgroundColor: 'white',
      style: {
        color: '#1d2d47'
      },
      formatter: function () {
        return Highcharts.dateFormat('%b %d, %Y', this.x) + '<br>' + this.y + ' days';
      }
    },
    didInsertElement: function () {
      var chart;
      chart = this._super();
      return this.set('chart', chart);
    }
  });
  var _default = TotalFlightsChart;
  _exports.default = _default;
});