define("clients/controllers/missions/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    submited: false,
    sessionAccount: Ember.inject.service(),
    missionsLink: Ember.computed('account.{is_insights_buildings,organization.id}', function () {
      if (this.get('account.is_insights_buildings')) {
        // eslint-disable-next-line no-undef
        return ENV.insights_endpoint + "/inspections?iorder=desc&ipage=1&isize=25&isortby=inspectionStart&vid=" + this.get('account.organization.id');
      } else {
        return '/locations';
      }
    }),
    actions: {
      newOrder() {
        // Make sure we reload the page to clear data
        if (window.location.pathname == '/new_order') {
          window.location.reload();
          window.location.href = "";
        }

        window.location = '/new_order';
      },

      goToMission(missionId) {
        return this.transitionToRoute('missions.show', missionId);
      },

      submitMission() {
        return this.set('submited', true);
      }

    }
  });

  _exports.default = _default;
});