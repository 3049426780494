define("clients/components/charts/total-flights-chart", ["exports", "clients/components/charts/base-chart"], function (_exports, _baseChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseChart.default.extend({
    classNames: ['client-chart total-flights-chart'],
    title: '<span data-toggle="tooltip" data-placement="top" data-container="body" ' + 'title="Total number of orders that pilots have flown, uploaded assets, and are ready to download."> ' + 'Orders Completed ' + '<i class="fa fa-question-circle"></i> </span>',
    containerName: 'flights-chart',
    tooltipOptions: {
      backgroundColor: 'white',
      style: {
        color: '#1d2d47'
      },
      formatter: function () {
        let pluralEnd;

        if (this.y === 1) {
          pluralEnd = '';
        } else {
          pluralEnd = 's';
        }

        return Highcharts.dateFormat('%b %d, %Y', this.x) + '<br>' + this.y + ' order' + pluralEnd;
      }
    },
    didInsertElement: function () {
      const chart = this._super();

      return this.set('chart', chart);
    }
  });

  _exports.default = _default;
});