define("clients/routes/client", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "clients/mixins/inner-application-route-setup-controller-mixin"], function (_exports, _unauthenticatedRouteMixin, _innerApplicationRouteSetupControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClientRoute;
  ClientRoute = Ember.Route.extend(_unauthenticatedRouteMixin.default, _innerApplicationRouteSetupControllerMixin.default, {
    session: Ember.inject.service(),
    model: function () {
      return this.store.createRecord('client');
    },
    setupController: function (controller, model) {
      return this._super(controller, model);
    },
    actions: {
      save: function (model) {
        var _this;

        _this = this;
        return model.save().then(function () {
          var credentials;
          credentials = {
            identification: model.get('email')
          };
          return _this.get('session').authenticate('authenticator:devise', credentials);
        });
      }
    }
  });
  var _default = ClientRoute;
  _exports.default = _default;
});