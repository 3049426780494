define("clients/components/mission-group/remove-from-group-modal", ["exports", "clients/components/mission-group/base-modal", "jquery", "clients/config/environment"], function (_exports, _baseModal, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const RemoveFromMissionGroupModalComponent = _baseModal.default.extend({
    GMTEventAttrs: {
      label: 'Remove from to group'
    },
    saveRecord: function () {
      let data = {
        missions_to_remove: this.selectedMissions.map(mission => mission.get('id'))
      };
      return _jquery.default.ajax({
        url: `${_environment.default.api.host}/v3/clients/mission_groups/${this.get('group.id')}`,
        headers: this.get('sessionAccount.headers'),
        type: 'PUT',
        data: data,
        dataType: 'json'
      });
    }
  });

  var _default = RemoveFromMissionGroupModalComponent;
  _exports.default = _default;
});