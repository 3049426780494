define("clients/components/suggested-group-item", ["exports", "jquery", "clients/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    metrics: Ember.inject.service(),
    classNames: ['mission-list-item-container'],
    isSaving: false,
    group_action: function (request) {
      this.set('isSaving', true);
      request().then(() => {
        this.set('isSaving', false);
        this.reloadModelAction();
      }, () => {
        this.set('isSaving', false);
        alert('Sorry, there was an error saving your changes.');
      });
    },
    actions: {
      keepSuggestegGroup: function () {
        this.set('isSaving', true);
        this.store.createRecord('mission-group', {
          name: this.get('group.name'),
          mission_ids: this.get('group.mission_ids')
        }).save().then(response => {
          this.metrics.trackEvent({
            event: 'event',
            action: 'click',
            category: 'Dashboard',
            objectType: 'missionGroup',
            objectId: response.id,
            label: 'Create new suggested mission group'
          });
          this.set('isSaving', false);
          this.reloadModelAction();
        }, () => {
          this.set('isSaving', false);
          alert('Sorry, there was an error saving your changes.');
        });
      },
      removeSuggestedGroup: function () {
        this.set('isSaving', true);
        let data = {
          name: this.get('group.name')
        };
        return _jquery.default.ajax({
          url: `${_environment.default.api.host}/v3/clients/suggested_groups`,
          headers: this.get('sessionAccount.headers'),
          type: 'DELETE',
          data: data,
          dataType: 'json'
        }).then(() => {
          this.set('isSaving', false);
          this.reloadModelAction();
        }, () => {
          this.set('isSaving', false);
          alert('Sorry, there was an error saving your changes.');
        });
      }
    }
  });

  _exports.default = _default;
});