define("clients/helpers/active-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.activeClass = void 0;
  var ActiveClassHelper, activeClass;
  _exports.activeClass = activeClass;

  _exports.activeClass = activeClass = function (arg) {
    var a, b;
    a = arg[0], b = arg[1];

    if (a === b) {
      return 'active';
    }
  };

  ActiveClassHelper = Ember.Helper.helper(activeClass);
  var _default = ActiveClassHelper;
  _exports.default = _default;
});