define("clients/data/sample_missions/rre_starter_package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": {
      "id": "DBM4930292865",
      "type": "missions",
      "attributes": {
        "instructions": null,
        "status": "complete",
        "client_id": "DBC3006554",
        "payment": {
          "id": null,
          "processor": null,
          "card": null
        },
        "external_assets_url": null,
        "owned_by_current_client": true,
        "contact_email": "clients@zeitview.com",
        "created_on": "2019-12-02T22:53:38Z",
        "is_pano_mission": false,
        "logo_url": null,
        "blurb": null,
        "paid": true,
        "paid_on": "2019-12-02T23:11:41Z",
        "price": 26900,
        "archive_url": null,
        "edit_url": null,
        "customer_instructions": "Weekend only. Fly between 12 \u2013 2 pm.",
        "scheduled_at_start": "2019-12-07T17:00:00Z",
        "scheduled_at_end": "2019-12-07T18:00:00Z",
        "parent_id": null,
        "reshoot_mission_id": null,
        "rejection_reason": null,
        "rejection_notes": null,
        "reference_id": "RRE Starter Sample Mission",
        "cloud_reqs": "less_than_50",
        "scheduling_requirement": "not_needed",
        "arcgis_feature_layer": null,
        "geo_properties": {}
      },
      "relationships": {
        "location": {
          "data": {
            "id": "284849",
            "type": "locations"
          }
        },
        "package": {
          "data": {
            "id": "20039",
            "type": "packages"
          }
        },
        "credit_card": {
          "data": null
        },
        "onsite_contact": {
          "data": {
            "id": "49441",
            "type": "onsite_contacts"
          }
        },
        "scheduling_contact": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "images": {
          "data": [{
            "id": "DBI7267645428",
            "type": "images"
          }, {
            "id": "DBI5053415798",
            "type": "images"
          }, {
            "id": "DBI7475659806",
            "type": "images"
          }, {
            "id": "DBI1488550160",
            "type": "images"
          }, {
            "id": "DBI2133540155",
            "type": "images"
          }, {
            "id": "DBI9063953685",
            "type": "images"
          }, {
            "id": "DBI6131290743",
            "type": "images"
          }, {
            "id": "DBI2728011740",
            "type": "images"
          }, {
            "id": "DBI0308550610",
            "type": "images"
          }, {
            "id": "DBI4513507924",
            "type": "images"
          }, {
            "id": "DBI6474875722",
            "type": "images"
          }, {
            "id": "DBI0732352285",
            "type": "images"
          }, {
            "id": "DBI1215508812",
            "type": "images"
          }, {
            "id": "DBI1286169930",
            "type": "images"
          }, {
            "id": "DBI9611859391",
            "type": "images"
          }]
        },
        "videos": {
          "data": [{
            "id": "DBV6079903380",
            "type": "videos"
          }, {
            "id": "DBV8810567272",
            "type": "videos"
          }, {
            "id": "DBV6547383241",
            "type": "videos"
          }, {
            "id": "DBV4294547083",
            "type": "videos"
          }, {
            "id": "DBV9304501999",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        },
        "attachments": {
          "data": []
        },
        "ortho_photo_maps": {
          "data": []
        },
        "shares": {
          "data": [{
            "id": "e580cbf691d904d79f5de528da47bf63",
            "type": "shares"
          }, {
            "id": "aab115b2aef7dcab67843dafc71b984a",
            "type": "shares"
          }]
        },
        "main_image": {
          "data": {
            "id": "DBI7267645428",
            "type": "images"
          }
        },
        "collaborators": {
          "data": []
        },
        "parent": {
          "data": null
        },
        "shots": {
          "data": [{
            "id": "1059966",
            "type": "shots"
          }, {
            "id": "1059965",
            "type": "shots"
          }, {
            "id": "1059964",
            "type": "shots"
          }, {
            "id": "1059963",
            "type": "shots"
          }, {
            "id": "1059962",
            "type": "shots"
          }, {
            "id": "1059961",
            "type": "shots"
          }, {
            "id": "1059960",
            "type": "shots"
          }, {
            "id": "1059959",
            "type": "shots"
          }, {
            "id": "1059958",
            "type": "shots"
          }]
        },
        "invoice_line_items": {
          "data": [{
            "id": "DBINV531544499433907",
            "type": "invoice_line_items"
          }]
        }
      }
    },
    "included": [{
      "id": "284849",
      "type": "locations",
      "attributes": {
        "name": "19 Misty Vale Rd, Sandy Hook, CT 06482, USA",
        "latitude": 41.4123638,
        "longitude": -73.2534763,
        "address": "19 Misty Vale Road",
        "address2": null,
        "city": "Newtown",
        "state": "Connecticut",
        "postal_code": "06482",
        "country": "United States",
        "formatted_address": "19 Misty Vale Rd, Sandy Hook, CT 06482, USA",
        "timezone_id": "America/New_York",
        "properties": {}
      }
    }, {
      "id": "20039",
      "type": "packages",
      "attributes": {
        "name": "RRE Starter Package Sample ",
        "description": null,
        "price": 26900,
        "cloud_reqs": "less_than_50",
        "position": null,
        "slug": "rre-starter-pack"
      },
      "relationships": {
        "vertical": {
          "data": {
            "id": "2",
            "type": "verticals"
          }
        },
        "organization": {
          "data": null
        },
        "client": {
          "data": {
            "id": "DBC3006554",
            "type": "clients"
          }
        },
        "catalog_items": {
          "data": []
        }
      }
    }, {
      "id": "2",
      "type": "verticals",
      "attributes": {
        "name": "Residential Real Estate",
        "short_name": "RRE"
      }
    }, {
      "id": "49441",
      "type": "onsite_contacts",
      "attributes": {
        "name": null,
        "call_action": null,
        "phone": null,
        "note": null
      }
    }, {
      "id": "DBC3006554",
      "type": "clients",
      "attributes": {
        "first_name": "Demo",
        "last_name": "Zeitview",
        "phone": "",
        "email": "clients@zeitview.com",
        "token": "qC65CqCmbKfMZvIU2gRb_uD7Sqw",
        "company_name": null,
        "invoiceable": false
      },
      "relationships": {
        "hidden_sample_missions": {
          "data": [{
            "id": "*",
            "type": "hidden_sample_mission"
          }]
        }
      }
    }, {
      "id": "DBI7267645428",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10002881-b1a8f34d25d386665f6de98cff4a9d6eb12e701e/original-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
        "name": "DBI-15-cyJ8ZSAX-DBUV011-DBM0011938845-100-06.JPG",
        "source_type": "edit",
        "formatted_size": "4.67 MB",
        "size": 4894254,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355383333333,
        "gps_longitude": -73.395783333333,
        "gps_altitude": 142.2,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002881-b1a8f34d25d386665f6de98cff4a9d6eb12e701e/large_1920-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10002881-b1a8f34d25d386665f6de98cff4a9d6eb12e701e/medium_1024-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10002881-b1a8f34d25d386665f6de98cff4a9d6eb12e701e/small_640-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10002881-b1a8f34d25d386665f6de98cff4a9d6eb12e701e/four_three_640-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10002881-b1a8f34d25d386665f6de98cff4a9d6eb12e701e/square_640-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10002881-b1a8f34d25d386665f6de98cff4a9d6eb12e701e/downloadable-00cd69329d79865f6ca14b43646035f23b1dc70d.JPG"
        },
        "created_on": "2019-12-02T23:00:26Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI5053415798",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10002879-7b8fade7a4f2ce94d034beb36b73608a5b383804/original-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
        "name": "DBI-14-59uTf3fD-DBU14-DBM0011938845_100_08.JPG",
        "source_type": "edit",
        "formatted_size": "4.52 MB",
        "size": 4737475,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355919444444,
        "gps_longitude": -73.396211111111,
        "gps_altitude": 157.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002879-7b8fade7a4f2ce94d034beb36b73608a5b383804/large_1920-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10002879-7b8fade7a4f2ce94d034beb36b73608a5b383804/medium_1024-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10002879-7b8fade7a4f2ce94d034beb36b73608a5b383804/small_640-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10002879-7b8fade7a4f2ce94d034beb36b73608a5b383804/four_three_640-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10002879-7b8fade7a4f2ce94d034beb36b73608a5b383804/square_640-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10002879-7b8fade7a4f2ce94d034beb36b73608a5b383804/downloadable-9b87707d2633e0a4bb9ae8a366dc9caa0456bed5.JPG"
        },
        "created_on": "2019-12-02T23:00:20Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI7475659806",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10002878-cc27296a97fc19573a80cd6593b5c43a7622070f/original-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
        "name": "DBI-13-PLuoy2Ue-DBU13-DBM0011938845_100_07.JPG",
        "source_type": "edit",
        "formatted_size": "4.42 MB",
        "size": 4638336,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355772222222,
        "gps_longitude": -73.396416666667,
        "gps_altitude": 145.3,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002878-cc27296a97fc19573a80cd6593b5c43a7622070f/large_1920-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10002878-cc27296a97fc19573a80cd6593b5c43a7622070f/medium_1024-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10002878-cc27296a97fc19573a80cd6593b5c43a7622070f/small_640-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10002878-cc27296a97fc19573a80cd6593b5c43a7622070f/four_three_640-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10002878-cc27296a97fc19573a80cd6593b5c43a7622070f/square_640-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10002878-cc27296a97fc19573a80cd6593b5c43a7622070f/downloadable-d7a242b2002cf3726162aea6b4d21db6d341cb7e.JPG"
        },
        "created_on": "2019-12-02T23:00:19Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI1488550160",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10002877-cc27296a97fc19573a80cd6593b5c43a7622070f/original-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
        "name": "DBI-12-gJzOgwAa-DBU11-DBM0011938845_100_05.JPG",
        "source_type": "edit",
        "formatted_size": "4.63 MB",
        "size": 4855984,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355555555556,
        "gps_longitude": -73.396355555556,
        "gps_altitude": 142,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002877-cc27296a97fc19573a80cd6593b5c43a7622070f/large_1920-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10002877-cc27296a97fc19573a80cd6593b5c43a7622070f/medium_1024-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10002877-cc27296a97fc19573a80cd6593b5c43a7622070f/small_640-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10002877-cc27296a97fc19573a80cd6593b5c43a7622070f/four_three_640-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10002877-cc27296a97fc19573a80cd6593b5c43a7622070f/square_640-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10002877-cc27296a97fc19573a80cd6593b5c43a7622070f/downloadable-617881437b45d0e676b73754a6d8e5f7d64bcc2f.JPG"
        },
        "created_on": "2019-12-02T23:00:19Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2133540155",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10002876-f8fd117ed02c9c85e2ce00616fa36574cc005d70/original-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
        "name": "DBI-11-cDJAk0PO-DBU10-DBM0011938845_100_04.JPG",
        "source_type": "edit",
        "formatted_size": "4.56 MB",
        "size": 4781833,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.3555,
        "gps_longitude": -73.396011111111,
        "gps_altitude": 138.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002876-f8fd117ed02c9c85e2ce00616fa36574cc005d70/large_1920-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10002876-f8fd117ed02c9c85e2ce00616fa36574cc005d70/medium_1024-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10002876-f8fd117ed02c9c85e2ce00616fa36574cc005d70/small_640-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10002876-f8fd117ed02c9c85e2ce00616fa36574cc005d70/four_three_640-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10002876-f8fd117ed02c9c85e2ce00616fa36574cc005d70/square_640-522213e7e7d5eb7e7652b936adf67054e121094a.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10002876-f8fd117ed02c9c85e2ce00616fa36574cc005d70/downloadable-522213e7e7d5eb7e7652b936adf67054e121094a.JPG"
        },
        "created_on": "2019-12-02T23:00:18Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9063953685",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10002875-f8fd117ed02c9c85e2ce00616fa36574cc005d70/original-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
        "name": "DBI-9-GG9BpHr1-DBU8-DBM0011938845_100_02.JPG",
        "source_type": "edit",
        "formatted_size": "4.37 MB",
        "size": 4582821,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355719444444,
        "gps_longitude": -73.395705555556,
        "gps_altitude": 123.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002875-f8fd117ed02c9c85e2ce00616fa36574cc005d70/large_1920-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10002875-f8fd117ed02c9c85e2ce00616fa36574cc005d70/medium_1024-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10002875-f8fd117ed02c9c85e2ce00616fa36574cc005d70/small_640-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10002875-f8fd117ed02c9c85e2ce00616fa36574cc005d70/four_three_640-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10002875-f8fd117ed02c9c85e2ce00616fa36574cc005d70/square_640-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10002875-f8fd117ed02c9c85e2ce00616fa36574cc005d70/downloadable-c61464eeb0288129c75b5f23df2a133759aa3a87.JPG"
        },
        "created_on": "2019-12-02T23:00:18Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6131290743",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10002874-ba8e022bcace437eb012c696aa9f708d0fd4c704/original-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
        "name": "DBI-10-pes5qTL6-DBU9-DBM0011938845_100_03.JPG",
        "source_type": "edit",
        "formatted_size": "4.51 MB",
        "size": 4726912,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355333333333,
        "gps_longitude": -73.396083333333,
        "gps_altitude": 140.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002874-ba8e022bcace437eb012c696aa9f708d0fd4c704/large_1920-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10002874-ba8e022bcace437eb012c696aa9f708d0fd4c704/medium_1024-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10002874-ba8e022bcace437eb012c696aa9f708d0fd4c704/small_640-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10002874-ba8e022bcace437eb012c696aa9f708d0fd4c704/four_three_640-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10002874-ba8e022bcace437eb012c696aa9f708d0fd4c704/square_640-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10002874-ba8e022bcace437eb012c696aa9f708d0fd4c704/downloadable-bb47db3ffe763c1ad98e77f70e6396f34adc09a8.JPG"
        },
        "created_on": "2019-12-02T23:00:18Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI2728011740",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10002872-feb60d516e8eef99872e7b60c9d1abe1484cdcd7/original-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
        "name": "DBI-8-NqvnpVWG-DBU7-DBM0011938845_100_01.JPG",
        "source_type": "edit",
        "formatted_size": "4.42 MB",
        "size": 4638672,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355897222222,
        "gps_longitude": -73.396075,
        "gps_altitude": 149.3,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002872-feb60d516e8eef99872e7b60c9d1abe1484cdcd7/large_1920-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10002872-feb60d516e8eef99872e7b60c9d1abe1484cdcd7/medium_1024-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10002872-feb60d516e8eef99872e7b60c9d1abe1484cdcd7/small_640-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10002872-feb60d516e8eef99872e7b60c9d1abe1484cdcd7/four_three_640-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10002872-feb60d516e8eef99872e7b60c9d1abe1484cdcd7/square_640-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10002872-feb60d516e8eef99872e7b60c9d1abe1484cdcd7/downloadable-76ab0370a84ea2d72de5a44fb0fc00700b1d5530.JPG"
        },
        "created_on": "2019-12-02T23:00:16Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059959",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0308550610",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10002870-ca7b313340c30a56e5ea6f079300995b0015f474/original-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
        "name": "DBI-7-zr795osN-DBU15-DBM0011938845_Birds_Eye.JPG",
        "source_type": "edit",
        "formatted_size": "4.58 MB",
        "size": 4801035,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355583333333,
        "gps_longitude": -73.396008333333,
        "gps_altitude": 216.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002870-ca7b313340c30a56e5ea6f079300995b0015f474/large_1920-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10002870-ca7b313340c30a56e5ea6f079300995b0015f474/medium_1024-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10002870-ca7b313340c30a56e5ea6f079300995b0015f474/small_640-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10002870-ca7b313340c30a56e5ea6f079300995b0015f474/four_three_640-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10002870-ca7b313340c30a56e5ea6f079300995b0015f474/square_640-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10002870-ca7b313340c30a56e5ea6f079300995b0015f474/downloadable-1edd976f2078ab87bb0267f9ae404d9a1b774f05.JPG"
        },
        "created_on": "2019-12-02T23:00:13Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059958",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI4513507924",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10002869-ca7b313340c30a56e5ea6f079300995b0015f474/original-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
        "name": "DBI-6-nYDsWRFb-DBU6-DBM0011938845_10_06.JPG",
        "source_type": "edit",
        "formatted_size": "4.5 MB",
        "size": 4718345,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355719444444,
        "gps_longitude": -73.396205555556,
        "gps_altitude": 119.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002869-ca7b313340c30a56e5ea6f079300995b0015f474/large_1920-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10002869-ca7b313340c30a56e5ea6f079300995b0015f474/medium_1024-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10002869-ca7b313340c30a56e5ea6f079300995b0015f474/small_640-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10002869-ca7b313340c30a56e5ea6f079300995b0015f474/four_three_640-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10002869-ca7b313340c30a56e5ea6f079300995b0015f474/square_640-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10002869-ca7b313340c30a56e5ea6f079300995b0015f474/downloadable-2ceea92eaa5005d5029b058106e99e8e7b0d365c.JPG"
        },
        "created_on": "2019-12-02T23:00:13Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059958",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI6474875722",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10002868-111362a32e6b85a66554d44b48f70ae98a55a9c3/original-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
        "name": "DBI-5-SHIakN7h-DBU5-DBM0011938845_10_05.JPG",
        "source_type": "edit",
        "formatted_size": "4.35 MB",
        "size": 4565311,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355588888889,
        "gps_longitude": -73.396216666667,
        "gps_altitude": 119.1,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002868-111362a32e6b85a66554d44b48f70ae98a55a9c3/large_1920-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10002868-111362a32e6b85a66554d44b48f70ae98a55a9c3/medium_1024-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10002868-111362a32e6b85a66554d44b48f70ae98a55a9c3/small_640-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10002868-111362a32e6b85a66554d44b48f70ae98a55a9c3/four_three_640-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10002868-111362a32e6b85a66554d44b48f70ae98a55a9c3/square_640-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10002868-111362a32e6b85a66554d44b48f70ae98a55a9c3/downloadable-e6d7efa985cfe822193552ce5f4190eaa19c5ca2.JPG"
        },
        "created_on": "2019-12-02T23:00:12Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059958",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI0732352285",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10002867-111362a32e6b85a66554d44b48f70ae98a55a9c3/original-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
        "name": "DBI-4-zG2C3bAz-DBU4-DBM0011938845_10_04.JPG",
        "source_type": "edit",
        "formatted_size": "4.34 MB",
        "size": 4550027,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355433333333,
        "gps_longitude": -73.396030555556,
        "gps_altitude": 116.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002867-111362a32e6b85a66554d44b48f70ae98a55a9c3/large_1920-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10002867-111362a32e6b85a66554d44b48f70ae98a55a9c3/medium_1024-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10002867-111362a32e6b85a66554d44b48f70ae98a55a9c3/small_640-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10002867-111362a32e6b85a66554d44b48f70ae98a55a9c3/four_three_640-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10002867-111362a32e6b85a66554d44b48f70ae98a55a9c3/square_640-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10002867-111362a32e6b85a66554d44b48f70ae98a55a9c3/downloadable-dbf5b90bcbe82441b39412018edadbde8dbe1637.JPG"
        },
        "created_on": "2019-12-02T23:00:12Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059958",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI1215508812",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10002866-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/original-91116a8f05403c970ead6159446a774f35a328aa.JPG",
        "name": "DBI-3-TSjOtv2d-DBU3-DBM0011938845_10_03.JPG",
        "source_type": "edit",
        "formatted_size": "4.3 MB",
        "size": 4506834,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355472222222,
        "gps_longitude": -73.395783333333,
        "gps_altitude": 116.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002866-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/large_1920-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10002866-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/medium_1024-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10002866-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/small_640-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10002866-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/four_three_640-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10002866-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/square_640-91116a8f05403c970ead6159446a774f35a328aa.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10002866-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/downloadable-91116a8f05403c970ead6159446a774f35a328aa.JPG"
        },
        "created_on": "2019-12-02T23:00:11Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059958",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI1286169930",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10002865-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/original-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
        "name": "DBI-2-suCDiKmD-DBU2-DBM0011938845_10_02.JPG",
        "source_type": "edit",
        "formatted_size": "4.06 MB",
        "size": 4257980,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355738888889,
        "gps_longitude": -73.395716666667,
        "gps_altitude": 115.6,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002865-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/large_1920-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10002865-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/medium_1024-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10002865-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/small_640-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10002865-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/four_three_640-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10002865-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/square_640-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10002865-d47b1f2700d66bbd1256c8cdb1c663c21fc5b557/downloadable-08e88a6d95ec87db5dae2825abce66d3c7068d15.JPG"
        },
        "created_on": "2019-12-02T23:00:11Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059958",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBI9611859391",
      "type": "images",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "url": "https://cdn.dronebase.com/assets/mission/images/10002864-3fa5c0dd4de7618f61362d63172a69bf8667befe/original-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
        "name": "DBI-1-S7zPb7Hj-DBU1-DBM0011938845_10_01.JPG",
        "source_type": "edit",
        "formatted_size": "4.22 MB",
        "size": 4422001,
        "height": 3000,
        "width": 4000,
        "resolution": 72,
        "gps_latitude": 41.355761111111,
        "gps_longitude": -73.396094444445,
        "gps_altitude": 115.9,
        "share_token": null,
        "version_urls": {
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/10002864-3fa5c0dd4de7618f61362d63172a69bf8667befe/large_1920-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "medium_1024": "https://cdn.dronebase.com/assets/mission/images/10002864-3fa5c0dd4de7618f61362d63172a69bf8667befe/medium_1024-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "small_640": "https://cdn.dronebase.com/assets/mission/images/10002864-3fa5c0dd4de7618f61362d63172a69bf8667befe/small_640-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "small_43_640": "https://cdn.dronebase.com/assets/mission/images/10002864-3fa5c0dd4de7618f61362d63172a69bf8667befe/four_three_640-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "square_640": "https://cdn.dronebase.com/assets/mission/images/10002864-3fa5c0dd4de7618f61362d63172a69bf8667befe/square_640-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG",
          "download": "https://cdn.dronebase.com/assets/mission/images/10002864-3fa5c0dd4de7618f61362d63172a69bf8667befe/downloadable-d2b7819934d9396e8268a1337988d0b7bc121e10.JPG"
        },
        "created_on": "2019-12-02T23:00:10Z"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059958",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV6079903380",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-20-sJ1Ie9Dm-DL-DBUV000-DBM0011938845-Custom.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174327-2024a703981f3c2ba4df82b88a6b9483f8aa0ee3/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174327-2024a703981f3c2ba4df82b88a6b9483f8aa0ee3/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174327-2024a703981f3c2ba4df82b88a6b9483f8aa0ee3/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174327-2024a703981f3c2ba4df82b88a6b9483f8aa0ee3/DL-DBV-20-sJ1Ie9Dm-DL-DBUV000-DBM0011938845-Custom.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059964",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV8810567272",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-19-rNGGmfIC-DL-DBUV003-DBM0011938845-Rise--Reverse.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174326-59f97334358e2162fbc456fb36c784ba22b79744/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174326-59f97334358e2162fbc456fb36c784ba22b79744/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174326-59f97334358e2162fbc456fb36c784ba22b79744/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174326-59f97334358e2162fbc456fb36c784ba22b79744/DL-DBV-19-rNGGmfIC-DL-DBUV003-DBM0011938845-Rise--Reverse.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059963",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV6547383241",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-18-7Evlh8X2-DL-DBUV002-DBM0011938845-Orbit.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174325-d35d3ca77c96236fabb8fb5972d7612f3784b1d8/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174325-d35d3ca77c96236fabb8fb5972d7612f3784b1d8/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174325-d35d3ca77c96236fabb8fb5972d7612f3784b1d8/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174325-d35d3ca77c96236fabb8fb5972d7612f3784b1d8/DL-DBV-18-7Evlh8X2-DL-DBUV002-DBM0011938845-Orbit.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059962",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV4294547083",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-17-ENlGjcdo-DL-DBUV004-DBM0011938845-Trucking.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174324-67a2661237bd2a340baf8c66c7ccbd542a02a861/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174324-67a2661237bd2a340baf8c66c7ccbd542a02a861/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174324-67a2661237bd2a340baf8c66c7ccbd542a02a861/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174324-67a2661237bd2a340baf8c66c7ccbd542a02a861/DL-DBV-17-ENlGjcdo-DL-DBUV004-DBM0011938845-Trucking.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059961",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "DBV9304501999",
      "type": "videos",
      "attributes": {
        "processing_status": "ready",
        "processing": false,
        "name": "DBV-16-lPiocWmG-DL-DBUV001-DBM0011938845-Dolly.MOV",
        "source_type": "edit",
        "formatted_size": null,
        "share_token": null,
        "version_urls": {
          "poster_image": "https://cdn.dronebase.com/assets/mission/videos/174323-ddad05e7adc00ab869861212b231358fd7ef7629/poster_0000.jpg",
          "mp4_med": "https://cdn.dronebase.com/assets/mission/videos/174323-ddad05e7adc00ab869861212b231358fd7ef7629/720-med.mp4",
          "hls": "https://cdn.dronebase.com/assets/mission/videos/174323-ddad05e7adc00ab869861212b231358fd7ef7629/playlist.m3u8",
          "download": "https://cdn.dronebase.com/assets/mission/videos/174323-ddad05e7adc00ab869861212b231358fd7ef7629/DL-DBV-16-lPiocWmG-DL-DBUV001-DBM0011938845-Dolly.MOV"
        },
        "thumbnail_timecode": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "shot": {
          "data": {
            "id": "1059960",
            "type": "shots"
          }
        }
      }
    }, {
      "id": "e580cbf691d904d79f5de528da47bf63",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-04T23:28:06Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "aab115b2aef7dcab67843dafc71b984a",
      "type": "shares",
      "attributes": {
        "logo": null,
        "blurb": null,
        "created_on": "2019-12-04T23:25:08Z"
      },
      "relationships": {
        "shareable": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        }
      }
    }, {
      "id": "1059966",
      "type": "shots",
      "attributes": {
        "instructions": "(1) image from from above looking straight down at the property with the full home/lot in frame. ",
        "mission_id": "277977",
        "shot_type_id": 18,
        "pilot_comment": "none needed ",
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Image - Birds Eye "
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "18",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "18",
      "type": "shot_types",
      "attributes": {
        "name": "Image - Birds Eye ",
        "description": "",
        "video": null,
        "slug": "image-birds-eye-photo-looking-straight-down-entire-property-in-frame",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "12",
            "type": "shot_type_sample_images"
          }, {
            "id": "13",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1059965",
      "type": "shots",
      "attributes": {
        "instructions": null,
        "mission_id": "277977",
        "shot_type_id": 22,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Final Assets"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "22",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "22",
      "type": "shot_types",
      "attributes": {
        "name": "Final Assets",
        "description": "Use this shot to upload final edited assets for the customer. Not for use by pilots.\n",
        "video": null,
        "slug": "final-assets",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1059964",
      "type": "shots",
      "attributes": {
        "instructions": "Cinematic Video clip (of pool, back yard, etc.)",
        "mission_id": "277977",
        "shot_type_id": 11,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Custom Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "11",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV6079903380",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "11",
      "type": "shot_types",
      "attributes": {
        "name": "Custom Shot",
        "description": "",
        "video": "",
        "slug": "custom-shot",
        "camera_requirement": null
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1059963",
      "type": "shots",
      "attributes": {
        "instructions": "Video clip: Rise + Reverse Away facing the front of the subject property.  \n",
        "mission_id": "277977",
        "shot_type_id": 10,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Rise + Reverse Away Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "10",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV8810567272",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "10",
      "type": "shot_types",
      "attributes": {
        "name": "Rise + Reverse Away Shot",
        "description": "",
        "video": "https://youtu.be/PwOvzZqQWkg",
        "slug": "rise-reverse-away-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1059962",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Contextual Orbit shot around property (counterclockwise)  \n*Must have entire subject property in frame and your camera must be angled up enough so that we also have the horizon in frame.* The point is to provide context of the property in relation to the surrounding area. This will require you to fly at a higher altitude and farther off the property to get the required shot. Typically shot around 100 ft. Orbit should be slow + steady. No jerky camera movements.  \n",
        "mission_id": "277977",
        "shot_type_id": 8,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Orbit Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "8",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV6547383241",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "8",
      "type": "shot_types",
      "attributes": {
        "name": "Orbit Shot",
        "description": "",
        "video": "https://youtu.be/MzdF92gWXyU",
        "slug": "orbit-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1059961",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Truck (*right to left*) across the front of the property. The front of the property should take up the frame from ground to top of roof. Adjust distance accordingly.  \n",
        "mission_id": "277977",
        "shot_type_id": 2,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Trucking Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "2",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV4294547083",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "2",
      "type": "shot_types",
      "attributes": {
        "name": "Trucking Shot",
        "description": "",
        "video": "https://youtu.be/m-r79WAcDNs",
        "slug": "trucking-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1059960",
      "type": "shots",
      "attributes": {
        "instructions": "Video Clip: Slowly fly towards the front of the property at a constant altitude (10 feet over roof level) with your camera pointing straight (can have slight angle downward if needed). Keep the horizon flat. Fly completely over property and the end of the property lot.  \n",
        "mission_id": "277977",
        "shot_type_id": 1,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Dolly Shot"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "1",
            "type": "shot_types"
          }
        },
        "images": {
          "data": []
        },
        "videos": {
          "data": [{
            "id": "DBV9304501999",
            "type": "videos"
          }]
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "1",
      "type": "shot_types",
      "attributes": {
        "name": "Dolly Shot",
        "description": "",
        "video": "https://youtu.be/H80sEIUHa7Y",
        "slug": "dolly-shot",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": []
        }
      }
    }, {
      "id": "1059959",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and your camera must be angled up enough so that we have the horizon in frame.*  \n",
        "mission_id": "277977",
        "shot_type_id": 13,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (100 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "13",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI2728011740",
            "type": "images"
          }, {
            "id": "DBI9063953685",
            "type": "images"
          }, {
            "id": "DBI6131290743",
            "type": "images"
          }, {
            "id": "DBI7475659806",
            "type": "images"
          }, {
            "id": "DBI1488550160",
            "type": "images"
          }, {
            "id": "DBI5053415798",
            "type": "images"
          }, {
            "id": "DBI7267645428",
            "type": "images"
          }, {
            "id": "DBI2133540155",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "13",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (100 ft above ground level)",
        "description": "",
        "video": "",
        "slug": "images-cardinal-directions-100-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "17",
            "type": "shot_type_sample_images"
          }, {
            "id": "18",
            "type": "shot_type_sample_images"
          }, {
            "id": "19",
            "type": "shot_type_sample_images"
          }, {
            "id": "20",
            "type": "shot_type_sample_images"
          }, {
            "id": "21",
            "type": "shot_type_sample_images"
          }, {
            "id": "22",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "1059958",
      "type": "shots",
      "attributes": {
        "instructions": "(8) images taken from equal increments around the property (N, NW, W, etc). *We need the full subject property in frame and you may need to adjust altitude as you work around obstacles (trees, power lines, etc).*  \n",
        "mission_id": "277977",
        "shot_type_id": 12,
        "pilot_comment": null,
        "post_processing_status": "queued",
        "origin_type": "package",
        "name": "Images - Cardinal Directions (10 ft above ground level)"
      },
      "relationships": {
        "shot_type": {
          "data": {
            "id": "12",
            "type": "shot_types"
          }
        },
        "images": {
          "data": [{
            "id": "DBI1286169930",
            "type": "images"
          }, {
            "id": "DBI1215508812",
            "type": "images"
          }, {
            "id": "DBI0732352285",
            "type": "images"
          }, {
            "id": "DBI0308550610",
            "type": "images"
          }, {
            "id": "DBI4513507924",
            "type": "images"
          }, {
            "id": "DBI6474875722",
            "type": "images"
          }, {
            "id": "DBI9611859391",
            "type": "images"
          }]
        },
        "videos": {
          "data": []
        },
        "panoramas": {
          "data": []
        }
      }
    }, {
      "id": "12",
      "type": "shot_types",
      "attributes": {
        "name": "Images - Cardinal Directions (10 ft above ground level)",
        "description": "*If there are power lines do your best to avoid having them in frame. It is okay to fly higher or lower than roof level to avoid the power lines in the images.* ",
        "video": "",
        "slug": "images-cardinal-directions-10-ft-above-ground-level",
        "camera_requirement": "drone_camera"
      },
      "relationships": {
        "sample_images": {
          "data": [{
            "id": "35",
            "type": "shot_type_sample_images"
          }, {
            "id": "36",
            "type": "shot_type_sample_images"
          }, {
            "id": "37",
            "type": "shot_type_sample_images"
          }, {
            "id": "38",
            "type": "shot_type_sample_images"
          }, {
            "id": "39",
            "type": "shot_type_sample_images"
          }]
        }
      }
    }, {
      "id": "DBINV531544499433907",
      "type": "invoice_line_items",
      "attributes": {
        "reason": "base_package_price",
        "description": null,
        "amount": 26900,
        "created_on": "2019-12-02T22:53:38Z",
        "display_name": "Base Package Price"
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM4930292865",
            "type": "missions"
          }
        },
        "work_item": {
          "data": null
        }
      }
    }]
  };
  _exports.default = _default;
});