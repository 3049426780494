define("clients/router", ["exports", "clients/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    metrics: Ember.inject.service(),
    rootURL: _environment.default.rootURL,
    headData: Ember.inject.service(),

    setTitle(title) {
      this.headData.set('title', "Zeitview");
    },

    trackMetric() {
      const page = this.url;
      const title = page;
      return this.metrics.trackPage('Logger', {
        page,
        title
      });
    },

    routeDidChange() {
      return Ember.run.scheduleOnce('afterRender', this, this.trackMetric());
    }

  });
  Router.map(function () {
    this.route('login'); // eslint-disable-next-line ember/no-capital-letters-in-routes

    this.route('authenticationCallback');
    this.route('register');
    this.route('shares.pano', {
      path: 'p/:token'
    });
    this.route('shares.mission', {
      path: 'm/:token'
    });
    this.route('shares.image', {
      path: 'i/:token'
    });
    this.route('shares.video', {
      path: 'v/:token'
    });
    this.route('shares.ortho', {
      path: 'o/:token'
    });
    this.route('account', function () {
      return this.route('edit', {
        path: '/'
      });
    });
    this.route('organization');
    this.route('dashboard', {
      path: '/'
    }, function () {
      return this.route('locations', function () {
        return this.route('index', {
          path: '/'
        });
      });
    });
    this.route('charts', {
      path: '/analytics'
    });
    this.route('missions.edit', {
      path: 'orders/:mission_id/edit'
    });
    this.route('missions.show', {
      path: 'orders/:mission_id'
    });
    this.route('missions.receipt', {
      path: 'orders/:mission_id/receipt'
    });
    this.route('missions.new', {
      path: 'book/:package'
    });
    this.route('missions.upload', {
      path: 'orders/:mission_id/upload'
    });
    this.route('register');
    this.route('insights'); // eslint-disable-next-line ember/no-capital-letters-in-routes

    this.route('fourOhFour', {
      path: '*path'
    });
    this.route('sample-mission', {
      path: 'sample-mission/:mission_id'
    });
    return this.route('order-flow', {
      path: 'new_order'
    });
  });
  var _default = Router;
  _exports.default = _default;
});