define("clients/mixins/google-map-init-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GoogleMapInitMixin = Ember.Mixin.create({
    googleInit: Ember.inject.service(),
    didInsertElement: function () {
      return Ember.run.scheduleOnce('afterRender', this, this.loadMap);
    },
    loadMap: function () {
      if (this.googleInit.loaded) {
        return this.initMap();
      } else {
        this.googleInit.loadMap();
        return Ember.run.later(this, this.loadMap, 100);
      }
    }
  });
  var _default = GoogleMapInitMixin;
  _exports.default = _default;
});