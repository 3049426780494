define("clients/adapters/application", ["exports", "@ember-data/adapter/json-api", "ember-simple-auth/mixins/data-adapter-mixin", "clients/config/environment", "ember-inflector"], function (_exports, _jsonApi, _dataAdapterMixin, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    shouldBackgroundReloadRecord() {
      return false;
    },

    shouldReloadAll() {
      return true;
    },

    shouldReloadRecord() {
      return true;
    },

    namespace: _environment.default.api.namespace,
    host: _environment.default.api.host,

    authorize(xhr) {
      if (this.session.isAuthenticated) {
        if (this.session.data.authenticated.token) {
          xhr.setRequestHeader('Authorization', `Token token=\"${this.session.data.authenticated.token}\", email=\"${this.session.data.authenticated.email}\"`);
        } else {
          xhr.setRequestHeader('Authorization', `Bearer ${this.session.data.authenticated.accessToken}`);
          xhr.setRequestHeader('X-OAuth-User-ID-Token', this.session.data.authenticated.idToken);
        }
      } else {
        this.session.invalidate();
      }
    },

    pathForType(type) {
      const underscored = Ember.String.underscore(type);
      return (0, _emberInflector.pluralize)(underscored);
    }

  });

  _exports.default = _default;
});