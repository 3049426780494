define("clients/routes/dashboard/locations/index", ["exports", "ember-cli-pagination/remote/paged-remote-array", "clients/mixins/inner-application-route-setup-controller-mixin"], function (_exports, _pagedRemoteArray, _innerApplicationRouteSetupControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DashboardLocationsIndexRoute;
  DashboardLocationsIndexRoute = Ember.Route.extend(_innerApplicationRouteSetupControllerMixin.default, {
    sessionAccount: Ember.inject.service(),
    model: function (params) {
      return Ember.RSVP.hash({
        missions: _pagedRemoteArray.default.create({
          modelName: 'mission',
          store: this.store,
          page: params.page || 1,
          perPage: params.per_page || 20,
          otherParams: {
            current_date_column: params.current_date_column || this.controllerFor('dashboard.locations.index').get('current_date_column'),
            sort_direction: params.sort_direction || this.controllerFor('dashboard.locations.index').get('sort_direction'),
            sort_attribute: params.sort_attribute || this.controllerFor('dashboard.locations.index').get('sort_attribute'),
            statuses: params.statuses,
            show_org_missions: params.show_org_missions || this.controllerFor('dashboard.locations.index').get('show_org_missions'),
            group_id: params.group_id,
            q: params.q
          }
        }),
        client: this.sessionAccount.account,
        package_addons: this.store.findAll('package-addon'),
        mission_groups: this.store.query('mission-group', {
          statuses: params.statuses,
          show_org_missions: params.show_org_missions || this.controllerFor('dashboard.locations.index').get('show_org_missions'),
          q: params.q
        }),
        suggested_groups: this.store.query('suggested-group', {}),
        currencies: this.store.findAll('currency')
      });
    },
    setupController: function (controller, model) {
      var currentGroup;

      this._super(controller, model);

      this.controllerFor('application').set('showQuickOrder', true);
      this.controllerFor('dashboard.locations.index').set('searchTerms', model.missions.otherParams.q);
      currentGroup = model.mission_groups.find(function (group) {
        return group.get('id') === model.missions.otherParams.group_id;
      });
      controller.set('currentGroup', currentGroup);
      controller.set('currentRoute', this);
      controller.set('showContextMenu', false);
      return controller.set('selectedMissions', []);
    },
    actions: {
      loading: function (transition) {
        this.controllerFor('dashboard.locations.index').set('missionsLoaded', false);
        transition.promise["finally"](function (_this) {
          return function () {
            return _this.controllerFor('dashboard.locations.index').set('missionsLoaded', true);
          };
        }(this));
        return true;
      },
      reloadModel: function () {
        return this.refresh();
      }
    },
    queryParams: {
      q: {
        refreshModel: true
      },
      sort_attribute: {
        refreshModel: true
      },
      sort_direction: {
        refreshModel: true
      },
      statuses: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      show_org_missions: {
        refreshModel: true
      },
      group_id: {
        refreshModel: true
      }
    }
  });
  var _default = DashboardLocationsIndexRoute;
  _exports.default = _default;
});