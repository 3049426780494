define("clients/components/input-validated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputValidatedComponent;
  InputValidatedComponent = Ember.Component.extend({
    actions: {
      showErrors: function () {
        this.set('showError', true);

        if (this.get('errors') && this.get('errors').length > 0) {
          return this.$('input').addClass('error');
        } else {
          return this.$('input').removeClass('error');
        }
      }
    }
  });
  var _default = InputValidatedComponent;
  _exports.default = _default;
});