define("clients/routes/account/edit", ["exports", "clients/mixins/css-class-namespace", "clients/mixins/inner-application-route-setup-controller-mixin"], function (_exports, _cssClassNamespace, _innerApplicationRouteSetupControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AccountEditRoute;
  AccountEditRoute = Ember.Route.extend(_cssClassNamespace.default, _innerApplicationRouteSetupControllerMixin.default, {
    sessionAccount: Ember.inject.service(),
    model: function () {
      return this.get('sessionAccount.account');
    },
    setupController: function (controller, model) {
      return this._super(controller, model);
    },
    actions: {
      save: function (model) {
        return model.save();
      }
    }
  });
  var _default = AccountEditRoute;
  _exports.default = _default;
});