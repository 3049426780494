define("clients/helpers/format-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatPrice = formatPrice;
  const defaultCurrency = Ember.Object.create({
    code: 'USD',
    symbol: '$'
  });

  function formatPrice(_ref) {
    let [cents, currency] = _ref;
    // backwards compatibility for existing nulls that data migrations haven't cleaned up
    const renderCurrency = currency?.get('symbol') && currency?.get('code') ? currency : defaultCurrency;

    if (cents === null || cents === undefined) {
      return '-';
    } else {
      let sign = '';
      if (cents < 0) sign = '−'; // format as: −$50 USD

      return `${sign}${renderCurrency.get('symbol')}${Math.abs(cents) / 100} ${renderCurrency.get('code')}`;
    }
  }

  var _default = Ember.Helper.helper(formatPrice);

  _exports.default = _default;
});