define("clients/adapters/package", ["exports", "clients/adapters/application", "clients/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PackageAdapter;
  PackageAdapter = _application.default.extend({
    namespace: _environment.default.api.namespace + '/missions',
    pathForType: function (modelName) {
      return 'packages';
    }
  });
  var _default = PackageAdapter;
  _exports.default = _default;
});