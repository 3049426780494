define("clients/models/mission", ["exports", "clients/models/shareable", "@ember-data/model", "clients/config/environment"], function (_exports, _shareable, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _shareable.default.extend({
    store: Ember.inject.service(),
    created_on: (0, _model.attr)('date'),
    legacy: (0, _model.attr)('boolean'),
    status: (0, _model.attr)('string'),
    customer_instructions: (0, _model.attr)('string'),
    payment_token: (0, _model.attr)('string'),
    category: (0, _model.attr)('string'),
    external_assets_url: (0, _model.attr)('string'),
    payment: (0, _model.attr)(),
    scheduled_at_start: (0, _model.attr)('string'),
    scheduled_at_end: (0, _model.attr)('string'),
    logo_url: (0, _model.attr)(),
    blurb: (0, _model.attr)('string'),
    price: (0, _model.attr)('number'),
    editUrl: (0, _model.attr)('string'),
    archive_url: (0, _model.attr)(),
    contact_email: (0, _model.attr)('string'),
    video_count: (0, _model.attr)('number'),
    image_count: (0, _model.attr)('number'),
    panorama_count: (0, _model.attr)('number'),
    reference_id: (0, _model.attr)('string'),
    cloud_reqs: (0, _model.attr)('string'),
    arcgis_feature_layer: (0, _model.attr)('string'),
    flown_on: (0, _model.attr)('string'),
    has_roof_report_deliverable: (0, _model.attr)('boolean'),
    insights_enabled: (0, _model.attr)('boolean'),
    scheduling_requirement: (0, _model.attr)('string', {
      defaultValue: 'not_needed'
    }),
    is_on_hold: (0, _model.attr)('boolean'),
    owned_by_current_client: (0, _model.attr)('boolean'),
    paid_on: (0, _model.attr)('date'),
    parent_id: (0, _model.attr)('string'),
    rejection_reason: (0, _model.attr)('string'),
    rejection_notes: (0, _model.attr)('string'),
    reshoot_mission_id: (0, _model.attr)('string'),
    payment_status: (0, _model.attr)('string'),
    "package": (0, _model.belongsTo)('package', {
      async: false
    }),
    location: (0, _model.belongsTo)('location', {
      async: false
    }),
    credit_card: (0, _model.belongsTo)('credit_card', {
      async: false
    }),
    videos: (0, _model.hasMany)('video', {
      async: false
    }),
    panoramas: (0, _model.hasMany)('panorama', {
      async: false
    }),
    ortho_photo_maps: (0, _model.hasMany)('ortho_photo_map', {
      async: false
    }),
    client: (0, _model.belongsTo)('client', {
      async: false
    }),
    children: (0, _model.hasMany)('mission', {
      inverse: 'parent'
    }),
    parent: (0, _model.belongsTo)('mission', {
      inverse: 'children',
      async: false
    }),
    shareUrl: '',
    onsite_contact: (0, _model.belongsTo)('onsite_contact', {
      async: false
    }),
    scheduling_contact: (0, _model.belongsTo)('scheduling_contact', {
      async: false
    }),
    shots: (0, _model.hasMany)('shot'),
    activity_logs: (0, _model.hasMany)('activity_log', {
      async: true
    }),
    mission_groups: (0, _model.hasMany)('mission_group', {
      async: true
    }),
    attachments: (0, _model.hasMany)('attachments', {
      async: true
    }),
    buckets: (0, _model.hasMany)('buckets', {
      async: false
    }),
    invoice_line_items: (0, _model.hasMany)('invoice_line_items', {
      async: true
    }),
    images: (0, _model.hasMany)('image', {
      async: true
    }),
    composer_report: (0, _model.belongsTo)('mission-composer-report', {
      async: false
    }),
    map_features: (0, _model.hasMany)('map-feature'),
    geo_properties: (0, _model.attr)(),
    number_of_assets: (0, _model.attr)('number'),
    airbus_briefing: (0, _model.belongsTo)('airbus-briefing'),
    priceInDollars: Ember.computed('price', function () {
      return this.get('price') / 100;
    }),
    currency: (0, _model.belongsTo)('currency', {
      async: false
    }),
    meta_image_url: Ember.computed('main_image.collabThumbnail', 'promoted_videos.firstObject.thumbnail', function () {
      if (this.get('promoted_videos').length) {
        return this.get('promoted_videos.firstObject.thumbnail');
      } else if (this.get('main_image')) {
        return this.get('main_image.collabThumbnail');
      } else {
        return null;
      }
    }),
    main_image: Ember.computed.reads('images.firstObject'),
    promoted_videos: Ember.computed('videos', function () {
      return this.get('videos').reduce(function (array, video) {
        if (video.get('isPromoted')) {
          array.push(video);
          return array;
        } else {
          return array;
        }
      }, []);
    }),
    collaborators: (0, _model.hasMany)('collaborator', {
      async: false
    }),
    reversed_collaborators: Ember.computed('collaborators.[]', function () {
      return this.get('collaborators').toArray().reverse();
    }),
    clientEmail: Ember.computed.reads('client.email'),
    paid: Ember.computed('payment.id', function () {
      if (this.get('payment.id') != null) {
        return true;
      } else {
        return null;
      }
    }),
    share: Ember.computed.reads('shares.lastObject'),
    scheduled: Ember.computed.and('scheduled_at_start', 'scheduled_at_end'),
    statusTimeStamp: Ember.computed('activity_logs', 'status', function () {
      return this.get('activity_logs').find(function (_this) {
        return function (el) {
          return el.action === _this.get('status');
        };
      }(this));
    }),
    locationEditable: Ember.computed('status', function () {
      return this.get('status') === 'created' || !this.get('status');
    }),
    completed: Ember.computed.equal('status', 'complete'),
    invoiced: Ember.computed('status', function () {
      return ['invoice_needed', 'invoiced', 'complete'].includes(this.get('status'));
    }),
    assetsDelivered: Ember.computed('status', function () {
      return ['awaiting_payment', 'invoice_needed', 'invoiced', 'complete'].includes(this.get('status'));
    }),
    inProduction: Ember.computed('status', function () {
      return ['in_production', 'awaiting_payment', 'invoice_needed', 'invoiced', 'complete'].includes(this.get('status'));
    }),
    assetsUploaded: Ember.computed('status', function () {
      return ['assets_uploaded', 'in_production', 'awaiting_payment', 'invoice_needed', 'invoiced', 'complete'].includes(this.get('status'));
    }),
    flightCompleted: Ember.computed('status', function () {
      return ['flight_complete', 'assets_uploaded', 'processing_shots', 'assets_classified', 'in_production', 'awaiting_payment', 'invoice_needed', 'invoiced', 'complete'].includes(this.get('status'));
    }),
    pilotAssigned: Ember.computed('status', function () {
      return ['pilot_assigned', 'flight_complete', 'assets_uploaded', 'in_production', 'awaiting_payment', 'invoice_needed', 'invoiced', 'complete'].includes(this.get('status'));
    }),
    created: Ember.computed('status', function () {
      return ['created', 'pilot_assigned', 'flight_complete', 'assets_uploaded', 'in_production', 'awaiting_payment', 'invoice_needed', 'invoiced', 'complete'].includes(this.get('status'));
    }),
    isReshoot: Ember.computed('parent_id', function () {
      return this.get('parent_id') != null;
    }),
    hasReshoot: Ember.computed('status', 'reshoot_mission_id', function () {
      return this.get('status') === 'rejected' && this.get('reshoot_mission_id') != null;
    }),
    rawVideos: Ember.computed('shots.{[],@each.videos}', function () {
      return this.get('shots').reduce(function (allVideos, shot) {
        var shotVideos;
        shotVideos = shot.get('videos').filterBy('isPromoted', false);

        if (shotVideos.length) {
          shotVideos.setEach('assetType', 'video');
          return allVideos.concat(shotVideos);
        } else {
          return allVideos;
        }
      }, []);
    }),
    firstlyShownRawVideos: Ember.computed('rawVideos', function () {
      return this.get('rawVideos').slice(0, 8);
    }),
    hiddenRawVideos: Ember.computed('rawVideos.length', function () {
      if (this.get('rawVideos.length') > 8) {
        return this.get('rawVideos').slice(8, this.get('rawVideos.length'));
      } else {
        return null;
      }
    }),
    visibleRawVideos: Ember.computed.reads('firstlyShownRawVideos'),
    firstlyShownListRawVideos: Ember.computed('rawVideos', function () {
      return this.get('rawVideos').slice(0, 50);
    }),
    hiddenListRawVideos: Ember.computed('rawVideos.length', function () {
      if (this.get('rawVideos.length') > 50) {
        return this.get('rawVideos').slice(50, this.get('rawVideos.length'));
      } else {
        return null;
      }
    }),
    visibleListRawVideos: Ember.computed.reads('firstlyShownListRawVideos'),
    promotedPanoramas: Ember.computed('shots.{[],@each.panoramas}', function () {
      return this.get('shots').reduce(function (allPanoramas, shot) {
        var shotPanoramas;
        shotPanoramas = shot.get('panoramas').filterBy('isPromoted', true);

        if (shotPanoramas.length) {
          shotPanoramas.setEach('assetType', 'panorama');
          return allPanoramas.concat(shotPanoramas);
        } else {
          return allPanoramas;
        }
      }, []);
    }),
    firstlyShownPanoramas: Ember.computed('promotedPanoramas', function () {
      return this.get('promotedPanoramas').slice(0, 8);
    }),
    hiddenPanoramas: Ember.computed('promotedPanoramas.length', function () {
      if (this.get('promotedPanoramas.length') > 8) {
        return this.get('promotedPanoramas').slice(8, this.get('promotedPanoramas.length'));
      } else {
        return null;
      }
    }),
    visiblePanoramas: Ember.computed.reads('firstlyShownPanoramas'),
    firstlyShownListPanoramas: Ember.computed('promotedPanoramas', function () {
      return this.get('promotedPanoramas').slice(0, 50);
    }),
    hiddenListPanoramas: Ember.computed('promotedPanoramas.length', function () {
      if (this.get('promotedPanoramas.length') > 50) {
        return this.get('promotedPanoramas').slice(50, this.get('promotedPanoramas.length'));
      } else {
        return null;
      }
    }),
    visibleListPanoramas: Ember.computed.reads('firstlyShownListPanoramas'),
    promotedOrthos: Ember.computed('ortho_photo_maps', 'shots.@each.ortho_photo_maps', function () {
      var orthos;
      orthos = this.get('ortho_photo_maps').filterBy('isPromoted', true);
      orthos.setEach('assetType', 'ortho_photo_map');
      return orthos;
    }),
    firstlyShownOrthos: Ember.computed('promotedOrthos', function () {
      return this.get('promotedOrthos').slice(0, 8);
    }),
    hiddenOrthos: Ember.computed('promotedOrthos.length', function () {
      if (this.get('promotedOrthos.length') > 8) {
        return this.get('promotedOrthos').slice(8, this.get('promotedOrthos.length'));
      } else {
        return null;
      }
    }),
    visibleOrthos: Ember.computed.reads('firstlyShownOrthos'),
    firstlyShownListOrthos: Ember.computed('promotedOrthos', function () {
      return this.get('promotedOrthos').slice(0, 50);
    }),
    hiddenListOrthos: Ember.computed('promotedOrthos.length', function () {
      if (this.get('promotedOrthos.length') > 50) {
        return this.get('promotedOrthos').slice(50, this.get('promotedOrthos.length'));
      } else {
        return null;
      }
    }),
    visibleListOrthos: Ember.computed.reads('firstlyShownListOrthos'),
    promotedAttachments: Ember.computed('attachments.[]', function () {
      return this.get('attachments').filterBy('final', true);
    }),
    arcgisLink: Ember.computed('client.esri_account.baseArcgisUrl', 'arcgis_feature_layer', function () {
      if (this.get('arcgis_feature_layer')) {
        return this.get('client.esri_account.baseArcgisUrl') + "/home/item.html?id=" + this.get('arcgis_feature_layer');
      } else {
        return null;
      }
    }),
    firstlyShownAttachments: Ember.computed('promotedAttachments', function () {
      return this.get('promotedAttachments').slice(0, 8);
    }),
    hiddenAttachments: Ember.computed('promotedAttachments.length', function () {
      if (this.get('promotedAttachments.length') > 8) {
        return this.get('promotedAttachments').slice(8, this.get('promotedAttachments.length'));
      } else {
        return null;
      }
    }),
    hiddenListAttachments: Ember.computed('promotedAttachments.length', function () {
      if (this.get('promotedAttachments.length') > 50) {
        return this.get('promotedAttachments').slice(50, this.get('promotedAttachments.length'));
      } else {
        return null;
      }
    }),
    visibleAttachments: Ember.computed.reads('firstlyShownAttachments'),
    firstlyShownListAttachments: Ember.computed('promotedAttachments', function () {
      return this.get('promotedAttachments').slice(0, 50);
    }),
    visibleListAttachments: Ember.computed.reads('firstlyShownListAttachments'),
    refIdOrDBM: Ember.computed.or('reference_id', 'id'),
    cloudReqsVerbose: Ember.computed('cloud_reqs', 'package.cloud_reqs', function () {
      var cloudReqs;
      cloudReqs = this.get('cloud_reqs') || this.get('package.cloud_reqs');

      switch (cloudReqs) {
        case 'less_than_10':
          return "Wait for perfect skies (Up to 10% cloudy)";

        case 'less_than_100':
          return "Gray skies OK, fly ASAP (Up to 100% cloudy)";

        default:
          return "Blue skies preferred (Up to 50% cloudy)";
      }
    }),
    scheduling_contact_line: Ember.computed('scheduling_contact.{name,phone}', function () {
      if (this.get('scheduling_contact') && this.get('scheduling_contact.name') && this.get('scheduling_contact.phone')) {
        return this.get('scheduling_contact.name') + ", " + this.get('scheduling_contact.phone');
      }

      return null;
    }),
    loadImages: function (shareToken) {
      return this.get('store').query('image', {
        missionId: this.get('id'),
        shareToken: shareToken
      }).then(response => {
        this.set('images', response);
      }, function (error) {
        var message;

        if (error.errors) {
          message = error.errors[0].detail;
          return alert(message);
        }
      });
    },
    loadInvoiceLineItems: function () {
      return this.get('store').query('invoice-line-item', {
        missionId: this.get('id')
      }).then(function (_this) {
        return function (items) {
          return _this.set('invoice_line_items', items);
        };
      }(this)).then(function (_this) {
        return function () {
          var featureAreaOutsideOfRange, forMapFeatures, standardLineItems;
          forMapFeatures = [];
          standardLineItems = [];

          _this.get('invoice_line_items').forEach(function (item) {
            if (item.get('work_item.map_feature')) {
              forMapFeatures.pushObject(item);
            }

            return standardLineItems.pushObject(item);
          });

          featureAreaOutsideOfRange = forMapFeatures.some(function (feature) {
            return feature.work_item.calculator_debug_info.value_outside_of_defined_range;
          });

          _this.set('sortedLineItems', standardLineItems.sortBy('created_on'));

          _this.set('mapFeatureLineItems', _this.groupMapFeatureLineItems(forMapFeatures));

          return _this.set('featureAreaOutsideOfRange', featureAreaOutsideOfRange);
        };
      }(this));
    },
    groupMapFeatureLineItems: function (items) {
      var mapFeatureGroupedItems;
      mapFeatureGroupedItems = {};
      items.forEach(function (item) {
        var groupedItems, key, map_feature;
        map_feature = item.get('work_item.map_feature');
        key = map_feature.id;
        groupedItems = mapFeatureGroupedItems[key];

        if (groupedItems) {
          return groupedItems.items.pushObject(item);
        } else {
          groupedItems = {
            map_feature: map_feature,
            feature_details: item.get('work_item.calculator_debug_info'),
            items: [item]
          };
          return mapFeatureGroupedItems[key] = groupedItems;
        }
      });
      return mapFeatureGroupedItems;
    },
    clientUploadShot: Ember.computed('shots', function () {
      return this.get('shots').findBy('slug', 'insights_building') || this.get('shots').findBy('slug', 'insights_building_ortho');
    }),
    insightsLink: Ember.computed('client.organization.id', 'id', 'insights_enabled', 'legacy', 'location.number', function () {
      if (this.legacy) {
        return _environment.default.insights_endpoint + "/inspections";
      }

      if (this.insights_enabled) {
        if (this.get('client.organization.id')) {
          return _environment.default.insights_endpoint + "/sites/" + this.location.number + "/" + this.id + "?vid=" + this.client.organization.id;
        } else {
          return _environment.default.insights_endpoint + "/sites/" + this.location.number + "/" + this.id;
        }
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});