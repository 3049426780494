define("clients/helpers/human-readable-filesize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function () {
    const [size] = Array.from(arguments.length <= 0 ? undefined : arguments[0]);
    let i = Math.floor(Math.log(size) / Math.log(1024));

    if (i < 0) {
      i = 0;
    }

    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  });

  _exports.default = _default;
});