define("clients/templates/components/mission-group/rename-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9rb/klDV",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,[[\"title\",\"isSaving\"],[\"Rename Group\",[35,4]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"group-input\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"type\",\"value\",\"id\",\"class\",\"placeholder\"],[\"text\",[35,0],\"group-input\",\"form-control input-lg\",\"Enter Name for Group\"]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"group-buttons\"],[12],[2,\"\\n      \"],[8,\"button\",[[4,[38,2],[[32,0],\"close\"],null]],[[\"@type\"],[\"secondary\"]],[[\"default\"],[{\"statements\":[[2,\"Cancel\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"button\",[[16,\"disabled\",[30,[36,3],[[35,0]],null]],[4,[38,2],[[32,0],\"save\"],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Save\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"groupName\",\"input\",\"action\",\"is-empty\",\"isSaving\",\"mission-group/base-modal\"]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-group/rename-modal.hbs"
    }
  });

  _exports.default = _default;
});