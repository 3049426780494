define("clients/templates/components/share/only-pano", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4av6/K2t",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"mission-share-shot\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"bucket-header\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"bucket-name\"],[12],[2,\"Panoramas\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[[35,0],\"pano_full_screen\"],null]]]],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"panorama\"],[[35,2]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"full_screen\",\"if\",\"sharedPanorama\",\"pano-viewer\"]}",
    "meta": {
      "moduleName": "clients/templates/components/share/only-pano.hbs"
    }
  });

  _exports.default = _default;
});