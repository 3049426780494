define("clients/components/dropdown-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DropdownButtonComponent;
  DropdownButtonComponent = Ember.Component.extend({
    showDropdown: false,
    disableOnSelect: false,
    optionLabelPath: 'displayName',
    actions: {
      toggleShowDropdown: function () {
        return this.set('showDropdown', !this.get('showDropdown'));
      },
      selectItem: function (item) {
        if (this.get('disableOnSelect')) {
          item.set('selected', true);
        }

        if (this.get('action')) {
          return this.sendAction('action', item);
        } else {
          return this.set('selectedItem', item);
        }
      },
      dropdownBlurred: function () {
        if (this.$(':hover')) {
          $(this.$(':hover')[this.$(':hover').length - 1]).click();
        }

        return this.set('showDropdown', false);
      }
    }
  });
  var _default = DropdownButtonComponent;
  _exports.default = _default;
});