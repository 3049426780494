define("clients/components/credit-card-form", ["exports", "clients/config/environment", "clients/validations/credit_card", "ember-changeset-validations", "ember-changeset", "jquery"], function (_exports, _environment, _credit_card, _emberChangesetValidations, _emberChangeset, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CreditCardFormComponent;
  CreditCardFormComponent = Ember.Component.extend(_credit_card.default, {
    tagName: 'form',
    role: 'form',
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    stripe: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    newCard: false,
    cardAdded: false,
    paying: false,
    defaultCard: Ember.computed('client.credit_cards.[]', function () {
      return this.get('client.credit_cards.lastObject');
    }),
    paymentAmount: Ember.computed('mission.package.price', function () {
      return this.get('mission.priceInDollars');
    }),
    initChangeSet: Ember.on('init', function () {
      this.changeset = new _emberChangeset.default(this.get('credit_card') ? this.get('credit_card') : this.get('store').createRecord('credit_card'), (0, _emberChangesetValidations.default)(_credit_card.default), _credit_card.default);
      this.set('newCard', this.get('client.credit_cards.lastObject.id') == null);
      return this.set('cardAdded', this.get('mission.status') === 'complete');
    }),
    creditCardParams: function () {
      var expireDate;
      expireDate = this.get('credit_card.exp_date').replace(' ', '').split('/');
      return {
        number: this.get('credit_card.number').replace(' ', ''),
        exp_month: Number(expireDate[0]),
        exp_year: Number(expireDate[1]),
        cvc: this.get('credit_card.cvc')
      };
    },
    pay: function () {
      var _this, card, headers;

      this.set('paying', true);
      _this = this;
      card = this.get('enteredCard') || this.get('defaultCard');
      this.set('mission.credit_card', card);
      headers = this.get('sessionAccount.headers');
      return this.get('mission').save().then(function (_this) {
        return function (response) {
          return _jquery.default.ajax({
            url: _environment.default.api.host + "/v1/clients/missions/" + _this.get('mission.id') + "/payments",
            type: 'POST',
            dataType: 'json',
            headers: headers
          }).then(function (response) {
            _this.set('saveState', 'success');

            Ember.run.later(function () {
              return _this.set('saveState', 'initial');
            }, 1000);

            _this.set('success', 'Payment recieved!');

            _this.set('mission.status', response.data.attributes.status);

            _this.set('showModal', false);

            return _this.get('reloadMission')();
          }, function (response) {
            var error;

            _this.set('saveState', 'error');

            Ember.run.later(function () {
              return _this.set('saveState', 'initial');
            }, 1000);

            _this.set('paying', false);

            error = response.responseJSON.errors[0].detail || 'There was an error processing your payment. Please contact our staff.';
            return _this.changeset.pushErrors('processing', error);
          });
        };
      }(this), function (_this) {
        return function (response) {
          var error;

          _this.set('saveState', 'error');

          Ember.run.later(function () {
            return _this.set('saveState', 'initial');
          }, 1000);

          _this.set('paying', false);

          error = response.responseJSON.errors[0].detail || 'There was an error processing your payment. Please contact our staff.';
          return _this.changeset.pushErrors('processing', error);
        };
      }(this));
    },
    actions: {
      submitCreditCard: function () {
        this.set('saveState', 'inProgress');
        this.changeset.set('processing', null);
        return this.changeset.validate().then(function (_this) {
          return function () {
            var i, input, inputs, len;

            if (_this.changeset.get('isInvalid')) {
              inputs = document.getElementsByTagName('input');

              for (i = 0, len = inputs.length; i < len; i++) {
                input = inputs[i];
                input.focus();
                input.blur();
              }

              _this.set('saveState', 'error');

              Ember.run.later(function () {
                return _this.set('saveState', 'initial');
              }, 1000);
              return;
            }

            _this.set('cardAdded', true);

            return _this.get('stripe').card.createToken(_this.creditCardParams()).then(function (response) {
              var creditCard;
              creditCard = _this.get('credit_card');
              creditCard.set('provider_token', response.id);
              creditCard.set('stripe_id', response.card.id);
              return creditCard.save().then(function (response) {
                _this.set('enteredCard', creditCard);

                return _this.pay();
              }, function (response) {
                _this.set('saveState', 'error');

                Ember.run.later(function () {
                  return _this.set('saveState', 'initial');
                }, 1000);

                _this.set('cardAdded', false);

                return _this.changeset.pushErrors('processing', response.errors[0].detail);
              });
            }, function (response) {
              _this.set('saveState', 'error');

              Ember.run.later(function () {
                return _this.set('saveState', 'initial');
              }, 1000);

              _this.set('cardAdded', false);

              return _this.changeset.pushErrors('processing', response.error.message);
            });
          };
        }(this));
      },
      triggerPayment: function () {
        this.set('saveState', 'inProgress');
        return this.pay();
      },
      toggleNewCard: function () {
        return this.set('newCard', !this.get('newCard'));
      }
    }
  });
  var _default = CreditCardFormComponent;
  _exports.default = _default;
});