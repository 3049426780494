define("clients/templates/components/mission-group/introduction-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lDhp942x",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ember-modal-inner-wrap registration-modal\"],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"ember-modal-dialog-close skip-modal close-modal\"],[4,[38,0],[[32,0],\"closeModal\"],null],[12],[2,\"×\"],[13],[2,\"\\n\\n  \"],[10,\"img\"],[14,\"src\",\"/assets/images/dashboard/introduction/group-feature.png\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-information\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"modal-header\"],[12],[2,\"Introducing the New Group Feature\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-text\"],[12],[2,\"\\n      Now you can sort your missions into Groups for better project organization and management!\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n      \"],[8,\"button\",[[4,[38,0],[[32,0],\"closeModal\"],null]],[[\"@size\"],[\"extra-large\"]],[[\"default\"],[{\"statements\":[[2,\"Got it!\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-group/introduction-modal.hbs"
    }
  });

  _exports.default = _default;
});