define("clients/components/video-js-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VideoJsSourceComponent;
  VideoJsSourceComponent = Ember.Component.extend({
    tagName: 'source',
    attributeBindings: ['src', 'type']
  });
  var _default = VideoJsSourceComponent;
  _exports.default = _default;
});