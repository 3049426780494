define("clients/components/mission-plan-map", ["exports", "clients/config/environment", "clients/mixins/google-map-features-mixin", "jquery"], function (_exports, _environment, _googleMapFeaturesMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_googleMapFeaturesMixin.default, {
    sessionAccount: Ember.inject.service(),
    classNames: ['google-map'],
    undoObject: null,
    DEFAULT_ZOOM: 19,
    currentlySaving: false,
    location: Ember.computed.reads('model.location.name'),
    noLocation: Ember.computed('model.location.name.length', function () {
      return !this.model.location.name || this.model.location.name.length < 1;
    }),
    noProperty: Ember.computed.reads('model.features.length'),
    isCalculateFeaturePrice: false,

    initMap() {
      let options = {
        zoom: this.DEFAULT_ZOOM,
        tilt: 0,
        styles: this.mapStyles,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM
        },
        streetViewControl: false,
        scaleControl: false,
        panControl: false,
        draggable: true,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.LARGE,
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        disableDoubleClickZoom: false,
        mapTypeId: google.maps.MapTypeId.HYBRID
      };

      if (this.smallScreen) {
        options.zoomControl = false;
        options.disableDoubleClickZoom = true;
      }

      if (!this.map) {
        this.map = new google.maps.Map(document.getElementById('map_canvas'), options);
      }

      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('map_ui_container'));
      this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(document.getElementById('control_boundry_zoom'));
      this.map.addListener('tilesloaded', () => {
        (0, _jquery.default)('#map_ui_container').show();
        (0, _jquery.default)('#control_boundry_zoom').show();
        (0, _jquery.default)('#map_list_container').show();
      }); // namingIndex: event.feature.getProperty('namingIndex') || this.getNextIndex(),

      this.markers = Ember.A([]);
      this.setupListeners();
      this.setupInfoWindow();
      return this.loadData();
    },

    populateInfoWindow(target) {
      const contentArr = target.content.split('||');
      this.infoWindowNode.html(`<strong>${contentArr[1]}</strong><br> \
<p class='small pull-left'>${contentArr[3]}</p>`);
      return target.set('content', this.infoWindowNode[0]);
    },

    actions: {
      changeToPanoramaIcon(selectedFeature) {
        this.changeToPanoramaIcon(selectedFeature);
      },

      changeToPointIcon(selectedFeature) {
        this.changeToPointIcon(selectedFeature);
      },

      add_polygon() {
        this.metrics.trackEvent({
          event: 'event',
          category: 'Plan Mission',
          action: 'click',
          label: 'Add Property'
        });
        return this.addFeature(this.featureOptions.PROPERTYAREA);
      },

      add_line() {
        this.metrics.trackEvent({
          event: 'event',
          category: 'Plan Mission',
          action: 'click',
          label: 'Add Line'
        });
        return this.addFeature(this.featureOptions.LINE);
      },

      add_no_fly_zone() {
        this.metrics.trackEvent({
          event: 'event',
          category: 'Plan Mission',
          action: 'click',
          label: 'Add No Fly Zone'
        });
        return this.addFeature(this.featureOptions.NOFLYZONE);
      },

      add_point_of_interest() {
        this.metrics.trackEvent({
          event: 'event',
          category: 'Plan Mission',
          action: 'click',
          label: 'Add Point of Interest'
        });
        return this.addFeature(this.featureOptions.POINTOFINTEREST);
      },

      remove_feature(feature) {
        this.metrics.trackEvent({
          event: 'event',
          category: 'Plan Mission',
          action: 'click',
          label: 'Remove Feature'
        });
        return this.removeFeature(feature);
      },

      boundry_zoom() {
        this.fitAllFeatures();
        return this.metrics.trackEvent({
          event: 'event',
          category: 'Plan Mission',
          action: 'click',
          label: 'Boundry Zoom'
        });
      },

      toggle_list() {
        (0, _jquery.default)('#map_list').toggle();
        return this.metrics.trackEvent({
          event: 'event',
          category: 'Plan Mission',
          action: 'click',
          label: 'Toggle List'
        });
      },

      toggle_tabs(show, hide) {
        (0, _jquery.default)('.tab').removeClass('selected');
        (0, _jquery.default)(event.target).addClass('selected');
        (0, _jquery.default)(hide).hide();
        return (0, _jquery.default)(show).show();
      },

      update(redirect) {
        this.set('currentlySaving', true);
        this.metrics.trackEvent({
          event: 'event',
          objectId: this.model.mission.id,
          objectType: 'mission',
          category: 'Plan Mission',
          action: 'click',
          label: 'Update'
        });
        this.infoWindow.close();
        return this.map.data.toGeoJson(json => {
          this.set('model.mission.geo_properties', json);
          let data = this.model.location.toJSON();
          data.mission_id = this.model.mission.id;
          data.geo_properties = JSON.stringify(json);

          _jquery.default.ajax({
            url: `${_environment.default.api.host}/v1/clients/locations/${this.model.location.id}`,
            headers: this.sessionAccount.headers,
            type: 'PUT',
            data: data,
            dataType: 'json'
          }).then(response => {
            this.set('currentlySaving', false);

            if (redirect) {
              return this.redirectAction(this.model.mission.id);
            } else {
              this.set('model.mission.geo_properties', response.data.attributes.properties);
              this.clearMap();
              this.set('colorIndex', 0);
              this.loadData();
            }
          }, error_response => {
            this.set('currentlySaving', false);
            const error = error_response?.responseJSON?.errors?.firstObject;

            if (error) {
              alert("There was an issue saving: \n" + error.detail);
            }

            return console.log('something w wrong...', error_response);
          });
        });
      }

    }
  });

  _exports.default = _default;
});