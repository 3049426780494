define("clients/templates/shares/pano", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BQBOlSwe",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,5],null,[[\"shareType\",\"mission\",\"shareToken\",\"currentShare\",\"shareBarModel\",\"loginAction\",\"full_screen\"],[\"pano\",[35,4],[35,3,[\"shareToken\"]],[35,2],[35,1],\"loginAction\",[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"full_screen\",\"shareBarModel\",\"currentShare\",\"model\",\"mission\",\"mission-share\"]}",
    "meta": {
      "moduleName": "clients/templates/shares/pano.hbs"
    }
  });

  _exports.default = _default;
});