define("clients/components/group-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mission-list-item-container'],
    actions: {
      goToGroup: function () {
        this.goToGroupAction(this.group);
      },
      showRenameGroupModal: function () {
        this.showRenameGroupModalAction(this.group);
      },
      showRemoveGroupModal: function () {
        this.showRemoveGroupModalAction(this.group);
      }
    }
  });

  _exports.default = _default;
});