define("clients/templates/components/group-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AKAtM65l",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"mission-list-item group\"],[4,[38,0],[[32,0],\"goToGroup\"],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mission-group-icon\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/dashboard/mission-group/group_icon.svg\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mission-group-name\"],[12],[2,\"\\n    \"],[1,[35,1,[\"name\"]]],[2,\" (\"],[1,[35,1,[\"missionsCount\"]]],[2,\")\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"dropdown mission-group-actions\"],[12],[2,\"\\n  \"],[10,\"a\"],[14,0,\"dropdown-toggle\"],[14,\"data-toggle\",\"dropdown\"],[14,\"aria-haspopup\",\"true\"],[14,\"aria-expanded\",\"false\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"dropdown-menu dropdown-menu-right\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[11,\"a\"],[4,[38,0],[[32,0],\"showRenameGroupModal\"],null],[12],[2,\"Rename Group\"],[13],[13],[2,\"\\n    \"],[10,\"li\"],[12],[11,\"a\"],[4,[38,0],[[32,0],\"showRemoveGroupModal\"],null],[12],[2,\"Delete Group\"],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"group\"]}",
    "meta": {
      "moduleName": "clients/templates/components/group-item.hbs"
    }
  });

  _exports.default = _default;
});