define("clients/components/mission-group/introduction-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const IntroductionMissionGroupModalComponent = Ember.Component.extend({
    actions: {
      closeModal() {
        this.closeModalAction();
      }

    }
  });
  var _default = IntroductionMissionGroupModalComponent;
  _exports.default = _default;
});