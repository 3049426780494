define("clients/validations/creditcard", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    'number': [(0, _validators.validateLength)(16), (0, _validators.validateNumber)({
      gt: 0
    })],
    'expiry': (0, _validators.validatePresence)(true),
    'cvv': [(0, _validators.validateNumber)({
      gt: 0
    }), (0, _validators.validateLength)(3)] // 'name': validatePresence(true),
    // 'address_zip': [
    //   validateNumber({gt: 0}),
    // ] cannot use these yet

  };
  _exports.default = _default;
});