define("clients/data/sample_images/cre_pro_package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "data": [{
      "id": "DBI8532371149",
      "type": "image",
      "attributes": {
        "name": "DBI-22-UTujz0vQ-downloadable-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581503-5872cefb2fa8dfbff7ea658c1885d20e27d7242b/downloadable-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581503-5872cefb2fa8dfbff7ea658c1885d20e27d7242b/large_1920-535ff7b4799b54f086ef13417e0bb269b3925519.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNTAzLTU4NzJjZWZiMmZhOGRmYmZmN2VhNjU4YzE4ODVkMjBlMjdkNzI0MmIvbGFyZ2VfMTkyMC01MzVmZjdiNDc5OWI1NGYwODZlZjEzNDE3ZTBiYjI2OWIzOTI1NTE5LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNTAzLTU4NzJjZWZiMmZhOGRmYmZmN2VhNjU4YzE4ODVkMjBlMjdkNzI0MmIvbGFyZ2VfMTkyMC01MzVmZjdiNDc5OWI1NGYwODZlZjEzNDE3ZTBiYjI2OWIzOTI1NTE5LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:37:17Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047826",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI0458937384",
      "type": "image",
      "attributes": {
        "name": "DBI-37-FWO8IpOK-downloadable-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581408-e54b38f075a44c95d543ea71b0912036ec65a78b/downloadable-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581408-e54b38f075a44c95d543ea71b0912036ec65a78b/large_1920-eb66ccb5fa4b34966d835d1fa57c59d77af59e01.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNDA4LWU1NGIzOGYwNzVhNDRjOTVkNTQzZWE3MWIwOTEyMDM2ZWM2NWE3OGIvbGFyZ2VfMTkyMC1lYjY2Y2NiNWZhNGIzNDk2NmQ4MzVkMWZhNTdjNTlkNzdhZjU5ZTAxLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNDA4LWU1NGIzOGYwNzVhNDRjOTVkNTQzZWE3MWIwOTEyMDM2ZWM2NWE3OGIvbGFyZ2VfMTkyMC1lYjY2Y2NiNWZhNGIzNDk2NmQ4MzVkMWZhNTdjNTlkNzdhZjU5ZTAxLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:21Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047828",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI0419714054",
      "type": "image",
      "attributes": {
        "name": "DBI-36-5LxHwG3x-downloadable-85c01ca4fc8767e66107eb0e4c33965acfbc983f.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581407-e54b38f075a44c95d543ea71b0912036ec65a78b/downloadable-85c01ca4fc8767e66107eb0e4c33965acfbc983f.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581407-e54b38f075a44c95d543ea71b0912036ec65a78b/large_1920-85c01ca4fc8767e66107eb0e4c33965acfbc983f.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNDA3LWU1NGIzOGYwNzVhNDRjOTVkNTQzZWE3MWIwOTEyMDM2ZWM2NWE3OGIvbGFyZ2VfMTkyMC04NWMwMWNhNGZjODc2N2U2NjEwN2ViMGU0YzMzOTY1YWNmYmM5ODNmLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNDA3LWU1NGIzOGYwNzVhNDRjOTVkNTQzZWE3MWIwOTEyMDM2ZWM2NWE3OGIvbGFyZ2VfMTkyMC04NWMwMWNhNGZjODc2N2U2NjEwN2ViMGU0YzMzOTY1YWNmYmM5ODNmLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:20Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047828",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4933297293",
      "type": "image",
      "attributes": {
        "name": "DBI-35-rULRVEkj-downloadable-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581404-d8b888facd9f997ba85e39a887976b79d08540c4/downloadable-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581404-d8b888facd9f997ba85e39a887976b79d08540c4/large_1920-2f061394182882b945975bdb8fa14d5f936112ca.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNDA0LWQ4Yjg4OGZhY2Q5Zjk5N2JhODVlMzlhODg3OTc2Yjc5ZDA4NTQwYzQvbGFyZ2VfMTkyMC0yZjA2MTM5NDE4Mjg4MmI5NDU5NzViZGI4ZmExNGQ1ZjkzNjExMmNhLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNDA0LWQ4Yjg4OGZhY2Q5Zjk5N2JhODVlMzlhODg3OTc2Yjc5ZDA4NTQwYzQvbGFyZ2VfMTkyMC0yZjA2MTM5NDE4Mjg4MmI5NDU5NzViZGI4ZmExNGQ1ZjkzNjExMmNhLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:19Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3938178586",
      "type": "image",
      "attributes": {
        "name": "DBI-34-m6tAWs2l-downloadable-48d8f29a4b08d3dc615a93a4d071d94d316beb34.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581403-62f73bec992273915bc030dedab92db5bd665fd9/downloadable-48d8f29a4b08d3dc615a93a4d071d94d316beb34.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581403-62f73bec992273915bc030dedab92db5bd665fd9/large_1920-48d8f29a4b08d3dc615a93a4d071d94d316beb34.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNDAzLTYyZjczYmVjOTkyMjczOTE1YmMwMzBkZWRhYjkyZGI1YmQ2NjVmZDkvbGFyZ2VfMTkyMC00OGQ4ZjI5YTRiMDhkM2RjNjE1YTkzYTRkMDcxZDk0ZDMxNmJlYjM0LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNDAzLTYyZjczYmVjOTkyMjczOTE1YmMwMzBkZWRhYjkyZGI1YmQ2NjVmZDkvbGFyZ2VfMTkyMC00OGQ4ZjI5YTRiMDhkM2RjNjE1YTkzYTRkMDcxZDk0ZDMxNmJlYjM0LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:18Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI9752919324",
      "type": "image",
      "attributes": {
        "name": "DBI-33-mDcxYt7u-downloadable-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581402-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/downloadable-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581402-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/large_1920-518e8f7b40d7e65868736a720e47f9acceb37e44.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNDAyLTRkZGY3YTY2MThiMmU3MjgyYjc3ZjVkZTBlZjJkY2RmMjg2MDYwYTcvbGFyZ2VfMTkyMC01MThlOGY3YjQwZDdlNjU4Njg3MzZhNzIwZTQ3ZjlhY2NlYjM3ZTQ0LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNDAyLTRkZGY3YTY2MThiMmU3MjgyYjc3ZjVkZTBlZjJkY2RmMjg2MDYwYTcvbGFyZ2VfMTkyMC01MThlOGY3YjQwZDdlNjU4Njg3MzZhNzIwZTQ3ZjlhY2NlYjM3ZTQ0LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:17Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI1962407297",
      "type": "image",
      "attributes": {
        "name": "DBI-32-wJJ820e7-downloadable-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581401-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/downloadable-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581401-4ddf7a6618b2e7282b77f5de0ef2dcdf286060a7/large_1920-fafb17a1abb54dc8a2e70a5240af702f6ff059c5.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNDAxLTRkZGY3YTY2MThiMmU3MjgyYjc3ZjVkZTBlZjJkY2RmMjg2MDYwYTcvbGFyZ2VfMTkyMC1mYWZiMTdhMWFiYjU0ZGM4YTJlNzBhNTI0MGFmNzAyZjZmZjA1OWM1LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNDAxLTRkZGY3YTY2MThiMmU3MjgyYjc3ZjVkZTBlZjJkY2RmMjg2MDYwYTcvbGFyZ2VfMTkyMC1mYWZiMTdhMWFiYjU0ZGM4YTJlNzBhNTI0MGFmNzAyZjZmZjA1OWM1LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:17Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI3442308017",
      "type": "image",
      "attributes": {
        "name": "DBI-31-khxpM9IQ-downloadable-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581400-6fbda4d9fe95f30a2caff16b49efe022ac37b61d/downloadable-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581400-6fbda4d9fe95f30a2caff16b49efe022ac37b61d/large_1920-d84445703a5ee69913dea10b9cf11a92ff094870.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNDAwLTZmYmRhNGQ5ZmU5NWYzMGEyY2FmZjE2YjQ5ZWZlMDIyYWMzN2I2MWQvbGFyZ2VfMTkyMC1kODQ0NDU3MDNhNWVlNjk5MTNkZWExMGI5Y2YxMWE5MmZmMDk0ODcwLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxNDAwLTZmYmRhNGQ5ZmU5NWYzMGEyY2FmZjE2YjQ5ZWZlMDIyYWMzN2I2MWQvbGFyZ2VfMTkyMC1kODQ0NDU3MDNhNWVlNjk5MTNkZWExMGI5Y2YxMWE5MmZmMDk0ODcwLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:16Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI7990417916",
      "type": "image",
      "attributes": {
        "name": "DBI-30-F7TBOt01-downloadable-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581399-f247a624036a7ea7e5cd5ee154416d208f0eac49/downloadable-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581399-f247a624036a7ea7e5cd5ee154416d208f0eac49/large_1920-0682793e7996851c1da4c81b5963f908c5c2a539.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzk5LWYyNDdhNjI0MDM2YTdlYTdlNWNkNWVlMTU0NDE2ZDIwOGYwZWFjNDkvbGFyZ2VfMTkyMC0wNjgyNzkzZTc5OTY4NTFjMWRhNGM4MWI1OTYzZjkwOGM1YzJhNTM5LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzk5LWYyNDdhNjI0MDM2YTdlYTdlNWNkNWVlMTU0NDE2ZDIwOGYwZWFjNDkvbGFyZ2VfMTkyMC0wNjgyNzkzZTc5OTY4NTFjMWRhNGM4MWI1OTYzZjkwOGM1YzJhNTM5LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:15Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI4274425053",
      "type": "image",
      "attributes": {
        "name": "DBI-29-xQWWxsP9-downloadable-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581398-349e25895296a9042f70fd1334beb012b1e0c679/downloadable-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581398-349e25895296a9042f70fd1334beb012b1e0c679/large_1920-44ad6af113a7d4f4b841e1e1b57e2ffd7aecd46a.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzk4LTM0OWUyNTg5NTI5NmE5MDQyZjcwZmQxMzM0YmViMDEyYjFlMGM2NzkvbGFyZ2VfMTkyMC00NGFkNmFmMTEzYTdkNGY0Yjg0MWUxZTFiNTdlMmZmZDdhZWNkNDZhLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzk4LTM0OWUyNTg5NTI5NmE5MDQyZjcwZmQxMzM0YmViMDEyYjFlMGM2NzkvbGFyZ2VfMTkyMC00NGFkNmFmMTEzYTdkNGY0Yjg0MWUxZTFiNTdlMmZmZDdhZWNkNDZhLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:14Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI8086126253",
      "type": "image",
      "attributes": {
        "name": "DBI-28-P9D2dccV-downloadable-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581397-349e25895296a9042f70fd1334beb012b1e0c679/downloadable-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581397-349e25895296a9042f70fd1334beb012b1e0c679/large_1920-b03c94fdc252502aa9f70fea1a60f86a6a75ccb0.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzk3LTM0OWUyNTg5NTI5NmE5MDQyZjcwZmQxMzM0YmViMDEyYjFlMGM2NzkvbGFyZ2VfMTkyMC1iMDNjOTRmZGMyNTI1MDJhYTlmNzBmZWExYTYwZjg2YTZhNzVjY2IwLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzk3LTM0OWUyNTg5NTI5NmE5MDQyZjcwZmQxMzM0YmViMDEyYjFlMGM2NzkvbGFyZ2VfMTkyMC1iMDNjOTRmZGMyNTI1MDJhYTlmNzBmZWExYTYwZjg2YTZhNzVjY2IwLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:14Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6268348479",
      "type": "image",
      "attributes": {
        "name": "DBI-27-bDA5O3Mk-downloadable-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581396-ad8af1144400c9ff055ba092f947d6567b85ee10/downloadable-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581396-ad8af1144400c9ff055ba092f947d6567b85ee10/large_1920-894d4c8998b9440304108f092dee5dd5b11d94b7.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzk2LWFkOGFmMTE0NDQwMGM5ZmYwNTViYTA5MmY5NDdkNjU2N2I4NWVlMTAvbGFyZ2VfMTkyMC04OTRkNGM4OTk4Yjk0NDAzMDQxMDhmMDkyZGVlNWRkNWIxMWQ5NGI3LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzk2LWFkOGFmMTE0NDQwMGM5ZmYwNTViYTA5MmY5NDdkNjU2N2I4NWVlMTAvbGFyZ2VfMTkyMC04OTRkNGM4OTk4Yjk0NDAzMDQxMDhmMDkyZGVlNWRkNWIxMWQ5NGI3LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:13Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI1057634445",
      "type": "image",
      "attributes": {
        "name": "DBI-26-nNDC6xKx-downloadable-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581395-7d3913aa146b0bb868f049ea4677e88174f710a7/downloadable-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581395-7d3913aa146b0bb868f049ea4677e88174f710a7/large_1920-566c58054eb103640db99ce3c40d401d5e0d9646.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzk1LTdkMzkxM2FhMTQ2YjBiYjg2OGYwNDllYTQ2NzdlODgxNzRmNzEwYTcvbGFyZ2VfMTkyMC01NjZjNTgwNTRlYjEwMzY0MGRiOTljZTNjNDBkNDAxZDVlMGQ5NjQ2LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzk1LTdkMzkxM2FhMTQ2YjBiYjg2OGYwNDllYTQ2NzdlODgxNzRmNzEwYTcvbGFyZ2VfMTkyMC01NjZjNTgwNTRlYjEwMzY0MGRiOTljZTNjNDBkNDAxZDVlMGQ5NjQ2LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:12Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047827",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI9959548548",
      "type": "image",
      "attributes": {
        "name": "DBI-25-ukxnxkry-downloadable-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581394-b79f7d37e23de3891e8f449b661dbc1916cf199a/downloadable-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581394-b79f7d37e23de3891e8f449b661dbc1916cf199a/large_1920-0632da6d3944561fa734289f437dad64c1c8be12.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzk0LWI3OWY3ZDM3ZTIzZGUzODkxZThmNDQ5YjY2MWRiYzE5MTZjZjE5OWEvbGFyZ2VfMTkyMC0wNjMyZGE2ZDM5NDQ1NjFmYTczNDI4OWY0MzdkYWQ2NGMxYzhiZTEyLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzk0LWI3OWY3ZDM3ZTIzZGUzODkxZThmNDQ5YjY2MWRiYzE5MTZjZjE5OWEvbGFyZ2VfMTkyMC0wNjMyZGE2ZDM5NDQ1NjFmYTczNDI4OWY0MzdkYWQ2NGMxYzhiZTEyLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:11Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047826",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI2285291220",
      "type": "image",
      "attributes": {
        "name": "DBI-24-E2tw38OL-downloadable-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581393-afc0ea96e79d9a87932913b7c7b70a9f0b8d8685/downloadable-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581393-afc0ea96e79d9a87932913b7c7b70a9f0b8d8685/large_1920-e0e0ec63025e165620ea5ef305746f7e2571b710.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzkzLWFmYzBlYTk2ZTc5ZDlhODc5MzI5MTNiN2M3YjcwYTlmMGI4ZDg2ODUvbGFyZ2VfMTkyMC1lMGUwZWM2MzAyNWUxNjU2MjBlYTVlZjMwNTc0NmY3ZTI1NzFiNzEwLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzkzLWFmYzBlYTk2ZTc5ZDlhODc5MzI5MTNiN2M3YjcwYTlmMGI4ZDg2ODUvbGFyZ2VfMTkyMC1lMGUwZWM2MzAyNWUxNjU2MjBlYTVlZjMwNTc0NmY3ZTI1NzFiNzEwLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:10Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047826",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI1396105140",
      "type": "image",
      "attributes": {
        "name": "DBI-22-aafW6v7i-downloadable-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581391-57cd36b2b1f3968ba56502048c3c010455536f1f/downloadable-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581391-57cd36b2b1f3968ba56502048c3c010455536f1f/large_1920-7d202cb20da13b1d1cd74e8317988ab26ca7b2fd.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzkxLTU3Y2QzNmIyYjFmMzk2OGJhNTY1MDIwNDhjM2MwMTA0NTU1MzZmMWYvbGFyZ2VfMTkyMC03ZDIwMmNiMjBkYTEzYjFkMWNkNzRlODMxNzk4OGFiMjZjYTdiMmZkLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzkxLTU3Y2QzNmIyYjFmMzk2OGJhNTY1MDIwNDhjM2MwMTA0NTU1MzZmMWYvbGFyZ2VfMTkyMC03ZDIwMmNiMjBkYTEzYjFkMWNkNzRlODMxNzk4OGFiMjZjYTdiMmZkLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:09Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047826",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI0048210078",
      "type": "image",
      "attributes": {
        "name": "DBI-21-47vp11zz-downloadable-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581390-0e8045cddbd1b86f69308eddcf9eba3cabc30a54/downloadable-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581390-0e8045cddbd1b86f69308eddcf9eba3cabc30a54/large_1920-7c917afdce2068c57bd9f4fc6ac3cea07bc77377.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzkwLTBlODA0NWNkZGJkMWI4NmY2OTMwOGVkZGNmOWViYTNjYWJjMzBhNTQvbGFyZ2VfMTkyMC03YzkxN2FmZGNlMjA2OGM1N2JkOWY0ZmM2YWMzY2VhMDdiYzc3Mzc3LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzkwLTBlODA0NWNkZGJkMWI4NmY2OTMwOGVkZGNmOWViYTNjYWJjMzBhNTQvbGFyZ2VfMTkyMC03YzkxN2FmZGNlMjA2OGM1N2JkOWY0ZmM2YWMzY2VhMDdiYzc3Mzc3LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:08Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047826",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6979528367",
      "type": "image",
      "attributes": {
        "name": "DBI-20-F1ZJA8fl-downloadable-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581389-8d7b5f0c6b41ddebc8bc8189d3861581c825454d/downloadable-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581389-8d7b5f0c6b41ddebc8bc8189d3861581c825454d/large_1920-acd469284a64eefaeadc9ac7d078e2e5c0b756f8.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzg5LThkN2I1ZjBjNmI0MWRkZWJjOGJjODE4OWQzODYxNTgxYzgyNTQ1NGQvbGFyZ2VfMTkyMC1hY2Q0NjkyODRhNjRlZWZhZWFkYzlhYzdkMDc4ZTJlNWMwYjc1NmY4LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzg5LThkN2I1ZjBjNmI0MWRkZWJjOGJjODE4OWQzODYxNTgxYzgyNTQ1NGQvbGFyZ2VfMTkyMC1hY2Q0NjkyODRhNjRlZWZhZWFkYzlhYzdkMDc4ZTJlNWMwYjc1NmY4LkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:19:06Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047826",
            "type": "shot"
          }
        }
      }
    }, {
      "id": "DBI6162164261",
      "type": "image",
      "attributes": {
        "name": "DBI-18-LOvGFmfi-downloadable-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
        "version_urls": {
          "download": "https://cdn.dronebase.com/assets/mission/images/9581384-1d26b578718b26dfde0c4b7decfaa8543bbc2019/downloadable-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "large_1920": "https://cdn.dronebase.com/assets/mission/images/9581384-1d26b578718b26dfde0c4b7decfaa8543bbc2019/large_1920-9e7e8775fa34274d4ffa91ca00ee97d8dec85c0e.JPG",
          "small_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzg0LTFkMjZiNTc4NzE4YjI2ZGZkZTBjNGI3ZGVjZmFhODU0M2JiYzIwMTkvbGFyZ2VfMTkyMC05ZTdlODc3NWZhMzQyNzRkNGZmYTkxY2EwMGVlOTdkOGRlYzg1YzBlLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUiLCJoZWlnaHQiOjY0MCwid2lkdGgiOjY0MH19fQ==",
          "square_640": "https://d3qebnxw8g8784.cloudfront.net/eyJidWNrZXQiOiJkcm9uZWJhc2UtcHJvZHVjdGlvbiIsImtleSI6ImFzc2V0cy9taXNzaW9uL2ltYWdlcy85NTgxMzg0LTFkMjZiNTc4NzE4YjI2ZGZkZTBjNGI3ZGVjZmFhODU0M2JiYzIwMTkvbGFyZ2VfMTkyMC05ZTdlODc3NWZhMzQyNzRkNGZmYTkxY2EwMGVlOTdkOGRlYzg1YzBlLkpQRyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb250YWluIiwiaGVpZ2h0Ijo2NDAsIndpZHRoIjo2NDB9fX0="
        },
        "processing": false,
        "processing_status": "ready",
        "created_at": "2019-10-23T00:18:28Z",
        "share_token": null
      },
      "relationships": {
        "mission": {
          "data": {
            "id": "DBM7214786245",
            "type": "mission"
          }
        },
        "shot": {
          "data": {
            "id": "1047826",
            "type": "shot"
          }
        }
      }
    }]
  };
  _exports.default = _default;
});