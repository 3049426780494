define("clients/templates/components/mission-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NfYq/vWS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"mission-order-google-map\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,1,\"map_instructions\"],[12],[2,\"\\n    Property details. (Only editable before mission is confirmed. Please contact us if you need to change details.)\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,1,\"map_canvas\"],[14,0,\"collapsed\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,1,\"map_auto_saving\"],[12],[2,\"Saving...\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,1,\"info-window-node\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-map.hbs"
    }
  });

  _exports.default = _default;
});