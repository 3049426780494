define("clients/components/asset-uploader", ["exports", "jquery", "clients/utils/arrayWrapper", "clients/config/environment", "clients/mixins/s3-asset-upload"], function (_exports, _jquery, _arrayWrapper, _environment, _s3AssetUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_s3AssetUpload.default, {
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    fileProgress: 0,
    showNativeThumbnail: true,
    filesUploaded: 0,
    listView: false,
    uploadProgress: null,
    currentImages: Ember.computed.reads('mission.images'),
    currentVideos: Ember.computed.reads('mission.videos'),
    currentAssets: Ember.computed.union('currentImages', 'currentVideos'),
    inProgress: Ember.observer('queue.progress', function () {
      let progress = this.get('queue.progress');
      let filesUploaded = 0;
      const queue = this.queue;

      if (queue) {
        const uploader = queue.get('uploader');

        for (const file of uploader.files) {
          if (file.percent > 0) {
            filesUploaded++;
          }
        }

        if (filesUploaded > queue.numFilesUploading) {
          filesUploaded = filesUploaded - (uploader.files.length - queue.numFilesUploading);
        }

        this.set('filesUploaded', filesUploaded);
        this.set('numFilesUploading', queue.numFilesUploading);
      }

      if (!progress || progress <= 0) {
        progress = 0;
      }

      if (progress >= 100) {
        progress = 100;
        this.set('numFilesUploading', 0);
        queue.clearNumUploading();
        this.set('filesUploaded', 0);
      }

      this.set('uploadProgress', progress);
      const uploaded = this.fileSize * progress / 100.0;

      if (uploaded) {
        // eslint-disable-next-line no-undef
        this.set('fileProgress', filesize(uploaded, {
          'base': 10,
          'round': 0
        }));
      }
    }),
    startUploading: Ember.observer('queue.uploadsQueued', function () {
      const uploadsQueued = this.get('queue.uploadsQueued');

      if (uploadsQueued) {
        const uploader = this.get('queue.uploader');
        this.set('numFilesUploading', uploader.files.length);

        if (uploader.files.length > 100) {
          this.set('listView', true);
        }

        Ember.run.later(function () {
          return uploader.start();
        });
      }
    }),
    showInstructions: Ember.computed('queue.content.length', 'images.length', 'videos.length', function () {
      return this.get('queue.content.length') === 0 && this.get('images.length') === 0 && this.get('videos.length') === 0;
    }),
    queueLookup: Ember.inject.service('upload-queue-manager'),
    runtimes: (0, _arrayWrapper.default)(['html5', 'html4']),
    extensions: (0, _arrayWrapper.default)(),
    'max-file-size': 0,
    'no-duplicates': true,
    multiple: true,
    classNames: ['asset-uploader'],
    onfileadd: null,
    onerror: null,
    config: Ember.computed('elementId', 'extensions.length', 'fileFilters', 'max-file-size', 'multiple', 'no-duplicates', 'runtimes', 'shot_type', 'uploadedCount', function () {
      const config = {
        url: true,
        browse_button: "uploader" + (this.shot_type || ''),
        filters: {
          max_file_size: this.get('max-file-size'),
          prevent_duplicates: this.get('no-duplicates')
        },
        multi_selection: this.multiple,
        runtimes: this.runtimes.join(','),
        container: this.elementId,
        uploadCount: this.uploadedCount
      };
      const filters = this.fileFilters || {};
      Object.keys(filters).forEach(function (filter) {
        if (this.get(filter)) {
          return config.filters[filter] = this.get(filter);
        }
      });

      if (this.get('extensions.length')) {
        config.filters.mime_types = [{
          extensions: this.extensions.map(ext => {
            return ext.toLowerCase();
          }).join(',')
        }];
      }

      return config;
    }),
    didInsertElement: function () {
      this._super();

      Ember.run.scheduleOnce('afterRender', this, 'attachUploadQueue');
    },
    attachUploadQueue: function () {
      const queueLookup = this.queueLookup;
      let queueName = 'uploader';
      const queue = queueLookup.findOrCreate(queueName, this, this.config);
      this.initDragDrop();
      this.set('queue', queue);
    },
    // eslint-disable-next-line ember/no-on-calls-in-components
    destroyUploadQueue: Ember.on('willDestroyElement', function () {
      const queue = this.queue;

      if (queue) {
        queue.uploader.destroy();
        this.set('queue', null);
      }
    }),
    addAsset: function (file) {
      const fileType = file.get('type').match(/video|image/)[0];
      const asset = this.store.createRecord(fileType, {
        id: file.get('id'),
        name: file.get('sanitizedName'),
        processing: true,
        generatingNativeThumbnail: this.showNativeThumbnail,
        file: file,
        uploadNumber: file.uploadNumber,
        source_type: 'edit'
      });
      const mission = this.mission;
      mission.get(`${fileType}s`).unshiftObject(asset);
      this.send('addAsset', file);
    },
    initDragDrop: function (target) {
      let elementName = target || this.shot_type || 'upload-target';
      elementName = `.${elementName}`;
      const uploadElement = (0, _jquery.default)(elementName);
      uploadElement.on('drag dragend dragover dragenter dragleave drop', function (e) {
        e.preventDefault();
        e.stopPropagation();
      }).on('dragover dragenter', function (e) {
        // Don't allow drags from within the page, only from the desktop
        if (e.dataTransfer.types.length !== 3) {
          uploadElement.addClass('is-dragover');
        }
      }).on('dragleave dragend drop', function () {
        uploadElement.removeClass('is-dragover');
      }).on('drop', e => {
        this.addFilesAndUpload(e.originalEvent.dataTransfer.files);
      });
    },
    addFilesAndUpload: function (droppedFiles) {
      const queue = this.queue;
      const uploader = queue.get('uploader');
      uploader.bind('FilesAdded', Ember.run.bind(this, 'afterFilesAdded'));

      for (const file of droppedFiles) {
        uploader.addFile(file);
      }
    },
    afterFilesAdded: function () {
      Ember.run.later(() => {
        const queue = this.queue;
        const uploader = queue.get('uploader');
        uploader.start();
      });
    },
    uploadsComplete: function () {
      if (!window.confirm('Have you uploaded all assets for this mission?')) {
        return;
      }

      this.updateStatus('assets_uploaded');
    },
    updateStatus: function (newStatus) {
      // Update mission status to the next status
      const mission = this.mission;
      mission.set('status', newStatus);
      const headers = this.get('sessionAccount.headers');

      _jquery.default.ajax({
        url: `${_environment.default.api.host}/v1/clients/missions/${mission.id}/status/${newStatus}`,
        type: 'PATCH',
        dataType: 'json',
        headers: headers
      }).then(() => {
        console.log('success');
      }).catch(() => {
        console.log('fail');
      });
    },
    deselectAll: function () {// no op
    },
    actions: {
      uploadsComplete() {
        this.uploadsComplete();
      },

      toggleListView() {
        this.toggleProperty('listView');
        this.toggleProperty('showNativeThumbnail');
      },

      toggleSampleUpload() {
        this.toggleSampleUploadAction();
      },

      deleteAsset(asset) {
        if (window.confirm('Are you sure? This will permanently remove this asset.')) {
          return asset.destroyRecord().then(() => alert('The asset was deleted successfully'), () => alert('There was an issue deleting this asset'));
        }
      }

    }
  });

  _exports.default = _default;
});