define("clients/templates/components/mission-group/delete-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZMPLS8Vs",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"title\",\"isSaving\"],[\"Delete Group\",[35,2]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    The following Group will be deleted and the (\"],[1,[35,0,[\"totalMissionsCount\"]]],[2,\") Missions contained within the group will be unassigned\\n    in the Mission List:\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"group-input\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/dashboard/mission-group/group_icon.svg\"],[12],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"group-name\"],[12],[1,[35,0,[\"name\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"group-buttons\"],[12],[2,\"\\n      \"],[8,\"button\",[[4,[38,1],[[32,0],\"close\"],null]],[[\"@type\"],[\"secondary\"]],[[\"default\"],[{\"statements\":[[2,\"Cancel\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"button\",[[4,[38,1],[[32,0],\"save\"],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Confirm\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"group\",\"action\",\"isSaving\",\"mission-group/base-modal\"]}",
    "meta": {
      "moduleName": "clients/templates/components/mission-group/delete-modal.hbs"
    }
  });

  _exports.default = _default;
});