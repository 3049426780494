define("clients/helpers/location-visibility-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LocationVisibilityClassHelper;
  LocationVisibilityClassHelper = Ember.Helper.helper(function (arg) {
    var col, el, key;
    col = arg[0], key = arg[1];
    el = col.find(function (item, index, self) {
      return item.id === key;
    });

    if (el.visible) {
      return '';
    } else {
      return 'hidden';
    }
  });
  var _default = LocationVisibilityClassHelper;
  _exports.default = _default;
});